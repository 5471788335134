import styled from 'styled-components';

const StyledReportComponent = styled.div`
padding: 11px 1px;
background: var(--noDataBackground);
position: sticky;
top: 0;
z-index: 1;
background: var(--noDataBackground) 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 3px var(--boxShadow);
opacity: 1;
&.v1ReportStyle{
  padding-top: 0;
}
.se-header-divider{
  border: 0.5px solid var(--searchBorder);
  margin: -5px 16px;
  border-left:none;
}
.se-report-header {
  display:flex;
  padding-left: 15px;
  align-items: center;
  .se-report-top-button-containers{
    margin-right: 68.5px ;
    display: flex;
  .se-new-header-button, .se-new-export-button{
    display: flex;
    align-items: center;
    padding: 7px 7px;
    border: 1px solid var(--searchBorder);
    border-radius: 2px;
    background: var(--noDataBackground);
    font-family: 'SemiBold';

    :disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .se-dropdown-hidden, .se-divider-hidden {
    display: none;
  }
 
  .se-new-header-button:hover{
    border: 1px solid var(--collapsibleColor);
  }
  .se-new-export-button:hover{
    border: 1px solid var(--collapsibleColor);

    #Path_7624, #Path_7626{
      fill: var(--collapsibleColor) !important;
    }
  }
  }
  .se-header-container{
    flex:1;
    display:flex;
    flex-direction:column;
    margin-right: 10px;
    overflow: hidden;
    padding-left: 10px;

    .se-report-header-label{
      font-size: 20px;
      font-family: SemiBold;
      color: var(--reportHeaderLabel);
      line-height: 1.4;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;

      ._pendo-badge { 
        margin: 8.5px 0 0 10px !important;
      }
      
      .se-permission-template-cn{
        display: flex;
        margin: 4px 0px 0px 4px;
        background-color: rgba(245, 128, 42, 0.1);

        .se-permission-template-label {
          font-size: 11px;
          color: #F5802A;
          margin: 4px 3px 0px 0px;
        }

        .se-icon-cn {
          margin: 0px 0px 0px 2px;
          }
      }
      
    }
    .se-report-sub-header-label{
      letter-spacing: 0px;
      color: var(--searchBoxTextColor);
      opacity: 1;
      font-family: Regular;
      font-size: 11px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
  
.se-new-report-button-container{
    display:flex;
    place-content: flex-end;
    flex:1;
    
} 

.se-new-report-button-text{
 font-size: 11px;
 color:var(--headerColor);
 margin-right: 6px;
}

.se-margin{
  margin-right: 15px;
}
.se-new-report-button{
    background: var(--onHoverBorder) 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1; 
    font-family: Regular;
    letter-spacing: 0px;
    color:  var(--noDataBackground);
    opacity: 1;
    padding: 0px 15px 0px 12px;
    display: flex;
    align-items: center;
    cursor:pointer;
    border: none;
}  
 
  .searchIcon {
    padding: 0.5rem;
  }
  
  .searchBox {
    border: 0;
    flex: 1;
    color:var(--searchBoxTextColor);
    font-family: Regular;
    outline:none;
  }
  .searchBox:focus,.searchBox:hover{
    border:0px
  }
  .searchButton {
    background: var(--searchButtonBackground);
    border: 0;
    color:  var(--noDataBackground);
    border-radius: 0;
  }
  @media (max-width: 320px) {
    .se-header-container{
      margin-left: 17px;
}}

@media (max-width: 991px) {
    .se-header-container {
      margin-left: 17px;
}
}
`;

export default StyledReportComponent;
