import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReduxModel } from 'core/models';

// We are taking in the component that should be rendered only if the user has not having reporting feature disabled
// We are also passing the rest of the props to the <Route /> component
const ProtectedRoute: React.FC<RouteProps> = ({ ...routerProps }) => {
  const isReportingDisabled = useSelector((state: ReduxModel.IGlobalState) => state.auth.isReportingDisabled as boolean);

  if (isReportingDisabled) {
    return <Redirect to="/unauthorized" />;
  }
  return <Route {...routerProps} />;
};

export default ProtectedRoute;
