import React, { useState } from 'react';
import StyledComponent from 'components/container/PageContainer/page-container.style';
import SideNavbar from 'components/common/SideNavbar/side-navbar.component';
import { SIERAPage } from 'core/constants/page-url';
import HeaderComponent from 'components/common/Header/header.component';
import store from 'redux/store';

const PageContainer = (Component: any, route:SIERAPage) => (
  (props: any) => {
    const [expand, setExpand] = useState(false);
    const onClick = () => {
      setExpand(!expand);
    };
    const lsqMarvinApp = store.getState().auth.isMarvin;
    const isV1Report = store.getState().auth.isV1Report;
    return (
      <>
        <StyledComponent className={`page-container ${route} ${expand ? 'collapse' : 'expand'}`}>
          {
            (!lsqMarvinApp && !isV1Report ? <HeaderComponent {...props} /> : null)
          }
          <div
            className={`se-main-cn ${isV1Report ? 'v1ReportStyle' : ''}`}
          >
            {
            (
            !isV1Report
              ? (
                <SideNavbar
                  {...props}
                  onClick={onClick}
                />
              ) : null
            )
  }
            <div
              className="se-main-body"
            >
              <Component
                {...props}
                expand
              />
            </div>
          </div>
        </StyledComponent>
      </>
    );
  });

export default PageContainer;
