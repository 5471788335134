import styled from 'styled-components';

const StyledNoExportState = styled.div`
width:100%;
height:100%;
display: flex;
justify-content: center;
align-items:center;

 .se-no-export-state-text{
     font:  16px SemiBold;
     color: var(--headerColor);
     margin-top: 12px;
     padding: 0 30px;
     text-align: center;
}

.se-no-export-icon{
    margin:0 auto;
    width:72px;
    height:72px;
    display:flex;
    justify-content:center;
    align-items: center;
    background:#F4F6F8;
    border-radius: 100%;
}
`;

export default StyledNoExportState;
