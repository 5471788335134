import React from 'react';
import StyledNoResultState from 'components/feature/Report/ReportSidebar/common/NoResultState/no-result-state.style';
import { INoResultStateProps } from 'components/feature/Report/ReportSidebar/common/NoResultState/no-result-state.d';

const NoResultStateScreen = (props:INoResultStateProps) => {
  const { message, Icon } = props;
  return (

    <StyledNoResultState>
      <div>
        <div className="se-no-result-state-icon">

          {Icon}
        </div>
        <div className="se-no-result-state-text">
          {message}
        </div>
      </div>

    </StyledNoResultState>
  );
};

export default NoResultStateScreen;
