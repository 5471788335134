import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  AdditionalFieldTitle,
  DimensionStatus, ExpandListButtonContext, FieldEntitiesType, MaxDrilldownGrouping,
} from 'core/constants/report';
import { MaxLimitDesc } from 'core/constants/filter';
import {
  AdditionalDimensionEmptyState, SearchBarPlaceHolder, AdditionalDimensionNoResultState,
  NoDimensionDataMessages,
} from 'core/constants/visualizations';
import { ReduxModel } from 'core/models';
import {
  genericSearch, getFieldDisplayName, getFieldIdentifier, getTextTooltipContentForField, sortAdditionalFields,
} from 'core/utils/report.util';
import { IDrilldownFieldsProps, IColumnWithIndex } from 'components/feature/Report/ReportSidebar/Settings/DrilldownDimensions/DrilldownAvailableFields/drilldown-avail-fields.d';
import AvailableDimensions from 'components/feature/Report/ReportSidebar/common/AvailableDimensions/available-dimensions.component';
import DimensionItem from 'components/feature/Report/ReportSidebar/common/AvailableDimensions/DimensionItem/dimension-item.component';
import EmptyStateScreen from 'components/feature/Report/ReportSidebar/common/EmptyState/empty-state.component';
import SearchBar from 'components/feature/Report/ReportSidebar/common/SearchBar/search-bar-component';
import NoResultStateScreen from 'components/feature/Report/ReportSidebar/common/NoResultState/no-result-state-component';
import NoFieldsIcon from 'components/common/svg/NoFields/noFields.svg';
import FieldEntity from 'components/feature/Report/ReportSidebar/common/FieldEntity/field-entity.component';

const DrilldownAvailableFields = (props: IDrilldownFieldsProps) => {
  const {
    reportBuilderPortal, expanded, collapse, addDrilldownFields,
  } = props;
  const [report] = useSelector((state: ReduxModel.IGlobalState) => [state.report] as const);

  const { autodrilldownData } = report;

  const [query, setQuery] = useState<string>('');
  const [expandedEntity, setExpandedEntity] = useState<string[]>([]);

  useEffect(() => {
    if (!expanded) {
      setQuery('');
      setExpandedEntity([]);
    }
  }, [expanded]);

  const entityList = [] as string[];
  Object.keys(FieldEntitiesType).forEach((e: string) => {
    if (autodrilldownData.drilldownFields && autodrilldownData.drilldownFields.find((d) => d.BuilderConfig && (d.BuilderConfig.Entity === e))) {
      entityList.push(e);
    }
  });

  // Original indexes are needed for setGroupingDrilldown
  const unGroupedDimensions = autodrilldownData.drilldownFields && autodrilldownData.drilldownFields
    .map((dim, index) => ({ ...dim, originalIndex: index } as IColumnWithIndex))
    .filter((dim) => dim.Props.Dimension && dim.Props.Dimension.Applied === DimensionStatus.NotApplied);
  const isMaxGroupingLimit = ((autodrilldownData.drilldownFields && autodrilldownData.drilldownFields.length - unGroupedDimensions.length) >= MaxDrilldownGrouping);
  const hasFields = unGroupedDimensions && !isEmpty(unGroupedDimensions);

  // filtered ungrouped dimensions based on search query.
  const filteredUnGroupedDim = unGroupedDimensions && unGroupedDimensions.filter((dim) => genericSearch(query, getFieldDisplayName(dim)));

  const hasFilteredGroupedDim = filteredUnGroupedDim && !!filteredUnGroupedDim.length;

  // list of list which has two element first is entity and second element is list of matching dimensions.
  const entityDimList = [] as any;
  entityList.forEach((entity) => {
    const dimList = filteredUnGroupedDim.filter((dim) => dim.BuilderConfig && (dim.BuilderConfig.Entity === entity));
    entityDimList.push([entity, sortAdditionalFields(dimList)]);
  });

  const handleExpandedEntity = (entity: string) => {
    if (expandedEntity.includes(entity)) {
      setExpandedEntity(
        query ? expandedEntity.filter((e) => e !== entity) : [],
      );
      return;
    }
    setExpandedEntity(
      query ? [...expandedEntity, entity] : [entity],
    );
  };

  return (
    <AvailableDimensions
      reportBuilderPortal={reportBuilderPortal}
      expanded={expanded}
      title={AdditionalFieldTitle.Drilldown}
      context={ExpandListButtonContext.Drilldown}
      collapse={collapse}
      search={hasFields
        && (
        <SearchBar
          onChange={(q) => setQuery(q)}
          query={query}
          searchPlaceholder={SearchBarPlaceHolder.Fields}
          setFocus={() => setExpandedEntity(entityList)}
        />
        )}
      showcase={(
          hasFields
            ? (
              <>
                {
                  hasFilteredGroupedDim
                    ? entityDimList.map((entity: any) => {
                      const [entityKey, dimensions] = entity;
                      const unGrpDimsWithinEntity = !!(autodrilldownData.drilldownFields.find((dim) => (dim.BuilderConfig
                        && (dim.BuilderConfig.Entity === entityKey) && dim.Props.Dimension.Applied === DimensionStatus.NotApplied)));
                      return (
                        <FieldEntity
                          key={entityKey}
                          entity={entityKey}
                          expand={expandedEntity.includes(entityKey)}
                          handleExpandedEntity={() => handleExpandedEntity(entityKey)}
                          showcase={(
                            <>
                              {
                                unGrpDimsWithinEntity
                                  ? (
                                    <>
                                      {
                                        query && !dimensions.length
                                          ? (
                                            <div
                                              className="se-no-dimension-data"
                                            >
                                              {NoDimensionDataMessages.NoSearches}
                                            </div>
                                          )
                                          : dimensions.map((dim: IColumnWithIndex) => (
                                            <DimensionItem
                                              key={getFieldIdentifier(dim)}
                                              name={getFieldDisplayName(dim)}
                                              nameTooltipContent={getTextTooltipContentForField(dim)}
                                              isMaxLimit={isMaxGroupingLimit}
                                              maxLimitDesc={MaxLimitDesc.DrilldownField
                                                .replace('{{limit}}', MaxDrilldownGrouping.toString())}
                                              add={() => addDrilldownFields(dim.originalIndex)}
                                            />
                                          ))
                                      }
                                    </>
                                  )
                                  : (
                                    <div
                                      className="se-no-dimension-data"
                                    >
                                      {NoDimensionDataMessages.AllColumnAdded}
                                    </div>
                                  )
                              }
                            </>
                        )}
                        />
                      );
                    })
                    : (
                      <NoResultStateScreen
                        message={AdditionalDimensionNoResultState.Fields}
                        Icon={(
                          <NoFieldsIcon
                            width={36}
                            height={33}
                          />
                        )}
                      />
                    )
              }
              </>
            )
            : (
              <EmptyStateScreen
                message={AdditionalDimensionEmptyState.Fields}
              />
            )
          )}
    />
  );
};

export default DrilldownAvailableFields;
