import styled from 'styled-components';

const StyledNoResultState = styled.div`
display: flex;
width:100%;
height:100%;
justify-content: center;
align-items:center;

.se-no-result-state-text{
    font-family: 'Regular';
    font-size: 12px;
    color: var(--paginationColor);
    margin-top: 12px;
    padding: 0 30px;
    text-align: center;
}

.se-no-result-state-icon{
    margin:0 auto;
    width:60px;
    height:60px;
    display:flex;
    justify-content:center;
    align-items: center;
    border-radius: 100%;
}
`;

export default StyledNoResultState;
