import React from 'react';
import { IProps } from 'components/common/FilterBuilder/MaskedFilter/masked-filter.d';
import StyledContainer from 'components/common/FilterBuilder/MaskedFilter/masked-filter.styled';
import LockIcon from 'components/common/svg/Lock/lock.svg';
import { MaskedMessage } from 'core/constants/report';
import Tooltip from 'rc-tooltip';

const MaskedFilter = (props: IProps) => {
  const { label, removeBorderClass } = props;
  return (
    <StyledContainer>
      <div className={`se-filter-container ${removeBorderClass}`}>
        <div className="se-item-name">
          {label}
        </div>
        <Tooltip
          placement="bottom"
          overlay={(
            <span>
              {MaskedMessage.AppliedFilter}
            </span>
)}
          mouseEnterDelay={1.5}
          overlayClassName="se-restricted-field"
        >
          <div className="se-icon-cn se-lock-icon">
            <LockIcon
              width={20}
              height={20}
            />
          </div>
        </Tooltip>
      </div>
    </StyledContainer>
  );
};

export default MaskedFilter;
