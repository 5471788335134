import React from 'react';
import PageContainer from 'components/container/PageContainer/page-container.component';
import { Report } from 'components/feature';
import Auth from 'components/container/Auth/auth.component';
import { SIERAPage } from 'core/constants/page-url';

export default (props: any) => (
  <Auth
    Component={PageContainer(Report, SIERAPage.REPORT)}
    closedNavbar
    {...props}
  />
);
