import styled from 'styled-components';

export default styled.div`
.se-spinner-loader {
    border: 2px solid var(--base-date-picker-border);;
    border-top: 2px solid var(--reactSelectBorderAcitve);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
