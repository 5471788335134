import React from 'react';
import { ColumnActionTypes } from 'core/constants/report';
import { ObjModel, ReportResponseModel } from 'core/models';
import ColumnChartUtil from 'core/utils/column-chart.util';
import { IProps } from 'components/feature/Report/ReportBuilder/Reports/Charts/column-chart.d';
import StyledHighChartComponent from 'components/feature/Report/ReportBuilder/Reports/Charts/column-chart.styled';
import StoreConnector from 'components/feature/Report/ReportBuilder/Reports/Charts/column-chart.redux';
import { RawDataComponent } from 'components/common/visualization/other/UnderlyingDataTable/underlying-data.component';
import HighChart from 'components/common/visualization/highcharts/HighChart/high-chart.component';

export const ColumnChart = (props: IProps) => {
  const {
    reduxState, reduxAction, handleDrilldownClick, handleSetFilterClick, onColumnReorder, onCellClicked,
  } = props;
  const {
    reportConfig, reportData, sort, isMultiSort, activeFilters, activeDimensions, activeDimensionsLength,
  } = reduxState.report;
  const { sortingChange } = reduxAction;
  const { AllowUserDrillDown, AllowDrilldownOnMaskedDimensions } = reportConfig;
  const builder = reportConfig?.Visualization?.Builder as ReportResponseModel.IChartVisualizationBuilder;

  let categories:Array<string> = [];
  let series: Array<{name: string, data: Array<ObjModel.Obj>}> = [];
  let categoryData: ObjModel.ObjGeneric<ObjModel.Obj> = {};
  const util = new ColumnChartUtil(reduxState.report);
  const drillDown = (t: any) => {
    const category = t?.point?.category;
    if (category && category.length && util.XAxis && util.XAxis.length) {
      const columnDef = util.XAxis[0]; // need to make dynamic and will require changes
      const data = categoryData[category];
      if (columnDef && columnDef.Props && columnDef.Props.Action && columnDef.Props.Action.Type && data) {
        const action = columnDef.Props.Action;
        switch (action.Type) {
          case ColumnActionTypes.Drilldown:
            handleDrilldownClick(action, data, category);
            break;
          case ColumnActionTypes.SetFilter:
            handleSetFilterClick(columnDef, action, data, category);
            break;
          default:
            throw new Error('Not Implemented');
        }
      }
    }
  };
  if (reportData && reportData.Raw && reportData.Raw.Data) {
    const xAxisData = util.GetXAxisColumns();
    categories = xAxisData.categories;
    categoryData = xAxisData.categoryData;
    series = util.GetYAxisColumns(activeFilters, drillDown, AllowUserDrillDown);
  }
  const aggregatesData = reportData?.Raw?.Aggregates;

  return (
    <StyledHighChartComponent>
      <div className="se-column-chart-container">
        <HighChart
          options={util.GetChartOptions(categories, series, AllowUserDrillDown)}
        />
      </div>
      <RawDataComponent
        {...util.GetRawDataConfig(activeDimensions)}
        label={builder?.XAxis?.label}
        onColumnReorder={onColumnReorder}
        sorting={{
          sort,
          isMultiSort,
          onChange: sortingChange,
        }}
        activeFilters={activeFilters}
        AllowUserDrillDown={AllowUserDrillDown}
        activeDimensionsLength={activeDimensionsLength}
        onCellClicked={onCellClicked}
        allowAutodrilldown={AllowDrilldownOnMaskedDimensions}
        aggregatesData={aggregatesData}
      />
    </StyledHighChartComponent>

  );
};

export default StoreConnector()(ColumnChart);
