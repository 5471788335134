import { Dispatch } from 'redux';
import { isArray, isEmpty } from 'lodash';
import { FilterType } from 'core/constants/filter';
import {
  DimensionNoValue, DimensionStatus, ExportStatus, ReportViewTypes,
} from 'core/constants/report';
import { IColumn } from 'core/models/report-response';
import { ReportActionTypes } from 'core/constants/redux-action-types';
import FilterFetchUtility from 'core/utils/filter-fetch-utility';
import {
  AuthReduxModel, FilterModel, ObjModel, ReportReduxModel, ReportResponseModel,
} from 'core/models';
import { ReportFetchCriteria, ReportService, SelectedRow } from 'services/report.service';
import * as Actions from 'redux/report/actions';
import { getColumnsForActiveDimensions } from 'redux/report/reducer';
import { IDimension } from 'core/models/report-redux';
import { getReportViewInfo } from 'redux/report-view/report-view.actions';

// Custom middleware for report to handle asynchronous request.
// next[method] called to send action to reducer.
export default (store: any) => (next: any) => (action: ReportReduxModel.IAction) => {
  const { getState, dispatch } = store;

  switch (action.type) {
    case ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST: {
      next(action);
      const { auth } = getState();
      const {
        reportId, isRefresh,
      } = action.payload as ReportReduxModel.IReportConfigLoadRequestPayload;
      handleReportConfigLoadRequest(
        dispatch,
        reportId,
        isRefresh,
        auth.variables,
      );
      break;
    }

    case ReportActionTypes.FILTER_LOAD_REQUEST: {
      next(action);
      const { auth, report } = getState();
      const {
        filterId, isLazyLoad, filterValue,
      } = action.payload as ReportReduxModel.IFilterLoadRequestPayload;
      handleFilterLoadRequest(
        dispatch,
        report.filterConfig,
        report.appliedFilters,
        filterId,
        isLazyLoad,
        filterValue,
        auth.variables,
      );
      break;
    }

    case ReportActionTypes.REPORT_DATA_LOAD_REQUEST: {
      next(action);
      const { report, reportView } = getState();
      handleReportDataLoadRequest(
        dispatch,
        report.filterConfig,
        report.activeFilters,
        report.reportId,
        report.sort,
        report.page,
        report.prevAppliedFilters,
        report.columns,
        report.activeDimensions,
        report.allMeasures,
        reportView.selectedReportViewItem,
      );
      break;
    }

    case ReportActionTypes.DRILLDOWN_CHANGE: {
      next(action);
      const { auth, report } = getState();
      const {
        filterId, value,
      } = action.payload as ReportReduxModel.IDrilldownChangePayload;
      handleDrilldownChange(
        dispatch,
        report.filterConfig,
        filterId,
        value,
        auth.variables,
      );
      break;
    }

    case ReportActionTypes.REORDER_COLUMNS: {
      next(action);
      const {
        columns,
      } = action.payload as ReportReduxModel.IReorderColumnsPayload;
      handleReorderColumns(
        dispatch,
        columns,
      );
      break;
    }

    case ReportActionTypes.SAVE_COLUMNS_ORDER_REQUEST: {
      next(action);
      const { report } = store.getState();
      const { columns, isAutodrilldown } = action.payload as ReportReduxModel.IReorderColumnsPayload;
      let SelctedMeasure = '';
      let isDrilldown = false;
      let ColumnAliases = [];
      if (report.autodrilldownData) {
        SelctedMeasure = report.autodrilldownData.selectedMeasure;
        isDrilldown = true;
        ColumnAliases = report.autodrilldownData.columns
          .map((item: IDimension) => item?.BuilderConfig?.Alias || '')
          .filter((element: string) => element !== '');
      }
      if (!isAutodrilldown) {
        handleSaveColumnsOrderRequest(
          report.reportId,
          columns,
          SelctedMeasure,
          isDrilldown,
          ColumnAliases,
        );
      }
      break;
    }

    case ReportActionTypes.SEARCH_DROPDOWN_OPTIONS:
    case ReportActionTypes.LOAD_MORE_DROPDOWN_OPTIONS: {
      next(action);
      const { auth } = getState();
      const {
        filterId, filterValue,
      } = action.payload as ReportReduxModel.ISearchDropdownOptionsPayload;
      handleSearchDropdownOptions(
        dispatch,
        filterId,
        filterValue,
        auth.variables,
      );
      break;
    }

    case ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST: {
      next(action);
      const { report } = getState();
      const { selectedMeasure } = action.payload as ReportReduxModel.IDynamicDimensionsLoadRequestPayload;
      handleDynamicDimensionsLoadRequest(
        dispatch,
        report.reportId,
        selectedMeasure,
      );
      break;
    }

    case ReportActionTypes.REFRESH: {
      next(action);
      const { report } = getState();
      if (report?.defaultView?.DefaultViewID) {
        dispatch(getReportViewInfo({ personalisationId: report?.defaultView?.DefaultViewID, context: 'reportSelect', ReportViewType: ReportViewTypes.Default }));
      } else {
        dispatch(Actions.reportConfigLoadRequest(report.reportId, true));
      }
      dispatch(Actions.exportListRequest());
      dispatch(Actions.dataRefreshDetailsLoadRequest(report.reportId));
      break;
    }

    case ReportActionTypes.AUTODRILLDOWN: {
      next(action);
      const {
        selectedMeasure,
      } = action.payload as ReportReduxModel.IAutoDrilldownPayload;
      if (selectedMeasure) {
        dispatch(Actions.autodrilldownReportDataLoadRequest());
      }
      break;
    }

    case ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST: {
      next(action);
      const { report } = getState();
      handleAutodrilldownReportDataLoadRequest(
        dispatch,
        report.reportId,
        report.autodrilldownData,
        report.filterConfig,
        report.columns,
        report.activeDimensions,
        report.allMeasures,
        report.activeFilters,
      );
      break;
    }

    case ReportActionTypes.PAGINATION_CHANGE: {
      next(action);
      const {
        ...payload
      } = action.payload as ReportReduxModel.IPaginationChangePayload;
      if (payload.isAutodrilldown) {
        dispatch(Actions.autodrilldownReportDataLoadRequest());
      }
      break;
    }

    case ReportActionTypes.EXPORT_DATA_LOAD_REQUEST: {
      next(action);
      const {
        fileType,
      } = action.payload as ReportReduxModel.IExportDataLoadRequestPayload;
      const { report } = getState();
      handleExportDataLoadRequest(
        dispatch,
        report.filterConfig,
        report.activeFilters,
        report.reportId,
        report.sort,
        report.page,
        report.columns,
        report.activeDimensions,
        report.allMeasures,
        report.autodrilldownData,
        fileType,
      );
      break;
    }

    case ReportActionTypes.EXPORT_LIST_LOAD_REQUEST: {
      next(action);
      handleExportListLoadRequest(dispatch);
      break;
    }

    case ReportActionTypes.EXPORT_USAGE_STATUS_REQUEST: {
      next(action);
      handleExportUsageStatusRequest(dispatch);
      break;
    }

    case ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_REQUEST: {
      next(action);
      const {
        exportIds,
      } = action.payload as ReportReduxModel.IExportListLoadByIdsPayload;

      handleExportListLoadByIdsRequest(dispatch, exportIds);
      break;
    }

    case ReportActionTypes.RETRY_EXPORT_REQUEST: {
      next(action);
      const {
        exportID,
      } = action.payload as ReportReduxModel.IRetryExportRequestPayload;
      handleRetryExportRequest(dispatch, exportID);
      break;
    }

    case ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST: {
      next(action);
      const { reportId, isPolling } = action.payload as ReportReduxModel.IDataRefreshDetailsLoadRequestPayload;
      handleDataRefreshDetailsLoadRequest(dispatch, reportId, isPolling);
      break;
    }

    default:
      next(action);
      break;
  }
};

const handleReportConfigLoadRequest = (
  dispatch: Dispatch, reportId: string, isRefresh: boolean, variables: AuthReduxModel.IVariables,
) => {
  const params: ObjModel.Obj = { reportId };
  if (isRefresh) {
    params.isRefresh = 1;
  }
  new ReportService().getReportConfig(params)
    .then((res) => {
      dispatch(Actions.reportConfigLoadSuccess(res, variables));
    }).catch(() => dispatch(Actions.reportConfigLoadFailure()));
};

const handleFilterLoadRequest = (
  dispatch: Dispatch, filterConfig: Array<ReportResponseModel.IFilterConfig>,
  appliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>, filterId: string,
  isLazyLoad: boolean, filterValue: any, variables: AuthReduxModel.IVariables,
) => {
  const filter = filterConfig.find((item) => item.ID === filterId);
  const value = filter.LinkedTo
    ? appliedFilters[filter.LinkedTo].FilterResponse
    : null;
  new FilterFetchUtility(variables).GetSingleFilterData(
    filter,
    value,
    isLazyLoad
      ? filterValue
      : appliedFilters[filterId].FilterResponse,
    appliedFilters[filterId],
  )
    .then((res) => {
      if (res) {
        const response = res;
        if (isLazyLoad) {
          response.FilterResponse = appliedFilters[filterId].FilterResponse;
        }
        dispatch(Actions.filterLoadSuccess(filterId, response));
      } else {
        dispatch(Actions.filterLoadFailure(filterId));
      }
    });
};

const handleReportDataLoadRequest = (
  dispatch: Dispatch, filterConfig: Array<ReportResponseModel.IFilterConfig>,
  filters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>, reportId: string,
  sort: Array<ReportResponseModel.ISortedField>, page: ReportResponseModel.IPage,
  prevAppliedFilters:any, columns: Array<ReportResponseModel.IColumn>,
  activeDimensions: Array<ReportReduxModel.IDimension>, measures: Array<ReportResponseModel.IColumn>,
  selectedReportViewItem:string,
) => {
  let queryParams;
  const data = {
    ReportId: reportId,
    Filters: {},
    FiltersMetadata: {},
    Sort: sort,
    Page: page,
    Columns: getColumns(columns, activeDimensions, measures),
    SelectedMeasure: '',
    SelectedRow: [],
    PersonalisedViewId: selectedReportViewItem,
  } as ReportFetchCriteria;
  if (filterConfig && !isEmpty(filterConfig)) {
    data.Filters = filterConfig.reduce((acc: ObjModel.Obj, item) => {
      // Remove Disabled filters
      if (filters[item.ID] && !filters[item.ID].Disabled) {
        if (item.Type === FilterType.UserMultiSelect) {
          acc[item.ID] = filters[item.ID].FilterResponse.UserIds;
          data.FiltersMetadata.UserDropdownSelectType = filters[item.ID].FilterResponse.Type;
        } else {
          acc[item.ID] = filters[item.ID].FilterResponse;
        }
      }
      return acc;
    }, {});
  }

  // Flag is added to ignore personalisation in case of user drilldown
  if (prevAppliedFilters.length) {
    queryParams = {
      params: {
        noPersonalisation: 1,
      },
    };
  }
  new ReportService().getReportData(data, queryParams)
    .then((res) => dispatch(Actions.reportDataLoadSuccess(res)))
    .catch(() => dispatch(Actions.reportDataLoadFailure()));
};

const handleDrilldownChange = (
  dispatch: Dispatch, filterConfig: Array<ReportResponseModel.IFilterConfig>, filterId: string,
  value: any, variables: AuthReduxModel.IVariables,
) => {
  if (filterConfig && !isEmpty(filterConfig)) {
    const filter = filterConfig.find((item) => item.ID === filterId);
    if (filter) {
      let filterValue = value;
      switch (filter.Type) {
        case FilterType.GroupMultiSelect:
        case FilterType.CustomDefinedMultiSelect:
        case FilterType.LSQMetadataMultiSelect:
        case FilterType.UserMultiSelect:
          if (value && !isArray(value)) {
            filterValue = [filterValue];
          }
          break;
        default:
          break;
      }
      dispatch(Actions.filterChange(filterId, filterValue, variables, true));
    }
  }
};

const handleReorderColumns = (
  dispatch: Dispatch, columns: Array<ReportResponseModel.IColumn>,
) => {
  dispatch(Actions.saveColumnsOrder(columns));
};

const handleSaveColumnsOrderRequest = (
  reportId: string, columns: Array<ReportResponseModel.IColumn>, SelectedMeasure: string, IsDrilldown: boolean, ColumnAliases: Array<string>,
) => {
  const data = {
    ReportId: reportId,
    ColumnNames: IsDrilldown ? ColumnAliases : columns.map((item) => item.Name),
    SelectedMeasure,
  };
  new ReportService().saveColumnsOrder(data);
};

const handleSearchDropdownOptions = (
  dispatch: Dispatch, filterId: string, filterValue: any, variables: AuthReduxModel.IVariables,
) => {
  dispatch(Actions.filterLoadRequest(filterId, variables, true, filterValue));
};

const handleDynamicDimensionsLoadRequest = (dispatch: Dispatch, reportId: string, selectedMeasure?: string) => {
  if (selectedMeasure) {
    new ReportService().getDynamicDimensions(reportId, selectedMeasure)
      .then((res) => dispatch(Actions.dynamicDimensionsLoadSuccess(res, selectedMeasure)))
      .catch(() => dispatch(Actions.dynamicDimensionsLoadFailure()));
  } else {
    new ReportService().getDynamicDimensions(reportId)
      .then((res) => dispatch(Actions.dynamicDimensionsLoadSuccess(res)))
      .catch(() => dispatch(Actions.dynamicDimensionsLoadFailure()));
  }
};

const handleAutodrilldownReportDataLoadRequest = (
  dispatch: Dispatch, reportId: string, autodrilldownData: ReportReduxModel.IAutoDrilldownData,
  filterConfig: Array<ReportResponseModel.IFilterConfig>, columns: Array<ReportResponseModel.IColumn>,
  activeDimensions: Array<ReportReduxModel.IDimension>, measures: Array<ReportResponseModel.IColumn>,
  activeFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>,
) => {
  const groupedDimensions: SelectedRow[] = [];
  activeDimensions.map((dim) => {
    if (dim.Applied !== 0) {
      const name = dim.BuilderConfig && dim.BuilderConfig.IsDynamicField ? dim.BuilderConfig.Alias : dim.Name;
      const entity = dim.BuilderConfig ? dim.BuilderConfig.Entity : '';
      const column = columns.find((col) => (
        col.BuilderConfig && col.BuilderConfig.IsDynamicField
          ? col.BuilderConfig.Alias === name
          : col.Name === name));
      const selectedRow: SelectedRow = {
        Alias: name,
        Entity: entity,
        Value: autodrilldownData.selectedRowData[name] === DimensionNoValue ? '' : autodrilldownData.selectedRowData[name],
        IsMasked: column.Props.IsMasked,
      };
      groupedDimensions.push(selectedRow);
    }
    return null;
  });
  // autodrilldownData.drilldownFields are fields with Mode not equal to 0
  const groupedDrilldownFields : IColumn[] = autodrilldownData.drilldownFields
  && autodrilldownData.drilldownFields.filter((dim) => dim.Props
  && dim.Props.Dimension && dim.Props.Dimension.Applied === DimensionStatus.Applied);
  const fetchDrillDownRequest: any = {
    ReportId: reportId,
    Filters: {},
    Page: autodrilldownData.page,
    FiltersMetadata: {},
    Sort: autodrilldownData.sort,
    Columns: getColumns(columns, activeDimensions, measures),
    SelectedMeasure: autodrilldownData.selectedMeasure,
    SelectedRow: groupedDimensions,
    DrilldownColumns: groupedDrilldownFields,
  };
  fetchDrillDownRequest.Filters = !!filterConfig && !isEmpty(filterConfig) && filterConfig.reduce((acc: ObjModel.Obj, item) => {
    // Remove Disabled filters
    if (activeFilters[item.ID] && !activeFilters[item.ID].Disabled) {
      if (item.Type === FilterType.UserMultiSelect) {
        acc[item.ID] = activeFilters[item.ID].FilterResponse.UserIds;
        fetchDrillDownRequest.FiltersMetadata.UserDropdownSelectType = activeFilters[item.ID].FilterResponse.Type;
      } else {
        acc[item.ID] = activeFilters[item.ID].FilterResponse;
      }
    }
    return acc;
  }, {});
  new ReportService().getFetchDrilldownReport(fetchDrillDownRequest)
    .then((res) => {
      dispatch(Actions.autodrilldownReportDataLoadSuccess(res));
      if (!autodrilldownData.drilldownFields) {
        dispatch(Actions.dynamicDimensionsLoadRequest(autodrilldownData.selectedMeasure));
      }
    })
    .catch(() => dispatch(Actions.autodrilldownReportDataLoadFailure()));
};

const getColumns = (
  columns: ReportResponseModel.IColumn[], activeDimensions: ReportReduxModel.IDimension[],
  measures: ReportResponseModel.IColumn[],
) => {
  if (!isEmpty(columns) && !isEmpty(activeDimensions)) {
    const newColumns = getColumnsForActiveDimensions(columns, activeDimensions, measures);
    return newColumns;
  }
  return columns;
};

const handleExportDataLoadRequest = (
  dispatch: Dispatch, filterConfig: Array<ReportResponseModel.IFilterConfig>,
  filters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>, reportId: string,
  sort: Array<ReportResponseModel.ISortedField>, page: ReportResponseModel.IPage,
  columns: Array<ReportResponseModel.IColumn>, activeDimensions: Array<ReportReduxModel.IDimension>,
  measures: Array<ReportResponseModel.IColumn>,
  autodrilldownData:ReportReduxModel.IAutoDrilldownData, fileType: string,
) => {
  const queryParams = fileType;
  let data:ReportFetchCriteria;
  if (!autodrilldownData) {
    data = {
      ReportId: reportId,
      Filters: {},
      FiltersMetadata: {},
      Sort: sort,
      Page: page,
      Columns: getColumns(columns, activeDimensions, measures),
      SelectedMeasure: '',
      SelectedRow: [],
    } as ReportFetchCriteria;
  } else {
    const groupedDimensions: SelectedRow[] = [];
    activeDimensions.map((dim) => {
      if (dim.Applied !== 0) {
        const name = dim.BuilderConfig && dim.BuilderConfig.IsDynamicField ? dim.BuilderConfig.Alias : dim.Name;
        const entity = dim.BuilderConfig ? dim.BuilderConfig.Entity : '';
        const column = columns.find((col) => (
          col.BuilderConfig && col.BuilderConfig.IsDynamicField
            ? col.BuilderConfig.Alias === name
            : col.Name === name));
        const selectedRow: SelectedRow = {
          Alias: name,
          Entity: entity,
          Value: autodrilldownData.selectedRowData[name] === DimensionNoValue ? '' : autodrilldownData.selectedRowData[name],
          IsMasked: column.Props.IsMasked,
        };
        groupedDimensions.push(selectedRow);
      }
      return null;
    });
    // autodrilldownData.drilldownFields are fields with Mode not equal to 0
    const groupedDrilldownFields : IColumn[] = autodrilldownData.drilldownFields
  && autodrilldownData.drilldownFields.filter((dim) => dim.Props
  && dim.Props.Dimension && dim.Props.Dimension.Applied === DimensionStatus.Applied);
    data = {
      ReportId: reportId,
      Filters: {},
      Page: autodrilldownData.page,
      FiltersMetadata: {},
      Sort: autodrilldownData.sort,
      Columns: getColumns(columns, activeDimensions, measures),
      SelectedMeasure: autodrilldownData.selectedMeasure,
      SelectedRow: groupedDimensions,
      DrilldownColumns: groupedDrilldownFields,
    } as ReportFetchCriteria;
  }
  data.Filters = !!filterConfig && !isEmpty(filterConfig) && filterConfig.reduce((acc: ObjModel.Obj, item) => {
    // Remove Disabled filters
    if (filters[item.ID] && !filters[item.ID].Disabled) {
      if (item.Type === FilterType.UserMultiSelect) {
        acc[item.ID] = filters[item.ID].FilterResponse.UserIds;
        data.FiltersMetadata.UserDropdownSelectType = filters[item.ID].FilterResponse.Type;
      } else {
        acc[item.ID] = filters[item.ID].FilterResponse;
      }
    }
    return acc;
  }, {});
  new ReportService().getExportData(data, queryParams)
    .then((res) => dispatch(Actions.exportDataLoadSuccess(res)))
    .catch((res) => {
      dispatch(Actions.exportDataLoadFailure(res?.response?.data));
    });
};

const handleExportListLoadRequest = (dispatch: Dispatch) => {
  new ReportService().getExportListData()
    .then((res) => {
      dispatch(Actions.exportListSuccess(res));
    }).catch(() => {
      dispatch(Actions.exportListFailure());
    });
};

const handleExportUsageStatusRequest = (dispatch: Dispatch) => {
  new ReportService().getExportUsageStatus()
    .then((res) => {
      dispatch(Actions.exportUsageStatusSuccess(res));
    }).catch(() => {
      dispatch(Actions.exportUsageStatusFailure());
    });
};

const handleExportListLoadByIdsRequest = (dispatch: Dispatch, exportIds:Array<string>) => {
  new ReportService().getExportListDataByIds(exportIds)
    .then((res) => {
      const isAnyFailedExport = !!res.find((exp) => exp.Status === ExportStatus.Failed);
      // when there is any failed export we need to get the latest daily ExportUsageStatus
      if (isAnyFailedExport) handleExportUsageStatusRequest(dispatch);

      dispatch(Actions.exportListByIdsSuccess(res));
    }).catch(() => {
      dispatch(Actions.exportListByIdsFailure());
    });
};

const handleRetryExportRequest = (dispatch: Dispatch, exportID: string) => {
  new ReportService().getRetryExportData({ ExportID: exportID })
    .then((res) => dispatch(Actions.retryExportSuccess(res, exportID)))
    .catch(() => dispatch(Actions.retryExportFailure(exportID)));
};

const handleDataRefreshDetailsLoadRequest = (dispatch: Dispatch, reportId: string, isPolling?: boolean) => {
  new ReportService().getDataRefreshDetails({ reportId })
    .then((res) => dispatch(Actions.dataRefreshDetailsLoadSuccess(res, isPolling)))
    .catch(() => dispatch(Actions.dataRefreshDetailsLoadFailure()));
};
