import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import thunk from 'redux-thunk';
import AuthReducer from 'redux/user-auth/reducer';
import ReportReducer from 'redux/report/reducer';
import ReportMiddleware from 'redux/report/middleware';
import ReportListReducer from 'redux/report-list/reducer';
import ReportListMiddleware from 'redux/report-list/middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReportViewReducer from './report-view/report-view.reducer';

// createStore: creates a redux store(global) for app
// combineReducer: helper function turns an object whose values are different reducing functions
// into a single reducing function you can pass to createStore function
// AuthReducer, ReportReducer:  functions that take the current state and an action as arguments,
// and return a new state result
// applyMiddleware: combines all middleware
// logger: passed as argument to applymiddleware, each action is passes through logger. loggers log
// each action in console.

const middlewares = [thunk, ReportMiddleware, ReportListMiddleware];
const isDevelop = process.env.NODE_ENV !== 'production';

let middleware = applyMiddleware(...middlewares);

if (isDevelop) {
  middleware = composeWithDevTools(middleware);
}

export default createStore(
  combineReducers({
    auth: AuthReducer,
    report: ReportReducer,
    reportList: ReportListReducer,
    reportView: ReportViewReducer,
  }),
  compose(middleware),
);
