import React from 'react';
import { Messages } from 'core/constants/visualizations';
import StyledNoData from 'components/common/NoData/no-data.styled';
import NoDataIcon from 'components/common/svg/Nodata/nodataicon.svg';

const NoDataScreen = () => (
  <StyledNoData>
    <NoDataIcon
      width={333}
      height={83}
    />
    <span className="se-no-data-header"> No Data Available</span>
    <span className="se-no-data-text">{Messages.NoData}</span>
  </StyledNoData>
);

export default NoDataScreen;
