import styled from 'styled-components';

export default styled.div`
flex: 1;

.se-sidebar-header {
    position: sticky;
    top: 0;
    background: #fff;

    .se-title-cn {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .se-title {
        font: 13px SemiBold;
        color: var(--secondaryColor);
        margin: 0 4px 0 4px;
    }
    
    .se-desc {
        font-size: 11px;
        color: #919EAB;
        margin-top: 8px;
    }

    .se-toggler {
        position: absolute;
        right: 0;
        button {
            border: 0;
            padding: 0;
        }
    }

    .se-toggler.inactive {
        transform: rotate(180deg);
    }

    .se-grouping-counter {
        font-size: 11px;
        color: var(--paleSkyColor);
        background-color: var(--grayCounterBackgroundColor);
        border-radius: 40px;
        padding: 1px 6px;
        height: 17px;

        &.danger {
            color: var(--dangerColor);
        }
    }
    .se-info-icon {
        cursor: pointer;
        margin-right: 4px;
        }
    .se-drilldown-icon {
        display: flex;
        transform: rotate(180deg);
    }
        
}

.se-add-padding {
    padding-bottom: 12px;
}
`;
