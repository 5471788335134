/*
This component should handle date ranges
It will expose different functions to handle date ranges to filter things
It will be wrapper over materialui-daterange-picker
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import 'react-dates/initialize';
import DatePicker from 'components/common/DatePicker/date-picker.component';
import { dateRangeShowcase } from 'core/utils/date.util';
import { IProps } from 'components/common/DatePicker/DateRange/date-range.d';
import StyledContainer from 'components/common/DatePicker/DateRange/date-range.styled';
import CalendarIcon from 'components/common/svg/Calendar/calendar.svg';
import CustomRange from 'components/common/DatePicker/DateRange/CustomRange/custom-range.component';
import { IDateRangePicker } from 'core/models/filter';
import { IRelativeDateRanges } from 'core/constants/date';
import ismobile from 'is-mobile';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';

const DateRange = (props: IProps) => {
  const {
    config, value, onChangeAbsoluteRange, onChangeRelativeRange, hasCustomRange,
    relativeValue, addBorderClass, removeBorderClass,
  } = props;
  const [open, setOpen] = useState(false);
  const [top, setTop] = useState(null);
  const [right, setRight] = useState(null);
  const togglerRef = React.useRef(null);
  const datePickerRef = React.useRef(null);
  const datePickerReference = React.useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (open && togglerRef.current && !togglerRef.current.contains(e.target)
        && datePickerRef.current && !datePickerRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [open]);

  let inititalValue = {
    startDate: moment(),
    endDate: moment(),
  };
  let dateLabel = config.Placeholder || '';

  if (value && moment(value.From).isValid() && moment(value.To).isValid()) {
    inititalValue = {
      startDate: moment(value.From),
      endDate: moment(value.To),
    };
    dateLabel = dateRangeShowcase(value.From, value.To);
  }

  const toggle = () => {
    // for mobile view height will be passed as props like  verticalHeight={600}
    if (!ismobile()) {
      getDatePickerPosition();
    }

    setOpen(!open);
  };

  // this function is for placing the calender based on its position
  const getDatePickerPosition = () => {
    const viewportOffsetToggle = togglerRef.current.getBoundingClientRect();
    const viewPortHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    let datePickerTop = null;
    let datePickerRight = 64;

    if (viewportOffsetToggle.top > 350) {
      // we have defined height 343px for datepicker through css
      datePickerTop = viewportOffsetToggle.top - 350;
    } else if (viewportOffsetToggle.bottom + 350 > viewPortHeight) {
      datePickerTop = viewportOffsetToggle.top - (viewportOffsetToggle.bottom + 350 - viewPortHeight);
      datePickerRight = viewportOffsetToggle.width + 75;
    } else {
      datePickerTop = viewportOffsetToggle.bottom;
    }
    setTop(datePickerTop);
    setRight(datePickerRight);
  };

  const handleAbsoluteRangeChange = (range: IDateRangePicker) => {
    onChangeAbsoluteRange(
      config.Key,
      range,
    );
  };

  const handleRelativeRangeChange = (range: IRelativeDateRanges) => {
    toggle();
    onChangeRelativeRange(
      config.Key,
      range,
    );
  };

  return (

    <StyledContainer>
      <div
        className="se-date-range-cn"
      >
        <button
          ref={togglerRef}
          type="button"
          onClick={toggle}
          className={`se-date-range-toggler ${addBorderClass} ${removeBorderClass} ${PendoClassNames.DateRangeFilter}`}
          disabled={config.Disabled}
        >
          <div
            className="se-date-range-label"
          >
            {dateLabel}
          </div>
          <span
            className="se-date-range-icon"
          >
            <CalendarIcon
              width={18}
              height={18}
              fill="var(--arrowIcon)"
            />
          </span>
        </button>

        <div
          ref={datePickerRef}
          className="se-date-range-selector-cn"
          style={{
            top: `${top}px`,
            right: `${right}px`,
          }}
        >
          { open && (
            <>
              <DatePicker toggle={toggle} initialDateRange={inititalValue} onChangeAbsoluteRange={handleAbsoluteRangeChange} autofocus />
              {
                  hasCustomRange && (
                  <div
                    className="se-custom-ranges se-custom-scrollbar"
                    ref={datePickerReference}
                  >
                    <CustomRange
                      selected={relativeValue}
                      onChange={handleRelativeRangeChange}
                    />
                  </div>
                  )
                }
            </>
          )}
        </div>
      </div>
    </StyledContainer>
  );
};

export default DateRange;
