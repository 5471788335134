import React from 'react';
import StyledComponent from 'components/feature/ReportListing/ReportSection/ReportCard/report-card.style';

const ReportCardLoader = () => (
  <StyledComponent>
    <div
      className="se-report-card se-shimmer-cn"
    >
      <div
        className="se-shimmer"
      />
    </div>
  </StyledComponent>
);

export default ReportCardLoader;
