/* eslint-disable no-shadow */

export enum DroppableTypes {
  Dimensions = 'Dimensions',
  Measures = 'Measures',
}

// IDs used by <Droppable /> for identifying each droppable section.
export enum DroppableIds {
  AvailableFields = 'AvailableFields',
  RowGrouping = 'RowGrouping',
  ColumnGrouping = 'ColumnGrouping',
  DrilldownGrouping = 'DrilldownGrouping',
  Measures = 'Measures',
}

export enum PendoClassNames {
  AvailableFieldsBtn = 'se-track-additional-fields-btn',
  AvailableFiltersBtn = 'se-track-additional-filters-btn',
  MeasuresBtn = 'se-track-measures-btn',
  Filters = 'se-track-filters',
  Setting = 'se-track-visualization-settings',
  ReportViews ='se-track-report-view',
  ZeroValues = 'se-track-zero-values',
  AddField ='se-track-add-field',
  AddFilter ='se-track-add-filter',
  DateRangeFilter ='se-track-date-range',
  RemoveFilter = 'se-track-remove-filter',
  ResetFilters = 'se-track-reset-filters',
  ApplyFilters = 'se-track-apply-filters',
  PreviousPage ='se-track-prev-page',
  NextPage ='se-track-next-page',
  SortReport ='se-track-sort-report',
  Pagination = 'se-track-pagination',
  DropDownFilters ='se-track-dropdown-filters',
  UserDrilldown = 'se-track-user-drilldown',
  SortData = 'se-track-sort-data',
  BackBtn = 'se-track-back-btn',
  ApplySettings = 'se-track-apply-settings',
  RemoveField= 'se-track-remove-field',
  ShowZeroValues ='se-track-show-zero-values',
  NewReportViewButton = 'se-track-new-view-btn',
  DeleteReportViewButton = 'se-track-delete-view-btn',
  InfoReportViewIcon ='se-track-info-view-btn',
  ExportButton = 'se-track-quick-export',
  QuickCsvExport = 'se-track-quick-export-csv',
  QuickExcelExport = 'se-track-quick-export-xlsx',
  LargeExportButton = 'se-track-large-export',
  ExportPanel = 'se-track-export-panel-export',
  DownloadFile = 'se-track-export-panel-download',
  DataRefreshButton = 'se-track-data-refresh-button',
  ShareReportView = 'se-track-share-report-view',
  StopShareReportView = 'se-track-stop-share-report-view',
  PngChartExport = 'se-track-png-chart-export',
  JpgChartExport = 'se-track-jpg-chart-export',
  PdfChartExport = 'se-track-pdf-chart-export',
  RowGrouping = 'se-track-expand-row-grouping-fields',
  ColGrouping = 'se-track-expand-col-grouping-fields',
  ExpandDrilldown = 'se-track-expand-drilldown-fields'

}

export const GroupingDragDropPlaceholder = 'Add fields for grouping';
export const dimensionItemHeightPx = 32;
export const dimensionItemBottomMarginPx = 8; // TODO: Use these values in respective CSS.
export const autodrilldownBreadcrumbsTooltip = 'Click here to go back';
export const mouseEnterDelay = 1.5;
