import React, { useEffect, useState } from 'react';
import StyledCircularLoadButtonContainer from 'components/common/CircularLoadButton/circular-load-button.style';
import { dataRefreshDetailsLoadRequest } from 'redux/report/actions';
import Reload from 'assets/images/Reload.svg';
import { useDispatch } from 'react-redux';
import { useInterval } from 'usehooks-ts';
import { Timers } from 'core/constants/report';
import { IProps } from './circular-load-button-types';

export default function CircularLoadButton(props: IProps) {
  const {
    uniqKey, totalTimeInterval, backgroundColor, doneGlowHighState, progressColor, setIsRefreshAllowed,
    percentageCompletion, isRefreshReq, reportId, pauseAt, startPollingAt,
  } = props;
  // if secondsTillCompletion is 100%, what is one percent in seconds
  const onePercentSecond = (totalTimeInterval * 60 * 1000) / 100;
  // 0 - 100%
  const [progress, setProgress] = useState(percentageCompletion);
  const dispatch = useDispatch();

  useEffect(() => {
    if (progress === 100) {
      setIsRefreshAllowed(true);
    }
  }, [progress]);

  // circular progress loader
  useInterval(
    () => {
      setProgress((prev) => prev + 1);
    },
    // Delay in milliseconds or null to stop it
    progress < pauseAt ? onePercentSecond : null,
  );

  // polling api call
  useInterval(
    () => {
      dispatch(dataRefreshDetailsLoadRequest(reportId, true));
    },
    // Delay in milliseconds or null to stop it
    progress >= (startPollingAt || pauseAt) && !isRefreshReq ? Timers.Minute : null,
  );

  // once is refresh required is true we want to show refresh icon by setting progress to 100
  useEffect(() => {
    if (isRefreshReq) {
      setProgress(100);
    }
  }, [isRefreshReq]);

  return (
    <StyledCircularLoadButtonContainer
      doneGlowHighState={doneGlowHighState}
      primaryBackground={progressColor}
      uniqKey={uniqKey}
    >
      <div className="se-circular-load-btn-container">
        <ShowContent progress={progress} />
        <div
          className="se-circular-load-btn-outer"
          //  inline styles to improve performance
          style={{
            background: `conic-gradient(${progressColor || '#006FD6'}  ${`${progress}%` || '0%'}, ${backgroundColor || '#E8E9EA'} ${`${progress}%` || '0%'})`,
          }}
        />
      </div>
    </StyledCircularLoadButtonContainer>
  );
}

const ShowContent = ({ progress }: { progress: number}) => {
  if (progress === 100) {
    return <ReloadContent />;
  }
  return <div className="se-circular-load-btn-inner" />;
};

const ReloadContent = () => (
  <div className="se-circular-load-btn-inner done">
    <img src={Reload} alt="reload" />
  </div>
);
