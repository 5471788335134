import React, { useState } from 'react';
import CircleArrow from 'components/common/svg/CircleArrow/circle-arrow.svg';
import StyledContainer from 'components/common/SideNavbar/side-navbar.style';
import SideNavbarSectionLoader from 'components/common/SideNavbar/SideNavbarSection/side-navbar-section-loader';

const SideNavBarComponent = () => {
  const [expand, setExpand] = useState(true);

  const toggleExpand = () => {
    setExpand(!expand);
  };

  return (
    <StyledContainer>
      <>
        {
          !expand && (
          <div
            className="se-divider"
          />
          )
        }
        <div
          className={`se-side-navbar ${expand ? '' : 'collapse'}`}
        >
          <div
            className="se-side-navbar-showcase"
          >
            {
              [1, 2].map((item) => (
                <SideNavbarSectionLoader
                  key={item}
                />
              ))
            }
          </div>
          <button
            type="button"
            className="se-side-navbar-toggler"
            onClick={toggleExpand}
          >
            <CircleArrow
              width={24}
              height={24}
            />
          </button>
        </div>
      </>
    </StyledContainer>
  );
};

export default SideNavBarComponent;
