import React from 'react';
import { IProps } from 'components/feature/ReportListing/ReportHeader/report-header.d';
import StyledComponent from 'components/feature/ReportListing/ReportHeader/report-header.style';
import StoreConnector from 'components/feature/ReportListing/ReportHeader/report-header.redux';
import ReportSorting from 'components/feature/ReportListing/ReportHeader/ReportSorting/report-sort.component';

const ReportHeader = (props: IProps) => {
  const { reduxState } = props;
  const { reportList } = reduxState;
  const { selectedReportType } = reportList;

  return (
    <StyledComponent>
      <div
        className="se-report-header"
      >
        <div
          className="se-report-header-label"
        >
          {selectedReportType.name}
        </div>
        <div className="se-report-header-content">
          <div>
            <ReportSorting />
          </div>
        </div>
      </div>
    </StyledComponent>
  );
};

export default StoreConnector()(ReportHeader);
