import React from 'react';
import Tooltip from 'rc-tooltip';
import StyledPaginationContainer from 'components/common/Pagination/pagination.styled';
import { components } from 'react-select';
import ArrowDownIcon from 'components/common/svg/ArrowDown/arrow-down.svg';
import { pageSizeOptions } from 'core/constants/pagination';
import { IProps } from 'components/common/Pagination/pagination.d';
import ReactSelect from 'components/common/select/ReactSelect/react-select.component';
import NextIcon from 'components/common/svg/Next/next.svg';
import PrevIcon from 'components/common/svg/Prev/prev.svg';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <ArrowDownIcon
      width={20}
      height={15}
    />
  </components.DropdownIndicator>
);

const Pagination = (props: IProps) => {
  const {
    page, hasNextPage, onPaginationChange, isDisabled, recordsRetrieved,
  } = props;
  const hasPrev = page.PageIndex > 1;
  const hasNext = hasNextPage;

  const onPaginationChangeHandler = (...params:any) => {
    const [key, value] = params;
    onPaginationChange(key, value);
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <StyledPaginationContainer>
      <div
        className="se-page-size-cn"
      >
        <div
          className="se-pagination-label"
        >
          Records Per Page:
        </div>
        <div className={isDisabled ? 'se-pagination-disabled' : ''}>
          <ReactSelect
            config={{
              Key: 'PageSize',
              Meta: {
                ClassName: 'se-pagination-dropdown',
                MenuPlacement: 'top',
                Options: pageSizeOptions,
                CloseOnSelect: true,
                AppliedOnClose: false,
                Checkbox: false,
                Placeholder: '',
                Searchable: false,
                DropdownIndicator: true,
                Clearable: false,
                Components: {
                  DropdownIndicator,
                },
              },
            }}
            value={page.PageSize}
            isDisabled={isDisabled}
            onChange={onPaginationChangeHandler}
            pendoClassName={PendoClassNames.Pagination}
          />
        </div>
        { (!!recordsRetrieved) && (
        <div
          className="se-records-label"
        >
          {`${recordsRetrieved} Records Retrieved`}
        </div>
        )}
      </div>
      <div
        className="se-page-index-cn"
      >
        <div
          className="se-page-index-counter-cn"
        >
          <div
            className="se-pagination-label"
          >
            <span>
              Page:
            </span>
          </div>
          <div className="se-page-index">
            {page.PageIndex}
          </div>
        </div>
        <div
          className="se-page-index-controller-cn"
        >
          <Tooltip
            placement="top"
            overlay={<span>Prev</span>}
            mouseEnterDelay={1.5}
          >
            <button
              type="button"
              className={`se-btn-prev ${PendoClassNames.PreviousPage}`}
              disabled={!hasPrev}
              onClick={() => {
                onPaginationChange(
                  'PageIndex',
                  page.PageIndex - 1,
                );
              }}
            >
              <PrevIcon
                width={24}
                height={24}
                fill={hasPrev ? 'var(--paginationPrev)' : 'var(--paginationNext)'}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="top"
            overlay={<span>Next</span>}
            mouseEnterDelay={1.5}
          >
            <button
              type="button"
              className={`se-btn-next ${PendoClassNames.NextPage}`}
              disabled={!hasNext}
              onClick={() => {
                onPaginationChange(
                  'PageIndex',
                  page.PageIndex + 1,
                );
              }}
            >
              <NextIcon
                width={24}
                height={24}
                fill={hasNext ? 'var(--paginationPrev)' : 'var(--paginationNext)'}
              />
            </button>
          </Tooltip>
        </div>
      </div>
    </StyledPaginationContainer>

  );
};

export default Pagination;
