import React from 'react';

type Props = {
    width: number;
    height: number;
    fill?: string;
}

const EmptyExportIcon = ({
  width, height, fill,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 26.86 28.516">
    <defs>
      <filter id="Line_2987" x="0" y="0" width={width} height={height} filterUnits="userSpaceOnUse">
        <feOffset dx="3" />
        <feGaussianBlur result="blur" />
        <feFlood floodColor="#f4f6f8" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_23528" data-name="Group 23528" transform="translate(-5749.32 -14050.742)">
      <g id="Group_22653" data-name="Group 22653" transform="translate(5751.75 14053)">
        <g id="Group_19531" data-name="Group 19531">
          <g id="Export">
            <g id="add_chart_black_24dp">
              <path id="Path_7623" data-name="Path 7623" d="M0,0H24V24H0Z" fill="#f4f6f8" />
              <path id="Path_7624" data-name="Path 7624" d="M6.03,10.112H8.045v7.123H6.03Zm8.06,3.053h2.015v4.07H14.09ZM10.06,7.06h2.015V17.235H10.06Zm10.075.01V4.018H18.12V7.07H15.1V9.105H18.12v3.053h2.015V9.105h3.023V7.07ZM18.12,19.28H4.015V5.035h12.09V3H4.015A2.031,2.031,0,0,0,2,5.035V19.28a2.031,2.031,0,0,0,2.015,2.035H18.12a2.031,2.031,0,0,0,2.015-2.035V14.193H18.12Z" transform="translate(-0.105 -0.158)" fill="#454f5b" />
              <rect id="Rectangle_6406" data-name="Rectangle 6406" width="4.725" height="10.631" transform="translate(16.63 2.362)" fill="#f4f6f8" />
              <rect id="Rectangle_6407" data-name="Rectangle 6407" width="9.45" height="5.906" transform="translate(14.268 6.353)" fill="#f4f6f8" />
            </g>
            <g id="reply_black_24dp" transform="translate(13.578 2.842)">
              <path id="Path_7625" data-name="Path 7625" d="M9.475,0H0V9.475H9.475Z" fill="#f4f6f8" />
              <path id="Path_7626" data-name="Path 7626" d="M7.416,6.627V5l2.81,2.847-2.81,2.847V9.027A4.938,4.938,0,0,0,3,11.1,5.131,5.131,0,0,1,7.416,6.627Z" transform="translate(-1.875 -3.126)" fill="#454f5b" />
            </g>
          </g>
        </g>
      </g>
      <g transform="matrix(1, 0, 0, 1, 5749.32, 14050.74)" filter="url(#Line_2987)">
        <line id="Line_2987-2" data-name="Line 2987" x2="22" y2="27" transform="translate(0.93 0.76)" fill="#f4f6f8" stroke="#454f5b" strokeWidth="2.4" />
      </g>
    </g>
  </svg>

);

EmptyExportIcon.defaultProps = {
  fill: '#637381',
};

export default EmptyExportIcon;
