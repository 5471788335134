import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const BarChartIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 24 24"
  >
    <g
      id="Bar_Chart"
      data-name="Bar Chart"
      transform="translate(-93 -343)"
    >
      <rect
        id="Rectangle_6370"
        data-name="Rectangle 6370"
        width="24"
        height="24"
        transform="translate(93 343)"
        fill="#fff"
      />
      <path
        id="Path_7594"
        data-name="Path 7594"
        d="M5,9.2H8V19H5ZM10.6,5h2.8V19H10.6Zm5.6,8H19v6H16.2Z"
        transform="translate(117 343) rotate(90)"
        fill={fill}
      />
    </g>
  </svg>
);

BarChartIcon.defaultProps = {
  fill: '#46bfc9',
};

export default BarChartIcon;
