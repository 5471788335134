import styled from 'styled-components';

const StyledContainer = styled.div`

    .se-job-details{
        display: inherit;

        .se-job-details-cn{ 
            display: flex;
            
            .se-divider-cn {
            height: 100% !important; 
            width: 0px;
            border-left: none;
            margin: 0px 16px;

            .se-job-details-divider {
                height: 34px;
                margin-bottom: 22px;
                border: 1px solid #63738180;
                border-left: none;
            }
            .se-job-example-divider {
                height: 34px;
                border: 1px solid #63738180;
                border-left: none
            }
            }

            .se-job-details-content-cn {
                    .se-blue-text {
                    color: #1172D3
                    }
                    .se-green-text {
                    color: #3BB345;
                    }
               
            .se-info-example-divider{
                    margin: 12px 0px;
                    border-top: 1px solid #63738180;
            }
            .se-example-cn {
                display: flex;
                word-wrap: break-word;
                font: 11px Regular;
                color: #ADBAC7;
                max-width: 250px;
            }
        }
        }

        .se-job-completed-cn {
            display: block;
            text-align: center;
            max-width: 190px;
            font: 11px Regular;
            .se-job-completed-text {
            margin-top: 12px;
            margin-bottom: 4px;
            }
        }
    }

    .se-job-details::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 3%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #212B36 transparent transparent transparent;
    }
`;
export default StyledContainer;
