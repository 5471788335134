import React from 'react';

type Props = {
    width: number,
    height: number,
    fill?: string,
}

const LSQIcon = ({ width, height, fill }: Props) => (

  <svg
    id="Group_16953"
    data-name="Group 16953"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width, height,
    }}
    viewBox="0 0 24 24"
  >
    <rect
      id="Rectangle_1390"
      data-name="Rectangle 1390"
      width="24"
      height="24"
      fill="#fff"
    />
    <g
      id="Group_8433"
      data-name="Group 8433"
      transform="translate(0.749 1)"
    >
      <rect
        id="Rectangle_1388"
        data-name="Rectangle 1388"
        width="22"
        height="22"
        transform="translate(0.252)"
        fill="#006fd6"
      />
      <rect
        id="Rectangle_1389"
        data-name="Rectangle 1389"
        width="11"
        height="11"
        transform="translate(0.252 11)"
        fill="#fff"
      />
      <path
        id="Path_452"
        data-name="Path 452"
        d="M663.843,2224,652.3,2212.268V2224Z"
        transform="translate(-652.3 -2201.745)"
        fill={fill}
      />
    </g>
  </svg>

);

LSQIcon.defaultProps = {
  fill: '#000000',
};

export default LSQIcon;
