import React from 'react';

type Props = {
  width: number;
  height: number;
}

const JpgChartIcon = ({
  width, height,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.666 1.66699H5.00001C4.5583 1.66831 4.13506 1.84436 3.82272 2.1567C3.51038 2.46904 3.33433 2.89228 3.33301 3.33399V16.667C3.33221 17.1078 3.50635 17.5309 3.8172 17.8434C4.12805 18.156 4.55021 18.3324 4.99101 18.334H15C15.4415 18.3324 15.8645 18.1562 16.1767 17.8439C16.4888 17.5316 16.6647 17.1085 16.666 16.667V6.66699L11.666 1.66699ZM14.999 16.667H4.99901V3.33399H10.833V7.49999H15L14.999 16.667Z"
      fill="#48BB91"
    />
    <path
      d="M1 9H12C12.2652 9 12.5196 9.10536 12.7071 9.29289C12.8946 9.48043 13 9.73478 13 10V14C13 14.2652 12.8946 14.5196 12.7071 14.7071C12.5196 14.8946 12.2652 15 12 15H1C0.734784 15 0.48043 14.8946 0.292893 14.7071C0.105357 14.5196 0 14.2652 0 14V10C0 9.73478 0.105357 9.48043 0.292893 9.29289C0.48043 9.10536 0.734784 9 1 9V9Z"
      fill="#48BB91"
    />
    <path
      d="M4.01079 10.2598V12.7098C4.01079 13.0498 3.91246 13.3148 3.71579 13.5048C3.51912 13.6915 3.25579 13.7848 2.92579 13.7848C2.59246 13.7848 2.32246 13.6882 2.11579 13.4948C1.91246 13.3015 1.81079 13.0315 1.81079 12.6848H2.51079C2.51412 12.8348 2.54912 12.9515 2.61579 13.0348C2.68579 13.1182 2.78579 13.1598 2.91579 13.1598C3.04246 13.1598 3.13912 13.1198 3.20579 13.0398C3.27246 12.9598 3.30579 12.8498 3.30579 12.7098V10.2598H4.01079Z"
      fill="white"
    />
    <path
      d="M7.37235 11.3398C7.37235 11.5265 7.32735 11.7015 7.23735 11.8648C7.15069 12.0282 7.01235 12.1598 6.82235 12.2598C6.63569 12.3598 6.39902 12.4098 6.11235 12.4098H5.52735V13.7498H4.82735V10.2598H6.11235C6.38235 10.2598 6.61235 10.3065 6.80235 10.3998C6.99235 10.4932 7.13402 10.6215 7.22735 10.7848C7.32402 10.9482 7.37235 11.1332 7.37235 11.3398ZM6.08235 11.8448C6.27569 11.8448 6.41902 11.8015 6.51235 11.7148C6.60569 11.6248 6.65235 11.4998 6.65235 11.3398C6.65235 10.9998 6.46235 10.8298 6.08235 10.8298H5.52735V11.8448H6.08235Z"
      fill="white"
    />
    <path
      d="M10.2993 11.3098C10.2193 11.1632 10.1093 11.0515 9.96935 10.9748C9.82934 10.8982 9.66601 10.8598 9.47935 10.8598C9.27268 10.8598 9.08935 10.9065 8.92935 10.9998C8.76935 11.0932 8.64435 11.2265 8.55435 11.3998C8.46435 11.5732 8.41935 11.7732 8.41935 11.9998C8.41935 12.2332 8.46435 12.4365 8.55435 12.6098C8.64768 12.7832 8.77601 12.9165 8.93935 13.0098C9.10268 13.1032 9.29268 13.1498 9.50935 13.1498C9.77601 13.1498 9.99435 13.0798 10.1643 12.9398C10.3343 12.7965 10.446 12.5982 10.4993 12.3448H9.29935V11.8098H11.1893V12.4198C11.1427 12.6632 11.0427 12.8882 10.8893 13.0948C10.736 13.3015 10.5377 13.4682 10.2943 13.5948C10.0543 13.7182 9.78435 13.7798 9.48435 13.7798C9.14768 13.7798 8.84268 13.7048 8.56935 13.5548C8.29935 13.4015 8.08601 13.1898 7.92935 12.9198C7.77601 12.6498 7.69935 12.3432 7.69935 11.9998C7.69935 11.6565 7.77601 11.3498 7.92935 11.0798C8.08601 10.8065 8.29935 10.5948 8.56935 10.4448C8.84268 10.2915 9.14601 10.2148 9.47935 10.2148C9.87268 10.2148 10.2143 10.3115 10.5043 10.5048C10.7943 10.6948 10.9943 10.9632 11.1043 11.3098H10.2993Z"
      fill="white"
    />
  </svg>

);

export default JpgChartIcon;
