import styled from 'styled-components';

export default styled.div`
flex: 1;
width: 100%;
height: 100%;
overflow: hidden;

.se-report-settings {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .se-zero-values {
        flex: 1;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .se-zero-values-settings {
            height: 100%;
            padding: 16px;
            background: #FFF;
            box-sizing: border-box;
            position: relative;

            .se-zero-values-settings-item {
                margin-bottom: 24px;

                :last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
`;
