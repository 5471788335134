import React, { useEffect, useState } from 'react';
import { FilterModel } from 'core/models';
import { IProps } from 'components/common/select/CustomSelect/custom-select.d';
import StyledComponent from 'components/common/select/CustomSelect/custom-select.style';

const getOptionComponent = (
  item: FilterModel.ISelectFilterOptions, isSelected: boolean,
) => (
  <div
    className={`se-cs-select-option ${isSelected ? 'active' : ''}`}
  >
    {item.label}
  </div>
);

const CustomSelect = (props: IProps) => {
  const {
    config, value, onChange,
  } = props;
  const {
    Key, Toggler, Options, RenderOption = getOptionComponent, ClassNames,
  } = config;

  const [show, setShow] = useState<boolean>(false);
  const ref = React.useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (show && ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [show]);

  const toggleMenu = () => setShow(!show);

  return (
    <StyledComponent>
      <div
        key={Key}
        className={`se-cs-select-cn ${ClassNames.Container || ''} ${show ? 'active' : ''}`}
      >
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleMenu();
          }}
          className="se-cs-select-toggler"
        >
          {Toggler}
        </button>
        {
            show && (
            <div
              ref={ref}
              className={`se-cs-select-menu ${ClassNames.Menu || ''}`}
            >
              {
                Options.map((item) => {
                  const isSelected = item.value === value;
                  return (
                    <button
                      key={item.value}
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleMenu();
                        if (!isSelected) {
                          onChange(Key, item.value);
                        }
                      }}
                      className="se-cs-select-option"
                    >
                      {RenderOption(item, isSelected)}
                    </button>
                  );
                })
            }
            </div>
            )
        }
      </div>
    </StyledComponent>
  );
};

export default CustomSelect;
