import React from 'react';

type Props = {
  width: number;
  height: number;
}

const EmptyDataIcon = ({
  width, height,
}: Props) => (
  <svg
    id="description_black_24dp_1_"
    data-name="description_black_24dp (1)"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 80 80"
  >
    <path
      id="Path_3"
      data-name="Path 3"
      d="M0,0H80V80H0Z"
      style={{
        fill: 'none',
      }}
    />
    <path
      id="Path_4"
      data-name="Path 4"
      d="M29.538,4H6V57.1H46.351V20.593H29.538ZM39.625,50.461h-26.9V43.824h26.9Zm0-19.912v6.637h-26.9V30.549Z"
      transform="translate(13.825 9.451)"
      style={{
        fill: '#919eab',
      }}
      opacity="0.3"
    />
    <path
      id="Path_5"
      data-name="Path 5"
      d="M17.275,48.585H43.824V55.24H17.275Zm0-13.31H43.824V41.93H17.275ZM37.187,2H10.637A6.666,6.666,0,0,0,4,8.655v53.24a6.637,6.637,0,0,0,6.6,6.655H50.461A6.666,6.666,0,0,0,57.1,61.894V21.965ZM50.461,61.894H10.637V8.655H33.868V25.292H50.461Z"
      transform="translate(9.451 4.725)"
      style={{
        fill: '#919eab',
      }}
    />
    <circle
      id="Ellipse_709"
      data-name="Ellipse 709"
      cx="12"
      cy="12"
      r="12"
      transform="translate(45 51)"
      style={{
        fill: '#fff',
      }}
    />
    <g id="push_pin_black_24dp" transform="translate(49 55)">
      <g id="Group_18514" data-name="Group 18514">
        <rect
          id="Rectangle_6173"
          data-name="Rectangle 6173"
          width="16"
          height="16"
          style={{
            fill: 'none',
          }}
        />
      </g>
      <g id="Group_18515" data-name="Group 18515" transform="translate(3.333 1.333)">
        <path
          id="Path_7468"
          data-name="Path 7468"
          d="M12.333,6.667V3.333H13a.669.669,0,0,0,.667-.667h0A.669.669,0,0,0,13,2H6.333a.669.669,0,0,0-.667.667h0a.669.669,0,0,0,.667.667H7V6.667a2,2,0,0,1-2,2H5V10H8.98v4.667l.667.667.667-.667V10h4.02V8.667h0A2,2,0,0,1,12.333,6.667Z"
          transform="translate(-5 -2)"
          style={{
            fill: '#ed7b24',
          }}
        />
      </g>
    </g>
  </svg>
);

export default EmptyDataIcon;
