import styled from 'styled-components';

export const selectedBackgroundColor = '#e5f0fb';

export default styled.div`
  width: 100%;
  margin-bottom: 8px;

  :last-child {
    margin-bottom: 0;
  }

  .se-single-select-item {
    display: flex;
    border: 1px solid #DFE3E8;
    padding: 5px 12px;
    background: #FFF;
    border-radius: 2px;
    cursor: pointer;

    .se-item-name {
      flex: 1;
      font-size: 13px;
      font-family: Regular;
      color: var(--headerColor);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    .se-icon-cn {
      height: 20px; // set the non-padded height of .se-single-select-item to a consistent value
    }

  }

  .se-single-select-item.selected {
    background-color: ${selectedBackgroundColor};
  }

  .se-single-select-item:hover {
    border-color: var(--onHoverBorder);
  }

  .se-single-select-item.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
