import styled from 'styled-components';

const StyledHighChartComponent = styled.div`
position: relative;
.highcharts-credits{
    display: none;
}
@media (min-width: 768px) {
  
}

@media (min-width: 1024px) {

}
`;

export default StyledHighChartComponent;
