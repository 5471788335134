import React from 'react';

type Props = {
    width: number;
    height: number;
    fill?: string;
}

const PlusIcon = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="icon-stroke" d="M3.57101 7.99897V4.42899H0V3.57204H3.57101V0H4.42899V3.57101H8V4.42899H4.42899V8L3.57101 7.99897Z" fill={fill} />
    <rect className="icon-stroke" y="3.57031" width="8" height="0.859375" fill="#006FD6" />
  </svg>

);

PlusIcon.defaultProps = {
  fill: '#006FD6',
};

export default PlusIcon;
