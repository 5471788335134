import { clone } from 'lodash';
import { APIRequestStatus } from 'core/constants/redux';
import { ReportListActionTypes } from 'core/constants/redux-action-types';
import { ReportListSortType } from 'core/constants/report';
import { ReportListReduxModel, ReportListResponseModel } from 'core/models';

const initialState: ReportListReduxModel.IState = {
  requestProcessing: {
    [ReportListActionTypes.REPORT_CATEGORIES_LOAD_REQUEST]: APIRequestStatus.Processing,
    [ReportListActionTypes.PINNED_REPORTS_LOAD_REQUEST]: APIRequestStatus.Processing,
    [ReportListActionTypes.CATEGORY_REPORTS_LOAD_REQUEST]: APIRequestStatus.Processing,
    [ReportListActionTypes.REPORT_PIN_UNPIN_REQUEST]: APIRequestStatus.Success,
    [ReportListActionTypes.REORDER_REPORT_CATEGORIES_REQUEST]: APIRequestStatus.Success,
  },
  categories: null,
  pinnedReports: null,
  categoriesReports: {},
  selectedReportType: null,
  sort: ReportListSortType.ReportNameAZ,
};

export default (
  state: ReportListReduxModel.IState = initialState, action: ReportListReduxModel.IAction,
) => {
  switch (action.type) {
    case ReportListActionTypes.REPORT_CATEGORIES_LOAD_REQUEST: {
      return getStateOnReportCategoriesLoadRequest(state);
    }

    case ReportListActionTypes.REPORT_CATEGORIES_LOAD_SUCCESS: {
      const {
        categories,
      } = action.payload as ReportListReduxModel.IReportCategoriesLoadSuccessPayload;
      return getStateOnReportCategoriesLoadSuccess(
        state,
        categories,
      );
    }

    case ReportListActionTypes.REPORT_CATEGORIES_LOAD_FAILURE: {
      return getStateOnReportCategoriesLoadFailure(state);
    }

    case ReportListActionTypes.PINNED_REPORTS_LOAD_REQUEST: {
      return getStateOnPinnedReportsLoadRequest(state);
    }

    case ReportListActionTypes.PINNED_REPORTS_LOAD_SUCCESS: {
      const {
        pinnedReports,
      } = action.payload as ReportListReduxModel.IPinnedReportsLoadSuccessPayload;
      return getStateOnPinnedReportsLoadSuccess(
        state,
        pinnedReports,
      );
    }

    case ReportListActionTypes.PINNED_REPORTS_LOAD_FAILURE: {
      return getStateOnPinnedReportsLoadFailure(state);
    }

    case ReportListActionTypes.CATEGORY_REPORTS_LOAD_REQUEST: {
      return getStateOnCategoryReportsLoadRequest(state);
    }

    case ReportListActionTypes.CATEGORY_REPORTS_LOAD_SUCCESS: {
      const {
        categoryId, categoryReports,
      } = action.payload as ReportListReduxModel.ICategoryReportsLoadSuccessPayload;
      return getStateOnCategoryReportsLoadSuccess(
        state,
        categoryId,
        categoryReports,
      );
    }

    case ReportListActionTypes.CATEGORY_REPORTS_LOAD_FAILURE: {
      return getStateOnCategoryReportsLoadFailure(state);
    }

    case ReportListActionTypes.REPORT_PIN_UNPIN_REQUEST: {
      return getStateOnReportPinUnpinRequest(state);
    }

    case ReportListActionTypes.REPORT_PIN_UNPIN_SUCCESS: {
      return getStateOnReportPinUnpinSuccess(state);
    }

    case ReportListActionTypes.REPORT_PIN_UNPIN_FAILURE: {
      return getStateOnReportPinUnpinFailure(state);
    }

    case ReportListActionTypes.SORTING_LIST_CHANGE: {
      const {
        sortType,
      } = action.payload as ReportListReduxModel.ISortingChangePayload;
      return getStateOnSortingChange(
        state,
        sortType,
      );
    }

    case ReportListActionTypes.SELECT_REPORT_TYPE: {
      const {
        id, name, isReportCategory,
      } = action.payload as ReportListReduxModel.ISelectReportTypePayload;
      return getStateOnSelectReportType(
        state,
        id,
        name,
        isReportCategory,
      );
    }

    case ReportListActionTypes.REORDER_REPORT_CATEGORIES_REQUEST: {
      return getStateOnReorderReportCategoriesRequest(state);
    }

    case ReportListActionTypes.REORDER_REPORT_CATEGORIES_SUCCESS: {
      const {
        categories,
      } = action.payload as ReportListReduxModel.IReorderReportCategoriesRequestPayload;
      return getStateOnReorderReportCategoriesSuccess(
        state,
        categories,
      );
    }

    case ReportListActionTypes.REORDER_REPORT_CATEGORIES_FAILURE: {
      return getStateOnReorderReportCategoriesFailure(state);
    }

    default:
      return state;
  }
};

const getStateOnReportCategoriesLoadRequest = (state: ReportListReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportListActionTypes.REPORT_CATEGORIES_LOAD_REQUEST] = APIRequestStatus.Processing;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnReportCategoriesLoadSuccess = (
  state: ReportListReduxModel.IState, categories: Array<ReportListResponseModel.ICategory>,
) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportListActionTypes.REPORT_CATEGORIES_LOAD_REQUEST] = APIRequestStatus.Success;
  newState.requestProcessing = newRequestProcessing;
  newState.categories = categories;
  return newState;
};

const getStateOnReportCategoriesLoadFailure = (state: ReportListReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportListActionTypes.REPORT_CATEGORIES_LOAD_REQUEST] = APIRequestStatus.Failure;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnPinnedReportsLoadRequest = (state: ReportListReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportListActionTypes.PINNED_REPORTS_LOAD_REQUEST] = APIRequestStatus.Processing;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnPinnedReportsLoadSuccess = (
  state: ReportListReduxModel.IState, pinnedReports: Array<ReportListResponseModel.IReportSection>,
) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportListActionTypes.PINNED_REPORTS_LOAD_REQUEST] = APIRequestStatus.Success;
  newState.requestProcessing = newRequestProcessing;
  newState.pinnedReports = pinnedReports;
  return newState;
};

const getStateOnPinnedReportsLoadFailure = (state: ReportListReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportListActionTypes.PINNED_REPORTS_LOAD_REQUEST] = APIRequestStatus.Failure;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnCategoryReportsLoadRequest = (state: ReportListReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportListActionTypes.CATEGORY_REPORTS_LOAD_REQUEST] = APIRequestStatus.Processing;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnCategoryReportsLoadSuccess = (
  state: ReportListReduxModel.IState, categoryId: string,
  categoryReports: Array<ReportListResponseModel.IReportSection>,
) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  const newCategoriesReports = clone(newState.categoriesReports);
  newRequestProcessing[ReportListActionTypes.CATEGORY_REPORTS_LOAD_REQUEST] = APIRequestStatus.Success;
  newState.requestProcessing = newRequestProcessing;
  newCategoriesReports[categoryId] = categoryReports;
  newState.categoriesReports = newCategoriesReports;
  return newState;
};

const getStateOnCategoryReportsLoadFailure = (state: ReportListReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportListActionTypes.CATEGORY_REPORTS_LOAD_REQUEST] = APIRequestStatus.Failure;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnReportPinUnpinRequest = (state: ReportListReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportListActionTypes.REPORT_PIN_UNPIN_REQUEST] = APIRequestStatus.Processing;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnReportPinUnpinSuccess = (state: ReportListReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportListActionTypes.REPORT_PIN_UNPIN_REQUEST] = APIRequestStatus.Success;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnReportPinUnpinFailure = (state: ReportListReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportListActionTypes.REPORT_PIN_UNPIN_REQUEST] = APIRequestStatus.Failure;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnSortingChange = (state: ReportListReduxModel.IState, sortType: ReportListSortType) => {
  const newState = clone(state);
  newState.sort = sortType;
  return newState;
};

const getStateOnSelectReportType = (
  state: ReportListReduxModel.IState, id: string, name: string, isReportCategory: boolean,
) => {
  const newState = clone(state);
  newState.selectedReportType = {
    id, name, isReportCategory,
  };
  return newState;
};

const getStateOnReorderReportCategoriesRequest = (state: ReportListReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportListActionTypes.REORDER_REPORT_CATEGORIES_REQUEST] = APIRequestStatus.Processing;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnReorderReportCategoriesSuccess = (
  state: ReportListReduxModel.IState, categories: Array<ReportListResponseModel.ICategory>,
) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportListActionTypes.REORDER_REPORT_CATEGORIES_REQUEST] = APIRequestStatus.Success;
  newState.requestProcessing = newRequestProcessing;
  newState.categories = categories;
  return newState;
};

const getStateOnReorderReportCategoriesFailure = (state: ReportListReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportListActionTypes.REORDER_REPORT_CATEGORIES_REQUEST] = APIRequestStatus.Failure;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};
