import styled from 'styled-components';

const StyledContainer = styled.div`
width: 100%;
height: 100%;
overflow: hidden;

.se-report-report-views {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .se-create-state-desc {
    width: 100%;
    font-size: 12px;
    color: #637381;
    padding: 16px;
    box-sizing: border-box;
  }

  .se-save-new-state {

    &.externalAppIntegrationStlye{
      padding-bottom: 45px;
    }

    .se-save-cancel-btn-cn {
      display: flex;
      align-items: center;

      button.se-create-state-btn{
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border: none;
        font-size: 12px;
        font-family: SemiBold;
        color: #FFFFFF;
        border-radius: 2px;
        background-color:#006FD6;

        :hover {
          background-color:#0064C0;
        }

        :active {
          background-color:#0064C0;
        }
    
        :disabled {
          color: #FFFFFF;
          background-color: #7EB6EA;
          cursor: not-allowed;
          border-width: 0px;
          svg {
          path {
            stroke: rgba(0, 111, 214, 0.5);
          }
        }
        }

        :focus {
          border: 2px solid rgba(17, 111, 214, 0.3);
          box-shadow: 0px 0px 1px #1360B5;
        }
      }

      button.se-create-state-btn.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      
      .not-allowed {
        cursor: not-allowed;
      }

      display: flex;
      button.se-cancel-btn {
        font: 12px SemiBold;
        width: 128px;
        height: 32px;
        border: 1px solid #DFE3E8;
        color: #454F5B;
        font-weight: 400;
        border-radius: 2px;

        :hover {
          background-color: #F9FAFB;
        }
      }
      button.se-save-btn {
        color: #FFFFFF;
        font: 12px SemiBold;
        background-color: #006FD6;
        border: 0px;
        width: 128px;
        height: 32px;
        margin-left: 10px;
        cursor: pointer;
        font-weight: 400;
        border-radius: 2px;
        
        :disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        :hover{
          background-color: #0064C0;
        }
      }
    }
  }

  .se-save-new-state.placed-bottom {
    margin: 0;
    .se-create-new-state-cn {
      padding: 0 16px;
    }
    .se-save-cancel-btn-cn {
      border-top: 0.3px solid #DFE3E8;
      height: 48px;
      padding: 0 16px;
    }
  }

  .se-save-new-state.placed-top {
    margin: 0 16px;
  }

  .se-report-view-list {
    width: 100%;
    height: 100%;
    padding: 1px 16px;
    box-sizing: border-box;
    overflow: overlay;
  }

  .se-bookmark-icon {
    margin-right: 4px;
  }

  .se-save-new-state {

    .se-save-cancel-btn-cn {
      display: flex;
      align-items: center;

      button.se-create-state-btn{
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border: none;
        font-size: 12px;
        font-family: SemiBold;
        color: #FFFFFF;
        border-radius: 2px;
        background-color:#006FD6;

        :hover {
          background-color:#0064C0;
        }

        :active {
          background-color:#0064C0;
        }
    
        :disabled {
          color: #FFFFFF;
          background-color: #7EB6EA;
          cursor: not-allowed;
          border-width: 0px;
          svg {
          path {
            stroke: rgba(0, 111, 214, 0.5);
          }
        }
        }

        :focus {
          border: 2px solid rgba(17, 111, 214, 0.3);
          box-shadow: 0px 0px 1px #1360B5;
        }
      }

      button.se-create-state-btn.disabled {
        opacity: 0.5;
        cursor: default;
      }
      
      .not-allowed {
        cursor: not-allowed;
      }

      display: flex;
      button.se-cancel-btn {
        font: 12px SemiBold;
        width: 128px;
        height: 32px;
        border: 1px solid #DFE3E8;
        color: #454F5B;
        font-weight: 400;
        border-radius: 2px;

        :hover {
          background-color: #F9FAFB;
        }
      }
      button.se-save-btn {
        color: #FFFFFF;
        font: 12px SemiBold;
        background-color: #006FD6;
        border: 0px;
        width: 128px;
        height: 32px;
        margin-left: 10px;
        cursor: pointer;
        font-weight: 400;
        border-radius: 2px;
        
        :disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        :hover{
          background-color: #0064C0;
        }
      }
    }
  }

  .se-save-new-state.placed-bottom {
    margin: 0;
    .se-create-new-state-cn {
      padding: 0 16px;
    }
    .se-save-cancel-btn-cn {
      border-top: 0.3px solid #DFE3E8;
      height: 48px;
      padding: 0 16px;
    }
  }

  .se-save-new-state.placed-top {
    margin: 0 16px;
  }

  .se-save-new-state.placed-top.create-state {
    .se-save-cancel-btn-cn {
      padding-top: 12px;
      border-top: 0.3px solid #DFE3E8;
    }
  }

  .se-divider {
    border-top: 0.3px solid #DFE3E8;
  }

  .se-divider.report-views {
    margin: 0 -16px;
  }

  .Toastify .update {
    border: solid 1px #F38037;
    background-color: #FAEEE6;
    border-left:solid 6px #F38037;
  }

  .Toastify .create {
    border: solid 1px #116FD6;
    background-color: #116FD6;
    border-left:solid 6px #116FD6;
  }

  .Toastify .create {
    border: solid 1px #116FD6;
    background-color: #E5F0FB;
    border-left:solid 6px #116FD6;
  }

  .se-create-state-desc-divider {
    margin-bottom: 12px;
  }

  .se-report-section {
    display:flex ;
    margin: 4px 16px 16px 16px ;
    border-radius:4px ;
    background-color: #F4F6F8 ;

  button {
    justify-content:center ;
    align-items: center ;
    flex:1 ;
    border: solid 0px ;
    background-color: #F4F6F8 ;
    border-radius:4px ;
    font-size:13px ;
    font-family: SemiBold ;
  }  

  .se-all-views-section {
    color:#454F5B ;
    margin: 5px 4px 5px 8px ;
    padding: 3px 34px ;
  }

  .se-My-views-section {
    color:#3BB345 ;
    margin: 5px 8px 5px 4px ;
    padding: 3px 31px ;
  }

  .open {
    background-color:#FFFFFF ;
  }
}
}
`;

export default StyledContainer;
