import React from 'react';

type Props = {
    width: number,
    height: number,
}

const SieraLogo = ({ width, height }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 226 114"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#116fd6" />
        <stop offset="1" stopColor="#084e96" />
      </linearGradient>
    </defs>
    <g
      id="Group_19633"
      data-name="Group 19633"
      transform="translate(-566 -366)"
    >
      <g
        id="Group_19624"
        data-name="Group 19624"
        transform="translate(481 250)"
      >
        <text
          id="SIERA_"
          data-name="SIERA "
          transform="translate(85 116)"
          fill="#012240"
          fontSize="80"
          fontFamily="BrandonGrotesque-Black, Brandon Grotesque"
          fontWeight="800"
        >
          <tspan x="0" y="84">SI</tspan>
          <tspan y="84" fill="#fff">E</tspan>
          <tspan y="84">RA </tspan>
        </text>
      </g>
      <rect
        id="Rectangle_6811"
        data-name="Rectangle 6811"
        width="35"
        height="11.703"
        transform="translate(679 405.703) rotate(180)"
        fill="url(#linear-gradient)"
      />
      <rect
        id="Rectangle_6812"
        data-name="Rectangle 6812"
        width="25.5"
        height="11.942"
        transform="translate(669.5 428.042) rotate(180)"
        fill="url(#linear-gradient)"
      />
      <rect
        id="Rectangle_6813"
        data-name="Rectangle 6813"
        width="35"
        height="10.95"
        transform="translate(679 449) rotate(180)"
        fill="url(#linear-gradient)"
      />
    </g>
  </svg>

);

export default SieraLogo;
