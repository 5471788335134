/* eslint-disable no-shadow */

// Action types of Report reducer.
export enum ReportActionTypes {
    REPORT_CONFIG_LOAD_REQUEST = 'REPORT_CONFIG_LOAD_REQUEST',
    REPORT_CONFIG_LOAD_SUCCESS = 'REPORT_CONFIG_LOAD_SUCCESS',
    REPORT_CONFIG_LOAD_FAILURE = 'REPORT_CONFIG_LOAD_FAILURE',
    FILTERS_LOAD = 'FILTERS_LOAD',
    FILTER_LOAD_REQUEST = 'FILTER_LOAD_REQUEST',
    FILTER_LOAD_SUCCESS = 'FILTER_LOAD_SUCCESS',
    FILTER_LOAD_FAILURE = 'FILTER_LOAD_FAILURE',
    REPORT_DATA_LOAD_REQUEST = 'REPORT_DATA_LOAD_REQUEST',
    REPORT_DATA_LOAD_SUCCESS = 'REPORT_DATA_LOAD_SUCCESS',
    REPORT_DATA_LOAD_FAILURE = 'REPORT_DATA_LOAD_FAILURE',
    FILTER_CHANGE = 'FILTER_CHANGE',
    PAGINATION_CHANGE = 'PAGINATION_CHANGE',
    SORTING_CHANGE = 'SORTING_CHANGE',
    DRILLDOWN_CHANGE = 'DRILLDOWN_CHANGE',
    REVERSE_DRILLDOWN = 'REVERSE_DRILLDOWN',
    APPLY_FILTERS = 'APPLY_FILTERS',
    CLEAR_FILTERS = 'CLEAR_FILTERS',
    SAVED_STATES_LOAD_REQUEST = 'SAVED_STATES_LOAD_REQUEST',
    SAVED_STATES_LOAD_SUCCESS = 'SAVED_STATES_LOAD_SUCCESS',
    SAVED_STATES_LOAD_FAILURE = 'SAVED_STATES_LOAD_FAILURE',
    CREATE_SAVED_STATE_REQUEST = 'CREATE_SAVED_STATE_REQUEST',
    CREATE_SAVED_STATE_SUCCESS = 'CREATE_SAVED_STATE_SUCCESS',
    CREATE_SAVED_STATE_FAILURE = 'CREATE_SAVED_STATE_FAILURE',
    DELETE_SAVED_STATE_REQUEST = 'DELETE_SAVED_STATE_REQUEST',
    DELETE_SAVED_STATE_SUCCESS = 'DELETE_SAVED_STATE_SUCCESS',
    DELETE_SAVED_STATE_FAILURE = 'DELETE_SAVED_STATE_FAILURE',
    APPLY_REPORT_VIEW = 'APPLY_REPORT_VIEW',
    SET_TOOLBAR_MENU_ITEM = 'SET_TOOLBAR_MENU_ITEM',
    SET_ZERO_ANCHOR = 'SET_ZERO_ANCHOR',
    SET_GROUPING = 'SET_GROUPING',
    APPLY_DIMENSIONS = 'APPLY_DIMENSIONS',
    REORDER_COLUMNS = 'REORDER_COLUMNS',
    SAVE_COLUMNS_ORDER_REQUEST = 'SAVE_COLUMNS_ORDER_REQUEST',
    SET_MEASURE_STATE = 'SET_MEASURE_STATE',
    REORDER_MEASURE = 'REORDER_MEASURE',
    TOGGLE_ZERO_ANCHORS = 'TOGGLE_ZERO_ANCHORS',
    SEARCH_DROPDOWN_OPTIONS = 'SEARCH_DROPDOWN_OPTIONS',
    LOAD_MORE_DROPDOWN_OPTIONS = 'LOAD_MORE_DROPDOWN_OPTIONS',
    RESET = 'RESET',
    REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST = 'REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST',
    REPORT_DYNAMIC_DIMENSIONS_LOAD_SUCCESS = 'REPORT_DYNAMIC_DIMENSIONS_LOAD_SUCCESS',
    REPORT_DYNAMIC_DIMENSIONS_LOAD_FAILURE = 'REPORT_DYNAMIC_DIMENSIONS_LOAD_FAILURE',
    ADD_DYNAMIC_FILTER = 'ADD_DYNAMIC_FILTER',
    DELETE_DYNAMIC_FILTER = 'DELETE_DYNAMIC_FILTER',
    REFRESH = 'REFRESH',
    AUTODRILLDOWN = 'AUTODRILLDOWN',
    AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST = 'AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST',
    AUTODRILLDOWN_REPORT_DATA_LOAD_SUCCESS = 'AUTODRILLDOWN_REPORT_DATA_LOAD_SUCCESS',
    AUTODRILLDOWN_REPORT_DATA_LOAD_FAILURE = 'AUTODRILLDOWN_REPORT_DATA_LOAD_FAILURE',
    SET_GROUPING_DRILLDOWN = 'SET_GROUPING_DRILLDOWN',
    SET_BINNING = 'SET BINNING',
    EXPORT_DATA_LOAD_REQUEST = 'EXPORT_DATA_LOAD_REQUEST',
    EXPORT_DATA_LOAD_SUCCESS = 'EXPORT_DATA_LOAD_SUCCESS',
    EXPORT_DATA_LOAD_FAILURE = 'EXPORT_DATA_LOAD_FAILURE',
    EXPORT_USAGE_STATUS_REQUEST = 'EXPORT_USAGE_STATUS_REQUEST',
    EXPORT_USAGE_STATUS_SUCCESS = 'EXPORT_USAGE_STATUS_SUCCESS',
    EXPORT_USAGE_STATUS_FAILURE = 'EXPORT_USAGE_STATUS_FAILURE',
    EXPORT_LIST_LOAD_REQUEST = 'EXPORT_LIST_LOAD_REQUEST',
    EXPORT_LIST_LOAD_SUCCESS = 'EXPORT_LIST_LOAD_SUCCESS',
    EXPORT_LIST_LOAD_FAILURE = 'EXPORT_LIST_LOAD_FAILURE',
    EXPORT_LIST_LOAD_BY_IDS_REQUEST = 'EXPORT_LIST_LOAD_BY_IDS_REQUEST',
    EXPORT_LIST_LOAD_BY_IDS_SUCCESS = 'EXPORT_LIST_LOAD_BY_IDS_SUCCESS',
    EXPORT_LIST_LOAD_BY_IDS_FAILURE = 'EXPORT_LIST_LOAD_BY_IDS_FAILURE',
    RETRY_EXPORT_REQUEST = 'RETRY_EXPORT_REQUEST',
    RETRY_EXPORT_SUCCESS = 'RETRY_EXPORT_SUCCESS',
    RETRY_EXPORT_FAILURE = 'RETRY_EXPORT_FAILURE',
    UPDATE_TRIGGER_EXPORT = 'UPDATE_TRIGGER_EXPORT',
    DATA_REFRESH_DETAILS_LOAD_REQUEST = 'DATA_REFRESH_DETAILS_LOAD_REQUEST',
    DATA_REFRESH_DETAILS_LOAD_SUCCESS = 'DATA_REFRESH_DETAILS_SUCCESS',
    DATA_REFRESH_DETAILS_LOAD_FAILURE = 'DATA_REFRESH_DETAILS_FAILURE',
}

// Action types of ReportList reducer.
export enum ReportListActionTypes {
    REPORT_CATEGORIES_LOAD_REQUEST = 'REPORT_CATEGORIES_LOAD_REQUEST',
    REPORT_CATEGORIES_LOAD_SUCCESS = 'REPORT_CATEGORIES_LOAD_SUCCESS',
    REPORT_CATEGORIES_LOAD_FAILURE = 'REPORT_CATEGORIES_LOAD_FAILURE',
    PINNED_REPORTS_LOAD_REQUEST = 'PINNED_REPORTS_LOAD_REQUEST',
    PINNED_REPORTS_LOAD_SUCCESS = 'PINNED_REPORTS_LOAD_SUCCESS',
    PINNED_REPORTS_LOAD_FAILURE = 'PINNED_REPORTS_LOAD_FAILURE',
    CATEGORY_REPORTS_LOAD_REQUEST = 'CATEGORY_REPORTS_LOAD_REQUEST',
    CATEGORY_REPORTS_LOAD_SUCCESS = 'CATEGORY_REPORTS_LOAD_SUCCESS',
    CATEGORY_REPORTS_LOAD_FAILURE = 'CATEGORY_REPORTS_LOAD_FAILURE',
    REPORT_PIN_UNPIN_REQUEST = 'REPORT_PIN_UNPIN_REQUEST',
    REPORT_PIN_UNPIN_SUCCESS = 'REPORT_PIN_UNPIN_SUCCESS',
    REPORT_PIN_UNPIN_FAILURE = 'REPORT_PIN_UNPIN_FAILURE',
    SORTING_LIST_CHANGE = 'SORTING_LIST_CHANGE',
    SELECT_REPORT_TYPE = 'SELECT_REPORT_TYPE',
    REORDER_REPORT_CATEGORIES_REQUEST = 'REORDER_REPORT_CATEGORIES_REQUEST',
    REORDER_REPORT_CATEGORIES_SUCCESS = 'REORDER_REPORT_CATEGORIES_SUCCESS',
    REORDER_REPORT_CATEGORIES_FAILURE = 'REORDER_REPORT_CATEGORIES_FAILURE',
}
