import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const PinnedIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="push_pin_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 20 20"
  >
    <g id="Group_18514" data-name="Group 18514">
      <rect
        id="Rectangle_6173"
        data-name="Rectangle 6173"
        width="20"
        height="20"
        style={{
          fill: 'none',
        }}
      />
    </g>
    <g id="Group_18515" data-name="Group 18515" transform="translate(4.167 1.667)">
      <path
        id="Path_7468"
        data-name="Path 7468"
        d="M14.167,7.833V3.667H15a.836.836,0,0,0,.833-.833h0A.836.836,0,0,0,15,2H6.667a.836.836,0,0,0-.833.833h0a.836.836,0,0,0,.833.833H7.5V7.833a2.5,2.5,0,0,1-2.5,2.5H5V12H9.975v5.833l.833.833.833-.833V12h5.025V10.333h0A2.5,2.5,0,0,1,14.167,7.833Z"
        transform="translate(-5 -2)"
        style={{
          fill,
        }}
      />
    </g>
  </svg>
);

PinnedIcon.defaultProps = {
  fill: '#ed7b24',
};

export default PinnedIcon;
