import React, { useRef } from 'react';
import ClearIcon from 'components/common/svg/Clear/clear.svg';
import { ISearchProps } from 'components/feature/Report/ReportSidebar/common/SearchBar/search-bar.d';
import StyledSearchBar from 'components/feature/Report/ReportSidebar/common/SearchBar/search-bar-style';

const SearchBar = (props: ISearchProps) => {
  const {
    onChange, query, searchPlaceholder, setFocus,
  } = props;
  const inputElement = useRef(null);

  return (
    <StyledSearchBar>
      <div className="se-sidebar-showcase-search">
        <div className="se-sidebar-search">
          <input
            className="se-sidebar-input"
            type="text"
            placeholder={searchPlaceholder}
            onChange={(e) => onChange(e.target.value)}
            value={query}
            onFocus={() => setFocus()}
            ref={inputElement}
          />
          <div className="se-sidebar-clear">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={`se-icon-cn ${query !== '' ? 'show' : 'hide'}`}
              onClick={() => {
                onChange('');
                inputElement.current.focus();
              }}
            >
              <ClearIcon
                width={13}
                height={13}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="se-search-divider" />
    </StyledSearchBar>
  );
};

export default SearchBar;
