import React, { useState } from 'react';
import {
  DragDropContext, DraggableId, DragStart, Droppable, DropResult,
} from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxModel } from 'core/models';

import {
  ColumnModes,
  ExpandListButtonContext,
  expandValuesButtonLabel,
  PreDefinedFieldEntityType,
  ReportDimensionsSettingsTitles,
} from 'core/constants/report';
import SidebarHeader from 'components/feature/Report/ReportSidebar/common/SidebarHeader/sidebar-header.component';
import { DroppableIds, DroppableTypes } from 'components/feature/Report/ReportSidebar/common/constants';
import * as Actions from 'redux/report/actions';
import ExpandListButton from 'components/common/ExpandListButton/expand-list-button.component';
import StyledContainer from './measures.style';
import IMeasuresProps from './measures.d';
import DraggableItem from '../../common/DraggableItem/draggable-item.component';
import { getFieldIdentifier, isMeasureAndApplied, isMeasureDynamic } from '../../../../../../core/utils/report.util';
import { displayName } from '../../common/helpers';
import { DraggableItemState } from '../../common/DraggableItem/draggable-item.d';
import AvailableMeasures from './AvailableMeasures/available-measures.component';
import { IColumn } from '../../../../../../core/models/report-response';

const Measures = (props: IMeasuresProps) => {
  const dispatch = useDispatch();
  const { reportBuilderPortal, panelExpanded, setPanelExpanded } = props;
  const [
    allMeasures,
  ] = useSelector((state: ReduxModel.IGlobalState) => [
    state.report.allMeasures,
  ] as const);
  const [measureDragging, setMeasureDragging] = useState<DraggableId>('');

  const appliedMeasures = allMeasures
    .map((m, index) => ({ ...m, originalIndex: index } as IColumnWithIndex))
    .filter((m) => isMeasureAndApplied(m));

  const onBeforeDragStart = ({ draggableId }: DragStart) => {
    setMeasureDragging(draggableId);
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    setMeasureDragging('');
    // reorder measures
    if (!destination) return;
    const sourceName = getFieldIdentifier(appliedMeasures[source.index]);
    const sourceOriginalIndex = allMeasures.findIndex((m) => getFieldIdentifier(m) === sourceName);
    const destName = getFieldIdentifier(appliedMeasures[destination.index]);
    const destOriginalIndex = allMeasures.findIndex((m) => getFieldIdentifier(m) === destName);
    dispatch(Actions.reOrderMeasure(sourceOriginalIndex, destOriginalIndex));
  };

  const unset = (index: number) => dispatch(Actions.setMeasureState(index, ColumnModes.Hidden));

  return (
    <StyledContainer>
      <DragDropContext onBeforeDragStart={onBeforeDragStart} onDragEnd={onDragEnd}>
        <AvailableMeasures
          expanded={panelExpanded}
          reportBuilderPortal={reportBuilderPortal}
          collapse={() => setPanelExpanded(false)}
        />
        <div className="se-dimensions-settings-item">
          <div className="se-grouping-header-cn">
            <SidebarHeader
              title={ReportDimensionsSettingsTitles.Measures}
              source={ExpandListButtonContext.Values}
            />
            <ExpandListButton
              label={expandValuesButtonLabel}
              isExpanded={panelExpanded}
              onClickToggler={() => setPanelExpanded(!panelExpanded)}
              clickSource={ExpandListButtonContext.Values}
            />
          </div>

          <Droppable
            droppableId={DroppableIds.Measures}
            type={DroppableTypes.Measures}
          >
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {appliedMeasures.map((col, index) => {
                  const name = displayName(col);
                  const key = getFieldIdentifier(col);
                  return (
                    <DraggableItem
                      key={key}
                      draggableId={key}
                      text={name}
                      data={col.originalIndex}
                      state={measureDragging === key ? DraggableItemState.Dragging : DraggableItemState.Default}
                      index={index}
                      showCloseButton
                      onClickClose={(data) => unset(data)}
                      entity={isMeasureDynamic(col) && col.BuilderConfig ? col.BuilderConfig.Entity : PreDefinedFieldEntityType}
                    />
                  );
                })}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </StyledContainer>
  );
};

export default Measures;

interface IColumnWithIndex extends IColumn{
  originalIndex: number;
}
