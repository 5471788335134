import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const UnsortIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 12.133 7.733"
  >
    <path
      id="Path_7711"
      data-name="Path 7711"
      d="M3,13.733H7.044V12.445H3ZM3,6V7.289H15.133V6Zm0,4.511h8.089V9.222H3Z"
      transform="translate(-3 -6)"
      fill={fill}
    />
  </svg>

);
UnsortIcon.defaultProps = {
  fill: '#cad3db',
};

export default UnsortIcon;
