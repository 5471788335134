import styled from 'styled-components';

const StyledContainer = styled.div`
width: 100%;
height: 100%;

.se-error-dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background:var(--boxShadow);
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .se-error-showcase {
        width: 400px;
        height: 208px;
        border-radius: 2px;
    }
}

.se-error-dialog.non-droppable {
   cursor: default;
}

.se-error-non-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--noDataBackground);
}

.se-icon {
    display: flex;
    align-items: center;
    margin-right: 4px;
}

.se-error-showcase {
    background:var(--noDataBackground);
    padding: 12px;
    text-align: center;
    cursor: default;
    border-radius: 2px;
    box-sizing: border-box;

    .se-error-icon {
        margin-bottom: 5px;
    }

    .se-error-header {
        font-size: 16px;
        font-family: SemiBold;
        color: var(--paginationColor);
        margin-bottom: 8px;
    }

    .se-error-message {
        padding: 0 20px;
        font-size: 11px;
        color: var(--searchBoxTextColor);
        margin-bottom: 20px;
        max-width: 410px;
    }

    .se-error-actions {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            margin: 0 8px;
            padding: 5px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-family: SF Pro, SemiBold;
            color: var(--paginationColor);
            border: 0;
            background: var(--borderColor);

            :hover {
                background: var(--paginationBorder);
            }
        }
    }
}
`;
export default StyledContainer;
