import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const FailedIcon = ({
  width, height, fill,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <g id="Group_22665" data-name="Group 22665" transform="translate(-1237 -224)">
      <rect id="Rectangle_8515" data-name="Rectangle 8515" width={width} height={height} transform="translate(1237 224)" fill="none" />
      <path id="Path_10256" data-name="Path 10256" d="M14.663,5.837a6.254,6.254,0,1,0,1.624,5.978H14.663A4.684,4.684,0,1,1,10.25,5.563a4.618,4.618,0,0,1,3.3,1.391L11.031,9.47H16.5V4Z" transform="translate(1236.601 223.893)" fill="#de323b" />
    </g>
  </svg>

);

FailedIcon.defaultProps = {
  fill: '#919eab',
};

export default FailedIcon;
