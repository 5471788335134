import React from 'react';
import { VisualizationTypes } from 'core/constants/visualizations';
import { IProps } from 'components/feature/ReportListing/ReportIcon/report-icon.d';
import TabularIcon from 'components/common/svg/Tabular/tabular.component';
import LineChartIcon from 'components/common/svg/LineChart/line-chart.svg';
import ColumnChartIcon from 'components/common/svg/ColumnChart/column-chart.svg';
import BarChartIcon from 'components/common/svg/BarChart/bar-chart.svg';
import AreaChartIcon from 'components/common/svg/AreaChart/area-chart.svg';

const ReportIcon = (props: IProps) => {
  const { type, color } = props;
  let Icon = null;
  switch (type) {
    case VisualizationTypes.Table:
      Icon = TabularIcon;
      break;

    case VisualizationTypes.LineChart:
      Icon = LineChartIcon;
      break;

    case VisualizationTypes.ColumnChart:
      Icon = ColumnChartIcon;
      break;

    case VisualizationTypes.BarChart:
      Icon = BarChartIcon;
      break;

    case VisualizationTypes.AreaChart:
      Icon = AreaChartIcon;
      break;

    default:
      break;
  }
  return Icon && (
    <Icon
      width={18}
      height={18}
      fill={color}
    />
  );
};

export default ReportIcon;
