import React from 'react';
import { components } from 'react-select';
import { ReportSortData } from 'core/constants/report';
import ArrowDownIcon from 'components/common/svg/ArrowDown/arrow-down.svg';
import TickIcon from 'components/common/svg/Tick/tick.svg';
import { IProps } from 'components/feature/ReportListing/ReportHeader/ReportSorting/report-sort.d';
import StyledContainer from 'components/feature/ReportListing/ReportHeader/ReportSorting/report-sorting.style';
import StoreConnector from 'components/feature/ReportListing/ReportHeader/ReportSorting/report-sort.redux';
import ReactSelect from 'components/common/select/ReactSelect/react-select.component';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';

const Option = (props: any) => {
  const { children, isSelected } = props;
  return (
    <components.Option {...props}>
      <span
        className="rs-option-label-cn"
      >
        {children || '(empty)'}
      </span>
      <span>
        {
          isSelected && (
            <TickIcon
              width={10}
              height={8}
            />
          )
        }
      </span>
    </components.Option>
  );
};

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <ArrowDownIcon
      width={16}
      height={16}
    />
  </components.DropdownIndicator>
);

const ReportSorting = (props: IProps) => {
  const { reduxState, reduxAction } = props;
  const { reportList } = reduxState;
  const { sort } = reportList;
  const { sortingChange } = reduxAction;

  return (
    <StyledContainer>
      <ReactSelect
        config={{
          Key: 'sort',
          Meta: {
            ClassName: 'se-report-list-sorting-dropdown',
            Options: ReportSortData,
            DropdownIndicator: true,
            Searchable: false,
            Clearable: false,
            AppliedOnClose: false,
            CloseOnSelect: true,
            Components: {
              Option,
              DropdownIndicator,
            },
          },
        }}
        value={sort}
        onChange={(key, value) => {
          sortingChange(value);
        }}
        pendoClassName={PendoClassNames.SortReport}
      />
    </StyledContainer>

  );
};

export default StoreConnector()(ReportSorting);
