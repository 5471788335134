import React from 'react';

type Props = {
    width: number;
    height: number;
    fill?: string;
}

const RowGroupingIcon = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.05 13.15H6.322V4.05H3.05V13.15ZM7.372 13.15H10.627V4.05H7.372V13.15ZM11.677 13.15H14.95V4.05H11.677V13.15ZM3.05 14.2C2.77368 14.1932 2.51057 14.0803 2.31512 13.8849C2.11967 13.6894 2.00684 13.4263 2 13.15V4.05C2.00684 3.77368 2.11967 3.51057 2.31512 3.31512C2.51057 3.11967 2.77368 3.00684 3.05 3H14.95C15.2263 3.00684 15.4894 3.11967 15.6849 3.31512C15.8803 3.51057 15.9932 3.77368 16 4.05V13.15C15.9932 13.4263 15.8803 13.6894 15.6849 13.8849C15.4894 14.0803 15.2263 14.1932 14.95 14.2H3.05Z"
      fill={fill}
    />
    <rect
      x="3"
      y="4"
      width="3.33"
      height="9.2"
      fill="#116FD6"
      fillOpacity="0.5"
    />
  </svg>

);

RowGroupingIcon.defaultProps = {
  fill: '#454F5B',
};

export default RowGroupingIcon;
