import React from 'react';
import { IProps } from 'components/feature/Report/ReportFooter/report-footer.d';
import StoreConnector from 'components/feature/Report/ReportFooter/report-footer.redux';
import Pagination from 'components/common/Pagination/pagination.component';
import StyledFooterComponent from './report-footer.style';
import LoadInformation from './LoadInformation/load-information.component';

const ReportFooter = (props: IProps) => {
  const { reduxState, reduxAction } = props;
  const { report } = reduxState;
  const {
    page, reportData, autodrilldownData,
  } = report;

  const { paginationChange } = reduxAction;
  let currPage = page;
  let isReportEmpty = reportData && reportData.Raw ? !reportData.Raw.Data : false;
  let hasNextPage = reportData && reportData.Raw ? reportData.Raw.HasNextPage : false;
  if (autodrilldownData && autodrilldownData.reportData) {
    currPage = autodrilldownData.page;
    isReportEmpty = autodrilldownData.reportData && autodrilldownData.reportData.Raw
      && autodrilldownData.reportData.Raw.Data && !autodrilldownData.reportData.Raw.Data;
    hasNextPage = autodrilldownData.reportData && autodrilldownData.reportData.Raw
      ? autodrilldownData.reportData.Raw.HasNextPage
      : false;
  }
  let recordsRetrievedValue;
  if (isReportEmpty) {
    recordsRetrievedValue = null;
  } else if (autodrilldownData && autodrilldownData.reportData) {
    recordsRetrievedValue = autodrilldownData.reportData.Raw && autodrilldownData.reportData.Raw.Data
      ? autodrilldownData.reportData.Raw.Data.length
      : 0;
  } else {
    recordsRetrievedValue = reportData && reportData.Raw && reportData.Raw.Data
      ? reportData.Raw.Data.length
      : 0;
  }
  return (
    <StyledFooterComponent>
      <div className="se-report-footer">
        <LoadInformation />
        <Pagination
          page={currPage}
          hasNextPage={hasNextPage}
          onPaginationChange={(key, value) => {
            paginationChange(
              key,
              value,
              !!(autodrilldownData && autodrilldownData.reportData),
            );
          }}
          isDisabled={isReportEmpty}
          recordsRetrieved={recordsRetrievedValue}
        />
      </div>
    </StyledFooterComponent>
  );
};

export default StoreConnector()(ReportFooter);
