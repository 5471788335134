import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import {
  ColumnApi, GridApi, CellClassParams, ColDef, Column, ValueFormatterParams, GetContextMenuItemsParams, ExcelStyle,
} from 'ag-grid-enterprise';
import {
  GridModel, ObjModel, ReduxModel, ReportResponseModel,
} from 'core/models';
import {
  ColumnActionTypes, DimensionNoValue, DrilldownChangesTypes, DrilldownChangeTypes, EncryptedText, TotalSummaryText, LSQLinkType, PivotColGrpHeaderSeparator, SieraAutoDrilldownLink, formatType, Timers,
} from 'core/constants/report';
import { IColumnActionDrilldownChangeMeta } from 'core/models/report-response';
import { ICellMeta, LinkMeta } from 'core/models/grid';
import {
  getCurlyBracesParamsFromString, getFormattedValues, getFormattedValuesForBinning, formattedCurrentDate,
} from 'core/utils/formatters.utility';
import { IProps } from 'components/common/visualization/Grid/grid.d';
import StyledGridComponent from 'components/common/visualization/Grid/grid.styled';
import ColumnHeader from 'components/common/visualization/Grid/ColumnHeader/column-header.component';
import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import { extractValFromStrWithSeparator } from 'core/utils/report-builder.util';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';
import { isValid } from 'date-fns';
import { updateTriggerExport } from 'redux/report/actions';
import { useDebounce } from 'usehooks-ts';
import { ColumnMovedEvent } from 'ag-grid-community';

// Grid component will be used to display/render the records. It is wrapper over Ag-grid react component.
// Params will be of Props Interface
// Returns a component based on different props.
const Grid = (props: IProps) => {
  const {
    paginationPageSize,
    rowData,
    columns: gridColumns,
    rowHeight,
    sorting,
    suppressDragLeaveHidesColumns,
    onCellClicked,
    onColumnReorder,
    activeFilters,
    AllowUserDrillDown,
    activeDimensionsLength,
    pivotMode,
    autodrilldown = false,
    allowAutodrilldown = false,
    aggregatesData,
  } = props;

  const [reportTitle, pivotBinningType, isExportable, triggerExport, userTimeZone,
  ] = useSelector((state: ReduxModel.IGlobalState) => [
    state.report.reportConfig.Details.Title, state.report.pivotBinningType,
    state.report.reportConfig.IsExportable, state.report.triggerExport, state.auth?.userAttributes?.UserTimeZone] as const);

  // Render columns
  const gridApi = useRef<GridApi>();
  const columnApi = useRef<ColumnApi>();
  const gridRef = useRef(null);
  const [gridHeaderNames, setGridHeaderNames] = useState<string>('');
  const debouncedGridHeaderNamesValue = useDebounce<string>(gridHeaderNames, Timers.TwoSeconds);

  const dispatch = useDispatch();

  useEffect(() => {
    if (triggerExport === formatType.CSV) {
      gridApi?.current?.exportDataAsCsv();
    } else if (triggerExport === formatType.Excel) {
      gridApi?.current?.exportDataAsExcel();
    }

    dispatch(updateTriggerExport(''));
  }, [triggerExport]);

  // method to set totals row at the bottom when its not in pivot mode
  const pinTotalsRowToBottom = () => {
    setTimeout(() => {
      if (!pivotMode && aggregatesData?.GrandTotals) {
        gridRef.current.api.setPinnedBottomRowData([aggregatesData.GrandTotals]);
      } else {
        gridRef.current.api.setPinnedBottomRowData();
      }
    }, 0);
  };

  useEffect(() => {
    const handleMouseDown = (event:any) => {
      const GridBodyViewport = document.getElementsByClassName('ag-body-viewport');
      // We are using 150 as default height of Context menu to set popupParent, looking for a better approach
      if (event.clientY + 150 < document.querySelector('body').clientHeight) {
        gridApi.current.setPopupParent(document.querySelector('body'));
      } else {
        gridApi.current.setPopupParent(null);
      }
      if (!GridBodyViewport[0].contains(event.target)) {
        if (gridApi && gridApi.current) {
          gridApi.current.clearRangeSelection();
        }
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  });

  // Triggers when "debouncedGridHeaderNamesValue" changes
  useEffect(() => {
    if (debouncedGridHeaderNamesValue !== '') {
      onColumnReorder(JSON.parse(debouncedGridHeaderNamesValue));
    }
  }, [debouncedGridHeaderNamesValue]);

  // as columnMovedEvents are triggered multiple times we will be debouncing the event
  const onColumnMovedHandler = (columnMovedEvent: ColumnMovedEvent) => {
    // ag-grid sometimes gives culumn as null on columnMovedEvent
    if (columnMovedEvent?.column) {
      // we need to pass onto set in order to remove the duplicate headers which gets generated in pivot mode
      const reorderedHeaderNames = Array.from(new Set(columnMovedEvent.columnApi.getAllGridColumns().map((col:any) => col.colDef.headerName)));
      setGridHeaderNames(JSON.stringify(reorderedHeaderNames));
    }
  };

  // checking for masked active(dimension) col.
  // rowGroup and pivot keys used to validate whether col is active(dimension) or not.
  const hasMaskedField = gridColumns.find((col) => (col.rowGroup || col.pivot) && col.isMasked);

  // this method will return the updated link which will be mapping Display name of the properties for all the given Alias name
  const getUpdatedMetaLink = (meta:LinkMeta, aliasDisplayNamespair:any) => {
    let link = meta?.Route;
    const params:Array<string> = getCurlyBracesParamsFromString(link);
    if (params.length > 0) {
      params.forEach((item) => {
        const displayName = aliasDisplayNamespair[meta.TakeParamFrom[item]];
        link = link.replace(`{${item}}`, `{${displayName}}`);
      });
    }
    return link;
  };
  // this method will update the columns with lsqLink with a string indicating its lsq clickable link
  const updateLSQlinkForColumns = () => {
    const aliasDisplayNamespair :any = {};
    gridColumns.forEach((item:GridModel.IColumnConfig) => {
      const alias = item.builderConfig?.Alias;
      aliasDisplayNamespair[alias] = item.field;
    });
    gridColumns.forEach((item:GridModel.IColumnConfig) => {
      if (item.action?.Type === LSQLinkType.LINKTOLSQ) {
        // eslint-disable-next-line no-param-reassign
        item.lsqLink = getUpdatedMetaLink(item.action?.Meta?.LinkMeta, aliasDisplayNamespair);
      } else if (item.action?.Type === LSQLinkType.LINKTOSELF) {
        // eslint-disable-next-line no-param-reassign
        item.lsqLink = LSQLinkType.LINKTOSELF;
      }
    });
  };

  // with this empty column to our columns we can ensure proper width for our actual columns
  const addEmptyColumnToTheEnd = () => {
    const emptyCol:GridModel.IColumnConfig = {
      field: 'zempty',
      colId: 'zempty',
    };
    gridColumns.push(emptyCol);
  };

  const renderColumns = (columnItems: Array<GridModel.IColumnConfig>) => {
    let rowIndex = 0; // this variable is to set the rowGroupIndex based on columns which are row grouped.

    if (rowData && rowData.length > 0) {
      setAlignmentForColumns();
    }

    // adding an empty column to the end
    addEmptyColumnToTheEnd();

    // update lsq link property for the columns
    updateLSQlinkForColumns();

    return columnItems.map((column: GridModel.IColumnConfig, index: number) => {
      const colAdvancedConfig = colAdvancedConfigs(column);
      const isColumnClickAble = colAdvancedConfig.click;
      const styleClass = colAdvancedConfig.cellClass;
      const { valueFormatter } = colAdvancedConfig;
      const headerComponentParams = getHeaderComponentParams(column);
      let currentColumn = { ...column, width: getWidthByFieldCharacters(column.field) };
      if (column.rowGroup) {
        currentColumn.rowGroupIndex = rowIndex;
        currentColumn.headerName = column.field;
        currentColumn = { ...currentColumn };
        rowIndex += 1;
      }

      if (column.field === 'zempty') {
        return (
          <AgGridColumn
            {...currentColumn}
            key={column.colId}
            headerName=""
            lockPosition="right"
            cellClass={['default-cursor']}
            suppressSizeToFit={index !== columnItems.length - 1}
            cellRenderer={cellRenderer}
          />
        );
      }
      // handling for LSQ clickable links
      if (column.lsqLink) {
        const lsqStyles = ['link-primary'];
        // adding unique styles for both lead and opportunity columns to track changes in pendo
        if (column.lsqLink.includes('LeadID')) {
          lsqStyles.push('se-track-lsq-lead');
        } else if (column.lsqLink.includes('opportunityId')) {
          lsqStyles.push('se-track-lsq-opportunity');
        }

        return (
          <AgGridColumn
            {...currentColumn}
            key={column.colId}
            headerName={column.field}
            onCellClicked={onCellClicked}
            cellClass={lsqStyles}
            tooltipValueGetter={(param) => param.valueFormatted}
            headerComponentParams={headerComponentParams}
            valueFormatter={valueFormatter}
            suppressSizeToFit={index !== columnItems.length - 1}
            cellRenderer={cellRenderer}
          />
        );
      }

      if (isColumnClickAble === true) {
        return (
          <AgGridColumn
            headerClass={[column.isLeftAligned ? 'header-left' : 'header-right']}
            {...currentColumn}
            key={column.colId}
            headerName={column.field}
            onCellClicked={onCellClicked}
            cellClass={styleClass}
            tooltipValueGetter={(param) => param.valueFormatted}
            headerComponentParams={headerComponentParams}
            valueFormatter={valueFormatter}
            suppressSizeToFit={index !== columnItems.length - 1}
            cellRenderer={cellRenderer}
          />
        );
      }

      const stylesForNonClickable:string[] = [];
      if (column.isMasked) {
        stylesForNonClickable.push('se-masked');
      }
      stylesForNonClickable.push(column.isLeftAligned ? 'align-left' : 'align-right');

      return (
        <AgGridColumn
          headerClass={[column.isLeftAligned ? 'header-left' : 'header-right']}
          {...currentColumn}
          headerName={column.field}
          key={column.colId}
          cellClass={stylesForNonClickable}
          tooltipValueGetter={(param) => param.valueFormatted}
          headerComponentParams={headerComponentParams}
          valueFormatter={valueFormatter}
          suppressSizeToFit={index !== columnItems.length - 1}
          cellRenderer={cellRenderer}
        />
      );
    });
  };

  /*
  we read all the characters of a header field one by one and based on the group it matches we add the width
  1) A-Z excluding I = 15px
  2) ilI = 8px
  3) fjrt = 10px
  4) rest of small letters = 12px

  TODO: we can further refactor to other chars such as speacial chars and numbers and assign a width to it.
  We can efficiently use Map to get the width in O(N)
  */
  const getWidthByFieldCharacters = (str: string) => {
    let width = 0;
    for (let i = 0; i < str.length; i += 1) {
      const ch = str.charAt(i);
      if (ch === 'I') {
        width += 8;
      } else if (ch <= 'Z' && ch >= 'A') {
        width += 15;
      } else if (ch === 'i' || ch === 'l') {
        width += 8;
      } else if (ch === 'f' || ch === 'j' || ch === 'r' || ch === 't') {
        width += 10;
      } else if (ch >= 'a' && ch <= 'z') {
        width += 12;
      } else {
        width += 15;
      }
    }

    return Math.min(width, 200);
  };

  // based on isLeftAligned property content for both header and cells will be aligned
  const setAlignmentForColumns = () => {
    const data = rowData.map((item) => item.Fields.reduce((acc: any, field: any) => {
      acc[field.Name] = field.Value;
      return acc;
    }, {}));

    gridColumns.forEach((val, index, arr) => {
      const param = data[0][val.colId];
      const parsedValue = parseInt(param, 10);
      /* eslint-disable no-param-reassign */
      arr[index].isLeftAligned = Number.isNaN(parsedValue) && !isValid(new Date(param?.toString()));
      /* eslint-enable no-param-reassign */
    });
  };

  const getHeaderComponentParams = (column: GridModel.IColumnConfig) => {
    const params: GridModel.IHeaderComponentParams = {};
    if (column.sortable) {
      params.sorting = sorting;
      params.activeDimensionsLength = activeDimensionsLength;
    }
    return params;
  };

  const getClassnames = (data: ObjModel.Obj, action: ReportResponseModel.IColumnAction) => {
    if (action.Type === ColumnActionTypes.Drilldown) {
      const metadata = action.Meta as ReportResponseModel.IColumnActionDrilldownMeta;
      if (metadata && metadata.DrilldownChanges && metadata.DrilldownChanges.Changes) {
        if (metadata.DrilldownChanges.Type === DrilldownChangesTypes.FilterOnly) {
          for (let i = 0; i < metadata.DrilldownChanges.Changes.length; i += 1) {
            const change = metadata.DrilldownChanges.Changes[i];
            if (change.Type === DrilldownChangeTypes.Filter) {
              if (AllowUserDrillDown) {
                const changeMeta = change.ChangeMeta as IColumnActionDrilldownChangeMeta;
                if (changeMeta) {
                  const filterToChange = changeMeta.FilterID;
                  if (changeMeta.TakeValueFrom && changeMeta.TakeValueFrom.length) {
                    if (activeFilters[filterToChange]?.FilterResponse === data?.[changeMeta.TakeValueFrom]) {
                      return `cell-disabled ${PendoClassNames.UserDrilldown}`;
                    }
                  }
                }
              } else {
                return 'cell-default';
              }
            }
          }
        }
      }
    }
    return `${action.Styles} ${AllowUserDrillDown && PendoClassNames.UserDrilldown}`;
  };
  const cellClassRowGroup = (params:CellClassParams) => {
    const cellClasses = [] as any;
    const columnDefinations = params.api.getColumnDefs();
    const lastRowGroupCol = columnDefinations.find((item: GridModel.IColumnConfig) => (
      item.rowGroup && item.rowGroupIndex === activeDimensionsLength - 1)) as GridModel.IColumnConfig;
    const currentColumnDef = columnDefinations.find((item: GridModel.IColumnConfig) => (
      item.colId === (params.colDef.showRowGroup || params.colDef.colId))) as GridModel.IColumnConfig;
    if (params.colDef.showRowGroup && currentColumnDef) {
      if (currentColumnDef.action) {
        // for pivot, data is undefined, extract it from node
        const rowDataVal = params.data || params.node?.allLeafChildren?.[0]?.data;
        if (rowDataVal) {
          cellClasses.push(getClassnames(rowDataVal, currentColumnDef.action));
        }
      }
    }
    if (lastRowGroupCol) {
      if (params.colDef.showRowGroup === lastRowGroupCol.colId) {
        cellClasses.push('cel-border-right');
      }
    }
    if (params.value) {
      if (activeDimensionsLength > 1) { cellClasses.push('row-group'); } // this is just class name. not needed for constants
    }
    return cellClasses;
  };

  const cellRenderer = (params: any) => {
    const columnId = params?.colDef?.colId;
    // if its first cell of Totals row then we will have a text TotalSummaryText
    // Note: left = 0 indicates its the first column of the grid
    if (params?.colDef?.pinned === 'left' && params?.column?.left === 0 && params?.node?.rowPinned === 'bottom') {
      return TotalSummaryText;
    }

    let value = params.valueFormatted ? params.valueFormatted : params.value;
    const columns = params.columnApi.getColumns();
    if (columns && columns.length) {
      const columnDefs = columns.map((c: Column) => c.getUserProvidedColDef())
        .filter((c: ColDef<any>) => c.field === params.colDef.headerName);
      if (columnDefs && columnDefs.length) {
        const columnOfInterest = columnDefs[0] as GridModel.IColumnConfig;
        // for pivot, data is undefined, extract it from node
        const rowDataVal = params.data || params.node?.allLeafChildren?.[0]?.data;
        if (columnOfInterest.pivot && columnOfInterest && columnOfInterest.builderConfig
          && columnOfInterest.builderConfig.IsDynamicField) {
          value = rowDataVal[columnOfInterest.builderConfig.Alias];
        }
        if (columnOfInterest && columnOfInterest.parentDataColumn
          && columnOfInterest.parentDataColumn.length) {
          value = params.value ? rowDataVal[columnOfInterest.headerName] : params.value;
        }
        if (columnOfInterest.isMasked) {
          if (!allowAutodrilldown && !autodrilldown) {
            return value === undefined || value === null ? '' : extractValFromStrWithSeparator(value);
          }
          return EncryptedText;
        }
      }
    }

    // TODO: 'value' and 'formattedValue' properties are not present in params, need to fix. For now accessing value using params.data object
    if (value === undefined && params.data && columnId in params.data) {
      value = params.data[columnId];
    }
    // Empty String is returning in case of undefined and null otherwise the grid throws an error.
    // This behaviour comes up with new version of ag-grid.
    return value === undefined || value === null ? '' : value;
  };

  const autoGroupColumnDef : ColDef = {
    minWidth: 50,
    onCellClicked,
    sortable: true,
    cellClass: cellClassRowGroup,
    suppressSizeToFit: true,
    headerComponentParams: {
      sorting,
      activeDimensionsLength,
    },
    cellRendererParams: {
      suppressDoubleClickExpand: true,
      suppressEnterExpand: true,
    },
    // for related dimensions, takes in the id column and returns the actual value. Default behaviour for other columns
    cellRenderer,
    tooltipValueGetter: cellRenderer,
    pinned: 'left',
    // for suppressing draging for row grouped columns.
    suppressMovable: true,
    lockPosition: true,
    valueFormatter: (params) => {
      let value = params.value;
      const columns = params.columnApi.getColumns();
      const columnDefs = columns.map((c: Column) => c.getUserProvidedColDef())
        .filter((c: ColDef<any>) => c.field === params.colDef.headerName);
      if (columnDefs && columnDefs.length) {
        // for pivot, data is undefined, extract it from node
        const rowDataVal = params.data || params.node?.allLeafChildren?.[0]?.data;
        const columnOfInterest = columnDefs[0] as GridModel.IColumnConfig;
        if (columnOfInterest && columnOfInterest.parentDataColumn
          && columnOfInterest.parentDataColumn.length) {
          value = params.value ? rowDataVal[columnOfInterest.headerName] : params.value;
        }
        if (columnOfInterest && columnOfInterest.binningType && !columnOfInterest.isMasked && value !== DimensionNoValue) {
          value = getFormattedValuesForBinning(value, columnOfInterest.binningType);
        }
      }
      return value;
    },
    resizable: true,
  };

  useEffect(() => {
    // This is needed to force refresh ag-grid columns. There is a behaviour when ag-grid doesn't update the columns order.
    // This is also seen with the new version.
    if (gridApi && gridApi.current) {
      gridApi.current.setAutoGroupColumnDef(autoGroupColumnDef);
      pinTotalsRowToBottom();
    }
  });

  const colAdvancedConfigs = (
    column: GridModel.IColumnConfig,
  ): { click: boolean; cellClass: any ; valueFormatter: any} => {
    const columnConfig: ICellMeta = {
      click: false,
      cellClass: [],
      valueFormatter: null,
    };

    if (!column.isMasked) {
      columnConfig.valueFormatter = (params:any) => {
        const columnId = params?.colDef?.colId;
        let value = params.value;
        if (value === undefined && params.data && columnId in params.data) {
          value = params.data[columnId];
        }
        return getFormattedValues(value, column.formatter, userTimeZone);
      };
    }
    if (autodrilldown) {
      return columnConfig;
    }
    let isAutodrilldownCol = !column.hide && column.autodrilldown;
    if (hasMaskedField && !allowAutodrilldown) {
      isAutodrilldownCol = false;
    }
    columnConfig.click = !!column.action || isAutodrilldownCol;
    columnConfig.cellClass = (params: CellClassParams) => {
      const classes = [] as string[];
      const headerClasee:string[] = params.colDef?.headerClass as string[];
      classes.push(headerClasee[0] === 'header-right' ? 'align-right' : 'align-left');
      if (column.action) {
        classes.push(getClassnames(params.data, column.action));
      }
      if (isAutodrilldownCol) {
        classes.push(params.value ? `link-primary ${SieraAutoDrilldownLink}` : 'cell-default');
      }
      return classes;
    };

    return columnConfig;
  };

  const resizeTableWidth = () => {
    gridApi.current.sizeColumnsToFit();
    if (gridRef.current.eGridDiv) {
      const totalWidth = gridRef.current.eGridDiv.querySelector('.ag-header-viewport').getBoundingClientRect();
      const currentWidth = gridRef.current.eGridDiv.querySelector('.ag-header-container').getBoundingClientRect();
      if (currentWidth.width < totalWidth.width) {
        gridApi.current.sizeColumnsToFit();
      }
    }
  };

  const onGridReady = (params:any) => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;
    resizeTableWidth();
  };

  const onColumnResized = () => {
    gridApi.current.sizeColumnsToFit();
  };

  const getContextMenuItems = ((params:GetContextMenuItemsParams) => {
    const MenuItems = [
      {
        name: 'Copy',
        icon: '<i class="ag-icon ag-icon-copy"></i>',
        action: () => {
          params.api.copySelectedRangeToClipboard({
            includeGroupHeaders: true,
            includeHeaders: true,
          });
        },
        shortcut: 'Ctrl+C',
        cssClasses: ['se-track-click-copy'],
      },
      {
        name: 'CSV Export',
        icon: '<i class="ag-icon ag-icon-csv"></i>',
        action: () => {
          gridApi.current.exportDataAsCsv();
        },
        cssClasses: ['se-track-click-excelcsv'],
      },
      {
        name: 'Excel Export',
        icon: '<i class="ag-icon ag-icon-excel"></i>',
        action: () => {
          gridApi.current.exportDataAsExcel();
        },
        cssClasses: ['se-track-click-excelexp'],
      },
    ];
    return MenuItems;
  });

  const exportParams = (params:any) => {
    const colDef = params.column.getColDef();

    const columns = params.columnApi.getColumns();
    const columnDefs = columns.map((c: Column) => c.getUserProvidedColDef())
      .filter((c: ColDef<any>) => c.field === colDef.headerName);
    const columnOfInterest = columnDefs[0] as GridModel.IColumnConfig;
    const group = colDef.showRowGroup || columnOfInterest?.headerName || colDef.colId;

    const rowDataVal = params.node?.data?.[group] || params.node?.allLeafChildren?.[0]?.data[group];

    let value = rowDataVal;

    if (colDef.valueFormatter) {
      const valueFormatterParams: ValueFormatterParams = {
        ...params,
        data: params.node.data,
        node: params.node!,
        colDef: params.column.getColDef(),
      };
      value = colDef.valueFormatter(valueFormatterParams);
    }

    //  return formated values in case of date binning
    if (columnOfInterest && columnOfInterest.binningType && !columnOfInterest.isMasked && (params.value !== undefined || null) && rowDataVal !== DimensionNoValue) {
      value = getFormattedValuesForBinning(rowDataVal, columnOfInterest.binningType);
    }

    // we need to return TotalSummayText for the first cell of bottom pinned row
    if (params.column?.pinned === 'left' && params.column?.left === 0 && params?.node?.rowPinned === 'bottom') {
      value = TotalSummaryText;
    }

    // Returns Encrypted text in case of masking
    value = columnOfInterest && columnOfInterest.isMasked ? EncryptedText : value;

    // Remove the range selection after copy
    if (gridApi && gridApi.current) {
      gridApi.current.clearRangeSelection();
    }
    return value;
  };

  const popupParent = useMemo(() => document.querySelector('body'), []);

  const getFileName = () => `${reportTitle}_${formattedCurrentDate()}`;

  const processPivotResultColGroupDefHandler = ((c: any) => {
    if (c.headerName.indexOf(PivotColGrpHeaderSeparator) !== -1) {
      // this is suggested by ag grid docs, hence the eslint ignore
      // eslint-disable-next-line no-param-reassign
      c.headerName = extractValFromStrWithSeparator(c.headerName);
    }
    const columnId = c.groupId.split('_')[1];
    const dateBinningColumn = gridColumns.find((column) => (column.binningType && !column.isMasked ? columnId === column.colId : null));
    // pivotBinningType and dateBinningColumn will not be there for AutoDrilldown columns
    if (dateBinningColumn || pivotBinningType) {
      // Here we'll be using the pivot binning type from the store instead of column property(ag-grid issue)
      const binningType = pivotBinningType || dateBinningColumn.binningType;
      // eslint-disable-next-line no-param-reassign
      c.headerName = c.headerName !== DimensionNoValue && c.headerName !== EncryptedText
        ? getFormattedValuesForBinning(c.headerName, binningType)
        : c.headerName;
    }
  });
  const excelStyles :ExcelStyle[] = [
    {
      id: 'align-right',
      alignment: {
        horizontal: 'Right',
      },

    },
    {
      id: 'align-left',
      alignment: {
        horizontal: 'Left',
      },

    },
  ];

  return (
    <StyledGridComponent>
      <div
        className="ag-theme-alpine ag-grid-cn"
      >
        <AgGridReact
          ref={gridRef}
          paginationPageSize={paginationPageSize}
          onGridReady={onGridReady}
          applyColumnDefOrder
          rowHeight={rowHeight}
          headerHeight={rowHeight}
          suppressDragLeaveHidesColumns={suppressDragLeaveHidesColumns}
          suppressLoadingOverlay
          suppressNoRowsOverlay
          rowData={rowData.map((item) => item.Fields.reduce((acc: any, field: any) => {
            acc[field.Name] = field.Value;
            return acc;
          }, {}))}
          frameworkComponents={{ agColumnHeader: ColumnHeader }}
          onColumnResized={onColumnResized}
          onColumnMoved={onColumnMovedHandler}
          suppressPropertyNamesCheck
          groupDefaultExpanded={-1}
          groupHideOpenParents
          autoGroupColumnDef={autoGroupColumnDef}
          pivotMode={pivotMode}
          suppressAggFuncInHeader
          processPivotResultColGroupDef={processPivotResultColGroupDefHandler}
          processCellForClipboard={(params:any) => exportParams(params)}
          defaultExcelExportParams={{
            processCellCallback: (params:any) => exportParams(params),
            fileName: getFileName(),
            sheetName: getFileName().replace(/[^0-9a-zA-Z_ ]/g, ''),
          }}
          defaultCsvExportParams={{
            processCellCallback: (params:any) => exportParams(params),
            fileName: getFileName(),
          }}
          enableRangeSelection
          getContextMenuItems={getContextMenuItems}
          popupParent={popupParent}
          excelStyles={excelStyles}
          copyGroupHeadersToClipboard
          copyHeadersToClipboard
          alwaysShowHorizontalScroll
          alwaysShowVerticalScroll
          suppressContextMenu={!isExportable}
        >
          {renderColumns(gridColumns)}
        </AgGridReact>

      </div>

    </StyledGridComponent>

  );
};

// The default props values for the grid colums
Grid.defaultProps = {
  rowHeight: 40,
  domLayout: 'autoHeight',
  suppressDragLeaveHidesColumns: true,
};

export default Grid;
