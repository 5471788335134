import styled from 'styled-components';

const StyledButtonsDropdownContainer = styled.div`

width: 100%;
height: 100%;

.se-buttons-dropdown{
  position: relative;
  display: inline-block;
}

.se-dropdown-content {
  position: absolute;
  right: 0;
  top:34px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.16);
  z-index: 1;
  padding: 4px 0px;
}

.se-dropdown-buttons{
    padding:0px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height:32px ;
    :hover{
        .se-dropdown-bar{
            border-color:var(--collapsibleColor);;
        }
    }
}

.se-dropdown-line-seperator{
    border-top: 1px solid rgba(173, 186, 199, 0.25);
}

.se-dropdown-margin{
    margin-top: 2px;
}

.disabled {
    .se-dropdown-bar{
            border: none;
            border-color: #CAD3DB;
    }
}

.border-top{
    border-top: 1px solid rgba(173, 186, 199, 0.25);;
}

.svg-main-button{
    border: 1px solid #CAD3DB;
    border-right: none;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    :hover{
        border-color:var(--collapsibleColor);
        border-right: none !important;
        #Path_7624, #Path_7626{
            fill: var(--collapsibleColor) !important;
        }
    }

    :disabled {
        opacity: 0.5 ;
        cursor: not-allowed ;
        border: 1px solid #CAD3DB!important;
        border-right: none !important;
    }
}
.se-arrow-indicator{
    border: 1px solid #CAD3DB;
    border-left: none !important;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    width: 15px;
    height: 100%;
    margin: 0 auto;
    padding: 0px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover{
        border-color: var(--collapsibleColor);
        #Path_7624, #Path_7626{
            fill: var(--collapsibleColor) !important;
        } 
    }
}

.se-disable {
    border: 1px solid #CAD3DB !important;
        #Path_7624, #Path_7626{
            fill: var(--collapsibleColor) !important;
        }
    :hover {
        border-color: var(--collapsibleColor) !important;
    }    
}
.se-dropdown-bar{
    height: 100%;
    border-left: 1px solid #CAD3DB;
}

.svg-main-button:hover, .se-arrow-indicator:hover{
    border: 1px solid var(--collapsibleColor);

    #Path_7624, #Path_7626{
      fill: var(--collapsibleColor) !important;
    }
  }

.se-dropdown-item{
    display: flex;
    background: #FFFFFF;
    justify-content: center;
    padding: 1px 0px;
    align-items: center;
    border: none;
    width: 180px;
    height: 32px;
}

.se-hide-button{
    display: none;
}

.top-border{
    border-top:1px solid rgba(173, 186, 199, 0.25);
    margin-top: 1px;
    padding: 2px 0px;
}

.disable {
    opacity: 0.5 ;
    cursor: not-allowed ;
}

.se-dropdown-item:hover{
    background-color: #F4F6F8;
}

.se-dropdown-item-svg{
   padding:6px 6px 6px 15px ;
   height: 18px;
}


.se-dropdown-item-name{
    font-size: 13px;
    font-family:'Regular';
    flex-grow: 1;
    text-align: left;
    color: #454F5B;
}

.se-main-text {
    display: inline-block;
    padding: 6px 4px 8px 4px;
    font-family: 'SemiBold';
    font-size: 11px;
    color: #454F5B;
}
.se-main-icon {
    width: 14px;
    height: 14px;
}
`;
export default StyledButtonsDropdownContainer;
