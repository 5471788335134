import { ReportViewActivityType } from 'core/constants/report';
import { ReportViewAction, ReportViewActionTypes } from './report-view.actions';
import { ReportViewState } from './report-view.state';

const initialState: ReportViewState = {
  reportViewList: {
    isReportListLoading: false,
    reportViewItems: [],
  },
  isCreating: false,
  reportViewInfo: {
    isInfoLoading: false,
    reportViewInfoItem: null,
  },
  selectedReportViewItem: null,
  isDeleteLoading: false,
  isDeleteSuccess: false,
  actionId: null,
  isReportViewReportLoading: false,
  isSharingOrUnsharing: false,
  reportViewError: null,
};

const ReportViewReducer = (state: ReportViewState = initialState, action: ReportViewActionTypes) => {
  switch (action.type) {
    case ReportViewAction.REQUEST_REPORTVIEW:
      return {
        ...state,
        reportViewList: {
          reportViewItems: [],
          isReportListLoading: true,
        },
      };
    case ReportViewAction.REQUEST_REPORTVIEW_SUCCESS:
      return {
        ...state,
        reportViewList: {
          ...state.reportViewList,
          isReportListLoading: false,
          reportViewItems: [
            ...action.payload,
          ],
        },
      };
    case ReportViewAction.REQUEST_REPORTVIEW_FAILURE:
      return {
        ...state,
        reportViewList: {
          ...state.reportViewList,
          isReportListLoading: false,
        },
      };
    case ReportViewAction.DELETE_REPORTVIEW:
      return {
        ...state,
        isDeleteLoading: true,
      };
    case ReportViewAction.DELETE_REPORTVIEW_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: true,
        isDeleteLoading: false,
        actionId: action.payload.personalisationId,

      };
    case ReportViewAction.DELETE_REPORTVIEW_FAILURE:
      return {
        ...state,
        isDeleteSuccess: false,
        isDeleteLoading: false,
        actionId: null,
      };
    case ReportViewAction.DELETE_REPORTVIEW_COMPELETE:
      return {
        ...state,
        reportViewList: {
          ...state.reportViewList,
          reportViewItems: state.reportViewList.reportViewItems.filter((item) => item.PersonalisationId !== action.payload),
        },
        isDeleteSuccess: false,
        isDeleteLoading: false,
        actionId: null,
      };
    case ReportViewAction.CREATE_REPORTVIEW:
      return {
        ...state,
        isCreating: true,
      };
    case ReportViewAction.CREATE_REPORTVIEW_SUCCESS:
      return {
        ...state,
        isCreating: false,
        reportViewList: {
          ...state.reportViewList,
          reportViewItems: [
            ...state.reportViewList.reportViewItems,
            action.payload,
          ],
        },
      };
    case ReportViewAction.CREATE_REPORTVIEW_FAILURE:
      return {
        ...state,
      };
    case ReportViewAction.REQUEST_REPORTVIEW_INFO:
      return {
        ...state,
        reportViewInfo: {
          ...state.reportViewInfo,
          isInfoLoading: action.payload === 'tooltip',
        },
        isReportViewReportLoading: action.payload === 'reportSelect',
      };
    case ReportViewAction.REQUEST_REPORTVIEW_INFO_SUCCESS:
    {
      const { personalisationId, reportViewInfo, context } = action.payload;
      return {
        ...state,
        reportViewInfo: {
          ...state.reportViewInfo,
          isInfoLoading: false,
          reportViewInfoItem: { ...reportViewInfo },
        },
        selectedReportViewItem: context === 'reportSelect' ? personalisationId : '',
        isReportViewReportLoading: false,
      };
    }
    case ReportViewAction.REQUEST_REPORTVIEW_INFO_FAILURE:
    {
      return {
        ...state,
        reportViewInfo: {
          ...state.reportViewInfo,
          isInfoLoading: false,
        },
        isReportViewReportLoading: false,
        reportViewError: 'Deleted',
      };
    }
    case ReportViewAction.RESET_REPORT_VIEW:
      return {
        ...initialState,
      };
    case ReportViewAction.SHARE_OR_UNSHARE_REPORT_VIEW: {
      return {
        ...state,
        isSharingOrUnsharing: true,
      };
    }
    case ReportViewAction.SHARE_OR_UNSHARE_REPORT_VIEW_SUCCESS: {
      return {
        ...state,
        reportViewList: {
          ...state.reportViewList,
          reportViewItem: state.reportViewList.reportViewItems.forEach((item) => {
            if (item.PersonalisationId === action.payload.PersonalisationId) {
              if (action.payload.Activity === ReportViewActivityType.Shared) {
                // eslint-disable-next-line no-param-reassign
                item.IsShared = true;
              } else {
                // eslint-disable-next-line no-param-reassign
                item.IsShared = false;
              }
            }
          }),
        },
        isSharingOrUnsharing: false,
        actionId: action.payload.PersonalisationId,
      };
    }
    case ReportViewAction.SHARE_OR_UNSHARE_REPORT_VIEW_FAILURE: {
      return {
        ...state,
        isSharingOrUnsharing: false,
        actionId: null,
      };
    }

    case ReportViewAction.SHARE_OR_UNSHARE_REPORT_VIEW_COMPLETE:
      return {
        ...state,
        reportViewList: {
          ...state.reportViewList,
          reportViewItems: state.reportViewList.reportViewItems.filter((item) => item.PersonalisationId !== action.payload),
        },
        actionId: '',
        isSharingUnsharing: false,
      };
    default:
      return state;
  }
};

export default ReportViewReducer;
