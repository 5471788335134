import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const CalendarIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="calendar_today_black_24dp_1_"
    data-name="calendar_today_black_24dp (1)"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 20 20"
  >
    <path
      id="Path_7606"
      data-name="Path 7606"
      d="M0,0H20V20H0Z"
      style={{
        fill: 'none',
      }}
    />
    <path
      id="Path_7607"
      data-name="Path 7607"
      d="M17.035,2.668H16.2V1H14.529V2.668H6.176V1H4.506V2.668H3.671A1.674,1.674,0,0,0,2,4.337V17.684a1.674,1.674,0,0,0,1.671,1.668H17.035a1.674,1.674,0,0,0,1.671-1.668V4.337A1.674,1.674,0,0,0,17.035,2.668Zm0,15.016H3.671V8.508H17.035Zm0-10.845H3.671v-2.5H17.035Z"
      transform="translate(-0.353 -0.176)"
      style={{
        fill,
      }}
    />
  </svg>
);

CalendarIcon.defaultProps = {
  fill: '#000000',
};

export default CalendarIcon;
