import React, { useEffect, useState } from 'react';
import AuthService from 'services/auth.service';
import FullScreenLoader from 'components/common/loader/FullscreenLoader/fullscreen-loader.component';
import { CleanUp } from 'core/models/cleanUp';
import store from 'redux/store';
import { setAuth, setMarvinAuth, setV1ReportContext } from 'redux/user-auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from 'components/feature/Report/ReportSidebar/ReportView/report-view.selector';

type Props = {
  Component: React.ComponentType;
}

const Auth = ({ Component, ...props }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAuthorized, setIsAuthourized] = useState<boolean>(false);
  const dispatch = useDispatch();

  const {
    isV1Report, isMarvin, statusCode,
  } = useSelector(authSelector);

  useEffect(() => {
    const cleanUpObj: CleanUp = { isMounted: true };
    authorize(cleanUpObj);
    return () => { cleanUpObj.isMounted = false; };
  }, []);

  useEffect(() => {
    const retryCreateAuth = async () => {
      if (isMarvin && statusCode === 401) {
        if (await new AuthService().createAuth(true)) {
          setIsAuthourized(true);
          setIsLoading(false);
        }
      }
    };

    retryCreateAuth();
  }, [statusCode]);

  const authorize = async (cleanUpObj: CleanUp) => {
    if (!isLoading && !isAuthorized) {
      if (!isV1Report) {
        const isV1ReportResponse = getV1ReportInfo();
        dispatch(setV1ReportContext(isV1ReportResponse));
      }
      if (!isMarvin) {
        dispatch(setAuth(null));
        const marvinInfo = await getMarvinInfo();
        dispatch(setMarvinAuth(marvinInfo));
      }
      setIsLoading(true);
      if (await new AuthService().isAuthorized()) {
        if (cleanUpObj.isMounted) {
          setIsAuthourized(true);
          setIsLoading(false);
        }
      }
    }
  };

  const getMarvinInfo = async () => {
    const params = new URLSearchParams(window.location.search);
    return params.has('isMarvin');
  };

  const getV1ReportInfo = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('context') === 'V1Report';
  };

  if (isLoading) {
    return (
      <FullScreenLoader />
    );
  }

  if (isAuthorized) {
    return (
      <Component
        {...props}
      />
    );
  }
  return null;
};

export default Auth;
