import React from 'react';

type Props = {
    width: number,
    height: number,
    fill?: string,
}

const SimpleDownArrowIcon = ({ width, height, fill }: Props) => (
  <svg width={width} height={height} viewBox="0 0 8 5" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M1.19807 0.00135946L4.02607 2.82436L6.85707 0.00135946L7.72607 0.868359L4.02607 4.56836L0.326074 0.868359L1.19807 0.00135946Z" fill="#454F5B" />
  </svg>
);

SimpleDownArrowIcon.defaultProps = {
  fill: '#000000',
};

export default SimpleDownArrowIcon;
