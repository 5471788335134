import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const OpportunityIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 12.093 11.937"
  >
    <g
      id="Opportunity_1_"
      data-name="Opportunity (1)"
      transform="translate(-1.88 -1.992)"
    >
      <path id="Path_10546" data-name="Path 10546" d="M9.665,10.48A3.439,3.439,0,1,0,13.1,13.913,3.439,3.439,0,0,0,9.665,10.48Zm0,5.865A2.432,2.432,0,1,1,12.1,13.913,2.432,2.432,0,0,1,9.665,16.345Z" transform="translate(-1.753 -3.429)" fill={fill} />
      <path id="Path_10547" data-name="Path 10547" d="M9.889,3.7V6.461H10.9V3.7l1.144,1.15.727-.7L10.736,2.134a.507.507,0,0,0-.7,0L8,4.142l.727.7Z" transform="translate(-2.472)" fill={fill} />
      <path id="Path_10548" data-name="Path 10548" d="M19.147,5.669H16.3V6.677h1.621L16,8.661l.7.7L18.664,7.4V9.031h1.013V6.17a.5.5,0,0,0-.53-.5Z" transform="translate(-5.704 -1.485)" fill={fill} />
      <path id="Path_10549" data-name="Path 10549" d="M4.842,9.365l.685-.7L3.591,6.707H5.206V5.67H2.381a.5.5,0,0,0-.5.5V9.014H2.887V7.392l1.9,1.919Z" transform="translate(0 -1.486)" fill={fill} />
    </g>
  </svg>
);

OpportunityIcon.defaultProps = {
  fill: '#919eab',
};

export default OpportunityIcon;
