import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const TabularIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 24 24"
  >
    <g
      id="Tabular"
      transform="translate(-628 -130)"
    >
      <path
        id="Path_7441"
        data-name="Path 7441"
        d="M17.316,3H4.684A1.738,1.738,0,0,0,3,4.778V17.222A1.738,1.738,0,0,0,4.684,19H17.316A1.738,1.738,0,0,0,19,17.222V4.778A1.738,1.738,0,0,0,17.316,3Zm0,1.778V7.444H4.684V4.778ZM13.105,17.222H8.895v-8h4.211Zm-8.421-8H7.211v8H4.684Zm10.105,8v-8h2.526v8Z"
        transform="translate(628.983 130.983)"
        fill={fill}
      />
    </g>
  </svg>
);

TabularIcon.defaultProps = {
  fill: '#3bb345',
};

export default TabularIcon;
