import React from 'react';

type Props = {
  width: number;
  height: number;
}

const EmptyListIcon = ({
  width, height,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{
      width,
      height,
    }}
    viewBox="0 0 24.438 26.489"
  >
    <defs>
      <filter id="Path_10184" x="4.185" y="0" width="19.67" height="26.489" filterUnits="userSpaceOnUse">
        <feOffset dy="3" />
        <feGaussianBlur result="blur" />
        <feFlood floodColor="#f4f6f8" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_21829" data-name="Group 21829" transform="translate(-922 -388.954)">
      <path id="list_FILL0_wght400_GRAD0_opsz48" d="M11.77,16.036V14H30.438v2.036Zm0,5.77V19.77H30.438v2.036Zm0,5.77V25.54H30.438v2.036ZM7.018,16.036a.987.987,0,1,1,.73-.289A.989.989,0,0,1,7.018,16.036Zm0,5.77a.987.987,0,1,1,.73-.288A.989.989,0,0,1,7.018,21.807Zm0,5.77a.987.987,0,1,1,.73-.288A.989.989,0,0,1,7.018,27.577Z" transform="translate(916 380.58)" fill="#454f5b" />
      <g transform="matrix(1, 0, 0, 1, 922, 388.95)" filter="url(#Path_10184)">
        <path id="Path_10184-2" data-name="Path 10184" d="M8519.935-7684.453l12.464,15.324,5.657,6.9" transform="translate(-8514.97 7685.08)" fill="#fff" stroke="#454f5b" strokeWidth="2" />
      </g>
    </g>
  </svg>
);

export default EmptyListIcon;
