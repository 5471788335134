import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Tooltip from 'rc-tooltip';
import {
  DraggableItemState,
  IDraggableItemProps,
  StateToClassMapping,
} from 'components/feature/Report/ReportSidebar/common/DraggableItem/draggable-item.d';
import StyledContainer from 'components/feature/Report/ReportSidebar/common/DraggableItem/draggable-item.style';
import DraggableIcon from 'components/common/svg/Draggable/draggable.svg';
import CloseIcon from 'components/common/svg/Close/close.svg';
import InfoIcon from 'components/common/svg/Info/info.svg';
import AddIcon from 'components/common/svg/Add/add.svg';
import NewReplaceIcon from 'components/common/svg/ReplaceIcon/replaceIcon.svg';
import { FieldEntities } from 'core/utils/report.util';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';
import ReactSelect from 'components/common/select/ReactSelect/react-select.component';
import { DateTimeBinning } from 'core/constants/report';
import { components } from 'react-select';
import TickIcon from 'components/common/svg/Tick/tick.svg';
import ArrowIcon from 'components/common/svg/ArrowDown/arrow-down.svg';
import { useDispatch } from 'react-redux';
import { setBinning } from 'redux/report/actions';
import { DateBinningFormatters } from 'core/constants/formatters';

const Option = (props: any) => {
  const { children, isSelected, data } = props;
  return (
    <components.Option {...props}>
      <div className="se-react-select-option-cn">
        <span
          className="rs-option-label-cn"
        >
          {children || '(empty)'}
        </span>
        <div className="rs-option-example">
          {data.example}
        </div>
      </div>
      <span>
        {
          isSelected && (
            <TickIcon
              width={10}
              height={8}
            />
          )
        }
      </span>
    </components.Option>
  );
};

const DropdownIndicator = (props: any) => {
  const { selectProps } = props;
  return (
    <components.DropdownIndicator {...props}>
      {
        selectProps.dropdownIndicator && (
          <>
            {
              selectProps.menuIsOpen
                ? (
                  <span
                    className="se-react-select-dropdown-indicator-rotate"
                  >
                    <ArrowIcon
                      width={19}
                      height={16}
                    />
                  </span>
                )
                : (
                  <ArrowIcon
                    width={19}
                    height={16}
                  />
                )
            }
          </>
        )
      }
    </components.DropdownIndicator>
  );
};

const ValueContainer = (props: any) => {
  const {
    children, hasValue,
  } = props;
  let element = null;
  if (hasValue) {
    element = (
      <div className="se-react-select-single-select-cn">
        {children[0]?.props?.data?.abbreviation || children[0]?.props?.data?.label}
      </div>
    );
  }
  return (
    <components.ValueContainer {...props}>
      {element}
      {children}
    </components.ValueContainer>
  );
};

// Represents a draggable dimension item in Visualization Settings.
const DraggableItem = <T, >(props: IDraggableItemProps<T>) => {
  const {
    draggableId, text, index, data, state: itemState = DraggableItemState.Default,
    showCloseButton, onClickClose, isCloseButtonDisabled,
    disabledCloseButtonOverlay, infoOverlay, infoOverlayClassName,
    isDragDisabled, className = '', textTooltipContent, borderClassName = '',
    addBorderClass, entity = '', binningType,
  } = props;

  const [beingRemoved, setBeingRemoved] = useState(false);

  // reordering the options to put the selected item in the first place
  const dateBinningOptions:any = [];
  if (binningType) {
    dateBinningOptions.push(...DateTimeBinning.filter((item:any) => item.value === binningType));// selected item
    dateBinningOptions.push(...DateTimeBinning.filter((item:any) => item.value !== binningType));// non selected items
  } else {
    dateBinningOptions.push(...DateTimeBinning);
  }

  const borderClass = borderClassName || StateToClassMapping[itemState] || '';

  const dispatch = useDispatch();

  return (
    <>
      <Draggable draggableId={draggableId} index={index} isDragDisabled={!!beingRemoved || isDragDisabled}>
        {(draggableProvided) => (
          <StyledContainer
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            // enforce all items to look the same while dragging, for dnd to render proper placeholder
            className={`${className} ${itemState !== DraggableItemState.Default ? 'se-dim-dragging' : ''}`}
          >
            <div className={`se-draggable-item ${borderClass} ${addBorderClass} ${beingRemoved ? 'se-disable-pointer se-remove-red-border' : ''}`}>
              {
               entity && (
               <div
                 className="se-entity-indicator-cn"
               >
                 <div
                   className="se-entity-indicator"
                   style={{
                     background: FieldEntities[entity].color,
                   }}
                 />
               </div>
               )
            }
              <div className="se-icon-cn se-drag-icon">
                <DraggableIcon
                  width={16}
                  height={16}
                  fill={`${itemState === DraggableItemState.Dragging || itemState === DraggableItemState.AboutToBeCombined || itemState === DraggableItemState.AboutToBeAdded || itemState === DraggableItemState.DropDisallowed ? 'var(--base-font-color)' : 'var(--arrowIcon)'}`}
                />
              </div>
              <Tooltip
                placement="bottom"
                overlay={(
                  <div>
                    {textTooltipContent || text}
                  </div>
                )}
                mouseEnterDelay={1.5}
              >
                <div className="se-item-name">
                  {text}
                </div>
              </Tooltip>
              {
                itemState === DraggableItemState.AboutToBeAdded && (
                  <div className="se-icon-cn">
                    <AddIcon
                      width={16}
                      height={16}
                      strokeFill="#454f5b"
                    />
                  </div>
                )
              }
              {
                  itemState === DraggableItemState.AboutToBeCombined && (
                  <div className="se-icon-cn">
                    <NewReplaceIcon
                      width={12}
                      height={12}
                    />
                  </div>
                  )
              }
              {
                !!infoOverlay && (
                  <Tooltip
                    placement="leftTop"
                    arrowContent={(
                      <div className="rc-tooltip-arrow-inner" />
                    )}
                    overlay={infoOverlay}
                    overlayClassName={infoOverlayClassName}
                  >
                    <div className="se-icon-cn se-info-icon">
                      <InfoIcon
                        width={16}
                        height={16}
                      />
                    </div>
                  </Tooltip>
                )
              }
              {binningType && (
              <div className={`se-date-binning ${binningType !== DateBinningFormatters.NONE ? 'set-binning-border' : ''}`}>
                <ReactSelect
                  config={{
                    Key: 'date-binning',
                    Meta: {
                      ClassName: 'se-date-binning',
                      Options: dateBinningOptions,
                      DropdownIndicator: true,
                      Searchable: false,
                      Clearable: false,
                      AppliedOnClose: false,
                      CloseOnSelect: true,
                      Components: {
                        Option,
                        DropdownIndicator,
                        ValueContainer,
                      },
                    },
                  }}
                  value={binningType}
                  onChange={(key, value) => dispatch(setBinning(data, value))}
                />
              </div>
              )}
              {
                showCloseButton && !isCloseButtonDisabled && (
                  // TODO: Make this a button.
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <div
                    className={`se-icon-cn se-item-close-btn se-close-remove-field ${PendoClassNames.RemoveField}`}
                    onClick={() => {
                      setBeingRemoved(true);
                      return (onClickClose && setTimeout(() => {
                        setBeingRemoved(false);
                        onClickClose(data);
                      }, 600));
                    }}
                  >
                    <CloseIcon
                      width={16}
                      height={16}
                    />
                  </div>
                )
              }
              {
                showCloseButton && isCloseButtonDisabled && !!disabledCloseButtonOverlay && (
                  <Tooltip
                    placement="top"
                    overlay={disabledCloseButtonOverlay}
                  >
                    <div className="se-icon-cn se-item-close-btn se-item-close-btn-disabled">
                      <CloseIcon
                        width={16}
                        height={16}
                      />
                    </div>
                  </Tooltip>
                )
              }
            </div>
          </StyledContainer>
        )}
      </Draggable>
    </>
  );
};

export default DraggableItem;
