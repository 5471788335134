/* eslint-disable no-shadow */
export enum NavSections {
    Reports = 'Reports',
    Dashboard = 'Dashboard'
}

export enum NavSectionSubTypes {
    Pinned = 'pinned',
    Category = 'category',
}
