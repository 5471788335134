import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const SuccessIcon = ({
  width, height, fill,
}: Props) => (
  <svg id="file_download_black_24dp_2_" data-name="file_download_black_24dp (2)" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <g id="Group_21962" data-name="Group 21962">
      <rect id="Rectangle_7951" data-name="Rectangle 7951" width={width} height={height} fill="none" />
    </g>
    <g id="Group_21963" data-name="Group 21963" transform="translate(2.871 2.756)">
      <path id="Path_10248" data-name="Path 10248" d="M16.59,13.892v2.7H5.8v-2.7H4v2.7a1.8,1.8,0,0,0,1.8,1.8H16.59a1.8,1.8,0,0,0,1.8-1.8v-2.7Zm-.9-3.6L14.423,9.027l-2.329,2.32V4H10.3v7.347L7.966,9.027,6.7,10.3l4.5,4.5Z" transform="translate(-4 -4)" fill="#006fd6" />
    </g>
  </svg>

);

SuccessIcon.defaultProps = {
  fill: '#919eab',
};

export default SuccessIcon;
