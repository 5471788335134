import styled from 'styled-components';

const StyledReportComponent = styled.div`
    width: 160px;
    height: 32px;

    .se-report-list-sorting-dropdown {
        .se-react-select__control {
            width: 160px;
            height: 32px;
            border-width: 0.5px;
            border-color: #f9fafb;
            border-radius: 2px;
            cursor: pointer;
            background-color: #f9fafb;
            :hover {
              borderColor: #1172D3;
            }
        }

        .se-react-select__value-container {
            font-size: 12px !important;
            font-family: SemiBold !important;
            color: #454f5b !important;
        }

        .se-react-select__option {
            font-size: 13px;
            color: #637381;
        }

        .se-react-select__dropdown-indicator {
            color: #454F5B;
        }
    }
`;

export default StyledReportComponent;
