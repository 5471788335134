import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
};

const FilterCloseIcon = ({ width, height, fill }: Props) => (
  <svg
    id="close_black_24dp_3_"
    data-name="close_black_24dp (3)"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 12 12"
  >
    <path
      id="Path_7102"
      data-name="Path 7102"
      d="M0,0H12V12H0Z"
      fill="none"
    />
    <path
      id="Path_7103"
      data-name="Path 7103"
      d="M12.5,5.755,11.745,5,8.75,7.995,5.755,5,5,5.755,7.995,8.75,5,11.745l.755.755L8.75,9.505,11.745,12.5l.755-.755L9.505,8.75Z"
      transform="translate(-2.75 -2.75)"
      fill={fill}
    />
  </svg>
);

FilterCloseIcon.defaultProps = {
  fill: '#262626',
};

export default FilterCloseIcon;
