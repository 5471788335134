import React, { useState } from 'react';
import { ISettingsProps } from 'components/feature/Report/ReportSidebar/Settings/settings.d';
import StyledContainer from 'components/feature/Report/ReportSidebar/Settings/settings.style';
import StoreConnector from 'components/feature/Report/ReportSidebar/Settings/settings.redux';
import Dimensions from 'components/feature/Report/ReportSidebar/Settings/Dimensions/dimensions.component';
import Measures from 'components/feature/Report/ReportSidebar/Settings/Measures/measures.component';
import ApplySettingsButton
  from 'components/feature/Report/ReportSidebar/common/ApplySettingsButton/apply-settings.component';
import {
  areVisualisationSettingsPristine,
  compareDrilldownFields,
} from 'core/utils/report-builder.util';
import { isEmpty } from 'lodash';
import DrilldownDimensions from 'components/feature/Report/ReportSidebar/Settings/DrilldownDimensions/drilldown-dimensions-component';

const Settings = (props: ISettingsProps) => {
  const {
    reduxState, reduxAction, reportBuilderPortal, toolbarExpanded,
  } = props;
  const { report } = reduxState;
  const {
    appliedDimensions, activeDimensions, autodrilldownData, columns, allMeasures,
  } = report;
  const { applyDimensions, autodrilldownReportDataLoadRequest } = reduxAction;

  const [applyClickCount, setApplyClickCount] = useState(0);
  const [applyClickCountDrilldown, setApplyClickCountDrilldown] = useState(0);
  const [measuresPanelExpanded, setMeasuresPanelExpanded] = useState(false);

  const isAutodrilldown = autodrilldownData && !isEmpty(autodrilldownData.reportData);

  return (
    <StyledContainer>
      <div
        className="se-report-settings"
      >
        <div className="se-dimensions-settings se-custom-scrollbar">
          {
            isAutodrilldown
              ? (
                <DrilldownDimensions
                  reportBuilderPortal={reportBuilderPortal}
                  toolbarExpanded={toolbarExpanded}
                  applyClickCountDrilldown={applyClickCountDrilldown}
                />
              )
              : (
                <>
                  <Dimensions
                    reportBuilderPortal={reportBuilderPortal}
                    toolbarExpanded={toolbarExpanded}
                    applyClickCount={applyClickCount}
                  />
                  <Measures
                    reportBuilderPortal={reportBuilderPortal}
                    panelExpanded={measuresPanelExpanded}
                    setPanelExpanded={setMeasuresPanelExpanded}
                  />
                </>
              )
          }
        </div>
        <div
          className="se-apply-settings"
        >
          {
            isAutodrilldown
              ? (
                <ApplySettingsButton
                  onApply={() => {
                    autodrilldownReportDataLoadRequest();
                    setApplyClickCountDrilldown(applyClickCountDrilldown + 1);
                  }}
                  disabled={compareDrilldownFields(autodrilldownData.drilldownFields, autodrilldownData.columns)}
                />
              )
              : (
                <ApplySettingsButton
                  onApply={() => {
                    applyDimensions();
                    setApplyClickCount(applyClickCount + 1);
                  }}
                  disabled={areVisualisationSettingsPristine(appliedDimensions, activeDimensions, columns, allMeasures)}
                />
              )
          }

        </div>
      </div>
    </StyledContainer>
  );
};

export default StoreConnector()(Settings);
