import React from 'react';
import Tooltip from 'rc-tooltip';
import {
  ISingleSelectListProps,
} from 'components/feature/Report/ReportSidebar/common/SingleSelectList/single-select-list.d';
import StyledContainer from 'components/feature/Report/ReportSidebar/common/SingleSelectList/single-select-list.style';
import SingleSelectItem
  from 'components/feature/Report/ReportSidebar/common/SingleSelectList/SingleSelectItem/single-select-item.component';

const SingleSelectList = <T, >(props: ISingleSelectListProps<T>) => {
  const { items, onSelectItem, getDisabledHelpText } = props;
  return (
    <StyledContainer>
      {items.map((item) => {
        let disabledHelpText = '';
        if (item.isDisabled) {
          disabledHelpText = getDisabledHelpText(item.data);
        }

        if (item.isDisabled && disabledHelpText !== '') {
          return (
            <Tooltip
              key={item.id}
              placement="bottom"
              overlay={(
                <span>{disabledHelpText}</span>
              )}
              overlayClassName="se-dimension-item-grouping-limit-tooltip"
            >
              <SingleSelectItem {...item} onSelectItem={onSelectItem} />
            </Tooltip>
          );
        }
        return <SingleSelectItem key={item.id} {...item} onSelectItem={onSelectItem} />;
      })}
    </StyledContainer>
  );
};

export default SingleSelectList;
