import styled from 'styled-components';

const StyledEmptyState = styled.div`
width:100%;
height:100%;
display: flex;
justify-content: center;
align-items:center;

 .se-empty-state-text{
     font-family: 'Regular';
     font-size: 12px;
     color: var(--paginationColor);
     margin-top: 12px;
     padding: 0 30px;
     text-align: center;
}

.se-empty-list-icon{
    margin:0 auto;
    width:60px;
    height:60px;
    display:flex;
    justify-content:center;
    align-items: center;
    background:#F4F6F8;
    border-radius: 100%;
}
`;

export default StyledEmptyState;
