import React from 'react';

type Props = {
  width: number;
  height: number;
}

const PdfChartIcon = ({
  width, height,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.666 1.66699H5.00001C4.5583 1.66831 4.13506 1.84436 3.82272 2.1567C3.51038 2.46904 3.33433 2.89228 3.33301 3.33399V16.667C3.33221 17.1078 3.50635 17.5309 3.8172 17.8434C4.12805 18.156 4.55021 18.3324 4.99101 18.334H15C15.4415 18.3324 15.8645 18.1562 16.1767 17.8439C16.4888 17.5316 16.6647 17.1085 16.666 16.667V6.66699L11.666 1.66699ZM14.999 16.667H4.99901V3.33399H10.833V7.49999H15L14.999 16.667Z"
      fill="#8E27B3"
    />
    <path
      d="M1 9H12C12.2652 9 12.5196 9.10536 12.7071 9.29289C12.8946 9.48043 13 9.73478 13 10V14C13 14.2652 12.8946 14.5196 12.7071 14.7071C12.5196 14.8946 12.2652 15 12 15H1C0.734784 15 0.48043 14.8946 0.292893 14.7071C0.105357 14.5196 0 14.2652 0 14V10C0 9.73478 0.105357 9.48043 0.292893 9.29289C0.48043 9.10536 0.734784 9 1 9V9Z"
      fill="#8E27B3"
    />
    <path
      d="M4.65438 11.3349C4.65438 11.5215 4.60938 11.6965 4.51938 11.8599C4.43271 12.0232 4.29438 12.1549 4.10438 12.2549C3.91771 12.3549 3.68104 12.4049 3.39437 12.4049H2.80938V13.7449H2.10938V10.2549H3.39437C3.66437 10.2549 3.89438 10.3015 4.08438 10.3949C4.27438 10.4882 4.41604 10.6165 4.50938 10.7799C4.60604 10.9432 4.65438 11.1282 4.65438 11.3349ZM3.36438 11.8399C3.55771 11.8399 3.70104 11.7965 3.79438 11.7099C3.88771 11.6199 3.93438 11.4949 3.93438 11.3349C3.93438 10.9949 3.74438 10.8249 3.36438 10.8249H2.80938V11.8399H3.36438Z"
      fill="white"
    />
    <path
      d="M6.37137 10.2549C6.73803 10.2549 7.0597 10.3265 7.33637 10.4699C7.61637 10.6132 7.83137 10.8182 7.98137 11.0849C8.1347 11.3482 8.21137 11.6549 8.21137 12.0049C8.21137 12.3549 8.1347 12.6615 7.98137 12.9249C7.83137 13.1849 7.61637 13.3865 7.33637 13.5299C7.0597 13.6732 6.73803 13.7449 6.37137 13.7449H5.15137V10.2549H6.37137ZM6.34637 13.1499C6.71303 13.1499 6.99637 13.0499 7.19637 12.8499C7.39637 12.6499 7.49637 12.3682 7.49637 12.0049C7.49637 11.6415 7.39637 11.3582 7.19637 11.1549C6.99637 10.9482 6.71303 10.8449 6.34637 10.8449H5.85137V13.1499H6.34637Z"
      fill="white"
    />
    <path
      d="M10.8904 10.2549V10.8199H9.43535V11.7149H10.5504V12.2699H9.43535V13.7449H8.73535V10.2549H10.8904Z"
      fill="white"
    />
  </svg>

);

export default PdfChartIcon;
