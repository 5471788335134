import CacheItem from 'core/constants/cache';
import { StoreModel } from 'core/models';
import Cache from '../../core/cache/cache';
import * as ActionTypes from './action-types';

const initialState = (): StoreModel.IState => {
  const token = Cache.getItem(CacheItem.Auth);
  const expiresOn = Cache.getItem(CacheItem.AuthExpiry) || -1;
  const userAttributes = Cache.getItem(CacheItem.UserAttr);
  return {
    token,
    expiresOn,
    userAttributes,
    variables: {
      SER_USER_ID: userAttributes ? userAttributes.Id : '',
      SER_USER_AUTO_ID: userAttributes ? userAttributes.AutoId : 0,
    },
    isReportingDisabled: false,
    isMarvin: false,
    isV1Report: false,
    statusCode: 200,
  };
};

const reducer = (
  state: StoreModel.IState = initialState(), action: StoreModel.Action,
) => {
  switch (action.type) {
    case ActionTypes.SET_AUTH: {
      const { auth } = action.payload;
      if (auth) {
        const { Token, ExpiresOn, UserAttributes } = auth;
        Cache.setItem(CacheItem.Auth, Token);
        Cache.setItem(CacheItem.AuthExpiry, ExpiresOn);
        Cache.setItem(CacheItem.UserAttr, UserAttributes);
        return ({
          ...state,
          token: Token,
          expiresOn: ExpiresOn,
          userAttributes: UserAttributes,
          variables: {
            SER_USER_ID: UserAttributes ? UserAttributes.Id : '',
            SER_USER_AUTO_ID: UserAttributes ? UserAttributes.AutoId : 0,
          },
        });
      }
      Cache.clear();
      return ({
        ...state,
        token: null,
        expiresOn: -1,
        userAttributes: null,
      });
    }
    case ActionTypes.SET_REPORTING_RIGHTS: {
      const isReportingDisabled = action.payload as boolean;
      return {
        ...state,
        isReportingDisabled,
      };
    }
    case ActionTypes.SET_MARVIN_AUTH: {
      const isMarvin = action.payload;
      return {
        ...state,
        isMarvin,
      };
    }
    case ActionTypes.SET_V1_REPORT_CONTEXT: {
      const isV1Report = action.payload;
      return {
        ...state,
        isV1Report,
      };
    }
    case ActionTypes.SET_STATUS_ERROR_CODE: {
      const statusCode = action.payload;
      return {
        ...state,
        statusCode,
      };
    }
    default:
      return state;
  }
};

export default reducer;
