import React, { ReactNode } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { isEmpty } from 'lodash';
import { IDateRangeTypes } from 'core/constants/date';
import { FilterType, UserFilterPayloadType } from 'core/constants/filter';
import { dateRangeShowcase, isValidDateRangePayload } from 'core/utils/date.util';
import {
  DimensionIdentifier,
  DimensionMode,
  DimensionStatus,
  headerOptions,
  ReportDimensionsSettingsTitles,
  ReportDimensionsSettingsTypes,
  ReportViewTypes,
} from 'core/constants/report';
import {
  FilterModel, ObjModel, ReportReduxModel, ReportResponseModel,
} from 'core/models';
import StyledContainer from 'components/feature/Report/ReportSidebar/ReportView/style/report-view-info.style';
import InfoIcon from 'components/common/svg/Info/info.svg';
import 'react-popper-tooltip/dist/styles.css';
import { hasUserFilterValue } from 'core/utils/report.util';
import { displayName } from 'components/feature/Report/ReportSidebar/common/helpers';
import { getReportViewInfo } from 'redux/report-view/report-view.actions';
import { useDispatch, useSelector } from 'react-redux';
import BarLoader from 'components/common/loader/BarLoader/bar-loader.component';
import { IReportViewInfo } from 'types/report-view.type';
import { reportSelector, reportViewSelector } from './report-view.selector';
import { PendoClassNames } from '../common/constants';

interface ReportViewInfoProps {
    stateIndex: number;
    ViewType: ReportViewTypes;
}

const getFilterValueLayout = (
  filterType: string, value: any, filterOptions: Array<FilterModel.ISelectFilterOptions> = [],
  isReportView: boolean = false, ViewType: ReportViewTypes,
): ReactNode => {
  if (ViewType === ReportViewTypes.Default && isEmpty(value) && isEmpty(filterOptions)) {
    return (
      <div
        className="se-filter-value"
      >
        All
      </div>
    );
  }
  if (value) {
    switch (filterType) {
      case FilterType.DateRange: {
        return (isValidDateRangePayload(value) && (
          <div
            className="se-filter-value"
          >
            {
              value.Type === IDateRangeTypes.Absolute
                ? dateRangeShowcase(value.Value.From, value.Value.To)
                : value.Value
            }
          </div>
        )) || '--';
      }

      case FilterType.UserMultiSelect: {
        let displayText = '';
        switch (value.Type) {
          case UserFilterPayloadType.All:
            displayText = 'All';
            break;
          case UserFilterPayloadType.In:
            displayText = value.UserIds.length;
            break;
          case UserFilterPayloadType.NotIn:
            displayText = `All except ${value.UserIds.length}`;
            break;
          default:
            break;
        }
        return (
          <div
            className="se-filter-value"
          >
            {displayText}
          </div>
        );
      }
      case FilterType.GroupMultiSelect:
      case FilterType.CustomDefinedMultiSelect:
      case FilterType.LSQMetadataMultiSelect: {
        return isEmpty(value) ? filterOptions.map((item:any) => (
          <div
            className="se-filter-value"
            key={item.value}
          >
            {
                item ? item.label : '--'
              }
          </div>
        )) : value.map((item: any) => {
          const option = isReportView ? item : filterOptions.find((i) => i.value === item);
          return (
            <div
              className="se-filter-value"
              key={option.value}
            >
              {
                option ? option.label : '--'
              }
            </div>
          );
        });
      }

      case FilterType.LSQMetadataSingleSelect:
      case FilterType.CustomDefinedSingleSelect: {
        const option = isReportView ? value : filterOptions.find((i) => i.value === value);
        return isEmpty(value) ? filterOptions.map((item:any) => (
          <div
            className="se-filter-value"
            key={item.value}
          >
            {
                item ? item.label : '--'
              }
          </div>
        )) : (
          <div>
            {
              option ? option.label : '--'
            }
          </div>
        );
      }

      default:
        break;
    }
  }

  return null;
};

const getFilterInfoLayout = (
  filterConfig: Array<ReportResponseModel.IFilterConfig>, appliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>,
  stateIndex: number, dynamicDimensions: Array<ReportResponseModel.IColumn>, reportViewInfo: IReportViewInfo, ViewType: ReportViewTypes,
): ReactNode => {
  const isReportView = stateIndex !== -1;
  let configs = [] as Array<ReportResponseModel.IFilterConfig>;
  if (isReportView
    && reportViewInfo && reportViewInfo.PersonalisationObject?.filters) {
    const keys = Object.keys(reportViewInfo.PersonalisationObject.filters);
    keys.forEach((k) => {
      const config = filterConfig && filterConfig.find((c) => c.ID === k);
      if (config) {
        configs.push(config);
      } else {
        const dynamicDimension = dynamicDimensions && dynamicDimensions.find((d) => (
          d.BuilderConfig && d.BuilderConfig.IsFilterApplicable && (d.BuilderConfig.FilterId === k)));
        if (dynamicDimension) {
          configs.push({
            ID: dynamicDimension.BuilderConfig.FilterId,
            Type: dynamicDimension.BuilderConfig.FilterType,
            Label: dynamicDimension.BuilderConfig.DisplayName,
            Entity: dynamicDimension.BuilderConfig.Entity,
            Alias: dynamicDimension.BuilderConfig.Alias,
            Metadata: dynamicDimension.BuilderConfig.MetaData,
            IsDynamic: dynamicDimension.BuilderConfig.IsDynamicField,
            DefaultValue: null,
            StyleConfig: null,
            LinkedTo: '',
            LinkedDimensionAlias: '',
            AlwaysEnabled: false,
          });
        }
      }
    });
  } else {
    configs = filterConfig;
  }

  if (configs) {
    return configs.filter((item) => item.Type !== FilterType.PRE_APPLIED).map((item) => {
      if ((!isReportView && appliedFilters[item.ID] && appliedFilters[item.ID].FilterResponse)
        || (isReportView && reportViewInfo && reportViewInfo.PersonalisationObject
          && reportViewInfo.PersonalisationObject.filters
          && reportViewInfo.PersonalisationObject.filters[item.ID])) {
        let value = null;
        if (isReportView) {
          value = reportViewInfo.PersonalisationObject.filters[item.ID];
        } else {
          const appliedValue = appliedFilters[item.ID].FilterResponse;
          switch (item.Type) {
            case FilterType.UserMultiSelect: {
              if (hasUserFilterValue(appliedFilters[item.ID].FilterResponse, appliedFilters[item.ID].OptionsMeta.Count)) {
                // conversion to type ALL for below cases.
                switch (appliedValue.Type) {
                  case UserFilterPayloadType.In:
                    if (appliedValue.UserIds.length === appliedFilters[item.ID].OptionsMeta.Count) {
                      value = {
                        Type: UserFilterPayloadType.All,
                        UserIds: [],
                      };
                    }
                    break;
                  case UserFilterPayloadType.NotIn:
                    if (!appliedValue.UserIds.length) {
                      value = {
                        Type: UserFilterPayloadType.All,
                        UserIds: [],
                      };
                    }
                    break;
                  default:
                    break;
                }
              }
              break;
            }

            case FilterType.GroupMultiSelect:
            case FilterType.CustomDefinedMultiSelect:
            case FilterType.LSQMetadataMultiSelect: {
              if (!isEmpty(appliedValue)) {
                value = appliedValue;
              }
              break;
            }

            default:
              value = appliedValue;
              break;
          }
        }

        return value && (
          <div
            key={item.ID}
            className="se-filter"
          >
            <div
              className="se-filter-label"
            >
              {`${item.IsDynamic ? `${item.Entity} | ${item.Label}` : item.Label} :`}
            </div>
            {
              getFilterValueLayout(
                item.Type,
                value,
                appliedFilters[item.ID]?.FilterOptions ? appliedFilters[item.ID].FilterOptions : [],
                isReportView,
                ViewType,
              )
            }
          </div>
        );
      }
      return null;
    });
  }
  return null;
};

const getSettingsValueLayout = (type: ReportDimensionsSettingsTypes, value: any) => {
  if (type) {
    switch (type) {
      case ReportDimensionsSettingsTypes.RowGrouping:
      case ReportDimensionsSettingsTypes.ColumnGrouping:
        return value.map((item: string, index: number) => {
          const key = `${item}${index}`;
          return (
            <div
              key={key}
              className="se-setting-value"
            >
              {item}
            </div>
          );
        });

      default:
        break;
    }
  }
  return null;
};

const getSettingsItemLayout = (type: ReportDimensionsSettingsTypes, value: any) => {
  if (type && value) {
    let header = null;
    let body = null;
    switch (type) {
      case ReportDimensionsSettingsTypes.RowGrouping:
      case ReportDimensionsSettingsTypes.ColumnGrouping:
      {
        header = `${ReportDimensionsSettingsTitles[type]} :`;
        body = getSettingsValueLayout(
          type,
          value,
        );
        break;
      }

      default:
        break;
    }

    if (header && body) {
      return (
        <div
          className="se-setting-item"
        >
          <div
            className="se-setting-label"
          >
            {header}
          </div>
          {body}
        </div>
      );
    }
  }
  return null;
};

const getSettingsInfoLayout = (
  activeDimensions: Array<ReportReduxModel.IDimension>, stateIndex: number,
  reportViewInfo: IReportViewInfo,
) => {
  if (stateIndex > -1) {
    if (reportViewInfo && reportViewInfo.PersonalisationObject) {
      const { rowGrouping, colGrouping } = reportViewInfo.PersonalisationObject;
      return (
        <>
          {
            rowGrouping
            && getSettingsItemLayout(
              ReportDimensionsSettingsTypes.RowGrouping,
              rowGrouping.map((item) => item.split(DimensionIdentifier)[0]),
            )
          }
          {
            colGrouping
            && getSettingsItemLayout(
              ReportDimensionsSettingsTypes.ColumnGrouping,
              colGrouping.map((item) => item.split(DimensionIdentifier)[0]),
            )
          }
        </>
      );
    }
  } else if (activeDimensions && !isEmpty(activeDimensions)) {
    const rowGrouping = [] as Array<string>;
    const colGrouping = [] as Array<string>;
    activeDimensions.forEach((dimension) => {
      if (dimension.Applied !== DimensionStatus.NotApplied) {
        if (dimension.DimensionMode !== DimensionMode.ColumnGroup) {
          rowGrouping.push(displayName(dimension, true));
        } else {
          colGrouping.push(displayName(dimension, true));
        }
      }
    });
    return (
      <>
        {
          rowGrouping && !isEmpty(rowGrouping)
          && getSettingsItemLayout(
            ReportDimensionsSettingsTypes.RowGrouping,
            rowGrouping,
          )
        }
        {
          colGrouping && !isEmpty(colGrouping)
          && getSettingsItemLayout(
            ReportDimensionsSettingsTypes.ColumnGrouping,
            colGrouping,
          )
        }
      </>
    );
  }
  return null;
};

const getSortingInfoLayout = (
  sort: Array<ReportResponseModel.ISortedField>, activeDimensions: ReportReduxModel.IDimension[],
) => {
  if (sort && !isEmpty(sort)) {
    return (
      <div
        className="se-sorting-info"
      >
        <div
          className="se-sorting-label"
        >
          Sorting Order :
        </div>
        <ol>
          {
            sort.map((item) => {
              const dim = !isEmpty(activeDimensions) && activeDimensions.find((d) => (
                d.BuilderConfig && d.BuilderConfig.IsDynamicField && d.BuilderConfig.IsDimApplicable
                  ? d.BuilderConfig.Alias === item.Field : d.Name === item.Field));
              return (
                <li
                  key={item.Field}
                  className="se-sorting-value"
                >
                  {`${dim ? displayName(dim, true) : item.Field} (${item.Direction})`}
                </li>
              );
            })
          }
        </ol>
      </div>
    );
  }
  return null;
};

const ReportViewInfo = ({ stateIndex, ViewType }: ReportViewInfoProps) => {
  const dispatch = useDispatch();
  const reportStore = useSelector(reportSelector);
  const reportViewListStore = useSelector(reportViewSelector);

  const {
    filterConfig, activeFilters, activeDimensions, dynamicDimensions,
    sort,
  } = reportStore;

  const { reportViewList, reportViewInfo } = reportViewListStore;
  const { reportViewItems } = reportViewList;
  const {
    setTooltipRef, setTriggerRef, getTooltipProps, getArrowProps, visible,
  } = usePopperTooltip({
    placement: 'right-end',
    trigger: 'click',
    closeOnOutsideClick: true,
  });

  const filterInfoLayout = getFilterInfoLayout(
    filterConfig,
    activeFilters,
    stateIndex,
    dynamicDimensions,
    reportViewInfo?.reportViewInfoItem,
    ViewType,
  );

  const settingsInfoLayout = getSettingsInfoLayout(
    activeDimensions,
    stateIndex,
    reportViewInfo?.reportViewInfoItem,
  );

  const sortingInfoLayout = getSortingInfoLayout(
    stateIndex > -1
      ? reportViewInfo?.reportViewInfoItem
      && reportViewInfo.reportViewInfoItem?.PersonalisationObject?.sort
      : sort,
    activeDimensions,
  );

  return (
    <StyledContainer>
      <div
        className="se-report-view-info"
      >
        <button
          ref={setTriggerRef}
          className={`se-info-icon se-icon-cn ${PendoClassNames.InfoReportViewIcon}`}
          type="button"
          onClick={(event) => {
            event.stopPropagation();
            if (stateIndex > -1 && reportViewItems && !visible) {
              dispatch(getReportViewInfo({ personalisationId: reportViewItems[stateIndex].PersonalisationId, context: 'tooltip', ReportViewType: ViewType }));
            }
          }}
        >
          <InfoIcon
            width={16}
            height={16}
            fill="#919EAB"
          />
        </button>
        {
          visible && (
            <div
              ref={setTooltipRef}
              {...getTooltipProps({ className: 'tooltip-container se-info' })}
            >
              <div
                {...getArrowProps({ className: 'tooltip-arrow' })}
              />
              {
                !(reportViewInfo?.isInfoLoading) ? (
                  <div
                    className="se-info-details se-custom-scrollbar"
                  >
                    {
                      filterInfoLayout ? (
                        <div>
                          <div className="se-filter-header">
                            {`${headerOptions.AppliedFilters} :`}
                          </div>
                          {filterInfoLayout}
                        </div>
                      ) : null
                      }
                    {
                      filterInfoLayout && settingsInfoLayout
                        ? (
                          <div
                            className="se-info-divider"
                          />
                        )
                        : ''
                    }
                    {
                      settingsInfoLayout
                    }
                    {
                      sortingInfoLayout
                    }
                  </div>
                ) : (
                  <BarLoader
                    isRelative={false}
                    style={{
                      position: 'absolute',
                    }}
                    content="Loading..."
                  />
                )
              }
            </div>
          )
        }
      </div>
    </StyledContainer>
  );
};

export default ReportViewInfo;
