import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const JobCompletedIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00386 13.373L2.09411 8.1051L0.420654 9.88946L7.00386 16.9572L21.1462 1.78243L19.4923 0L7.00386 13.373Z"
      fill={fill}
    />
  </svg>

);

JobCompletedIcon.defaultProps = {
  fill: '#919eab',
};

export default JobCompletedIcon;
