import React from 'react';

type Props = {
  width: number;
  height: number;
}

const PngChartIcon = ({
  width, height,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.666 1.66699H5.00001C4.5583 1.66831 4.13506 1.84436 3.82272 2.1567C3.51038 2.46904 3.33433 2.89228 3.33301 3.33399V16.667C3.33221 17.1078 3.50635 17.5309 3.8172 17.8434C4.12805 18.156 4.55021 18.3324 4.99101 18.334H15C15.4415 18.3324 15.8645 18.1562 16.1767 17.8439C16.4888 17.5316 16.6647 17.1085 16.666 16.667V6.66699L11.666 1.66699ZM14.999 16.667H4.99901V3.33399H10.833V7.49999H15L14.999 16.667Z"
      fill="#4894BF"
    />
    <path
      d="M1 9H12C12.2652 9 12.5196 9.10536 12.7071 9.29289C12.8946 9.48043 13 9.73478 13 10V14C13 14.2652 12.8946 14.5196 12.7071 14.7071C12.5196 14.8946 12.2652 15 12 15H1C0.734784 15 0.48043 14.8946 0.292893 14.7071C0.105357 14.5196 0 14.2652 0 14V10C0 9.73478 0.105357 9.48043 0.292893 9.29289C0.48043 9.10536 0.734784 9 1 9V9Z"
      fill="#4894BF"
    />
    <path
      d="M4.02571 11.3425C4.02571 11.5292 3.98071 11.7042 3.89071 11.8675C3.80405 12.0309 3.66571 12.1625 3.47571 12.2625C3.28905 12.3625 3.05238 12.4125 2.76571 12.4125H2.18071V13.7525H1.48071V10.2625H2.76571C3.03571 10.2625 3.26571 10.3092 3.45571 10.4025C3.64571 10.4959 3.78738 10.6242 3.88071 10.7875C3.97738 10.9509 4.02571 11.1359 4.02571 11.3425ZM2.73571 11.8475C2.92905 11.8475 3.07238 11.8042 3.16571 11.7175C3.25905 11.6275 3.30571 11.5025 3.30571 11.3425C3.30571 11.0025 3.11571 10.8325 2.73571 10.8325H2.18071V11.8475H2.73571Z"
      fill="white"
    />
    <path
      d="M7.5077 13.7525H6.8077L5.22271 11.3575V13.7525H4.52271V10.2575H5.22271L6.8077 12.6575V10.2575H7.5077V13.7525Z"
      fill="white"
    />
    <path
      d="M10.6295 11.3125C10.5495 11.1659 10.4395 11.0542 10.2995 10.9775C10.1595 10.9009 9.99613 10.8625 9.80946 10.8625C9.6028 10.8625 9.41946 10.9092 9.25946 11.0025C9.09946 11.0959 8.97446 11.2292 8.88446 11.4025C8.79446 11.5759 8.74946 11.7759 8.74946 12.0025C8.74946 12.2359 8.79446 12.4392 8.88446 12.6125C8.9778 12.7859 9.10613 12.9192 9.26946 13.0125C9.4328 13.1059 9.6228 13.1525 9.83946 13.1525C10.1061 13.1525 10.3245 13.0825 10.4945 12.9425C10.6645 12.7992 10.7761 12.6009 10.8295 12.3475H9.62946V11.8125H11.5195V12.4225C11.4728 12.6659 11.3728 12.8909 11.2195 13.0975C11.0661 13.3042 10.8678 13.4709 10.6245 13.5975C10.3845 13.7209 10.1145 13.7825 9.81446 13.7825C9.4778 13.7825 9.1728 13.7075 8.89946 13.5575C8.62946 13.4042 8.41613 13.1925 8.25946 12.9225C8.10613 12.6525 8.02946 12.3459 8.02946 12.0025C8.02946 11.6592 8.10613 11.3525 8.25946 11.0825C8.41613 10.8092 8.62946 10.5975 8.89946 10.4475C9.1728 10.2942 9.47613 10.2175 9.80946 10.2175C10.2028 10.2175 10.5445 10.3142 10.8345 10.5075C11.1245 10.6975 11.3245 10.9659 11.4345 11.3125H10.6295Z"
      fill="white"
    />
  </svg>

);

export default PngChartIcon;
