/* eslint-disable no-shadow */
export enum VisualizationTypes{
  Table='Table',
  ColumnChart='ColumnChart',
  LineChart='LineChart',
  AreaChart='AreaChart',
  BarChart='BarChart',
  SummaryTable= 'summary',
  MultiLineTable= 'multiline',
  NoData='Nodata'
}

export const Messages = {
  NoData: 'There are no data to display for the selected filter. Try adjusting the filter for what you are looking for.',
};

export const AdditionalDimensionEmptyState = {
  Fields: 'No fields are currently available in this section.',
  Filters: 'No filters are currently available in this section.',
};

export const AdditionalDimensionNoResultState = {
  Fields: 'No fields found.',
  Filters: 'No filters found.',
};

export const SearchBarPlaceHolder = {
  Fields: 'Search Fields',
  Filters: 'Search Filters',
  Values: 'Search Fields and Values',
};

export const NoDimensionDataMessages = {
  NoSearches: 'No Items Found.',
  AllColumnAdded: 'All columns have been added',
  AllFiltersAdded: 'All filters have been added',
  AllMeasuresAdded: 'All pre-defined measures have been added',
};

export const Chartcolors = {
  ColumnChart: [['#116FD6', '#084E96'], ['#D1331B', '#932413'], ['#ED7B24', '#A65619'], ['#F5E733', '#ACA220'], ['#3BB345', '#297D30']],
  BarChart: [['#116FD6', '#084E96'], ['#D1331B', '#932413'], ['#ED7B24', '#A65619'], ['#F5E733', '#ACA220'], ['#3BB345', '#297D30']],
  AreaChart: [['#99C5EF00', '#116FD6'], ['#99C5EF00', '#D1331B'], ['#99C5EF00', '#ED7B24'], ['#99C5EF00', '#F5E733'], ['#99C5EF00', '#3BB345']],
  LineChart: ['#116FD6', '#D1331B', '#ED7B24', '#F5E733', '#3BB345'],
  Default: ['#116FD6', '#D1331B', '#ED7B24', '#F5E733', '#3BB345'],
};

export const NoExportState = {
  Export: 'No Export Yet.',
};
