import styled from 'styled-components';

const StyledCircularLoadButtonContainer = styled.div<
    {
      primaryBackground: string,
        doneGlowHighState: string,
        uniqKey:string,

    }>`
    {
        height:100%;
        width:100%;
    }
    .se-circular-load-btn-container{
        border-radius:50%;
        width: 100%;
        height: 100%;
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .se-circular-load-btn-inner{
        position: absolute;
        background-color: white;
        border-radius:50%;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .se-circular-load-btn-inner.done{
        cursor:pointer;
        height:100%;
        width:100%;
        background-color: ${(props) => props.primaryBackground};
        -webkit-animation: ${(props) => `${props.uniqKey}glowing`} 1500ms 3;
        -moz-animation: ${(props) => `${props.uniqKey}glowing`} 1500ms 3;
        -o-animation: ${(props) => `${props.uniqKey}glowing`} 1500ms 3;
        animation: ${(props) => `${props.uniqKey}glowing`} 1500ms 3;
    }
    .se-circular-load-btn-outer{
        // colors for below are in order of progress, background
        border-radius: 50%;
        height:100%;
        width:100%;
    }
    @-webkit-keyframes ${(props) => `${props.uniqKey}glowing`} {
        0% { background-color: ${(props) => props.doneGlowHighState}; -webkit-box-shadow: 0 0 3px ${(props) => props.doneGlowHighState}; }
        50% { background-color: ${(props) => props.primaryBackground}; -webkit-box-shadow: 0 0 15px ${(props) => props.primaryBackground}; }
        100% { background-color: ${(props) => props.doneGlowHighState}; -webkit-box-shadow: 0 0 3px ${(props) => props.doneGlowHighState}; }
      }
      
      @-moz-keyframes ${(props) => `${props.uniqKey}glowing`} {
        0% { background-color: ${(props) => props.doneGlowHighState}; -moz-box-shadow: 0 0 3px ${(props) => props.doneGlowHighState}; }
        50% { background-color: ${(props) => props.primaryBackground}; -moz-box-shadow: 0 0 15px ${(props) => props.primaryBackground}; }
        100% { background-color: ${(props) => props.doneGlowHighState}; -moz-box-shadow: 0 0 3px ${(props) => props.doneGlowHighState}; }
      }
      
      @-o-keyframes ${(props) => `${props.uniqKey}glowing`} {
        0% { background-color: ${(props) => props.doneGlowHighState}; box-shadow: 0 0 3px ${(props) => props.doneGlowHighState}; }
        50% { background-color: ${(props) => props.primaryBackground}; box-shadow: 0 0 15px ${(props) => props.primaryBackground}; }
        100% { background-color: ${(props) => props.doneGlowHighState}; box-shadow: 0 0 3px ${(props) => props.doneGlowHighState}; }
      }
      
      @keyframes ${(props) => `${props.uniqKey}glowing`} {
        0% { background-color: ${(props) => props.doneGlowHighState}; box-shadow: 0 0 3px ${(props) => props.doneGlowHighState}; }
        50% { background-color: ${(props) => props.primaryBackground}; box-shadow: 0 0 15px ${(props) => props.primaryBackground}; }
        100% { background-color: ${(props) => props.doneGlowHighState}; box-shadow: 0 0 3px ${(props) => props.doneGlowHighState}; }
      }

      @keyframes rotation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(359deg);
        }
      }
`;
export default StyledCircularLoadButtonContainer;
