import styled from 'styled-components';

const StyledNoData = styled.div`
text-align: center;
display: flex;
justify-content: center;
align-items: center;
background-color:var(--noDataBackground);
flex-direction: column;
color: var(--noDataColor);
box-shadow: 0px 0px 3px var(--boxShadow);


> .se-no-data-text{
  font-family: 'Regular';
  font-size: 11px;
  color: var(--searchBoxTextColor);
  background-color: var(--noDataBackground);
}

.se-no-data-header{
  font-size:16px;
  color: var(--paginationColor);
  font-family: SemiBold;
  margin:10px 0px;
}

@media (min-width: 320px) {
    min-height: 200px;
    > .se-no-data-text{
        font-size: 11px;
      }
  }

@media (min-width: 768px) {
    min-height: 400px;
    > .se-no-data-text{
        font-size: 11px;
      }
  }

  @media (min-width: 1024px) {
    min-height: 400px;
    > .se-no-data-text{
        font-size: 11px;
      }
  }
`;

export default StyledNoData;
