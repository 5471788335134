/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';
import { StyledSettingsSectionContainer } from '../settings.style';

export default styled(StyledSettingsSectionContainer)`
flex: 1;
width: 100%;
height: 100%;
overflow: hidden;

.se-no-dimension-data {
    height: 32px;
    font-size: 13px;
    color: #b9c4ce;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.se-grouping-header-cn{
    display: flex;
    margin-bottom: 8px;
}
`;
