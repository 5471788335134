import styled from 'styled-components';

const StyledFooterComponent = styled.div`
  .se-report-footer{
    padding: 8px 12px 8px 12px;
    background-color: #fff;
    height: 48px;
    box-sizing: border-box;
    border-top: 1px solid #DFE3E8;
    display: flex;
  }
`;

export default StyledFooterComponent;
