import styled from 'styled-components';

export default styled.div`
width: 100%;
.se-entity-header {
    width: 100%;
    border: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    align-items: center;
    height: 32px;
    padding: 8px 12px 8px 15px;

    :hover {
        background: #F4F6F8;
    }
}
.se-entity-label {
    flex: 1;
    font-size: 12px ;
    color: #454F5B;
    text-transform: uppercase;
    text-align: left;
    font-family: SemiBold;
}

.se-entity-icon {
    width: 12px;
    height: 12px;
    margin-right: 12px;
}

.se-entity-showcase.expand {
    padding-bottom: 8px;
}

.se-entity-showcase.collapse {
    height: 0;
    overflow: hidden;
}

.se-entity-header.expand {
    background: #F4F6F8; 
    
    .se-arrow-indicator {
        transform: rotate(180deg);
    }
}

`;
