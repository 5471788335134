import {
  DimensionStatus, expandFieldsButtonLabel, ExpandListButtonContext, ReportDimensionsSettingsDescs, ReportDimensionsSettingsTitles,
} from 'core/constants/report';
import { ReduxModel } from 'core/models';
import { IColumn } from 'core/models/report-response';
import { getFieldIdentifier } from 'core/utils/report.util';
import React, { useState, useEffect } from 'react';
import {
  DragDropContext, DraggableId, DragUpdate, DropResult,
} from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from 'redux/report/actions';
import DrilldownAvailableFields from 'components/feature/Report/ReportSidebar/Settings/DrilldownDimensions/DrilldownAvailableFields/drilldown-avail-fields-component';
import DrilldownGrouping from 'components/feature/Report/ReportSidebar/Settings/DrilldownDimensions/DrilldownGrouping/drilldown-grouping-component';
import { IDrilldownProps } from 'components/feature/Report/ReportSidebar/Settings/DrilldownDimensions/drilldown-dimensions.d';
import StyledContainer from 'components/feature/Report/ReportSidebar/Settings/DrilldownDimensions/drilldown-dimensions.style';
import { DroppableIds } from 'components/feature/Report/ReportSidebar/common/constants';
import { DraggableItemState } from 'components/feature/Report/ReportSidebar/common/DraggableItem/draggable-item.d';
import { checkDrilldownLimits } from 'components/feature/Report/ReportSidebar/common/helpers';
import { IColumnWithIndex } from 'components/feature/Report/ReportSidebar/Settings/DrilldownDimensions/DrilldownGrouping/drilldown-grouping.d';
import SidebarHeader from 'components/feature/Report/ReportSidebar/common/SidebarHeader/sidebar-header.component';
import ExpandListButton from 'components/common/ExpandListButton/expand-list-button.component';
import { getDimensionSelectedCount, getInfoIconWithTooltip } from '../settings.utils';

const DrilldownDimensions = (props: IDrilldownProps) => {
  const {
    reportBuilderPortal, toolbarExpanded, applyClickCountDrilldown,
  } = props;
  const [
    autodrilldownData,
  ] = useSelector((state: ReduxModel.IGlobalState) => [
    state.report.autodrilldownData, state.report.reportConfig,
  ] as const);
  const dispatch = useDispatch();
  const [availableFieldsExpanded, setAvailableFieldsExpanded] = useState(false);
  const [selectedDimension, setSelectedDimension] = useState(''); // tells child components which dimension is being dragged, if any
  const [dimensionState, setDimensionState] = useState(DraggableItemState.Default); // tells child components the state of the dragged dimension
  const [newAdditionIndex, setNewAdditionIndex] = useState(-1);
  const [replaceIndex, setReplaceIndex] = useState(-1);
  useEffect(() => {
    if (!toolbarExpanded) {
      setAvailableFieldsExpanded(false);
    }
  }, [toolbarExpanded]);
  useEffect(() => setAvailableFieldsExpanded(false), [applyClickCountDrilldown]);

  const groupedDimensions = autodrilldownData.drilldownFields && autodrilldownData.drilldownFields
    .map((dim, index) => ({ ...dim, originalIndex: index } as IColumnWithIndex))
    .filter((dim) => dim.Props.Dimension && dim.Props.Dimension?.Applied === DimensionStatus.Applied);

  const {
    isMaxDrilldownGroupingReached,
    MaxDrilldownGrouping,
  } = checkDrilldownLimits(groupedDimensions);

  const addDrilldownFields = (index : number) => {
    // this index would be source index
    setNewAdditionIndex(groupedDimensions.length);
    setTimeout(() => {
      setNewAdditionIndex(-1);
    }, 600);
    dispatch(Actions.setGroupingDrilldown(index, DimensionStatus.Applied, groupedDimensions.length));
  };

  const resetState = () => {
    setSelectedDimension('');
    setDimensionState(DraggableItemState.Default);
    setReplaceIndex(-1);
  };

  const getDimensionByName = (name: DraggableId): [IColumn, number] => {
    const index = autodrilldownData.drilldownFields.findIndex((dim) => {
      if (dim.BuilderConfig && dim.BuilderConfig.IsDynamicField) {
        return dim.BuilderConfig.Alias === name;
      }
      return dim.Name === name;
    });
    return [autodrilldownData.drilldownFields[index], index];
  };

  // set the selected dimension and its state
  const onDragUpdate = ({ source, combine }: DragUpdate) => {
    const srcDimension = autodrilldownData.drilldownFields[source.index];
    setSelectedDimension(getFieldIdentifier(srcDimension));
    if (combine) {
      const [, dropIndex] = getDimensionByName(combine.draggableId); // desDimension
      setReplaceIndex(dropIndex);
      setDimensionState(DraggableItemState.AboutToBeCombined);
    } else {
      setReplaceIndex(-1);
      setDimensionState(DraggableItemState.Dragging);
    }
  };

  const onDragEnd = (result:DropResult) => {
    const { source, destination, combine } = result;

    if (combine) {
      const [desDimension, destinationIndex] = getDimensionByName(combine.draggableId);
      if (!source || !desDimension) return;
      if (combine.droppableId === DroppableIds.DrilldownGrouping) {
        dispatch(Actions.setGroupingDrilldown(source.index, DimensionStatus.Applied, destinationIndex, true));
      }
      resetState();
      return;
    }
    if (!destination || (source.index === destination.index)) {
      resetState();
      return;
    }

    if (source.index !== destination.index) {
      dispatch(Actions.setGroupingDrilldown(source.index, DimensionStatus.Applied, destination.index));
    }
    resetState();
  };

  return (
    <StyledContainer>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragUpdate={onDragUpdate}
      >
        <DrilldownAvailableFields
          expanded={availableFieldsExpanded}
          reportBuilderPortal={reportBuilderPortal}
          collapse={() => setAvailableFieldsExpanded(false)}
          addDrilldownFields={addDrilldownFields}
        />
        <div className="se-dimensions-settings-item">
          <div className="se-grouping-header-cn">
            <SidebarHeader
              title={ReportDimensionsSettingsTitles.DrilldownColumn}
              counter={(
                getDimensionSelectedCount(isMaxDrilldownGroupingReached, groupedDimensions?.length || 0, MaxDrilldownGrouping)
              )}
              infoIcon={getInfoIconWithTooltip(ReportDimensionsSettingsDescs.DrilldownColumn)}
              source={ExpandListButtonContext.Drilldown}
            />
            <ExpandListButton
              label={expandFieldsButtonLabel}
              isExpanded={availableFieldsExpanded}
              onClickToggler={() => setAvailableFieldsExpanded(!availableFieldsExpanded)}
              clickSource={ExpandListButtonContext.Drilldown}
            />
          </div>
          <DrilldownGrouping
            selectedDimension={selectedDimension}
            dimensionState={dimensionState}
            isMaxDrilldownGroupingReached={isMaxDrilldownGroupingReached}
            replaceIndex={replaceIndex}
            newAdditionIndex={newAdditionIndex}
          />
        </div>
      </DragDropContext>
    </StyledContainer>
  );
};

export default DrilldownDimensions;
