import styled from 'styled-components';

const StyledContainer = styled.div`
height: 100%;
width: auto;
background-color: #F4F6F8;
position: relative;
.se-side-navbar {
  position: relative;
  height: 100%;
  transition: all 0.2s ease-in-out;

  .se-side-navbar-showcase {
    position: relative;
    width: 250px;
    height: 100%;
    padding: 12px;
    background: var(--borderColor);
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    border-right: 1px solid var(--sideNavBarBorder);
  }

  .se-side-navbar-toggler {
    position: absolute;
    top: 32px;
    right: -12px;
    z-index: 3;
    display: flex;
    padding: 0;
    border: 0;
    background: transparent;
  }
}

.se-side-navbar.collapse {
  position: fixed;
  z-index: 3;

  .se-side-navbar-showcase {
    margin-left: -238px;
    :hover {
      margin: 0;
    }
  }

  .se-side-navbar-toggler {
    transform: rotate(180deg);
  }

}

.se-divider {
  padding: 0 6px;
}
.se-side-navbar-hamburger{
  border: none;
  position: absolute;
  z-index: 4;
  top:5px;
  left:15px;
}

.se-side-nav-section-header{
text-align: left;
font-size: 14px;
letter-spacing: 0px;
color: var(--navHeaderColor);
opacity: 1;
margin-bottom: 16px;
font-family:SemiBold;
}

.se-side-nav-section-header.se-shimmer-cn{
  width: 53px;
  height: 16px;
}

.se-side-nav-section{
  margin-top:8px ;
  margin-bottom:12px ;
}

.se-side-nav-section-types-cn{
  margin-top: 16px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}

.se-side-nav-section-sub-types{
  padding-bottom: 12px;
  border-bottom: solid 1px var(--sideNavBorderColor);
  display: flex;
  flex-direction: column;
}



.se-side-nav-cateogries{
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.se-side-nav-sub-type{
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 9px;
  margin-bottom: 2px;
  border-radius: 2px;
  height: 32px;
  background:transparent;
  border:transparent;
  font-family: Regular;
  overflow: hidden;
}

.se-side-nav-sub-type.se-shimmer-cn {
  height: 32px;
}

.se-side-nav-sub-type.active, .se-side-nav-cateogries-item.active{
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 8%);
  background-color: var(--noDataBackground);
  font-family: SemiBold;
  border: transparent;
}

.se-side-nav-sub-type.active .se-side-nav-sub-type-label, .se-side-nav-cateogries-item.active .se-side-nav-sub-type-label{
  color: var(--reportHeaderLabel);
}
.se-side-nav-sub-type:hover, .se-side-nav-cateogries-item:hover{
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 8%);
  background-color: var(--noDataBackground);
  font-family: SemiBold;
  color: var(--reportHeaderLabel);
}

.se-side-nav-sub-type :hover, .se-side-nav-cateogries-item :hover{
  color: var(--reportHeaderLabel);
  }

  .se-side-nav-sub-type :hover .se-side-nav-sub-type-label,.se-side-nav-sub-type :hover .se-side-nav-sub-type-label{
    color: var(--reportHeaderLabel);
  }

  .se-side-nav-sub-type-label :hover{
    color: var(--reportHeaderLabel);
}
.se-side-nav-cateogries-item{
  display: flex;
  align-items: center;
  padding-left: 9px;
  margin-bottom: 2px;
  border-radius: 2px;
  height: 32px;
  cursor: pointer;
}

.se-side-nav-sub-type-icon {
  display: flex;
  align-items: center;
}

.se-side-nav-sub-type-label{
  font-size: 13px;
  margin-left: 8px;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color:var(--paginationColor);
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.se-hamburger {
  display: none;
}


@media (max-width: 320px) {
  background-color: #FFF;
  .se-hamburger{
    display: block;
    position: relative;
  }
  .se-side-navbar.collapse .se-side-navbar-showcase {
    margin-left: -250px;
    :hover{
      margin-left: -250px;
    }
}
.se-side-navbar .se-side-navbar-toggler{
  display: none;
}
.se-divider {
padding:0px;
}

}

@media (max-width: 991px) {
  background-color: #FFF;
  .se-hamburger{
    display: block;
    position: relative;
  }
  .se-side-navbar.collapse .se-side-navbar-showcase {
    margin-left: -250px;
    :hover{
      margin-left: -250px;
    }
  }
  .se-side-navbar .se-side-navbar-toggler{
  display: none;
}
.se-divider {
padding:0px;
}

}

@media (min-width: 992px) {
  .se-hamburger{
    display: none;
  }
}
`;
export default StyledContainer;
