import styled from 'styled-components';

const StyledContainer = styled.div<
{position: number}
>`

    .se-kebab-icon {
        border: none;
        padding: 0px !important;
        :hover {
            .icon-stroke {
                fill: #454F5B !important;
            }
        }
    }
        
    .focus {
        .icon-stroke {
            fill: #454F5B !important;
        }
    }
    .unfocus {
        .icon-stroke {
            fill: #ADBAC7 !important;
        }
    }

    .se-dropdown-cn{
        display: block;
        position: fixed;
        font: 13px Regular;
        width: 140px;
        top: 36px; 
        background-color: #FFFFFF;
        box-shadow: 0px 1px 6px 0px rgba(33, 43, 54, 0.2);
        padding: 4px 0px;
        right: 0px;
        opacity: 0;

            
        &.se-dropdown-cn.expand {
            position: absolute;
            opacity: 1;
            z-index: 1;
            transition: opacity 250ms;  
        }

        .se-option-cn {
            display: flex;
            padding: 6px 8px;
            border: none;
            width: 100%;
            align-items: center;
            :hover {
                background-color: #F4F6F8;
            }
        
            .se-option-label {
                margin-left: 8px;
                font: 13px Regular;
            }
        }
    }

    .se-option-cn.disabled {
        display: none;
    }
    .se-dropdown-cn.top {
        top: -${(props) => props.position}px; 
   }
`;
export default StyledContainer;
