import styled from 'styled-components';

const StyledContainer = styled.div`
height: 100%;

.se-report-sidebar {
  height: 100%;
  display: flex;
  background: var(--noDataBackground);

  .se-report-sidebar-showcase {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--paginationBorder);
    text-align: left;
    margin-right: -298px;
    transition: margin 0.2s ease-in-out;
    background: var(--noDataBackground);

    .se-sidebar-showcase-header {
      padding: 10px 15px 9px 15px;
      background:  var(--noDataBackground);
      border-bottom: 1px solid var(--paginationBorder);
        
      .se-sidebar-header-title {
        display: flex;
        align-items: center;
      }
        
      .se-sidebar-title-icon {
        display: flex;
        align-items: center;
      }
        
      .se-sidebar-title-label {
        margin-left: 6px;
        margin-bottom: 2px;
        font-family: SemiBold;
        color: var(--primaryColor);
        font-size: 15px;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
        
      .se-sidebar-header-desc {
        font-size: 11px;
        color: #637381;
        white-space: nowrap;
        line-height: 14px;
      }
    }
  }

  .se-report-sidebar-showcase.expand {
    margin-right: 0;
  }

  .se-report-sidebar-nav {
    position: relative;
    width: 68px;
    height: 100%;
    box-sizing: border-box;
    background:  var(--noDataBackground);
  }
  .se-filter-header-cn {
    display: flex;
    margin-bottom: 16px;
    .se-filter-header {
      display: inherit;
      flex: 1;
    .se-filter-counter {
      font: 11px SemiBold;
      color: var(--paleSkyColor);
      background-color: var(--grayCounterBackgroundColor);
      padding: 1px 6px 1px 6px;
      border-radius: 40px;
      height: 17px;

    .se-limit-reached {
      color: var(--dangerColor);
      }
    }
  }
}
}
`;

export default StyledContainer;
