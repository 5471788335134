import React, { useEffect } from 'react';
import { URLHash } from 'core/constants/page-url';
import { NavSectionSubTypes } from 'core/constants/side-navbar';
import { APIRequestStatus } from 'core/constants/redux';
import { ReportListActionTypes } from 'core/constants/redux-action-types';
import { NavReportSectionSubTypes } from 'core/utils/side-navbar.util';
import { IProps } from 'components/feature/ReportListing/report-list.d';
import StyledComponent from 'components/feature/ReportListing/report-list.style';
import StoreConnector from 'components/feature/ReportListing/report-list.redux';
import ReportSection from 'components/feature/ReportListing/ReportSection/report-section.component';
import ReportHeader from 'components/feature/ReportListing/ReportHeader/report-header.component';
import EmptyDataScreen from 'components/feature/ReportListing/EmptyDataComponent/empty-data.component';
import ReportListLoader from 'components/feature/ReportListing/report-list.loader';
import BarLoader from 'components/common/loader/BarLoader/bar-loader.component';

const ReportList = (props: IProps) => {
  const { reduxState, reduxAction, ...rest } = props;
  const { reportList } = reduxState;
  const {
    requestProcessing, selectedReportType, pinnedReports, categoriesReports,
    categories,
  } = reportList;
  const {
    pinnedReportsLoadRequest, categoryReportsLoadRequest, selectReportType,
  } = reduxAction;
  const { location } = rest;

  const loadingCategories = requestProcessing[ReportListActionTypes.REPORT_CATEGORIES_LOAD_REQUEST] === APIRequestStatus.Processing;

  let refreshing = false;
  let loadingReports = false;
  let reportSections = null;

  if (selectedReportType) {
    if (selectedReportType.isReportCategory) {
      loadingReports = !categoriesReports[selectedReportType.id]
      && requestProcessing[ReportListActionTypes.CATEGORY_REPORTS_LOAD_REQUEST] === APIRequestStatus.Processing;
      refreshing = !!categoriesReports[selectedReportType.id]
      && requestProcessing[ReportListActionTypes.CATEGORY_REPORTS_LOAD_REQUEST] === APIRequestStatus.Processing;
      reportSections = categoriesReports[selectedReportType.id];
    } else {
      loadingReports = !pinnedReports
      && requestProcessing[ReportListActionTypes.PINNED_REPORTS_LOAD_REQUEST] === APIRequestStatus.Processing;
      refreshing = !!pinnedReports
      && requestProcessing[ReportListActionTypes.PINNED_REPORTS_LOAD_REQUEST] === APIRequestStatus.Processing;
      reportSections = pinnedReports;
    }
  }

  refreshing = refreshing || requestProcessing[ReportListActionTypes.REPORT_PIN_UNPIN_REQUEST] === APIRequestStatus.Processing;

  useEffect(() => {
    const hash = location.hash.replace('#', '').split('/');
    if (!!categories && hash[0] && hash[0] === URLHash.Report && hash[1]) {
      if (hash[1] === NavSectionSubTypes.Category) {
        if (hash[2] !== (selectedReportType && selectedReportType.id)) {
          const category = categories.find((item) => item.CategoryId === hash[2]);
          if (category) {
            selectReportType(
              category.CategoryId,
              category.DisplayName,
              true,
            );
            categoryReportsLoadRequest();
          }
        }
      } else if (hash[1] !== (selectedReportType && selectedReportType.id)) {
        const subType = NavReportSectionSubTypes.find((item) => item.Id === hash[1]);
        if (subType) {
          selectReportType(
            subType.Id,
            subType.Name,
            false,
          );
          pinnedReportsLoadRequest();
        }
      }
    }
  }, [categories, location, selectedReportType]);

  if (loadingCategories || loadingReports) {
    return (
      <ReportListLoader />
    );
  }

  if (selectedReportType) {
    return (
      <StyledComponent>
        <div
          className="se-reports"
        >
          <ReportHeader />
          <div
            className={!reportSections ? 'se-report-sections' : ''}
          >
            {
              reportSections
                ? reportSections.map((item) => (
                  <ReportSection
                    key={item.SectionId}
                    reportSection={item}
                    {...rest}
                  />
                ))
                : <EmptyDataScreen />
            }
          </div>
        </div>
        {
          refreshing && (
            <BarLoader
              isRelative={false}
              style={{
                backgroundColor: 'rgb(234 234 234 / 70%)',
                zIndex: 3,
              }}
              content="Loading..."
            />
          )
        }
      </StyledComponent>
    );
  }

  return null;
};

export default StoreConnector()(ReportList);
