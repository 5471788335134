import React, { useEffect, useState } from 'react';
import { APIRequestStatus } from 'core/constants/redux';
import { ReportListActionTypes } from 'core/constants/redux-action-types';
import { getNavSections } from 'core/utils/side-navbar.util';
import { IProps } from 'components/common/SideNavbar/side-navbar.d';
import StoreConnector from 'components/common/SideNavbar/side-navbar.redux';
import StyledContainer from 'components/common/SideNavbar/side-navbar.style';
import CircleArrow from 'components/common/svg/CircleArrow/circle-arrow.svg';
import SideNavbarLoader from 'components/common/SideNavbar/side-navbar.loader';
import SideNavbarSection from 'components/common/SideNavbar/SideNavbarSection/side-navbar-section.component';
import HamburgerIcon from '../svg/Hamburger/hamburger.svg';

const SideNavBar = (props: IProps) => {
  const {
    reduxAction, reduxState, closedNavbar, onClick, ...rest
  } = props;
  const { reportList } = reduxState;
  const { categories, requestProcessing } = reportList;
  const { reportCategoriesLoadRequest, reorderReportCategories } = reduxAction;

  const [expand, setExpand] = useState(!closedNavbar);
  const loader = requestProcessing[ReportListActionTypes.REPORT_CATEGORIES_LOAD_REQUEST] === APIRequestStatus.Processing;

  useEffect(() => {
    if (!categories) {
      reportCategoriesLoadRequest();
    }
  }, [categories]);

  const toggleExpand = () => {
    onClick(expand);
    setExpand(!expand);
  };

  if (loader) {
    return (
      <SideNavbarLoader />
    );
  }

  const sections = getNavSections(categories);

  return (
    <StyledContainer className={`se-sidenav-container ${expand ? 'hide' : ''}`}>
      <>
        {
          !expand && (
          <div
            className="se-divider"
          />
          )
        }
        <div className="se-hamburger">
          <button
            type="button"
            className="se-side-navbar-hamburger"
            onClick={toggleExpand}
          >
            { !expand ? (
              <HamburgerIcon
                width={16}
                height={16}
              />
            ) : 'X'}
          </button>

        </div>
        <div
          className={`se-side-navbar ${expand ? '' : 'collapse'}`}
        >
          <div
            className="se-side-navbar-showcase"
          >
            {
            sections.map((section) => (
              <SideNavbarSection
                key={section.SectionId}
                item={section}
                reorderCategories={reorderReportCategories}
                {...rest}
              />
            ))
          }
          </div>
          <button
            type="button"
            className="se-side-navbar-toggler"
            onClick={toggleExpand}
          >
            <CircleArrow
              width={24}
              height={24}
            />
          </button>
        </div>
      </>
    </StyledContainer>
  );
};

export default StoreConnector()(SideNavBar);
