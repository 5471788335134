import styled from 'styled-components';

const StyledContainer = styled.div`


color:var(--noDataBackground);
.se-header{
    display: flex;
    align-items: center;
    background-color: var(--headerBackgroundColor);
    padding: 12px 16px;
    height: 24px;
}
.se-header-old-logo{
    height: 24px;
    margin-right: 16px;
}

.se-header-divider{
    width: 0px;
    height: 40px;
    border: 0.5px solid var(--arrowIcon);
    opacity: 1;
}
.se-header-new-logo{
    a {
    height: 34px;
        }
    flex: 1;
    margin:0 16px;
    display: flex;
    height: 34px;
}
.se-user-details{
    display: flex;
}
.se-user-name-country{
    display: flex;
    flex-direction: column;
    margin:0 21px;
}
.se-username{
    color:var(--noDataBackground);
    font-size: 11px;
}
.se-country{
    color:var(--searchBorder);
    font-size: 9px;
}
.avatar .se-name-initials{
    width: 24px;
    border-radius: 50%;
    height: 24px;
    font-size: 8px;
    text-align: center;
    border: 1px solid transparent;
    background:var(--avtarColor);
    text-align: center;
    line-height: 24px;
    padding: 0px;
    color:var(--noDataBackground);
}
.avatar{
    position: relative;
    margin-right: 21px;
}
.se-logout{
    position: absolute;
    top: 42px;
    right: 0px;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 6px var(--boxShadow);
    z-index:5;
    background-color: var(--noDataBackground);
}
}
.se-logout-button{
    border: none;
    font-size: 13px;
    color:var(--headerColor);
    display: flex;
    padding: 13px 41px 12px 10px;
}

.se-logout.arrow-top:after {
  content: " ";
  position: absolute;
  right: 0px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid var(--noDataBackground);
}
.se-logout-text{
    min-width: max-content;
    margin: 0 4px;
}
`;
export default StyledContainer;
