import { IDimension } from 'core/models/report-redux';
import { VisualizationTypes } from 'core/constants/visualizations';
import {
  MaxColumnGrouping, MaxDrilldownGrouping, MaxRowGrouping, MinRowGrouping,
} from 'core/constants/report';
import {
  IColumnWithIndex,
} from 'components/feature/Report/ReportSidebar/Settings/DrilldownDimensions/DrilldownGrouping/drilldown-grouping.d';
import { IColumn } from '../../../../../core/models/report-response';

function checkAndGetGroupingLimits(rowGroupedDimensions: IDimension[], colGroupedDimensions: IDimension[], type: VisualizationTypes) {
  let minRowGroupingLimit = 1;
  let maxRowGroupingLimit = 1;
  let maxColGroupingLimit = 1;

  const rowGroupingCount = rowGroupedDimensions ? rowGroupedDimensions.length : 0;
  const columnGroupingCount = colGroupedDimensions ? colGroupedDimensions.length : 0;
  switch (type) {
    case VisualizationTypes.Table:
    case VisualizationTypes.SummaryTable:
      minRowGroupingLimit = MinRowGrouping.Table;
      maxRowGroupingLimit = MaxRowGrouping.Table;
      maxColGroupingLimit = MaxColumnGrouping.Table;
      break;

    case VisualizationTypes.AreaChart:
    case VisualizationTypes.BarChart:
    case VisualizationTypes.ColumnChart:
    case VisualizationTypes.LineChart:
      minRowGroupingLimit = MinRowGrouping.ColumnChart;
      maxRowGroupingLimit = MaxRowGrouping.ColumnChart;
      maxColGroupingLimit = MaxColumnGrouping.ColumnChart;
      break;

    default:
      break;
  }

  const isMinRowGroupingReached = rowGroupingCount <= minRowGroupingLimit;
  const isMaxRowGroupingReached = rowGroupingCount >= maxRowGroupingLimit;
  const isMaxColGroupingReached = columnGroupingCount >= maxColGroupingLimit;

  return {
    isMinRowGroupingReached,
    isMaxRowGroupingReached,
    minRowGroupingLimit,
    maxRowGroupingLimit,
    isMaxColGroupingReached,
    maxColGroupingLimit,
  };
}

function checkDrilldownLimits(groupedDimensions : IColumnWithIndex[]) {
  const minDrilldownGroupingLimit = 1;
  const drilldownGroupingCount = groupedDimensions ? groupedDimensions.length : 0;
  const isMinDrilldownGroupingReached = drilldownGroupingCount <= minDrilldownGroupingLimit;
  const isMaxDrilldownGroupingReached = drilldownGroupingCount >= MaxDrilldownGrouping;
  return {
    isMaxDrilldownGroupingReached,
    MaxDrilldownGrouping,
    isMinDrilldownGroupingReached,
    minDrilldownGroupingLimit,
  };
}

function displayName(
  dimension: IDimension | IColumn, withEnity: boolean = false,
): string { // TODO: Make this a method on IDimension
  if (!dimension) {
    return '';
  }
  if (dimension.BuilderConfig && dimension.BuilderConfig.IsDynamicField) {
    return `${withEnity ? `${dimension.BuilderConfig.Entity} | ` : ''}${dimension.BuilderConfig.DisplayName}`;
  }
  return (dimension as IDimension).ReferTo || dimension.Name || '';
}

export { checkAndGetGroupingLimits, displayName, checkDrilldownLimits };
