import React from 'react';
import StyledComponent from 'components/feature/ReportListing/ReportSection/report-section.style';
import ReportCardLoader from 'components/feature/ReportListing/ReportSection/ReportCard/report-card.loader';

const ReportActionLoader = () => (
  <StyledComponent>
    <div
      className="se-report-section"
    >
      <div
        className="se-report-section-header se-shimmer-cn"
      >
        <div
          className="se-shimmer"
        />
      </div>
      <div
        className="se-report-section-body"
      >
        {[1, 2, 3, 4, 5, 6].map((item) => (
          <ReportCardLoader key={item} />
        ))}
      </div>
    </div>
  </StyledComponent>
);

export default ReportActionLoader;
