import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ObjModel, ReduxModel, ReportResponseModel } from 'core/models';
import * as Actions from 'redux/report/actions';

const mapStateToProps = (state: ReduxModel.IGlobalState) => ({
  reduxState: state,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reduxAction: {
    drilldownChange: (
      filterId: string,
      filterValue: any,
      drilldownValue: any,
      user: string,
    ) => {
      dispatch(Actions.drilldownChange(
        filterId,
        filterValue,
        drilldownValue,
        user,
      ));
    },
    reverseDrilldown: (index: any) => {
      dispatch(Actions.reverseDrilldown(index));
    },
    reportDataLoad: () => {
      dispatch(Actions.reportDataLoadRequest());
    },
    reportConfigLoad: (reportId: string, isRefresh?: boolean) => {
      dispatch(Actions.reportConfigLoadRequest(reportId, isRefresh));
    },
    reorderColumns: (columns: Array<ReportResponseModel.IColumn>, isAutodrilldown?: boolean) => {
      dispatch(Actions.reorderColumns(columns, isAutodrilldown));
    },
    refresh: () => {
      dispatch(Actions.refresh());
    },
    autoDrilldown: (selectedMeasure: string, selectedRowData: ObjModel.Obj) => {
      dispatch(Actions.autodrilldown(selectedMeasure, selectedRowData));
    },
    autodrilldownReportDataLoad: () => {
      dispatch(Actions.autodrilldownReportDataLoadRequest());
    },
  },
});

export default () => connect(mapStateToProps, mapDispatchToProps);
