import React from 'react';

const SideNavBarSection = () => (
  <div
    className="se-side-nav-section"
  >
    <div
      className="se-side-nav-section-header se-shimmer-cn"
    >
      <div
        className="se-shimmer"
      />
    </div>
    <div
      className="se-side-nav-section-types-cn"
    >

      <div
        className="se-side-nav-section-sub-types"
      >
        {[1].map((index: number) => (
          <div
            className="se-side-nav-sub-type se-shimmer-cn"
            key={index}
          >
            <div
              className="se-shimmer"
            />
          </div>
        ))}
      </div>
      <div
        className="se-side-nav-cateogries"
      >
        {
            [1, 2, 3, 4, 5, 6, 7, 8].map((index: number) => (
              <div
                className="se-side-nav-cateogries-item se-shimmer-cn"
                key={index}
              >
                <div className="se-shimmer" />
              </div>
            ))
          }
      </div>
    </div>
  </div>
);

export default SideNavBarSection;
