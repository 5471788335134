import React, { useEffect } from 'react';
import { URLHash, SIERAPage } from 'core/constants/page-url';
import { IProps } from 'routes/main/main.d';
import Auth from 'components/container/Auth/auth.component';
import PageContainer from 'components/container/PageContainer/page-container.component';
import ReportList from 'components/feature/ReportListing/report-list.component';

const Main = (props: IProps) => {
  const { history, location } = props;
  let Component: React.ComponentType = null;

  useEffect(() => {
    if (!location.hash) {
      history.push({
        hash: 'report/pinned',
      });
    }
  }, [location.hash]);

  const hash = location.hash.replace('#', '').split('/');
  switch (hash[0]) {
    case URLHash.Report:
      Component = PageContainer(ReportList, SIERAPage.LISTING);
      break;

    default:
      Component = PageContainer(ReportList, SIERAPage.LISTING);
      break;
  }

  return (
    <Auth
      Component={Component}
      {...props}
    />
  );
};

export default Main;
