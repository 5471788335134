import React, { useState } from 'react';
import StyledContainer from 'components/common/Header/header.styled';
import AuthService from 'services/auth.service';
import { Link } from 'react-router-dom';
import StoreConnector from 'components/common/Header/header.redux';
import { IProps } from 'components/common/Header/header.d';
import LSQIcon from 'components/common/svg/LsqIcon/lsq-icon.svg';
import LogoutIcon from 'components/common/svg/Logout/logout.svg';
import sieralogo from 'assets/images/sieraBetaLogo.png'; // changed this to have beta tag

function HeaderComponent(props: IProps) {
  const {
    reduxState,
  } = props;

  const { auth } = reduxState;
  const { userAttributes } = auth;
  const [toggle, setToggle] = useState(false);

  const logout = async () => {
    await new AuthService().logout();
  };
  const toggleLogout = () => {
    setToggle(!toggle);
  };
  const getNameInitials = () => {
    if (userAttributes && userAttributes.pic && userAttributes.pic.length) {
      return (
        <img
          src={userAttributes.pic.url}
          alt="profile pic"
        />
      );
    }
    if (userAttributes && userAttributes.Name && userAttributes.Name.length) {
      const firstName = userAttributes.Name.split(' ')[0].charAt(0).toUpperCase();
      const lastName = userAttributes.Name.split(' ').length > 1
        ? userAttributes.Name.split(' ')[userAttributes.Name.split(' ').length - 1].charAt(0).toUpperCase() : '';
      return firstName + lastName;
    }
    return '';
  };
  return (
    <StyledContainer>
      <div className="se-header">
        <div className="se-header-old-logo">
          <a href={process.env.REACT_APP_LSQ_URL} aria-label="home"><LSQIcon height={24} width={24} fill="#0b293d" /></a>
        </div>
        <div className="se-header-divider"> </div>
        <div className="se-header-new-logo">
          <Link to="/"><img src={sieralogo} height={34} width={90} alt="logo" /></Link>
        </div>
        <div className="se-header-divider"> </div>
        <div className="se-user-details">

          <div className="se-user-name-country">
            <div className="se-username">{userAttributes && userAttributes.Name}</div>
            <div className="se-country">{userAttributes && userAttributes.UserTimeZone}</div>
          </div>
          <div className="avatar">
            <button
              onClick={toggleLogout}
              type="button"
              className="se-name-initials"
            >
              {getNameInitials()}

            </button>
            { toggle && (
            <div className="se-logout arrow-top">

              <button type="button" onClick={logout} className="se-logout-button">
                <LogoutIcon width={16} height={16} fill="#454f5b" />
                <span className="se-logout-text">Log Out</span>
              </button>
            </div>
            )}
          </div>
        </div>

      </div>

    </StyledContainer>
  );
}

export default StoreConnector()(HeaderComponent);
