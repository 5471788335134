import styled from 'styled-components';

const StyledReportComponent = styled.div`
.se-report-section {
  width: 100%;
  margin-bottom: 20px;
  font-family: Regular;
}

.se-report-section-header {
  border: 0;
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  color:var(--headerColor);
  font-size: 13px;
  background: var(--borderColor) 0% 0% no-repeat padding-box;
  position: sticky;
  top: 120px;
  cursor: pointer;
  z-index:1;
}

.se-report-section-icon {
  transition: transform 0.1s linear;
}

.se-rotate-icon {
  transform: rotate(90deg)
}

.se-report-section-name {
  margin-left: 8px;
  font-family:'Regular'
}

.se-report-section-count {
  margin-left: 8px;
  padding: 3px 6px;
  background: var(--searchBoxTextColor);
  border-radius: 16px;
  color: #fff;
  font-size: 11px;
}

.se-report-section-count.hide {
  display: none;
}

.se-report-section-body {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1px;
}

.se-report-section-body.hide {
  display: none;
}
`;

export default StyledReportComponent;
