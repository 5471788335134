import React, { useEffect, useRef, useState } from 'react';
import CircularLoadButton from 'components/common/CircularLoadButton/circular-load-button.component';
import JobInfoComponent from 'components/common/JobInfoComponent/job-info-component';
import CloseIcon from 'components/common/svg/Close/close.svg';
import { ReduxModel } from 'core/models';
import { useDispatch, useSelector } from 'react-redux';
import { JobTypes } from 'core/constants/report';
import { refresh } from 'redux/report/actions';
import StyledContainer from './load-information.style';
import { PendoClassNames } from '../../ReportSidebar/common/constants';
import { authSelector } from '../../ReportSidebar/ReportView/report-view.selector';

const LoadInformation = () => {
  const [jobObject, reportId] = useSelector((state: ReduxModel.IGlobalState) => [state.report.jobObject, state.report.reportId] as const);
  const { isMarvin } = useSelector(authSelector);
  const dispatch = useDispatch();
  const { headerName, jobsArray } = jobObject;
  const today = new Date();
  const [clicked, setClicked] = useState(false);
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [isRefreshAllowed, setIsRefreshAllowed] = useState(false);
  const jobInfoRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (jobInfoRef.current && !jobInfoRef.current.contains(e.target)
        && buttonRef.current && !buttonRef.current.contains(e.target)) {
        setShowJobDetails(false);
        setClicked(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [clicked]);

  const onMouseEnterEventHandler = () => {
    if (clicked) {
      return;
    }
    setShowJobDetails(true);
  };

  const onMouseLeaveEventHandler = () => {
    if (clicked) {
      return;
    }
    setShowJobDetails(false);
  };

  const onCloseClickEventHandler = () => {
    setClicked(false);
    setShowJobDetails(false);
  };

  const minutesRefreshedBefore = (lastRunOn: Date) => Math.floor(Math.abs(today.getTime() - lastRunOn.getTime()) / 60000);

  const reloadPage = () => {
    if (isMarvin) {
      dispatch(refresh());
    } else {
      window.location.reload();
    }
  };

  return (
    <StyledContainer>
      <div className="se-load-information-container">
        <div
          className={`se-job-info-cn ${!(showJobDetails) && 'se-display-none'}`}
          ref={jobInfoRef}
        >
          <div className="se-info-header">
            <span>{headerName}</span>
            {clicked
            && (
            <span
              className="se-close-icon"
            >
              <button
                className="se-close-btn"
                type="button"
                onClick={onCloseClickEventHandler}
              >
                <CloseIcon
                  width={20}
                  height={20}
                  fill="#637381"
                />
              </button>
            </span>
            )}
          </div>
          <div className="se-job-load-details">
            {jobsArray.map((job, index) => (
              <JobInfoComponent
                key={job.type}
                type={job.type}
                isRefreshReq={job.isRefreshReq}
                infoText={job.InfoText}
                showDivider={index !== jobsArray.length - 1}
                LastRefreshed={minutesRefreshedBefore(job.Details.LastRefreshTime)}
                NextRefreshInterval={job.Details.NextRefreshInterval}
              />
            ))}
          </div>
        </div>
        <button
          type="button"
          className={`se-load-information-btn-cn  ${PendoClassNames.DataRefreshButton || ''} `}
          ref={buttonRef}
          onClick={() => (isRefreshAllowed ? reloadPage() : setClicked(true))}
          onMouseEnter={onMouseEnterEventHandler}
          onMouseLeave={onMouseLeaveEventHandler}
        >
          {jobsArray.map((job, index) => {
            const minutesRanBefore = minutesRefreshedBefore(job.Details.LastRefreshTime);
            const currentPercentageCompleted = Math.floor((minutesRanBefore * 100) / job.Details.NextRefreshInterval);

            // if percentage completion is greater than 90 we want to set 90% loader
            const percentageCompletion = currentPercentageCompleted > 90 ? 90 : currentPercentageCompleted;
            // for Load job we have multiplied the interval by 3 in the (reducer), so pauseAt has to 30% to start the polling on actual interval
            const startPollingAt = job.type === JobTypes.Load ? 30 : 90;
            return (
              <div
                key={job.type}
                className={`se-load-information-button ${index === jobsArray.length - 1 && 'se-load-btn'}`}
              >
                <CircularLoadButton
                  uniqKey={job.type}
                  progressColor={job.type === JobTypes.Load ? '#116FD6' : '#3BB345'}
                  doneGlowHighState={job.type === JobTypes.Load ? '#084E96' : '#297D30'}
                  totalTimeInterval={job.Details.NextRefreshInterval}
                  shouldPause
                  pauseAt={90}
                  startPollingAt={startPollingAt}
                  setIsRefreshAllowed={setIsRefreshAllowed}
                  percentageCompletion={percentageCompletion}
                  isRefreshReq={job.isRefreshReq}
                  reportId={reportId}
                />
              </div>
            );
          })}
        </button>
        <div className="se-divider" />
      </div>
    </StyledContainer>
  );
};

export default LoadInformation;
