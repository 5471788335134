import React from 'react';
import StyledBarLoader from 'components/common/loader/BarLoader/bar-loader.styled';
import { IProps } from 'components/common/loader/BarLoader/bar-loader.d';

const BarLoader = (props: IProps) => {
  const { style, content } = props;
  return (
    <StyledBarLoader style={style}>
      <div
        className={content.length === 0 ? '' : 'se-loader'}
      >
        {content}
      </div>
      {content}
    </StyledBarLoader>
  );
};

export default BarLoader;
