import styled from 'styled-components';

const StyledUnderlyingDataComponent = styled.div`
width: 100%;
height: auto;

.se-rawdata-viewer-body.ag-theme-alpine.ag-grid-cn {
  transition-property: all;
  transition-timing-function: ease-in-out;
  height: auto;
}


.se-rawdata-viewer-container {
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 12px;
  }

  .se-rawdata-body-cn {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .se-values-table span{
    color:var(--searchBoxTextColor);
  }
  .se-rawdata-viewer-header-text {
    font-size: 16px;
    color: var(--rawDataHeader);
    padding: 0 5px;
  }
  .se-rawdata-viewer-button.active{
    background-color: var(--rawDataButton);
   // padding: 4px 4px 2px 4px;
  }

  .se-rawdata-viewer-button:hover{
    border: 1px solid var(--collapsibleColor);
  }
  .se-rawdata-viewer-header {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    background-color: var(--noDataBackground);
    height: 56px;
    align-items: center;
  }
  
  .se-rawdata-viewer-button {
   background-color: transparent;
   border: 1px solid transparent;
   padding: 5px 5px 2px 5px;

  }
  .se-rawdata-viewer-button:hover{
    color: var(--base-font-color);
  }
  
  .se-rawdata-viewer-button:hover svg{
    stroke: var(--base-font-color);
  }
  
  .se-rawdata-viewer-button svg {
    color: transparent;
    stroke:var(--paginationColor);
    padding: 0 2px;
  }
  
  .se-rawdata-viewer-body > div{
    overflow: hidden;
    padding: 16px 0;
  }`;

export default StyledUnderlyingDataComponent;
