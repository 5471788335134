import { ReportActionTypes } from 'core/constants/redux-action-types';
import {
  AuthReduxModel, FilterModel, ObjModel, ReportReduxModel, ReportResponseModel,
} from 'core/models';
import {
  ColumnModes, DimensionMode, DimensionStatus, ReportViewTypes,
} from 'core/constants/report';
import { ReportViewState } from 'redux/report-view/report-view.state';
import { IDataRefresh } from 'core/models/report-response';

export const reportConfigLoadRequest = (reportId: string, isRefresh?: boolean)
: ReportReduxModel.IReportConfigLoadRequest => ({
  type: ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST,
  payload: {
    reportId,
    isRefresh,
  },
});

export const reportConfigLoadSuccess = (
  reportConfig: ReportResponseModel.IReportConfig, variables: AuthReduxModel.IVariables,
)
: ReportReduxModel.IReportConfigLoadSuccess => ({
  type: ReportActionTypes.REPORT_CONFIG_LOAD_SUCCESS,
  payload: {
    reportConfig,
    variables,
  },
});

export const reportConfigLoadFailure = ()
: ReportReduxModel.IReportConfigLoadFailure => ({
  type: ReportActionTypes.REPORT_CONFIG_LOAD_FAILURE,
});

export const filtersLoad = ()
: ReportReduxModel.IFiltersLoad => ({
  type: ReportActionTypes.FILTERS_LOAD,
});

export const filterLoadRequest = (
  filterId: string, variables: AuthReduxModel.IVariables, isLazyLoad?: boolean,
  filterValue?: any,
): ReportReduxModel.IFilterLoadRequest => ({
  type: ReportActionTypes.FILTER_LOAD_REQUEST,
  payload: {
    filterId,
    variables,
    isLazyLoad,
    filterValue,
  },
});

export const filterLoadSuccess = (
  filterId: string, filterResponse: FilterModel.IFilterResponse,
): ReportReduxModel.IFilterLoadSuccess => ({
  type: ReportActionTypes.FILTER_LOAD_SUCCESS,
  payload: {
    filterId,
    filterResponse,
  },
});

export const filterLoadFailure = (filterId: string)
: ReportReduxModel.IFilterLoadFailure => ({
  type: ReportActionTypes.FILTER_LOAD_FAILURE,
  payload: {
    filterId,
  },
});

export const reportDataLoadRequest = ()
: ReportReduxModel.IReportDataLoadRequest => ({
  type: ReportActionTypes.REPORT_DATA_LOAD_REQUEST,
});

export const reportDataLoadSuccess = (reportData: ReportResponseModel.IReportData)
: ReportReduxModel.IReportDataLoadSuccess => ({
  type: ReportActionTypes.REPORT_DATA_LOAD_SUCCESS,
  payload: {
    reportData,
  },
});

export const reportDataLoadFailure = ()
: ReportReduxModel.IReportDataLoadFailure => ({
  type: ReportActionTypes.REPORT_DATA_LOAD_FAILURE,
});

export const filterChange = (
  filterId: string, filterValue: any, variables: AuthReduxModel.IVariables,
  useChangedFilters?: boolean,
)
: ReportReduxModel.IFilterChange => ({
  type: ReportActionTypes.FILTER_CHANGE,
  payload: {
    filterId,
    filterValue,
    variables,
    useChangedFilters,
  },
});

export const paginationChange = (
  paginationKey: string, paginationValue: any, isAutodrilldown?: boolean,
)
: ReportReduxModel.IPaginationChange => ({
  type: ReportActionTypes.PAGINATION_CHANGE,
  payload: {
    paginationKey,
    paginationValue,
    isAutodrilldown,
  },
});

export const sortingChange = (
  sortingField: string, sortingOrder: string, isMultiSort: boolean, isUnderlyingTable?: boolean, isMultiSortInActiveDimension?:boolean,
)
: ReportReduxModel.ISortingChange => ({
  type: ReportActionTypes.SORTING_CHANGE,
  payload: {
    sortingField,
    sortingOrder,
    isMultiSort,
    isUnderlyingTable,
    isMultiSortInActiveDimension,
  },
});

export const drilldownChange = (
  filterId: string, value: any, drilldownValue: any, user: string,
)
: ReportReduxModel.IDrilldownChange => ({
  type: ReportActionTypes.DRILLDOWN_CHANGE,
  payload: {
    filterId,
    value,
    drilldownValue,
    user,
  },
});

export const reverseDrilldown = (index: any)
: ReportReduxModel.IReverseDrilldown => ({
  type: ReportActionTypes.REVERSE_DRILLDOWN,
  payload: {
    index,
  },
});

export const applyFilters = ()
: ReportReduxModel.IApplyFilters => ({
  type: ReportActionTypes.APPLY_FILTERS,
});

export const clearFilters = (variables: AuthReduxModel.IVariables)
: ReportReduxModel.IClearFilters => ({
  type: ReportActionTypes.CLEAR_FILTERS,
  payload: {
    variables,
  },
});

export const dynamicDimensionsLoadRequest = (selectedMeasure?: string)
    : ReportReduxModel.IDynamicDimensionsLoadRequest => ({
  type: ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST,
  payload: {
    selectedMeasure,
  },
});

export const dynamicDimensionsLoadSuccess = (dynamicDimensions: Array<ReportResponseModel.IColumn>, selectedMeasure?: string)
    : ReportReduxModel.IDynamicDimensionsLoadSuccess => ({
  type: ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_SUCCESS,
  payload: {
    dynamicDimensions,
    selectedMeasure,
  },
});

export const dynamicDimensionsLoadFailure = ()
    : ReportReduxModel.IDynamicDimensionsLoadFailure => ({
  type: ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_FAILURE,
});

export const applyReportView = (reportState: ReportReduxModel.IState, variables: AuthReduxModel.IVariables, reportViewState: ReportViewState, urlViewType?: ReportViewTypes)
: ReportReduxModel.IApplyReportView => ({
  type: ReportActionTypes.APPLY_REPORT_VIEW,
  payload: {
    reportState,
    variables,
    reportViewState,
    urlViewType,
  },
});

export const setToolbarMenuItem = (index: number)
: ReportReduxModel.ISetToolbarMenuItem => ({
  type: ReportActionTypes.SET_TOOLBAR_MENU_ITEM,
  payload: {
    index,
  },
});

export const setGrouping = (sourceIndex: number, status: DimensionStatus, mode: DimensionMode, destinationIndex?: number, isSwapEnabled?: boolean)
  : ReportReduxModel.ISetGrouping => ({
  type: ReportActionTypes.SET_GROUPING,
  payload: {
    sourceIndex, status, mode, destinationIndex, isSwapEnabled,
  },
});

export const setZeroAnchor = (index: number)
: ReportReduxModel.ISetZeroAnchor => ({
  type: ReportActionTypes.SET_ZERO_ANCHOR,
  payload: {
    index,
  },
});

export const applyDimensions = ()
: ReportReduxModel.IApplyDimensions => ({
  type: ReportActionTypes.APPLY_DIMENSIONS,
});

export const reorderColumns = (
  columns: Array<ReportResponseModel.IColumn>, isAutodrilldown?: boolean,
)
: ReportReduxModel.IReorderColumns => ({
  type: ReportActionTypes.REORDER_COLUMNS,
  payload: {
    columns,
    isAutodrilldown,
  },
});

export const saveColumnsOrder = (columns: Array<ReportResponseModel.IColumn>)
: ReportReduxModel.ISaveColumnsOrderRequest => ({
  type: ReportActionTypes.SAVE_COLUMNS_ORDER_REQUEST,
  payload: {
    columns,
  },
});

export const setMeasureState = (sourceIndex: number, status: ColumnModes)
    : ReportReduxModel.ISetMeasureState => ({
  type: ReportActionTypes.SET_MEASURE_STATE,
  payload: { sourceIndex, status },
});

export const reOrderMeasure = (sourceIndex: number, destinationIndex: number)
    : ReportReduxModel.IReorderMeasure => ({
  type: ReportActionTypes.REORDER_MEASURE,
  payload: { sourceIndex, destinationIndex },
});

export const toggleZeroAnchors = (status: boolean)
: ReportReduxModel.IToggleZeroAnchors => ({
  type: ReportActionTypes.TOGGLE_ZERO_ANCHORS,
  payload: {
    status,
  },
});

export const searchDropdownOption = (
  filterId: string, searchKey: string, filterValue: any,
)
: ReportReduxModel.ISearchDropdownOptions => ({
  type: ReportActionTypes.SEARCH_DROPDOWN_OPTIONS,
  payload: {
    filterId,
    searchKey,
    filterValue,
  },
});

export const loadMoreDropdownOption = (
  filterId: string, filterValue: any,
)
: ReportReduxModel.ILoadMoreDropdownOptions => ({
  type: ReportActionTypes.LOAD_MORE_DROPDOWN_OPTIONS,
  payload: {
    filterId,
    filterValue,
  },
});

export const addDynamicFilter = (index: number)
: ReportReduxModel.IAddDynamicFilter => ({
  type: ReportActionTypes.ADD_DYNAMIC_FILTER,
  payload: {
    index,
  },
});

export const deleteDynamicFilter = (filterId: string)
: ReportReduxModel.IDeleteDynamicFilter => ({
  type: ReportActionTypes.DELETE_DYNAMIC_FILTER,
  payload: {
    filterId,
  },
});

export const autodrilldown = (selectedMeasure: string, selectedRowData: ObjModel.Obj)
: ReportReduxModel.IAutoDrilldown => ({
  type: ReportActionTypes.AUTODRILLDOWN,
  payload: {
    selectedMeasure,
    selectedRowData,
  },
});

export const reset = ()
: ReportReduxModel.IReset => ({
  type: ReportActionTypes.RESET,
});

export const refresh = ()
: ReportReduxModel.IRefresh => ({
  type: ReportActionTypes.REFRESH,
});

export const autodrilldownReportDataLoadRequest = ()
: ReportReduxModel.IAutoDrilldownReportDataLoadRequest => ({
  type: ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST,
});

export const autodrilldownReportDataLoadSuccess = (
  reportData: ReportResponseModel.IReportData,
)
: ReportReduxModel.IAutoDrilldownReportDataLoadSuccess => ({
  type: ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_SUCCESS,
  payload: {
    reportData,
  },
});

export const autodrilldownReportDataLoadFailure = ()
: ReportReduxModel.IAutoDrilldownReportDataLoadFailure => ({
  type: ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_FAILURE,
});

export const setGroupingDrilldown = (sourceIndex: number, status: DimensionStatus, destinationIndex?: number, isSwapEnabled?: boolean)
: ReportReduxModel.ISetGroupingDrilldown => ({
  type: ReportActionTypes.SET_GROUPING_DRILLDOWN,
  payload: {
    sourceIndex, status, destinationIndex, isSwapEnabled,
  },
});
export const setBinning = (index: any, value: string): ReportReduxModel.ISetBinning => ({
  type: ReportActionTypes.SET_BINNING,
  payload: {
    index,
    value,
  },
});

export const exportDataLoadRequest = (fileType: string)
: ReportReduxModel.IExportDataLoadRequest => ({
  type: ReportActionTypes.EXPORT_DATA_LOAD_REQUEST,
  payload: {
    fileType,
  },
});

export const exportDataLoadSuccess = (exportData: ReportResponseModel.IExportData)
: ReportReduxModel.IExportDataLoadSuccess => ({
  type: ReportActionTypes.EXPORT_DATA_LOAD_SUCCESS,
  payload: {
    exportData,
  },
});

export const exportDataLoadFailure = (failureResponse?: ReportResponseModel.IExportDataFailureResponse)
: ReportReduxModel.IExportDataLoadFailure => ({
  type: ReportActionTypes.EXPORT_DATA_LOAD_FAILURE,
  payload: {
    failureResponse,
  },
});

export const exportListRequest = ()
: ReportReduxModel.IExportListLoadRequest => ({
  type: ReportActionTypes.EXPORT_LIST_LOAD_REQUEST,
});

export const getExportUsageStatus = ()
: ReportReduxModel.IExportUsageStatusRequest => ({
  type: ReportActionTypes.EXPORT_USAGE_STATUS_REQUEST,
});

export const exportUsageStatusSuccess = (exportUsageData: ReportResponseModel.IExportUsageData)
: ReportReduxModel.IExportUsageStatusSuccess => ({
  type: ReportActionTypes.EXPORT_USAGE_STATUS_SUCCESS,
  payload: exportUsageData,
});

export const exportUsageStatusFailure = ()
: ReportReduxModel.IExportUsageStatusFailure => ({
  type: ReportActionTypes.EXPORT_USAGE_STATUS_FAILURE,
});

export const exportListRequestByIds = (exportIds: Array<string>)
: ReportReduxModel.IExportListByIdsLoadRequest => ({
  type: ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_REQUEST,
  payload: { exportIds },
});

export const exportListSuccess = (exportListData: Array<ReportResponseModel.IExportData>)
: ReportReduxModel.IExportListLoadSuccess => ({
  type: ReportActionTypes.EXPORT_LIST_LOAD_SUCCESS,
  payload: {
    exportListData,
  },
});

export const exportListByIdsSuccess = (exportListData: Array<ReportResponseModel.IExportData>)
: ReportReduxModel.IExportListLoadByIdsSuccess => ({
  type: ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_SUCCESS,
  payload: {
    exportListData,
  },
});

export const exportListFailure = ()
: ReportReduxModel.IExportListLoadFailure => ({
  type: ReportActionTypes.EXPORT_LIST_LOAD_FAILURE,
});

export const exportListByIdsFailure = ()
: ReportReduxModel.IExportListLoadByIdsFailure => ({
  type: ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_FAILURE,
});

export const retryExportRequest = (exportID: string)
: ReportReduxModel.IRetryExportRequest => ({
  type: ReportActionTypes.RETRY_EXPORT_REQUEST,
  payload: {
    exportID,
  },
});

export const retryExportSuccess = (isInProgress: string, exportID:string)
: ReportReduxModel.IRetryExportSuccess => ({
  type: ReportActionTypes.RETRY_EXPORT_SUCCESS,
  payload: {
    isInProgress,
    exportID,
  },
});

export const retryExportFailure = (exportID: string)
: ReportReduxModel.IRetryExportFailure => ({
  type: ReportActionTypes.RETRY_EXPORT_FAILURE,
  payload: {
    exportID,
  },
});

export const updateTriggerExport = (exportFormatType: string)
: ReportReduxModel.IUpdateTriggerExport => ({
  type: ReportActionTypes.UPDATE_TRIGGER_EXPORT,
  payload: {
    exportFormatType,
  },
});

export const dataRefreshDetailsLoadRequest = (reportId: string, isPolling?: boolean)
: ReportReduxModel.IDataRefreshDetailsLoadRequest => ({
  type: ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST,
  payload: {
    reportId,
    isPolling,
  },
});

export const dataRefreshDetailsLoadSuccess = (jobDetails: IDataRefresh, isPolling?: boolean)
: ReportReduxModel.IDataRefreshDetailsLoadSuccess => ({
  type: ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_SUCCESS,
  payload: {
    jobDetails,
    isPolling,
  },
});

export const dataRefreshDetailsLoadFailure = ()
: ReportReduxModel.IDataRefreshDetailsLoadFailure => ({
  type: ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_FAILURE,
});
