/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import Tooltip from 'rc-tooltip';
import { SortingOrderTypes, CompulsorySort } from 'core/constants/sorting';
import StyledColumnHeaderComponent from 'components/common/visualization/Grid/ColumnHeader/column-header.styled';
import { IProps } from 'components/common/visualization/Grid/ColumnHeader/column-header.d';
import UnsortIcon from 'components/common/svg/Unsort/unsort.component';
import AscSortIcon from 'components/common/svg/AscShort/asc-short.svg';
import { getRowIndexOfRowGroupColumn } from 'core/utils/report.util';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';

const ColumnHeader = (props: IProps) => {
  const {
    enableSorting, displayName, sorting, column, columnApi,
    activeDimensionsLength,
  } = props;
  const getCurrentColumnDefination = column.getColDef();
  const getAllColDefs = columnApi.getColumns();
  // showRowGroup for grouped field, colId for non grouped field
  const sortField: any = getCurrentColumnDefination.showRowGroup || getCurrentColumnDefination.colId;
  let rowGroupIndex = 0;
  let isRowGroup = false;
  if (getCurrentColumnDefination.showRowGroup) {
    rowGroupIndex = getRowIndexOfRowGroupColumn(getAllColDefs, sortField);
    isRowGroup = true;
  }

  let sortedBy = '';
  let multiSortSequence = -1;
  if (enableSorting) {
    if (sorting) {
      const index = sorting.sort.findIndex((item) => item.Field === sortField);
      if (index !== -1) {
        sortedBy = sorting.sort[index].Direction;
        if (sorting.isMultiSort || activeDimensionsLength > 1) {
          multiSortSequence = index + 1;
        }
      }
    }
  }

  const [sortingOrder, setSortingOrder] = useState<string>(sortedBy);

  const onHeaderClicked = (event: React.MouseEvent) => {
    if (enableSorting) {
      if (sorting) {
        let newSortingOrder = SortingOrderTypes.ASC;
        switch (sortingOrder) {
          case SortingOrderTypes.ASC:
            newSortingOrder = SortingOrderTypes.DESC;
            break;
          case SortingOrderTypes.DESC:
            newSortingOrder = '';
            // if length of sorting array is 1, then disabling none option, user won't be able to remove sort in that case, only he can
            // change it to ascending and descending
            if ((isRowGroup && activeDimensionsLength > 1 && rowGroupIndex < activeDimensionsLength - 1) || (sorting.sort.length === 1)) {
              newSortingOrder = SortingOrderTypes.ASC;
            }
            break;
          default:
            break;
        }
        conditonalSorting(event, newSortingOrder);
      }
    }
  };
  const conditonalSorting = (event:React.MouseEvent, newSortingOrder:string) => {
    if (activeDimensionsLength > 1) {
      if (isRowGroup) {
        if (rowGroupIndex < activeDimensionsLength - 1) {
          setSortingOrder(newSortingOrder);
          if (event.metaKey || event.ctrlKey) {
            sorting.onChange(
              sortField,
              newSortingOrder,
              event.metaKey || event.ctrlKey,
              false,
            );
          } else {
            sorting.onChange(
              sortField,
              newSortingOrder,
              true,
              true,
            );
          }
        } else {
          sortingForNonRowGroupedColumn(event, newSortingOrder, true, true);
        }
      } else {
        setSortingOrder(newSortingOrder);
        if (event.metaKey || event.ctrlKey) {
          sorting.onChange(
            sortField,
            newSortingOrder,
            event.metaKey || event.ctrlKey,
            false,
          );
        } else {
          sorting.onChange(
            sortField,
            newSortingOrder,
            true,
            true,
          );
        }
      }
    } else {
      sortingForNonRowGroupedColumn(event, newSortingOrder, false, false);
    }
  };

  const sortingForNonRowGroupedColumn = (event:React.MouseEvent, newSortingOrder:string, groupItem:boolean, isMultiSortInActiveDimension:boolean) => {
    setSortingOrder(newSortingOrder);
    if (event.metaKey || event.ctrlKey) {
      sorting.onChange(
        sortField,
        newSortingOrder,
        event.metaKey || event.ctrlKey,
      );
    } else {
      sorting.onChange(
        sortField,
        newSortingOrder,
        groupItem,
        isMultiSortInActiveDimension,
      );
    }
  };

  let sort = null;
  const isValidRowGroup = isRowGroup && activeDimensionsLength > 1;
  const isMandatorySortForRowGroup = isValidRowGroup && rowGroupIndex < activeDimensionsLength - 1;
  // getting tooltip messages for mandatory and non mandatory row group fields
  const getTooltipForRowGroup = () => {
    if (isMandatorySortForRowGroup) {
      return <span>{CompulsorySort.TOOLTIP}</span>;
    }
    return displayName;
  };
  // getting classes  for mandatory and non mandatory row group fields
  const getClassesForRowGroup = () => {
    if (isValidRowGroup) {
      return 'se-row-group-sort';
    }
    if (activeDimensionsLength === 1 && isRowGroup) {
      return 'se-row-group-sort';
    }
    return 'se-non-row-sort';
  };
  if (enableSorting) {
    sort = (
      <div
        className={`se-column-header-sort-cn ${PendoClassNames.SortData}`}
      >
        {
          multiSortSequence !== -1 && (
          <div
            className="se-column-header-sort-sequence"
          >
            {multiSortSequence}
            {isMandatorySortForRowGroup ? '*' : ''}
          </div>
          )
        }
        {sortingOrder === SortingOrderTypes.ASC && (

          <div
            className={`se-column-header-sort-up-icon ${sortingOrder === SortingOrderTypes.ASC
              ? 'active' : 'inactive'}`}
          >

            <AscSortIcon
              width={16}
              height={16}
              fill="#637381"
            />

          </div>

        )}
        { sortingOrder === SortingOrderTypes.DESC && (

          <div
            className={`se-column-header-sort-down-icon ${sortingOrder === SortingOrderTypes.DESC
              ? 'active' : 'inactive'}`}
          >

            <UnsortIcon
              width={16}
              height={16}
              fill="#637381"
            />

          </div>

        )}
        { sortingOrder === '' && (
        <div
          className={`se-column-header-sort-down-icon ${sortingOrder === ''
            ? 'active' : 'inactive'}`}
        >
          <UnsortIcon
            width={16}
            height={16}
          />
        </div>
        )}
      </div>
    );
  }

  return (
    <StyledColumnHeaderComponent>
      <Tooltip
        placement="bottom"
        overlay={getTooltipForRowGroup()}
        mouseEnterDelay={0.5}
        overlayClassName={getClassesForRowGroup()}
      >
        <div
          className="se-column-header-cn"
          tabIndex={0}
          onClick={onHeaderClicked}
        >
          <div
            className="se-column-header-label"
          >
            {displayName}
          </div>
          {sort}
        </div>
      </Tooltip>
    </StyledColumnHeaderComponent>
  );
};

export default ColumnHeader;
