import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const SwitchIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="toggle_on_black_24dp_1_"
    data-name="toggle_on_black_24dp (1)"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 28 28"
  >
    <path
      id="Path_7619"
      data-name="Path 7619"
      d="M0,28H28V0H0Z"
      fill="none"
    />
    <path
      id="Path_7620"
      data-name="Path 7620"
      d="M19.506,18.645H7.835A5.823,5.823,0,1,1,7.835,7H19.506a5.823,5.823,0,1,1,0,11.645Zm0-9.316a3.494,3.494,0,1,0,3.5,3.494A3.493,3.493,0,0,0,19.506,9.329Z"
      transform="translate(0.329 1.177)"
      fill={fill}
    />
  </svg>
);

SwitchIcon.defaultProps = {
  fill: '#116fd6',
};

export default SwitchIcon;
