import React from 'react';
import StyledLoadingComponent from 'components/common/LoadingSpinner/loading.style';

const LoadingComponent = () => (
  <StyledLoadingComponent>
    <div className="se-loading-spinner" />
  </StyledLoadingComponent>
);

export default LoadingComponent;
