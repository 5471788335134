import React from 'react';
import { FieldEntities } from 'core/utils/report.util';
import { IFieldEntityProps } from 'components/feature/Report/ReportSidebar/common/FieldEntity/field-entity.d';
import StyledContainer from 'components/feature/Report/ReportSidebar/common/FieldEntity/field-entity.style';
import ArrowDownIcon from 'components/common/svg/ArrowDown/arrow-down.svg';

const FieldEntity = (props: IFieldEntityProps) => {
  const {
    entity, expand, handleExpandedEntity, showcase,
  } = props;
  const data = FieldEntities[entity];
  return (
    <StyledContainer>
      <button
        className={`se-entity-header ${expand ? 'expand' : ''}`}
        type="button"
        onClick={() => handleExpandedEntity()}
      >
        <span
          className="se-icon-cn se-entity-icon"
        >
          {
            data.icon && (
            <data.icon
              width={16}
              height={16}
              fill={data.color}
            />
            )
        }
        </span>
        <span
          className="se-entity-label"
        >
          {entity}
        </span>
        <span
          className="se-icon-cn se-arrow-indicator"
        >
          <ArrowDownIcon
            width={24}
            height={24}
          />
        </span>
      </button>
      <div
        className={`se-entity-showcase ${expand ? 'expand' : 'collapse'}`}
      >
        {showcase}
      </div>
    </StyledContainer>
  );
};

export default FieldEntity;
