import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from 'rc-tooltip';
import { IProps } from 'components/feature/ReportListing/ReportSection/ReportCard/report-card.d';
import StyledComponent from 'components/feature/ReportListing/ReportSection/ReportCard/report-card.style';
import StoreConnector from 'components/feature/ReportListing/ReportSection/ReportCard/report-card.redux';
import ReportAction from 'components/feature/ReportListing/ReportSection/ReportCard/ReportAction/report-action.component';
import ReportIcon from 'components/feature/ReportListing/ReportIcon/report-icon.component';

const ReportCard = (props: IProps) => {
  const {
    sectionId, report, reduxState, ...rest
  } = props;
  const { reportList } = reduxState;
  const { selectedReportType, categories } = reportList;
  const { location } = rest;

  const reportCategory = selectedReportType.isReportCategory ? selectedReportType.id : sectionId;
  const category = categories.find((item) => (item.CategoryId === reportCategory));
  let iconColor = '';
  if (category) {
    iconColor = category.IconColor;
  }

  return (
    <StyledComponent>
      <Link
        key={report.ReportId}
        to={`report/${report.ReportId}?referral=${location.hash.slice(1)}`}
        className="se-report-card"
      >
        <div
          className="se-report-header"
        >
          <div
            className="se-report-card-icon"
          >
            <ReportIcon
              type={report.VisualisationType}
              color={iconColor}
            />
          </div>
          <div
            className="se-report-title"
          >
            {report.DisplayName}
          </div>
          <div
            className="se-report-action"
          >
            <ReportAction
              report={report}
            />
          </div>
        </div>
        <Tooltip
          placement="bottom"
          overlayClassName="se-report-card-tooltip"
          overlay={(
            <span>
              {report.Description}
            </span>
)}
          mouseEnterDelay={1.5}
        >
          <div
            className="se-report-description"
          >

            {report.Description}

          </div>
        </Tooltip>
      </Link>
    </StyledComponent>
  );
};

export default StoreConnector()(ReportCard);
