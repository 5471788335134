import styled from 'styled-components';

export default styled.div`
button.se-custom-range {
    display: block;
    width: 100%;
    border: 0;
    padding: 8px;
    font-size: 13px;
    color: #454F5B;
    text-align: center;
    font-family: Regular;
    :hover{
         background: #E5F0FB;;
    }
    :disabled {
        background: #E5F0FB;
        font-family: SemiBold;
    }
}
`;
