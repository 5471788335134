import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const ArrowDownIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="arrow_drop_down_black_24dp_1_"
    data-name="arrow_drop_down_black_24dp (1)"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 20 15"
  >
    <path
      id="Path_4"
      data-name="Path 4"
      d="M0,0H20V15H0Z"
      fill="none"
    />
    <path
      id="Path_5"
      data-name="Path 5"
      d="M7,10l4.345,4.345L15.69,10Z"
      transform="translate(-1.345 -4.392)"
      fill={fill}
    />
  </svg>
);

ArrowDownIcon.defaultProps = {
  fill: '#454f5b',
};

export default ArrowDownIcon;
