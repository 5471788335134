import { isEmpty, isEqual } from 'lodash';
import { FilterType, UserFilterPayloadType } from 'core/constants/filter';
import {
  FilterModel, ObjModel, ReportResponseModel,
} from 'core/models';
import { dateRangeProcessor, isEqualDateRangePayload } from 'core/utils/date.util';

export const isFilterChanged = (
  config: Array<ReportResponseModel.IFilterConfig>, appliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>,
  activeFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>,
) => {
  let checkNextFilter = false;
  for (let i = 0; i < config.length; i += 1) {
    const filter = config[i];

    if (activeFilters[filter.ID] && appliedFilters[filter.ID]) {
      if (!activeFilters[filter.ID].Disabled && !appliedFilters[filter.ID].Disabled) {
        switch (filter.Type) {
          case FilterType.UserMultiSelect:
            if (isUserFilterValueChanged(
              activeFilters[filter.ID].FilterResponse,
              appliedFilters[filter.ID].FilterResponse,
              appliedFilters[filter.ID].OptionsMeta.Count,
            )) {
              return true;
            }
            break;
          case FilterType.GroupMultiSelect:
          case FilterType.CustomDefinedMultiSelect:
          case FilterType.LSQMetadataMultiSelect:
            if (activeFilters[filter.ID].FilterResponse && appliedFilters[filter.ID].FilterResponse) {
              if (activeFilters[filter.ID].FilterOptions && activeFilters[filter.ID].FilterOptions) {
                if (activeFilters[filter.ID].FilterResponse.length
                 === appliedFilters[filter.ID].FilterResponse.length) {
                  for (let j = 0; j < appliedFilters[filter.ID].FilterResponse.length; j += 1) {
                    if (!activeFilters[filter.ID].FilterResponse.includes(
                      appliedFilters[filter.ID].FilterResponse[j],
                    )) {
                      return true;
                    }
                  }
                  checkNextFilter = true;
                } else {
                  return true;
                }
              }
            }
            break;

          case FilterType.DateRange:
            if (activeFilters[filter.ID].FilterResponse && appliedFilters[filter.ID].FilterResponse) {
              if (!isEqualDateRangePayload(activeFilters[filter.ID].FilterResponse,
                appliedFilters[filter.ID].FilterResponse)) {
                return true;
              }
              checkNextFilter = true;
            }
            break;
          default:
            break;
        }

        if (!checkNextFilter
         && !isEqual(activeFilters[config[i].ID].FilterResponse,
           appliedFilters[config[i].ID].FilterResponse)) {
          return true;
        }
      } else if (!activeFilters[filter.ID].Disabled || !appliedFilters[filter.ID].Disabled) {
        return true;
      }
    } else if (activeFilters[filter.ID] || appliedFilters[filter.ID]) {
      return true;
    }
  }
  return false;
};

export const isClearable = (
  config: Array<ReportResponseModel.IFilterConfig>, activeFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>,
) => {
  if (config && !isEmpty(config)) {
    for (let i = 0; i < config.length; i += 1) {
      if (config[i].Type !== FilterType.PRE_APPLIED && activeFilters[config[i].ID] && !activeFilters[config[i].ID].Disabled) {
        switch (config[i].Type) {
          case FilterType.UserMultiSelect: {
            if (activeFilters[config[i].ID].OptionsMeta.Count) {
              const activeValue = activeFilters[config[i].ID].FilterResponse;
              if (activeValue) {
                switch (activeValue.Type) {
                  case UserFilterPayloadType.In:
                    if (activeValue.UserIds.length !== activeFilters[config[i].ID].OptionsMeta.Count) {
                      return true;
                    }
                    break;
                  case UserFilterPayloadType.NotIn:
                    if (activeValue.UserIds.length) {
                      return true;
                    }
                    break;
                  default:
                    break;
                }
              } else {
                return true;
              }
            }
            break;
          }
          case FilterType.GroupMultiSelect:
          case FilterType.LSQMetadataMultiSelect:
          case FilterType.CustomDefinedMultiSelect: {
            if (activeFilters[config[i].ID].FilterResponse && activeFilters[config[i].ID].FilterOptions) {
              if (activeFilters[config[i].ID].FilterResponse.length
                !== activeFilters[config[i].ID].FilterOptions.length) {
                return true;
              }
            } else {
              return true;
            }
            break;
          }

          case FilterType.LSQMetadataSingleSelect:
          case FilterType.CustomDefinedSingleSelect: {
            if (activeFilters[config[i].ID].FilterResponse && activeFilters[config[i].ID].FilterOptions
              && !isEmpty(activeFilters[config[i].ID].FilterOptions)) {
              if (activeFilters[config[i].ID].FilterResponse
                !== activeFilters[config[i].ID].FilterOptions[0].value) {
                return true;
              }
            } else {
              return true;
            }
            break;
          }

          case FilterType.DateRange: {
            if (activeFilters[config[i].ID].FilterResponse) {
              const defaultDateRange: FilterModel.IDateRangePayload = dateRangeProcessor(null);
              if (!isEqualDateRangePayload(defaultDateRange,
                activeFilters[config[i].ID].FilterResponse)) {
                return true;
              }
            } else {
              return true;
            }
            break;
          }

          default:
            break;
        }
      }
    }
  }
  return false;
};

export const isUserFilterValueChanged = (
  newValue: FilterModel.IUserFilterPayload, previousValue: FilterModel.IUserFilterPayload, count: number,
) => {
  if (newValue && previousValue) {
    switch (newValue.Type) {
      case UserFilterPayloadType.All:
        switch (previousValue.Type) {
          case UserFilterPayloadType.In:
            if (previousValue.UserIds.length !== count) {
              return true;
            }
            break;
          case UserFilterPayloadType.NotIn:
            if (previousValue.UserIds.length) {
              return true;
            }
            break;
          default:
            break;
        }
        break;
      case UserFilterPayloadType.In:
        switch (previousValue.Type) {
          case UserFilterPayloadType.All:
            if (newValue.UserIds.length !== count) {
              return true;
            }
            break;
          case UserFilterPayloadType.In: {
            if (newValue.UserIds.length !== previousValue.UserIds.length) {
              return true;
            }
            const excIds = newValue.UserIds.filter((item: number) => !previousValue.UserIds.includes(item));
            if (excIds.length) {
              return true;
            }
            break;
          }
          case UserFilterPayloadType.NotIn: {
            if (newValue.UserIds.length && previousValue.UserIds.length) {
              if (newValue.UserIds.length === count) {
                return true;
              }
              if (previousValue.UserIds.length === count) {
                return true;
              }
              const excIds = previousValue.UserIds.filter((item: number) => newValue.UserIds.includes(item));
              if (excIds.length) {
                return true;
              }
              if (count - previousValue.UserIds.length !== newValue.UserIds.length) {
                return true;
              }
            } else if (newValue.UserIds.length && !previousValue.UserIds.length) {
              if (newValue.UserIds.length !== count) {
                return true;
              }
            } else if (!newValue.UserIds.length && previousValue.UserIds.length) {
              if (previousValue.UserIds.length !== count) {
                return true;
              }
            } else {
              return true;
            }
            break;
          }
          default:
            break;
        }
        break;
      case UserFilterPayloadType.NotIn: {
        switch (previousValue.Type) {
          case UserFilterPayloadType.All:
            if (newValue.UserIds.length) {
              return true;
            }
            break;
          case UserFilterPayloadType.In: {
            if (newValue.UserIds.length && previousValue.UserIds.length) {
              if (newValue.UserIds.length === count) {
                return true;
              }
              if (previousValue.UserIds.length === count) {
                return true;
              }
              const excIds = newValue.UserIds.filter((item: number) => previousValue.UserIds.includes(item));
              if (excIds.length) {
                return true;
              }
              if (count - newValue.UserIds.length !== previousValue.UserIds.length) {
                return true;
              }
            } else if (newValue.UserIds.length && !previousValue.UserIds.length) {
              if (newValue.UserIds.length !== count) {
                return true;
              }
            } else if (!newValue.UserIds.length && previousValue.UserIds.length) {
              if (previousValue.UserIds.length !== count) {
                return true;
              }
            } else {
              return true;
            }
            break;
          }
          case UserFilterPayloadType.NotIn: {
            if (newValue.UserIds.length !== previousValue.UserIds.length) {
              return true;
            }
            const excIds = newValue.UserIds.filter((item: number) => !previousValue.UserIds.includes(item));
            if (excIds.length) {
              return true;
            }
            break;
          }
          default:
            break;
        }
        break;
      }
      default:
        break;
    }
  } else if (newValue || previousValue) {
    return true;
  }
  return false;
};
