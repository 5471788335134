import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'rc-tooltip';
import {
  getReportViewList, createAndGetReportView, DeleteReportView, getReportViewInfo, shareAndUnshareReportView, shareOrUnshareReportViewComplete, deleteReportViewCompletion,
} from 'redux/report-view/report-view.actions';
import BarLoader from 'components/common/loader/BarLoader/bar-loader.component';
import { IReportView } from 'types/report-view.type';
import NewViewIcon from 'components/common/svg/NewView/newViewIcon.svg';
import {
  CreateNewReportView, ReportViewButtons, ReportViewsActionTypes, DisableReportViewOnDrilldownInfo, MaskedMessage, TooltipPlacement, TooltipDelayTime, ReportViewActivityType, ReportViewTypes, ReportViewURLTypes,
} from 'core/constants/report';
import { PersonalisationTypes } from 'core/constants/personalisation';
import { deleteGivenQueryStringsFromUrl, getPerosnalisationDataForReportView, setReportViewDataInURL } from 'core/utils/report.util';
import ReportViewListItem from './report-view-item.component';
import StyledContainer from './style/report-view.style';
import { PendoClassNames } from '../common/constants';
import CreateReportView from './create-report-view.component';
import { authSelector, reportSelector, reportViewSelector } from './report-view.selector';

const ReportViewComponent = () => {
  const dispatch = useDispatch();
  const reportViewListStore = useSelector(reportViewSelector);
  const {
    reportViewList, isCreating, actionId, isSharingOrUnsharing, isDeleteLoading, selectedReportViewItem,
  } = reportViewListStore;
  const { isReportListLoading, reportViewItems } = reportViewList;
  const report = useSelector(reportSelector);
  const { isV1Report, isMarvin } = useSelector(authSelector);
  const auth = useSelector(authSelector);
  const { userAttributes } = auth;
  const {
    reportId, filterConfig, activeFilters, activeDimensions, sort, reportConfig,
  } = report;
  const maskedFilter = filterConfig.find((filter) => filter.IsMasked === true);
  const hasMaskedFilter = maskedFilter && maskedFilter.IsMasked;
  const isDrilldown = !!reportConfig && reportConfig.AllowUserDrillDown
  && !!report.prevAppliedFilters && !!report.prevAppliedFilters.length;
  const [isCreateReportView, setIsCreateReportView] = useState<boolean>(false);
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState<boolean>(false);
  const [stateLabel, setStateLabel] = useState<string>('');
  const [isValidLabel, setIsValidLabel] = useState<boolean>(true);
  const [actionIndex, setActionIndex] = useState<number>(-1);
  const [actionSuccess, setActionSuccess] = useState<boolean>(false);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const [action, setAction] = useState<ReportViewsActionTypes>(null);
  const [isActionLoading, setIsActionLoading] = useState<boolean>(false);
  const [isMyViewsSelected, setIsMyViewsSelected] = useState<boolean>(false);

  const isSelectedIdExist = reportViewItems.some((item:IReportView) => item.PersonalisationId === selectedReportViewItem);
  const saveButtonRef = useRef(null);
  const cancelButtonRef = useRef(null);

  const myViews : IReportView[] = reportViewItems.filter((item) => item.ReportViewType === ReportViewTypes.Custom);

  useEffect(() => {
    dispatch(getReportViewList(reportId || ''));
  }, [reportId]);

  // In order to show the delete success message on top of a report view button
  useEffect(() => {
    if (!isDeleteLoading) {
      setIsActionLoading(false);
      setActionSuccess(true);
      setTimeout(() => {
        if (action === ReportViewsActionTypes.Delete) {
          dispatch(deleteReportViewCompletion(actionId));
          if (actionIndex === selectedIndex) {
            setSelectedIndex(-1);
          }
        }
        setActionIndex(-1);
        setActionSuccess(false);
        setAction(null);
      }, 1500);
    }
  }, [isDeleteLoading]);

  // In order to show the share or stop share success message on top of a report view button
  useEffect(() => {
    const actionItem = reportViewItems.find((item) => item.PersonalisationId === actionId);
    if (!isSharingOrUnsharing) {
      setActionSuccess(true);
      setIsActionLoading(false);
      setTimeout(() => {
        if (action === ReportViewsActionTypes.StopShare && actionItem?.ReportViewType === ReportViewTypes.Shared) {
          dispatch(shareOrUnshareReportViewComplete(actionId));
          if (actionIndex === selectedIndex) {
            setSelectedIndex(-1);
            deleteGivenQueryStringsFromUrl([ReportViewURLTypes.rvId, ReportViewURLTypes.rvType, ReportViewURLTypes.rvu]);
          }
        }
        setActionIndex(-1);
        setActionSuccess(false);
        setAction(null);
      }, 1500);
    }
  }, [isSharingOrUnsharing]);

  // show the create success Animation
  useEffect(() => {
    const DisplayName = (reportViewItems && reportViewItems[reportViewItems.length - 1]?.DisplayName) || '';
    if (stateLabel && stateLabel === DisplayName) {
      setSelectedIndex(reportViewItems.length - 1);
      setTimeout(() => {
        setStateLabel('');
      }, 1500);
    }
  }, [reportViewItems]);

  // Disable cancle and save button
  useEffect(() => {
    if (!isCreating) {
      setIsCreateReportView(false);
      setIsSaveButtonClicked(false);
    }
  }, [isCreating]);

  // Disable creation while having same name
  useEffect(() => {
    const value = stateLabel ? !(reportViewItems && reportViewItems.some((item) => item.DisplayName === stateLabel.trim())) && true : false;
    setIsValidLabel(value);
  }, [stateLabel]);

  const saveNewReportViewButtonHandler = () => {
    setIsCreateReportView(true);
    setIsSaveButtonClicked(false);
  };

  const handleSetStateLabel = (name: string) => {
    setStateLabel(name);
  };

  const handleCreate = (name: string) => {
    setIsSaveButtonClicked(true);
    saveButtonRef.current.disabled = true;
    cancelButtonRef.current.disabled = true;
    setIsCreateButtonDisabled(true);
    dispatch(createAndGetReportView({
      ReportId: reportId,
      DisplayName: name.trim(),
      PersonalisationType: PersonalisationTypes.ReportViews,
      PersonalisationObject: getPerosnalisationDataForReportView(
        filterConfig,
        activeFilters,
        activeDimensions,
        sort,
      ),
    },
    userAttributes?.Id));
  };

  const handleViewAction = (index: number, Id:string, callFrom: ReportViewsActionTypes, viewType: ReportViewTypes, isShared: boolean) => {
    setAction(callFrom);
    setActionIndex(index);
    setIsActionLoading(true);
    const newTimeoutId = setTimeout(() => {
      switch (callFrom) {
        case ReportViewsActionTypes.Delete: {
          dispatch(DeleteReportView({
            personalisationId: Id,
            reportViewType: viewType,
            isShared,
          }));
          if (index === (reportViewItems.length - 1)) {
            setIsCreateButtonDisabled(false);
          }
          if (index === selectedIndex) {
            deleteGivenQueryStringsFromUrl([ReportViewURLTypes.rvId, ReportViewURLTypes.rvType, ReportViewURLTypes.rvu]);
          }
          break;
        }
        case ReportViewsActionTypes.Share: {
          dispatch(shareAndUnshareReportView({
            PersonalisationId: Id,
            Activity: ReportViewActivityType.Shared,
            ReportViewType: viewType,
            IsShared: isShared,
          }));
          break;
        }
        case ReportViewsActionTypes.StopShare: {
          dispatch(shareAndUnshareReportView({
            PersonalisationId: Id,
            Activity: ReportViewActivityType.Unshared,
            ReportViewType: viewType,
            IsShared: isShared,
          }));
          break;
        }
        default: {
          break;
        }
      }
      setTimeoutId(null);
    }, 5000);
    setTimeoutId(newTimeoutId);
  };

  const undoAction = () => {
    setActionIndex(-1);
    setIsActionLoading(false);
    clearTimeout(timeoutId);
    setTimeoutId(null);
    setAction(null);
  };

  const onSelectReportView = (PersonalisationId: string, currentIndex:number, ViewType: ReportViewTypes) => {
    dispatch(getReportViewInfo({ personalisationId: PersonalisationId, context: 'reportSelect', ReportViewType: ViewType }));
    setIsCreateButtonDisabled(true);
    setSelectedIndex(currentIndex);
    // On selecting new report we have to append reportViewId, reportViewType and UserId to URL

    window.history.pushState(null, null, setReportViewDataInURL(PersonalisationId, ViewType, userAttributes?.Id));
  };

  const disableReportView = ((isActionLoading || actionSuccess)) && ((action === ReportViewsActionTypes.Delete) || (action === ReportViewsActionTypes.Share) || (action === ReportViewsActionTypes.StopShare));

  const saveNewReportViewButton = () => {
    const btn = (
      <button
        className={`se-create-state-btn ${isDrilldown || isSelectedIdExist ? 'se-cursor-not-allowed' : ''} ${PendoClassNames.NewReportViewButton} ${hasMaskedFilter && 'disabled'}`}
        type="button"
        onClick={(!isDrilldown && !hasMaskedFilter) && saveNewReportViewButtonHandler}
        disabled={isCreateButtonDisabled || disableReportView || isSelectedIdExist}
      >
        <span className="se-bookmark-icon se-icon-cn">
          <NewViewIcon
            width={20}
            height={20}
          />
        </span>
        <span>
          {CreateNewReportView}
        </span>
      </button>
    );
    if (isDrilldown) {
      return (
        <Tooltip
          placement={TooltipPlacement.Bottom}
          overlay={(
            <span>
              {DisableReportViewOnDrilldownInfo.ReportViewNewButton}
            </span>
          )}
          mouseEnterDelay={TooltipDelayTime}
        >
          {btn}
        </Tooltip>
      );
    }

    if (hasMaskedFilter) {
      return (
        <Tooltip
          placement={TooltipPlacement.Bottom}
          overlay={(
            <span>
              {MaskedMessage.CreateReportView}
            </span>
          )}
          mouseEnterDelay={TooltipDelayTime}
          overlayClassName="se-restricted-field"
        >
          {btn}
        </Tooltip>
      );
    }

    return btn;
  };
  return (
    <StyledContainer>
      <div className="se-report-report-views">
        <div className="se-create-state-desc-divider" />
        {isReportListLoading ? (
          <BarLoader
            style={{
              position: 'absolute',
            }}
            content="Loading..."
          />
        ) : null}
        <div className="se-report-section">
          <button
            className={`se-all-views-section ${!isMyViewsSelected && 'open'} `}
            type="button"
            onClick={() => { setIsMyViewsSelected(false); }}
          >
            All Views
          </button>
          <button
            className={`se-My-views-section ${isMyViewsSelected && 'open'}`}
            type="button"
            onClick={() => { setIsMyViewsSelected(true); }}
          >
            My Views
          </button>
        </div>
        {(reportViewItems && reportViewItems.length !== 0) && !(isMyViewsSelected && myViews.length === 0) && (
        <div className="se-report-view-list se-custom-scrollbar">
          { isMyViewsSelected && myViews && myViews.map((reportViewListItem: IReportView) => {
            const originalIndex = reportViewItems.findIndex((item) => item.PersonalisationId === reportViewListItem.PersonalisationId);
            return (
              <ReportViewListItem
                key={reportViewListItem.PersonalisationId}
                index={originalIndex}
                reportViewListItem={reportViewListItem}
                actionSuccess={originalIndex === actionIndex && actionSuccess}
                actionIndex={actionIndex}
                stateLabel={stateLabel}
                isValidLabel={isValidLabel}
                disableReportView={disableReportView}
                selectedIndex={selectedIndex}
                isActionProcessing={originalIndex === actionIndex && (action === ReportViewsActionTypes.Delete || action === ReportViewsActionTypes.Share || action === ReportViewsActionTypes.StopShare) && !actionSuccess}
                handleAction={handleViewAction}
                undoAction={undoAction}
                action={action}
                onSelectReportView={onSelectReportView}
                ViewType={reportViewListItem.ReportViewType}
                sharedBy={reportViewListItem.SharedBy}
                isShared={reportViewListItem.IsShared}
              />
            );
          })}
          {!isMyViewsSelected && reportViewItems && reportViewItems.map((reportViewListItem: IReportView, index: number) => (
            <ReportViewListItem
              key={reportViewListItem.PersonalisationId}
              index={index}
              reportViewListItem={reportViewListItem}
              actionSuccess={index === actionIndex && actionSuccess}
              actionIndex={actionIndex}
              stateLabel={stateLabel}
              isValidLabel={isValidLabel}
              disableReportView={disableReportView}
              selectedIndex={selectedIndex}
              isActionProcessing={index === actionIndex && (action === ReportViewsActionTypes.Delete || action === ReportViewsActionTypes.Share || action === ReportViewsActionTypes.StopShare) && !actionSuccess}
              handleAction={handleViewAction}
              undoAction={undoAction}
              action={action}
              onSelectReportView={onSelectReportView}
              ViewType={reportViewListItem.ReportViewType}
              sharedBy={reportViewListItem.SharedBy}
              isShared={reportViewListItem.IsShared}
            />
          )) }
        </div>
        )}
        <div className={`se-save-new-state ${isV1Report || isMarvin ? 'externalAppIntegrationStlye' : ''} ${(isMyViewsSelected && myViews.length === 0) || reportViewItems.length === 0 ? 'placed-top' : 'placed-bottom'} ${isCreateReportView ? 'create-state' : ''}`}>
          {
            isCreateReportView && (
              <div className="se-create-new-state-cn">
                <CreateReportView
                  handleCreate={handleCreate}
                  isDisabled={isCreating}
                  isActionCompleted={isCreateReportView && isCreating}
                  stateLabel={stateLabel}
                  handleSetStateLabel={handleSetStateLabel}
                  isValidLabel={isValidLabel}
                  isCreating={isCreating}
                />
              </div>
            )
          }
          <div className="se-save-cancel-btn-cn">
            {
              isCreateReportView ? (
                <>
                  <button
                    className={`se-cancel-btn ${isSaveButtonClicked ? 'not-allowed' : ''}`}
                    type="button"
                    onClick={() => {
                      setIsCreateReportView(false);
                    }}
                    ref={cancelButtonRef}
                  >
                    {ReportViewButtons.Cancel}
                  </button>
                  <button
                    className="se-save-btn"
                    type="button"
                    onClick={() => handleCreate(stateLabel)}
                    disabled={!isValidLabel}
                    ref={saveButtonRef}
                  >
                    {ReportViewButtons.Save}
                  </button>
                </>
              ) : saveNewReportViewButton()
            }
          </div>
        </div>
      </div>
    </StyledContainer>
  );
};

export default ReportViewComponent;
