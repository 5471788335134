import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const CustomIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 20 20"
  >
    <g id="post_add_black_24dp" transform="translate(-0.369 -0.369)">
      <g id="Group_20821" data-name="Group 20821">
        <rect id="Rectangle_7704" data-name="Rectangle 7704" width="20" height="20" transform="translate(0.369 0.369)" fill="none" />
      </g>
      <g id="Group_20823" data-name="Group 20823" transform="translate(2.592 1.728)">
        <g id="Group_20822" data-name="Group 20822">
          <path id="Path_9722" data-name="Path 9722" d="M15.1,17.287H4.728V6.728h6.048V5H4.728A1.733,1.733,0,0,0,3,6.728V17.1a1.733,1.733,0,0,0,1.728,1.728H15.1A1.733,1.733,0,0,0,16.825,17.1V11.048H15.1Z" transform="translate(-3 -2.408)" fill={fill} />
          <path id="Path_9723" data-name="Path 9723" d="M18.32,2H16.592V4.592H14c.009.009,0,1.728,0,1.728h2.592V8.9c.009.009,1.728,0,1.728,0V6.32h2.592V4.592H18.32Z" transform="translate(-4.495 -2)" fill={fill} />
          <rect id="Rectangle_7705" data-name="Rectangle 7705" width="7" height="2" transform="translate(3.477 5.641)" fill={fill} />
          <path id="Path_9724" data-name="Path 9724" d="M7,12v2h6.912V12H7Z" transform="translate(-3.523 -3.359)" fill={fill} />
          <rect id="Rectangle_7706" data-name="Rectangle 7706" width="7" height="2" transform="translate(3.477 11.641)" fill={fill} />
        </g>
      </g>
    </g>
  </svg>
);

CustomIcon.defaultProps = {
  fill: '#084e96',
};

export default CustomIcon;
