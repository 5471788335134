import {
  ColumnActionTypes,
  ColumnModes,
  DimensionMode,
  DimensionNoValue,
  DimensionStatus,
  DrilldownChangesTypes,
  DrilldownChangeTypes,
  EncryptedText,
  PivotColGrpHeaderSeparator,
  ZeroAnchorStatus,
} from 'core/constants/report';
import {
  FilterModel, GridModel, ObjModel, ReportReduxModel, ReportResponseModel,
} from 'core/models';
import { IColumnActionDrilldownChangeMeta } from 'core/models/report-response';
import { ObjGeneric } from 'core/models/obj';
import { isEmpty, isEqual } from 'lodash';
import { isMeasureAndApplied } from './report.util';

export const getDrilldownValue = (
  prev: number, isParent: boolean, prevAppliedFilters: Array<ObjModel.ObjGeneric<FilterModel.IFilterResponse>>,
  gridColumns: Array<GridModel.IColumnConfig>,
): string => {
  if (!isParent && prevAppliedFilters[prev] && gridColumns && gridColumns.length) {
    const drilldownIndex = gridColumns.findIndex(
      (c) => c.action && c.action.Type === ColumnActionTypes.Drilldown,
    );
    if (drilldownIndex > -1) {
      const column = gridColumns[drilldownIndex];
      const metadata = column.action.Meta as ReportResponseModel.IColumnActionDrilldownMeta;
      if (metadata && metadata.DrilldownChanges && metadata.DrilldownChanges.Changes
      ) {
        if (metadata.DrilldownChanges.Type === DrilldownChangesTypes.FilterOnly) {
          for (let i = 0; i < metadata.DrilldownChanges.Changes.length; i += 1) {
            const change = metadata.DrilldownChanges.Changes[i];
            if (change.Type === DrilldownChangeTypes.Filter) {
              const changeMeta = change.ChangeMeta as IColumnActionDrilldownChangeMeta;
              if (changeMeta) {
                const filterToChange = changeMeta.FilterID;
                const prevValue = prevAppliedFilters[prev][filterToChange];
                if (prevValue && prevValue.Hidden && typeof prevValue.Hidden === 'string'
                  && prevValue.Hidden.length) {
                  return prevValue.Hidden;
                }
              }
            }
          }
        }
      }
    }
  }
  return 'Back';
};

export const getColumnsProperties = (columns: Array<ReportResponseModel.IColumn>, isAutodrilldown?: boolean, allowAutodrilldown?: boolean)
  : Array<GridModel.IColumnConfig> => {
  const colsDefs = [] as Array<GridModel.IColumnConfig>;
  const refererCols = {} as ObjGeneric<string>; // stores currentColumn to ReferredColumn link
  const dimensionMap = prepareDimensionList(columns);
  const hasPivot = hasPivotColumn(columns);
  columns.forEach((column) => {
    const { Props, BuilderConfig } = column;
    let { Name } = column;
    if (BuilderConfig && BuilderConfig.IsDynamicField) {
      Name = `${BuilderConfig.Entity} | ${BuilderConfig.DisplayName}`;
    }
    // if a column is hidded or if it contains a ReferTo to property then set hide to true
    let hide = !!(Props && Props.Mode === ColumnModes.Hidden) || (Props && !isEmpty(Props.ReferTo)) || !isMeasureAndApplied(column);
    let rowGroup = false;
    let suppressMovable = false;
    let isPivotColumn = false;
    let isValueColumn = false;
    let parentDataColumn = '';
    let binningType = '';
    if (!isAutodrilldown) {
    // is dimension
      if (Props && Props.Dimension) {
        if (Props.Mode !== ColumnModes.Hidden) {
          hide = true;
          // if dimension is applied
          if (Props.Dimension.Applied !== DimensionStatus.NotApplied) {
            isPivotColumn = column.Props.Dimension.DimensionMode !== DimensionMode.RowGroup;
            rowGroup = column.Props.Dimension.DimensionMode !== DimensionMode.ColumnGroup;
          }
        }
        suppressMovable = true;
        if (Props.ReferTo) {
          refererCols[Props.ReferTo] = Name;
        }
        if (Props.Dimension.DimensionProp) {
          binningType = Props.Dimension.DimensionProp.Props.BinningType;
        }
      } else if (hasPivot && !dimensionMap[column.Name] && !isPivotColumn && !rowGroup) {
        isValueColumn = true;
      }
      if (Name in refererCols) {
        parentDataColumn = refererCols[Name];
        const index = columns.findIndex((item) => (item.Props && item.Props.Dimension
        && (item.Props.ReferTo === Name)));
        if (index > -1) {
          suppressMovable = true;
          hide = true;
          const referredDimensionProps = columns[index].Props;
          if (referredDimensionProps && referredDimensionProps.Dimension) {
            if (referredDimensionProps.Dimension.Applied !== DimensionStatus.NotApplied) {
              const dimension = referredDimensionProps.Dimension;
              isPivotColumn = dimension.DimensionMode !== DimensionMode.RowGroup;
              rowGroup = dimension.DimensionMode !== DimensionMode.ColumnGroup;
            }
          }
        }
      }
    }
    if (column.Props.ParentDimension && !hide) {
      const relatedDimension = columns.find((item) => (item.Props && item.Props.Dimension && (item.Name === column.Props.ParentDimension)));

      if (relatedDimension) {
        if (relatedDimension.Props.Dimension.Applied === DimensionStatus.NotApplied) {
          hide = true;
          isValueColumn = false;
        }
      }
    }
    colsDefs.push({
      // Alias for dynamic field, name for predefined field
      colId: BuilderConfig && BuilderConfig.IsDynamicField
        ? BuilderConfig.Alias
        : Name,
      field: isPivotColumn && BuilderConfig && BuilderConfig.IsDynamicField
        ? BuilderConfig.Alias
        : Name,
      hide,
      rowGroup,
      sortable: isValueColumn ? false : Props && Props.Mode === ColumnModes.Sortable,
      converter: Props && Props.Converter,
      action: Props && Props.Action,
      resizable: true,
      initialWidth: 200,
      unSortIcon: isValueColumn ? false : Props && Props.Mode === ColumnModes.Sortable,
      headerTooltip: Name,
      isMeasure: Props && Props.IsMeasure,
      formatter: Props && Props.Formatter,
      suppressMovable,
      pivot: isPivotColumn,
      aggFunc: isValueColumn ? 'first' : null,
      enableValue: isValueColumn,
      keyCreator: (param) => {
        const colDef: GridModel.IColumnConfig = param.colDef;
        // in case of dynamic field, Alias is used as key.
        if (colDef.builderConfig && colDef.builderConfig.IsDynamicField) {
          if (!colDef.pivot) {
            return param.data[colDef.builderConfig.Alias];
          }
        }
        // for referred column, using the actual column data as key instead of referred value
        // Ex: for OwnerId and Owner Name, this returns as OwnerId as key which gets rendered in UI. This again gets fixed by cellRenderer in autoGroupColumnDef
        if (rowGroup && colDef.parentDataColumn && colDef.parentDataColumn.length) {
          return param.data[colDef.parentDataColumn];
        }
        if (colDef.pivot && (colDef.parentDataColumn || (colDef.isMasked && allowAutodrilldown))) {
          const key = colDef.parentDataColumn ? param.data[colDef.parentDataColumn] : param.value;
          const val = colDef.isMasked ? EncryptedText : param.value;
          return `${key}${PivotColGrpHeaderSeparator}${val}`;
        }
        return param.value;
      },
      parentDataColumn,
      builderConfig: BuilderConfig || null,
      autodrilldown: Props.AllowDrillDown,
      isMasked: Props.IsMasked,
      binningType,
    });
  });
  return colsDefs;
};

export const getRowData = (
  columns: Array<ReportResponseModel.IColumn>, reportData: Array<ObjModel.Obj>, activeDimensions: Array<ReportReduxModel.IDimension>,
): Array<ObjModel.Obj> => {
  const rowData: Array<ObjModel.Obj> = [];
  for (let i = 0; i < reportData.length; i += 1) {
    const data = reportData[i];
    rowData.push({
      Fields: columns.map((c) => {
        let idx = -1;
        let name = c.Name;
        // dynamic field exists in columns only if it is an active dimension so no need to check in activeDimension list.
        if (c.BuilderConfig && c.BuilderConfig.IsDynamicField) {
          name = c.BuilderConfig.Alias;
        }
        idx = activeDimensions.findIndex((dim) => (dim.Applied !== DimensionStatus.NotApplied
        && ((dim.BuilderConfig && dim.BuilderConfig.IsDynamicField && name === dim.BuilderConfig.Alias)
          || (name === (dim.ReferTo || dim.Name)))
        && (!data[name])));
        return ({
          Name: name,
          Value: idx === -1 ? data[name] : DimensionNoValue,
        });
      }),
    });
  }
  return rowData;
};

const prepareDimensionList = (columns: Array<ReportResponseModel.IColumn>): { [key: string]: boolean; } => {
  const dimensionMap: { [key: string]: boolean; } = {};
  columns.forEach((column) => {
    const { Props, BuilderConfig } = column;
    // is dimension
    if (Props && Props.Dimension) {
      if (BuilderConfig && BuilderConfig.IsDynamicField) {
        dimensionMap[BuilderConfig.Alias] = true;
      } else {
        dimensionMap[column.Name] = true;
      }
      if (column.Props?.ReferTo && column.Props?.ReferTo.length) {
        dimensionMap[column.Props?.ReferTo] = true;
      }
    }
  });
  return dimensionMap;
};

export const hasPivotColumn = (columns: Array<ReportResponseModel.IColumn>): boolean => columns.some((column) => {
  const { Props } = column;
  // is dimension
  if (Props && Props.Dimension && Props.Dimension.Applied !== DimensionStatus.NotApplied) {
    if (Props.Dimension.DimensionMode === DimensionMode.ColumnGroup
      || Props.Dimension.DimensionMode === DimensionMode.Both) {
      return true;
    }
  }
  return false;
});

export const areVisualisationSettingsPristine = (
  appliedDimensions: Array<ReportReduxModel.IDimension>, activeDimensions: Array<ReportReduxModel.IDimension>,
  columns: ReportResponseModel.IColumn[], measures: ReportResponseModel.IColumn[],
) => areDimensionsPristine(appliedDimensions, activeDimensions) && areMeasuresPristine(columns, measures);

export const areMeasuresPristine = (columns: ReportResponseModel.IColumn[], measures: ReportResponseModel.IColumn[]) => {
  if (isEmpty(columns) || isEmpty(measures)) return true;
  const activeMeasures = columns.filter((c) => isMeasureAndApplied(c));
  const appliedMeasures = measures.filter((m) => isMeasureAndApplied((m)));
  return isEqual(activeMeasures, appliedMeasures);
};

export const areDimensionsPristine = (
  appliedDimensions: Array<ReportReduxModel.IDimension>, activeDimensions: Array<ReportReduxModel.IDimension>,
) => {
  if (appliedDimensions && !isEmpty(appliedDimensions) && activeDimensions && !isEmpty(activeDimensions)) {
    const dimensionInActiveDimensions = activeDimensions.filter((dimension) => dimension.Applied !== DimensionStatus.NotApplied);
    const dimensionInAppliedDimensions = appliedDimensions.filter((dimension) => dimension.Applied !== DimensionStatus.NotApplied);
    if (dimensionInActiveDimensions.length !== dimensionInAppliedDimensions.length) {
      return false;
    }
    if (dimensionInActiveDimensions.length) {
      for (let i = 0; i < dimensionInActiveDimensions.length; i += 1) {
        const index = dimensionInAppliedDimensions.findIndex((dimension) => {
          if (dimensionInActiveDimensions[i].DimensionMode === dimension.DimensionMode) {
            if (dimensionInActiveDimensions[i].BuilderConfig && dimensionInActiveDimensions[i].BuilderConfig.IsDynamicField
            && dimension.BuilderConfig && dimensionInActiveDimensions[i].BuilderConfig.IsDynamicField) {
              if (dimensionInActiveDimensions[i].DimensionProp && dimension.DimensionProp) {
                return dimensionInActiveDimensions[i].BuilderConfig.Alias === dimension.BuilderConfig.Alias
                       && dimensionInActiveDimensions[i].DimensionProp.Props.BinningType === dimension.DimensionProp.Props.BinningType;
              }
              return dimensionInActiveDimensions[i].BuilderConfig.Alias === dimension.BuilderConfig.Alias;
            }
            if (!(dimensionInActiveDimensions[i].BuilderConfig && dimensionInActiveDimensions[i].BuilderConfig.IsDynamicField)
            && !(dimension.BuilderConfig && dimension.BuilderConfig.IsDynamicField)) {
              if (dimensionInActiveDimensions[i].DimensionProp && dimension.DimensionProp) {
                return dimensionInActiveDimensions[i].Name === dimension.Name
                       && dimensionInActiveDimensions[i].DimensionProp.Props.BinningType === dimension.DimensionProp.Props.BinningType;
              }
              return dimensionInActiveDimensions[i].Name === dimension.Name;
            }
          }
          return false;
        });
        if (index === -1) {
          return false;
        }
        // check for grouping order of dimension
        if (index !== i) {
          return false;
        }
      }
    }
  } else if (appliedDimensions && !isEmpty(appliedDimensions)) {
    const index = appliedDimensions.findIndex((dimension) => dimension.Applied !== DimensionStatus.NotApplied);
    if (index !== -1) {
      return false;
    }
  } else if (activeDimensions && !isEmpty(activeDimensions)) {
    const index = activeDimensions.findIndex((dimension) => dimension.Applied !== DimensionStatus.NotApplied);
    if (index !== -1) {
      return false;
    }
  }
  return true;
};

export const applyZeroAnchorsSettings = (
  appliedDimensions: Array<ReportReduxModel.IDimension>, activeDimensions: Array<ReportReduxModel.IDimension>,
) => {
  if (appliedDimensions && !isEmpty(appliedDimensions) && activeDimensions && !isEmpty(activeDimensions)) {
    const dimensionInActiveDimensions = activeDimensions.filter((dimension) => (
      dimension.Applied !== DimensionStatus.NotApplied && dimension.ZeroAnchor === ZeroAnchorStatus.Applied
    ));
    const dimensionInAppliedDimensions = appliedDimensions.filter((dimension) => (
      dimension.Applied !== DimensionStatus.NotApplied && dimension.ZeroAnchor === ZeroAnchorStatus.Applied
    ));
    if (dimensionInActiveDimensions.length !== dimensionInAppliedDimensions.length) {
      return true;
    }
    for (let i = 0; i < dimensionInActiveDimensions.length; i += 1) {
      const index = dimensionInAppliedDimensions.findIndex((dimension) => {
        if (dimensionInActiveDimensions[i].BuilderConfig && dimensionInActiveDimensions[i].BuilderConfig.IsDynamicField
          && dimension.BuilderConfig && dimensionInActiveDimensions[i].BuilderConfig.IsDynamicField) {
          return dimensionInActiveDimensions[i].BuilderConfig.Alias === dimension.BuilderConfig.Alias;
        }
        if (!(dimensionInActiveDimensions[i].BuilderConfig && dimensionInActiveDimensions[i].BuilderConfig.IsDynamicField)
          && !(dimension.BuilderConfig && dimension.BuilderConfig.IsDynamicField)) {
          return dimensionInActiveDimensions[i].Name === dimension.Name;
        }
        return false;
      });
      if (index === -1) {
        return true;
      }
    }
  } else if (appliedDimensions && !isEmpty(appliedDimensions)) {
    const index = appliedDimensions.findIndex((dimension) => (
      dimension.Applied !== DimensionStatus.NotApplied && dimension.ZeroAnchor === ZeroAnchorStatus.Applied
    ));
    if (index !== -1) {
      return true;
    }
  } else if (activeDimensions && !isEmpty(activeDimensions)) {
    const index = activeDimensions.findIndex((dimension) => (
      dimension.Applied !== DimensionStatus.NotApplied && dimension.ZeroAnchor === ZeroAnchorStatus.Applied
    ));
    if (index !== -1) {
      return true;
    }
  }
  return false;
};

export const extractValFromStrWithSeparator = (str: string) => {
  if (typeof str === 'string' && str && str.indexOf(PivotColGrpHeaderSeparator) !== -1) {
    return str.split(PivotColGrpHeaderSeparator)[1] || '';
  }
  return str;
};

export const compareDrilldownFields = (
  drilldownFields: Array<ReportResponseModel.IColumn>, orderedFields: Array<ReportResponseModel.IColumn>,
) => {
  if (drilldownFields && !isEmpty(drilldownFields) && orderedFields && !isEmpty(orderedFields)) {
    const appliedDrilldownFields = drilldownFields.filter((dim) => dim.Props.Dimension.Applied === DimensionStatus.Applied);
    const responseFields = orderedFields.filter((dim) => dim.Props.Mode !== ColumnModes.Hidden);
    if (appliedDrilldownFields.length !== responseFields.length) {
      return false;
    }
    if (appliedDrilldownFields.length) {
      for (let i = 0; i < appliedDrilldownFields.length; i += 1) {
        // eslint-disable-next-line array-callback-return, consistent-return
        const index = responseFields.findIndex((dim) => {
          if (appliedDrilldownFields[i].BuilderConfig && appliedDrilldownFields[i].BuilderConfig.IsDynamicField) {
            return appliedDrilldownFields[i].BuilderConfig.Alias === dim.BuilderConfig.Alias;
          }
          if (!(appliedDrilldownFields[i].BuilderConfig && appliedDrilldownFields[i].BuilderConfig.IsDynamicField)) {
            return appliedDrilldownFields[i].Name === dim.Name;
          }
        });
        if (index === -1) {
          return false;
        }
        // check for grouping order of dimension
        if (index !== i) {
          return false;
        }
      }
      return true;
    }
    if (responseFields.length === 0) return true;
    return false;
  }
  return false;
};
