import { ReduxModel } from 'core/models';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Actions from 'redux/report/actions';

const mapStateToProps = (state: ReduxModel.IGlobalState) => ({
  reduxState: state,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reduxAction: {
    applyDimensions: () => {
      dispatch(Actions.applyDimensions());
    },
    autodrilldownReportDataLoadRequest: () => {
      dispatch(Actions.autodrilldownReportDataLoadRequest());
    },
  },
});

export default () => connect(mapStateToProps, mapDispatchToProps);
