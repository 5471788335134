import styled from 'styled-components';

const StyledContainer = styled.div`
flex: 1;
width: 100%;
height: 100%;
overflow: hidden;

.se-filter-builder-cn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .se-filters {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 16px 16px 16px 16px;
    box-sizing: border-box;
  }

  .se-filters, .se-disabled-filters {
    .se-filter {
      width: 100%;
      margin-bottom: 16px;
      box-sizing: border-box;

      :last-child {
        margin-bottom: 0;
      }

      .se-filter-label-cn {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
      }
  
      .se-filter-label {
        font-size: 13px;
        font-family: SemiBold;
        color: var(--paginationColor);
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      button.se-remove-dynamic-filter {
        padding: 0;
        border: 0;
        margin-right:13px;
        &:hover {
          svg {
            path:last-child {
              fill: #454F5B !important;
            }
          }
        }
      }
    }
  }

  .se-disabled-filters-cn {
    position: relative;
    margin: 16px;
    box-sizing: border-box;
    transition: height 4s ease-in-out;
    border: 1px solid #DFE3E8;
    border-left: 0;
    border-right: 0;
    max-height: 41px;
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;

    button.se-disabled-filters-toggler {
      width: 100%;
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 0;
      padding: 5px;
      font-family: SemiBold;
      font-size: 13px;
      color: #637381;
    }

    .se-disabled-filters-section-header {
      width: 100%;
      position: sticky;
      padding-bottom: 16px;
      top: 0;
      background: #fff;
    }

    .se-icon-cn {
      transform: rotate(180deg);
    }

    .se-disabled-filters-desc {
      padding: 0 5px;
      font-size: 11px;
      color: #919EAB;
    }

    .se-disabled-filters {
      padding: 0 5px;
    }
  }

  .se-disabled-filters-cn.expand {
    max-height: 260px;
    border-bottom: 0;

    .se-icon-cn {
      transform: none;
    }

  }

  .se-filter-builder-footer {
    padding: 7px 10px;
    display: flex;
    border-top: 1px solid var(--base-date-picker-border);

    &.externalAppIntegrationStlye{
      padding-bottom: 45px;
    }

    button {
      flex: 1;
      margin: 0 5px;
      padding: 8px;
      font-size: 12px;
      font-family: Regular;
      color: var(--headerColor);
      border-radius: 2px;
      font-family: SemiBold;
    }

    button.se-apply-filters  {
      border: 2px solid var(--collapsibleColor);
      color: #FFFFFF;
      background-color:#006FD6;

      :hover {
        background-color:#0064C0;
      }

      :active {
        background-color:#0064C0;
      }

      :focus {
        border: 2px solid rgba(17, 111, 214, 0.3);
        box-shadow: 0px 0px 1px #1360B5;
      }

      :disabled {
        color: #FFFFFF;
        background-color: #7EB6EA;
        cursor: not-allowed;
        border-width: 0px;
      }
    }

    button.se-clear-filters {
      color: var(--headerColor);
      background-color:#FFFFFF;
      border: 1px solid #C4CDD5;
      border-radius: 2px;

      :hover {
        background-color:#F9FAFB;
      }

      :active {
        background-color:#F9FAFB;
        border: 1px solid #1172D3;
      }

      :focus {
        border: 2px solid rgba(17, 111, 214, 0.3);
        box-shadow: 0px 0px 1px #1360B5;
      }

      :disabled {
        color: var(--headerColor);
        opacity: 0.6;
        cursor: not-allowed;
        border: 1px solid #C4CDD5;
      }
    }
  }



  .se-date-range-cn {
    position: unset;
  }
}
`;
export default StyledContainer;
