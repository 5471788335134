import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { ReportDimensionsSettingsTitles, FieldEntitiesType } from 'core/constants/report';
import {
  AdditionalDimensionEmptyState, SearchBarPlaceHolder, AdditionalDimensionNoResultState,
  NoDimensionDataMessages,
} from 'core/constants/visualizations';
import { FilterType, FilterMaxLimit, MaxLimitDesc } from 'core/constants/filter';
import { ReduxModel } from 'core/models';
import { IColumn } from 'core/models/report-response';
import {
  getFieldDisplayName, getFieldIdentifier, getTextTooltipContentForField,
  genericSearch, sortAdditionalFields,
} from 'core/utils/report.util';
import { addDynamicFilter } from 'redux/report/actions';
import { IAvailableFiltersProps } from 'components/feature/Report/ReportSidebar/AvailableFilters/available-filters.d';
import AvailableDimensions from 'components/feature/Report/ReportSidebar/common/AvailableDimensions/available-dimensions.component';
import DimensionItem from 'components/feature/Report/ReportSidebar/common/AvailableDimensions/DimensionItem/dimension-item.component';
import EmptyStateScreen from 'components/feature/Report/ReportSidebar/common/EmptyState/empty-state.component';
import NoResultStateScreen from 'components/feature/Report/ReportSidebar/common/NoResultState/no-result-state-component';
import SearchBar from 'components/feature/Report/ReportSidebar/common/SearchBar/search-bar-component';
import NoFiltersIcon from 'components/common/svg/NoFilters/noFilters.svg';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';
import FieldEntity from '../common/FieldEntity/field-entity.component';

const AvailableFilters = (props:IAvailableFiltersProps) => {
  const {
    reportBuilderPortal, expand, collapse, getIdOnAddNewFilter,
  } = props;

  const dispatch = useDispatch();
  const [report] = useSelector((state: ReduxModel.IGlobalState) => [state.report] as const);
  const { dynamicDimensions, filterConfig, appliedFilters } = report;
  const [query, setQuery] = useState<string>('');
  const [expandedEntity, setExpandedEntity] = useState<string[]>([]);

  useEffect(() => {
    if (!expand) {
      setQuery('');
      setExpandedEntity([]);
    }
  }, [expand]);

  const isFilterMaxLimit = ((filterConfig && filterConfig.filter((config) => (!(config.IsDynamic && config.IsRemoved)
  && config.Type !== FilterType.PRE_APPLIED && appliedFilters[config.ID] && !appliedFilters[config.ID].Disabled)).length) || 0)
  === FilterMaxLimit;

  const additionalFilters = !isEmpty(dynamicDimensions) ? dynamicDimensions.map((dim, index) => ({ ...dim, originalIndex: index }))
    . filter((dim) => !isEmpty(dim.BuilderConfig) && dim.BuilderConfig.IsFilterApplicable) : [];

  const hasFilters = !!additionalFilters.length;

  const entityList = [] as string[];
  if (!isEmpty(additionalFilters)) {
    Object.keys(FieldEntitiesType).forEach((e: string) => {
      if (additionalFilters.find((dim) => dim.BuilderConfig && (dim.BuilderConfig.Entity === e))) {
        entityList.push(e);
      }
    });
  }

  const nonAppliedAdditionalFilters = additionalFilters.filter((dim) => (!isEmpty(filterConfig)
    ? !(filterConfig.find((f) => !f.IsRemoved && dim.BuilderConfig.FilterId === f.ID))
    : true));

  const filteredNonAppliedAdditionalFilters = nonAppliedAdditionalFilters.filter((dim) => genericSearch(query, getFieldDisplayName(dim)));

  const hasFilteredNonAppliedAdditionalFilters = !!filteredNonAppliedAdditionalFilters.length;

  const entityDimList = [] as any;
  entityList.forEach((entity) => {
    const dimList = filteredNonAppliedAdditionalFilters.filter((dim) => !isEmpty(dim.BuilderConfig) && dim.BuilderConfig.Entity === entity);
    entityDimList.push([entity, sortAdditionalFields(dimList)]);
  });

  const handleExpandedEntity = (entity: string) => {
    if (expandedEntity.includes(entity)) {
      setExpandedEntity(
        query ? expandedEntity.filter((e) => e !== entity) : [],
      );
      return;
    }
    setExpandedEntity(
      query ? [...expandedEntity, entity] : [entity],
    );
  };

  const addFilter = (index: number) => {
    dispatch(addDynamicFilter(index));
    getIdOnAddNewFilter(
      dynamicDimensions[index].BuilderConfig.FilterId,
      dynamicDimensions[index].BuilderConfig.FilterType,
    );
  };

  return (
    <AvailableDimensions
      reportBuilderPortal={reportBuilderPortal}
      expanded={expand}
      title={ReportDimensionsSettingsTitles.AvailableFilters}
      collapse={collapse}
      search={hasFilters
        && (
        <SearchBar
          onChange={(q) => setQuery(q)}
          query={query}
          searchPlaceholder={SearchBarPlaceHolder.Filters}
          setFocus={() => setExpandedEntity(entityList)}
        />
        )}
      showcase={hasFilters
        ? (
          <>
            {
              hasFilteredNonAppliedAdditionalFilters
                ? entityDimList.map((entity: any) => {
                  const [entityKey, dimensions] = entity;
                  const nonAppliedFilterWithinEntity = nonAppliedAdditionalFilters.filter((f) => f.BuilderConfig.Entity === entityKey)
                    .length !== 0;

                  return (
                    <FieldEntity
                      key={entityKey}
                      entity={entityKey}
                      expand={expandedEntity.includes(entityKey)}
                      handleExpandedEntity={() => handleExpandedEntity(entityKey)}
                      showcase={(
                        <>
                          {
                            nonAppliedFilterWithinEntity
                              ? (
                                <>
                                  {
                                    query && !dimensions.length
                                      ? (
                                        <div
                                          className="se-no-dimension-data"
                                        >
                                          {NoDimensionDataMessages.NoSearches}
                                        </div>
                                      )
                                      : dimensions.map((dim: iColumnWithIndex) => (
                                        <DimensionItem
                                          key={getFieldIdentifier(dim)}
                                          name={getFieldDisplayName(dim)}
                                          nameTooltipContent={getTextTooltipContentForField(dim)}
                                          isMaxLimit={isFilterMaxLimit}
                                          maxLimitDesc={MaxLimitDesc.AppliedFilter.replace('{{Filter limit}}', FilterMaxLimit.toString())}
                                          add={() => addFilter(dim.originalIndex)}
                                          isMasked={dim.Props.IsMasked}
                                          className={PendoClassNames.AddFilter}
                                        />
                                      ))
                                  }
                                </>
                              )
                              : (
                                <div
                                  className="se-no-dimension-data"
                                >
                                  {NoDimensionDataMessages.AllFiltersAdded}
                                </div>
                              )
                        }
                        </>
                  )}
                    />
                  );
                })
                : (
                  <NoResultStateScreen
                    message={AdditionalDimensionNoResultState.Filters}
                    Icon={(
                      <NoFiltersIcon
                        width={36}
                        height={33}
                      />
                )}
                  />
                )
          }
          </>
        )
        : (
          <EmptyStateScreen
            message={AdditionalDimensionEmptyState.Filters}
          />
        )}
    />
  );
};

interface iColumnWithIndex extends IColumn {
  originalIndex: number;
}

export default AvailableFilters;
