import styled from 'styled-components';
import StyledDimensionsContainer from '../Dimensions/dimensions.style';

export default styled(StyledDimensionsContainer)`
  .se-info-icon {
    .icon-stroke {
      fill: #F38037;
    }
  }
`;
