import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { ErrorMessage } from 'core/constants/errors';
import { APIRequestStatus } from 'core/constants/redux';
import {
  AppliedFilter,
  DimensionMode, DimensionStatus, expandFiltersButtonLabel, ExpandListButtonContext, ToolbarMenuItemTypes,
} from 'core/constants/report';
import { ReportActionTypes } from 'core/constants/redux-action-types';
import { FilterMaxLimit, FilterType } from 'core/constants/filter';
import { ReportToolbarMenuOptions } from 'core/utils/report.util';
import { INavClick, IProps } from 'components/feature/Report/ReportSidebar/report-sidebar.d';
import StoreConnector from 'components/feature/Report/ReportSidebar/report-sidebar.redux';
import StyledContainer from 'components/feature/Report/ReportSidebar/report-sidebar.style';
import FilterBuilder from 'components/common/FilterBuilder/filter-builder.component';
import ErrorComponent from 'components/common/Error/error.component';
import Settings from 'components/feature/Report/ReportSidebar/Settings/settings.component';
import ZeroValues from 'components/feature/Report/ReportSidebar/ZeroValues/zero-values.component';
import { UserAttributes } from 'core/models/auth-response';
import UserRole from 'core/constants/auth';
import AvailableFilters from 'components/feature/Report/ReportSidebar/AvailableFilters/available-filters.component';
import SideBarNavItem from 'components/feature/Report/ReportSidebar/common/SideBarNavItems/sidebar-nav-items.component';
import ExpandListButton from 'components/common/ExpandListButton/expand-list-button.component';
import ReportViewComponent from './ReportView/report-view.component';

const showcaseItem = (props: IProps, reportBuilderPortal: HTMLDivElement, toolbarExpanded: boolean) => {
  const { reduxState, reduxAction } = props;
  const { auth, report } = reduxState;
  const { userAttributes } = auth;
  const {
    reportId, filterConfig, appliedFilters, activeFilters, requestProcessing,
    selectedToolbarMenuItem,
  } = report;
  const {
    applyFilters, clearFilters, filterChange, searchDropdownOptions,
    loadMoreDropdownOptions, deleteDynamicFilter, refresh,
  } = reduxAction;

  const [expandAvailableFilters, setExpandAvailableFilters] = React.useState(false);
  const [newAdditionFilterState, setNewAdditionFilterState] = useState({
    ID: '',
    isHighlight: false,
  });

  useEffect(() => {
    if (selectedToolbarMenuItem !== -1) {
      setExpandAvailableFilters(false);
    }
  }, [selectedToolbarMenuItem]);

  let menuItemData = null;
  let Component = null;
  if (selectedToolbarMenuItem !== -1) {
    menuItemData = ReportToolbarMenuOptions[selectedToolbarMenuItem];
  }

  const onFilterChange = (filterId: string, filterValue: any) => {
    filterChange(filterId, filterValue, auth.variables);
  };

  const onClearFilters = () => clearFilters(auth.variables);

  const getIdOnAddNewFilter = (Id: string, type: string) => {
    switch (type) {
      case FilterType.UserMultiSelect:
      case FilterType.LSQMetadataMultiSelect:
      case FilterType.CustomDefinedMultiSelect:
      case FilterType.GroupMultiSelect:
      case FilterType.LSQMetadataSingleSelect:
      case FilterType.CustomDefinedSingleSelect: {
        setNewAdditionFilterState({ ...newAdditionFilterState, ID: Id });
        break;
      }
      default: {
        setNewAdditionFilterState({
          ID: Id,
          isHighlight: true,
        });
        setTimeout(() => {
          setNewAdditionFilterState({
            ID: '',
            isHighlight: false,
          });
        }, 600);
        break;
      }
    }
  };

  useEffect(() => {
    if (requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST][newAdditionFilterState.ID] === APIRequestStatus.Success) {
      setNewAdditionFilterState({ ...newAdditionFilterState, isHighlight: true });
      setTimeout(() => {
        setNewAdditionFilterState({
          ID: '',
          isHighlight: false,
        });
      }, 600);
    }
  }, [requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST][newAdditionFilterState.ID]]);

  const nonDisabledFiltersCount = filterConfig
    ? filterConfig
      .filter((config) => appliedFilters[config.ID] && !(config.IsDynamic
      && config.IsRemoved) && config.Type !== FilterType.PRE_APPLIED
      && !appliedFilters[config.ID].Disabled)
      .length
    : 0;

  if (menuItemData) {
    switch (menuItemData.id) {
      case ToolbarMenuItemTypes.Filters: {
        const error = Object.keys(requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST])
          .findIndex((item) => (requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST][item]
          === APIRequestStatus.Failure)) !== -1;
        Component = error
          ? (
            <ErrorComponent
              message={ErrorMessage.Filters}
              tenant={userAttributes && userAttributes.OrgShortCode}
              issue={`Report: ${reportId}`}
              retry={() => refresh()}
            />
          )
          : (
            <FilterBuilder
              config={filterConfig}
              filters={appliedFilters}
              activeFilters={activeFilters}
              loaders={requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST]}
              applyFilters={applyFilters}
              clearFilters={onClearFilters}
              onChange={onFilterChange}
              searchDropdownOptions={searchDropdownOptions}
              loadMoreDropdownOptions={loadMoreDropdownOptions}
              auth={auth}
              availableDynamicFilters={(
                <>
                  <div className="se-filter-header-cn">
                    <div className="se-filter-header">
                      <span className={`se-filter-counter ${nonDisabledFiltersCount === FilterMaxLimit
                        ? 'se-limit-reached' : ''}`}
                      >
                        {AppliedFilter}
                        <span className={` ${nonDisabledFiltersCount === FilterMaxLimit
                          ? 'se-limit-reached' : ''}`}
                        >
                          {nonDisabledFiltersCount}
                          /
                          {FilterMaxLimit}
                        </span>
                      </span>
                    </div>
                    <ExpandListButton
                      label={expandFiltersButtonLabel}
                      isExpanded={expandAvailableFilters}
                      onClickToggler={() => setExpandAvailableFilters(!expandAvailableFilters)}
                      clickSource={ExpandListButtonContext.Filters}
                    />
                  </div>
                  <AvailableFilters
                    reportBuilderPortal={reportBuilderPortal}
                    expand={expandAvailableFilters}
                    collapse={() => setExpandAvailableFilters(false)}
                    getIdOnAddNewFilter={getIdOnAddNewFilter}
                  />
                </>
              )}
              removeDynamicFilter={deleteDynamicFilter}
              closeAvailableFilters={() => setExpandAvailableFilters(false)}
              newAddFilterID={newAdditionFilterState.isHighlight && newAdditionFilterState.ID}
            />
          );
        break;
      }

      case ToolbarMenuItemTypes.Setting: {
        Component = <Settings reportBuilderPortal={reportBuilderPortal} toolbarExpanded={toolbarExpanded} />;
        break;
      }

      case ToolbarMenuItemTypes.ReportViews: {
        Component = <ReportViewComponent />;
        break;
      }

      case ToolbarMenuItemTypes.ZeroValues: {
        Component = <ZeroValues />;
        break;
      }

      default:
        break;
    }

    return (
      <>
        <div
          className="se-sidebar-showcase-header"
        >
          <div
            className="se-sidebar-header-title"
          >
            <div
              className="se-sidebar-title-icon"
            >
              <menuItemData.panelIcon
                width={20}
                height={20}
                fill="var(--primaryColor)"
              />
            </div>
            <div
              className="se-sidebar-title-label"
            >
              {menuItemData.name}
            </div>
          </div>
          <div
            className="se-sidebar-header-desc"
          >
            {menuItemData.desc}
          </div>
        </div>
        {Component}
      </>
    );
  }
  return null;
};

const getToolbarMenu = (props: IProps, onPress: INavClick, isExpand: boolean) => {
  const { reduxState } = props;
  const { report, auth } = reduxState;
  const userAttributes = auth.userAttributes as UserAttributes;
  const {
    reportConfig, selectedToolbarMenuItem, activeDimensions, appliedDimensions,
    autodrilldownData,
  } = report;

  const isMenuSelected = selectedToolbarMenuItem !== -1;
  const hasColumnGroupings = appliedDimensions.some((dim) => (
    dim.DimensionMode !== DimensionMode.RowGroup && dim.Applied !== DimensionStatus.NotApplied
  ));

  const isAutodrilldown = !!(autodrilldownData && autodrilldownData.reportData);

  return (
    ReportToolbarMenuOptions.map((item, index) => {
      switch (item.name) {
        case ToolbarMenuItemTypes.Filters: {
          if (reportConfig.Filters && !isEmpty(reportConfig.Filters)) {
            break;
          }
          return null;
        }

        case ToolbarMenuItemTypes.Setting: {
          // hasPivotColumn is a temporary lock for reports with pivot columns. Once we have on and of controls we can remove this
          if (activeDimensions && !isEmpty(activeDimensions)) {
            break;
          }
          return null;
        }

        case ToolbarMenuItemTypes.ZeroValues: {
          // zero values disabled entirely for column grouping
          if (!activeDimensions || isEmpty(activeDimensions) || hasColumnGroupings || !(userAttributes && userAttributes.Role && userAttributes.Role.length > 0)) {
            return null;
          }
          // zero values disabled for Sales User
          switch (userAttributes.Role) {
            case UserRole.Administrator:
            case UserRole.SalesManager:
            case UserRole.MarketingUser:
              break;
            default:
              return null;
          }
          break;
        }

        default:
          break;
      }

      const isActive = isExpand && isMenuSelected
      && selectedToolbarMenuItem === index;

      return (
        <SideBarNavItem
          item={item}
          isActive={isActive}
          isAutodrilldown={isAutodrilldown}
          onPress={() => onPress(index)}
          index={index}
        />
      );
    })
  );
};

const ReportSidebar = (props: IProps) => {
  const { reduxState, reduxAction, reportBuilderPortal } = props;
  const { report } = reduxState;
  const { selectedToolbarMenuItem } = report;
  const { setToolbarMenuItem } = reduxAction;

  const [expand, setExpand] = useState<boolean>(true);

  useEffect(() => {
    if (selectedToolbarMenuItem === -1 && expand) {
      setExpand(false);
    }
  }, [selectedToolbarMenuItem]);

  const onPress = (index: number) => {
    if (selectedToolbarMenuItem !== index) {
      setToolbarMenuItem(index);
      setExpand(true);
      return;
    }
    setToolbarMenuItem(-1);
  };

  return (
    <StyledContainer>
      <div
        className="se-report-sidebar"
      >
        <div
          className={`se-report-sidebar-showcase ${expand ? 'expand' : ''}`}
        >
          {showcaseItem(props, reportBuilderPortal, expand)}
        </div>
        <div
          className="se-report-sidebar-nav"
        >
          {
            getToolbarMenu(props, onPress, expand)
          }
        </div>
      </div>
    </StyledContainer>
  );
};

export default StoreConnector()(ReportSidebar);
