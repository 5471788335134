import styled from 'styled-components';

const StyledReportComponent = styled.div`
width: 100%;
height: 100%;
overflow: hidden;

.se-cover-open {
  background-color: #212B36;
  padding: 0 0;
  opacity: 0.3;
  z-index: 9; // less than export-pannel-component, when opened
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.se-report {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f4f6f8;
  overflow: hidden;

  .se-report-showcase {
    flex: 1;
    height: 100%;
    display: flex;
    overflow: hidden;

    .se-report-body {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
`;

export default StyledReportComponent;
