/* eslint-disable no-console */
import moment from 'moment';
import { isArray } from 'lodash';
import FilterService, { IUserOptionsFetchPayload } from 'services/filter.service';
import { DatePayloadFormat, IDateRangeTypes } from 'core/constants/date';
import { FilterType, UserFilterPayloadType } from 'core/constants/filter';
import { FilterModel, ObjModel, ReportResponseModel } from 'core/models';
import VariableParser from 'core/utils/variable-parser';
import { isValidDateRangePayload } from './date.util';

export default class FilterFetchUtility {
  _variables : ObjModel.Obj

  constructor(variables: ObjModel.Obj) {
    this._variables = variables;
  }

  // GetSingleFilterData gives filter data based on type and key, along with linked data
  // param filterConfig: filterConfig from report config
  // param value: default value or linked filter value required to fetch data
  // returns: based on filter type return type depends. Cast post it is returned.
  // throws error when non implemented filter is requested.
  GetSingleFilterData = async (
    filterConfig: ReportResponseModel.IFilterConfig, dependentValue: any, value: any, meta?: any,
  ): Promise<FilterModel.IFilterResponse> => {
    try {
      switch (filterConfig.Type) {
        case FilterType.PRE_APPLIED:
          return {
            FilterID: filterConfig.ID,
            FilterResponse: new VariableParser(this._variables).ReplaceVariable(filterConfig.DefaultValue),
            shouldLoad: false,
            Disabled: false,
          };
        case FilterType.GroupMultiSelect: {
          let FilterResponse: any = [];
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          const data = await this.groupMultiSelectProcessor();
          if (data && data.length) {
            data.forEach((item) => {
              FilterOptions.push({
                label: item.DisplayName,
                value: item.Value,
              });
            });
            FilterResponse = this.getValueForMultiSelect(data, value);
          }
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: false,
            Disabled: false,
          };
        }
        case FilterType.UserMultiSelect: {
          const {
            OptionsMeta, FilterOptions,
          } = meta as FilterModel.IFilterResponse;
          let newOptions: Array<FilterModel.ISelectFilterOptions> = OptionsMeta.Page
          && OptionsMeta.Page.PageIndex > 0 ? FilterOptions : [];
          const data = await this.userMultiSelectProcessor(
            filterConfig,
            dependentValue,
            value,
            OptionsMeta.Page,
            OptionsMeta.SearchKey,
          ) as FilterModel.IUserFilterOptionResponse;
          if (data && data.DisplayUsers && data.DisplayUsers.length) {
            newOptions = newOptions.concat(
              data.DisplayUsers.map((item) => ({
                label: item.DisplayName,
                value: item.Value,
              })),
            );
          }
          return {
            FilterID: filterConfig.ID,
            FilterResponse: value,
            FilterOptions: newOptions,
            shouldLoad: false,
            Disabled: false,
            OptionsMeta: {
              ...OptionsMeta,
              Count: data ? data.Count : 0,
              HasNextPage: data.HasNextPage,
            },
          };
        }
        case FilterType.LSQMetadataMultiSelect: {
          let FilterResponse: any = [];
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          const data = await this.lsqMultiSelectProcessor(filterConfig);
          if (data && data.length) {
            data.forEach((item) => {
              FilterOptions.push({
                label: item.DisplayName,
                value: item.Value,
              });
            });
            FilterResponse = this.getValueForMultiSelect(data, value);
          }
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: false,
            Disabled: false,
          };
        }
        case FilterType.LSQMetadataSingleSelect: {
          let FilterResponse: any = null;
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          const data = await this.lsqMultiSelectProcessor(filterConfig);
          if (data && data.length) {
            data.forEach((item) => {
              FilterOptions.push({
                label: item.DisplayName,
                value: item.Value,
              });
            });
            FilterResponse = this.getValueForSingleSelect(data, value);
          }
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: false,
            Disabled: false,
          };
        }
        case FilterType.CustomDefinedMultiSelect: {
          let FilterResponse: any = [];
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          const data = await this.customDefinedMultiSelectProcessor(filterConfig);
          if (data && data.length) {
            data.forEach((item) => {
              FilterOptions.push({
                label: item.DisplayName,
                value: item.Value,
              });
            });
            FilterResponse = this.getValueForMultiSelect(data, value);
          }
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: false,
            Disabled: false,
          };
        }

        case FilterType.CustomDefinedSingleSelect: {
          let FilterResponse: any = null;
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          const data = await this.customDefinedMultiSelectProcessor(filterConfig);
          if (data && data.length) {
            data.forEach((item) => {
              FilterOptions.push({
                label: item.DisplayName,
                value: item.Value,
              });
            });
            FilterResponse = this.getValueForSingleSelect(data, value);
          }
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: false,
            Disabled: false,
          };
        }
        case FilterType.DateRange:
          return {
            FilterID: filterConfig.ID,
            FilterResponse: this.dateRangeProcessor(value),
            shouldLoad: false,
            Disabled: false,
          };
        default:
          throw new Error('Filter Type not implemented');
      }
    } catch (error) {
      console.error('GetFilterData failed for ', filterConfig, dependentValue, error);
    }
    return null;
  }

  // GetDefaultSingleFilter gives filter default data based on type and key, along with linked data
  // param filterConfig: filterConfig from report config
  // param value: default value or linked filter value required to fetch data
  // returns: based on filter type return type depends. Cast post it is returned.
  // throws error when non implemented filter is requested.
  GetDefaultSingleFilter = (filterConfig: ReportResponseModel.IFilterConfig, value: any): FilterModel.IFilterResponse => {
    try {
      switch (filterConfig.Type) {
        case FilterType.PRE_APPLIED:
          return {
            FilterID: filterConfig.ID,
            FilterResponse: value && value !== '' && value !== filterConfig.DefaultValue ? value : new VariableParser(this._variables).ReplaceVariable(filterConfig.DefaultValue),
            shouldLoad: false,
            Disabled: false,
          };
        case FilterType.GroupMultiSelect: {
          const FilterResponse: any = !filterConfig.IsMasked ? value : [];
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: !filterConfig.IsMasked,
            Disabled: false,
          };
        }
        case FilterType.UserMultiSelect: {
          const FilterResponse: any = !filterConfig.IsMasked ? (value || {
            Type: UserFilterPayloadType.All,
            UserIds: [],
          } as FilterModel.IUserFilterPayload)
            : [];
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: !filterConfig.IsMasked,
            Disabled: false,
            OptionsMeta: {
              Page: {
                PageIndex: 0,
                PageSize: 20,
              },
              SearchKey: '',
              Count: 0,
              HasNextPage: false,
            },
          };
        }
        case FilterType.LSQMetadataMultiSelect: {
          const FilterResponse: any = !filterConfig.IsMasked ? value : [];
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: !filterConfig.IsMasked,
            Disabled: false,
          };
        }
        case FilterType.LSQMetadataSingleSelect: {
          const FilterResponse: any = !filterConfig.IsMasked ? value : null;
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: !filterConfig.IsMasked,
            Disabled: false,
          };
        }
        case FilterType.CustomDefinedMultiSelect: {
          const FilterResponse: any = !filterConfig.IsMasked ? value : [];
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: !filterConfig.IsMasked,
            Disabled: false,
          };
        }
        case FilterType.CustomDefinedSingleSelect: {
          const FilterResponse: any = !filterConfig.IsMasked ? value : null;
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: !filterConfig.IsMasked,
            Disabled: false,
          };
        }
        case FilterType.DateRange:
          return {
            FilterID: filterConfig.ID,
            FilterResponse: !filterConfig.IsMasked ? this.dateRangeProcessor(value) : null,
            shouldLoad: false,
            Disabled: false,
          };
        default:
          throw new Error('Filter Type not implemented');
      }
    } catch (error) {
      console.error('GetFilterData failed for ', filterConfig, value, error);
    }
    return null;
  }

  private groupMultiSelectProcessor = async () => new FilterService().GetGroups()

  private userMultiSelectProcessor = async (
    filterConfig: ReportResponseModel.IFilterConfig, value: any, filterValue: FilterModel.IUserFilterPayload,
    page: FilterModel.IPage, searchKey: string,
  ) => {
    const filterKey = filterConfig?.Metadata?.FilterKey;
    let userIds = [];
    if (filterKey && filterKey.length) {
      const data = {
        Type: filterKey,
        InclusionType: filterValue?.Type,
        UserIDs: filterValue?.Type === UserFilterPayloadType.All ? [] : filterValue?.UserIds,
        Page: page,
        Search: searchKey,
      } as IUserOptionsFetchPayload;
      if (value) {
        if (Array.isArray(value)) {
          userIds = value;
        } else {
          userIds = [value];
        }
      }
      if (filterKey === 'GroupUsers') {
        data.Groups = userIds;
      } else {
        data.Users = userIds;
      }
      return new FilterService().GetUserFilter(data);
    }
    return null;
  }

  private lsqMultiSelectProcessor = async (filterConfig: ReportResponseModel.IFilterConfig) => {
    const filterKey = filterConfig?.Metadata?.FilterKey;
    if (filterKey && filterKey.length) {
      return new FilterService().GetLSQDropdownValues(filterKey);
    }
    return null;
  }

  private customDefinedMultiSelectProcessor = async (filterConfig: ReportResponseModel.IFilterConfig) => {
    const filterKey = filterConfig?.Metadata?.FilterKey;
    if (filterKey && filterKey.length) {
      return new FilterService().GetCustomDefinedDropdownValues(filterKey);
    }
    return null;
  }

  private dateRangeProcessor = (value: FilterModel.IDateRangePayload)
  : FilterModel.IDateRangePayload => {
    if (isValidDateRangePayload(value)) {
      const newValue = { ...value };
      if (newValue.Type === IDateRangeTypes.Absolute) {
        newValue.Value.From = moment(value.Value.From).startOf('day').format(DatePayloadFormat);
      }
      return newValue;
    }
    return {
      Type: IDateRangeTypes.Absolute,
      Value: {
        From: moment().add(-7, 'days').startOf('day').format(DatePayloadFormat),
        To: moment().endOf('day').format(DatePayloadFormat),
      },
    };
  }

  private getValueForMultiSelect = (data: Array<FilterModel.ISelectFilterResponse>, value: any) => {
    const response = [];
    if (data && data.length) {
      if (value) {
        if (isArray(value)) {
          data.forEach((item) => {
            if (value.includes(item.Value)) {
              response.push(item.Value);
            }
          });
        } else {
          for (let i = 0; i < data.length; i += 1) {
            if (data[i].Value === value) {
              response.push(data[i].Value);
              break;
            }
          }
        }
      } else {
        data.forEach((item) => {
          response.push(item.Value);
        });
      }
    }
    return response;
  }

  private getValueForSingleSelect = (data: Array<FilterModel.ISelectFilterResponse>, value: any) => {
    let response = null;
    if (data && data.length) {
      if (value) {
        for (let i = 0; i < data.length; i += 1) {
          if (data[i].Value === value) {
            response = value;
            break;
          }
        }
      } else {
        response = data[0].Value;
      }
    }
    return response;
  }
}
