import React from 'react';
import StyledComponent from 'components/feature/ReportListing/report-list.style';
import ReportSectionLoader from 'components/feature/ReportListing/ReportSection/report-section.loader';
import ReportHeaderLoader from 'components/feature/ReportListing/ReportHeader/report-header.loader';

const ReportListLoader = () => (
  <StyledComponent>
    <div
      className="se-reports"
    >
      <ReportHeaderLoader />
      <div>
        {
          [1, 2, 3].map((item) => (
            <ReportSectionLoader key={item} />
          ))
          }
      </div>
    </div>
  </StyledComponent>
);

export default ReportListLoader;
