import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const ExportCloseIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="ExportIcon"
      d="M11.834 1.34199L10.659 0.166992L5.99999 4.82499L1.34199 0.166992L0.166992 1.34199L4.82499 5.99999L0.166992 10.659L1.34199 11.834L5.99999 7.17599L10.658 11.834L11.833 10.659L7.17499 6.00099L11.834 1.34199Z"
      fill="#919EAB"
    />
  </svg>
);

ExportCloseIcon.defaultProps = {
  fill: '#262626',
};

export default ExportCloseIcon;
