import { Reducer } from 'react';
import { IPlaceholderProps } from 'components/feature/Report/ReportSidebar/common/Placeholder/placeholder.d';

interface IAction {
  type: 'setActive' | 'unsetActive' | 'setMovable' | 'unsetMovable' | 'setTopOffset' | 'reset';
  topOffset?: number;
}

// eslint-disable-next-line import/prefer-default-export
export const placeholderPropsReducer: Reducer<IPlaceholderProps, IAction> = (prevState, action) => {
  if (!action) return prevState;
  switch (action.type) {
    case 'setActive': return { ...prevState, isActive: true };
    case 'unsetActive': return { ...prevState, isActive: false };
    case 'setMovable': return { ...prevState, isMovable: true };
    case 'unsetMovable': return { ...prevState, isMovable: false };
    case 'setTopOffset': return { ...prevState, topOffset: action.topOffset };
    case 'reset': return { isMovable: false, isActive: false, topOffset: 0 };
    default: return prevState;
  }
};
