import {
  compareAsc,
  isValid, startOfDay,

} from 'date-fns';
import {
  DateDisplayFormat, IDateRangeTypes, IRelativeDateRanges, DatePayloadFormat,
} from 'core/constants/date';
import moment from 'moment';
import { FilterModel } from 'core/models';

/*
Function returns a label to display for date range filter.
*/
export const dateRangeShowcase = (startDate: any, endDate: any)
: string => {
  if (isValid(new Date(startDate)) && isValid(new Date(endDate))) {
    return (
      `${moment(startDate).format(DateDisplayFormat)} to
       ${moment(endDate).format(DateDisplayFormat)}`
    );
  }
  return null;
};

/*
Function returns value based on passed date range payload. If valid returns same value
, if invalid returns a default value.
*/
export const dateRangeProcessor = (value: FilterModel.IDateRangePayload)
: FilterModel.IDateRangePayload => {
  if (isValidDateRangePayload(value)) {
    return value;
  }

  return {
    Type: IDateRangeTypes.Absolute,
    Value: {
      From: moment().add(-7, 'days').startOf('day').format(DatePayloadFormat),
      To: moment().endOf('day').format(DatePayloadFormat),
    },
  };
};

/*
Function returns the absolute value of predefined date ranges.
*/
export const evaluatePredefinedDateRange = (value: IRelativeDateRanges)
: FilterModel.IDateRangeResponse => {
  let absoluteValue = null;

  switch (value) {
    case IRelativeDateRanges.Yesterday: {
      absoluteValue = {
        From: moment().add(-1, 'days').startOf('day'),
        To: moment().add(-1, 'days').endOf('day'),
      };
      break;
    }

    case IRelativeDateRanges.ThisWeek: {
      absoluteValue = {
        From: moment().startOf('week'),
        To: moment().endOf('week'),
      };
      break;
    }

    case IRelativeDateRanges.LastWeek: {
      absoluteValue = {
        From: moment().add(-1, 'weeks').startOf('week'),
        To: moment().add(-1, 'weeks').endOf('week'),
      };
      break;
    }

    case IRelativeDateRanges.ThisMonth: {
      absoluteValue = {
        From: moment().startOf('month'),
        To: moment().endOf('month'),
      };
      break;
    }

    case IRelativeDateRanges.LastMonth: {
      absoluteValue = {
        From: moment().add(-1, 'months').startOf('month'),
        To: moment().add(-1, 'months').endOf('month'),
      };
      break;
    }

    case IRelativeDateRanges.ThisQuarter: {
      absoluteValue = {
        From: moment().startOf('quarter'),
        To: moment().endOf('quarter'),
      };
      break;
    }

    case IRelativeDateRanges.LastQuarter: {
      absoluteValue = {
        From: moment().add(-1, 'quarters').startOf('quarter'),
        To: moment().add(-1, 'quarters').endOf('quarter'),
      };
      break;
    }

    case IRelativeDateRanges.ThisYear: {
      absoluteValue = {
        From: moment().startOf('year'),
        To: moment().endOf('year'),
      };
      break;
    }

    case IRelativeDateRanges.LastYear: {
      absoluteValue = {
        From: moment().add(-1, 'years').startOf('year'),
        To: moment().add(-1, 'years').endOf('year'),
      };
      break;
    }

    case IRelativeDateRanges.Today: {
      absoluteValue = {
        From: moment().startOf('day'),
        To: moment().endOf('day'),
      };
      break;
    }
    default:
      break;
  }

  return absoluteValue;
};

/*
Function validates for valid date range payload.
*/
export const isValidDateRangePayload = (value: FilterModel.IDateRangePayload)
:boolean => {
  if (value && value.Type && Object.values(IDateRangeTypes).includes(value.Type) && value.Value) {
    if (value.Type === IDateRangeTypes.Absolute) {
      if (isValid(new Date(value.Value.From)) && isValid(new Date(value.Value.To))) {
        return true;
      }
    } else if (Object.values(IRelativeDateRanges).includes(value.Value)) {
      return true;
    }
  }
  return false;
};

export const isEqualDateRangePayload = (
  payload1: FilterModel.IDateRangePayload, payload2: FilterModel.IDateRangePayload,
): boolean => {
  if (isValidDateRangePayload(payload1) && isValidDateRangePayload(payload2)) {
    if (payload1.Type === payload2.Type) {
      if (payload1.Type === IDateRangeTypes.Absolute) {
        if (!compareAsc(startOfDay(new Date(payload1.Value.From)), startOfDay(new Date(payload2.Value.From)))
          && !compareAsc(startOfDay(new Date(payload1.Value.To)), startOfDay(new Date(payload2.Value.To)))) {
          return true;
        }
      } else if (payload1.Value === payload2.Value) {
        return true;
      }
    }
  }
  return false;
};
