import React from 'react';

type Props = {
    width: number;
    height: number;
    fill?: string;
}

const ExportTickIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.496 7.09667L0.89 4.30073L0 5.24778L3.5 9L11 0.946017L10.118 0L3.496 7.09667Z"
      fill={fill}
    />
  </svg>

);

ExportTickIcon.defaultProps = {
  fill: '#637381',
};

export default ExportTickIcon;
