import React from 'react';
import { GroupingDragDropPlaceholder } from 'components/feature/Report/ReportSidebar/common/constants';
import { IPlaceholderProps } from 'components/feature/Report/ReportSidebar/common/Placeholder/placeholder.d';
import StyledContainer from 'components/feature/Report/ReportSidebar/common/Placeholder/placeholder.style';

const Placeholder = (props: IPlaceholderProps) => {
  const { isMovable, isActive, topOffset } = props;
  return (
    <StyledContainer
      className="se-grouping-drag-drop-placeholder-container"
      isMovable={isMovable}
      topOffset={topOffset}
    >
      <div
        className={`se-grouping-drag-drop-placeholder ${isActive ? 'active' : ''}`}
      >
        {GroupingDragDropPlaceholder}
      </div>
    </StyledContainer>
  );
};

export default Placeholder;
