/* This component will render the Reports.It is the parent component for all types of report
   and will inputs arguments for creating the different report types.
 */
import React, { useCallback, useState } from 'react';
import {
  Column, RowNode,
} from 'ag-grid-enterprise';
import { Scrollbars } from 'react-custom-scrollbars';
import { useResizeDetector } from 'react-resize-detector';
import { cloneDeep, isEmpty } from 'lodash';
import { VisualizationTypes } from 'core/constants/visualizations';
import { ErrorMessage } from 'core/constants/errors';
import { APIRequestStatus } from 'core/constants/redux';
import { ReportActionTypes } from 'core/constants/redux-action-types';
import {
  ColumnActionTypes, DimensionNoValue, DimensionStatus, DrilldownChangesTypes, DrilldownChangeTypes, EncryptedText, LSQLinkType, PivotColGrpHeaderSeparator, ReportViewURLTypes,
} from 'core/constants/report';
import {
  GridModel, ObjModel, ReportReduxModel, ReportResponseModel,
} from 'core/models';
import { IColumnActionDrilldownChangeMeta } from 'core/models/report-response';
import ColumnChartUtil from 'core/utils/column-chart.util';
import Tooltip from 'rc-tooltip';
import { getColumnsProperties, getDrilldownValue } from 'core/utils/report-builder.util';
import { IProps } from 'components/feature/Report/ReportBuilder/report-builder.d';
import StyledContainer from 'components/feature/Report/ReportBuilder/report-builder.style';
import StoreConnector from 'components/feature/Report/ReportBuilder/report-builder.redux';
import ColumnChart from 'components/feature/Report/ReportBuilder/Reports/Charts/column-chart.component';
import BasicTable from 'components/feature/Report/ReportBuilder/Reports/Tables/BasicTable/basic-table.component';
import SummmaryTable from 'components/feature/Report/ReportBuilder/Reports/Tables/SummaryTable/summary-table.component';
import Multitable from 'components/feature/Report/ReportBuilder/Reports/Tables/MultiTable/multi-table.component';
import NoDataScreen from 'components/common/NoData/no-data.component';
import BackButton from 'components/common/BackButton/back-button.component';
import BarLoader from 'components/common/loader/BarLoader/bar-loader.component';
import ErrorComponent from 'components/common/Error/error.component';
import StyledBackButtonContainer from 'components/common/BackButton/back-button.styled';
import NextIcon from 'components/common/svg/Next/next.svg';
import { getCurlyBracesParamsFromString, getFormattedValuesForBinning } from 'core/utils/formatters.utility';
import { autodrilldownBreadcrumbsTooltip } from 'components/feature/Report/ReportSidebar/common/constants';
import store from 'redux/store';
import { Obj } from 'core/models/obj';
import { useSelector, useDispatch } from 'react-redux';
import { resetReportview } from 'redux/report-view/report-view.actions';
import { clearFilters } from 'redux/report/actions';
import { deleteGivenQueryStringsFromUrl } from 'core/utils/report.util';
import { reportViewSelector } from '../ReportSidebar/ReportView/report-view.selector';

function ReportBuilder(props: IProps) {
  /*  This function will decide which type of report will be there. It will take input argument as
  'type' and will return specific component based on input type */
  const {
    reduxState, reduxAction, setReportBuilderPortal,
  } = props;
  const { auth, report } = reduxState;
  const { userAttributes, variables } = auth;
  const {
    reportConfig, reportData, requestProcessing, prevAppliedFilters, drilldownPoint,
    reportId, columns, activeDimensions, autodrilldownData,
  } = report;
  const {
    drilldownChange, reverseDrilldown, reorderColumns, refresh, autoDrilldown,
    autodrilldownReportDataLoad,
  } = reduxAction;
  const dispatch = useDispatch();

  const [selectedRowData, setSelectedRowData] = useState();

  const {
    [ReportActionTypes.REPORT_DATA_LOAD_REQUEST]: dataLoadStatus,
    [ReportActionTypes.FILTER_LOAD_REQUEST]: filtersLoadStatus,
    [ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST]: autodrilldownDataLoadStatus,
    [ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST]: dynamicDimensionsLoadStatus,
  } = requestProcessing;

  const isAutodrilldown = autodrilldownData && !isEmpty(autodrilldownData.reportData);
  const reportViewStore = useSelector(reportViewSelector);
  const { reportViewError, isReportViewReportLoading } = reportViewStore;

  const isLoadingData = autodrilldownData
    ? autodrilldownDataLoadStatus === APIRequestStatus.Processing || dynamicDimensionsLoadStatus === APIRequestStatus.Processing
    : dataLoadStatus === APIRequestStatus.Processing || dataLoadStatus === APIRequestStatus.AboutToLoad;
  const error = autodrilldownData
    ? autodrilldownDataLoadStatus === APIRequestStatus.Failure
    : dataLoadStatus === APIRequestStatus.Failure;
  const isLoadingFilters = Object.values(filtersLoadStatus)
    .findIndex((item) => item === APIRequestStatus.Processing) !== -1;
  let allowAutodrilldown = reportConfig.AllowDrilldownOnMaskedDimensions;
  let gridColumns = columns ? getColumnsProperties(columns, isAutodrilldown, allowAutodrilldown) : [] as Array<GridModel.IColumnConfig>;

  let isParent = !prevAppliedFilters.length;

  let isNoData = !isLoadingData && reportData && reportData.Raw && isEmpty(reportData.Raw.Data);

  const retry = autodrilldownData
    ? autodrilldownReportDataLoad
    : refresh;

  const resetUrlAndRefresh = () => {
    clearFilters(variables);
    dispatch(resetReportview());
    // After clicking on reset we have to remove reportViewId, reportViewType and UserId to URL
    deleteGivenQueryStringsFromUrl([ReportViewURLTypes.rvId, ReportViewURLTypes.rvType, ReportViewURLTypes.rvu]);
    retry();
  };

  if (autodrilldownData && autodrilldownData.reportData) {
    allowAutodrilldown = false;
    gridColumns = autodrilldownData.columns
      ? getColumnsProperties(autodrilldownData.columns, isAutodrilldown, allowAutodrilldown)
      : [];
    isParent = false;
    isNoData = !isLoadingData && autodrilldownData.reportData && autodrilldownData.reportData.Raw
    && isEmpty(autodrilldownData.reportData.Raw.Data);
  }

  const onResize = useCallback(() => {
  }, []);
  const { ref } = useResizeDetector({ onResize });

  const styles = error ? {
    barLoader: {
      backgroundColor: 'rgb(0 0 0 / 70%)',
      zIndex: 3,
    },
  } : {
    barLoader: {
      backgroundColor: 'rgb(234 234 234 / 70%)',
      zIndex: 3,
    },
  };

  const openLSQLinkInNewTab = (lsqLink :string, data:any) => {
    let link = lsqLink;
    let marvinURL = document.referrer;
    const params = getCurlyBracesParamsFromString(lsqLink);
    const isMarvin = store.getState().auth.isMarvin;

    if (params.length > 0) {
      params.forEach((displayName) => {
        const displayNameValue = data[displayName];
        link = link.replace(`{${displayName}}`, `${displayNameValue}`);
        if (lsqLink.includes('LeadID')) {
          marvinURL += `LeadDetails?Id=${displayNameValue}`;
        }
      });
    }
    if (lsqLink.includes('opportunityId')) {
      marvinURL += `OpportunityDetails?Id=${data['Lead Prospect ID']}&OpportunityId=${data['OpportunityId ']}&OpportunityEvent=${data['ActivityEvent ']} `;
    }
    // removing the extra slash from the begining
    link = link.substring(1);
    const lsqURL = process.env.REACT_APP_LSQ_URL + link;
    window.open(isMarvin ? marvinURL : lsqURL, '_blank');
  };

  const onCellClicked = (e: ObjModel.Obj) => {
    let columnDef = e.colDef;
    let drilldownValue = e.value;
    const rowPinned = e.rowPinned;

    // for pinned bottom row no drilldown (Total row)
    if (rowPinned === 'bottom') {
      return;
    }

    let data = e.data;
    if (data === undefined && !e.colDef.pivotValueColumn) {
      // row grouping with no pivot; since each row group has only one row, we take the first one
      data = e.node?.allLeafChildren?.[0]?.data;
    }

    if (columnDef.lsqLink) {
      try {
        if (e.value) {
          if (columnDef.lsqLink === LSQLinkType.LINKTOSELF) {
            window.open(e.value, '_blank');
          } else {
            openLSQLinkInNewTab(columnDef.lsqLink, data);
          }
        }
      } catch (ex:any) {
        if (ex instanceof DOMException) {
          // todo - either toast notification, send logs to backend
        }
      }
      return;
    }
    if (data === undefined && !!e.colDef.pivotValueColumn) {
      // pivoted columns; find underlying data that matches all the pivot values
      const pivotColumns: Column[] = e.columnApi.getPivotColumns();
      for (let i = 0; i < e.node.allLeafChildren?.length; i += 1) {
        const leaf: RowNode = e.node.allLeafChildren[i];
        let doPick = true;
        for (let j = 0; j < columnDef.pivotKeys.length; j += 1) {
          const column = pivotColumns[j].getColDef() as any;
          const value = columnDef.pivotKeys[j];
          doPick = doPick && (leaf.data[column.parentDataColumn ? column.parentDataColumn : column.colId].toString()
            === ((column.parentDataColumn || (column.isMasked && allowAutodrilldown))
            && value.indexOf(PivotColGrpHeaderSeparator) !== -1 ? value.split(PivotColGrpHeaderSeparator)[0] : value));
        }
        if (doPick) {
          data = leaf.data;
          break;
        }
      }
    }

    if (columnDef.showRowGroup) {
      const getAllColDefs = e.columnApi.getColumns();
      const getTheParentColDefination = getAllColDefs.find((item: any) => (
        item.colDef.headerName === columnDef.headerName));
      columnDef = getTheParentColDefination.colDef;
      if (columnDef.parentDataColumn && columnDef.parentDataColumn.length) {
        drilldownValue = data?.[columnDef.headerName];
      }
    } else {
      const selectedMeasure = columnDef.pivotKeys && columnDef.pivotKeys.length
        ? columnDef.pivotValueColumn.colDef.colId : columnDef.colId;

      const dataWithNoValuesReplacedToNull = changeDataValuesFromNovalueToOriginal(data);
      setSelectedRowData(data);
      autoDrilldown(selectedMeasure, dataWithNoValuesReplacedToNull);
      return;
    }

    const action = columnDef.action as ReportResponseModel.IColumnAction;

    if (columnDef && action && data) {
      switch (action.Type) {
        case ColumnActionTypes.Drilldown:
          handleDrilldownClick(action, data, drilldownValue);
          break;
        case ColumnActionTypes.SetFilter:
          handleSetFilterClick(columnDef, action, data, drilldownValue);
          break;
        default:
          throw new Error('Not Implemented');
      }
    }
  };

  // we are applying [No Value] when we recieve a '' or 0 from api, here reverting to its original value
  const changeDataValuesFromNovalueToOriginal = (cellData:Obj) => {
    const newData :Obj = {};

    Object.keys(cellData).forEach((key:string) => {
      if (cellData[key] === DimensionNoValue) {
        // for zero values we recieve null from backend where drilldown is not allowed. So check for the type where data is not null.
        const nonNullData = reportData.Raw.Data.find((value) => value[key] !== null && value[key] !== undefined);
        newData[key] = (typeof (nonNullData && nonNullData[key]) === 'string') ? '' : 0;
      } else {
        newData[key] = cellData[key];
      }
    });

    return newData;
  };

  const handleSetFilterClick = (
    columnDef: any,
    action: ReportResponseModel.IColumnAction,
    data: ObjModel.Obj,
    drilldownValue: any,
  ) => {
    const metadata = action.Meta as ReportResponseModel.IColumnActionFilterSetMeta;
    if (metadata && metadata.FilterID && metadata.FilterID.length) {
      const filterToChange = metadata.FilterID;
      let valueToSet: any = null;
      if (metadata.TakeValueFrom && metadata.TakeValueFrom.length) {
        valueToSet = data[metadata.TakeValueFrom];
      } else {
        valueToSet = data[columnDef.Field];
      }
      drilldownChange(
        filterToChange,
        valueToSet,
        drilldownValue,
        userAttributes && userAttributes.Name,
      );
    }
  };

  // This function will handle drill down  click to child. Based on Filter type values will be set.
  const handleDrilldownClick = (
    action: ReportResponseModel.IColumnAction,
    data: ObjModel.Obj,
    drilldownValue: string,
  ) => {
    const metadata = action.Meta as ReportResponseModel.IColumnActionDrilldownMeta;
    if (
      metadata
      && metadata.DrilldownChanges
      && metadata.DrilldownChanges.Changes
    ) {
      if (metadata.DrilldownChanges.Type === DrilldownChangesTypes.FilterOnly) {
        for (let i = 0; i < metadata.DrilldownChanges.Changes.length; i += 1) {
          const change = metadata.DrilldownChanges.Changes[i];
          if (change.Type === DrilldownChangeTypes.Filter) {
            const changeMeta = change.ChangeMeta as IColumnActionDrilldownChangeMeta;
            if (changeMeta) {
              const filterToChange = changeMeta.FilterID;
              let valueToSet: any = null;
              if (changeMeta.TakeValueFrom && changeMeta.TakeValueFrom.length) {
                valueToSet = data[changeMeta.TakeValueFrom];
              }
              drilldownChange(
                filterToChange,
                valueToSet,
                drilldownValue,
                userAttributes && userAttributes.Name,
              );
            }
          }
        }
      }
    }
  };

  const getPrevDrilldownValue = (prev: number) => {
    switch (reportConfig.Visualization.Type) {
      case VisualizationTypes.AreaChart:
      case VisualizationTypes.BarChart:
      case VisualizationTypes.LineChart:
      case VisualizationTypes.ColumnChart: {
        const instance = new ColumnChartUtil(reduxState.report);
        return instance.GetPreviousDrilldownValue(prev);
      }
      case VisualizationTypes.Table:
        return getDrilldownValue(
          prev,
          isParent,
          prevAppliedFilters,
          gridColumns,
        );
      default:
        break;
    }
    return 'Back';
  };

  // Function to handle column reordering in ag grid.
  /*
  step1: inject headerName property for the existing columns into a new variable- columsWithHeaderName
  step2: create a new Array for reordered columns and  push all the mode 0 columns into newReorderedColumnArray
  step3: from reorderedHeaderNames add all the columns to newReorderedColumnArray matching the header name
  step4: add the pending columns to newReorderedColumnArray to include pivot columns
  in all the above step we are maintaining a Set(seen) to track only once a column gets added
  */
  const onColumnReorder = (reorderedHeaderNames: Array<string>) => {
    const currColumns = isAutodrilldown
      ? cloneDeep(autodrilldownData.columns)
      : cloneDeep(columns) as Array<ReportResponseModel.IColumn>;

    // adding headerName prop to all the columns
    const columsWithHeaderName = currColumns.map((item:ReportResponseModel.IColumn) => {
      let headerName = item.Name;
      if (item.BuilderConfig && item.BuilderConfig.IsDynamicField) {
        headerName = `${item.BuilderConfig.Entity} | ${item.BuilderConfig.DisplayName}`;
      }
      return { ...item, headerName };
    });

    const seen = new Set();
    // adding all the mode 0 cols into the list
    const newReorderedColumnArray = columsWithHeaderName.filter((item:any) => {
      if (item.Props.Mode === 0) {
        seen.add(item.headerName);
        return true;
      }
      return false;
    });

    // add all the columns according to reorderedHeaderNames except for zEmtpy columnd which is having emtyp string as coldId
    reorderedHeaderNames.forEach((headerName:string) => {
      if (!seen.has(headerName) && headerName !== '') {
        newReorderedColumnArray.push(columsWithHeaderName.find((col:any) => col.headerName === headerName));
        seen.add(headerName);
      }
    });

    // remaining cols to add it to newReorderedColumnArray i.e pivot columns
    columsWithHeaderName.forEach((item:any) => {
      if (!seen.has(item.headerName)) {
        newReorderedColumnArray.push(item);
        seen.add(item.headerName);
      }
    });

    reorderColumns(
      newReorderedColumnArray,
      isAutodrilldown,
    );
  };

  const reportType = (type: VisualizationTypes) => {
    switch (type) {
      case VisualizationTypes.Table:
      default:
        return (
          <BasicTable
            onCellClicked={onCellClicked}
            onColumnReorder={onColumnReorder}
            columns={gridColumns}
          />
        );
      case VisualizationTypes.ColumnChart:
      case VisualizationTypes.LineChart:
      case VisualizationTypes.AreaChart:
      case VisualizationTypes.BarChart:
        return (
          <ColumnChart
            handleSetFilterClick={handleSetFilterClick}
            handleDrilldownClick={handleDrilldownClick}
            onColumnReorder={onColumnReorder}
            onCellClicked={onCellClicked}
          />
        );
      case VisualizationTypes.SummaryTable:
        return <SummmaryTable {...props} />;
      case VisualizationTypes.MultiLineTable:
        return <Multitable {...props} />;
    }
  };

  const backButton = () => (isAutodrilldown
    ? (
      <StyledBackButtonContainer id="autodrilldown">
        <Tooltip
          mouseEnterDelay={1.5}
          placement="bottom"
          overlay={(<span>{autodrilldownBreadcrumbsTooltip}</span>)}
          overlayClassName="se-breadcrumbs-tooltip"
        >
          <button
            type="button"
            className="se-back-item"
            onClick={() => autoDrilldown(null, null)}
          >
            {reportConfig.Details.Title}
          </button>
        </Tooltip>
        <div
          className="se-icon-cn"
        >
          <NextIcon
            width={20}
            height={20}
            fill="var(--searchBoxTextColor)"
          />
        </div>
        <div
          className="se-selected-measure-cn"
        >
          <span>
            Drilldown on&nbsp;
          </span>
          <span
            className="se-selected-dimension"
          >
            {
              getSelectedGroupedItem(activeDimensions, selectedRowData)
            }
          </span>
          <span>
            &nbsp;for&nbsp;
          </span>
          <span
            className="se-selected-measure"
          >
            {autodrilldownData.selectedMeasure}
          </span>
        </div>
      </StyledBackButtonContainer>
    )
    : (
      <BackButton
        backCallback={isLoadingData || isLoadingFilters ? null : reverseDrilldown}
        prevValues={prevAppliedFilters}
        getPreviousDrilldownValue={getPrevDrilldownValue}
        drilldownLevel={drilldownPoint && drilldownPoint.level}
      />
    ));

  const barLoader = () => (
    <div className="ag-loader-fullscreen">
      <BarLoader
        isRelative={false}
        style={styles.barLoader}
        content={error ? '' : 'Loading...'}
      />
    </div>
  );

  const renderReport = () => (
    <div className="se-render-report-layout">
      {!isParent ? backButton()
        : null}
      {isLoadingData || isLoadingFilters || isReportViewReportLoading ? barLoader()
        : null}
      {isNoData
        ? <NoDataScreen />
        : reportType(
          isAutodrilldown ? autodrilldownData.reportData?.Visualization?.Visualization?.Type : reportConfig.Visualization.Type,
        )}
    </div>
  );

  if (error || reportViewError === 'Deleted') {
    return (
      <StyledContainer>
        <div
          className="se-report-builder"
        >
          <div
            className="se-error-cn"
          >
            <ErrorComponent
              message={ErrorMessage.Report}
              headerMessage={reportViewError && ErrorMessage.ReportView}
              tenant={userAttributes && userAttributes.OrgShortCode}
              issue={`Report: ${reportId}`}
              retry={reportViewError ? () => resetUrlAndRefresh() : () => retry()}
              back={autodrilldownData
                ? () => autoDrilldown(null, null)
                : null}
            />
          </div>
        </div>
        {/* A portal for the report sidebar to render panels that hover over the report. */}
        <div ref={setReportBuilderPortal} />
      </StyledContainer>
    );
  }
  return (
    <StyledContainer>
      <Scrollbars
        renderTrackHorizontal={(trackHorizontalProps) => <div {...trackHorizontalProps} className="track-horizontal" />}
        renderTrackVertical={(trackVerticalProps) => <div {...trackVerticalProps} className="track-vertical" />}
        renderThumbHorizontal={(thumbHorizontalProps) => <div {...thumbHorizontalProps} className="thumb-horizontal" />}
        renderThumbVertical={(thumbVerticalProps) => <div {...thumbVerticalProps} className="thumb-vertical" />}
      >
        <div className="se-report-builder" ref={ref}>
          {renderReport()}
        </div>
      </Scrollbars>
      {/* A portal for the report sidebar to render panels that hover over the report. */}
      <div ref={setReportBuilderPortal} />
    </StyledContainer>

  );
}

export default StoreConnector()(ReportBuilder);

const getSelectedGroupedItem = (
  activeDimensions: ReportReduxModel.IDimension[], selectedRowData: ObjModel.Obj,
) => {
  let displayStr = '';
  if (!isEmpty(activeDimensions) && !isEmpty(selectedRowData)) {
    const groupedDims = activeDimensions.filter((dim) => dim.Applied !== DimensionStatus.NotApplied);
    for (let i = 0; i < groupedDims.length; i += 1) {
      const key = groupedDims[i].BuilderConfig && groupedDims[i].BuilderConfig.IsDynamicField
        ? groupedDims[i].BuilderConfig.Alias
        : groupedDims[i].ReferTo || groupedDims[i].Name;
      const binningType = groupedDims[i]?.DimensionProp?.Props?.BinningType;
      if (selectedRowData[key]) {
        let value = selectedRowData[key];
        value = binningType && value !== DimensionNoValue ? getFormattedValuesForBinning(selectedRowData[key], binningType) : selectedRowData[key];
        value = groupedDims[i].IsMasked ? EncryptedText : value;
        displayStr = displayStr.concat(displayStr.length ? ` / ${value}` : value);
      }
    }
  }

  return displayStr;
};
