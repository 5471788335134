import React, { useRef } from 'react';
import { IReportView } from 'types/report-view.type';
import { useSelector } from 'react-redux';
import LinearBar from 'components/common/loader/LinearBar/linear-bar.component';
import DeleteIcon from 'components/common/svg/Delete/delete.svg';
import LockIcon from 'components/common/svg/Lock/lock.svg';
import TickIcon from 'components/common/svg/Tick/tick.svg';
import TickMarkIcon from 'components/common/svg/TickMarkIcon/tickMarkIcon.svg';
import WarningIcon from 'components/common/svg/Warning/warning.svg';
import { LinearBarColorTypes, SizeTypes } from 'core/constants/loader';
import {
  ReportViewTypes,
  MaskedMessage, ReportViewActionMessage, ReportViewActionSuccess, ReportViewsActionTypes,
  TooltipDelayTime,
  TooltipPlacement,
  ViewTypeProperties,
} from 'core/constants/report';
import Tooltip from 'rc-tooltip';
import KebabIcon from 'components/common/svg/KebabIcon/kebab-icon.svg';
import DropDown from 'components/common/DropDown/dropdown.component';
import ShareIcon from 'components/common/svg/Share/share.svg';
import { IOption } from 'components/common/DropDown/dropdown.types';
import StopShareIcon from 'components/common/svg/StopSharing/stop-sharing-icon.svg';
import UserRole from 'core/constants/auth';
import StyledContainer from './style/report-view-list.style';
import { PendoClassNames } from '../common/constants';
import { authSelector, reportViewSelector } from './report-view.selector';
import ReportViewInfo from './report-view-info.component';

export interface ReportViewListProps {
  index: number;
  actionSuccess : boolean,
  actionIndex : number,
  reportViewListItem: IReportView;
  stateLabel: string;
  isValidLabel: boolean,
  disableReportView:boolean,
  selectedIndex:number,
  isActionProcessing: boolean,
  action: ReportViewsActionTypes
  handleAction: (Deletedindex: number, Id:string, callFrom: ReportViewsActionTypes, type: ReportViewTypes, isShared: boolean) => void,
  undoAction: () => void,
  onSelectReportView:(PersonalisationId: string, index : number, ViewType: ReportViewTypes) => void
  ViewType: ReportViewTypes,
  sharedBy: string,
  isShared: boolean,
}

const ReportViewListItem = (props: ReportViewListProps) => {
  const reportView = useSelector(reportViewSelector);
  const { selectedReportViewItem } = reportView;
  const {
    index, reportViewListItem, actionSuccess, actionIndex, stateLabel, isValidLabel, disableReportView,
    selectedIndex, isActionProcessing, handleAction, undoAction, action, onSelectReportView, ViewType,
    sharedBy, isShared,
  } = props;
  const {
    PersonalisationId, DisplayName, IsRestricted,
  } = reportViewListItem;

  const auth = useSelector(authSelector);
  const { userAttributes } = auth;
  const userRole = userAttributes?.Role as UserRole;

  const ViewContainer = useRef(null);

  const isReportSelected = PersonalisationId === selectedReportViewItem;
  const createSuccess = (stateLabel === reportViewListItem.DisplayName) && isValidLabel;
  const selectedView = !isActionProcessing && !actionSuccess && selectedIndex === index && !createSuccess;
  const disableView = disableReportView && !isActionProcessing;
  const showKebabMenu = !((ViewType === ReportViewTypes.Default) || (userRole !== UserRole.Administrator && ViewType === ReportViewTypes.Shared));

  const showIcon = () => {
    if (isActionProcessing && action === ReportViewsActionTypes.Delete) {
      return (
        <WarningIcon
          width={16}
          height={16}
        />
      );
    }
    if (isActionProcessing && action === ReportViewsActionTypes.Share) {
      return (
        <ShareIcon
          width={16}
          height={16}
          fill="var(--onHoverBorder)"
        />
      );
    }
    if (isActionProcessing && action === ReportViewsActionTypes.StopShare) {
      return (
        <StopShareIcon
          width={16}
          height={16}
          fill="#F5802A"
        />
      );
    }
    if (actionSuccess && actionIndex === index) {
      switch (action) {
        case ReportViewsActionTypes.Delete: {
          return (
            <TickMarkIcon
              width={20}
              height={20}
              fill="#D1331B"
            />
          );
        }
        case ReportViewsActionTypes.Share: {
          return (
            <TickMarkIcon
              width={20}
              height={20}
              fill="var(--collapsibleColor)"
            />
          );
        }
        case ReportViewsActionTypes.StopShare: {
          return (
            <TickMarkIcon
              width={20}
              height={20}
              fill="#F5802A"
            />
          );
        }
        default: return '';
      }
    } if (!IsRestricted) {
      if ((stateLabel === reportViewListItem.DisplayName)
      && isValidLabel) {
        return (
          <TickMarkIcon
            width={16}
            height={16}
            fill="#189F3F"
          />
        );
      }
      return (
        <div className="se-report-view-info">
          <ReportViewInfo
            stateIndex={index}
            ViewType={ViewType}
          />
        </div>
      );
    }

    return (
      <Tooltip
        placement="bottom"
        overlay={(
          <span>{MaskedMessage.ApplyReportView}</span>
                    )}
        mouseEnterDelay={TooltipDelayTime}
        overlayClassName="se-restricted-field"
      >
        <div className="se-icon-cn se-lock-icon">
          <LockIcon
            width={16}
            height={16}
          />
        </div>
      </Tooltip>
    );
  };

  const getDisplayName = () => {
    let showName;
    if (createSuccess) {
      showName = ReportViewActionSuccess.Create;
    } else if (actionSuccess && actionIndex === index) {
      switch (action) {
        case ReportViewsActionTypes.Delete: {
          showName = ReportViewActionSuccess.Delete;
          break;
        }
        case ReportViewsActionTypes.Share: {
          showName = ReportViewActionSuccess.Share;
          break;
        }
        case ReportViewsActionTypes.StopShare: {
          showName = ReportViewActionSuccess.StopShare;
          break;
        }
        default:
          break;
      }
    } else if (isActionProcessing) {
      switch (action) {
        case ReportViewsActionTypes.Delete: {
          showName = ViewType === ReportViewTypes.Custom && isShared ? ReportViewActionMessage.DeleteShare : `Deleting ${DisplayName}`;
          break;
        }
        case ReportViewsActionTypes.Share: {
          showName = ReportViewActionMessage.Share;
          break;
        }
        case ReportViewsActionTypes.StopShare: {
          showName = ReportViewActionMessage.StopShare;
          break;
        }
        default:
          break;
      }
    } else {
      showName = DisplayName;
    }
    return showName;
  };

  const reportViewName = (
    <div className="se-report-view-name">
      {getDisplayName()}
    </div>
  );

  const handleOnClick = (callFrom:string, event:React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    switch (callFrom) {
      case ReportViewsActionTypes.Delete:
      case ReportViewsActionTypes.Share:
      case ReportViewsActionTypes.StopShare: {
        handleAction(index, PersonalisationId, callFrom, ViewType, isShared);
        break;
      }
      case ReportViewsActionTypes.Undo: {
        undoAction();
        break;
      }

      default: {
        break;
      }
    }
  };
  const getDisabledState = (actionType: ReportViewsActionTypes) => {
    switch (actionType) {
      case ReportViewsActionTypes.Delete: {
        return ViewType !== ReportViewTypes.Custom;
      }
      case ReportViewsActionTypes.Share: {
        if (userRole !== UserRole.Administrator) {
          return true;
        }
        if (ViewType === ReportViewTypes.Custom) {
          return isShared;
        }
        if (ViewType === ReportViewTypes.Shared) {
          return true;
        }
        return false;
      }
      case ReportViewsActionTypes.StopShare: {
        if (userRole !== UserRole.Administrator) {
          return true;
        }
        if (ViewType === ReportViewTypes.Custom) {
          return !isShared;
        }
        return false;
      }
      default: {
        return false;
      }
    }
  };

  const options: IOption[] = [
    {
      label: 'Share View',
      clickHandler: (event) => handleOnClick(ReportViewsActionTypes.Share, event),
      svg: <ShareIcon width={20} height={20} fill="var(--headerColor)" />,
      textColor: 'var(--headerColor)',
      isDisabled: getDisabledState(ReportViewsActionTypes.Share),
      PendoClass: PendoClassNames.ShareReportView,
    },
    {
      label: 'Stop Sharing',
      clickHandler: (event) => handleOnClick(ReportViewsActionTypes.StopShare, event),
      svg: <StopShareIcon width={20} height={20} fill="#F5802A" />,
      textColor: '#F5802A',
      isDisabled: getDisabledState(ReportViewsActionTypes.StopShare),
      PendoClass: PendoClassNames.StopShareReportView,
    },
    {
      label: 'Delete View',
      clickHandler: (event) => handleOnClick(ReportViewsActionTypes.Delete, event),
      svg: <DeleteIcon width={20} height={20} fill="#D23922" />,
      textColor: '#D23922',
      isDisabled: getDisabledState(ReportViewsActionTypes.Delete),
      PendoClass: PendoClassNames.DeleteReportViewButton,
    },
  ];

  const getBarColor = () => {
    switch (action) {
      case ReportViewsActionTypes.Delete: {
        return LinearBarColorTypes.Danger;
      }
      case ReportViewsActionTypes.Share: {
        return LinearBarColorTypes.Primary;
      }
      case ReportViewsActionTypes.StopShare: {
        return LinearBarColorTypes.Warning;
      }
      default:
        return LinearBarColorTypes.Primary;
    }
  };

  const getClassNames = () => {
    if (isActionProcessing) {
      if (action === ReportViewsActionTypes.Delete && ViewType === ReportViewTypes.Custom && isShared) {
        return `${action} se-delete-share`;
      }
      return action;
    }
    if (actionSuccess && actionIndex === index) {
      const common = 'se-action-success';
      if (action === ReportViewsActionTypes.Delete) {
        return `${common} se-delete-success`;
      }
      if (action === ReportViewsActionTypes.Share) {
        return `${common} se-share-success`;
      }
      if (action === ReportViewsActionTypes.StopShare) {
        return `${common} se-stop-share-success`;
      }
    }
    if (createSuccess) {
      return 'se-new-create';
    }
    if (selectedView || isReportSelected) {
      return 'selected';
    }
    if (IsRestricted) {
      return 'masked';
    }
    return '';
  };

  return (
    <StyledContainer key={PersonalisationId} IndicatorHeight={(ViewContainer?.current?.getBoundingClientRect().height || 40) - 2} IndicatorColor={ViewTypeProperties[ViewType]?.color}>
      <div className={`se-report-view ${disableView && selectedView ? 'disabled' : ''}`} ref={ViewContainer}>
        <button
          className={`se-report-view-btn ${getClassNames()}`}
          type="button"
          onClick={() => !isReportSelected && !IsRestricted && onSelectReportView(PersonalisationId, index, ViewType)}
          disabled={disableView}
        >
          {
          !(isActionProcessing || actionSuccess) && !createSuccess && (
          <Tooltip
            placement={TooltipPlacement.Bottom}
            overlay={(
              <span>
                {ViewType === ReportViewTypes.Shared ? ViewTypeProperties[ViewType]?.Message.replace('Admin', sharedBy) : ViewTypeProperties[ViewType]?.Message}
              </span>
                )}
            mouseEnterDelay={0.5}
            overlayClassName="se-Viewindicator"
          >
            <div
              className="se-viewType-indicator"
            />
          </Tooltip>
          )
}
          {showIcon()}
          <div className="se-report-view-label-cn">
            {!isActionProcessing ? (
              <Tooltip
                placement={TooltipPlacement.BottomLeft}
                overlay={(
                  <span>
                    {
                  `${DisplayName}`
                }
                  </span>
              )}
                overlayClassName="se-report-view-tooltip"
                mouseEnterDelay={TooltipDelayTime}
              >
                {reportViewName}
              </Tooltip>
            )
              : reportViewName}
          </div>
          {
            ((selectedView || isReportSelected) && !isActionProcessing && !actionSuccess) && (
            <div
              className="se-tick-icon se-icon-cn"
            >
              <TickIcon
                width={12}
                height={12}
              />
              {
              showKebabMenu
              && <div className="se-kebab-divider" />
              }
            </div>
            )
          }
          {
            isActionProcessing ? (
              <button
                className="se-undo-action-btn"
                type="button"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleOnClick(ReportViewsActionTypes.Undo, event)}
              >
                Undo
              </button>
            )
              : (!actionSuccess && !createSuccess
                && showKebabMenu && (
                <DropDown
                  mainSvg={(<KebabIcon width={16} height={16} fill="#919EAB" />)}
                  options={options}
                />
              )
              )
}
        </button>
        <LinearBar
          show={action === ReportViewsActionTypes.Delete || action === ReportViewsActionTypes.StopShare || action === ReportViewsActionTypes.Share}
          size={SizeTypes.XSmall}
          isBottomPlaced
          barColor={isActionProcessing && getBarColor()}
          isCompleted={false}
          time={5}
        />
      </div>
    </StyledContainer>
  );
};

export default ReportViewListItem;
