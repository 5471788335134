/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
export enum FilterType {
    PRE_APPLIED = 'PRE_APPLIED',
    UserMultiSelect = 'UserMultiSelect',
    LSQMetadataMultiSelect = 'LSQMetadataMultiSelect',
    DateRange = 'DateRange',
    DRILLDOWN='DRILLDOWN',
    FILTER_ONLY='Filter_Only',
    CustomDefinedMultiSelect='CustomDefinedMultiSelect',
    CustomDefinedSingleSelect='CustomDefinedSingleSelect',
    LSQMetadataSingleSelect='LSQMetadataSingleSelect',
    FILTER='Filter',
    SET_FILTER='SET_FILTER',
    GroupMultiSelect='GroupMultiSelect',
}

export enum ClickType {
    DRILLDOWN='DRILLDOWN',
    SET_FILTER='SET_FILTER'
}

export enum UserFilterPayloadType {
    All = 'ALL',
    In = 'IN',
    NotIn = 'NOTIN'
}

export enum SelectBulkAction {
    None = -1,
    SelectAll,
    RemoveAll
}

export enum UserMultiSelectFilterKeyType {
    GroupUsers = 'GroupUsers',
}

export const FilterMaxLimit = 15;

export enum MaxLimitDesc {
    AppliedFilter = 'Maximum Limit of filters ({{Filter limit}}) have been reached',
    AppliedDimension = 'Maximum Limit of fields {{limit}} have been reached',
    DrilldownField = 'Maximum Limit of fields {{limit}} have been reached'
}

export enum NumberOfFiltersApplied {
    AppliedFilters = 'All except ({{difference}})',
}
