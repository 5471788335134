import React from 'react';
import { ExpandListButtonContext } from 'core/constants/report';
import { ISidebarHeaderProps } from 'components/feature/Report/ReportSidebar/common/SidebarHeader/sidebar-header.d';
import StyledContainer from 'components/feature/Report/ReportSidebar/common/SidebarHeader/sidebar-header.style';
import SwitchIcon from 'components/common/svg/Switch/switch.svg';
import RowGroupingIcon from 'components/common/svg/RowGrouping/row-grouping.svg';
import ColumnGroupingIcon from 'components/common/svg/ColumnGrouping/column-grouping.svg';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';
import ValuesIcon from 'components/common/svg/Values/value.svg';

const SidebarHeader = (props: ISidebarHeaderProps) => {
  const {
    title, desc, onToggle, toggleValue,
    counter = null, infoIcon, source,
  } = props;
  const showToggle = typeof onToggle === 'function';

  return (
    <StyledContainer>
      <div className={`se-sidebar-header ${!!desc && 'se-add-padding'}`}>
        <div className="se-title-cn">
          {(source) && (
            <div className="se-icon-cn se-title-icon">
              {(source === ExpandListButtonContext.RowGroup || source === ExpandListButtonContext.Drilldown) && (
                <span
                  className={`${source === ExpandListButtonContext.Drilldown ? 'se-drilldown-icon' : ''}`}
                >
                  <RowGroupingIcon
                    width={18}
                    height={17}
                    fill="var(--secondaryColor)"
                  />
                </span>
              )}
              {source === ExpandListButtonContext.ColGroup && (
              <ColumnGroupingIcon
                width={18}
                height={17}
                fill="var(--secondaryColor)"
              />
              )}
              {source === ExpandListButtonContext.Values && (
                <ValuesIcon
                  width={16}
                  height={16}
                />
              )}
            </div>
          )}
          <div className="se-title">
            {title}
          </div>
          {infoIcon}
          {
            counter
          }
          {
            showToggle && (
              <div className={`se-toggler ${toggleValue ? 'active' : 'inactive'} ${PendoClassNames.ShowZeroValues}`}>
                <button
                  className="se-icon-cn"
                  type="button"
                  onClick={() => onToggle(!toggleValue)}
                >
                  <SwitchIcon
                    width={28}
                    height={28}
                    fill={toggleValue ? '#116fd6' : '#e8e9ea'}
                  />
                </button>
              </div>
            )
          }
        </div>
        {
          !!desc && (
            <div className="se-desc">
              {desc}
            </div>
          )
        }
      </div>
    </StyledContainer>
  );
};

export default SidebarHeader;
