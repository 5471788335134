import styled from 'styled-components';

const StyledHighChartComponent = styled.div`
height: 100%;
width: 100%;

.se-rawdata-fullwidth{
  height: 100%;
  .se-rawdata-viewer-container {
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 12px;
  transition: all 3s ease-in-out;
  }
}
.disable-click{
  cursor: not-allowed;
  pointer-events: none;
}
.enable-click{
  cursor: pointer;
  pointer-events: auto; 
}
.se-column-chart-container{
 background-color: #fff;
 position: relative;

}
position: relative;
.highcharts-title{
    display: none;
}
/* .highcharts-legend{
    display: none;
} */
.se-column-visualization-container{
position: relative;
}

`;

export default StyledHighChartComponent;
