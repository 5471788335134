import styled from 'styled-components';

const StyledExportComponent = styled.div`

.se-export-container {
    position:fixed ;
    background-color: #FFFFFF !important;
    right: 0px ;
    width: 348px ;
    height: 100% ;
    z-index: 10;
    opacity: 1;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-right: 0px;
    transition: margin-right 0.6s ease-in-out;
}

.se-export-container.toggle {
    margin-right: -348px ;
}

.se-label-limit{
    display: flex;
    justify-content: space-between;
    font: 13px Regular;

    .se-limit-info{
        background-color: #F4F6F8;
        border-radius: 10px;
        padding: 1px 8px;
        font-size: 11px;
        color: #637381;
    }

    .se-limit-numbers{
        padding-left:4px;
        font-weight: bold;
    }

    .se-limit-reached{
        color:#DE323B;
    }
}


.se-export-cont-header{
    display: flex;
    align-items: center;
    padding: 14px 16px 13px 16px;
}

.se-header-label{
    text-align: left;
    letter-spacing: 0px;
    color: #454F5B;
    opacity: 1;
    flex:1;
    margin-left: 8px;
    font: 16px SemiBold;
    line-height: 21px;
}

.se-header-icon{
    display: flex;
}

.se-icon-close-button{
    border: none;
    cursor: pointer;
    height: 20px;
    :hover{
        svg #ExportIcon {
            fill: #454F5B !important;
        }
    }
}

.se-export-format-section{
    background: #FFFFFF;
    opacity: 1;
    padding: 16px 16px 16.5px 16px;
}

.se-format-types{
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
}


.se-format-label{
    text-align: left;
    color: #454F5B;
    opacity: 1;
    font: 13px Regular;
}

.se-type-format{
    background: #FFFFFF;
    border: 1px solid #006FD6;
    border-radius: 2px;
    opacity: 1;
    box-sizing: border-box;
    padding-left: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    width: 90px;
    height: auto;
}
.se-no-border {
    border-color: #DFE3E8 !important;
}

.se-disabled{
    border-color:#DFE3E8;
}

.se-type-format:hover{
    background: #FFFFFF;
    border: 1px solid #006FD6;
    border-radius: 2px;
    opacity: 1;
}

.se-type-text{
    text-align: left;
    font: 13px 'Regular';
    letter-spacing: 0px;
    color: #212B36;
    opacity: 1;
    margin-left: 6px;
}

.se-format-types{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 16px;
}

.disabled{
    opacity: 0.5;
    pointer-events: none;
}

.se-export-showcase{
    width: 100%;
    overflow:overlay !important;
}
.se-export-showcase:last-child{
    padding-bottom: 72px;
    margin-top: 0px;
}

.se-header {
    font: 13px 'Regular';
    color: #454F5B;
    padding-left: 16px;
    margin-top: 20px;
}

.se-export-history-button-cont{
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 8px 16px 0px 16px;
    height: 48px;
    box-sizing: border-box;
    border-top: 1px solid #DFE3E8;
    background: #FFFFFF;
    opacity: 1;
    cursor: pointer;
}
.se-export-history-button{
    height: 32px;
    position: fixed;
    bottom: 8px;
    width: 314px;
    background: #006FD6;
    border: 1px solid #006FD6;
    border-radius: 2px;
    opacity: 1;
    text-align: center;
    box-sizing: border-box;
}

.se-export-history-label{
    font: 13px SemiBold;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    vertical-align: middle;
}

.se-export-divider {
    border-top: 1px solid #DFE3E8;
    opacity: 1;
    width: 100%;
}

.se-export-button-container {
 display: flex;
 align-items: center;
 padding:0px 15px 20px 15px;

 .se-inner-export-button{
    width: 100%;
 }

 button {
    width: 100%;
    padding: 8px;
    font-size: 12px;
    color: #FFFFFF;
    font-family: SemiBold;
    border-radius: 2px;
    border-width: 0px;
    background-color:#006FD6;
    border-top: 0.3px solid #DFE3E8;

    :hover{
        background-color: #006FC0;
    }
    :disabled {
        color: #FFFFFF;
        background-color: #7EB6EA;
        cursor: not-allowed;
        border-width: 0px;
    }
 } 
}

.se-export-note {
   font-size:13px ;
   color: #012240 ;
   height: 34px;
   line-height: 17px;
}

.se-export-note-section {
    background-color:#084E961A ;
    padding: 8px 16px ;
}

.se-selected-tick-icon {
    padding: 0px 8px 0px 13px;
    display: flex;
}

.se-daily-quota-warning-outer {
    padding: 0px 16px 20px 16px;
}

.se-daily-quota-warning-inner {
    background: #FFF0F1;
    height: 32px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.se-warning-text {
    font-size: 12px;
    font-family: 'Regular';
    color: #DE323B;
    margin-left: 8px;
}

`;

export default StyledExportComponent;
