import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const WarningIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="warning_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 16 16"
  >
    <path
      id="Path_8932"
      data-name="Path 8932"
      d="M0,0H16V16H0Z"
      fill="none"
    />
    <path
      id="Path_8933"
      data-name="Path 8933"
      d="M1,14.591H15.636L8.318,2ZM8.983,12.6H7.653V11.278H8.983Zm0-2.651H7.653V7.3H8.983Z"
      transform="translate(-0.318 -0.636)"
      fill={fill}
    />
  </svg>
);

WarningIcon.defaultProps = {
  fill: '#d1331b',
};

export default WarningIcon;
