import React from 'react';
import { NavSectionSubTypes } from 'core/constants/side-navbar';
import StyledEmptyData from 'components/feature/ReportListing/EmptyDataComponent/empty-data.styled';
import EmptyDataIcon from 'components/common/svg/EmptyData/empty-data.svg';

const EmptyDataScreen = (props:any) => {
  const { selectedType } = props;
  const messages = getMessages(selectedType);
  return (
    <StyledEmptyData>
      <EmptyDataIcon
        width={80}
        height={80}
      />
      <span className="se-empty-data-header">{messages.headerMessage}</span>
      <span className="se-empty-data-text">{messages.textMessage}</span>
    </StyledEmptyData>
  );
};

export default EmptyDataScreen;

const getMessages = (cateogry:any) => {
  switch (cateogry) {
    case NavSectionSubTypes.Pinned:
      return {
        headerMessage: 'No Pinned Reports',
        textMessage: 'Pin all your frequently viewed reports for a quick access.',
      };
    default:
      return {
        headerMessage: 'No Pinned Reports',
        textMessage: 'Pin all your frequently viewed reports for a quick access.',
      };
  }
};
