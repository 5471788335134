import styled from 'styled-components';

const StyledPaginationContainer = styled.div`

display: flex;
flex-grow: 1;
flex-wrap:nowrap;
align-items: center;
justify-content: space-between;
font-family: 'Regular';
bottom: 0;
position: sticky;
color: var(--paginationColor);
.se-page-size-cn, .se-page-index-cn {
    display: flex;
    align-items: center;
}
.se-page-size-cn {
  margin-left: 28px;
}
.se-pagination-label {
  color: var(--paginationColor);
  font-weight: normal;  
  span{
    color: #454f5b;
  }
}

.se-records-label {
  color: var(--paginationColor);
  font-weight: normal;
  border-left: 1px solid var(--paginationRecordsSeparator);
  padding: 8px 8px 8px 16px;
}

.se-pagination-disabled{
  cursor: not-allowed;
}

.se-page-index-counter-cn {
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  border-radius: 3px;
  border: solid 1px var(--paginationBorder);
  background-color:  var(--base-white);
  margin: 0;
  color: var(--paginationColor);
}

.se-page-index {
  color: #454f5b;
}

.se-page-index-controller-cn {
  display: flex;
}

.se-btn-prev, .se-btn-next {
  display: flex;
  align-items: center;
  padding: 1px;
  border: 1px solid var(--searchBorder);
  border-radius: 3px;
  background-color:  var(--base-white);
  cursor: pointer;
}

.se-btn-prev:hover, .se-btn-next:hover {
  border: 1px solid var(--collapsibleColor);
}
.se-btn-prev:disabled, .se-btn-next:disabled{
  cursor: not-allowed;
}

.se-pagination-dropdown {
  .se-react-select__control {
    height: 30px;
    background: var(--base-white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--paginationBorder);
    border-radius: 2px;
    padding: 0 8px 0 10px; 
  }

  .se-react-select__value-container {
    font-size: 14px;
  }

  .se-react-select__single-value {
    width: auto;
    max-width: none;
    position: relative;
    transform: none;
  }

  .se-react-select__option {
    font-size: 14px;
  }
}

@media (min-width: 320px) {
  justify-content: center;
  padding: 0px;
    .se-pagination-label {
        font-size: 13px;
        margin-right: 8px;
      }
      .se-btn-prev {
        margin-right: 8px;
      }
      .se-records-label {
        font-size: 13px;
        margin-left: 8px;
      }
      .se-page-index-counter-cn {
      
        height: 30px;
        font-size: 14px;
        padding: 0 8px;
      }
  }

@media (min-width: 768px) {
 
  justify-content: space-between;
    .se-pagination-label {
        font-size: 13px;
        margin: 8px 8px 8px 0px;
      }
      .se-btn-prev {
        margin-right: 8px;
      }
      .se-records-label {
        font-size: 13px;
        margin-left: 16px;
      }
      .se-page-index-counter-cn {
    
        height: 30px;
        font-size: 14px;
        padding: 0 8px;
        margin-right: 20px;
      }
  }

  @media (min-width: 1024px) {
 
    .se-page-index-counter-cn { 
        margin: 0 20px;
     
        height: 30px;
        font-size: 14px;
        padding: 0 8px;
      }
      .se-btn-prev {
        margin-right: 12px;
      }
  }

`;
export default StyledPaginationContainer;
