import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const ValuesIcon = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.36914 14.2364V13.0088L8.81714 8.00085L3.36914 2.99196V1.7644H12.8803V3.71285H6.45981L11.1149 7.99996L6.45981 12.2871H12.8803V14.2355L3.36914 14.2364Z"
      fill={fill}
    />
  </svg>

);

ValuesIcon.defaultProps = {
  fill: '#454F5B',
};

export default ValuesIcon;
