import styled from 'styled-components';

export default styled.div`
height: 48px;
padding: 0 16px;
display: flex;
align-items: center;
border-top: 0.3px solid #DFE3E8;
&.externalAppIntegrationStlye{
  padding-bottom: 45px;
}


button {
    width: 100%;
    padding: 8px;
    font-size: 12px;
    color: #FFFFFF;
    font-family: SemiBold;
    border-radius: 2px;
    border-width: 0px;
    background-color:#006FD6;
    border-top: 0.3px solid #DFE3E8;

    :hover {
        background-color:#0064C0;
    }

    :active {
        background-color:#0064C0;
    }

    :disabled {
        color: #FFFFFF;
        background-color: #7EB6EA;
        cursor: not-allowed;
        border-width: 0px;
    }

    :focus {
        border: 2px solid rgba(17, 111, 214, 0.3);
        box-shadow: 0px 0px 1px #1360B5;
    }
}
`;
