import React from 'react';

type Props = {
    width: number;
    height: number;
}

const QuotaWarningIcon = ({
  width, height,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.682129 13.9553H15.3181L8.00013 1.36426L0.682129 13.9553ZM8.66513 11.9643H7.33513V10.6423H8.66513V11.9643ZM8.66513 9.31326H7.33513V6.66426H8.66513V9.31326Z"
      fill="#DE323B"
    />
  </svg>

);

export default QuotaWarningIcon;
