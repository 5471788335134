import React from 'react';
import {
  IApplySettingsButtonProps,
} from 'components/feature/Report/ReportSidebar/common/ApplySettingsButton/apply-settings.d';
import StyledContainer from 'components/feature/Report/ReportSidebar/common/ApplySettingsButton/apply-settings.style';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { resetReportview } from 'redux/report-view/report-view.actions';
import { deleteGivenQueryStringsFromUrl } from 'core/utils/report.util';
import { ReportViewURLTypes } from 'core/constants/report';
import { authSelector } from '../../ReportView/report-view.selector';

const ApplySettingsButton = (props: IApplySettingsButtonProps) => {
  const { onApply, disabled } = props;
  const dispatch = useDispatch();
  const { isV1Report, isMarvin } = useSelector(authSelector);

  const applySettings = () => {
    onApply();
    dispatch(resetReportview());
    // We want to remove report view id if present from url on applying filter
    deleteGivenQueryStringsFromUrl([ReportViewURLTypes.rvId, ReportViewURLTypes.rvType, ReportViewURLTypes.rvu]);
  };
  return (
    <StyledContainer className={`${isV1Report || isMarvin ? 'externalAppIntegrationStlye' : ''}`}>
      <button
        type="button"
        onClick={() => applySettings()}
        disabled={disabled}
        className={PendoClassNames.ApplySettings}
      >
        Apply Settings
      </button>
    </StyledContainer>
  );
};

export default ApplySettingsButton;
