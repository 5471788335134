import styled from 'styled-components';

export default styled.div`
    display: flex;
    align-items: center;
    padding: 5px 16px 5px 26px;
    height: 32px;
    box-sizing: border-box;

    :hover {
        background: #F4F6F8;
    }

    .se-item-name {
        flex: 1;
        margin: 0px 8px 0px 0px ;
        font-size: 13px;
        color: var(--paginationColor);
        font-family: Regular;
       
  
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .se-add-btn {
        border: none;
        padding: 0;
        &.active {
            :hover {
                svg {
                    path:last-child {
                        fill: #006FD6 !important;
                    }
                }   
            }
        }

        &.inactive {
            cursor: not-allowed;
            svg {
                path:last-child {
                    fill: #D1331B !important;
                }
            } 
        }
    }
`;
