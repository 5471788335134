import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ReduxModel } from 'core/models';
import * as Actions from 'redux/report/actions';
import { DimensionMode, DimensionStatus } from 'core/constants/report';

const mapStateToProps = (state: ReduxModel.IGlobalState) => ({
  reduxState: state,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reduxAction: {
    setGrouping: (sourceIndex: number, status: DimensionStatus, mode: DimensionMode, destinationIndex?: number, isSwapEnabled?: boolean) => {
      dispatch(Actions.setGrouping(sourceIndex, status, mode, destinationIndex, isSwapEnabled));
    },
  },
});

export default () => connect(mapStateToProps, mapDispatchToProps);
