import { createGlobalStyle } from 'styled-components';
import 'rc-tooltip/assets/bootstrap_white.css';

const GlobalStyle = createGlobalStyle<DefaultProps>`

@font-face {
    font-family: Regular;
    src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeui.ttf") format("truetype");
  }
  
  @font-face {
    font-family: Bold;
    src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeuib.ttf") format("truetype");
  }
  
  @font-face { 
    font-family: Light;
    src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeuil.ttf") format("truetype"); 
  }
  
  @font-face {
    font-family: SemiBold;
    src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/seguisb.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Brandon Grotesque"; 
    src: url("https://db.onlinewebfonts.com/t/6cec834e2e8f2e4206567ed75e1281d4.ttf") format("truetype");
}
  body {
    margin: 0;
    font-family:"Regular", "sans-serif";
    background:${(props) => props.theme.body};
  }
  :root{
    --base-font-color: ${(props) => props.theme.headerColor};
    --base-transparent:transparent;
    --base-filter-label-color:${(props) => props.theme.filterLabelColor};
    --base-date-picker-color:${(props) => props.theme.datepickerColor};
    --base-date-picker-border:${(props) => props.theme.datePickerBorder};
    --reactSelectBorderAcitve:${(props) => props.theme.reactSelectBorderAcitve};
    --reactSelectBackground: ${(props) => props.theme.reactSelectBackground};
    --reactSelectActiveOption: ${(props) => props.theme.reactSelectActiveOption};
    --backButtonBackground: ${(props) => props.theme.backButtonBackground};
    --backButtonColor: ${(props) => props.theme.backButtonColor};
    --fullLoaderBar1:${(props) => props.theme.fullLoaderBar1};
    --fullLoaderBar2:${(props) => props.theme.fullLoaderBar2};
    --fullLoaderBar3:${(props) => props.theme.fullLoaderBar3};
    --fullLoaderBar4:${(props) => props.theme.fullLoaderBar4};
    --noDataBackground:${(props) => props.theme.noDataBackground};
    --noDataColor: ${(props) => props.theme.noDataColor};
    --paginationColor: ${(props) => props.theme.paginationColor};
    --paginationBorder:${(props) => props.theme.paginationBorder};
    --paginationRecordsSeparator:${(props) => props.theme.paginationRecordsSeparator};
    --base-white:#fff;
    --loaderColor:${(props) => props.theme.loaderColor};
    --reportHeaderLabel:${(props) => props.theme.reportHeaderLabel};
    --searchBorder:${(props) => props.theme.searchBorder};
    --searchHoverBorder:${(props) => props.theme.searchHoverBorder};
    --searchBoxTextColor:${(props) => props.theme.searchBoxTextColor};
    --searchButtonBackground:${(props) => props.theme.searchButtonBackground};
    --borderColor:${(props) => props.theme.borderColor};
    --navHeaderColor:${(props) => props.theme.navHeaderColor};
    --sideNavBorderColor:${(props) => props.theme.sideNavBorderColor};
    --collapsibleColor:${(props) => props.theme.collapsibleColor};
    --headerColor:${(props) => props.theme.headerColor};
    --paginationPrev:${(props) => props.theme.paginationPrev};
    --paginationNext:${(props) => props.theme.paginationNext};
    --arrowIcon:${(props) => props.theme.arrowIcon};
    --onHoverBorder:${(props) => props.theme.onHoverBorder};
    --reactOnOptionSelect:${(props) => props.theme.reactOnOptionSelect};
    --reactSelectScrollBar:${(props) => props.theme.reactSelectScrollBar};
    --rawDataHeader:${(props) => props.theme.rawDataHeader};
    --rawDataButton:${(props) => props.theme.rawDataButton};
    --boxShadow:${(props) => props.theme.boxShadow};
    --backButtonBorder:${(props) => props.theme.backButtonBorder};
    --sideNavBarBorder:${(props) => props.theme.sideNavBarBorder};
    --navSectionIcon:${(props) => props.theme.navSectionIcon};
    --headerBackgroundColor:${(props) => props.theme.headerBackgroundColor};
    --avtarColor:${(props) => props.theme.avtarColor};
    --primaryColor:${(props) => props.theme.primaryColor};
    --secondaryColor:${(props) => props.theme.secondaryColor};
    --dangerColor:${(props) => props.theme.dangerColor};
    --expandListButtonColor:${(props) => props.theme.expandListButtonColor};
    --expandListButtonHoverColor:${(props) => props.theme.expandListButtonHoverColor};
    --grayCounterBackgroundColor:${(props) => props.theme.grayCounterBackgroundColor};
    --paleSkyColor:${(props) => props.theme.paleSkyColor};
    --infoIconColor:${(props) => props.theme.infoIconColor};
  }
  }
  @media (min-width: 768px) {
    height: auto;
  }

  @media (min-width: 1024px) {
    height: auto;
  }

  button {
    cursor: pointer;
    background: transparent;
  }

  button:disabled {
    cursor: default;
  }
  .se-shimmer-cn {
    background: none !important;
    padding: 0 !important;
    border: 0 !important;
    pointer-events: none !important;
  }

  .se-shimmer {
    width: 100%;
    height: 100%;
    background: #f4f6f8;
    background-image: linear-gradient(to right, #f4f6f8 0%, #dfe3e8 20%, #f4f6f8 40%, #f4f6f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;  

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
  }

  @-webkit-keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    
    100% {
      background-position: 468px 0; 
    }
  }


.se-icon-cn {
  display: flex;
  align-items: center;
}

.se-custom-scrollbar {
  overflow: auto !important;
}


.se-custom-scrollbar::-webkit-scrollbar {
  background-color: white;
  width: 6px;
}

.se-custom-scrollbar:hover::-webkit-scrollbar {
  visibility: visible !important;
}

.se-custom-scrollbar::-webkit-scrollbar {
  visibility: hidden;
} 
 
.se-custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius:8px;
  cursor:pointer;
}
  
.se-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color:transparent;
  cursor:pointer;
  border-radius:3px;
} 
 
.se-custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #ADBAC7;
  cursor:pointer;
  border-radius:3px;
}

.se-text-btn {
  display: flex;
  align-items: center;
  border: 0;
  padding: 0;
}

.rc-tooltip-inner{
  min-height: 22px !important;
  padding: 0 10px !important;
  color: #454F5B !important;
  text-align: left;
  text-decoration: none;
  background-color: #F4F6F8 !important;
  border-radius: 3px;
  border: 1px solid #F4F6F8 !important;
  box-shadow: 0px 3px 4px #00000029;
  }
  
  .rc-tooltip:not(.se-dim-tooltip) {
  
    .rc-tooltip-arrow{
      display: none;
    } 
  }

  .rc-tooltip.se-dimension-item-info {
    background: #fff !important;
    opacity: 1;
    .rc-tooltip-inner {
      max-width: 120px;
      color: #F38037 !important;
      background: #fff !important;
      padding: 10px !important;
      opacity: 1;
    }

    .rc-tooltip-arrow {
      display: block;
      top: 5%;
      border-left-color: #F4F6F8  
    }
  }

  .rc-tooltip.se-dimension-item-grouping-limit-tooltip.hide {
    display: none;
  }

  .se-cursor-not-allowed {
    cursor: not-allowed;
  }

  .rc-tooltip.se-restricted-field {
    .rc-tooltip-inner {
    color: #FFFFFF !important;
    background-color: #212B36 !important;
    padding: 10px 8px 10px 8px !important;  
    font-size: 13px !important;
    white-space: nowrap;
  }
  }

  .rc-tooltip.se-report-view-tooltip {
    word-wrap: break-word;
    max-width: 220px;
  }

  .rc-tooltip.se-breadcrumbs-tooltip {
    .rc-tooltip-inner {
    color: #FFFFFF !important;
    background-color: #212B36 !important;
    padding: 5px 8px 5px 8px !important;  
    font-size: 12px !important;
    white-space: nowrap;
    opacity: 1;
    }
  }

  .se-export-name-tooltip{
    opacity: 1;
    .rc-tooltip-inner {
      color: #FFFFFF !important;
      background-color: #212B36 !important;
      padding: 12px !important;  
      font-size: 13px !important;
      opacity: 1;
      max-width: 300px;
      word-wrap: break-word;
      display: block;
      border: none !important;
      border-radius: 4px;
      box-shadow: none !important;
    }
  }

  .hide-button-tooltip {
    display: none !important;
  }
  .show-button-tooltip{
    opacity: 1;
    .rc-tooltip-inner {
      color: #FFFFFF !important;
      background-color: #212B36 !important;
      padding: 10px 8px 10px 8px !important;  
      font-size: 13px !important;
      opacity: 1;
      box-shadow: none;
    }
  }

  .rc-tooltip.se-Viewindicator {
    .rc-tooltip-inner {
    color: #FFFFFF !important;
    background-color: #212B36 !important;
    padding: 10px 8px 10px 8px !important;  
    font-size: 13px !important;
    white-space: nowrap;
    border-radius: 4px;
    top:20px;
  }
  }
  .highcharts-exporting-group {
    display: none;
  }

  .rc-tooltip.se-grouping-tooltip {
    .rc-tooltip-inner {
    width: 268px;
    color: #FFFFFF !important;
    background-color: #212B36 !important;
    overflow-wrap: break-word;
    white-space: normal;
    padding: 10px 8px 10px 8px !important;
  }
  }
`;

export interface DefaultProps {
theme:Itheme
}
export interface Itheme {
  body:string,
  text: string,
  border:string,
  background: string,
  headerColor: string,
  filterLabelColor:string,
  datepickerColor: string,
  datePickerBorder: string,
  reactSelectBorderAcitve:string,
  reactSelectBackground: string,
  reactSelectActiveOption: string,
  backButtonBackground: string,
  backButtonColor: string,
  loaderColor:string,
  fullLoaderBar1:string,
  fullLoaderBar2:string,
  fullLoaderBar3:string,
  fullLoaderBar4:string,
  noDataBackground:string,
  noDataColor: string,
  paginationColor: string,
  paginationBorder:string,
  paginationRecordsSeparator:string,
  reportHeaderLabel:string,
  searchBorder:string,
  searchHoverBorder:string,
  searchBoxTextColor:string,
  searchButtonBackground:string,
  borderColor:string,
  navHeaderColor:string,
  sideNavBorderColor:string,
  collapsibleColor:string,
  paginationPrev:string,
  paginationNext:string,
  arrowIcon:string,
  onHoverBorder:string,
  reactOnOptionSelect: string,
  reactSelectScrollBar: string,
  rawDataHeader:string,
  rawDataButton:string,
  boxShadow:string,
  backButtonBorder:string,
  sideNavBarBorder:string,
  navSectionIcon: string,
  headerBackgroundColor:string,
  avtarColor:string,
  primaryColor: string,
  secondaryColor: string,
  dangerColor: string,
  expandListButtonColor: string,
  expandListButtonHoverColor: string,
  grayCounterBackgroundColor: string,
  paleSkyColor: string,
  infoIconColor: string,

}

export default GlobalStyle;
