import React, { useEffect, useRef, useState } from 'react';
import StyledContainer from './dropdown.style';
import { IProps } from './dropdown.types';

const DropDown = (props: IProps) => {
  const { options, mainSvg } = props;
  const viewMenuClickHandler = (event:React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setDropdownExpand(!dropdownExpand);
  };
  const [dropdownExpand, setDropdownExpand] = useState<boolean>(false);
  const [position, setPosition] = useState<string>('');

  const dropDownRef = useRef(null);
  const viewMenuRef = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (dropDownRef.current && !dropDownRef.current.contains(e.target)
      && viewMenuRef.current && !viewMenuRef.current.contains(e.target)) {
        setDropdownExpand(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [dropdownExpand]);

  const optionClickHandler = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setDropdownExpand(false);
    options[index].clickHandler(event);
  };

  useEffect(() => {
    if (dropDownRef && dropDownRef.current) {
      const reportViewCn = dropDownRef.current.parentElement.parentElement.parentElement.parentElement.parentElement;
      const dropDownTarget = dropDownRef.current.getBoundingClientRect();
      const parentTarget = reportViewCn.getBoundingClientRect();
      setPosition('');
      if (parentTarget.bottom < dropDownTarget.bottom) {
        setPosition('top');
      }
    }
  }, [dropdownExpand]);
  return (
    <StyledContainer
      position={Math.floor(dropDownRef?.current?.getBoundingClientRect()?.height - 4)}
    >
      <button
        className={`se-kebab-icon se-icon-cn ${dropdownExpand ? 'focus' : 'unfocus'}`}
        type="button"
        onClick={(event) => viewMenuClickHandler(event)}
        ref={viewMenuRef}
      >
        {mainSvg}
      </button>
      <div
        className={`se-dropdown-cn ${dropdownExpand && 'expand'} ${position ? 'top' : 'bottom'}`}
        ref={dropDownRef}
      >
        {options.map((option, index) => (
          <button
            type="button"
            key={option.label}
            className={`se-option-cn ${option.PendoClass} ${option.isDisabled && 'disabled'}`}
            onClick={(event) => optionClickHandler(event, index)}
          >
            {option.svg}
            <div className="se-option-label" style={{ color: option.textColor }}>
              {option.label}
            </div>
          </button>
        ))}
      </div>
    </StyledContainer>
  );
};

export default DropDown;
