import React from 'react';
import Tooltip from 'rc-tooltip';
import { useDispatch } from 'react-redux';
import StyledExportedReportInfo from 'components/feature/Report/ReportSidebar/ExportedReportInfo/exported-report-info.style';
import { IExportedReportInfoProps } from 'components/feature/Report/ReportSidebar/ExportedReportInfo/exported-report-info.d';
import { ExportStatusIcons } from 'core/utils/report.util';
import { retryExportRequest } from 'redux/report/actions';
import CsvIcon from 'components/common/svg/Csv/csv.svg';
import { ExpiryTimeText, ExportStatus } from 'core/constants/report';
import { getDuration } from 'core/utils/formatters.utility';
import { PendoClassNames } from '../common/constants';

const ExportedReportInfo = (props:IExportedReportInfoProps) => {
  const {
    exportID, filename, status, createdOn, downloadLink, expiresOn,
  } = props;

  const res = ExportStatusIcons[status];

  const dispatch = useDispatch();

  let expiryTimeText:string;
  // using this flag for when Successful report expires
  let expiredReportFlag = false;

  const checkForExpiry = (d1:any, d2:any) => {
    if (d1 >= d2) {
      expiredReportFlag = true;
      expiryTimeText = ExpiryTimeText.Expired;
    }
  };

  let remTime;
  if (status === ExportStatus.Success) {
    remTime = getDuration(new Date(), new Date(expiresOn), ' ');
    checkForExpiry(new Date(), new Date(expiresOn));
  }
  if (!expiryTimeText) {
    expiryTimeText = status === ExportStatus.Success ? res.expiryText.replace('{{expiry time}}', remTime) : res.expiryText;
  }

  const created = new Date(createdOn);
  created.setSeconds(0);
  const createdOnTime = created.toLocaleTimeString('en-US', {
    hour12: true, hour: 'numeric', minute: 'numeric',
  }); // hh:mm AM/PM

  return (
    <StyledExportedReportInfo>
      <div className="se-exported-report-info-cont">
        <div className="se-format-type-icon">
          <CsvIcon
            width={20}
            height={20}
            fill="#A456BA"
          />
        </div>
        <div className={`se-info-container ${expiredReportFlag ? 'se-extend-width' : ''}`}>
          <Tooltip
            mouseEnterDelay={1.5}
            placement="bottomRight"
            overlay={filename}
            overlayClassName="se-export-name-tooltip"
          >
            <div className={`se-report-name ${expiredReportFlag ? 'se-extend-width' : ''}`}>
              {filename}
            </div>
          </Tooltip>
          <div className="se-report-timestamp">
            <span className="se-showTime">
              {`${createdOnTime}`}
              <span className="se-showTime-bar">|</span>
            </span>
            <span className={`${status === ExportStatus.InProgress || status === ExportStatus.InQueue ? 'se-highlight' : ''} ${status === ExportStatus.Failed ? 'se-failed' : ''}`}>
              {expiryTimeText}
            </span>
          </div>
        </div>
        {
         status === ExportStatus.Failed
           ? (
             // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
             <div className="se-report-failed" onClick={() => dispatch(retryExportRequest(exportID))}>
               <res.icon
                 width={20}
                 height={20}
               />
             </div>
           )
           : (
             <a
               href={downloadLink}
               className={`se-report-status ${expiredReportFlag ? 'hideIcon' : 'active'} ${!expiredReportFlag && status === ExportStatus.Success ? PendoClassNames.DownloadFile : ''}`}
               download
             >
               <res.icon
                 width={20}
                 height={20}
               />
             </a>
           )
        }
      </div>
    </StyledExportedReportInfo>
  );
};

export default ExportedReportInfo;
