import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import * as Actions from 'redux/report/actions';
import { ReduxModel } from 'core/models';
import { IDimension } from 'core/models/report-redux';
import {
  IColumnGroupingProps,
} from 'components/feature/Report/ReportSidebar/Settings/Dimensions/ColumnGrouping/column-grouping.d';
import { DimensionMode, DimensionStatus } from 'core/constants/report';
import { DroppableIds, DroppableTypes } from 'components/feature/Report/ReportSidebar/common/constants';
import StyledContainer from 'components/feature/Report/ReportSidebar/Settings/Dimensions/ColumnGrouping/column-grouping.style';
import DraggableItem from 'components/feature/Report/ReportSidebar/common/DraggableItem/draggable-item.component';
import Placeholder from 'components/feature/Report/ReportSidebar/common/Placeholder/placeholder.component';
import { displayName } from 'components/feature/Report/ReportSidebar/common/helpers';
import { getFieldIdentifier, getTextTooltipContentForDimension } from 'core/utils/report.util';

const ColumnGrouping = (props: IColumnGroupingProps) => {
  const {
    selectedDimension, dimensionState, placeholderProps,
    isMaxColGroupingReached, replaceIndex, newAdditionIndex,
  } = props;
  const [
    appliedDimensions,
  ] = useSelector((state: ReduxModel.IGlobalState) => [
    state.report.appliedDimensions, state.report.filterConfig,
  ] as const);
  const dispatch = useDispatch();

  // Column Grouping only shows grouped dimensions. Filter them out but store their original indexes.
  // Original indexes are needed for setRowGrouping and reorderGrouping.
  const groupedDimensions = appliedDimensions
    .map((dim, index) => ({ ...dim, originalIndex: index } as iDimensionWithIndex))
    .filter((dim) => dim.DimensionMode !== DimensionMode.RowGroup && dim.Applied !== DimensionStatus.NotApplied);
  const showPlaceholder = !isMaxColGroupingReached;

  // dispatchers
  const removeColumnGrouping = (index: number) => dispatch(Actions.setGrouping(index, DimensionStatus.NotApplied, DimensionMode.ColumnGroup));

  return (
    <Droppable
      droppableId={DroppableIds.ColumnGrouping}
      type={DroppableTypes.Dimensions}
      isCombineEnabled
    >
      {(droppableProvided) => (
        <StyledContainer
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
        >
          {
            groupedDimensions
              .map((dim, index) => {
                const closeButtonDisabled = dim.Applied === DimensionStatus.Mandatory;

                const name = displayName(dim);
                const key = getFieldIdentifier(dim);
                return (
                  <DraggableItem
                    key={key}
                    draggableId={key}
                    text={name}
                    data={dim.originalIndex}
                    state={key === selectedDimension && dimensionState}
                    index={index}
                    showCloseButton
                    isCloseButtonDisabled={closeButtonDisabled}
                    infoOverlayClassName="se-dimension-item-info"
                    onClickClose={removeColumnGrouping}
                    textTooltipContent={getTextTooltipContentForDimension(dim)}
                    borderClassName={replaceIndex === dim.originalIndex ? 'se-replace-blue-border' : ''}
                    entity={dim.BuilderConfig && dim.BuilderConfig.Entity}
                    addBorderClass={newAdditionIndex === dim.originalIndex ? 'se-add-green-border' : ''}
                    binningType={dim.DimensionProp ? dim.DimensionProp?.Props?.BinningType : ''}
                  />
                );
              })
          }
          {droppableProvided.placeholder}
          {
            showPlaceholder
            && (
              <Placeholder {...placeholderProps} />
            )
          }
        </StyledContainer>
      )}
    </Droppable>
  );
};

// A temporary interface to store original indexes of dimensions
interface iDimensionWithIndex extends IDimension {
  originalIndex: number;
}

export default ColumnGrouping;
