import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const StarsIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="auto_awesome_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 19 19"
  >
    <g
      id="Group_1379"
      data-name="Group 1379"
    >
      <rect
        id="Rectangle_972"
        data-name="Rectangle 972"
        width="19"
        height="19"
        fill="none"
      />
    </g>
    <g
      id="Group_1381"
      data-name="Group 1381"
      transform="translate(0.773 0.773)"
    >
      <g
        id="Group_1380"
        data-name="Group 1380"
      >
        <path
          id="Path_859"
          data-name="Path 859"
          d="M18.092,7.184l.966-2.126,2.126-.966-2.126-.966L18.092,1l-.966,2.126L15,4.092l2.126.966Z"
          transform="translate(-4.178 -1)"
          fill={fill}
        />
        <path
          id="Path_860"
          data-name="Path 860"
          d="M18.092,15l-.966,2.126L15,18.092l2.126.966.966,2.126.966-2.126,2.126-.966-2.126-.966Z"
          transform="translate(-4.178 -4.178)"
          fill={fill}
        />
        <path
          id="Path_861"
          data-name="Path 861"
          d="M9.117,8.252,7.184,4,5.252,8.252,1,10.184l4.252,1.933,1.933,4.252,1.933-4.252,4.252-1.933Zm-1.167,2.7-.765,1.685-.765-1.685-1.685-.765,1.685-.765.765-1.685.765,1.685,1.685.765Z"
          transform="translate(-1 -1.681)"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

StarsIcon.defaultProps = {
  fill: '#3bb345',
};

export default StarsIcon;
