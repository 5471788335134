/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ReportListResponseModel } from 'core/models';
import { IProps } from 'components/common/SideNavbar/SideNavbarSection/SideNavCategories/side-nav-categories.d';
import CategoryIcons from 'components/feature/ReportListing/CategoryIcons/category-icons';

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SideNavCategories = (props : IProps) => {
  const {
    section, reorderCategories, history, location,
  } = props;

  const [items, setItems] = useState(section.Categories);
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items1 = reorder(
      items,
      result.source.index,
      result.destination.index,
    ) as Array<ReportListResponseModel.ICategory>;
    reorderCategories(items1);
    setItems(items1);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: any) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="se-side-nav-cateogries"
          >
            {items.map((item: ReportListResponseModel.ICategory, index: any) => (
              <Draggable key={item.CategoryId} draggableId={item.CategoryId} index={index}>
                {(draggableProvided: any) => {
                  const isSelected = location.hash === `#${section.SectionId}/category/${item.CategoryId}`;
                  return (
                    <div
                      key={item.CategoryId}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      className={`se-side-nav-cateogries-item ${isSelected ? 'active' : ''}`}
                      role="button"
                      onClick={() => {
                        history.push({
                          pathname: section.Path,
                          hash: `#${section.SectionId}/category/${item.CategoryId}`,
                        });
                      }}
                      tabIndex="0"
                    >
                      <CategoryIcons
                        width={20}
                        height={20}
                        name={item.Icon}
                        color={item.IconColor}
                      />
                      <span
                        className="se-side-nav-sub-type-label"
                      >
                        {item.DisplayName}
                      </span>
                    </div>
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SideNavCategories;
