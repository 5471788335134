import styled from 'styled-components';

export default styled.div`
flex: 1;
width: 100%;
height: 100%;
overflow: hidden;

.se-report-settings {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.se-apply-settings{
    border-top: 0.3px solid #DFE3E8;
}


  .se-dimensions-settings {
    height: 100%;
    padding: 16px;
    background: #FFF;
    box-sizing: border-box;
    position: relative;
    
    .se-dimensions-settings-item {
      margin-bottom: 20px;
      :last-child {
        margin-bottom: 0;
      }
    }

    [data-rbd-droppable-id="RowGrouping"], [data-rbd-droppable-id="ColumnGrouping"], [data-rbd-droppable-id="DrilldownGrouping"]  {
      position: relative;
    }

    .se-grouping-counter {
      font-size: 11px;
      color: var(--paginationColor);

      &.danger {
        color: #D1331B;
      }
    }
  }

`;

export const StyledSettingsSectionContainer = styled.div`
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0;
  }
`;
