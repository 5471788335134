import styled from 'styled-components';

const StyledComponent = styled.div`
.se-cs-select-cn {
    position: relative;
    width: 120px;

    button {
        width: 100%;
        display: block;
        border: 0;
        padding: 0;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .se-cs-select-menu {
        padding: 4px 0;
        width: 100%;
        max-height: 130px;
        position: absolute;
        top: 100%;
        right: 0;
        border-radius: 2px;
        background: #fff;
        z-index: 1;
        box-shadow: 0px 2px 4px #212B3633;

        .se-cs-select-option {
            padding: 7px 8px;

            :hover {
                background: #F4F6F8;
            }
        }
    }
}
`;

export default StyledComponent;
