import {
  clone, cloneDeep, isEmpty, sortBy,
} from 'lodash';
import { defaultPageValue, PaginationKey } from 'core/constants/pagination';
import { FilterType, UserFilterPayloadType } from 'core/constants/filter';
import { APIRequestStatus } from 'core/constants/redux';
import { ReportActionTypes } from 'core/constants/redux-action-types';
import {
  AuthReduxModel, FilterModel, ObjModel, ReportReduxModel, ReportResponseModel,
} from 'core/models';
import { getReportViewDimensionStr, isMeasure, isMeasureAndApplied } from 'core/utils/report.util';
import { dateRangeProcessor, isEqualDateRangePayload } from 'core/utils/date.util';
import FilterFetchUtility from 'core/utils/filter-fetch-utility';
import {
  ColumnModes,
  dailyQuotaApiResponse,
  DimensionIdentifier,
  DimensionMode,
  DimensionStatus,
  ExportStatus,
  jobInfoTextLoad,
  jobInfoTextMerge,
  JobTypes,
  ReportViewTypes,
  ZeroAnchorStatus,
} from 'core/constants/report';
import { SortingOrderTypes } from 'core/constants/sorting';
import { IDimension } from 'core/models/report-redux';
import { IColumn, IDataRefresh, IJob } from 'core/models/report-response';
import { DateBinningFormatters } from 'core/constants/formatters';
import moment from 'moment';
import { ReportViewState } from 'redux/report-view/report-view.state';

// initialState: defines the initial state of the report reducer, the state of
// report reducer when app loads for first time.
export const initialState: ReportReduxModel.IState = {
  requestProcessing: {
    [ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST]: APIRequestStatus.Processing,
    [ReportActionTypes.FILTER_LOAD_REQUEST]: {},
    [ReportActionTypes.REPORT_DATA_LOAD_REQUEST]: APIRequestStatus.Processing,
    [ReportActionTypes.SAVED_STATES_LOAD_REQUEST]: APIRequestStatus.Success,
    [ReportActionTypes.CREATE_SAVED_STATE_REQUEST]: APIRequestStatus.Success,
    [ReportActionTypes.DELETE_SAVED_STATE_REQUEST]: APIRequestStatus.Success,
    [ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST]: APIRequestStatus.Processing,
    [ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST]: APIRequestStatus.Processing,
    [ReportActionTypes.EXPORT_DATA_LOAD_REQUEST]: APIRequestStatus.Success,
    [ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_REQUEST]: APIRequestStatus.Success,
    [ReportActionTypes.EXPORT_LIST_LOAD_REQUEST]: APIRequestStatus.Success,
    [ReportActionTypes.EXPORT_USAGE_STATUS_REQUEST]: APIRequestStatus.Success,
    [ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST]: APIRequestStatus.Success,
  },
  reportId: null,
  reportConfig: null,
  filterConfig: [],
  appliedFilters: {},
  sort: [],
  isMultiSort: false,
  page: {
    PageIndex: 1,
    PageSize: 10,
  },
  reportData: null,
  prevAppliedFilters: [],
  activeFilters: {},
  useChangedFilters: true,
  drilldownPoint: null,
  selectedToolbarMenuItem: -1,
  columns: null, // active columns
  appliedDimensions: null,
  activeDimensions: null,
  allMeasures: null,
  dynamicDimensions: null,
  activeDimensionsLength: 0,
  isMultiSortInActiveDimension: false,
  zeroValuesEnabled: false,
  autodrilldownData: null,
  exportData: null,
  exportListData: null,
  pivotBinningType: '',
  triggerExport: '',
  isDailyLimitReached: false,
  totalDailyExports: 0,
  exportsConsumed: 0,
  jobObject: {
    headerName: '',
    jobsArray: [],
  },
  defaultView: null,
  isFirstLoad: true,
};

// report reducer: function takes current state and action and reduce the current state
// based on action and return  that reduced state which is new state for global store.
export default (state = cloneDeep(initialState), action: ReportReduxModel.IAction) => {
  switch (action.type) {
    case ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST: {
      const {
        reportId,
      } = action.payload as ReportReduxModel.IReportConfigLoadRequestPayload;
      return getStateOnReportConfigLoadRequest(reportId);
    }

    case ReportActionTypes.REPORT_CONFIG_LOAD_SUCCESS: {
      const {
        reportConfig, variables,
      } = action.payload as ReportReduxModel.IReportConfigLoadSuccessPayload;
      return getStateOnReportConfigLoadSuccess(
        state,
        reportConfig,
        variables,
      );
    }

    case ReportActionTypes.REPORT_CONFIG_LOAD_FAILURE: {
      return getStateOnReportConfigLoadFailure(state);
    }

    case ReportActionTypes.FILTER_LOAD_REQUEST: {
      const {
        filterId, variables, isLazyLoad,
      } = action.payload as ReportReduxModel.IFilterLoadRequestPayload;
      return getStateOnFilterLoadRequest(
        state,
        filterId,
        variables,
        isLazyLoad,
      );
    }

    case ReportActionTypes.FILTER_LOAD_SUCCESS: {
      const {
        filterId, filterResponse,
      } = action.payload as ReportReduxModel.IFilterLoadSuccessPayload;
      return getStateOnFilterLoadSuccess(
        state,
        filterId,
        filterResponse,
      );
    }

    case ReportActionTypes.FILTER_LOAD_FAILURE: {
      const {
        filterId,
      } = action.payload as ReportReduxModel.IFilterLoadFailurePayload;
      return getStateOnFilterLoadFailure(
        state,
        filterId,
      );
    }

    case ReportActionTypes.REPORT_DATA_LOAD_REQUEST: {
      return getStateOnReportDataLoadRequest(state);
    }

    case ReportActionTypes.REPORT_DATA_LOAD_SUCCESS: {
      const {
        reportData,
      } = action.payload as ReportReduxModel.IReportDataLoadSuccessPayload;
      return getStateOnReportDataLoadSuccess(
        state,
        reportData,
      );
    }

    case ReportActionTypes.REPORT_DATA_LOAD_FAILURE: {
      return getStateOnReportDataLoadFailure(state);
    }

    case ReportActionTypes.FILTER_CHANGE: {
      const {
        filterId, filterValue, variables, useChangedFilters,
      } = action.payload as ReportReduxModel.IFilterChangePayload;
      return getStateOnFilterChange(
        state,
        filterId,
        filterValue,
        variables,
        useChangedFilters,
      );
    }

    case ReportActionTypes.PAGINATION_CHANGE: {
      const {
        paginationKey, paginationValue, isAutodrilldown,
      } = action.payload as ReportReduxModel.IPaginationChangePayload;
      return getStateOnPaginationChange(
        state,
        paginationKey,
        paginationValue,
        isAutodrilldown,
      );
    }

    case ReportActionTypes.SORTING_CHANGE: {
      const {
        sortingField, sortingOrder, isMultiSort, isUnderlyingTable, isMultiSortInActiveDimension,
      } = action.payload as ReportReduxModel.ISortingChangePayload;
      return getStateOnSortingChange(
        state,
        sortingField,
        sortingOrder,
        isMultiSort,
        isUnderlyingTable,
        isMultiSortInActiveDimension,
      );
    }

    case ReportActionTypes.DRILLDOWN_CHANGE: {
      const {
        filterId, drilldownValue, user,
      } = action.payload as ReportReduxModel.IDrilldownChangePayload;
      return getStateOnDrilldownChange(
        state,
        filterId,
        drilldownValue,
        user,
      );
    }

    case ReportActionTypes.REVERSE_DRILLDOWN: {
      const {
        index,
      } = action.payload as ReportReduxModel.IReverseDrilldownPayload;
      return getStateOnReverseDrilldown(state, index);
    }

    case ReportActionTypes.APPLY_FILTERS: {
      return getStateOnApplyingFilters(state);
    }

    case ReportActionTypes.CLEAR_FILTERS: {
      const {
        variables,
      } = action.payload as ReportReduxModel.IClearFiltersPayload;
      return getStateOnClearFilters(state, variables);
    }

    case ReportActionTypes.APPLY_REPORT_VIEW: {
      const {
        reportState, variables, reportViewState, urlViewType,
      } = action.payload as ReportReduxModel.IApplyReportViewPayload;
      return getStateOnApplyReportView(
        reportState,
        variables,
        reportViewState,
        urlViewType,
      );
    }

    case ReportActionTypes.SET_TOOLBAR_MENU_ITEM: {
      const {
        index,
      } = action.payload as ReportReduxModel.ISelectedToolbarMenuItemPayload;
      return getStateOnSetToolbarMenuItem(
        state,
        index,
      );
    }

    case ReportActionTypes.SET_GROUPING: {
      const {
        sourceIndex, status, mode, destinationIndex, isSwapEnabled,
      } = action.payload as ReportReduxModel.ISetGroupingPayload;
      return getStateOnSetGrouping(
        state,
        sourceIndex,
        status,
        mode,
        destinationIndex,
        isSwapEnabled,
      );
    }

    case ReportActionTypes.SET_ZERO_ANCHOR: {
      const {
        index,
      } = action.payload as ReportReduxModel.ISetZeroAnchorPayload;
      return getStateOnSetZeroAnchor(
        state,
        index,
      );
    }

    case ReportActionTypes.APPLY_DIMENSIONS: {
      return getStateOnApplyDimensions(state);
    }

    case ReportActionTypes.REORDER_COLUMNS: {
      const {
        columns, isAutodrilldown,
      } = action.payload as ReportReduxModel.IReorderColumnsPayload;
      return getStateOnReorderColumns(
        state,
        columns,
        isAutodrilldown,
      );
    }

    case ReportActionTypes.SET_MEASURE_STATE: {
      const {
        sourceIndex, status,
      } = action.payload as ReportReduxModel.ISetMeasureStatePayload;
      return getStateOnMeasureSet(
        state,
        sourceIndex,
        status,
      );
    }

    case ReportActionTypes.REORDER_MEASURE: {
      const {
        sourceIndex, destinationIndex,
      } = action.payload as ReportReduxModel.IReorderMeasurePayload;
      return getStateOnMeasureReorder(
        state,
        sourceIndex,
        destinationIndex,
      );
    }

    case ReportActionTypes.TOGGLE_ZERO_ANCHORS: {
      return getStateOnToggleZeroAnchor(state);
    }

    case ReportActionTypes.SEARCH_DROPDOWN_OPTIONS: {
      const {
        filterId, searchKey,
      } = action.payload as ReportReduxModel.ISearchDropdownOptionsPayload;
      return getStateOnSearchDropdownOptions(
        state,
        filterId,
        searchKey,
      );
    }

    case ReportActionTypes.LOAD_MORE_DROPDOWN_OPTIONS: {
      const {
        filterId,
      } = action.payload as ReportReduxModel.ILoadMoreDropdownOptionsPayload;
      return getStateOnLoadMoreDropdownOptions(
        state,
        filterId,
      );
    }

    case ReportActionTypes.ADD_DYNAMIC_FILTER: {
      const {
        index,
      } = action.payload as ReportReduxModel.IAddDynamicFilterPayload;
      return getStateOnAddDynamicFilter(
        state,
        index,
      );
    }

    case ReportActionTypes.DELETE_DYNAMIC_FILTER: {
      const {
        filterId,
      } = action.payload as ReportReduxModel.IDeleteDynamicFilterPayload;
      return getStateOnDeleteDynamicFilter(
        state,
        filterId,
      );
    }

    case ReportActionTypes.AUTODRILLDOWN: {
      const {
        selectedMeasure, selectedRowData,
      } = action.payload as ReportReduxModel.IAutoDrilldownPayload;
      return getStateOnAutoDrilldown(
        state,
        selectedMeasure,
        selectedRowData,
      );
    }

    case ReportActionTypes.RESET: {
      return cloneDeep(initialState);
    }

    case ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST: {
      return getDynamicDimensionOnDynamicDimensionLoadRequest(state);
    }

    case ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_SUCCESS: {
      const {
        dynamicDimensions, selectedMeasure,
      } = action.payload as ReportReduxModel.IDynamicDimensionsLoadSuccessPayload;
      return getDynamicDimensionOnDynamicDimensionLoadSuccess(
        state,
        dynamicDimensions,
        selectedMeasure,
      );
    }

    case ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_FAILURE: {
      return getDynamicDimensionOnDynamicDimensionLoadFailure(state);
    }

    case ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST: {
      return getStateOnAutodrilldownReportDataLoadRequest(state);
    }

    case ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_SUCCESS: {
      const {
        reportData,
      } = action.payload as ReportReduxModel.IAutoDrilldownReportDataLoadSuccessPayload;
      return getStateOnAutodrilldownReportDataLoadSuccess(
        state,
        reportData,
      );
    }

    case ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_FAILURE: {
      return getStateOnAutodrilldownReportDataLoadFailure(state);
    }

    case ReportActionTypes.EXPORT_DATA_LOAD_REQUEST: {
      return getStateOnExportDataLoadRequest(state);
    }

    case ReportActionTypes.EXPORT_DATA_LOAD_SUCCESS: {
      const {
        exportData,
      } = action.payload as ReportReduxModel.IExportDataLoadSuccessPayload;
      return getStateOnExportDataLoadSuccess(
        state,
        exportData,
      );
    }

    case ReportActionTypes.EXPORT_DATA_LOAD_FAILURE: {
      const {
        failureResponse,
      } = action.payload as ReportReduxModel.IExportDataLoadFailurePayload;
      return getStateOnExportDataLoadFailure(
        state,
        failureResponse,
      );
    }

    case ReportActionTypes.EXPORT_LIST_LOAD_REQUEST: {
      return getStateOnExportListLoadRequest(state);
    }

    case ReportActionTypes.EXPORT_USAGE_STATUS_REQUEST: {
      return getStateExportUsageStatusRequest(state);
    }

    case ReportActionTypes.EXPORT_USAGE_STATUS_SUCCESS: {
      const exportUsageData = action.payload as ReportResponseModel.IExportUsageData;
      return getStateOnExportUsageStatusSuccess(
        state,
        exportUsageData,
      );
    }

    case ReportActionTypes.EXPORT_USAGE_STATUS_FAILURE: {
      return getStateOnExportUsageStatusFailure(state);
    }

    case ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_REQUEST: {
      return getStateOnExportListLoadByIdsRequest(state);
    }

    case ReportActionTypes.EXPORT_LIST_LOAD_SUCCESS: {
      const {
        exportListData,
      } = action.payload as ReportReduxModel.IExportListLoadSuccessPayload;
      return getStateOnExportListLoadSuccess(
        state,
        exportListData,
      );
    }

    case ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_SUCCESS: {
      const {
        exportListData,
      } = action.payload as ReportReduxModel.IExportListLoadSuccessPayload;
      return getStateOnExportListLoadByIdsSuccess(
        state,
        exportListData,
      );
    }

    case ReportActionTypes.EXPORT_LIST_LOAD_FAILURE: {
      return getStateOnExportListLoadFailure(state);
    }

    case ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_FAILURE: {
      return getStateOnExportListLoadByIdsFailure(state);
    }

    case ReportActionTypes.SET_BINNING: {
      const { index, value } = action.payload as ReportReduxModel.ISetBinningPayload;
      return getStateOnSetBinning(state, index, value);
    }

    case ReportActionTypes.SET_GROUPING_DRILLDOWN: {
      const {
        sourceIndex, status, destinationIndex, isSwapEnabled,
      } = action.payload as ReportReduxModel.ISetGroupingDrilldownPayload;
      return getStateOnSetGroupingDrilldown(
        state,
        sourceIndex,
        status,
        destinationIndex,
        isSwapEnabled,
      );
    }

    case ReportActionTypes.RETRY_EXPORT_REQUEST: {
      const {
        exportID,
      } = action.payload as ReportReduxModel.IRetryExportRequestPayload;
      return getStateOnRetryExportRequest(
        state,
        exportID,
      );
    }

    case ReportActionTypes.RETRY_EXPORT_SUCCESS: {
      const {
        isInProgress, exportID,
      } = action.payload as ReportReduxModel.IRetryExportSuccessPayload;
      return getStateOnRetryExportSuccess(
        state,
        exportID,
        isInProgress,
      );
    }

    case ReportActionTypes.RETRY_EXPORT_FAILURE: {
      const {
        exportID,
      } = action.payload as ReportReduxModel.IRetryExportFailurePayload;
      return getStateOnRetryExportFailure(
        state,
        exportID,
      );
    }

    case ReportActionTypes.UPDATE_TRIGGER_EXPORT: {
      const {
        exportFormatType,
      } = action.payload as ReportReduxModel.IUpdateTriggerExportPayload;
      return getStateOnUpdateTriggerExport(
        state,
        exportFormatType,
      );
    }

    case ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST: {
      return dataRefreshDetailsLoadRequest(state);
    }

    case ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_SUCCESS: {
      const { jobDetails, isPolling } = action.payload as ReportReduxModel.IDataRefreshDetailsLoadSuccessPayload;
      return dataRefreshDetailsLoadSuccess(state, jobDetails, isPolling);
    }

    case ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_FAILURE: {
      return dataRefreshDetailsLoadFailure(state);
    }

    default:
      return state;
  }
};

const getStateOnReportConfigLoadRequest = (reportId: string) => {
  const newState = cloneDeep(initialState);
  newState.reportId = reportId;
  return newState;
};

const getStateOnReportConfigLoadSuccess = (
  state: ReportReduxModel.IState, reportConfig: ReportResponseModel.IReportConfig,
  variables: AuthReduxModel.IVariables,
) => {
  const newState = clone(state);
  const fetchFilter = new FilterFetchUtility(variables);
  let newAppliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse> = {};
  newState.reportConfig = reportConfig;

  let dimensions = [] as Array<ReportReduxModel.IDimension>;
  newState.allMeasures = reOrderMeasures(reportConfig.Columns.filter((c) => isMeasure(c)));
  newState.columns = reportConfig.Columns as Array<ReportResponseModel.IColumn>;
  // logic to remove duplicate columns which has both Name and ReferTo props same
  const seen = new Set();
  newState.columns.forEach((item) => {
    if (item.Props.Dimension) {
      const key = `${item.Name}---${item.Props.ReferTo}`;
      if (!seen.has(key)) {
        seen.add(key);
        const dimension = {
          Name: item.Name,
          ReferTo: item.Props.ReferTo,
          ...item.Props.Dimension,
          BuilderConfig: item.BuilderConfig,
          IsMasked: item.Props.IsMasked,
        };
        if (dimension.ZeroAnchor !== ZeroAnchorStatus.Disabled) {
          dimension.ZeroAnchor = ZeroAnchorStatus.Enabled; // un-apply zero values by default
        }
        dimensions.push(dimension);
      }
    }
  });

  // re-order dimensions to keep applied row & column groupings at the top for easy drag and drop handling
  const rowGroupingDimensions = dimensions.filter((dim) => (
    dim.DimensionMode !== DimensionMode.ColumnGroup && dim.Applied !== DimensionStatus.NotApplied
  ));
  const colGroupingDimensions = dimensions.filter((dim) => (
    dim.DimensionMode !== DimensionMode.RowGroup && dim.Applied !== DimensionStatus.NotApplied
  ));
  const availableDimensions = dimensions.filter((dim) => dim.Applied === DimensionStatus.NotApplied);
  dimensions = [...rowGroupingDimensions, ...colGroupingDimensions, ...availableDimensions];

  newState.pivotBinningType = colGroupingDimensions.find((dim) => dim.DimensionProp && dim.DimensionProp)?.DimensionProp?.Props?.BinningType;

  if (!isEmpty(dimensions)) {
    newState.appliedDimensions = dimensions;
    newState.activeDimensions = dimensions;
    newState.columns = getColumnsForActiveDimensions(reportConfig.Columns, dimensions, newState.allMeasures);
  }

  if (reportConfig.Filters && !isEmpty(reportConfig.Filters)) {
    reportConfig.Filters.forEach((item) => {
      let value = null;
      if (item.Type === FilterType.UserMultiSelect) {
        if (reportConfig.FiltersMetadata?.UserDropdownSelectType && item.DefaultValue) {
          value = {
            Type: reportConfig.FiltersMetadata.UserDropdownSelectType,
            UserIds: item.DefaultValue,
          };
        }
      } else {
        value = item.DefaultValue;
      }
      newAppliedFilters[item.ID] = fetchFilter.GetDefaultSingleFilter(
        item,
        value,
      );
    });
    newAppliedFilters = getFiltersForActiveDimensions(
      reportConfig.Filters,
      newState.activeDimensions,
      newAppliedFilters,
    );
    newState.filterConfig = reportConfig.Filters;
    newState.appliedFilters = newAppliedFilters;
    newState.activeDimensionsLength = getAppliedDimension(newState.appliedDimensions);

    newState.selectedToolbarMenuItem = 0;
  }

  if (reportConfig.DefaultSort && !isEmpty(reportConfig.DefaultSort)) {
    newState.sort = reportConfig.DefaultSort;
    newState.isMultiSort = reportConfig.DefaultSort.length > 1;
    if (newState.appliedDimensions && getAppliedDimension(newState.appliedDimensions) > 1) {
      newState.sort = getDefaultSortForRowGrouping(reportConfig.DefaultSort, newState.appliedDimensions);
      newState.isMultiSort = true;
    }
  }

  if (reportConfig.DefaultPage) {
    newState.page = reportConfig.DefaultPage;
  }
  newState.requestProcessing[ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST] = APIRequestStatus.Success;
  newState.defaultView = reportConfig.DefaultView;
  return newState;
};

const getStateOnReportConfigLoadFailure = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  newState.requestProcessing[ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST] = APIRequestStatus.Failure;
  return newState;
};

const getStateOnFilterLoadRequest = (
  state: ReportReduxModel.IState, filterId: string, variables: AuthReduxModel.IVariables,
  isLazyLoad?: boolean,
) => {
  const newState = clone(state);
  const filtersLoading = cloneDeep(newState.requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST]);
  if (isLazyLoad) {
    filtersLoading[filterId] = APIRequestStatus.LazyLoading;
    newState.requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST] = filtersLoading;
  } else {
    const fetchFilter = new FilterFetchUtility(variables);
    let appliedFilters = cloneDeep(newState.appliedFilters);
    filtersLoading[filterId] = APIRequestStatus.Processing;
    newState.requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST] = filtersLoading;
    if (newState.filterConfig && !isEmpty(newState.filterConfig)) {
      newState.filterConfig.forEach((item) => {
        if (item.LinkedTo && item.LinkedTo === filterId) {
          if (appliedFilters[item.ID] && appliedFilters[item.ID].shouldLoad) {
            return;
          }
          appliedFilters[item.ID] = fetchFilter.GetDefaultSingleFilter(item, null);
        }
      });
    }
    appliedFilters = getFiltersForActiveDimensions(
      newState.filterConfig,
      newState.activeDimensions,
      appliedFilters,
    );
    newState.appliedFilters = appliedFilters;
  }
  return newState;
};

const getStateOnFilterLoadSuccess = (
  state: ReportReduxModel.IState, filterId: string, filterResponse: FilterModel.IFilterResponse,
) => {
  const newState = clone(state);
  const filtersLoading = clone(newState.requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST]);
  const newAppliedFilters = cloneDeep(newState.appliedFilters);
  filtersLoading[filterId] = APIRequestStatus.Success;
  newAppliedFilters[filterId] = filterResponse;
  newState.appliedFilters = newAppliedFilters;
  newState.requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST] = filtersLoading;
  return newState;
};

const getStateOnFilterLoadFailure = (
  state: ReportReduxModel.IState, filterId: string,
) => {
  const newState = clone(state);
  const filtersLoading = cloneDeep(newState.requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST]);
  filtersLoading[filterId] = APIRequestStatus.Failure;
  newState.requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST] = filtersLoading;
  newState.requestProcessing[ReportActionTypes.REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Success;
  return newState;
};

const getStateOnReportDataLoadRequest = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  newState.requestProcessing[ReportActionTypes.REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Processing;
  return newState;
};

export const getStateOnReportDataLoadSuccess = (
  state: ReportReduxModel.IState, reportData: ReportResponseModel.IReportData,
) => {
  const newState = clone(state);
  newState.reportData = reportData;
  newState.columns = getColumnsForActiveDimensions(newState.columns, newState.activeDimensions, newState.allMeasures);
  newState.requestProcessing[ReportActionTypes.REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Success;
  newState.isFirstLoad = false;
  return newState;
};

export const getStateOnReportDataLoadFailure = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  newState.requestProcessing[ReportActionTypes.REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Failure;
  return newState;
};

export const getStateOnFilterChange = (
  state: ReportReduxModel.IState, filterId: string, filterValue: any, variables: AuthReduxModel.IVariables,
  useChangedFilters: boolean = false,
) => {
  const newState = clone(state);
  const fetchFilter = new FilterFetchUtility(variables);
  let newAppliedFilters = cloneDeep(newState.appliedFilters);
  if (newState.filterConfig && !isEmpty(newState.filterConfig)) {
    newState.filterConfig.forEach((item) => {
      if (item.ID === filterId && newAppliedFilters[item.ID]) {
        newAppliedFilters[filterId].FilterResponse = filterValue;
      } else if (item.LinkedTo && item.LinkedTo === filterId) {
        newAppliedFilters[item.ID] = fetchFilter.GetDefaultSingleFilter(item, null);
      }
    });
  }
  newAppliedFilters = getFiltersForActiveDimensions(
    newState.filterConfig,
    newState.activeDimensions,
    newAppliedFilters,
  );
  newState.appliedFilters = newAppliedFilters;
  newState.useChangedFilters = useChangedFilters;
  return newState;
};

const getStateOnPaginationChange = (
  state: ReportReduxModel.IState, paginationKey: string, paginationValue: number,
  isAutodrilldown?: boolean,
) => {
  const newState = clone(state);
  let newPage = clone(newState.page);
  if (isAutodrilldown) {
    newPage = clone(newState.autodrilldownData.page);
  }
  if (paginationKey === PaginationKey.PageSize) {
    newPage.PageIndex = 1;
    newPage.PageSize = paginationValue;
  } else {
    newPage.PageIndex = paginationValue;
  }
  if (isAutodrilldown) {
    const newAutodrilldownData = clone(newState.autodrilldownData);
    newAutodrilldownData.page = newPage;
    newState.autodrilldownData = newAutodrilldownData;
  } else {
    newState.page = newPage;
  }
  return newState;
};

const getStateOnSortingChange = (
  state: ReportReduxModel.IState, sortingField: string, sortingOrder: string, isMultiSort: boolean,
  isUnderlyingTable: boolean, isMultiSortInActiveDimension: boolean,
) => {
  const newState = clone(state);
  const enableMultiSort = isMultiSort;

  let newSort = cloneDeep(newState.sort);
  if (newState.reportConfig.IsAugmented) {
    if (enableMultiSort) {
      newSort = newSort.filter((item) => {
        const index = newState.activeDimensions.findIndex((dim) => (dim.ReferTo || dim.Name) === item.Field);
        if (index !== -1) {
          return true;
        }
        return false;
      });
    }
  }

  newSort = enableMultiSort
    ? newSort.filter((item) => item.Field !== sortingField)
    : [];
  if (sortingOrder) {
    newSort.push({
      Field: sortingField,
      Direction: sortingOrder,
    });
  }
  if (newState.activeDimensions && getAppliedDimension(newState.activeDimensions) > 1) {
    if (sortingOrder && enableMultiSort) {
      // newSort = newState.sort;
      const sortItemIndex = newSort.findIndex((item) => item.Field === sortingField);
      if (sortItemIndex === -1) {
        newSort.push({
          Field: sortingField,
          Direction: sortingOrder,
        });
      } else {
        newSort[sortItemIndex].Field = sortingField;
        newSort[sortItemIndex].Direction = sortingOrder;
      }
      newSort = getDefaultSortForRowGrouping(newSort, newState.activeDimensions);
    }
  }
  if (isMultiSortInActiveDimension) {
    const multiSortInActiveDimension = newSort.slice(0, newState.activeDimensionsLength - 1);
    const sortItemIndex = multiSortInActiveDimension.findIndex((item) => item.Field === sortingField);
    if (sortingOrder) {
      if (sortItemIndex === -1) {
        multiSortInActiveDimension.push({
          Field: sortingField,
          Direction: sortingOrder,
        });
      } else {
        multiSortInActiveDimension[sortItemIndex].Field = sortingField;
        multiSortInActiveDimension[sortItemIndex].Direction = sortingOrder;
      }
    }
    newSort = multiSortInActiveDimension;
  }
  newState.isMultiSortInActiveDimension = isMultiSortInActiveDimension;
  newState.sort = newSort;
  newState.isMultiSort = enableMultiSort;
  return newState;
};

const getStateOnDrilldownChange = (
  state: ReportReduxModel.IState, filterId: string, drilldownValue: any, user: string,
) => {
  const newState = clone(state);
  const newAppliedFilters = clone(newState.activeFilters);
  const newPrevAppliedFilters = clone(newState.prevAppliedFilters);
  const newAppliedFilter = clone(newAppliedFilters[filterId]);
  newAppliedFilter.Hidden = newPrevAppliedFilters.length ? newState.drilldownPoint.level : user;
  newState.drilldownPoint = {
    filterId,
    level: drilldownValue,
  };
  newAppliedFilters[filterId] = newAppliedFilter;
  newPrevAppliedFilters.push(newAppliedFilters);
  newState.prevAppliedFilters = newPrevAppliedFilters;
  newState.useChangedFilters = true;
  return newState;
};

const getStateOnReverseDrilldown = (state: ReportReduxModel.IState, index: number) => {
  const newState = clone(state);
  const newPrevAppliedFilters = cloneDeep(newState.prevAppliedFilters).slice(0, index + 1);
  const newAppliedFilters = cloneDeep(newPrevAppliedFilters.pop());
  const newFilterConfig = newState.reportConfig.Filters;
  const filterIdsInNewAppliedFilters = Object.keys(newAppliedFilters);
  const dynamicFilterIds = filterIdsInNewAppliedFilters.filter((Id) => !!(newFilterConfig.find((filter) => filter.ID !== Id)));
  const dynamicFilters: ReportResponseModel.IFilterConfig[] = [];
  newState.dynamicDimensions.forEach((dim) => {
    if (!dim?.BuilderConfig) { return; }
    const {
      FilterId, FilterType: dimFilterType, DisplayName, Entity, Alias, MetaData, IsDynamicField,
    } = dim?.BuilderConfig;
    if (FilterId && dynamicFilterIds.includes(FilterId)) {
      const filterData = {
        ID: FilterId,
        Type: dimFilterType,
        Label: DisplayName,
        Entity,
        Alias,
        Metadata: MetaData,
        IsDynamic: IsDynamicField,
        DefaultValue: null,
        StyleConfig: null,
        LinkedTo: '',
        LinkedDimensionAlias: '',
        AlwaysEnabled: false,
      } as ReportResponseModel.IFilterConfig;
      newFilterConfig.push(filterData);
      dynamicFilters.push(filterData);
    }
  });
  if (dynamicFilters && !isEmpty(dynamicFilters)) {
    dynamicFilters.forEach((dynamicFilter) => {
      if (!newAppliedFilters[dynamicFilter.ID]) {
        const appliedFilter = newState.appliedFilters[dynamicFilter.ID];
        newAppliedFilters[dynamicFilter.ID] = cloneDeep(appliedFilter);
        if (appliedFilter) {
          switch (dynamicFilter.Type) {
            case FilterType.LSQMetadataSingleSelect:
            case FilterType.CustomDefinedSingleSelect: {
              const filterValue = (appliedFilter.FilterOptions && appliedFilter.FilterOptions[0]
              && appliedFilter.FilterOptions[0].value);
              newAppliedFilters[dynamicFilter.ID].FilterResponse = filterValue;
              break;
            }
            default: {
              const defaultFilter = new FilterFetchUtility(null).GetDefaultSingleFilter(dynamicFilter, null);
              newAppliedFilters[dynamicFilter.ID].FilterResponse = defaultFilter && defaultFilter.FilterResponse;
              break;
            }
          }
        }
      }
    });
  }
  newState.appliedFilters = newAppliedFilters;
  newState.prevAppliedFilters = newPrevAppliedFilters;
  newState.drilldownPoint.level = newState.appliedFilters[newState.drilldownPoint.filterId].Hidden;
  newState.page.PageIndex = 1;
  newState.useChangedFilters = true;
  return newState;
};

const getStateOnApplyingFilters = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  let newFilterConfig = cloneDeep(newState.filterConfig);
  const newAppliedFilters = cloneDeep(newState.appliedFilters);
  if (newFilterConfig && !isEmpty(newFilterConfig)) {
    newFilterConfig = newFilterConfig.filter((config) => !(config.IsDynamic && config.IsRemoved));
  }
  newState.page.PageIndex = 1;
  newState.filterConfig = newFilterConfig;
  newState.appliedFilters = newAppliedFilters;
  newState.activeFilters = newAppliedFilters;
  newState.useChangedFilters = false;

  // set this intermediate state to show loader in report builder component.
  newState.requestProcessing[ReportActionTypes.REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.AboutToLoad;
  return newState;
};

const getStateOnClearFilters = (
  state: ReportReduxModel.IState, variables: AuthReduxModel.IVariables,
) => {
  const newState = clone(state);
  const FetchFilter = new FilterFetchUtility(variables);

  let newAppliedFilters = newState.filterConfig.reduce((
    acc: ObjModel.ObjGeneric<FilterModel.IFilterResponse>, item,
  ) => {
    if (!acc[item.ID]) {
      const activeFilter = newState.activeFilters[item.ID];
      let isValueChanged = false;
      if (activeFilter && !activeFilter.Disabled) {
        acc[item.ID] = cloneDeep(activeFilter);

        switch (item.Type) {
          case FilterType.PRE_APPLIED: {
            break;
          }

          case FilterType.UserMultiSelect:
            acc[item.ID] = FetchFilter.GetDefaultSingleFilter(item, null);
            isValueChanged = true;
            break;

          case FilterType.GroupMultiSelect:
          case FilterType.CustomDefinedMultiSelect:
          case FilterType.LSQMetadataMultiSelect: {
            const newValue = activeFilter.FilterOptions
              ? activeFilter.FilterOptions.map((option: FilterModel.ISelectFilterOptions) => (
                (option.value)))
              : null;
            acc[item.ID].FilterResponse = newValue;
            if (activeFilter.FilterResponse || newValue) {
              if (activeFilter.FilterResponse && newValue) {
                if (activeFilter.FilterResponse.length !== newValue.length) {
                  isValueChanged = true;
                }
              } else {
                isValueChanged = true;
              }
            }
            break;
          }

          case FilterType.LSQMetadataSingleSelect:
          case FilterType.CustomDefinedSingleSelect: {
            const newValue = activeFilter.FilterOptions && !isEmpty(activeFilter.FilterOptions)
              ? activeFilter.FilterOptions[0].value
              : null;
            acc[item.ID].FilterResponse = newValue;
            isValueChanged = activeFilter.FilterResponse || newValue;
            break;
          }

          case FilterType.DateRange: {
            const newValue = dateRangeProcessor(null);
            acc[item.ID].FilterResponse = newValue;
            isValueChanged = !isEqualDateRangePayload(newValue, activeFilter.FilterResponse);
            break;
          }

          default:
            break;
        }
      } else {
        acc[item.ID] = FetchFilter.GetDefaultSingleFilter(item, null);
      }

      if (isValueChanged) {
        const linkedFilters = newState.filterConfig.filter(
          (config) => (config.LinkedTo) === item.ID,
        );
        linkedFilters.forEach((config) => {
          acc[config.ID] = FetchFilter.GetDefaultSingleFilter(config, null);
        });
      }
    }
    return acc;
  }, {});

  newAppliedFilters = getFiltersForActiveDimensions(
    newState.filterConfig,
    newState.activeDimensions,
    newAppliedFilters,
  );

  newState.appliedFilters = newAppliedFilters;
  newState.useChangedFilters = true;
  return newState;
};

export const getStateOnApplyReportView = (
  state: ReportReduxModel.IState, variables: AuthReduxModel.IVariables, reportViewState: ReportViewState, urlViewType?: ReportViewTypes,
) => {
  const { reportViewInfo: { reportViewInfoItem: { PersonalisationObject } }, reportViewList: { reportViewItems }, selectedReportViewItem } = reportViewState;
  const newState = clone(state);
  const FetchFilter = new FilterFetchUtility(variables);
  let newFilters = cloneDeep(newState.activeFilters);
  let newFilterConfig = cloneDeep(newState.filterConfig);
  const newReportConfig = cloneDeep(newState.reportConfig);
  const newDimensions = [];

  const {
    filters, rowGrouping, colGrouping, sort, binning, measures,
  } = PersonalisationObject;

  const newMeasureArr: any[] = [];

  if (isEmpty(measures)) {
    state.reportConfig?.Columns?.map((d: any) => {
      if (!isEmpty(d.Props.Measure)) {
        newMeasureArr.push({
          Name: d.Name,
          Expression: d.Props.Measure.Expression,
        });
      }
      return newMeasureArr;
    });
  }

  if (newMeasureArr) {
    newMeasureArr.forEach((dim: any) => {
      const index = newReportConfig.Columns.findIndex((d: any) => d.Name === dim.Name);
      newReportConfig.Columns[index] = {
        ...newReportConfig.Columns[index],
        Props: {
          ...newReportConfig.Columns[index].Props,
          Measure: {
            Name: dim.Name,
            Expression: dim.Expression,
          },
        },
      };
    });
  }

  // filtering only unique values from rowGrouping
  const newRowGrouping = Array.from(new Set(rowGrouping));
  // removing all the values
  rowGrouping.length = 0;
  newRowGrouping.forEach((item:any) => {
    rowGrouping.push(item);
  });

  const selectedView = reportViewItems && reportViewItems.find((item) => item.PersonalisationId === selectedReportViewItem);
  if (filters) {
    const personalisedFilterIds = Object.keys(filters);
    newFilterConfig = newFilterConfig
      .filter((config) => (config.IsDynamic ? personalisedFilterIds.includes(config.ID) : true))
      .map((c) => (c.IsDynamic ? { ...c, IsRemoved: false } : c));
    personalisedFilterIds.forEach((filterId) => {
      const configIndex = newFilterConfig.findIndex((config) => config.ID === filterId);
      if (configIndex === -1 && newState.dynamicDimensions && !isEmpty(newState.dynamicDimensions)) {
        const dynamicDimension = newState.dynamicDimensions.find((item) => item.BuilderConfig && item.BuilderConfig.IsFilterApplicable
        && (item.BuilderConfig.FilterId === filterId));
        if (dynamicDimension) {
          const filterData = {
            ID: dynamicDimension.BuilderConfig.FilterId,
            Type: dynamicDimension.BuilderConfig.FilterType,
            Label: dynamicDimension.BuilderConfig.DisplayName,
            Entity: dynamicDimension.BuilderConfig.Entity,
            Alias: dynamicDimension.BuilderConfig.Alias,
            Metadata: dynamicDimension.BuilderConfig.MetaData,
            IsDynamic: dynamicDimension.BuilderConfig.IsDynamicField,
            DefaultValue: null,
            StyleConfig: null,
            LinkedTo: '',
            LinkedDimensionAlias: '',
            AlwaysEnabled: false,
          } as ReportResponseModel.IFilterConfig;
          newFilterConfig.push(filterData);
        }
      }
    });
  }

  if (newFilterConfig && !isEmpty(newFilterConfig)) {
    newFilters = newFilterConfig.reduce((
      acc: ObjModel.ObjGeneric<FilterModel.IFilterResponse>, item,
    ) => {
      let value = null;
      if (filters[item.ID]) {
        switch (item.Type) {
          case FilterType.UserMultiSelect:
            value = {
              Type: filters[item.ID].Type,
              UserIds: filters[item.ID].UserIds,
            };
            break;

          case FilterType.GroupMultiSelect:
          case FilterType.LSQMetadataMultiSelect:
          case FilterType.CustomDefinedMultiSelect: {
            value = filters[item.ID].map((option: FilterModel.ISelectFilterOptions) => option.value);
            break;
          }

          case FilterType.CustomDefinedSingleSelect:
          case FilterType.LSQMetadataSingleSelect: {
            value = filters[item.ID].value;
            break;
          }

          default:
            value = filters[item.ID];
            break;
        }
      } else {
        switch (item.Type) {
          case FilterType.UserMultiSelect:
            value = {
              Type: UserFilterPayloadType.In,
              UserIds: [],
            };
            break;

          case FilterType.GroupMultiSelect:
          case FilterType.LSQMetadataMultiSelect:
          case FilterType.CustomDefinedMultiSelect: {
            value = [];
            break;
          }

          default:
            break;
        }
      }
      if ((selectedView?.ReportViewType === ReportViewTypes.Default || urlViewType === ReportViewTypes.Default) && isEmpty(value)) {
        acc[item.ID] = FetchFilter.GetDefaultSingleFilter(item, null);
        return acc;
      }
      acc[item.ID] = FetchFilter.GetDefaultSingleFilter(item, value);
      return acc;
    }, {});
  }

  if (newState.activeDimensions && !isEmpty(newState.activeDimensions)) {
    const groupingDimensionsIndex = [] as Array<number>;
    if (rowGrouping && !isEmpty(rowGrouping)) {
      // Apply dimension and zero values and update sorting order of dimensions.
      for (let i = 0; i < rowGrouping.length; i += 1) {
        const dimensionIndex = getRelatedDimensionConfigIndex(rowGrouping[i], newState.activeDimensions);
        // Check for existence of dimension for report.
        if (dimensionIndex > -1) {
          const nDimension = cloneDeep(newState.activeDimensions[dimensionIndex]);
          if (nDimension.DimensionMode === DimensionMode.ColumnGroup) {
            nDimension.DimensionMode = DimensionMode.RowGroup;
          }
          // Apply dimension.
          if (nDimension.Applied === DimensionStatus.NotApplied) {
            nDimension.Applied = DimensionStatus.Applied;
          }
          // Un-apply zero anchors.
          if (nDimension.ZeroAnchor === ZeroAnchorStatus.Applied) {
            nDimension.ZeroAnchor = ZeroAnchorStatus.Enabled;
          }
          // Apply Binning.
          if (nDimension.DimensionProp) {
            const key = getReportViewDimensionStr(nDimension);
            nDimension.DimensionProp.Props.BinningType = key in binning ? binning[key] : '';
          }
          groupingDimensionsIndex.push(dimensionIndex);
          newDimensions.push(nDimension);
        } else {
          // Do not apply saved state.
          return clone(newState);
        }
      }
    }
    // Append column grouping dimensions.
    if (colGrouping && !isEmpty(colGrouping)) {
      // Apply dimension and zero values and update sorting order of dimensions.
      for (let i = 0; i < colGrouping.length; i += 1) {
        const dimensionIndex = getRelatedDimensionConfigIndex(colGrouping[i], newState.activeDimensions);
        // Check for existence of dimension for report.
        if (dimensionIndex > -1) {
          const nDimension = cloneDeep(newState.activeDimensions[dimensionIndex]);
          // Apply dimension.
          if (nDimension.DimensionMode === DimensionMode.RowGroup) {
            nDimension.DimensionMode = DimensionMode.ColumnGroup;
          }
          if (nDimension.Applied === DimensionStatus.NotApplied) {
            nDimension.Applied = DimensionStatus.Applied;
          }
          // Un-apply zero anchors.
          if (nDimension.ZeroAnchor === ZeroAnchorStatus.Applied) {
            nDimension.ZeroAnchor = ZeroAnchorStatus.Enabled;
          }
          // Apply Binning.
          if (nDimension.DimensionProp) {
            const key = getReportViewDimensionStr(nDimension);
            nDimension.DimensionProp.Props.BinningType = key in binning ? binning[key] : '';
            // Updating the pivotBinningType from Saved State Work Flow
            newState.pivotBinningType = key in binning ? binning[key] : '';
          }
          groupingDimensionsIndex.push(dimensionIndex);
          newDimensions.push(nDimension);
        } else {
          // Do not apply saved state.
          return clone(newState);
        }
      }
    }
    // Append non grouping dimensions.
    newState.activeDimensions.forEach((dimension, dimensionIndex) => {
      if (!groupingDimensionsIndex.includes(dimensionIndex)) {
        const nonGroupingDimension = cloneDeep(dimension);
        if (nonGroupingDimension.Applied === DimensionStatus.Applied) {
          nonGroupingDimension.Applied = DimensionStatus.NotApplied;
        }
        if (nonGroupingDimension.ZeroAnchor === ZeroAnchorStatus.Applied) {
          nonGroupingDimension.ZeroAnchor = ZeroAnchorStatus.Enabled;
        }
        newDimensions.push(nonGroupingDimension);
      }
    });
  }

  newFilters = getFiltersForActiveDimensions(
    newFilterConfig,
    newDimensions,
    newFilters,
  );

  newState.sort = sort && sort.length ? sort : [];
  newState.isMultiSort = !!(sort && sort.length > 1);
  if (newDimensions && getAppliedDimension(newDimensions) > 1) {
    newState.sort = getDefaultSortForRowGrouping(
      newState.sort,
      newDimensions,
    );
  }

  newState.appliedDimensions = newDimensions;
  newState.activeDimensions = newDimensions;
  newState.activeDimensionsLength = getAppliedDimension(newState.appliedDimensions);
  newState.filterConfig = newFilterConfig;
  newState.reportConfig = newReportConfig;
  newState.appliedFilters = newFilters;
  newState.useChangedFilters = true;
  newState.prevAppliedFilters = [];
  newState.drilldownPoint = null;

  return newState;
};

const getStateOnSetToolbarMenuItem = (
  state: ReportReduxModel.IState, index: number,
) => {
  const newState = clone(state);
  newState.selectedToolbarMenuItem = index;
  return newState;
};

// Toggles a row/column grouping and re-orders the dimensions to put applied dimensions
// at the top. Optionally changes position of the dimension.
const getStateOnSetGrouping = (
  state: ReportReduxModel.IState,
  sourceIndex: number,
  status: DimensionStatus,
  mode: DimensionMode,
  destinationIndex?: number,
  isSwapEnabled?: boolean,
) => {
  const newState = clone(state);
  let newAppliedDimensions = cloneDeep(newState.appliedDimensions);
  const dimension = newAppliedDimensions[sourceIndex]; // source dimension
  const desDimension = newAppliedDimensions[destinationIndex]; // destination dimension
  // if swapping is there, then before applying changes to source dimension, we should swap its values in
  // destination dimension.
  if (isSwapEnabled) {
    // if destination dimension is mandatory applied , then it should remain mandatory applied only,
    // if swapped to row grouping
    let desDimStatus = DimensionStatus.NotApplied;
    if (desDimension.Applied === DimensionStatus.Mandatory) {
      desDimStatus = DimensionStatus.Mandatory;
    // and if source dimension is mandatory applied , then it should be applied
    } else if (dimension.Applied === DimensionStatus.Mandatory) {
      desDimStatus = DimensionStatus.Applied;
    // if both are mandatory or for any other case status should be swapped.
    } else {
      desDimStatus = dimension.Applied;
    }
    desDimension.Applied = desDimStatus;
    desDimension.ZeroAnchor = desDimension.ZeroAnchor === ZeroAnchorStatus.Disabled
      ? ZeroAnchorStatus.Disabled
      : ZeroAnchorStatus.Enabled; // zero values un-applied by default
    desDimension.DimensionMode = dimension.DimensionMode;
  }
  // making state changes to source dimension (applicable for all cases)
  dimension.Applied = status;
  dimension.ZeroAnchor = dimension.ZeroAnchor === ZeroAnchorStatus.Disabled
    ? ZeroAnchorStatus.Disabled
    : ZeroAnchorStatus.Enabled; // zero values un-applied by default
  dimension.DimensionMode = mode;

  // swapping source dimension with destination dimension in case of swapping true
  if (isSwapEnabled) {
    newAppliedDimensions[destinationIndex] = dimension;
    newAppliedDimensions[sourceIndex] = desDimension;
  }

  // change position of dimension to putBeforeIndex only in case of addition(no swapping)
  if (destinationIndex !== undefined && !isSwapEnabled) {
    newAppliedDimensions = newAppliedDimensions.filter((dim) => {
      if (dim.BuilderConfig && dim.BuilderConfig.IsDynamicField && dimension.BuilderConfig
        && dimension.BuilderConfig.IsDynamicField) {
        return dim.BuilderConfig.Alias !== dimension.BuilderConfig.Alias;
      }
      if (!(dim.BuilderConfig && dim.BuilderConfig.IsDynamicField)
        && !(dimension.BuilderConfig && dimension.BuilderConfig.IsDynamicField)) {
        return dim.Name !== dimension.Name;
      }
      return true;
    });
    newAppliedDimensions.splice(destinationIndex, 0, dimension);
  }

  newAppliedDimensions = reOrderDimensions(newAppliedDimensions);

  // if pivot is enabled , zero anchor of all applied dimension should set to enabled, if zeroAnchor is applied to send
  // zero anchor disabled to backend call
  const hasColumnGroupings = newAppliedDimensions.some((dim) => (
    dim.DimensionMode !== DimensionMode.RowGroup && dim.Applied !== DimensionStatus.NotApplied
  ));
  if (hasColumnGroupings) {
    newAppliedDimensions.forEach((dim) => {
      if (dim.ZeroAnchor === ZeroAnchorStatus.Applied) {
        // eslint-disable-next-line no-param-reassign
        dim.ZeroAnchor = ZeroAnchorStatus.Enabled;
      }
    });
  }
  newState.appliedDimensions = newAppliedDimensions;
  return newState;
};

const getStateOnMeasureSet = (state: ReportReduxModel.IState, index: number, status: ColumnModes) => {
  if (isEmpty(state.allMeasures) || index < 0 || index >= state.allMeasures.length) {
    return state;
  }
  const newState = clone(state);
  const newMeasures = cloneDeep(newState.allMeasures);
  const measure = newMeasures[index];
  measure.Props.Mode = status;

  // reorder measures to bring applied at the top
  newMeasures.splice(index, 1);
  if (status === ColumnModes.Hidden) {
    newMeasures.push(measure);
  } else {
    let firstUnApplied = newMeasures.findIndex((m) => !isMeasureAndApplied(m));
    if (firstUnApplied === -1) firstUnApplied = newMeasures.length;
    newMeasures.splice(firstUnApplied, 0, measure); // put just before un-applied
  }

  newState.allMeasures = newMeasures;
  return newState;
};

const getStateOnMeasureReorder = (state: ReportReduxModel.IState, sourceIndex: number, destinationIndex: number) => {
  if (isEmpty(state.allMeasures) || sourceIndex < 0 || sourceIndex >= state.allMeasures.length
      || destinationIndex < 0 || destinationIndex >= state.allMeasures.length) {
    return state;
  }
  const newState = clone(state);
  const newMeasures = cloneDeep(newState.allMeasures);
  const measure = newMeasures.splice(sourceIndex, 1);
  if (measure.length !== 1) return state;
  newMeasures.splice(destinationIndex, 0, measure[0]);

  newState.allMeasures = newMeasures;
  return newState;
};
const getStateOnSetZeroAnchor = (
  state: ReportReduxModel.IState, index: number,
) => {
  if (
    state.appliedDimensions[index].Applied === DimensionStatus.NotApplied
    || state.appliedDimensions[index].ZeroAnchor === ZeroAnchorStatus.Disabled
  ) {
    // no change upon setting inapplicable zero anchors
    return state;
  }

  const newState = clone(state);
  const newAppliedDimensions = cloneDeep(newState.appliedDimensions);

  newState.appliedDimensions.forEach((item, dimIndex) => {
    if (newAppliedDimensions[dimIndex].ZeroAnchor !== ZeroAnchorStatus.Disabled) {
      // clear Applied status of Enabled anchors to enforce selecting only one anchor
      newAppliedDimensions[dimIndex].ZeroAnchor = ZeroAnchorStatus.Enabled;
    }
  });
  newAppliedDimensions[index].ZeroAnchor = ZeroAnchorStatus.Applied;
  newState.appliedDimensions = newAppliedDimensions;
  return newState;
};

const getStateOnApplyDimensions = (
  state: ReportReduxModel.IState,
) => {
  const newState = clone(state);
  // Resetting the pivotBinningType
  newState.pivotBinningType = '';
  const newActiveDimensions = cloneDeep(newState.appliedDimensions);
  const newAppliedFilters = getFiltersForActiveDimensions(
    newState.filterConfig,
    newActiveDimensions,
    newState.activeFilters,
  );
  newState.sort = getSortForActiveDimensions(
    newState.sort,
    newActiveDimensions,
  );
  if (newState.appliedDimensions && getAppliedDimension(newState.appliedDimensions) > 1) {
    newState.sort = getDefaultSortForRowGrouping(newState.sort,
      newActiveDimensions);
  }

  const pivotColumn = newActiveDimensions.find((dim) => dim.DimensionMode === DimensionMode.ColumnGroup);
  // If there is a pivot column and it has DimensionProp, set pivotBinningType
  if (pivotColumn && pivotColumn.DimensionProp) {
    newState.pivotBinningType = pivotColumn.DimensionProp.Props.BinningType;
  }

  // if length of sort array after dimension removal is zero, then adding sorting on 1st measure
  if (newState.sort.length === 0) {
    // creating dimensionMap consisting of dimension and dimension meta field
    const dimensionMap: { [key: string]: boolean; } = {};
    newState.columns.forEach((column) => {
      const { Props } = column;
      // is dimension
      if (Props && Props.Dimension) {
        dimensionMap[column.Name] = true;
        if (column.Props?.ReferTo && column.Props?.ReferTo.length) {
          dimensionMap[column.Props?.ReferTo] = true;
        }
      }
    });
    // getting index of element which is not present in dimensionMap i.e measure
    const index = newState.columns.findIndex((item) => (!dimensionMap[item.Name]));
    if (index > -1) {
      newState.sort = [
        {
          Field: newState.columns[index].Name,
          Direction: 'ASC', // keeping it default ascending
        },
      ];
    }
  }

  newState.activeDimensions = newActiveDimensions;
  newState.appliedFilters = newAppliedFilters;
  newState.activeDimensionsLength = getAppliedDimension(newState.appliedDimensions);
  newState.isMultiSort = newState.sort.length > 1;
  return newState;
};

const getStateOnReorderColumns = (
  state: ReportReduxModel.IState, columns: Array<ReportResponseModel.IColumn>,
  isAutodrilldown?: boolean,
) => {
  const newState = clone(state);
  if (isAutodrilldown) {
    newState.autodrilldownData.columns = columns;
    // setting the order of autodrilldownData.drilldownFields when we change the order of columns in the grid directly
    const visibleCols:ReportResponseModel.IColumn[] = columns.filter((dim) => dim.Props.Mode !== ColumnModes.Hidden);
    const appliedDrilldownFields:ReportResponseModel.IColumn[] = visibleCols
      .map((dim) => newState.autodrilldownData.drilldownFields
        .find((x) => x.Name === dim.Name));
    const unappliedDrilldownFields:ReportResponseModel.IColumn[] = newState.autodrilldownData.drilldownFields
      .filter((dim) => dim.Props.Dimension.Applied === DimensionStatus.NotApplied);
    newState.autodrilldownData.drilldownFields = appliedDrilldownFields.concat(unappliedDrilldownFields);
  } else {
    newState.columns = columns;
    newState.allMeasures = reOrderMeasures(newState.columns.filter((c) => isMeasure(c)));
  }
  return newState;
};

const getStateOnToggleZeroAnchor = (
  state: ReportReduxModel.IState,
) => {
  const newState = clone(state);
  const newAppliedDimensions = cloneDeep(newState.appliedDimensions);
  newState.appliedDimensions.forEach((item, index) => {
    if (newAppliedDimensions[index].ZeroAnchor !== ZeroAnchorStatus.Disabled) {
      // clear Applied status of Enabled anchors
      newAppliedDimensions[index].ZeroAnchor = ZeroAnchorStatus.Enabled;
    }
  });
  newState.appliedDimensions = newAppliedDimensions;
  newState.zeroValuesEnabled = !newState.zeroValuesEnabled;
  return newState;
};

const getStateOnSearchDropdownOptions = (
  state: ReportReduxModel.IState, filterId: string, searchKey: string,
) => {
  const newState = clone(state);
  const filter = newState.filterConfig.find((config) => config.ID === filterId);
  const newAppliedFilters = cloneDeep(newState.appliedFilters);
  if (filter) {
    newAppliedFilters[filterId].OptionsMeta.SearchKey = searchKey;
    newAppliedFilters[filterId].OptionsMeta.Page.PageIndex = 0;
    newAppliedFilters[filterId].FilterOptions = [];
  }
  newState.appliedFilters = newAppliedFilters;
  newState.useChangedFilters = false;
  return newState;
};

const getStateOnLoadMoreDropdownOptions = (
  state: ReportReduxModel.IState, filterId: string,
) => {
  const newState = clone(state);
  const filter = newState.filterConfig.find((config) => config.ID === filterId);
  const newAppliedFilters = cloneDeep(newState.appliedFilters);
  if (filter) {
    newAppliedFilters[filterId].OptionsMeta.Page.PageIndex += 1;
  }
  newState.appliedFilters = newAppliedFilters;
  newState.useChangedFilters = false;
  return newState;
};

const getStateOnAddDynamicFilter = (
  state: ReportReduxModel.IState, index: number,
) => {
  const newState = clone(state);
  let newFilterConfig = cloneDeep(newState.filterConfig);
  const newAppliedFilters = cloneDeep(newState.appliedFilters);
  const dynamicDimension = cloneDeep(newState.dynamicDimensions)[index];
  newFilterConfig = newFilterConfig.filter((config) => dynamicDimension.BuilderConfig
  && dynamicDimension.BuilderConfig.IsFilterApplicable && (config.ID !== dynamicDimension.BuilderConfig.FilterId));
  const filterData = {
    ID: dynamicDimension.BuilderConfig.FilterId,
    Type: dynamicDimension.BuilderConfig.FilterType,
    Label: dynamicDimension.BuilderConfig.DisplayName,
    Entity: dynamicDimension.BuilderConfig.Entity,
    Alias: dynamicDimension.BuilderConfig.Alias,
    Metadata: dynamicDimension.BuilderConfig.MetaData,
    IsDynamic: dynamicDimension.BuilderConfig.IsDynamicField,
    DefaultValue: null,
    StyleConfig: null,
    LinkedTo: '',
    LinkedDimensionAlias: '',
    AlwaysEnabled: false,
  } as ReportResponseModel.IFilterConfig;
  newFilterConfig.push(filterData);
  newAppliedFilters[filterData.ID] = new FilterFetchUtility(null).GetDefaultSingleFilter(filterData, null);
  newState.filterConfig = newFilterConfig;
  newState.appliedFilters = newAppliedFilters;
  newState.useChangedFilters = false;
  return newState;
};

const getStateOnDeleteDynamicFilter = (
  state: ReportReduxModel.IState, filterId: string,
) => {
  const newState = clone(state);
  const newFilterConfig = cloneDeep(newState.filterConfig);
  const newAppliedFilters = cloneDeep(newState.appliedFilters);
  const index = newFilterConfig.findIndex((config) => config.ID === filterId);
  if (index !== -1) {
    newFilterConfig[index].IsRemoved = true;
  }
  delete newAppliedFilters[filterId];
  newState.appliedFilters = newAppliedFilters;
  newState.useChangedFilters = false;
  newState.filterConfig = newFilterConfig;
  return newState;
};

const getStateOnAutoDrilldown = (
  state: ReportReduxModel.IState, selectedMeasure: string, selectedRowData: ObjModel.Obj,
) => {
  const newState = clone(state);
  if (selectedMeasure) {
    newState.autodrilldownData = {
      selectedMeasure,
      selectedRowData,
      page: {
        PageSize: 25,
        PageIndex: 1,
      },
      columns: [],
      sort: [],
      isMultiSort: false,
      reportData: null,
      drilldownFields: null,
    };
  } else {
    newState.autodrilldownData = null;
  }

  return newState;
};

export const getSortForActiveDimensions = (
  sort: Array<ReportResponseModel.ISortedField>, activeDimensions: Array<ReportReduxModel.IDimension>,
) => {
  let newSort = cloneDeep(sort);
  if (sort && activeDimensions) {
    newSort = sort.filter((item) => {
      const relatedDimension = activeDimensions.find((dimension) => (
        dimension.BuilderConfig && dimension.BuilderConfig.IsDynamicField
          ? dimension.BuilderConfig.Alias === item.Field
          : (dimension.ReferTo || dimension.Name) === item.Field
      ));
      if (relatedDimension && relatedDimension.Applied === DimensionStatus.NotApplied) {
        return false;
      }
      return true;
    });
  }
  return newSort;
};

// Function is called whenever there is change in dimensions. Update dimension and rearrange columns
// based on current order of dimensions. Rearrange the dimension's referer column next to parent column
// (has dimension and ReferTo key) in columns array.
// param columns: array of columns in report config.
// param activeDimensions: array of active(used to fetch report) dimensions.
// returns: array of columns with updated dimension.
export const getColumnsForActiveDimensions = (
  columns: Array<ReportResponseModel.IColumn>, activeDimensions: Array<ReportReduxModel.IDimension>,
  measures: Array<ReportResponseModel.IColumn>,
): Array<ReportResponseModel.IColumn> => {
  let currColumns = cloneDeep(columns) as Array<ReportResponseModel.IColumn>;
  const newColumns = [] as Array<ReportResponseModel.IColumn>;
  activeDimensions.forEach((item) => {
    const column = currColumns.find((col) => {
      if (item.BuilderConfig && item.BuilderConfig.IsDynamicField && col.BuilderConfig
        && col.BuilderConfig.IsDynamicField) {
        return item.BuilderConfig.Alias === col.BuilderConfig.Alias;
      }
      if (!(item.BuilderConfig && item.BuilderConfig.IsDynamicField)
        && !(col.BuilderConfig && col.BuilderConfig.IsDynamicField)) {
        return item.Name === col.Name;
      }
      return false;
    });
    const refererCol = item.ReferTo
      ? currColumns.find((col) => col.Name === item.ReferTo)
      : null;
    if (column) {
      if (column && column.BuilderConfig && column.BuilderConfig.IsDynamicField && column.BuilderConfig.Alias && column.BuilderConfig.Alias.length && item.Applied !== DimensionStatus.Applied) {
        currColumns = currColumns.filter((col) => {
          if (col.BuilderConfig && col.BuilderConfig.IsDynamicField && column.BuilderConfig
            && column.BuilderConfig.IsDynamicField) {
            return col.BuilderConfig.Alias !== column.BuilderConfig.Alias;
          }
          return true;
        });
        return;
      }
      const dimension = cloneDeep(column.Props.Dimension);
      dimension.Applied = item.Applied;
      dimension.Orderable = item.Orderable;
      dimension.ZeroAnchor = item.ZeroAnchor;
      dimension.DimensionMode = item.DimensionMode;
      dimension.DimensionProp = item.DimensionProp;
      column.Props.Dimension = dimension;
      newColumns.push(column);
      currColumns = currColumns.filter((col) => {
        if (item.BuilderConfig && item.BuilderConfig.IsDynamicField && col.BuilderConfig
          && col.BuilderConfig.IsDynamicField) {
          return item.BuilderConfig.Alias !== col.BuilderConfig.Alias;
        }
        if (!(item.BuilderConfig && item.BuilderConfig.IsDynamicField)
          && !(col.BuilderConfig && col.BuilderConfig.IsDynamicField)) {
          return item.Name !== col.Name;
        }
        return true;
      });
      if (refererCol) {
        newColumns.push(refererCol);
        currColumns = currColumns.filter((col) => col.Name !== item.ReferTo);
      }
    } else if (item.Applied === DimensionStatus.Applied) {
      const dimension = cloneDeep(item);
      dimension.Applied = item.Applied;
      dimension.Orderable = item.Orderable;
      dimension.ZeroAnchor = item.ZeroAnchor;
      dimension.DimensionMode = item.DimensionMode;
      const newColumn = {
        Name: dimension.Name,
        BuilderConfig: dimension.BuilderConfig,
      } as IColumn;
      delete dimension.BuilderConfig;
      newColumn.Props = {
        Dimension: dimension,
        Action: null,
        Converter: null,
        Formatter: null,
        IsMeasure: false,
        Mode: ColumnModes.Visible,
        ReferTo: '',
        IsMasked: item.IsMasked,
      };
      newColumns.push(newColumn);
    }
  });
  return newColumns.concat(measures.filter((m) => isMeasure(m) && (isMeasureAndApplied(m) || !m.BuilderConfig?.IsDynamicField)));
};

// Function called whenever there is change in dimension, enables and disables the dimension's
// related filters based on active dimensions. Impact related filters(has LinkedDimensionAlias)
// with "AlwaysEnabled" property false, disabled whenever dimension is not applied, enabled when applied.
// param filterConfig: array of filter config in report config.
// param dimensions: array of active dimensions.
// param filters: active filters.
// param variables: user variables.
// returns: new object of filters.
const getFiltersForActiveDimensions = (
  filterConfig: Array<ReportResponseModel.IFilterConfig>, dimensions: Array<ReportReduxModel.IDimension>,
  filters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>,
): ObjModel.ObjGeneric<FilterModel.IFilterResponse> => {
  const appliedFilters = cloneDeep(filters);
  if (filterConfig && !isEmpty(filterConfig) && dimensions && !isEmpty(dimensions)) {
    filterConfig.forEach((config) => {
      if (!config.IsDynamic) {
        if (appliedFilters[config.ID] && appliedFilters[config.ID].Disabled) {
          appliedFilters[config.ID].Disabled = false;
          appliedFilters[config.ID].shouldLoad = true;
        }
      }
    });
  }
  return appliedFilters;
};

const getDefaultSortForRowGrouping = (
  sort: ReportResponseModel.ISortedField[], dimensions: ReportReduxModel.IDimension[],
) => {
  let sorts: ReportResponseModel.ISortedField[] = [];
  // appliedDimensionsOnly: all rowGrouped dimensions.
  const appliedDimensionsOnly = dimensions.filter((item) => (
    (item.DimensionMode === DimensionMode.RowGroup)
    && (item.Applied !== DimensionStatus.NotApplied)));
  // appliedDimensionsForSortOnly: default sortable n-1 rowGrouped element.
  const appliedDimensionsForSortOnly = appliedDimensionsOnly.slice(0, appliedDimensionsOnly.length - 1);
  appliedDimensionsForSortOnly.forEach((element: ReportReduxModel.IDimension) => {
    if (element.Applied && element.DimensionMode === DimensionMode.RowGroup) {
      const sortValue = getSortForEachActiveDimensions(element, sort);
      sorts.push(sortValue);
    }
  });
  // below varaible is used to get column sort that are  non-applied dimensions
  const notActiveDimensionSort = sort.filter((item) => {
    const relatedSort = sorts.find((element) => element.Field === item.Field);
    if (relatedSort) {
      return false;
    }
    return true;
  });
  if (notActiveDimensionSort.length) {
    sorts = sorts.concat(notActiveDimensionSort);
  }
  return sorts;
};

const getSortForEachActiveDimensions = (item: ReportReduxModel.IDimension, sort: ReportResponseModel.ISortedField[]) => {
  const elementSortValue = { Field: item.ReferTo ? item.ReferTo : item.Name, Direction: SortingOrderTypes.ASC };
  if (item.BuilderConfig && item.BuilderConfig.IsDynamicField) {
    elementSortValue.Field = item.BuilderConfig.Alias;
    elementSortValue.Direction = SortingOrderTypes.ASC;
  }
  sort.forEach((element: ReportResponseModel.ISortedField) => {
    if (element.Field === item.ReferTo) {
      elementSortValue.Field = element.Field;
      elementSortValue.Direction = element.Direction || SortingOrderTypes.ASC;
    }
    if (element.Field === item.Name) {
      elementSortValue.Field = item.ReferTo ? item.ReferTo : item.Name;
      elementSortValue.Direction = element.Direction || SortingOrderTypes.ASC;
    }
  });
  return elementSortValue;
};

const getAppliedDimension = (input: ReportReduxModel.IDimension[]) => {
  const appliedvalue = input.filter((item: ReportReduxModel.IDimension) => item.Applied !== DimensionStatus.NotApplied);
  const rowGroupColumns = appliedvalue.filter((item) => item.DimensionMode === DimensionMode.RowGroup);
  return rowGroupColumns.length;
};

const getDynamicDimensionOnDynamicDimensionLoadRequest = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  newState.requestProcessing[ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST] = APIRequestStatus.Processing;
  return newState;
};

const getDynamicDimensionOnDynamicDimensionLoadSuccess = (
  state: ReportReduxModel.IState, dynamicDimensions: Array<ReportResponseModel.IColumn>, selectedMeasure?: string,
) => {
  const newState = clone(state);
  if (!selectedMeasure) {
    const newDynamicDimensions = dynamicDimensions;
    let allDimensions = reOrderDimensions(state.appliedDimensions);

    let castedDimensions;
    if (newDynamicDimensions && newDynamicDimensions.length) {
      castedDimensions = newDynamicDimensions
        .filter((i) => (
          i?.BuilderConfig?.IsDimApplicable
        && state.appliedDimensions
          .filter((a: IDimension) => a?.BuilderConfig?.IsDynamicField)
          .findIndex((a:IDimension) => a?.BuilderConfig?.Alias === i?.BuilderConfig?.Alias) === -1))
        .map((d:IColumn) => ({
          Applied: DimensionStatus.NotApplied,
          Orderable: d.Props.Dimension.Orderable,
          ZeroAnchor: d.Props.Dimension.ZeroAnchor,
          DimensionMode: d.Props.Dimension.DimensionMode,
          BuilderConfig: d.BuilderConfig,
          Name: d.Name,
          IsMasked: d.Props.IsMasked,
          DimensionProp: d.Props.Dimension.DimensionProp,
        } as IDimension));

      // keep report dimensions at the top for better UX, separate from dynamic dimensions
      allDimensions = [...allDimensions, ...castedDimensions];
    }

    newState.dynamicDimensions = newDynamicDimensions;
    newState.appliedDimensions = allDimensions; // no meaning of applied and active dimensions for autodrilldown
    newState.activeDimensions = allDimensions;
  } else {
    const newDynamicDimensions = dynamicDimensions;
    newState.autodrilldownData.drilldownFields = newDynamicDimensions.filter((dim) => dim.Props.Mode !== ColumnModes.Hidden);

    const visibleCols = new Set();
    state.autodrilldownData.columns.forEach((dim) => {
      if (dim.Props.Mode !== ColumnModes.Hidden && dim.BuilderConfig && dim.BuilderConfig.Alias) {
        visibleCols.add(dim.BuilderConfig.Alias);
      }
    });

    const appliedDrilldownFields:ReportResponseModel.IColumn[] = [];
    const unappliedDrilldownFields:ReportResponseModel.IColumn[] = [];
    newState.autodrilldownData.drilldownFields.forEach((dim) => {
      const newDim = dim;
      if (dim.Props && dim.Props.Dimension) {
        if (visibleCols.has(dim?.BuilderConfig?.Alias)) {
          newDim.Props.Dimension.Applied = DimensionStatus.Applied;
          appliedDrilldownFields.push(newDim);
        } else {
          newDim.Props.Dimension.Applied = DimensionStatus.NotApplied;
          unappliedDrilldownFields.push(newDim);
        }
      }
    });
    // ordering appliedDrilldownFields according to the visualisation columns from fetch-drilldown-report API
    const orderedAppliedDrilldownFields:IColumn[] = [];
    state.autodrilldownData.columns.forEach((element) => element?.Props?.Mode !== ColumnModes.Hidden && orderedAppliedDrilldownFields.push(appliedDrilldownFields.find((x) => x?.BuilderConfig?.Alias === element?.BuilderConfig?.Alias)));
    newState.autodrilldownData.drilldownFields = orderedAppliedDrilldownFields.concat(unappliedDrilldownFields);
  }
  newState.requestProcessing[ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST] = APIRequestStatus.Success;
  return newState;
};

const getDynamicDimensionOnDynamicDimensionLoadFailure = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  newState.requestProcessing[ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST] = APIRequestStatus.Failure;
  return newState;
};

const reOrderDimensions = (appliedDimensions: IDimension[]): IDimension[] => {
  const rowGroupingDimensions = appliedDimensions.filter((dim) => (
    dim.DimensionMode !== DimensionMode.ColumnGroup && dim.Applied !== DimensionStatus.NotApplied
  ));
  const colGroupingDimensions = appliedDimensions.filter((dim) => (
    dim.DimensionMode !== DimensionMode.RowGroup && dim.Applied !== DimensionStatus.NotApplied
  ));
  const availableDimensions = appliedDimensions.filter((dim) => dim.Applied === DimensionStatus.NotApplied);
  return [...rowGroupingDimensions, ...colGroupingDimensions, ...availableDimensions];
};

const reOrderMeasures = (measures: IColumn[]): IColumn[] => sortBy(
  measures,
  (m) => (!isMeasureAndApplied(m) ? 1 : 0),
);

const getStateOnAutodrilldownReportDataLoadRequest = (
  state: ReportReduxModel.IState,
) => {
  const newState = clone(state);
  newState.requestProcessing[ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Processing;
  return newState;
};

const getStateOnAutodrilldownReportDataLoadSuccess = (
  state: ReportReduxModel.IState, reportData: ReportResponseModel.IReportData,
) => {
  const newState = clone(state);
  const newAutodrilldownData = clone(newState.autodrilldownData);
  newState.requestProcessing[ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Success;
  if (!isEmpty(reportData)) {
    newState.selectedToolbarMenuItem = -1;
    newAutodrilldownData.columns = reportData.Visualization?.Columns;
    newAutodrilldownData.sort = reportData.Visualization?.DefaultSort;
    newAutodrilldownData.isMultiSort = reportData.Visualization?.DefaultSort?.length > 1;
    newAutodrilldownData.reportData = reportData;
    newAutodrilldownData.page.PageSize = reportData.Visualization?.DefaultPage?.PageSize ? reportData.Visualization.DefaultPage.PageSize : defaultPageValue.PageSize;
    newState.autodrilldownData = newAutodrilldownData;
  }
  return newState;
};

const getStateOnAutodrilldownReportDataLoadFailure = (
  state: ReportReduxModel.IState,
) => {
  const newState = clone(state);
  newState.requestProcessing[ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Failure;
  return newState;
};

// getRelatedDimensionConfigIndex get called when applying saved state.
// params savedGroupingStr: - string for grouping saved in saved state.
// params activeDimensions: - current dimension list.
// return index of dimension matched with saved grouping string.
const getRelatedDimensionConfigIndex = (
  savedGroupingStr: string, activeDimensions: ReportReduxModel.IDimension[],
) => {
  if (savedGroupingStr && activeDimensions && !isEmpty(activeDimensions)) {
    return activeDimensions.findIndex((dimension) => {
      if (getReportViewDimensionStr(dimension) === savedGroupingStr) {
        return true;
      }
      if (savedGroupingStr.indexOf(DimensionIdentifier) !== -1 && dimension.BuilderConfig
      && dimension.BuilderConfig.IsDynamicField && dimension.BuilderConfig.Alias) {
        const splits = savedGroupingStr.split(DimensionIdentifier);
        if (splits[1] && (splits[1] === dimension.BuilderConfig.Alias)) {
          return true;
        }
      }
      return false;
    });
  }
  return -1;
};

const getStateOnSetGroupingDrilldown = (
  state: ReportReduxModel.IState,
  sourceIndex: number,
  status: DimensionStatus,
  destinationIndex?: number,
  isSwapEnabled?: boolean,
) => {
  const newState = clone(state);
  let newDrilldownFields = cloneDeep(newState.autodrilldownData.drilldownFields);
  const srcDimension = newDrilldownFields[sourceIndex]; // source dimension
  const desDimension = newDrilldownFields[destinationIndex]; // destination dimension
  srcDimension.Props.Dimension.Applied = status;
  if (isSwapEnabled) {
    newDrilldownFields[destinationIndex] = srcDimension;
    newDrilldownFields[sourceIndex] = desDimension;
  }

  // in case of addition
  if (destinationIndex !== undefined && !isSwapEnabled) {
    newDrilldownFields = newDrilldownFields.filter((dim) => {
      if (dim.BuilderConfig && dim.BuilderConfig.IsDynamicField && srcDimension.BuilderConfig
        && srcDimension.BuilderConfig.IsDynamicField) {
        return dim.BuilderConfig.Alias !== srcDimension.BuilderConfig.Alias;
      }
      if (!(dim.BuilderConfig && dim.BuilderConfig.IsDynamicField)
        && !(srcDimension.BuilderConfig && srcDimension.BuilderConfig.IsDynamicField)) {
        return dim.Name !== srcDimension.Name;
      }
      return true;
    });
    newDrilldownFields.splice(destinationIndex, 0, srcDimension);
  }

  // in case of removal just reorder
  newDrilldownFields = reorderDrilldownFields(newDrilldownFields);
  newState.autodrilldownData.drilldownFields = newDrilldownFields;
  return newState;
};

const reorderDrilldownFields = (drilldownFields: IColumn[]): IColumn[] => {
  const groupedDimensions = drilldownFields.filter((dim) => (
    dim.Props.Dimension.Applied === DimensionStatus.Applied
  ));
  const availableDrilldownFields = drilldownFields.filter((dim) => dim.Props.Dimension.Applied === DimensionStatus.NotApplied);
  return [...groupedDimensions, ...availableDrilldownFields];
};

const getStateOnSetBinning = (state: ReportReduxModel.IState, index: any, value: string) => {
  const newState = clone(state);
  const newAppliedDimensions = cloneDeep(newState.appliedDimensions);
  const newAppliedDimension = newAppliedDimensions[index];
  newAppliedDimension.DimensionProp.Props.BinningType = value;
  if (newAppliedDimension.ZeroAnchor !== ZeroAnchorStatus.Disabled) {
    // unapply zero values for all binning types except Hour of the Day(backend is not compatible)
    if (newAppliedDimension.DimensionProp && newAppliedDimension.DimensionProp?.Props?.BinningType !== DateBinningFormatters.HOUROFTHEDAY) {
      newAppliedDimension.ZeroAnchor = ZeroAnchorStatus.Enabled;
    }
  }
  newAppliedDimensions[index] = newAppliedDimension;
  newState.appliedDimensions = newAppliedDimensions;
  return newState;
};

const getStateOnExportDataLoadRequest = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  newState.requestProcessing[ReportActionTypes.EXPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Processing;
  return newState;
};

export const getStateOnExportDataLoadSuccess = (
  state: ReportReduxModel.IState, exportData: ReportResponseModel.IExportData,
) => {
  const newState = clone(state);
  newState.exportListData = [exportData, ...(state.exportListData || [])];
  newState.requestProcessing[ReportActionTypes.EXPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Success;
  // since we added new export request to the queue we need to increment export consumed count
  newState.exportsConsumed += 1;
  newState.isDailyLimitReached = newState.totalDailyExports === newState.exportsConsumed;
  return newState;
};

export const getStateOnExportDataLoadFailure = (state: ReportReduxModel.IState, failureResponse?: ReportResponseModel.IExportDataFailureResponse) => {
  const newState = clone(state);
  if (failureResponse && failureResponse.Message === dailyQuotaApiResponse) newState.isDailyLimitReached = true;
  newState.requestProcessing[ReportActionTypes.EXPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Failure;
  return newState;
};

const getStateOnExportListLoadRequest = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  newState.exportData = null;
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportActionTypes.EXPORT_LIST_LOAD_REQUEST] = APIRequestStatus.Processing;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateExportUsageStatusRequest = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportActionTypes.EXPORT_USAGE_STATUS_REQUEST] = APIRequestStatus.Processing;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnExportUsageStatusSuccess = (
  state: ReportReduxModel.IState, exportUsageData: ReportResponseModel.IExportUsageData,
) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportActionTypes.EXPORT_USAGE_STATUS_REQUEST] = APIRequestStatus.Success;
  newState.requestProcessing = newRequestProcessing;
  newState.totalDailyExports = exportUsageData.TotalDailyExports;
  newState.exportsConsumed = exportUsageData.ExportsConsumed;
  newState.isDailyLimitReached = exportUsageData.TotalDailyExports === exportUsageData.ExportsConsumed;
  return newState;
};

const getStateOnExportUsageStatusFailure = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportActionTypes.EXPORT_USAGE_STATUS_REQUEST] = APIRequestStatus.Failure;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnExportListLoadByIdsRequest = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_REQUEST] = APIRequestStatus.Processing;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnExportListLoadSuccess = (
  state: ReportReduxModel.IState, exportListData: Array<ReportResponseModel.IExportData>,
) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportActionTypes.EXPORT_LIST_LOAD_REQUEST] = APIRequestStatus.Success;
  newState.requestProcessing = newRequestProcessing;
  newState.exportListData = exportListData;
  return newState;
};

// will update the exportListData with the inprogressDataList
const getStateOnExportListLoadByIdsSuccess = (
  state: ReportReduxModel.IState, inProgressExportListData: Array<ReportResponseModel.IExportData>,
) => {
  const newState = clone(state);
  const inProgressExportIds = inProgressExportListData.map((item) => item.ExportID);
  const currentExportListData = newState.exportListData;
  const exportListDataUpdatedWithInprogressData = currentExportListData?.map((item) => (inProgressExportIds.includes(item.ExportID) ? inProgressExportListData?.find((i) => i.ExportID === item.ExportID) : item));
  newState.exportListData = exportListDataUpdatedWithInprogressData;

  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_REQUEST] = APIRequestStatus.Success;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnExportListLoadFailure = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportActionTypes.EXPORT_LIST_LOAD_REQUEST] = APIRequestStatus.Failure;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnExportListLoadByIdsFailure = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  const newRequestProcessing = clone(newState.requestProcessing);
  newRequestProcessing[ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_REQUEST] = APIRequestStatus.Failure;
  newState.requestProcessing = newRequestProcessing;
  return newState;
};

const getStateOnRetryExportRequest = (state: ReportReduxModel.IState, exportID: string) => {
  const newState = clone(state);
  const newExportListData = state.exportListData;
  const indexOfReport = newExportListData.findIndex((rep) => rep.ExportID === exportID);
  newExportListData[indexOfReport].Status = ExportStatus.InProgress;
  newState.exportListData = newExportListData;
  return newState;
};

const getStateOnRetryExportSuccess = (state: ReportReduxModel.IState, exportID: string, isInProgress: string) => {
  if (!isInProgress) {
    const newState = clone(state);
    const newExportListData = state.exportListData;
    const indexOfReport = newExportListData.findIndex((rep) => rep.ExportID === exportID);
    newExportListData[indexOfReport].Status = ExportStatus.Failed;
    newState.exportListData = newExportListData;
    return newState;
  }
  return state;
};

const getStateOnRetryExportFailure = (state: ReportReduxModel.IState, exportID: string) => {
  const newState = clone(state);
  const newExportListData = state.exportListData;
  const indexOfReport = newExportListData.findIndex((rep) => rep.ExportID === exportID);
  newExportListData[indexOfReport].Status = ExportStatus.Failed;
  newState.exportListData = newExportListData;
  return newState;
};

const getStateOnUpdateTriggerExport = (state: ReportReduxModel.IState, exportFormatType: string) => {
  const newState = clone(state);
  newState.triggerExport = exportFormatType;
  return newState;
};

const dataRefreshDetailsLoadRequest = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  newState.requestProcessing[ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST] = APIRequestStatus.Processing;
  return newState;
};

const dataRefreshDetailsLoadSuccess = (state: ReportReduxModel.IState, jobDetails: IDataRefresh, isPolling?: boolean) => {
  const newState = clone(state);
  const newJobObject = newState.jobObject;
  const newJobsArray = [] as IJob[];
  const jobTypes = Object.keys(jobDetails) as JobTypes[];

  jobTypes.forEach((jobType: JobTypes, index) => {
    const today = moment();
    const dataRefreshed = jobDetails[jobType].LastRefreshed;
    const newRefreshTime = today.subtract(dataRefreshed, 'minutes').toDate();
    const prevDataRefreshTime = state?.jobObject?.jobsArray[index]?.Details?.LastRefreshed || dataRefreshed;
    const isRefreshRequiredAlreadySet = state?.jobObject?.jobsArray[index]?.isRefreshReq || false;

    const job: IJob = {
      type: jobType,
      Details: {
        LastRefreshTime: newRefreshTime,
        //  we are multiplying refresh interval by 3 for load job but we will start polling on actual interval -SIERA-2802
        NextRefreshInterval: jobType === JobTypes.Load ? jobDetails[jobType].NextRefreshInterval * 3 : jobDetails[jobType].NextRefreshInterval,
        LastRefreshed: jobDetails[jobType].LastRefreshed,
      },
      InfoText: jobType === JobTypes.Load ? jobInfoTextLoad : jobInfoTextMerge,
      isRefreshReq: isPolling ? (isRefreshRequiredAlreadySet || prevDataRefreshTime > dataRefreshed) : false,
    };
    newJobsArray.push(job);
    if (jobTypes.length === 1 && jobTypes.length - 1 === index) {
      const extraJob = clone(job);
      extraJob.type = JobTypes.Merge;
      extraJob.InfoText = jobInfoTextMerge;
      newJobsArray.push(extraJob);
    }
  });
  newJobObject.headerName = 'Data Update';
  newJobObject.jobsArray = newJobsArray;
  newState.jobObject = newJobObject;
  newState.requestProcessing[ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST] = APIRequestStatus.Success;
  return newState;
};

const dataRefreshDetailsLoadFailure = (state: ReportReduxModel.IState) => {
  const newState = clone(state);
  newState.requestProcessing[ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST] = APIRequestStatus.Failure;
  return newState;
};
