import React from 'react';

type Props = {
    width: number;
    height: number;
}

const NewViewIcon = ({
  width, height,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.36799 8.41868L1.26299 8.63611L1.36799 8.85354C2.12965 10.4308 3.28607 11.706 4.82431 12.6757C6.37077 13.6507 8.10039 14.1361 10.0001 14.1361C11.8997 14.1361 13.6293 13.6507 15.1758 12.6757C16.714 11.706 17.8705 10.4308 18.6321 8.85354L18.7371 8.63611L18.6321 8.41868C17.8705 6.84146 16.714 5.56626 15.1758 4.59648C13.6293 3.62152 11.8997 3.13611 10.0001 3.13611C8.10039 3.13611 6.37077 3.62152 4.82431 4.59648C3.28607 5.56626 2.12965 6.84146 1.36799 8.41868ZM10.0001 11.9694C10.9942 11.9694 11.8625 11.6549 12.5745 11.0168C13.2886 10.3767 13.6612 9.57413 13.6612 8.63611C13.6612 7.69809 13.2886 6.89549 12.5745 6.25544C11.8625 5.61733 10.9942 5.30277 10.0001 5.30277C9.00586 5.30277 8.13757 5.61733 7.42563 6.25544C6.71153 6.89549 6.3389 7.69809 6.3389 8.63611C6.3389 9.57413 6.71153 10.3767 7.42563 11.0168C8.13757 11.6549 9.00586 11.9694 10.0001 11.9694ZM10.0001 10.0028C9.53028 10.0028 9.15925 9.86106 8.85547 9.58878C8.55406 9.31862 8.41741 9.01051 8.41741 8.63611C8.41741 8.26171 8.55406 7.95359 8.85547 7.68344C9.15925 7.41116 9.53028 7.26944 10.0001 7.26944C10.4698 7.26944 10.8409 7.41116 11.1446 7.68344C11.4461 7.95359 11.5827 8.26171 11.5827 8.63611C11.5827 9.01051 11.4461 9.31862 11.1446 9.58878C10.8409 9.86106 10.4698 10.0028 10.0001 10.0028ZM13.8871 11.1116C12.7074 11.7939 11.4151 12.1361 10.0001 12.1361C8.58502 12.1361 7.29267 11.7939 6.113 11.1116C5.01507 10.4766 4.16365 9.65195 3.5487 8.63611C4.16365 7.62027 5.01507 6.79563 6.113 6.16059C7.29267 5.47827 8.58502 5.13611 10.0001 5.13611C11.4151 5.13611 12.7074 5.47827 13.8871 6.16059C14.9855 6.79588 15.8313 7.62057 16.4354 8.63611C15.8313 9.65164 14.9855 10.4763 13.8871 11.1116Z"
      fill="white"
      stroke="#006FD6"
    />
    <path
      d="M13.8059 7.97292V8.32292H14.1559H14.9352H15.2852V7.97292V5.07617H18.1819H18.5319V4.72617V3.94695V3.59695H18.1819H15.2852V0.700195V0.350195H14.9352H14.1559H13.8059V0.700195V3.59695H10.9092H10.5592V3.94695V4.72617V5.07617H10.9092H13.8059V7.97292Z"
      fill="white"
      stroke="#006FD6"
      strokeWidth="0.7"
    />
  </svg>

);

export default NewViewIcon;
