import React, { useState, useEffect } from 'react';
import Tooltip from 'rc-tooltip';
import StyledUnderlyingDataComponent from 'components/common/visualization/other/UnderlyingDataTable/underlying-data.styled';
import { GridModel, ObjModel, FilterModel } from 'core/models';
import Grid from 'components/common/visualization/Grid/grid.component';
import UnderlyingIcon from 'components/common/svg/UnderLying/underlying.svg';
import { IAggregatesData } from 'core/models/report-response';

export const RawDataComponent = ({
  columns, rowData, label, onColumnReorder, sorting, activeFilters, AllowUserDrillDown, activeDimensionsLength, onCellClicked,
  allowAutodrilldown, aggregatesData,
}: {
  rowData: Array<ObjModel.Obj>,
  columns: Array<GridModel.IColumnConfig>,
  label:any,
  onColumnReorder: (reorderedHeaderNames: Array<string>) => void;
  sorting: GridModel.ISorting;
  activeFilters:ObjModel.ObjGeneric<FilterModel.IFilterResponse>;
  AllowUserDrillDown:boolean;
  activeDimensionsLength:number,
  onCellClicked: (e: ObjModel.Obj) => void;
  allowAutodrilldown: boolean,
  aggregatesData:IAggregatesData
}) => {
  const [showData, setShowData] = useState<boolean>(true);
  const toggleDataShow = () => { setShowData((prev) => !prev); };

  const rawDataGridContainerId = 'rawDataGridContainer';

  useEffect(() => {
    const target = document.getElementById(rawDataGridContainerId);
    if (target) {
      target.style.marginTop = showData ? '0px' : `-${target.clientHeight}px`;
      target.style.transitionDuration = target.clientHeight > 600 ? '0.7s' : '0.5s';
    }
  });

  return (
    <StyledUnderlyingDataComponent>
      <div className="se-rawdata-viewer-container">
        <div className="se-rawdata-viewer-header">
          <div />
          <div className="se-values-table">
            <span>{label}</span>
          </div>
          <Tooltip
            placement="left"
            overlayClassName="abc"
            overlay={(
              <span>
                {`${showData ? 'Collapse' : 'Expand'}`}
              </span>
            )}
            mouseEnterDelay={1.5}
          >
            <button
              className={`se-rawdata-viewer-button ${showData ? 'active' : ''}`}
              onClick={toggleDataShow}
              type="button"
            >
              <UnderlyingIcon
                width={16}
                height={20}
              />
            </button>
          </Tooltip>
        </div>
        <div
          className="se-rawdata-body-cn"
        >
          <div
            id={rawDataGridContainerId}
            className="se-rawdata-viewer-body ag-theme-alpine ag-grid-cn"
          >
            <Grid
              columns={columns}
              rowData={rowData}
              onColumnReorder={onColumnReorder}
              sorting={sorting}
              activeFilters={activeFilters}
              AllowUserDrillDown={AllowUserDrillDown}
              activeDimensionsLength={activeDimensionsLength}
              pivotMode={false}
              onCellClicked={onCellClicked}
              allowAutodrilldown={allowAutodrilldown}
              aggregatesData={aggregatesData}
            />
          </div>
        </div>

      </div>

    </StyledUnderlyingDataComponent>

  );
};

export default RawDataComponent;
