import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const FilterIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="filter_alt_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 21.839 21.839"
  >
    <path
      id="Path_7608"
      data-name="Path 7608"
      d="M0,0H21.839m0,21.839H0"
      style={{
        fill: 'none',
      }}
    />
    <path
      id="Path_7609"
      data-name="Path 7609"
      d="M6.74,5.824h9.121l-4.57,5.746ZM4.231,5.468c1.842,2.362,5.245,6.74,5.245,6.74v5.472a.915.915,0,0,0,.912.912h1.824a.915.915,0,0,0,.912-.912V12.209s3.393-4.378,5.236-6.74A.91.91,0,0,0,17.639,4H4.952A.91.91,0,0,0,4.231,5.468Z"
      transform="translate(-0.381 -0.377)"
      fill={fill}
      className="icon-stroke"
    />
    <path
      id="Path_10830"
      data-name="Path 10830"
      d="M4.215,5.347c1.69,2.167,4.812,6.184,4.812,6.184v5.021a.839.839,0,0,0,.837.837h1.674a.839.839,0,0,0,.837-.837V11.531s3.113-4.017,4.8-6.184A.835.835,0,0,0,16.517,4H4.877A.835.835,0,0,0,4.215,5.347Z"
      transform="translate(-0.701 -0.694)"
      fill="none"
      className="icon-active"
    />
    <path
      id="Path_7610"
      data-name="Path 7610"
      d="M0,0H21.839V21.839H0Z"
      style={{
        fill: 'none',
      }}
    />
  </svg>
);

FilterIcon.defaultProps = {
  fill: '#454f5b',
};

export default FilterIcon;
