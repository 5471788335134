import React from 'react';
import { ReportActionData, ReportActionTypes } from 'core/constants/report';
import TripledotIcon from 'components/common/svg/Tripledot/tripledot.component';
import { IProps } from 'components/feature/ReportListing/ReportSection/ReportCard/ReportAction/report-action.d';
import StyledComponent from 'components/feature/ReportListing/ReportSection/ReportCard/ReportAction/report-action.style';
import StoreConnector from 'components/feature/ReportListing/ReportSection/ReportCard/ReportAction/report-action.redux';
import CustomSelect from 'components/common/select/CustomSelect/custom-select.component';

const ReportAction = (props: IProps) => {
  const { report, reduxAction } = props;
  const { reportPinUnpinRequest } = reduxAction;

  const options = ReportActionData(report.IsPinned);

  const handleChange = (key: string, value: ReportActionTypes) => {
    switch (value) {
      case ReportActionTypes.Pin:
        reportPinUnpinRequest(report.ReportId, true);
        break;
      case ReportActionTypes.Unpin:
        reportPinUnpinRequest(report.ReportId, false);
        break;
      default:
        break;
    }
  };

  return (
    <StyledComponent>
      <CustomSelect
        config={{
          Key: 'Action',
          Toggler: (
            <TripledotIcon
              width={13}
              height={13}
            />
          ),
          Options: options,
          ClassNames: {
            Container: 'se-report-action-select-cn',
            Menu: 'se-select-menu',
          },
          RenderOption: (option) => {
            const { label } = option;
            return (
              <div
                className={`se-select-option se-track-${label}`}
              >
                <div
                  className="se-option-icon se-icon-cn"
                >
                  <option.icon
                    width={20}
                    height={20}
                    fill="#637381"
                  />
                </div>
                <div
                  className="se-option-label"
                >
                  {label}
                </div>
              </div>
            );
          },
        }}
        value=""
        onChange={handleChange}
      />
    </StyledComponent>
  );
};

export default StoreConnector()(ReportAction);
