import React, { HTMLAttributes } from 'react';
import CheckboxIcon from 'components/common/svg/Checkbox/checkbox.svg';
import {
  ISingleSelectItemProps,
} from 'components/feature/Report/ReportSidebar/common/SingleSelectList/SingleSelectItem/single-select-item.d';
import StyledContainer, {
  selectedBackgroundColor,
} from 'components/feature/Report/ReportSidebar/common/SingleSelectList/SingleSelectItem/single-select-item.style';

const SingleSelectItem = <T, >(props: ISingleSelectItemProps<T>) => {
  const {
    text, data,
    isSelected, isDisabled,
    onSelectItem,
  } = props;
  const tooltipProps = (
    ({
      onMouseEnter, onMouseLeave, onFocus, onClick,
    }: HTMLAttributes<HTMLDivElement>) => ({
      onMouseEnter, onMouseLeave, onFocus, onClick,
    })
  )(props);

  let classes = '';
  if (isSelected && !isDisabled) {
    classes += 'selected';
  }
  if (isDisabled) {
    classes += ' disabled';
  }

  return (
    <StyledContainer>
      <div
        // TODO: make this a button
        // Passing SingleSelectItem's browser events to inner div for tooltip.
        // See https://github.com/react-component/tooltip#important-note.
        {...tooltipProps}
        className={`se-single-select-item ${classes}`}
        onClick={() => !isDisabled && onSelectItem(data)}
        onKeyPress={(e) => (e.key === 'Enter' || e.key === ' ') && !isDisabled && onSelectItem(data)}
        role="menuitemradio"
        aria-checked={isSelected}
        tabIndex={0}
      >
        <div className="se-item-name">
          {text}
        </div>
        <div className="se-icon-cn">
          {
            isSelected && (
              <CheckboxIcon
                width={16}
                height={16}
                fill1="#116FD6"
                fill2={!isDisabled ? selectedBackgroundColor : '#fff'}
                stroke={!isDisabled ? selectedBackgroundColor : '#fff'}
              />
            )
          }
        </div>
      </div>
    </StyledContainer>
  );
};

export default SingleSelectItem;
