import React from 'react';
import moment from 'moment';
import {
  DatePayloadFormat, IDateRangeTypes, IRelativeDateRanges,
} from 'core/constants/date';
import { IDateRangePicker } from 'core/models/filter';
import { evaluatePredefinedDateRange, isValidDateRangePayload } from 'core/utils/date.util';
import { IDateRangeWrapperProps } from 'components/common/FilterBuilder/DateRangeWrapper/date-range-wrapper.d';
import DateRange from 'components/common/DatePicker/DateRange/date-range.component';

const DateRangeWrapper = (props: IDateRangeWrapperProps) => {
  const {
    config, value, onChange, addBorderClass, removeBorderClass,
  } = props;

  let absoluteValue = null;
  let relativeValue = null;
  if (isValidDateRangePayload(value)) {
    if (value.Type === IDateRangeTypes.Absolute) {
      absoluteValue = value.Value;
    } else {
      absoluteValue = evaluatePredefinedDateRange(value.Value);
      relativeValue = value.Value;
    }
  }

  const handleAbsoluteRangeChange = (key: string, range: IDateRangePicker) => {
    onChange(
      config.Key,
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: moment(range.startDate).format(DatePayloadFormat),
          To: moment(range.endDate).endOf('day').format(DatePayloadFormat),

        },
      },
    );
  };

  const handleRelativeRangeChange = (key: string, range: IRelativeDateRanges) => {
    onChange(
      config.Key,
      {
        Type: IDateRangeTypes.Relative,
        Value: range,
      },
    );
  };

  return (
    <DateRange
      config={config}
      value={absoluteValue}
      onChangeAbsoluteRange={handleAbsoluteRangeChange}
      onChangeRelativeRange={handleRelativeRangeChange}
      relativeValue={relativeValue}
      hasCustomRange
      addBorderClass={addBorderClass}
      removeBorderClass={removeBorderClass}
    />
  );
};

export default DateRangeWrapper;
