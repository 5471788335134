/* This component will render the Reports with Basic tables. This component will be stateless
component and states are maintained at parent wrapper component */

import React from 'react';
import Grid from 'components/common/visualization/Grid/grid.component';

export default function SummmaryTable(props: any) {
  // It will contain the configuraiton specific to  summary table report.
  const summaryTableConfig = {};

  // All methods specific to this component will be below

  return (
    <div className="se-summary-table">
      <Grid
        {...props}
        specificconfig={summaryTableConfig}
      />
    </div>
  );
}
