import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IActionButton } from 'components/common/ButtonsDropdown/buttons-dropdown.d';
import ButtonsDropdown from 'components/common/ButtonsDropdown/buttons-dropdown.component';
import MainCsvIcon from 'components/common/svg/MainCsvExport/mainCsvExport.svg';
import QuickCsvIcon from 'components/common/svg/QuickCsvExport/quickCsvExport.svg';
import QuickExcelIcon from 'components/common/svg/QuickExcelExport/quickExcelExport.svg';
import LargeExportIcon from 'components/common/svg/Export/largeexport.svg';
import { exportListRequest, updateTriggerExport } from 'redux/report/actions';
import { dropdownExportButtonNames, formatType } from 'core/constants/report';
import { IExportButtonsProps } from 'components/feature/Report/ReportHeader/ExportButtonsComponent/export-buttons.d';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';
import { ReduxModel } from 'core/models';
import { APIRequestStatus } from 'core/constants/redux';
import { isEmpty } from 'lodash';
import { ReportActionTypes } from 'core/constants/redux-action-types';
import PngChartIcon from 'components/common/svg/PngChart/png-chart-icon.svg';
import JpgChartIcon from 'components/common/svg/JpgChart/jpg-chart-icon.svg';
import PdfChartIcon from 'components/common/svg/PdfChart/pdf-chart-icon.svg';
import { VisualizationTypes } from 'core/constants/visualizations';

const ExportButtons = (props: IExportButtonsProps) => {
  const { setOpenPannel } = props;
  const dispatch = useDispatch();

  const exportClick = () => {
    setOpenPannel(true);
    dispatch(exportListRequest());
  };

  const [requestProcessing, reportData, reportConfig] = useSelector((state: ReduxModel.IGlobalState) => [state.report.requestProcessing, state.report.reportData, state.report.reportConfig] as const);

  const {
    [ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST]: configLoadStatus,
    [ReportActionTypes.REPORT_DATA_LOAD_REQUEST]: reportDataLoadStatus,
    [ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST]: autodrilldownDataLoadStatus,
  } = requestProcessing;
  const csvClick = () => dispatch(updateTriggerExport(formatType.CSV));
  const excelClick = () => dispatch(updateTriggerExport(formatType.Excel));
  const pngClick = () => dispatch(updateTriggerExport(formatType.PNG));
  const jpgClick = () => dispatch(updateTriggerExport(formatType.JPG));
  const pdfClick = () => dispatch(updateTriggerExport(formatType.PDF));
  const mainClickHandler = () => dispatch(updateTriggerExport(formatType.CSV));

  const filterError = Object.keys(requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST])
    .findIndex((item) => (requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST][item]
          === APIRequestStatus.Failure)) !== -1;

  const checkDisabled = () => (reportData && reportData.Raw && isEmpty(reportData.Raw.Data)) || reportDataLoadStatus === APIRequestStatus.Failure || configLoadStatus === APIRequestStatus.Failure || autodrilldownDataLoadStatus === APIRequestStatus.Failure || filterError;

  const isChartExportVisible = reportConfig?.Visualization?.Type === VisualizationTypes.ColumnChart
  || reportConfig?.Visualization?.Type === VisualizationTypes.AreaChart
  || reportConfig?.Visualization?.Type === VisualizationTypes.BarChart
  || reportConfig?.Visualization?.Type === VisualizationTypes.LineChart;

  const actionButtonsArray:IActionButton[] = [
    {
      name: dropdownExportButtonNames.PNG,
      onClickHandler: pngClick,
      svgComponent: <PngChartIcon width={20} height={20} />,
      topborderRequire: false,
      pendoClass: PendoClassNames.PngChartExport,
      isVisible: isChartExportVisible,
    },
    {
      name: dropdownExportButtonNames.JPG,
      onClickHandler: jpgClick,
      svgComponent: <JpgChartIcon width={20} height={20} />,
      topborderRequire: false,
      pendoClass: PendoClassNames.JpgChartExport,
      isVisible: isChartExportVisible,
    },
    {
      name: dropdownExportButtonNames.PDF,
      onClickHandler: pdfClick,
      svgComponent: <PdfChartIcon width={20} height={20} />,
      topborderRequire: false,
      pendoClass: PendoClassNames.PdfChartExport,
      isVisible: isChartExportVisible,
    },
    {
      name: dropdownExportButtonNames.CSV,
      onClickHandler: csvClick,
      svgComponent: <QuickCsvIcon width={20} height={20} />,
      topborderRequire: isChartExportVisible,
      pendoClass: PendoClassNames.QuickCsvExport,
      isDisabled: checkDisabled(),
      isVisible: true,
    },
    {
      name: dropdownExportButtonNames.Excel,
      onClickHandler: excelClick,
      svgComponent: <QuickExcelIcon width={20} height={20} />,
      topborderRequire: false,
      pendoClass: PendoClassNames.QuickExcelExport,
      isDisabled: checkDisabled(),
      isVisible: true,
    },
    {
      name: dropdownExportButtonNames.Export,
      onClickHandler: exportClick,
      svgComponent: <LargeExportIcon width={20} height={20} fill="#637381" />,
      topborderRequire: true,
      pendoClass: PendoClassNames.LargeExportButton,
      isVisible: true,
    },
  ];

  return (
    <ButtonsDropdown
      mainSvgComponent={<MainCsvIcon height={14} width={14} />}
      actionButtons={actionButtonsArray}
      mainBtnClick={mainClickHandler}
      mainPendoClass={PendoClassNames.ExportButton}
      isDisabled={checkDisabled()}
    />
  );
};

export default ExportButtons;
