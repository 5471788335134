import React from 'react';
import ColumnGroupingIcon from 'components/common/svg/ColumnGrouping/column-grouping.svg';
import ListIcon from 'components/common/svg/List/list.svg';
import RowGroupingIcon from 'components/common/svg/RowGrouping/row-grouping.svg';
import { ExpandListButtonContext } from 'core/constants/report';
import ValuesIcon from 'components/common/svg/Values/value.svg';

const getIcon = (context: ExpandListButtonContext) => {
  if (context === ExpandListButtonContext.ColGroup) {
    return (
      <ColumnGroupingIcon
        width={18}
        height={20}
        fill="var(--primaryColor)"
      />
    );
  }
  if (context === ExpandListButtonContext.RowGroup) {
    return (
      <RowGroupingIcon
        width={18}
        height={20}
        fill="var(--primaryColor)"
      />
    );
  }
  if (context === ExpandListButtonContext.Drilldown) {
    return (
      <span className="se-drilldown-icon">
        <RowGroupingIcon
          width={18}
          height={20}
          fill="var(--primaryColor)"
        />
      </span>
    );
  }
  if (context === ExpandListButtonContext.Values) {
    return (
      <ValuesIcon
        width={20}
        height={20}
        fill="var(--primaryColor)"
      />
    );
  }
  return (
    <ListIcon
      width={20}
      height={20}
      fill="var(--primaryColor)"
    />
  );
};

export default getIcon;
