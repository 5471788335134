import React from 'react';
import {
  dataUpdate, JobTypes, loadNewData, updateNewData,
} from 'core/constants/report';
import { Minutesformatter } from 'core/utils/formatters.utility';
import JobCompletedIcon from '../svg/JobCompletedIcon/job-completed-icon.svg';
import { IJobProps } from './job-info-types';
import StyledContainer from './job-info.styled';

const JobInfoComponent = ((props: IJobProps) => {
  const {
    type, isRefreshReq, infoText, showDivider, LastRefreshed, NextRefreshInterval,
  } = props;
  return (
    <StyledContainer>
      <div className="se-job-details">
        <div className="se-job-details-cn">
          {isRefreshReq
            ? (
              <div className="se-job-completed-cn">
                <JobCompletedIcon
                  width={22}
                  height={17}
                  fill={type === JobTypes.Load ? '#116FD6' : '#3BB345'}
                />
                <div className="se-job-completed-text">
                  {dataUpdate}
                </div>
                <div>
                  {type === JobTypes.Load ? loadNewData : updateNewData}
                </div>
              </div>
            )
            : (
              <div className="se-job-details-content-cn">
                <div>
                  <div>
                    {`${type === JobTypes.Load ? 'New' : 'Modified'} Data Refreshed :`}
                    <span className={type === JobTypes.Load ? 'se-blue-text' : 'se-green-text'}>
                      {` ${Minutesformatter(LastRefreshed)} ago`}
                    </span>
                  </div>
                  <div>
                    Data Refresh Interval :
                    <span className={type === JobTypes.Load ? 'se-blue-text' : 'se-green-text'}>
                      {` ~ ${Minutesformatter(NextRefreshInterval)}`}
                    </span>
                  </div>
                </div>
                <div className="se-info-example-divider" />
                <div className="se-example-cn">
                  {`${infoText}${Minutesformatter(NextRefreshInterval)}`}
                </div>
              </div>
            )}
          { showDivider && (
          <div className="se-divider-cn">
            <div className="se-job-details-divider" />
            <div className="se-job-example-divider" />
          </div>
          )}
        </div>
      </div>
    </StyledContainer>
  );
});
export default JobInfoComponent;
