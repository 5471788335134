import styled from 'styled-components';

const StyledContainer = styled.div`
width: 100%;
.se-date-range-cn {
  position: relative;
  width: 100%;
  height: 32px;

  .se-date-range-toggler {
    width: 100%;
    height: 100%;
    border: 1px solid var(--base-date-picker-border);
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 12px;
    font-size: 13px;
    font-family: Regular;
    color: var(--backButtonColor);
    background-color: transparent;
    overflow: hidden;
    box-shadow: none;
    text-align: left;

    :hover {
      border-color: #116FD6;
    }

    .se-date-range-label {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 8px;
    }

    :disabled {
      background: #F9FAFB;
    }
  }

  .se-date-range-selector-cn {
    position: absolute;
    right: 0;
    display: flex;
    z-index: 2;
    box-shadow: 0px 2px 4px #212B3633;
    font-family: Regular;
    background-color: #fff;



    >div {
      flex: 1;
    }
  }

  .se-date-range-selector-cn.hide {
    display: none ;
  }

  .se-date-range-picker {

    >div {
      box-shadow: none;
    }

    >div>div>div:nth-child(2) {
      display: none;
    }

    >div>div>div {
      width: 100%;

      hr {
        display: none
      }
    }

    >div>div>div>div:nth-child(1) {
      padding: 6px;

      >div {
        padding: 8px;
        font-size: 13px;
        color: #454F5B;
        font-family: SemiBold;
        background: #E5F0FB;

        h6 {
          display: none;
        }

        :nth-child(1) {
          margin-right: 6px;

          ::after {
            content: 'From'
          }
        }

        :nth-child(2) {
          display: none;
        }

        :nth-child(3) {
          margin-left: 6px;

          ::after {
            content: 'To'
          }
        }

      }
    }

    >div>div>div>div:nth-child(3) {
      >div {

        >div {

          >div {

            :nth-child(2) {
              span {
                font-family: SemiBold;
                font-size: 13px;
                color: #454F5B;
              }
            }

            :nth-child(3) {
              margin-bottom: 7px;
              button {
                border-color: #99c5ef;

                p {
                  font-family: Regular;
                  font-size: 13px;
                  color: #454F5B;
                }

                :disabled {
                  p {
                    color: #ADBAC7;
                  }
                }

                :hover {
                  background-color: #E5F0FB;   
                }
              }
            }

            :nth-child(1) {
              width: 100%;
              display: flex;
              align-items: center;
              padding: 0 20px;

              >div {
                :nth-child(1), :nth-child(4) {
                  flex: 1;
                  display: flex;
                  align-item: center;
                  padding: 0;
  
                  button {
                    padding: 0;
  
                    svg {
                      width: 20px;
                      height: 20px;
                      color: #006FD6;
                    }
  
                    :disabled {
                      svg {
                        opacity: 0.5;
                      }
                    }
                  }
                }

                :nth-child(1) {
                  justify-content: start;
                }

                :nth-child(4) {
                  justify-content: end;
                }
  
                :nth-child(2), :nth-child(3) {
                  >div {
                    font-size: 13px;
                    color: #454F5B;
                    font-family: SemiBold;
  
                    ::before {
                      border: 0;
                    }
  
                    >div:nth-child(1) {
                      padding-right: 20px;
                    }
  
                    svg {
                      width: 20px;
                      height: 20px;
                      color: #ADBAC7;
                    }

                    ul {
                      z-index: 3;
                      li {
                        font-family: SemiBold;
                        font-size: 13px;
                      }
                    }
                  }
                }

                :nth-child(2) {
                  margin-right: 10px;
                }

                :nth-child(3) {
                  margin-left: 10px;
                }
              }
            }
          }
        }

        :nth-child(2) {
          display: none;
        }
      }
    }

    button[class^="materialui-daterange-picker-makeStyles-filled"],
    button[class*="materialui-daterange-picker-makeStyles-filled"] {
      background-color: #99C5EF !important; 
    }

    div[class*="materialui-daterange-picker-makeStyles-highlighted"] {
      background-color: #E5F0FB80 !important;
    }
  }

  .se-custom-ranges {
    padding: 6px 6px 6px 0px;
    position: relative;
    background: #fff;
    height: 320px;
    z-index:3
  }

  
  @media (min-width: 320px) {
    .se-date-range-selector-cn {
      min-width: 300px;  
    }
  }

  @media (min-width: 768px) {
    .se-date-range-selector-cn {
      min-width: 720px;
    }  
  }

  @media (min-width: 1024px) {
    .se-date-range-selector-cn {
      min-width: 720px;  
    }
  }

  .se-add-green-border {
    border-color: #3BB345 !important;
  }

  .se-remove-red-border {
    border-color: #D1331B !important;
  }
}
`;
export default StyledContainer;
