import { setReportViewDataInURL } from 'core/utils/report.util';
import { ReportService } from 'services/report.service';
import {
  IReportView, ICreateReportView, IReportViewInfo, IReportInfoPayload, IFetchReportPayload, IReportViewSuccessPayload, IShareOrUnshareReportView, IDeleteReportView,
} from 'types/report-view.type';

export enum ReportViewAction {
  REQUEST_REPORTVIEW = '[ReportView] REQUEST_REPORTVIEW',
  REQUEST_REPORTVIEW_SUCCESS = '[ReportView] REQUEST_REPORTVIEW_SUCCESS',
  REQUEST_REPORTVIEW_FAILURE = '[ReportView] REQUEST_REPORTVIEW_FAILURE',
  DELETE_REPORTVIEW = '[ReportView] DELETE_REQUEST_REPORTVIEW',
  DELETE_REPORTVIEW_SUCCESS = '[ReportView] DELETE_REQUEST_REPORTVIEW_SUCCESS',
  DELETE_REPORTVIEW_FAILURE = '[ReportView] DELETE_REQUEST_REPORTVIEW_FAILURE',
  DELETE_REPORTVIEW_COMPELETE = '[ReportView] DELETE_REQUEST_REPORTVIEW_COMPELETE',
  UNDO_REPORTVIEW ='[ReportView] UNDO_REQUEST_REPORTVIEW_COMPELETE',
  CREATE_REPORTVIEW = '[ReportView] CREATE_REPORTVIEW',
  CREATE_REPORTVIEW_SUCCESS = '[ReportView] CREATE_REPORTVIEW_SUCCESS',
  CREATE_REPORTVIEW_FAILURE = '[ReportView] CREATE_REPORTVIEW_FAILURE',
  REQUEST_REPORTVIEW_INFO = '[ReportView] REQUEST_REPORTVIEW_INFO',
  REQUEST_REPORTVIEW_INFO_SUCCESS = '[ReportView] REQUEST_REPORTVIEW_INFO_SUCCESS',
  REQUEST_REPORTVIEW_INFO_FAILURE = '[ReportView] REQUEST_REPORTVIEW_INFO_FAILURE',
  UPDATE_PERSONALISED_DATA = '[ReportView] UPDATE_PERSONALISED_DATA',
  RESET_REPORT_VIEW = '[ReportView] RESET_REPORT_VIEW',
  SHARE_OR_UNSHARE_REPORT_VIEW = '[ReportView] SHARE_OR_UNSHARE_REPORT_VIEW',
  SHARE_OR_UNSHARE_REPORT_VIEW_SUCCESS = '[ReportView] SHARE_OR_UNSHARE_REPORT_VIEW_SUCCESS',
  SHARE_OR_UNSHARE_REPORT_VIEW_FAILURE = '[ReportView] SHARE_OR_UNSHARE_REPORT_VIEW_FAILURE',
  SHARE_OR_UNSHARE_REPORT_VIEW_COMPLETE = '[ReportView] SHARE_OR_UNSHARE_REPORT_VIEW_COMPLETE',
}

export interface RequestReportViewAction {
  type: typeof ReportViewAction.REQUEST_REPORTVIEW;
}

export interface RequestReportViewSuccessAction {
  type: typeof ReportViewAction.REQUEST_REPORTVIEW_SUCCESS;
  payload: IReportView[];
}

export interface RequestReportViewFailureAction {
  type: typeof ReportViewAction.REQUEST_REPORTVIEW_FAILURE;
}

export interface DeleteReportViewRequest {
  type: ReportViewAction.DELETE_REPORTVIEW;
}

export interface DeleteReportViewRequestSuccess {
  type: ReportViewAction.DELETE_REPORTVIEW_SUCCESS;
  payload: IDeleteReportView;
}

export interface DeleteReportViewRequestFailure {
  type: ReportViewAction.DELETE_REPORTVIEW_FAILURE;
}

export interface DeleteReportViewRequestCompletion {
  type: ReportViewAction.DELETE_REPORTVIEW_COMPELETE;
  payload: string;
}
export interface CreateReportViewAction {
  type: typeof ReportViewAction.CREATE_REPORTVIEW;
  payload: ICreateReportView;
}

export interface CreateReportViewSuccessAction {
  type: typeof ReportViewAction.CREATE_REPORTVIEW_SUCCESS;
  payload: IReportView;
}

export interface CreateReportViewFailureAction {
  type: typeof ReportViewAction.CREATE_REPORTVIEW_FAILURE;
}

export interface RequestReportViewInfoAction {
  type: typeof ReportViewAction.REQUEST_REPORTVIEW_INFO;
  payload: 'tooltip' | 'reportSelect';
}
export interface RequestReportViewInfoSuccessAction {
  type: typeof ReportViewAction.REQUEST_REPORTVIEW_INFO_SUCCESS;
  payload: IReportViewSuccessPayload;
}
export interface RequestReportViewInfoFailureAction {
  type: typeof ReportViewAction.REQUEST_REPORTVIEW_INFO_FAILURE;
}
export interface UpdatePersonalisedDataAction {
  type: typeof ReportViewAction.UPDATE_PERSONALISED_DATA;
  payload: IFetchReportPayload
}

export interface RestReportViewAction {
  type: typeof ReportViewAction.RESET_REPORT_VIEW;
}

export interface ShareOrUnshareReportViewAction {
  type: typeof ReportViewAction.SHARE_OR_UNSHARE_REPORT_VIEW;
}

export interface ShareOrUnshareReportViewSuccessAction {
  type: typeof ReportViewAction.SHARE_OR_UNSHARE_REPORT_VIEW_SUCCESS;
  payload: IShareOrUnshareReportView;
}

export interface ShareOrUnshareReportViewFailureAction {
  type: typeof ReportViewAction.SHARE_OR_UNSHARE_REPORT_VIEW_FAILURE;
}

export interface ShareOrUnshareReportViewCompletion {
  type: ReportViewAction.SHARE_OR_UNSHARE_REPORT_VIEW_COMPLETE;
  payload: string;
}

export type ReportViewActionTypes =
  | RequestReportViewAction
  | RequestReportViewSuccessAction
  | RequestReportViewFailureAction
  | DeleteReportViewRequest
  | DeleteReportViewRequestSuccess
  | DeleteReportViewRequestFailure
  | DeleteReportViewRequestCompletion
  | CreateReportViewAction
  | CreateReportViewSuccessAction
  | CreateReportViewFailureAction
  | RequestReportViewInfoAction
  | RequestReportViewInfoSuccessAction
  | RequestReportViewInfoFailureAction
  | RestReportViewAction
  | UpdatePersonalisedDataAction
  | ShareOrUnshareReportViewAction
  | ShareOrUnshareReportViewSuccessAction
  | ShareOrUnshareReportViewFailureAction
  | ShareOrUnshareReportViewCompletion

// Sync Action

export const requestReportViewList = (): ReportViewActionTypes => ({ type: ReportViewAction.REQUEST_REPORTVIEW });

export const requestReportViewSuccess = (payload: IReportView[]): ReportViewActionTypes => ({
  type: ReportViewAction.REQUEST_REPORTVIEW_SUCCESS,
  payload,
});

export const requestReportViewFailure = (): ReportViewActionTypes => ({
  type: ReportViewAction.REQUEST_REPORTVIEW_FAILURE,
});

export const deleteReportView = (): ReportViewActionTypes => ({
  type: ReportViewAction.DELETE_REPORTVIEW,
});

export const deleteReportViewSuccess = (payload: IDeleteReportView): ReportViewActionTypes => ({
  type: ReportViewAction.DELETE_REPORTVIEW_SUCCESS,
  payload,
});

export const deleteReportViewFailure = (): ReportViewActionTypes => ({ type: ReportViewAction.DELETE_REPORTVIEW_FAILURE });

export const deleteReportViewCompletion = (payload: string): ReportViewActionTypes => ({
  type: ReportViewAction.DELETE_REPORTVIEW_COMPELETE,
  payload,
});

export const createReportView = (payload: ICreateReportView): ReportViewActionTypes => ({
  type: ReportViewAction.CREATE_REPORTVIEW,
  payload,
});

export const createReportViewSuccess = (payload: IReportView): ReportViewActionTypes => ({
  type: ReportViewAction.CREATE_REPORTVIEW_SUCCESS,
  payload,
});

export const createReportViewFailure = (): ReportViewActionTypes => ({
  type: ReportViewAction.CREATE_REPORTVIEW_FAILURE,
});

export const requestReportViewInfo = (payload: 'tooltip' | 'reportSelect'): ReportViewActionTypes => ({
  type: ReportViewAction.REQUEST_REPORTVIEW_INFO,
  payload,
});

export const requestReportViewInfoSuccess = (payload: IReportViewSuccessPayload): ReportViewActionTypes => ({
  type: ReportViewAction.REQUEST_REPORTVIEW_INFO_SUCCESS,
  payload,
});

export const requestReportViewInfoFailure = (): ReportViewActionTypes => ({
  type: ReportViewAction.REQUEST_REPORTVIEW_INFO_FAILURE,
});

export const updatePersonalisedData = (payload: IFetchReportPayload): ReportViewActionTypes => ({
  type: ReportViewAction.UPDATE_PERSONALISED_DATA,
  payload,
});

export const resetReportview = (): ReportViewActionTypes => ({
  type: ReportViewAction.RESET_REPORT_VIEW,
});

export const shareUnshareReportView = (): ReportViewActionTypes => ({
  type: ReportViewAction.SHARE_OR_UNSHARE_REPORT_VIEW,
});

export const shareUnsareReportViewSuccess = (payload: IShareOrUnshareReportView): ReportViewActionTypes => ({
  type: ReportViewAction.SHARE_OR_UNSHARE_REPORT_VIEW_SUCCESS,
  payload,
});

export const shareUnsareReportViewFailure = (): ReportViewActionTypes => ({
  type: ReportViewAction.SHARE_OR_UNSHARE_REPORT_VIEW_FAILURE,
});

export const shareOrUnshareReportViewComplete = (payload: string): ReportViewActionTypes => ({
  type: ReportViewAction.SHARE_OR_UNSHARE_REPORT_VIEW_COMPLETE,
  payload,
});

// Aysnc action - API call using thunk

export const getReportViewList = (reportId: string) => async (dispatch: any) => {
  dispatch(requestReportViewList());
  new ReportService().getReportViews(reportId)
    .then((res) => dispatch(requestReportViewSuccess(res)))
    .catch(() => dispatch(requestReportViewFailure()));
};

export const getReportViewListInformation = (reportId: string) => async (dispatch: any) => {
  dispatch(requestReportViewList());
  new ReportService().getReportViews(reportId)
    .then((res) => dispatch(requestReportViewSuccess(res)))
    .catch(() => dispatch(requestReportViewFailure()));
};

export const DeleteReportView = (data: IDeleteReportView) => async (dispatch: any) => {
  dispatch(deleteReportView());
  new ReportService().deleteReportView(data)
    .then(() => dispatch(deleteReportViewSuccess(data)))
    .catch(() => dispatch(deleteReportViewFailure()));
};

export const createAndGetReportView = (data: ICreateReportView, userID: string) => async (dispatch: any) => {
  dispatch(createReportView(data));
  new ReportService().createReportView(data)
    .then((res) => {
      dispatch(createReportViewSuccess(res));

      window.history.pushState(null, null, setReportViewDataInURL(res.PersonalisationId, res.ReportViewType, userID));
    })
    .catch(() => dispatch(createReportViewFailure()));
};

export const getReportViewInfo = ({ personalisationId, context, ReportViewType }: IReportInfoPayload) => async (dispatch:any) => {
  dispatch(requestReportViewInfo(context));
  new ReportService().getReportViewInfoAPI(personalisationId, ReportViewType)
    .then((res:IReportViewInfo) => dispatch(requestReportViewInfoSuccess({ reportViewInfo: res, context, personalisationId })))
    .catch(() => dispatch(requestReportViewInfoFailure()));
};

export const shareAndUnshareReportView = (data: IShareOrUnshareReportView) => async (dispatch: any) => {
  dispatch(shareUnshareReportView());
  new ReportService().shareUnshareReportView(data)
    .then(() => dispatch(shareUnsareReportViewSuccess(data)))
    .catch(() => dispatch(shareUnsareReportViewFailure()));
};
