import styled from 'styled-components';

const StyledContainer = styled.div`
width: 100%;

.se-create-state-cn {
  width: 100%;
  margin-bottom: 12px;
  position: relative;
  box-sizing: border-box;

  .se-create-state {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px;
    border: 1px solid #DFE3E8;
    border-radius: 2px;
  }

  .se-label-input-cn {
    position: relative;
    margin: 0 13px;
    flex: 1;
    max-height: 22px;

    input {
      width: 100%;
      font-size: 13px;
      font-family: Regular;
      color: #454F5B;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-width: 0.3px;
      outline: none;
      border-color: #DFE3E8;
      background: #fff;

      :focus-visible {
        border-color: #006FD6;
      }

      ::placeholder {
        color: #919EAB;
      }

      :focus-visible ~ .se-info-desc {
        display: block;
      }

    }

  }

  .se-invalid-label-info {
    color: #DE323B;
    font-size: 11px;
    margin-top: 8px;
  }
}

.se-create-state-cn.disabled {
  pointer-events: none;
}
`;

export default StyledContainer;
