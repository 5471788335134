import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const NoFiltersIcon = ({
  width, height, fill,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 44 44">
    <g id="filter_alt_off_black_24dp" transform="translate(0.307 0.307)">
      <g id="Group_19060" data-name="Group 19060">
        <rect id="Rectangle_6513" data-name="Rectangle 6513" width={width} height={height} transform="translate(-0.307 -0.307)" fill="none" />
      </g>
      <g id="Group_19062" data-name="Group 19062" transform="translate(2.154 4.354)">
        <g id="Group_19061" data-name="Group 19061">
          <path id="Path_8226" data-name="Path 8226" d="M22.511,7.1l-5.532,7.05,2.216,2.216c1.6-2.03,7.716-9.87,7.716-9.87A1.546,1.546,0,0,0,25.687,4H6.83l3.1,3.1Z" transform="translate(7.797 -2.156)" fill={fill} />
          <path id="Path_8227" data-name="Path 8227" d="M4.035,2.81,1.39,5.436,17.426,21.789V32.964a1.868,1.868,0,0,0,1.863,1.863h3.725a1.868,1.868,0,0,0,1.863-1.863V28.923L35.642,39.688l2.626-2.626Z" transform="translate(-1.39 -2.81)" fill={fill} />
        </g>
      </g>
    </g>
  </svg>
);

NoFiltersIcon.defaultProps = {
  fill: '#919eab',
};

export default NoFiltersIcon;
