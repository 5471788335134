import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  AdditionalFieldTitle,
  DimensionMode,
  DimensionStatus, ExpandListButtonContext, FieldEntitiesType, MaxColumnGrouping, MaxRowGrouping,
} from 'core/constants/report';
import { MaxLimitDesc } from 'core/constants/filter';
import {
  AdditionalDimensionEmptyState, SearchBarPlaceHolder, AdditionalDimensionNoResultState,
  NoDimensionDataMessages,
} from 'core/constants/visualizations';
import { IDimension } from 'core/models/report-redux';
import { ReduxModel } from 'core/models';
import {
  genericSearch, getFieldIdentifier, getTextTooltipContentForDimension, sortAdditionalFields,
} from 'core/utils/report.util';
import {
  IAvailableFieldsProps,
} from 'components/feature/Report/ReportSidebar/Settings/Dimensions/AvailableFields/available-fields.d';
import { displayName } from 'components/feature/Report/ReportSidebar/common/helpers';
import AvailableDimensions from 'components/feature/Report/ReportSidebar/common/AvailableDimensions/available-dimensions.component';
import SearchBar from 'components/feature/Report/ReportSidebar/common/SearchBar/search-bar-component';
import DimensionItem from 'components/feature/Report/ReportSidebar/common/AvailableDimensions/DimensionItem/dimension-item.component';
import EmptyStateScreen from 'components/feature/Report/ReportSidebar/common/EmptyState/empty-state.component';
import NoResultStateScreen from 'components/feature/Report/ReportSidebar/common/NoResultState/no-result-state-component';
import NoFieldsIcon from 'components/common/svg/NoFields/noFields.svg';
import FieldEntity from 'components/feature/Report/ReportSidebar/common/FieldEntity/field-entity.component';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';

const AvailableFields = (props: IAvailableFieldsProps) => {
  const {
    reportBuilderPortal, expanded, collapse, addDimensionToGrouping, context,
  } = props;
  const [report] = useSelector((state: ReduxModel.IGlobalState) => [state.report] as const);

  const { appliedDimensions, reportConfig } = report;

  const [query, setQuery] = useState<string>('');
  const [expandedEntity, setExpandedEntity] = useState<string[]>([]);

  useEffect(() => {
    if (!expanded) {
      setQuery('');
      setExpandedEntity([]);
    }
  }, [expanded]);

  const entityList = [] as string[];
  Object.keys(FieldEntitiesType).forEach((e: string) => {
    if (appliedDimensions.find((d) => d.BuilderConfig && (d.BuilderConfig.Entity === e))) {
      entityList.push(e);
    }
  });

  // TODO: Add a function call to get dynamic dimensions
  // API call using UseEffect
  // After this, update saved state of reportConfig
  // after this, scrollbar, cache
  // Available Fields only shows un-grouped dimensions. Filter them out but store their original indexes.
  // Original indexes are needed for setRowGrouping and reorderGrouping.
  const unGroupedDimensions = appliedDimensions
    .map((dim, index) => ({ ...dim, originalIndex: index } as iDimensionWithIndex))
    .filter((dim) => dim.Applied === DimensionStatus.NotApplied);

  // const maxGroupingLimit = MaxRowGrouping[reportConfig.Visualization.Type as keyof typeof MaxRowGrouping]
  // + MaxColumnGrouping[reportConfig.Visualization.Type as keyof typeof MaxColumnGrouping];
  const maxGroupingLimit = context === ExpandListButtonContext.RowGroup ? MaxRowGrouping[reportConfig.Visualization.Type as keyof typeof MaxRowGrouping] : MaxColumnGrouping[reportConfig.Visualization.Type as keyof typeof MaxColumnGrouping];
  const rowGroupedDims = appliedDimensions.filter((dim) => dim.Applied !== DimensionStatus.NotApplied && dim.DimensionMode === DimensionMode.RowGroup);
  const colGroupedDims = appliedDimensions.filter((dim) => dim.Applied !== DimensionStatus.NotApplied && dim.DimensionMode === DimensionMode.ColumnGroup);

  const isMaxGroupingLimit = context === ExpandListButtonContext.RowGroup ? rowGroupedDims.length === maxGroupingLimit : colGroupedDims.length === maxGroupingLimit;

  const hasFields = unGroupedDimensions && !isEmpty(unGroupedDimensions);

  // filtered ungrouped dimensions based on search query.
  const filteredUnGroupedDim = unGroupedDimensions.filter((dim) => genericSearch(query, displayName(dim)));

  const hasFilteredGroupedDim = !!filteredUnGroupedDim.length;

  // list of list which has two element first is entity and second element is list of matching dimensions.
  const entityDimList = [] as any;
  entityList.forEach((entity) => {
    const dimList = filteredUnGroupedDim.filter((dim) => dim.BuilderConfig && (dim.BuilderConfig.Entity === entity));
    entityDimList.push([entity, sortAdditionalFields(dimList)]);
  });

  const handleExpandedEntity = (entity: string) => {
    if (expandedEntity.includes(entity)) {
      setExpandedEntity(
        query ? expandedEntity.filter((e) => e !== entity) : [],
      );
      return;
    }
    setExpandedEntity(
      query ? [...expandedEntity, entity] : [entity],
    );
  };

  return (
    <AvailableDimensions
      reportBuilderPortal={reportBuilderPortal}
      expanded={expanded}
      title={context === ExpandListButtonContext.RowGroup ? AdditionalFieldTitle.RowGroup : AdditionalFieldTitle.ColGroup}
      collapse={collapse}
      context={context}
      search={hasFields
        && (
        <SearchBar
          onChange={(q) => setQuery(q)}
          query={query}
          searchPlaceholder={SearchBarPlaceHolder.Fields}
          setFocus={() => setExpandedEntity(entityList)}
        />
        )}
      showcase={(
          hasFields
            ? (
              <>
                {
                  hasFilteredGroupedDim
                    ? entityDimList.map((entity: any) => {
                      const [entityKey, dimensions] = entity;
                      const unGrpDimsWithinEntity = !!(appliedDimensions.find((dim) => (dim.BuilderConfig
                        && (dim.BuilderConfig.Entity === entityKey) && dim.Applied === DimensionStatus.NotApplied)));
                      return (
                        <FieldEntity
                          key={entityKey}
                          entity={entityKey}
                          expand={expandedEntity.includes(entityKey)}
                          handleExpandedEntity={() => handleExpandedEntity(entityKey)}
                          showcase={(
                            <>
                              {
                                unGrpDimsWithinEntity
                                  ? (
                                    <>
                                      {
                                        query && !dimensions.length
                                          ? (
                                            <div
                                              className="se-no-dimension-data"
                                            >
                                              {NoDimensionDataMessages.NoSearches}
                                            </div>
                                          )
                                          : dimensions.map((dim: iDimensionWithIndex) => (
                                            <DimensionItem
                                              key={getFieldIdentifier(dim)}
                                              name={displayName(dim)}
                                              nameTooltipContent={getTextTooltipContentForDimension(dim)}
                                              isMaxLimit={isMaxGroupingLimit}
                                              maxLimitDesc={MaxLimitDesc.AppliedDimension
                                                .replace('{{limit}}', maxGroupingLimit.toString())}
                                              add={() => addDimensionToGrouping(dim.originalIndex, context)}
                                              className={PendoClassNames.AddField}
                                            />
                                          ))
                                      }
                                    </>
                                  )
                                  : (
                                    <div
                                      className="se-no-dimension-data"
                                    >
                                      {NoDimensionDataMessages.AllColumnAdded}
                                    </div>
                                  )
                              }
                            </>
                        )}
                        />
                      );
                    })
                    : (
                      <NoResultStateScreen
                        message={AdditionalDimensionNoResultState.Fields}
                        Icon={(
                          <NoFieldsIcon
                            width={36}
                            height={33}
                          />
                        )}
                      />
                    )
              }
              </>
            )
            : (
              <EmptyStateScreen
                message={AdditionalDimensionEmptyState.Fields}
              />
            )
          )}
    />
  );
};

// A temporary interface to store original indexes of dimensions
interface iDimensionWithIndex extends IDimension {
  originalIndex: number;
}

export default AvailableFields;
