import React from 'react';

type Props = {
    width: number,
    height: number,
    fill?: string,
}

const SimpleUpArrowIcon = ({ width, height, fill }: Props) => (
  <svg width={width} height={height} viewBox="0 0 8 5" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M1.19807 4.56798L4.02607 1.74498L6.85707 4.56798L7.72607 3.70098L4.02607 0.000976562L0.326074 3.70098L1.19807 4.56798Z" fill="#454F5B" />
  </svg>
);

SimpleUpArrowIcon.defaultProps = {
  fill: '#000000',
};

export default SimpleUpArrowIcon;
