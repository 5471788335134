import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const AreaChartIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="area_chart_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 24 24"
  >
    <rect
      id="Rectangle_6363"
      data-name="Rectangle 6363"
      width="24"
      height="24"
      fill="none"
    />
    <path
      id="Path_7595"
      data-name="Path 7595"
      d="M17,7,12,3,7,10,3,7V20H21V7Zm2,9.95L12,11.5,8,17,5,14.6V11l2.44,1.83L12.4,5.88,16.3,9H19Z"
      fill={fill}
    />
  </svg>

);

AreaChartIcon.defaultProps = {
  fill: '#46bfc9',
};

export default AreaChartIcon;
