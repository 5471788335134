import styled from 'styled-components';

const StyledUnauthorizedContainer = styled.div`

.unauth-section{
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  color: var(--noDataColor);

  >.se-info-data-header{
  text-align: center;
  font-family:SemiBold;
  letter-spacing: 0px;
  color: #454F5B;
  opacity: 1;
  font-size:30px;
  margin-top: 5%;
  }
  > .se-contact-data-text{
      text-align: center;
      font-family:Regular
      letter-spacing: 0px;
      color: #637381;
      opacity: 1;
      font-size:13px;
      font-size: 20px;
      margin-top: 2%;
  }

  > .se-go-to-dashboard{
    border: 1px solid #006FD6;
    color: #006FD6;
    text-decoration: none;
    font-family: SemiBold;
    padding:7px 20px;
    border-radius:2px;
    font-size: 13px;
    margin-top: 4%;
    transition: 300ms ease-out;
    cursor: pointer;
    &:hover{
      background-color: #006FD6;
      color: #FFFFFF;
    }
  }
     
  }
`;
export default StyledUnauthorizedContainer;
