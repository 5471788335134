import styled from 'styled-components';

export default styled.div`
  min-height: 32px; // 16px for DragDropIcon + 8+8px padding

  .se-info-icon {
    .icon-stroke {
      fill: #F38037;
    }
  }

`;
