import styled from 'styled-components';

const StyledBarLoader = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: var(--base-white);
  top: 0;
  left:0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: SemiBold;

.relative {
  position: absolute;
}
> .se-loader,
.se-loader:before,
.se-loader:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
> .se-loader {
  color: var(--loaderColor);
  font-size: 10px;
  margin: 40px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
> .se-loader:before,
.se-loader:after {
  content: '';
  position: absolute;
  top: 0;
}
> .se-loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
> .se-loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@media (min-width: 768px) {
  
}

@media (min-width: 1024px) {
  
}
`;
export default StyledBarLoader;
