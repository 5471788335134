import React, {
  Dispatch, ReducerAction, useEffect, useReducer, useState, MouseEvent,
} from 'react';
import {
  DimensionItemWarning,
  DimensionMode,
  DimensionStatus,
  expandFieldsButtonLabel,
  ExpandListButtonContext,
  MaxColumnGrouping,
  MaxRowGrouping,
  ReportDimensionsSettingsDescs,
  ReportDimensionsSettingsTitles,
} from 'core/constants/report';
import { IDimensionsProps } from 'components/feature/Report/ReportSidebar/Settings/Dimensions/dimensions.d';
import StoreConnector from 'components/feature/Report/ReportSidebar/Settings/Dimensions/dimensions.redux';
import StyledContainer from 'components/feature/Report/ReportSidebar/Settings/Dimensions/dimensions.style';
import {
  BeforeCapture,
  Combine, DragDropContext, DraggableId, DraggableLocation, DragStart, DragUpdate, DropResult,
} from 'react-beautiful-dnd';
import AvailableFields
  from 'components/feature/Report/ReportSidebar/Settings/Dimensions/AvailableFields/available-fields.component';
import RowGrouping from 'components/feature/Report/ReportSidebar/Settings/Dimensions/RowGrouping/row-grouping.component';
import { DroppableIds } from 'components/feature/Report/ReportSidebar/common/constants';
import { checkAndGetGroupingLimits } from 'components/feature/Report/ReportSidebar/common/helpers';
import { DraggableItemState } from 'components/feature/Report/ReportSidebar/common/DraggableItem/draggable-item.d';
import { IDimension } from 'core/models/report-redux';
import SidebarHeader from 'components/feature/Report/ReportSidebar/common/SidebarHeader/sidebar-header.component';
import ColumnGrouping from 'components/feature/Report/ReportSidebar/Settings/Dimensions/ColumnGrouping/column-grouping.component';
import { VisualizationTypes } from 'core/constants/visualizations';
import {
  placeholderPropsReducer,
} from 'components/feature/Report/ReportSidebar/Settings/Dimensions/dimensions.reducers';
import { getFieldIdentifier } from 'core/utils/report.util';
import ExpandListButton from 'components/common/ExpandListButton/expand-list-button.component';
import { getDimensionSelectedCount, getInfoIconWithTooltip } from '../settings.utils';

const Dimensions = (props: IDimensionsProps) => {
  const {
    reduxState, reduxAction, reportBuilderPortal, toolbarExpanded, applyClickCount,
  } = props;
  const { report } = reduxState;
  const { appliedDimensions, reportConfig } = report;
  const { setGrouping } = reduxAction;

  // states
  const [rowGroupAvailableFieldsExpanded, setRowGroupAvailableFieldsExpanded] = useState(false);
  const [colGroupAvailableFieldsExpanded, setColGroupAvailableFieldsExpanded] = useState(false);

  const [showMaxRowGroupingWarning, setShowMaxRowGroupingWarning] = useState(false);
  const [showMinRowGroupingWarning, setShowMinRowGroupingWarning] = useState(false);
  const [showMaxColGroupingWarning, setShowMaxColGroupingWarning] = useState(false);
  const [selectedDimension, setSelectedDimension] = useState(''); // tells child components which dimension is being dragged, if any
  const [dimensionState, setDimensionState] = useState(DraggableItemState.Default); // tells child components the state of the dragged dimension
  const [rowGroupingPlaceholderProps, setRowGroupingPlaceholderProps] = useReducer(placeholderPropsReducer, {});
  const [colGroupingPlaceholderProps, setColGroupingPlaceholderProps] = useReducer(placeholderPropsReducer, {});
  const [firstRowDragUpdate, setFirstRowDragUpdate] = useState(true); // next DragUpdate for the droppable will be the first
  const [firstColDragUpdate, setFirstColDragUpdate] = useState(true); // used for mimicking dnd's placeholder logic
  // map of droppables to their placeholder props dispatchers
  const [replaceIndex, setReplaceIndex] = useState(-1);

  const droppablePlaceholderDispatch: Record<DroppableIds, Dispatch<ReducerAction<typeof placeholderPropsReducer>>> = {
    [DroppableIds.AvailableFields]: (() => {}),
    [DroppableIds.RowGrouping]: setRowGroupingPlaceholderProps,
    [DroppableIds.ColumnGrouping]: setColGroupingPlaceholderProps,
    [DroppableIds.DrilldownGrouping]: (() => {}),
    [DroppableIds.Measures]: (() => {}),
  };

  const resetState = () => {
    setShowMaxRowGroupingWarning(false);
    setShowMinRowGroupingWarning(false);
    setShowMaxColGroupingWarning(false);
    resetPlaceholderProps();
    setSelectedDimension('');
    setDimensionState(DraggableItemState.Default);
    setReplaceIndex(-1);
  };
  const resetPlaceholderProps = () => {
    setRowGroupingPlaceholderProps({ type: 'reset' });
    setColGroupingPlaceholderProps({ type: 'reset' });
    setFirstRowDragUpdate(true);
    setFirstColDragUpdate(true);
  };
  useEffect(() => {
    if (!toolbarExpanded) {
      setRowGroupAvailableFieldsExpanded(false);
      setColGroupAvailableFieldsExpanded(false);
    }
  }, [toolbarExpanded]);
  useEffect(() => {
    setRowGroupAvailableFieldsExpanded(false);
    setColGroupAvailableFieldsExpanded(false);
  }, [applyClickCount]);

  // get grouping limits
  const rowGroupedDimensions = appliedDimensions.filter((dim) => (
    dim.DimensionMode !== DimensionMode.ColumnGroup && dim.Applied !== DimensionStatus.NotApplied
  ));
  const colGroupedDimensions = appliedDimensions.filter((dim) => (
    dim.DimensionMode !== DimensionMode.RowGroup && dim.Applied !== DimensionStatus.NotApplied
  ));
  const {
    isMaxRowGroupingReached,
    isMinRowGroupingReached,
    isMaxColGroupingReached,
    maxRowGroupingLimit,
    minRowGroupingLimit,
    maxColGroupingLimit,
  } = checkAndGetGroupingLimits(rowGroupedDimensions, colGroupedDimensions, reportConfig.Visualization.Type);

  // indexes from drag-drop are not original, recover original indexes for calling redux actions.
  // all grouped dimensions are guaranteed to be at the beginning of appliedDimensions.
  // we can use that knowledge to recover original indexes according to appliedDimensions
  const getOriginalIndex = (location: DraggableLocation): number => {
    if (location.droppableId === DroppableIds.RowGrouping) return location.index; // Row Grouping dimensions are always at the top of appliedDimensions
    // Row Grouping dimensions are always at the top of appliedDimensions
    if (location.droppableId === DroppableIds.ColumnGrouping) return rowGroupedDimensions.length + location.index;
    return rowGroupedDimensions.length + colGroupedDimensions.length + location.index; // un-grouped dimensions always come after grouped ones
  };
  const getDimensionByLocation = (location: DraggableLocation): IDimension => appliedDimensions[getOriginalIndex(location)];
  const getDimensionByName = (name: DraggableId): [IDimension, number] => {
    const index = appliedDimensions.findIndex((dim) => {
      if (dim.BuilderConfig && dim.BuilderConfig.IsDynamicField) {
        return dim.BuilderConfig.Alias === name;
      }
      return dim.Name === name;
    });
    return [appliedDimensions[index], index];
  };

  // #region Event Handlers

  const onBeforeDragCapture = (event: BeforeCapture) => {
    const [dim] = getDimensionByName(event.draggableId);
    setSelectedDimension(getFieldIdentifier(dim));
    setDimensionState(DraggableItemState.Dragging);
  };

  const onDragStart = (event: DragStart) => {
    updatePlaceholders(event.source, event.source);
  };

  // set the selected dimension and its state
  const onDragUpdate = ({ source, destination, combine }: DragUpdate) => {
    const dimension = getDimensionByLocation(source);
    setSelectedDimension(getFieldIdentifier(dimension));
    if (combine) {
      const [, dropIndex] = getDimensionByName(combine.draggableId);
      setReplaceIndex(dropIndex);
      setDimensionState(DraggableItemState.AboutToBeCombined);
    } else if (
      !!destination
      && source.droppableId !== destination.droppableId
      && destination.droppableId !== DroppableIds.AvailableFields
    ) {
      // user is trying to add or change a grouping
      if (
        (isMaxRowGroupingReached && destination.droppableId === DroppableIds.RowGrouping)
        || (isMinRowGroupingReached && source.droppableId === DroppableIds.RowGrouping)
        || (isMaxColGroupingReached && destination.droppableId === DroppableIds.ColumnGrouping)
      ) {
        setReplaceIndex(-1);
        setDimensionState(DraggableItemState.DropDisallowed);
      } else {
        setReplaceIndex(-1);
        setDimensionState(DraggableItemState.AboutToBeAdded);
      }
    } else {
      setReplaceIndex(-1);
      setDimensionState(DraggableItemState.Dragging);
    }

    updateMaxGroupingWarning(source, destination);
    updatePlaceholders(source, destination, combine);
  };

  const updatePlaceholders = (source: DraggableLocation, dest?: DraggableLocation, combine?: Combine) => {
    if (combine) {
      // when combining, always make dest placeholder movable to avoid sending it to the bottom.
      droppablePlaceholderDispatch[combine.droppableId as DroppableIds]({ type: 'setMovable' });

      // when combining within droppables, keep the original position of dest placeholder
      if (combine.droppableId === source.droppableId) return;

      // when combining across droppables, send source placeholder to the bottom.
      // if the first drag action is combine, mimic dnd by sending dest placeholder to the bottom.
      updatePlaceholderOffset(source.droppableId as DroppableIds);
      if (combine.droppableId === DroppableIds.RowGrouping && firstRowDragUpdate) updateRowPlaceholderOffset();
      else if (combine.droppableId === DroppableIds.ColumnGrouping && firstColDragUpdate) updateColPlaceholderOffset();
      return;
    }

    // reset all placeholders to keep our sanity
    // highlight the placeholder as soon as item is dragged
    setRowGroupingPlaceholderProps({ type: 'reset' });
    setColGroupingPlaceholderProps({ type: 'reset' });
    setRowGroupingPlaceholderProps({ type: 'setActive' });
    setColGroupingPlaceholderProps({ type: 'setActive' });

    if (!dest) { // if dragging outside any droppable...
      if (source.droppableId !== DroppableIds.AvailableFields) {
        // keep source placeholder movable to account for the missing (dragged) item and send it to the bottom
        droppablePlaceholderDispatch[source.droppableId as DroppableIds]({ type: 'setMovable' });
        updatePlaceholderOffset(source.droppableId as DroppableIds);
      }
      // next update in row/column groupings will be the first
      setFirstRowDragUpdate(true);
      setFirstColDragUpdate(true);
      return;
    }
    // it is confirmed that we are dragging from droppable to droppable

    // keep source placeholder movable to account for the missing (dragged) item and send it to the bottom
    droppablePlaceholderDispatch[source.droppableId as DroppableIds]({ type: 'setMovable' });
    updatePlaceholderOffset(source.droppableId as DroppableIds);
    droppablePlaceholderDispatch[dest.droppableId as DroppableIds]({ type: 'setMovable' });

    // subsequent updates will not be the first
    if (dest.droppableId === DroppableIds.RowGrouping) setFirstRowDragUpdate(false);
    else if (dest.droppableId === DroppableIds.ColumnGrouping) setFirstColDragUpdate(false);

    // move the placeholder to under the dragged item.
    // if dragging within a droppable, account for the missing (dragged) item by adding the next element's height
    let position = dest.index;
    if (source.droppableId === dest.droppableId) {
      position = source.index < dest.index ? dest.index + 1 : dest.index;
    }
    updatePlaceholderOffset(dest.droppableId as DroppableIds, position);
  };

  // #region placeholder helper functions
  const updatePlaceholderOffset = (droppableId: DroppableIds, position?: number) => {
    if (droppableId === DroppableIds.AvailableFields) return;

    // dnd doesn't allow custom placeholders. this implementation is copied from
    // https://github.com/atlassian/react-beautiful-dnd/issues/518#issuecomment-614771485.
    const droppableDom = document.querySelector(`[data-rbd-droppable-id=${droppableId}]`);
    const length = position === undefined ? droppableDom.children.length : position; // send it to the bottom
    let yPosition = 0;
    for (let i = 0; i < length; i += 1) {
      const child = droppableDom.children[i];
      if (
        child
        && !child.hasAttribute('data-rbd-placeholder-context-id') // ignore dnd's placeholder
        && !child.classList.contains('se-grouping-drag-drop-placeholder-container') // ignore our own placeholder
        && !child.classList.contains('se-dim-dragging') // ignore the dragging item
      ) {
        const style = window.getComputedStyle(droppableDom.children[i]);
        yPosition = yPosition + droppableDom.children[i].clientHeight + parseFloat(style.marginBottom);
      }
    }
    droppablePlaceholderDispatch[droppableId]({ type: 'setTopOffset', topOffset: yPosition });
  };
  const updateRowPlaceholderOffset = (position?: number) => updatePlaceholderOffset(DroppableIds.RowGrouping, position);
  const updateColPlaceholderOffset = (position?: number) => updatePlaceholderOffset(DroppableIds.ColumnGrouping, position);
  // #endregion

  const updateMaxGroupingWarning = (source: DraggableLocation, destination: DraggableLocation) => {
    if (
      !!destination
      && source.droppableId !== destination.droppableId
      && destination.droppableId !== DroppableIds.AvailableFields
    ) {
      // user was adding a new grouping, show if grouping limit is reached
      if (source.droppableId === DroppableIds.RowGrouping) {
        setShowMinRowGroupingWarning(true);
        setShowMaxRowGroupingWarning(false);
      }
      if (destination.droppableId === DroppableIds.RowGrouping) {
        setShowMaxRowGroupingWarning(true);
        setShowMaxColGroupingWarning(false);
      } else if (destination.droppableId === DroppableIds.ColumnGrouping) {
        setShowMaxColGroupingWarning(true);
        setShowMaxRowGroupingWarning(false);
      }
      return;
    }
    setShowMaxRowGroupingWarning(false);
    setShowMinRowGroupingWarning(false);
    setShowMaxColGroupingWarning(false);
  };

  // Sets new groupings and reorders the dimensions if needed.
  const onDragEnd = ({ source, destination, combine }: DropResult) => {
    resetPlaceholderProps();

    if (combine) {
      const [destinationDim, destinationIndex] = getDimensionByName(combine.draggableId);
      if (
        combine.droppableId === DroppableIds.AvailableFields
        || (
          !!source && source.droppableId === DroppableIds.AvailableFields
          && !!destinationDim && destinationDim.Applied === DimensionStatus.Mandatory
        )
      ) {
        resetState();
        return;
      }
      // set the new status and re-order the dimension, if is necessary.
      // indexes from DropResult are not original, recover original indexes for calling redux actions
      let newStatus = DimensionStatus.Applied;
      if (getDimensionByLocation(source).Applied === DimensionStatus.Mandatory) {
        newStatus = DimensionStatus.Mandatory;
      }
      if (combine.droppableId === DroppableIds.RowGrouping) {
        setGrouping(getOriginalIndex(source), newStatus, DimensionMode.RowGroup, destinationIndex, true);
      } else if (combine.droppableId === DroppableIds.ColumnGrouping) {
        setGrouping(getOriginalIndex(source), newStatus, DimensionMode.ColumnGroup, destinationIndex, true);
      }
      resetState();
      return;
    }
    if (
      !destination
      || destination.droppableId === DroppableIds.AvailableFields // dropping on Available Fields does nothing
      || ( // user is trying to set row grouping after limit has been reached, which does nothing
        isMaxRowGroupingReached
        && source.droppableId !== DroppableIds.RowGrouping && destination.droppableId === DroppableIds.RowGrouping
      )
      || ( // user is trying to unset row grouping after limit has been reached, which does nothing
        isMinRowGroupingReached
        && source.droppableId === DroppableIds.RowGrouping && destination.droppableId !== DroppableIds.RowGrouping
      )
      || ( // user is trying to set column grouping after limit has been reached, which does nothing
        isMaxColGroupingReached
        && source.droppableId !== DroppableIds.ColumnGrouping && destination.droppableId === DroppableIds.ColumnGrouping
      )
      // item was dragged and dropped in the same place, no need to re-render
      || (source.droppableId === destination.droppableId && source.index === destination.index)
    ) {
      resetState();
      return;
    }

    // set the new status and re-order the dimension, if is necessary.
    // indexes from DropResult are not original, recover original indexes for calling redux actions
    let newStatus = DimensionStatus.Applied;
    if (getDimensionByLocation(source).Applied === DimensionStatus.Mandatory) {
      newStatus = DimensionStatus.Mandatory;
    }
    if (destination.droppableId === DroppableIds.RowGrouping) {
      setGrouping(getOriginalIndex(source), newStatus, DimensionMode.RowGroup, getOriginalIndex(destination));
    } else if (destination.droppableId === DroppableIds.ColumnGrouping) {
      setGrouping(getOriginalIndex(source), newStatus, DimensionMode.ColumnGroup, getOriginalIndex(destination));
    }

    resetState();
  };

  const [newAdditionIndex, setNewAdditionIndex] = useState(-1);

  const addDimensionToGrouping = (index: number, context: ExpandListButtonContext) => {
    setNewAdditionIndex(rowGroupedDimensions.length);
    setTimeout(() => {
      setNewAdditionIndex(-1);
    }, 600);
    if (context === ExpandListButtonContext.RowGroup) {
      setGrouping(index, DimensionStatus.Applied, DimensionMode.RowGroup, rowGroupedDimensions.length);
      return;
    }
    if (context === ExpandListButtonContext.ColGroup) {
      setGrouping(index, DimensionStatus.Applied, DimensionMode.ColumnGroup, rowGroupedDimensions.length
        + colGroupedDimensions.length);
    }
  };

  // #endregion

  const onMouseMove = (event:MouseEvent) => {
    const target = document.getElementById('groupingLimitInfoTooltip');
    if (dimensionState === DraggableItemState.DropDisallowed) {
      if (showMaxRowGroupingWarning && isMaxRowGroupingReached) {
        target.textContent = DimensionItemWarning.MaxGrouping.replace('{{count}}', maxRowGroupingLimit.toString());
      } else if (showMaxColGroupingWarning && isMaxColGroupingReached) {
        target.textContent = (showMinRowGroupingWarning && isMinRowGroupingReached)
          ? DimensionItemWarning.MinGrouping.replace('{{count}}', minRowGroupingLimit.toString()) : DimensionItemWarning.MaxGrouping.replace('{{count}}', maxColGroupingLimit.toString());
      } else if (showMinRowGroupingWarning && isMinRowGroupingReached) {
        target.textContent = DimensionItemWarning.MinGrouping.replace('{{count}}', minRowGroupingLimit.toString());
      }
      target.style.display = 'block';
      target.style.top = `${event.clientY}px`;
      target.style.left = (event.clientX + target.clientWidth > window.innerWidth ? `${event.clientX - target.clientWidth}px` : `${event.clientX}px`);
    } else {
      target.style.display = 'none';
    }
  };

  return (
    <StyledContainer onMouseMove={onMouseMove}>
      <div className="se-grouping-limit-info-tooltip" id="groupingLimitInfoTooltip">ToolTip</div>
      <DragDropContext
        onBeforeCapture={onBeforeDragCapture}
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
      >
        <AvailableFields
          expanded={rowGroupAvailableFieldsExpanded}
          selectedDimension={selectedDimension}
          dimensionState={dimensionState}
          reportBuilderPortal={reportBuilderPortal}
          collapse={() => setRowGroupAvailableFieldsExpanded(false)}
          addDimensionToGrouping={addDimensionToGrouping}
          context={ExpandListButtonContext.RowGroup}
        />
        <div className="se-dimensions-settings-item">
          <div className="se-grouping-header-cn">
            <SidebarHeader
              title={ReportDimensionsSettingsTitles.RowGrouping}
              counter={
                getDimensionSelectedCount(isMaxRowGroupingReached, rowGroupedDimensions.length,
                  MaxRowGrouping[reportConfig.Visualization.Type as keyof typeof MaxRowGrouping])
                }
              infoIcon={getInfoIconWithTooltip(ReportDimensionsSettingsDescs.RowGrouping)}
              source={ExpandListButtonContext.RowGroup}
            />
            <ExpandListButton
              label={expandFieldsButtonLabel}
              isExpanded={rowGroupAvailableFieldsExpanded}
              onClickToggler={() => {
                setRowGroupAvailableFieldsExpanded(!rowGroupAvailableFieldsExpanded);
                setColGroupAvailableFieldsExpanded(false);
              }}
              clickSource={ExpandListButtonContext.RowGroup}
            />
          </div>

          <RowGrouping
            selectedDimension={selectedDimension}
            dimensionState={dimensionState}
            placeholderProps={rowGroupingPlaceholderProps}
            isMaxRowGroupingReached={isMaxRowGroupingReached}
            replaceIndex={replaceIndex}
            newAdditionIndex={newAdditionIndex}
          />
        </div>
        <AvailableFields
          expanded={colGroupAvailableFieldsExpanded}
          selectedDimension={selectedDimension}
          dimensionState={dimensionState}
          reportBuilderPortal={reportBuilderPortal}
          collapse={() => setColGroupAvailableFieldsExpanded(false)}
          addDimensionToGrouping={addDimensionToGrouping}
          context={ExpandListButtonContext.ColGroup}
        />
        {
            reportConfig.Visualization.Type === VisualizationTypes.Table && (
              <div className="se-dimensions-settings-item">
                <div className="se-grouping-header-cn">
                  <SidebarHeader
                    title={ReportDimensionsSettingsTitles.ColumnGrouping}
                    counter={
                      getDimensionSelectedCount(isMaxColGroupingReached, colGroupedDimensions.length,
                        MaxColumnGrouping[reportConfig.Visualization.Type as keyof typeof MaxColumnGrouping])
                        }
                    infoIcon={getInfoIconWithTooltip(ReportDimensionsSettingsDescs.ColumnGrouping)}
                    source={ExpandListButtonContext.ColGroup}
                  />
                  <ExpandListButton
                    label={expandFieldsButtonLabel}
                    isExpanded={colGroupAvailableFieldsExpanded}
                    onClickToggler={() => {
                      setColGroupAvailableFieldsExpanded(!colGroupAvailableFieldsExpanded);
                      setRowGroupAvailableFieldsExpanded(false);
                    }}
                    clickSource={ExpandListButtonContext.ColGroup}
                  />
                </div>

                <ColumnGrouping
                  selectedDimension={selectedDimension}
                  dimensionState={dimensionState}
                  placeholderProps={colGroupingPlaceholderProps}
                  isMaxColGroupingReached={isMaxColGroupingReached}
                  replaceIndex={replaceIndex}
                  newAdditionIndex={newAdditionIndex}
                />
              </div>
            )
          }
      </DragDropContext>
    </StyledContainer>
  );
};

export default StoreConnector()(Dimensions);
