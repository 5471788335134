import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const TabularIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="more_horiz_black_24dp_2_"
    data-name="more_horiz_black_24dp (2)"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 12.082 12.082"
  >
    <g
      id="more_horiz_black_24dp_2_2"
      data-name="more_horiz_black_24dp (2)"
      transform="translate(12.082) rotate(90)"
    >
      <path
        id="Path_7408"
        data-name="Path 7408"
        d="M0,0H12.082V12.082H0Z"
        fill="none"
      />
      <path
        id="Path_7409"
        data-name="Path 7409"
        d="M5.208,10a1.208,1.208,0,1,0,1.208,1.208A1.212,1.212,0,0,0,5.208,10Zm7.249,0a1.208,1.208,0,1,0,1.208,1.208A1.212,1.212,0,0,0,12.457,10ZM8.833,10a1.208,1.208,0,1,0,1.208,1.208A1.212,1.212,0,0,0,8.833,10Z"
        transform="translate(-2.792 -5.167)"
        fill={fill}
      />
    </g>
  </svg>
);

TabularIcon.defaultProps = {
  fill: '#919eab',
};

export default TabularIcon;
