import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const KebabIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 4 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.55029 2.18309C3.55029 1.86831 3.45695 1.56061 3.28207 1.29888C3.10719 1.03715 2.85863 0.833162 2.56781 0.712702C2.277 0.592242 1.95699 0.560725 1.64826 0.622134C1.33953 0.683544 1.05595 0.835123 0.83337 1.0577C0.610789 1.28028 0.45921 1.56387 0.3978 1.8726C0.336391 2.18132 0.367909 2.50133 0.488369 2.79214C0.608829 3.08296 0.81282 3.33152 1.07455 3.5064C1.33627 3.68128 1.64398 3.77463 1.95876 3.77463C2.38043 3.77324 2.78444 3.60511 3.08261 3.30694C3.38078 3.00877 3.5489 2.60476 3.55029 2.18309ZM3.55029 11.7336C3.55029 11.4188 3.45695 11.1111 3.28207 10.8494C3.10719 10.5877 2.85863 10.3837 2.56781 10.2632C2.277 10.1428 1.95699 10.1113 1.64826 10.1727C1.33953 10.2341 1.05595 10.3857 0.83337 10.6082C0.610789 10.8308 0.45921 11.1144 0.3978 11.4231C0.336391 11.7319 0.367909 12.0519 0.488369 12.3427C0.608829 12.6335 0.81282 12.8821 1.07455 13.0569C1.33627 13.2318 1.64398 13.3252 1.95876 13.3252C2.38043 13.3238 2.78444 13.1556 3.08261 12.8575C3.38078 12.5593 3.5489 12.1553 3.55029 11.7336ZM3.55029 6.95902C3.55029 6.64424 3.45695 6.33653 3.28207 6.07481C3.10719 5.81308 2.85863 5.60909 2.56781 5.48863C2.277 5.36817 1.95699 5.33665 1.64826 5.39806C1.33953 5.45947 1.05595 5.61105 0.83337 5.83363C0.610789 6.05621 0.45921 6.3398 0.3978 6.64852C0.336391 6.95725 0.367909 7.27726 0.488369 7.56807C0.608829 7.85889 0.81282 8.10745 1.07455 8.28233C1.33627 8.45721 1.64398 8.55055 1.95876 8.55055C2.38043 8.54916 2.78444 8.38104 3.08261 8.08287C3.38078 7.7847 3.5489 7.38069 3.55029 6.95902Z"
      className="icon-stroke"
      fill={fill}
    />
  </svg>

);

KebabIcon.defaultProps = {
  fill: '#ADBAC7',
};

export default KebabIcon;
