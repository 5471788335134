import React, { useEffect, useState } from 'react';
import NoFieldsIcon from 'components/common/svg/NoFields/noFields.svg';
import {
  ColumnModes,
  ExpandListButtonContext,
  FieldEntitiesType,
  PreDefinedFieldEntityType,
  ReportDimensionsSettingsDescs,
  ReportDimensionsSettingsTitles,
} from 'core/constants/report';
import {
  AdditionalDimensionEmptyState,
  AdditionalDimensionNoResultState,
  NoDimensionDataMessages,
  SearchBarPlaceHolder,
} from 'core/constants/visualizations';
import {
  genericSearch,
  getFieldIdentifier,
  getTextTooltipContentForDimension,
  isMeasure,
  isMeasureAndApplied,
  isMeasureDynamic,
  sortAdditionalFields,
} from 'core/utils/report.util';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxModel } from 'core/models';
import AvailableDimensions from '../../../common/AvailableDimensions/available-dimensions.component';
import DimensionItem from '../../../common/AvailableDimensions/DimensionItem/dimension-item.component';
import EmptyStateScreen from '../../../common/EmptyState/empty-state.component';
import FieldEntity from '../../../common/FieldEntity/field-entity.component';
import { displayName } from '../../../common/helpers';
import NoResultStateScreen from '../../../common/NoResultState/no-result-state-component';
import SearchBar from '../../../common/SearchBar/search-bar-component';
import { IColumn } from '../../../../../../../core/models/report-response';
import * as Actions from '../../../../../../../redux/report/actions';

export interface IAvailableMeasuresProps {
  reportBuilderPortal: HTMLDivElement;
  expanded: boolean;
  collapse(): any;
}

const AvailableMeasures = (props: IAvailableMeasuresProps) => {
  const {
    reportBuilderPortal, expanded, collapse,
  } = props;
  const dispatch = useDispatch();

  const [allMeasures] = useSelector((state: ReduxModel.IGlobalState) => [state.report.allMeasures] as const);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [expandedEntity, setExpandedEntity] = useState<string[]>([]);

  useEffect(() => {
    if (!expanded) {
      setSearchQuery('');
      setExpandedEntity([]);
    }
  }, [expanded]);

  const availableMeasures = allMeasures
    .map((m, index) => ({ ...m, originalIndex: index } as IColumnWithIndex))
    .filter((c) => isMeasure(c) && !isMeasureAndApplied(c));
  const filtered = availableMeasures.filter((dim) => genericSearch(searchQuery, displayName(dim)));
  const hasFiltered = !isEmpty(filtered);
  const hasFields = !isEmpty(availableMeasures);
  const entityGroupedList = [] as [string, IColumnWithIndex[]][];

  const entityList = [] as string[];
  if (availableMeasures.some((c) => !isMeasureDynamic(c))) {
    entityList.push(PreDefinedFieldEntityType);
  }
  Object.keys(FieldEntitiesType).forEach((entity: string) => {
    if (availableMeasures.some((c) => isMeasureDynamic(c) && c.BuilderConfig?.Entity === entity)) {
      entityList.push(entity);
    }
  });

  entityList.forEach((entity) => {
    let dimList: IColumnWithIndex[];
    if (entity === PreDefinedFieldEntityType) {
      dimList = filtered.filter((c) => !isMeasureDynamic(c));
    } else {
      dimList = filtered.filter((c) => c.BuilderConfig?.Entity === entity);
    }
    entityGroupedList.push([entity, sortAdditionalFields(dimList)]);
  });

  const setMeasure = (index: number) => dispatch(Actions.setMeasureState(index, ColumnModes.Sortable));

  const searchBar = () => (
    <SearchBar
      onChange={(q) => setSearchQuery(q)}
      query={searchQuery}
      searchPlaceholder={SearchBarPlaceHolder.Values}
      setFocus={() => setExpandedEntity(entityList)}
    />
  );

  const measuresEntity = () => (
    <>
      {
        hasFiltered
          ? entityGroupedList.map((entity) => {
            const [entityKey, measures] = entity;
            let entityHasMeasures: boolean;
            if (entityKey === PreDefinedFieldEntityType) {
              entityHasMeasures = availableMeasures.some((m) => !isMeasureDynamic(m));
            } else {
              entityHasMeasures = availableMeasures.some((m) => m.BuilderConfig?.Entity === entityKey);
            }
            return (
              measuresField(entityKey, measures, entityHasMeasures)
            );
          }) : (
            <NoResultStateScreen
              message={AdditionalDimensionNoResultState.Fields}
              Icon={(
                <NoFieldsIcon
                  width={36}
                  height={33}
                />
              )}
            />
          )
      }
    </>
  );

  const measuresField = (entityKey: string, measures: Array<IColumnWithIndex>, entityHasMeasures: boolean) => (
    <FieldEntity
      key={entityKey}
      entity={entityKey}
      expand={expandedEntity.includes(entityKey)}
      handleExpandedEntity={() => handleExpandedEntity(entityKey)}
      showcase={(
        <>
          {
            entityHasMeasures
              ? (
                <>
                  {
                    searchQuery && !measures.length
                      ? (
                        <div>
                          {NoDimensionDataMessages.NoSearches}
                        </div>
                      )
                      : measures.map((c) => (
                        <DimensionItem
                          key={getFieldIdentifier(c)}
                          name={displayName(c)}
                          nameTooltipContent={getTextTooltipContentForDimension(c)}
                          isMaxLimit={false}
                          maxLimitDesc=""
                          add={() => setMeasure(c.originalIndex)}
                        />
                      ))
                  }
                </>
              )
              : (
                <div>
                  {NoDimensionDataMessages.AllMeasuresAdded}
                </div>
              )
            }
        </>
      )}
    />
  );

  const handleExpandedEntity = (entity: string) => {
    if (expandedEntity.includes(entity)) {
      setExpandedEntity([]);
      return;
    }
    setExpandedEntity([entity]);
  };

  return (
    <AvailableDimensions
      reportBuilderPortal={reportBuilderPortal}
      expanded={expanded}
      title={ReportDimensionsSettingsTitles.AddValues}
      context={ExpandListButtonContext.Values}
      desc={ReportDimensionsSettingsDescs.AddValues}
      collapse={collapse}
      search={hasFields && searchBar()}
      showcase={(
        hasFields
          ? measuresEntity() : (
            <EmptyStateScreen
              message={AdditionalDimensionEmptyState.Fields}
            />
          )
      )}
    />
  );
};

export default AvailableMeasures;

interface IColumnWithIndex extends IColumn{
  originalIndex: number;
}
