import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../Report/ReportSidebar/ReportView/report-view.selector';

const Dashboard = () => {
  // TODO: since dashboard component is not ready we will redirect the user to main login page
  const { isMarvin } = useSelector(authSelector);
  useEffect(() => {
    if (isMarvin) {
      window.open(document.referrer);
    } else {
      window.open(`${process.env.REACT_APP_LSQ_URL}`, '_self');
    }
  }, []);

  return (<></>);
};

export default Dashboard;
