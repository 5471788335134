import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ReduxModel } from 'core/models';
import * as Actions from 'redux/report-list/actions';
import { ReportListSortType } from 'core/constants/report';

const mapStateToProps = (state: ReduxModel.IGlobalState) => ({
  reduxState: state,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reduxAction: {
    sortingChange: (sortType:ReportListSortType) => {
      dispatch(Actions.sortingListChange(sortType));
    },
  },
});

export default () => connect(mapStateToProps, mapDispatchToProps);
