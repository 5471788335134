import styled from 'styled-components';
import 'react-dates/lib/css/_datepicker.css';

const StyledContainer = styled.div`
.CalendarDay__before_hovered_end{
  background-color:transparent !important;
  color:#484848;
}
table td{
  height: 36px !important;
  width: 36px !important;
}
.CalendarMonthGrid{
   height:320px;
   background-color: transparent;
}
.DayPicker_transitionContainer {
  width: 710px !important;
  height: 295px !important;
}
.se-calender-to-from{
    display: flex;
    justify-content: space-around;
    margin: 5px;
     .se-date-label{
    font-family: 'SemiBold';
    padding: 7px;
    background: rgb(229,240,251);
    width: 100%;
    text-align: center;
    margin: 0 5px;
    font-size: 13px;
   }   
}

.se-data-range-picker{
    .CalendarDay__selected{
    background-color: rgb(153, 197, 239) !important;
    border-radius: 50%;
    border: none !important;
    color: rgb(69, 79, 91) !important;
  }
  
  .CalendarDay__selected_span{
    background-color: rgba(229, 240, 251, 0.5) !important;
    border: none !important;
    color: rgb(69, 79, 91) !important;
    :hover{
        background-color: rgba(229, 240, 251) !important;
        border-radius: 50%;
    }
  }
  

  .DateInput,.DateRangePickerInput_arrow{
    display: none !important;
  }
  .DateRangePicker_picker{
    position:static !important;
  }


  .DateRangePickerInput__withBorder {
    border:none !important;
  }
  .DayPicker__withBorder{
    box-shadow:none !important;
  }

  .CalendarDay__default {
  border:none !important;
  
}
.CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
  border: none !important;
}

.CalendarMonth_caption{
  font-family: 'SemiBold';
  font-size: 13px !important;
  padding-bottom: 45px !important;
  padding-top: 5px;
}

.DayPicker_weekHeader{
  top: 35px !important;
}
.DayPickerNavigation_button__horizontalDefault{
    top:0px;
}
.CalendarDay{
  font-size: 13px !important;
}
.DayPicker_weekHeader_li small{
  font-family: 'SemiBold';
  font-size: 13px !important;
  color: rgb(69, 79, 91);
}

.DayPickerNavigation_button__default{
  border: none !important;
 
}
.DayPickerNavigation_svg__horizontal{
  fill:rgb(0, 111, 214) !important;
}
}
`;
export default StyledContainer;
