/* eslint-disable no-shadow */
import PinnedIcon from 'components/common/svg/Pinned/pinned.svg';

export enum ReportTypeName {
  PinnedReport = 'Pinned Reports'
}

export enum ReportListSortType {
  ReportNameAZ = 'A-Z',
  ReportNameZA = 'Z-A'
}

export enum LSQLinkType {
  LINKTOLSQ = 'LINKTOLSQ',
  LINKTOSELF = 'LINKTOSELF',
}

export const ReportSortData = [
  {
    label: 'Report Name [A-Z]',
    value: 'A-Z',
  },
  {
    label: 'Report Name [Z-A]',
    value: 'Z-A',
  },
];

export enum ReportIcon {
  Phone = 'Phone',
  Bulb = 'Bulb',
  Star = 'Star',
  Sheet = 'Sheet',
  Person = 'Person',
  Revenue = 'Revenue',
  Custom = 'Custom'
}

export enum ReportActionTypes {
  Pin = 'Pin',
  Unpin = 'Unpin'
}

export const ReportActionData = (isPinned: boolean) => [
  (isPinned
    ? {
      icon: PinnedIcon,
      label: ReportActionTypes.Unpin,
      value: ReportActionTypes.Unpin,
    }
    : {
      icon: PinnedIcon,
      label: ReportActionTypes.Pin,
      value: ReportActionTypes.Pin,
    }),
];

export enum ToolbarMenuItemTypes {
  Filters = 'Filters',
  ReportViews = 'Report Views',
  Setting = 'Visualization Settings',
  ZeroValues = 'Zero Values'
}

export enum ToolbarDisplayMenuItems {
  Filters = 'Filters',
  Setting = 'Visualize',
  ReportViews = 'Report Views',
  ZeroValues = 'Zero Values'
}

export enum ReportDimensionsSettingsTypes {
  AvailableFields = 'AdditionalFields',
  RowGrouping = 'RowGrouping',
  ColumnGrouping = 'ColumnGrouping',
}

export enum ReportDimensionsSettingsTitles {
  AvailableFields = 'Additional Fields',
  RowGrouping = 'Row Grouping',
  ColumnGrouping = 'Column Grouping',
  AvailableFilters = 'Additional Filters',
  DrilldownColumn = 'Drilldown Column',
  AddValues = 'Add Values',
  Measures = 'Values',
}

export enum ReportDimensionsSettingsDescs {
  RowGrouping = 'Change the way the rows are grouped. Drag and drop to change the order.',
  ColumnGrouping = 'Change the way the columns are grouped. Drag and drop to change the order.',
  DrilldownColumn = 'Change the way the drilldown columns are grouped. Drag and drop to change the order.',
  AddValues= '',
}

export const ReportDimensionsZeroValuesTitle = 'Show records with no values';
export const ReportDimensionsZeroValuesDesc = 'View rows with zero values in report. This may impact report load time.';

export enum ColumnActionTypes {
  Drilldown = 'DRILLDOWN',
  AutoDrillDown = 'AUTODRILLDOWN',
  SetFilter = 'SET_FILTER'
}

export enum ColumnActionStyles {
  LinkPrimary = 'link-primary',
}

export enum DrilldownChangesTypes {
  FilterOnly = 'Filter_Only',
}

export enum DrilldownChangeTypes {
  Filter = 'Filter',
}

export enum DimensionStatus {
  NotApplied = 0,
  Applied,
  Mandatory,
}

export enum ZeroAnchorStatus {
  Disabled = 0,
  Enabled,
  Applied,
}

export enum ColumnModes {
  Hidden = 0,
  Visible,
  Sortable
}

export enum DimensionItemWarning {
  OnRemove = 'Unselecting this dimension will also disable the associated filter.',
  MinGrouping = 'Minimum {{count}} dimension has to be remain selected',
  MaxGrouping = 'Maximum Limit of {{count}} selected dimension is reached',
}

export enum ZeroValuesHelpText {
  Disabled = 'Zero Values against this dimension is not allowed.'
}

export enum MinRowGrouping {
  Table= 1,
  ColumnChart = 1,
  LineChart = 1,
  AreaChart = 1,
  BarChart = 1,
  summary = 1,
  multiline = 1,
  NoData = 1
}

export enum MaxRowGrouping {
  Table = 3,
  ColumnChart = 2,
  LineChart = 2,
  AreaChart = 2,
  BarChart = 2,
  summary = 2,
  multiline = 2,
  NoData = 2
}
export enum MinColumnGrouping {
  Table = 1,
  ColumnChart = 0,
  LineChart = 0,
  AreaChart = 0,
  BarChart = 0,
  summary = 1,
  multiline = 1,
  NoData = 1
}

export enum MaxColumnGrouping {
  Table = 1,
  ColumnChart = 0,
  LineChart = 0,
  AreaChart = 0,
  BarChart = 0,
  summary = 1,
  multiline = 1,
  NoData = 1
}

export enum DimensionMode {
  RowGroup = 0, // default
  ColumnGroup = 1,
  Both = 2,
}

export enum Aggregation {
  Sum = 'SUM',
}

export const DimensionIdentifier = 'DIMENSIONIDENTIFIER=';

export enum DisableReportViewOnDrilldownInfo {
  ReportViewNewButton = 'Drilldown view cannot be saved as a new view.',
}

export const DimensionNoValue = '[No Value]';

export enum FieldEntitiesType {
  Lead = 'Lead',
  Opportunity = 'Opportunity',
  Activity = 'Activity',
  Task = 'Task',
  User = 'User',
}
export const PreDefinedFieldEntityType = 'Pre-defined';

export const CreateNewReportView = 'Create New View';

export const DateTimeBinning = [
  {
    label: 'None',
    value: 'NoneExpr',
    example: '',
    abbreviation: '',
  },
  {
    label: 'Hour of the Day',
    value: 'ToHourExpr',
    example: 'Ex: 00:00, 01:00....23:00',
    abbreviation: 'HOD',
  },
  {
    label: 'Date',
    value: 'ToDateOfYearExpr',
    example: 'Ex: Jan 01, 2022',
    abbreviation: '',
  },
  {
    label: 'Month of the Year',
    value: 'ToMonthOfYearExpr',
    example: 'Ex: Jan, Feb',
    abbreviation: 'MOY',
  },
  {
    label: 'Month',
    value: 'ToMonthExpr',
    example: 'Ex: Jan 2022, Feb 2022',
    abbreviation: '',
  },
  {
    label: 'Year',
    value: 'ToYearExpr',
    example: 'Ex: 2021, 2022',
    abbreviation: '',
  },
  {
    label: 'Quarter',
    value: 'ToQuarterExpr',
    example: 'Ex: 2021 Q1, 2022 Q2',
    abbreviation: '',
  },
  {
    label: 'Quarter of the year',
    value: 'ToQuarterOfYearExpr',
    example: 'Ex: Q1, Q2',
    abbreviation: 'QOY',
  },
  {
    label: 'Week of the year',
    value: 'ToYearWeekExpr',
    example: 'Ex: Week1, Week2',
    abbreviation: 'WOY',
  },
  {
    label: 'Week',
    value: 'ToWeekExpr',
    example: 'Ex: 2021 W1, 2021 W2',
    abbreviation: '',
  },
  {
    label: 'Day',
    value: 'ToDayOfWeekExpr',
    example: 'Ex: Sunday, Monday, Tuesday',
    abbreviation: '',
  },
];

export enum ReportViewButtons {
  Save = 'Save',
  Cancel = 'Cancel'
}

export const ReportViewSaved = 'Report view saved successfully';
export const TotalSummaryText = 'Summary';
export const SieraAutoDrilldownLink = 'siera-autodrilldown-link';

export const MaxDrilldownGrouping = 10;

export const PivotColGrpHeaderSeparator = '<SIERA_INTERNAL_REPLACE>';

export const EncryptedText = 'xxxxx';

export enum MaskedMessage {
  ApplyReportView = 'Report Views can\'t be selected due to Permission Templates',
  CreateReportView = 'Report Views can\'t be created due to Permission Templates',
  AvailableFilter = 'Filter cannot be added due to Permission Template',
  AppliedFilter = 'Filter disabled due to Permission Templates',
  AvailableDimension = 'Field cannot be added due to Permission Template',
  Header = 'Permission Template Applied',
  HeaderTooltip = 'Some data has been restricted due to Permission Templates',
}

export enum ExportStatus {
  InProgress = 'IN-PROGRESS',
  InQueue = 'IN-QUEUE',
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export enum ExpiryTimeText {
  Expired = 'File Expired '
}
export enum dayHeader {
  Today = 'Today',
  Yesterday = 'Yesterday'
}

export enum formatType {
  CSV = 'CSV',
  PDF = 'PDF',
  Excel = 'Excel',
  PNG = 'PNG',
  JPG = 'JPG'
}

export const duplicateReportViewMessage = 'This name is already in use. Try using other name.';

export enum dropdownExportButtonNames {
  CSV = 'Quick CSV Export',
  Excel = 'Quick XLSX Export',
  Export = 'Export...',
  PNG = 'Export Chart as PNG',
  JPG = 'Export Chart as JPG',
  PDF = 'Export Chart as PDF'
}

export const dailyQuotaApiResponse = 'Your daily quota for exported reports has exceeded';

export const dailyQuotaWarning = 'Export limit reached. Try again after {{timeLeft}}';

export const ExportNote = 'Note : Exporting will take some time. Once the file is ready, it will be available in this section.';

export const dailyQuotaTooltip = 'Export in progress...';

export enum JobTypes {
  Merge = 'Merge',
  Load = 'Load'
}

export const dataUpdate = 'The data has been updated';

export const loadNewData = 'Click on the refresh icon to load the new data.';

export const updateNewData = 'Click on the refresh icon to load the modified data.';

export const jobInfoTextLoad = 'Eg: Any new lead, activity, call, task or opportunity will reflect in ';
export const jobInfoTextMerge = 'Eg: Modifications in the parent lead and opportunity will reflect in ';

export enum Timers{
  Minute=60000,
  TwoSeconds=2000,
}
export enum ReportViewsActionTypes {
  Create = 'create',
  Delete = 'delete',
  Undo = 'Undo',
  Share = 'Share',
  StopShare = 'StopShare',
}
export enum ReportViewActionSuccess {
  Create = 'Report view saved successfully',
  Delete = 'Report view deleted successfully.',
  Share = 'View shared successfully.',
  StopShare = 'Successfully stopped view sharing.',
}
export enum headerOptions {
  AppliedFilters = 'Applied Filters'
}

export enum TooltipPlacement {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
  TopLeft = 'topLeft',
  TopRight = 'topRight',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
  RightTop = 'rightTop',
  RightBottom = 'rightBottom',
  LeftTop = 'leftTop',
  LeftBottom = 'leftBottom',
}

export const TooltipDelayTime = 1.5;

export const DefaultTimezone = 'US/Alaska';

export const InternationalDateLineWest = 'International Date Line West';

export enum ReportViewTypes {
  Default = 'Default',
  Custom = 'Custom',
  Shared = 'Shared',
}

export const ViewTypeProperties = {
  [ReportViewTypes.Default]: {
    color: '#084E96',
    Message: 'View shared by : System',
  },
  [ReportViewTypes.Shared]: {
    color: '#084E96',
    Message: 'View shared by : Admin',
  },
  [ReportViewTypes.Custom]: {
    color: '#3BB345',
    Message: 'View created by me',
  },
};

export enum ReportViewActivityType {
  Shared = 'Shared',
  Unshared = 'Unshared'
}

export enum ReportViewActionMessage {
  Share = 'This view going to be shared with all the users',
  StopShare = 'Sharing of this view is going to be stopped for all users',
  DeleteShare = 'Deleting this view would stop sharing for all users'
}
export enum ReportViewURLTypes {
  rvId = 'rvId',
  rvType = 'rvType',
  // rvu is the UserId so that user can not identify that we have added the userID
  rvu = 'rvu',
}

export enum FilterButtonTypes {
  reset = 'reset',
  apply = 'apply',
}

export enum ExpandListButtonContext {
  Filters = 0,
  RowGroup,
  ColGroup,
  Drilldown,
  Values,
}

export enum AdditionalFieldTitle {
RowGroup = 'Add Row Grouping',
ColGroup = 'Add Column Grouping',
Drilldown = 'Add Columns',
}

export const expandFiltersButtonLabel = 'Filters';
export const expandFieldsButtonLabel = 'Fields';
export const expandValuesButtonLabel = 'Values';

export const AppliedFilter = 'Applied Filters: ';
