import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ReduxModel } from 'core/models';
import * as Actions from 'redux/report/actions';

const mapStateToProps = (state: ReduxModel.IGlobalState) => ({
  reduxState: state,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reduxAction: {
    paginationChange: (paginationKey: string, paginationValue: any, isAutodrilldown?: boolean) => {
      dispatch(Actions.paginationChange(paginationKey, paginationValue, isAutodrilldown));
    },
  },
});
export default () => connect(mapStateToProps, mapDispatchToProps);
