import React from 'react';

type Props = {
    width: number;
    height: number;
    fill?: string;
}

const CsvIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
  >
    <g
      id="Group_21985"
      data-name="Group 21985"
      transform="translate(-4613 -7666)"
    >
      <rect
        id="Rectangle_7980"
        data-name="Rectangle 7980"
        width="20"
        height="20"
        transform="translate(4613 7666)"
        fill="none"
      />
      <path
        id="Path_10275"
        data-name="Path 10275"
        d="M0,0H20V20H0Z"
        transform="translate(4613 7666)"
        fill="none"
      />
      <path
        id="Path_10276"
        data-name="Path 10276"
        d="M12.333,2H5.667A1.672,1.672,0,0,0,4,3.667V17a1.664,1.664,0,0,0,1.658,1.667H15.667A1.672,1.672,0,0,0,17.333,17V7Zm3.333,15h-10V3.667H11.5V7.833h4.167Z"
        transform="translate(4612.333 7665.667)"
        fill={fill}
      />
      <path
        id="Path_10278"
        data-name="Path 10278"
        d="M1,0H12a1,1,0,0,1,1,1V5a1,1,0,0,1-1,1H1A1,1,0,0,1,0,5V1A1,1,0,0,1,1,0Z"
        transform="translate(4613 7675)"
        fill={fill}
      />
      <path
        id="Path_10277"
        data-name="Path 10277"
        d="M3.437-.976A2.693,2.693,0,0,1,2.27-.759,1.968,1.968,0,0,1,.791-1.312,2.02,2.02,0,0,1,.253-2.785,2.158,2.158,0,0,1,.858-4.374a2.117,2.117,0,0,1,1.571-.61,2.938,2.938,0,0,1,1.008.151v.886A1.787,1.787,0,0,0,2.5-4.192a1.239,1.239,0,0,0-.929.362,1.342,1.342,0,0,0-.353.98,1.317,1.317,0,0,0,.333.944,1.177,1.177,0,0,0,.9.352,1.914,1.914,0,0,0,.986-.262Zm.524-.011V-1.9a1.751,1.751,0,0,0,.538.312,1.73,1.73,0,0,0,.587.1,1.3,1.3,0,0,0,.3-.031A.711.711,0,0,0,5.606-1.6a.379.379,0,0,0,.13-.131A.327.327,0,0,0,5.779-1.9a.354.354,0,0,0-.068-.214.736.736,0,0,0-.187-.174,1.949,1.949,0,0,0-.281-.154q-.162-.074-.35-.151a1.788,1.788,0,0,1-.714-.487,1.063,1.063,0,0,1-.235-.695,1.107,1.107,0,0,1,.128-.548A1.089,1.089,0,0,1,4.421-4.7a1.564,1.564,0,0,1,.511-.218,2.627,2.627,0,0,1,.615-.07,3.7,3.7,0,0,1,.565.038,2.223,2.223,0,0,1,.454.118v.852A1.374,1.374,0,0,0,6.344-4.1a1.771,1.771,0,0,0-.249-.09,1.9,1.9,0,0,0-.255-.053A1.8,1.8,0,0,0,5.6-4.26a1.255,1.255,0,0,0-.285.03.731.731,0,0,0-.216.084.418.418,0,0,0-.137.13.307.307,0,0,0-.048.169.324.324,0,0,0,.054.184.621.621,0,0,0,.154.154,1.632,1.632,0,0,0,.242.142q.142.07.322.144a3.586,3.586,0,0,1,.44.218,1.564,1.564,0,0,1,.335.261,1.015,1.015,0,0,1,.214.332,1.173,1.173,0,0,1,.074.434,1.164,1.164,0,0,1-.13.574,1.052,1.052,0,0,1-.352.376,1.525,1.525,0,0,1-.517.207,2.988,2.988,0,0,1-.622.063,3.461,3.461,0,0,1-.639-.057A1.93,1.93,0,0,1,3.961-.987ZM10.82-4.915,9.413-.831H8.371L6.981-4.915h.991l.852,2.843a2.036,2.036,0,0,1,.083.407h.017a2.3,2.3,0,0,1,.088-.419l.846-2.831Z"
        transform="translate(4613.963 7680.871)"
        fill="#fff"
      />
    </g>
  </svg>

);

CsvIcon.defaultProps = {
  fill: '#637381',
};

export default CsvIcon;
