/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
export enum LinearBarColorTypes {
    Primary = 'primary',
    Danger = 'danger',
    Warning = 'warning',
}

export enum SizeTypes {
    Regular = 'RG',
    Small = 'SM',
    XSmall = 'XS'
}
