import React from 'react';

type Props = {
    width: number;
    height: number;
}

const QuickExcelIcon = ({
  width, height,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.80816 15.0992L6.39191 11.0259H4.3132C4.20879 11.0259 4.13286 10.9777 4.0854 10.8814C4.03794 10.7851 4.03794 10.6937 4.0854 10.607L7.57364 4.69922H8.15738L7.57364 8.75811H9.62386C9.72827 8.75811 9.80658 8.80626 9.85879 8.90255C9.91099 8.99885 9.91336 9.09033 9.86591 9.177L6.39191 15.0992H5.80816Z"
      fill="#007728"
    />
    <path
      d="M1.35 18C0.99 18 0.675 17.865 0.405 17.595C0.135 17.325 0 17.01 0 16.65V1.35C0 0.99 0.135 0.675 0.405 0.405C0.675 0.135 0.99 0 1.35 0H9.4725L14.4 4.9275V16.65C14.4 17.01 14.265 17.325 13.995 17.595C13.725 17.865 13.41 18 13.05 18H1.35ZM8.7975 5.535V1.35H1.35V16.65H13.05V5.535H8.7975ZM1.35 1.35V5.535V1.35V16.65V1.35Z"
      fill="#007728"
    />
  </svg>

);

export default QuickExcelIcon;
