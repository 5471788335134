import styled from 'styled-components';

const StyledBackButtonContainer = styled.div`
background-color: var(--noDataBackground);
box-shadow: 0px 0px 2px var(--boxShadow);
border: 0.30000001192092896px solid var(--backButtonBorder);
opacity: 1;
display: flex;
align-items: center;
box-sizing: border-box;
position: relative;
margin-bottom: 16px;
.se-last-back-item-wrapper{
    height: 29px;
}
.se-last-back-item{
    color: var(--paginationColor);
    font-size: 12px;
}
.se-back-item{
    height: 32px;
    word-break: break-word;
    cursor: pointer;
    text-align: left;
    color: var(--collapsibleColor);
    background-color: var(--base-transparent);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    :disabled {
        cursor: not-allowed;
    }
    font-family: SemiBold;
}


> .se-dropdown{
    list-style: none;
    position: absolute;
    z-index: 1;
    background: var(--base-white);
    
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    li .se-back-item {
        width: 100%;
    }
}
> .se-dropdown-btn{
    padding: 0;
    margin: 0;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    height: 20px;
    padding: 0 4px;
    border: none;
    letter-spacing: -1px;
    color: #454F5B;
    :hover{
        background: #ADBAC7;
    }

}
.se-dropdown-btn.active{
    background: #ADBAC7;  
}

@media (min-width: 320px) {
    height: 28px;
    padding: 0 10px;
    .se-back-item{
        font-size: 12px;
        line-height: 13px;
        .se-back-icon {
            margin:0 8px;
        }
    }
 
    > .se-dropdown{
        left:0px;
        top: 13px;
        margin-top:20px;
        width: 180px;
        padding:0px;
        li .se-back-item {
            padding: 4px 12px;
        }
    }
    > .se-dropdown-btn{
        font-size: 15px;
    }
  }
@media (min-width: 768px) {
    height: 32px;
    padding: 0 10px;
    .se-back-item{
        font-size: 12px;
        line-height: 13px;
        padding: 5px;
        .se-back-icon {
            margin:0 8px;
        }
    }
  
    > .se-dropdown{
        top: 13px;
        li .se-back-item {
            padding: 4px 12px;
        }
    }
    > .se-dropdown-btn{
        font-size: 15px;
    }
  }

  @media (min-width: 1024px) {
    height: 32px;
    padding: 0 10px;
    .se-back-item{
        font-size: 12px;
        line-height: 13px;
        padding: 8px 5px;
        .se-back-icon {
            margin: 0 8px;
        }
    }
   
    > .se-dropdown{
        top: 13px;
        li .se-back-item {
            padding: 4px 12px;
        }
    }
    > .se-dropdown-btn{
        font-size: 15px;
    }
  }
`;
export default StyledBackButtonContainer;
