import React, { useState } from 'react';
import { sortBy } from 'lodash';
import { ReportListSortType } from 'core/constants/report';
import NextIcon from 'components/common/svg/Next/next.svg';
import { IProps } from 'components/feature/ReportListing/ReportSection/report-section.d';
import StoreConnector from 'components/feature/ReportListing/ReportSection/report-section.redux';
import StyledReportSection from 'components/feature/ReportListing/ReportSection/report-section.style';
import ReportCard from 'components/feature/ReportListing/ReportSection/ReportCard/report-card.component';

const ReportSection = (props: IProps) => {
  const { reportSection, reduxState, ...rest } = props;
  const { reportList } = reduxState;
  const { sort } = reportList;

  const [expand, setExpand] = useState(true);

  const reports = sortBy(reportSection.Reports.Data, (a) => a.DisplayName.toLowerCase());
  if (sort === ReportListSortType.ReportNameZA) {
    reports.reverse();
  }

  return (
    <StyledReportSection>
      <div
        key={reportSection.SectionId}
        className="se-report-section"
      >
        <button
          type="button"
          onClick={() => setExpand(!expand)}
          className="se-report-section-header"
        >
          <span
            className={`se-report-section-icon ${expand ? 'se-rotate-icon' : ''}`}
          >
            <NextIcon
              width={24}
              height={24}
              fill="#454F5B"
            />
          </span>
          <span
            className="se-report-section-name"
          >
            {reportSection.DisplayName}
          </span>
          <span
            className={`se-report-section-count ${expand ? 'hide' : ''}`}
          >
            {reportSection.TotalCount}
          </span>
        </button>
        <div
          className={`se-report-section-body ${expand ? '' : 'hide'}`}
        >
          {reports.map((item) => (
            <ReportCard
              key={item.ReportId}
              sectionId={reportSection.SectionId}
              report={item}
              {...rest}
            />
          ))}
        </div>
      </div>
    </StyledReportSection>
  );
};

export default StoreConnector()(ReportSection);
