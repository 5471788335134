import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Actions from 'redux/report-list/actions';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reduxAction: {
    reportPinUnpinRequest: (reportId: string, isPinned: boolean) => {
      dispatch(Actions.reportPinUnpinRequest(
        reportId,
        isPinned,
      ));
    },
  },
});

export default () => connect(null, mapDispatchToProps);
