/* eslint-disable no-nested-ternary */
import React from 'react';
import Tooltip from 'rc-tooltip';
import { IDimensionItemProps } from 'components/feature/Report/ReportSidebar/common/AvailableDimensions/DimensionItem/dimension-item.d';
import StyledContainer from 'components/feature/Report/ReportSidebar/common/AvailableDimensions/DimensionItem/dimension-item.style';
import AddIcon from 'components/common/svg/Add/add.svg';
import LockIcon from 'components/common/svg/Lock/lock.svg';
import 'rc-tooltip/assets/bootstrap_white.css';
import { MaskedMessage } from 'core/constants/report';

const DimensionItem = (props: IDimensionItemProps) => {
  const {
    name, nameTooltipContent, isMaxLimit, maxLimitDesc, add, className, isMasked,
  } = props;

  const addButton = (
    <button
      className={`se-icon-cn se-add-btn ${isMaxLimit ? 'inactive' : 'active'} ${className}`}
      type="button"
      onClick={() => {
        if (isMaxLimit) {
          return;
        }
        add();
      }}
    >
      <AddIcon
        width={16}
        height={16}
        strokeFill="var(--infoIconColor)"
      />
    </button>
  );

  return (
    <StyledContainer>
      <Tooltip
        placement="bottom"
        overlay={(
          <span>
            {nameTooltipContent}
          </span>
        )}
        mouseEnterDelay={1.5}
      >
        <div
          className="se-item-name"
        >
          {`${name}`}
        </div>
      </Tooltip>
      <div>
        {isMaxLimit && !isMasked
          ? (
            <Tooltip
              placement="bottom"
              overlay={(
                <span>
                  {maxLimitDesc}
                </span>
              )}
              mouseEnterDelay={1.5}
            >
              {addButton}
            </Tooltip>
          )
          : isMasked
            ? (
              <div className="se-lock-icon-tooltip">
                <Tooltip
                  placement="bottom"
                  overlay={(
                    <span>
                      {MaskedMessage.AvailableFilter}
                    </span>
              )}
                  mouseEnterDelay={1.5}
                  overlayClassName="se-restricted-field"
                >
                  <div className="se-icon-cn">
                    <LockIcon
                      width={20}
                      height={20}
                    />
                  </div>
                </Tooltip>
              </div>
            )
            : addButton}
      </div>
    </StyledContainer>
  );
};

export default DimensionItem;
