import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StyledExportComponent from 'components/feature/Report/ReportSidebar/ExportPanel/export-panel.style';
import { IExportProps } from 'components/feature/Report/ReportSidebar/ExportPanel/export-panel.d';
import { NoExportState } from 'core/constants/visualizations';
import CsvIcon from 'components/common/svg/Csv/csv.svg';
import {
  exportDataLoadRequest, exportListRequest, exportListRequestByIds, getExportUsageStatus, refresh,
} from 'redux/report/actions';
import { ReduxModel } from 'core/models';
import { ReportActionTypes } from 'core/constants/redux-action-types';
import { APIRequestStatus } from 'core/constants/redux';
import { isEmpty, orderBy } from 'lodash';
import { IExportData } from 'core/models/report-response';
import {
  dayHeader, formatType, ExportStatus, dailyQuotaWarning, ExportNote, DefaultTimezone,
} from 'core/constants/report';
import NoExportScreen from 'components/feature/Report/ReportSidebar/common/NoExportState/no-export-state-component';
import ExportedReportInfo from 'components/feature/Report/ReportSidebar/ExportedReportInfo/exported-report-info-component';
import ErrorComponent from 'components/common/Error/error.component';
import { ErrorMessage } from 'core/constants/errors';
import ExportTickIcon from 'components/common/svg/ExportTick/exporttick.svg';
import ExportCloseIcon from 'components/common/svg/Close/exportclose.svg';
import LargeExportIcon from 'components/common/svg/Export/largeexport.svg';
import {
  getCurrentHourAccordingToUserTimeZone, getCurrentHoursMinsAccordingToUserTimeZone, getTodaysDateAccordingToUserTimeZone, getYesterdaysDateAccordingToUserTimeZone,
} from 'core/utils/formatters.utility';
import QuotaWarningIcon from 'components/common/svg/QuotaWarning/quotaWarning.svg';
import { PendoClassNames } from '../common/constants';

const ExportComponent = (props:IExportProps) => {
  const { openPannel, setOpenPannel } = props;

  const [
    exportListData,
    requestProcessing,
    reportId,
    userAttributes,
    isDailyLimitReached,
    reportData,
    totalDailyExports,
    exportsConsumed,

  ] = useSelector((state: ReduxModel.IGlobalState) => [
    state.report.exportListData,
    state.report.requestProcessing,
    state.report.reportId,
    state.auth.userAttributes,
    state.report.isDailyLimitReached,
    state.report.reportData,
    state.report.totalDailyExports,
    state.report.exportsConsumed,
  ] as const);
  const {
    [ReportActionTypes.EXPORT_DATA_LOAD_REQUEST]: exportDataLoadStatus,
    [ReportActionTypes.EXPORT_LIST_LOAD_REQUEST]: exportListLoadStatus,
    [ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST]: configLoadStatus,
    [ReportActionTypes.REPORT_DATA_LOAD_REQUEST]: reportDataLoadStatus,
    [ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST]: autodrilldownDataLoadStatus,
  } = requestProcessing;

  const dispatch = useDispatch();

  const filterError = Object.keys(requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST])
    .findIndex((item) => (requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST][item]
          === APIRequestStatus.Failure)) !== -1;

  const checkDisabledState = () => (exportListData && !!(exportListData.find((element) => element.ReportID === reportId && element.Status === ExportStatus.InQueue)))
                                   || (reportData && reportData.Raw && isEmpty(reportData.Raw.Data)) || exportDataLoadStatus === APIRequestStatus.Processing || reportDataLoadStatus === APIRequestStatus.Failure
                                   || configLoadStatus === APIRequestStatus.Failure || autodrilldownDataLoadStatus === APIRequestStatus.Failure || filterError;

  const toggleRef = useRef(null);
  const isLoading = exportListLoadStatus === APIRequestStatus.Processing || reportDataLoadStatus === APIRequestStatus.Processing;
  const error = (exportDataLoadStatus === APIRequestStatus.Failure && !isDailyLimitReached) || exportListLoadStatus === APIRequestStatus.Failure;
  const TimeArray = [3000, 30000];
  const hasReports:boolean = !!exportListData;
  // based on user time zone we get hour and mins and calcualte time till 12 AM for next Quota update
  const timeZoneOfUser = userAttributes?.UserTimeZone || DefaultTimezone;
  const dailyQuotaTime = `${23 - getCurrentHourAccordingToUserTimeZone(timeZoneOfUser)}hr:${59 - getCurrentHoursMinsAccordingToUserTimeZone(timeZoneOfUser)}m`;
  const sortedExportList:IExportData[] = exportListData && orderBy(exportListData, (el) => new Date(el.CreatedOn), 'desc');
  // creating an object with date as key and array of all the exported reports created on that date as value
  const dividedExportList = sortedExportList && sortedExportList.reduce((dividedExportListItem:any, element:IExportData) => {
    const newDate = new Date(element.CreatedOn);
    const date = newDate.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'long', year: 'numeric',
    }).replace(/ /g, ' '); // dd month yyyy
    if (!dividedExportListItem[date]) {
      // eslint-disable-next-line no-param-reassign
      dividedExportListItem[date] = [];
    }
    dividedExportListItem[date].push(element);
    return dividedExportListItem;
  }, {});
  let timerId:any;

  const [arrayIndex, setArrayIndex] = useState(-1);
  // on clicking outside the container, close the panel
  useEffect(() => {
    const checkIfClickedOutside = (event: any) => {
      if (!isLoading && openPannel && toggleRef.current && !toggleRef.current?.contains(event.target)) {
        setOpenPannel(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  });

  // handling initial load request and closing panel actions
  useEffect(() => {
    if (openPannel) {
      dispatch(getExportUsageStatus());
      dispatch(exportListRequest());
      setArrayIndex(0);
    } else {
      clearTimeout(timerId);
      setArrayIndex(-1);
    }
  }, [openPannel]);

  // timer logic to call the api according to TimeArray
  // time array - 3000 sec is for delay for call back api response
  useEffect(() => {
    if (arrayIndex !== -1) {
      const time = arrayIndex < TimeArray.length ? TimeArray[arrayIndex] : 60000;
      timerId = setTimeout(() => {
        if (ifAnyReportInProgress()) {
          dispatch(exportListRequestByIds(getInProgressExportIds()));
        }
        setArrayIndex(arrayIndex + 1);
      }, time);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [arrayIndex]);

  const ifAnyReportInProgress = () => !!(exportListData?.find((element) => element.Status === ExportStatus.InQueue));
  const getInProgressExportIds = () => exportListData?.filter((data:IExportData) => data.Status === ExportStatus.InQueue).map((data:IExportData) => data.ExportID);
  const onReportClickHandler = () => {
    dispatch(exportDataLoadRequest(formatType.CSV));
    // after adding new export request we want to start the timer again
    setArrayIndex(0);
  };

  const today = getTodaysDateAccordingToUserTimeZone(timeZoneOfUser);
  const yesterday = getYesterdaysDateAccordingToUserTimeZone(timeZoneOfUser);
  const headerVal = (header:string) => {
    if (header === today) {
      return dayHeader.Today;
    }
    if (header === yesterday) {
      return dayHeader.Yesterday;
    }
    return header;
  };

  let resultantComponent;
  if (error) {
    resultantComponent = (
      <ErrorComponent
        message={ErrorMessage.Export}
        tenant={userAttributes && userAttributes.OrgShortCode}
        issue={`Report: ${reportId}`}
        retry={() => dispatch(refresh())}
      />
    );
  } else if (hasReports) {
    resultantComponent = (
      <div className="se-export-showcase se-custom-scrollbar">
        {dividedExportList && Object.entries(dividedExportList).map((arri) => {
          let header = arri[0];
          header = headerVal(header);
          const sublist:any = arri[1];
          return (
            <div>
              <div className="se-header">{header}</div>
              {sublist.map((element:IExportData) => (
                <ExportedReportInfo
                  exportID={element.ExportID}
                  filename={element.Filename}
                  status={element.Status}
                  createdOn={element.CreatedOn}
                  downloadLink={element.DownloadLink}
                  expiresOn={element.ExpiresOn}
                />
              ))}
            </div>
          );
        })}
      </div>
    );
  } else {
    resultantComponent = (
      <NoExportScreen
        message={NoExportState.Export}
      />
    );
  }

  return (
    <StyledExportComponent>
      <div>
        <div
          className={`se-export-container ${openPannel ? '' : 'toggle'}`}
          ref={toggleRef}
        >
          <div className="se-export-cont-header">
            <div className="se-header-icon">
              <LargeExportIcon
                width={20}
                height={20}
                fill="#454F5B"
              />
            </div>
            <div className="se-header-label"> Export Reports </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className="se-icon-close-button"
              onClick={() => setOpenPannel(false)}
            >
              <ExportCloseIcon
                width={12}
                height={12}
                fill="#919EAB"
              />
            </div>
          </div>
          <div className="se-export-divider" />
          <div className="se-export-note-section">
            <div className="se-export-note">{ExportNote}</div>
          </div>
          <div className="se-export-format-section">
            <div className="se-label-limit">
              <span className="se-format-label"> Export As : </span>
              <span className="se-limit-info">
                Daily Export Limit:
                <span className={`se-limit-numbers ${isDailyLimitReached ? 'se-limit-reached' : ''} `}>{`${exportsConsumed}/${totalDailyExports}`}</span>
              </span>
            </div>

            <div className="se-format-types">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div className={`se-type-format ${isDailyLimitReached || checkDisabledState() ? 'se-no-border' : ''}`}>
                <CsvIcon
                  width={20}
                  height={20}
                  fill="#A456BA"
                />
                <span className="se-type-text"> CSV </span>
                {!isDailyLimitReached && !checkDisabledState() && (
                  <div className="se-selected-tick-icon">
                    <ExportTickIcon
                      height={9}
                      width={11}
                      fill="#006FD6"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {
          !isDailyLimitReached
            ? (
              <div className="se-export-button-container">
                <div className="se-inner-export-button">
                  <button
                    type="button"
                    className={`se-export ${PendoClassNames.ExportPanel}`}
                    onClick={() => onReportClickHandler()}
                    disabled={checkDisabledState()}
                  >
                    Export
                  </button>
                </div>
              </div>
            )
            : (
              <div className="se-daily-quota-warning-outer">
                <div className="se-daily-quota-warning-inner">
                  <div className="se-warning-icon">
                    <QuotaWarningIcon
                      width={16}
                      height={16}
                    />
                  </div>
                  <div className="se-warning-text">
                    {dailyQuotaWarning.replace('{{timeLeft}}', dailyQuotaTime)}
                  </div>
                </div>
              </div>
            )
        }
          <div className="se-export-divider" />
          {resultantComponent}
        </div>
      </div>
    </StyledExportComponent>
  );
};
export default ExportComponent;
