import styled from 'styled-components';

const StyledReportComponent = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
overflow: hidden;

    .se-main-cn {
        flex: 1;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        &.v1ReportStyle{
            border-bottom: 1px solid var(--paginationBorder);
        }
    }

    .se-main-body {
        width: 100%;
        height: 100%;
    }

    .se-sidenav-container.hide .se-side-navbar-hamburger{
        right:0;
        left: auto;
        top:20px;
    }
`;

export default StyledReportComponent;
