import styled from 'styled-components';

const StyledReportComponent = styled.div`
width: 100%;
height: 100%;
   
.se-reports {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0 20px;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
.se-report-sections{
    width: 100%;
    height: calc(100% - 120px);
    display: flex;
    align-items: center;
}

@media (max-width: 320px) {

}

@media (max-width: 991px) {

}
`;

export default StyledReportComponent;
