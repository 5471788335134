import React, { useState } from 'react';
import { IProps } from 'components/common/Error/error.d';
import StyledContainer from 'components/common/Error/error.style';
import ErrorIcon from 'components/common/svg/Error/error.svg';
import RefreshIcon from 'components/common/svg/Refresh/refresh.svg';
import PrevIcon from 'components/common/svg/Prev/prev.svg';
// import MailIcon from 'components/common/svg/Mail/mail.svg';

const Component = (props: IProps) => {
  const {
    message, retry, back, headerMessage,
    // tenant, issue,
  } = props;
  return (
    <div
      className="se-error-showcase"
    >
      <div
        className="se-error-icon"
      >
        <ErrorIcon
          width={60}
          height={60}
        />
      </div>
      <div
        className="se-error-header"
      >
        {headerMessage || 'Sorry, Something Went Wrong'}
      </div>
      <div
        className="se-error-message"
      >
        {headerMessage ? 'Either this report does not exist or you do not have permission to access this report. Click on reset or choose another report view from report view section.' : `${message} Please try again or report an issue to support.`}
      </div>
      <div
        className="se-error-actions"
      >
        {
          back && (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                back();
              }}
            >
              <span
                className="se-icon"
              >
                <PrevIcon
                  width={20}
                  height={20}
                  fill="var(--paginationColor)"
                />
              </span>
              <span>
                Return
              </span>
            </button>
          )
        }
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            retry();
          }}
        >
          <span
            className="se-icon"
          >
            <RefreshIcon
              width={16}
              height={16}
              fill="var(--paginationColor)"
            />
          </span>
          <span>
            {headerMessage ? 'Reset' : 'Try Again'}
          </span>
        </button>
      </div>
    </div>
  );
};

const Error = (props: IProps) => {
  const { isDialog, isDroppable } = props;
  const [show, setShow] = useState<boolean>(true);

  if (isDialog) {
    return show
      ? (
        <StyledContainer>
          <button
            type="button"
            className={`se-error-dialog ${isDroppable ? '' : 'non-droppable'}`}
            onClick={() => {
              if (isDroppable) {
                setShow(false);
              }
            }}
          >
            <Component
              {...props}
            />
          </button>
        </StyledContainer>
      )
      : null;
  }

  return (
    <StyledContainer>
      <div
        className="se-error-non-dialog"
      >
        <Component
          {...props}
        />
      </div>
    </StyledContainer>
  );
};

export default Error;
