import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const CallIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 20 20"
  >
    <path
      data-name="Path 880"
      d="M0 0h20v20H0z"
      style={{
        fill: 'none',
      }}
    />
    <path
      data-name="Path 881"
      d="M6 4.694a10.431 10.431 0 0 0 .38 2.195L5.364 7.905a12.56 12.56 0 0 1-.644-3.211H6m8.354 10.184a10.8 10.8 0 0 0 2.2.381v1.262a13.07 13.07 0 0 1-3.219-.635l1.017-1.008M6.812 3H3.847A.85.85 0 0 0 3 3.847a14.4 14.4 0 0 0 14.4 14.4.85.85 0 0 0 .847-.847v-2.954a.85.85 0 0 0-.847-.846 9.664 9.664 0 0 1-3.025-.483.711.711 0 0 0-.263-.042.868.868 0 0 0-.6.246l-1.862 1.862A12.834 12.834 0 0 1 6.067 9.6l1.864-1.864a.85.85 0 0 0 .212-.864 9.625 9.625 0 0 1-.483-3.025A.85.85 0 0 0 6.812 3z"
      transform="translate(-.625 -.625)"
      fill={fill}
    />
  </svg>
);

CallIcon.defaultProps = {
  fill: '#bd3841',
};

export default CallIcon;
