import React, { useEffect, useState } from 'react';
import {
  dimensionItemBottomMarginPx,
  dimensionItemHeightPx,
  DroppableIds, DroppableTypes,
} from 'components/feature/Report/ReportSidebar/common/constants';
import { ReduxModel } from 'core/models';
import { Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import DraggableItem from 'components/feature/Report/ReportSidebar/common/DraggableItem/draggable-item.component';
import { DimensionItemWarning, DimensionMode, DimensionStatus } from 'core/constants/report';
import * as Actions from 'redux/report/actions';
import { IDimension } from 'core/models/report-redux';
import StyledContainer from 'components/feature/Report/ReportSidebar/Settings/Dimensions/RowGrouping/row-grouping.style';
import { checkAndGetGroupingLimits, displayName } from 'components/feature/Report/ReportSidebar/common/helpers';
import { IRowGroupingProps } from 'components/feature/Report/ReportSidebar/Settings/Dimensions/RowGrouping/row-grouping.d';
import Placeholder from 'components/feature/Report/ReportSidebar/common/Placeholder/placeholder.component';
import { DraggableItemState } from 'components/feature/Report/ReportSidebar/common/DraggableItem/draggable-item.d';
import { getFieldIdentifier, getTextTooltipContentForDimension } from 'core/utils/report.util';

// TODO: Enforce import ordering (Files, Icons, Components etc.)

const RowGrouping = (props: IRowGroupingProps) => {
  const {
    selectedDimension, dimensionState, placeholderProps,
    isMaxRowGroupingReached, replaceIndex, newAdditionIndex,
  } = props;
  const [
    appliedDimensions, reportConfig,
  ] = useSelector((state: ReduxModel.IGlobalState) => [
    state.report.appliedDimensions, state.report.reportConfig, state.report.filterConfig,
  ] as const);
  const dispatch = useDispatch();
  const [minHeight, setMinHeight] = useState(dimensionItemHeightPx + dimensionItemBottomMarginPx);

  // Row Grouping only shows grouped dimensions. Filter them out but store their original indexes.
  // Original indexes are needed for setRowGrouping and reorderGrouping.
  const groupedDimensions = appliedDimensions
    .map((dim, index) => ({ ...dim, originalIndex: index } as iDimensionWithIndex))
    .filter((dim) => dim.DimensionMode !== DimensionMode.ColumnGroup && dim.Applied !== DimensionStatus.NotApplied);

  const {
    isMinRowGroupingReached,
    minRowGroupingLimit,
  } = checkAndGetGroupingLimits(groupedDimensions, null, reportConfig.Visualization.Type);
  const showPlaceholder = !isMaxRowGroupingReached;

  // set a minimum height to account for the placeholder moving up and down,
  // which is confusing the ColumnGrouping droppable's drop target position
  useEffect(() => {
    // set minimum height based on number of items, their margins, and the placeholder
    let newMinHeight = groupedDimensions.length * (dimensionItemHeightPx + dimensionItemBottomMarginPx);
    if (showPlaceholder) newMinHeight += dimensionItemHeightPx; // last item does not have margin
    else if (newMinHeight >= dimensionItemBottomMarginPx) newMinHeight -= dimensionItemBottomMarginPx;
    setMinHeight(newMinHeight);
  }, [groupedDimensions.length, isMaxRowGroupingReached]);

  // dispatchers
  const removeRowGrouping = (index: number) => dispatch(Actions.setGrouping(index, DimensionStatus.NotApplied, DimensionMode.RowGroup));

  return (
    <Droppable
      droppableId={DroppableIds.RowGrouping}
      type={DroppableTypes.Dimensions}
      isCombineEnabled
    >
      {(droppableProvided) => (
        <StyledContainer
          minHeightPx={minHeight}
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
        >
          {
              groupedDimensions
                .map((dim, index) => {
                  const showMinGroupingWarning = isMinRowGroupingReached && dim.Applied === DimensionStatus.Applied;
                  const closeButtonDisabled = dim.Applied === DimensionStatus.Mandatory || showMinGroupingWarning;

                  const name = displayName(dim);
                  const key = getFieldIdentifier(dim);
                  return (
                    <DraggableItem
                      key={key}
                      draggableId={key}
                      text={name}
                      data={dim.originalIndex}
                      state={key === selectedDimension ? dimensionState : DraggableItemState.Default}
                      index={index}
                      showCloseButton
                      isCloseButtonDisabled={closeButtonDisabled}
                      // TODO: separate all html components to separate variables. Improves readability
                      disabledCloseButtonOverlay={showMinGroupingWarning && (
                        <span>
                          {
                          DimensionItemWarning.MinGrouping.replace(
                            '{{count}}',
                            minRowGroupingLimit.toString(),
                          )
                        }
                        </span>
                      )}
                      infoOverlayClassName="se-dimension-item-info"
                      onClickClose={removeRowGrouping}
                      textTooltipContent={getTextTooltipContentForDimension(dim)}
                      borderClassName={replaceIndex === dim.originalIndex ? 'se-replace-blue-border' : ''}
                      entity={dim.BuilderConfig && dim.BuilderConfig.Entity}
                      addBorderClass={newAdditionIndex === dim.originalIndex ? 'se-add-green-border' : ''}
                      binningType={dim.DimensionProp ? dim.DimensionProp?.Props?.BinningType : ''}
                    />
                  );
                })
            }
          {droppableProvided.placeholder}
          {
            showPlaceholder
            && (
              <Placeholder {...placeholderProps} />
            )
          }
        </StyledContainer>
      )}
    </Droppable>
  );
};

// A temporary interface to store original indexes of dimensions
interface iDimensionWithIndex extends IDimension {
  originalIndex: number;
}

export default RowGrouping;
