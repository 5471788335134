import React, { useEffect, useState } from 'react';
import Select, { components, InputActionMeta } from 'react-select';
import { FilterType, SelectBulkAction, UserFilterPayloadType } from 'core/constants/filter';
import { FilterModel } from 'core/models';
import { IProps } from 'components/common/select/ReactSelect/react-select.d';
import CheckboxIcon from 'components/common/svg/Checkbox/checkbox.svg';
import ArrowIcon from 'components/common/svg/ArrowDown/arrow-down.svg';
import TickIcon from 'components/common/svg/Tick/tick.svg';
// import ClearIcon from 'components/common/svg/Clear/clear.svg';
import Spinner from 'components/common/loader/Spinner/spinner.component';
import { isUserFilterValueChanged } from 'core/utils/filter-builder.util';
import { isEqual, throttle } from 'lodash';
import StyleComponent from 'components/common/select/ReactSelect/react-select.style';
import Tooltip from 'rc-tooltip';

const ControlComponent = (props: any) => {
  const {
    children, isMulti, isFocused, hasValue, getValue, options, setValue,
    selectProps,
  } = props;
  const { config, userValue } = selectProps;
  let element = null;
  if (isMulti && isFocused) {
    let isAllSelected = false;
    if (config.Type === FilterType.UserMultiSelect) {
      isAllSelected = getSelectedValueCountForUserFilter(
        config,
        userValue,
      ) === config.Meta.OptionsMeta.Count;
    } else {
      isAllSelected = hasValue && getValue().length === options.length;
    }
    element = (
      <button
        type="button"
        className="rs-selectall-btn"
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (config.Type === FilterType.UserMultiSelect) {
            setValue(
              isAllSelected ? [] : options,
              isAllSelected
                ? SelectBulkAction.RemoveAll
                : SelectBulkAction.SelectAll,
            );
          } else {
            setValue(
              isAllSelected ? [] : options,
            );
          }
        }}
      >
        {
          isAllSelected
            ? (
              <CheckboxIcon
                width={16}
                height={16}
              />
            )
            : (
              <CheckboxIcon
                width={16}
                height={16}
                fill1="var(--noDataBackground)"
              />
            )
        }
      </button>
    );
  }
  return (
    <components.Control {...props}>
      {element}
      {children}
    </components.Control>
  );
};

const ValueContainerComponent = (props: any) => {
  const {
    children, isMulti, hasValue, getValue, options, selectProps,
  } = props;
  const {
    config, userValue, inputValue,
  } = selectProps;
  let element = null;

  if (!inputValue) {
    if (isMulti) {
      if (config.Type === FilterType.UserMultiSelect) {
        const count = userValue
          ? getSelectedValueCountForUserFilter(
            config,
            userValue,
          )
          : 0;
        if (count) {
          element = (
            <div
              className="se-react-select-multi-value-cn"
            >
              {
                `${count === config?.Meta?.OptionsMeta?.Count ? 'All' : count} Selected`
              }
            </div>
          );
        }
      } else if (hasValue) {
        const count = getValue().length;
        element = (
          <div
            className="se-react-select-multi-value-cn"
          >
            {
            `${count === options.length ? 'All' : count} Selected`
          }
          </div>
        );
      }
    }
  }
  return (
    <components.ValueContainer {...props}>
      {element}
      {children}
    </components.ValueContainer>
  );
};

const PlaceholderComponent = (props: any) => {
  const { children, selectProps } = props;
  const { config, userValue } = selectProps;
  let hasUserFilterValue = false;
  if (config.Type === FilterType.UserMultiSelect
    && getSelectedValueCountForUserFilter(config, userValue)) {
    hasUserFilterValue = true;
  }
  return (
    <components.Placeholder {...props}>
      {
        selectProps.menuIsOpen || hasUserFilterValue ? null : children
      }
    </components.Placeholder>
  );
};

const OptionComponent = (props: any) => {
  const {
    isMulti, children, isSelected, selectProps,
  } = props;
  return (
    <components.Option {...props}>
      {
        isMulti && selectProps.isCheckbox && (
        <span
          className="se-react-select-option-checkbox"
        >
          {
            isSelected
              ? (
                <CheckboxIcon
                  width={16}
                  height={16}
                  fill2="var(--collapsibleColor)"
                  fill1="var(--noDataBackground)"
                  stroke="var(--collapsibleColor)"
                />
              )
              : (
                <CheckboxIcon
                  width={16}
                  height={16}
                  fill1="var(--noDataBackground)"
                />
              )
            }
        </span>
        )
      }
      <Tooltip
        overlay={children || '(empty)'}
        placement="bottom"
        mouseEnterDelay={1.5}
      >
        <span
          className="se-react-select-option-label"
        >
          {children || '(empty)'}
        </span>
      </Tooltip>
      {
        !isMulti && isSelected && (
          <span
            className="se-react-select-tick-icon"
          >
            <TickIcon
              width={11}
              height={11}
              fill="var(--collapsibleColor)"
            />
          </span>
        )
      }
    </components.Option>
  );
};

const DropdownIndicatorComponent = (props: any) => {
  const { selectProps } = props;
  return (
    <components.DropdownIndicator {...props}>
      {
        selectProps.dropdownIndicator && (
          <>
            {
              selectProps.menuIsOpen
                ? (
                  <span
                    className="se-react-select-dropdown-indicator-rotate"
                  >
                    <ArrowIcon
                      width={20}
                      height={20}
                    />
                  </span>
                )
                : (
                  <ArrowIcon
                    width={21}
                    height={21}
                    fill="var(--arrowIcon)"
                  />
                )
            }
          </>
        )
      }
    </components.DropdownIndicator>
  );
};

// const ClearIndicatorComponent = (props: any) => {
//   const { hasValue } = props;
//   return (
//     <components.ClearIndicator {...props}>
//       {
//         hasValue && (
//         <ClearIcon
//           width={15}
//           height={15}
//         />
//         )
//       }
//     </components.ClearIndicator>
//   );
// };

const MenuListComponent = (props: any) => {
  const { children, selectProps } = props;
  const { config, userValue } = selectProps;
  const menuListRef = React.useRef(null);
  let spinner = null;

  useEffect(() => {
    if (menuListRef.current) {
      menuListRef.current.querySelector('div').onscroll = handleLazyLoad;
    }
  }, [menuListRef, config]);

  const handleLazyLoad = (e: any) => {
    const { scrollHeight, offsetHeight, scrollTop } = e.target;
    if (config && config.Type === FilterType.UserMultiSelect) {
      if ((scrollHeight - (scrollTop + offsetHeight)) < 10) {
        if (!config.Loading && !config.LazyLoading && config.Meta?.OptionsMeta?.HasNextPage) {
          config.Meta.OptionsMeta.loadMoreDropdownOptions(config.Key, userValue);
        }
      }
    }
  };

  if (config.Type === FilterType.UserMultiSelect) {
    spinner = config.LazyLoading && (
      <div
        className="se-spinner-cn"
      >
        <Spinner />
        <span>
          Loading Options
        </span>
      </div>
    );
  }
  return (
    <div ref={menuListRef}>
      <components.MenuList {...props}>
        {children}
        {
          spinner && (
            <div
              className="se-lazy-loading-cn"
            >
              {
                spinner
              }
            </div>
          )
        }
      </components.MenuList>
    </div>
  );
};

const NoOptionsMessageComponent = (props: any) => {
  const { children, selectProps } = props;
  const { config } = selectProps;
  return !config.LazyLoading
    ? (
      <components.NoOptionsMessage {...props}>
        {children}
      </components.NoOptionsMessage>
    )
    : null;
};

const ReactSelect = (props: IProps) => {
  const {
    config, value, onChange, removeBorderClass, addBorderClass, pendoClassName,
  } = props;
  const {
    IsMulti = false, Key, Meta = {}, Loading = false,
  } = config;
  const {
    Options, OptionsMeta, Placeholder = 'Select', Checkbox = IsMulti, DropdownIndicator = true,
    CloseOnSelect = false, Searchable = true, MenuPlacement = 'auto', ClassName = '', AppliedOnClose = true,
    Components = {},
    // Clearable = true,
  } = Meta as FilterModel.ISelectMeta;
  const ref = React.useRef(null);

  const [searchValue, setSearchValue] = useState<string>('');
  const [activeTimeout, setActiveTimeout] = useState<ReturnType<typeof setTimeout>>(null);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [userValue, setUserValue] = useState<FilterModel.IUserFilterPayload>(value);
  const [prevUserValue, setPrevUserValue] = useState(value);
  const [menuPlacement, setMenuPlacement] = useState(MenuPlacement === 'auto' ? 'bottom' : MenuPlacement);
  const [scrolled, setScrolled] = useState(null);

  useEffect(() => {
    if (ref && ref.current && MenuPlacement === 'auto') {
      const target = ref.current.getBoundingClientRect();
      const screenHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      const scroll = ref.current.parentElement.parentElement;
      if (screenHeight - target.bottom < 260) {
        scroll.addEventListener('scroll', throttle(() => {
          setScrolled(scroll.scrollTop);
        }, 800));
        if (((screenHeight - target.bottom > 180 && Options.length < 4)
        || (screenHeight - target.bottom > 100 && Options.length < 2))) {
          if (menuPlacement !== 'bottom') {
            setMenuPlacement('bottom');
          }
          return;
        }
        if (menuPlacement !== 'top') {
          setMenuPlacement('top');
        }
        return;
      }
      if (menuPlacement !== 'bottom') {
        setMenuPlacement('bottom');
      }
    }
  },
  [
    Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
    ref && ref.current && ref.current.getBoundingClientRect(),
    Options,
    scrolled,
  ]);

  useEffect(() => {
    let selectedItem = null;
    if (value) {
      if (IsMulti) {
        if (config.Type === FilterType.UserMultiSelect && !isEqual(prevUserValue, value)) {
          switch (value.Type) {
            case UserFilterPayloadType.All:
              selectedItem = Options;
              break;
            case UserFilterPayloadType.In: {
              selectedItem = Options.filter((option) => value.UserIds.includes(option.value));
              break;
            }
            case UserFilterPayloadType.NotIn: {
              selectedItem = Options.filter((option) => !value.UserIds.includes(option.value));
              break;
            }
            default:
              break;
          }
          setUserValue(value);
          setSelectedOption(selectedItem);
          setPrevUserValue(value);
        } else if (value.length) {
          selectedItem = Options.filter((option) => value.includes(option.value));
          setSelectedOption(selectedItem);
        }
      } else {
        selectedItem = Options.find((option) => option.value === value);
        setSelectedOption(selectedItem);
      }
    }
  }, [value]);

  useEffect(() => {
    let selectedItem = null;
    if (IsMulti) {
      if (config.Type === FilterType.UserMultiSelect) {
        if (userValue) {
          switch (userValue.Type) {
            case UserFilterPayloadType.All:
              selectedItem = Options;
              break;
            case UserFilterPayloadType.In: {
              selectedItem = Options.filter((option) => userValue.UserIds.includes(option.value));
              break;
            }
            case UserFilterPayloadType.NotIn: {
              selectedItem = Options.filter((option) => !userValue.UserIds.includes(option.value));
              break;
            }
            default:
              break;
          }
        }
        setSelectedOption(selectedItem);
      }
    }
  }, [Options]);

  const onMenuClose = () => {
    if (!AppliedOnClose) {
      return;
    }
    if (IsMulti) {
      if (selectedOption) {
        if (config.Type === FilterType.UserMultiSelect) {
          const isValueChanged = isUserFilterValueChanged(
            userValue,
            value,
            OptionsMeta.Count,
          );
          if (isValueChanged) {
            onChange(Key, userValue);
          }
          // request to refersh list.
          config.Meta.OptionsMeta.searchDropdownOptions(Key, '', userValue);
          return;
        }

        const existingValues = value as Array<any>;
        const currentStateValues = selectedOption.map((item: FilterModel.ISelectFilterOptions) => item.value);
        if (existingValues && currentStateValues) {
          const maxArray:Array<any> = existingValues.length >= currentStateValues.length ? existingValues : currentStateValues;
          const minArray:Array<any> = existingValues.length >= currentStateValues.length ? currentStateValues : existingValues;
          const missing = maxArray.filter((item) => minArray.indexOf(item) < 0);
          if (missing && missing.length) {
            onChange(
              Key,
              currentStateValues,
            );
          }
        }
      }
    } else {
      // For single select dropdown
      const existingValues = value;
      if (existingValues && selectedOption && selectedOption.value && existingValues !== selectedOption.value) {
        onChange(
          Key,
          selectedOption.value,
        );
      }
    }
  };

  const filterOption = (option: FilterModel.ISelectFilterOptions) => {
    // To avoid filtering on list based on search while deboucing period.
    if (config.Type === FilterType.UserMultiSelect) {
      return true;
    }
    return (
      searchValue
        ? option.label.toString().toLowerCase().includes(searchValue.toLowerCase())
        : true
    );
  };

  const onInputChange = (inputValue: string, inputActionMeta: InputActionMeta) => {
    const { action } = inputActionMeta;
    switch (action) {
      case 'input-change': {
        setSearchValue(inputValue);
        if (config.Type === FilterType.UserMultiSelect) {
          // avoid search request if length of non empty input value is smaller than 3.
          if (inputValue && inputValue.length < 3) {
            return inputValue;
          }
          if (activeTimeout) {
            clearTimeout(activeTimeout);
          }
          setActiveTimeout(
            setTimeout(() => {
              config.Meta.OptionsMeta.searchDropdownOptions(Key, inputValue, userValue);
              setActiveTimeout(null);
            }, 500),
          );
        }
        return inputValue;
      }
      case 'menu-close': {
        setSearchValue('');
        return inputValue;
      }
      default:
        return searchValue;
    }
  };

  const onReactSelectChange = (option: any, e: any) => {
    setSelectedOption(option);
    let newUserValue = null;
    if (config.Type === FilterType.UserMultiSelect) {
      switch (e.action) {
        case SelectBulkAction.SelectAll:
          newUserValue = {
            Type: UserFilterPayloadType.All,
            UserIds: [],
          };
          break;
        case SelectBulkAction.RemoveAll:
          newUserValue = {
            Type: UserFilterPayloadType.In,
            UserIds: [],
          };
          break;
        default:
          newUserValue = getNewUserValue(
            option,
            userValue,
            Options,
            OptionsMeta?.Count,
          );
          break;
      }
      setUserValue(newUserValue);
    }

    if (AppliedOnClose) {
      return;
    }

    if (config.Type === FilterType.UserMultiSelect) {
      onChange(Key, newUserValue);
      return;
    }
    onChange(
      Key,
      IsMulti
        ? option.map((item: FilterModel.ISelectFilterOptions) => item.value)
        : option.value,
    );
  };

  return (
    <StyleComponent ref={ref}>
      <Select
        isMulti={IsMulti}
        isDisabled={config.Disabled}
        options={Options}
        value={selectedOption}
        isClearable={false}
        isSearchable={Searchable}
        placeholder={Placeholder}
        isCheckbox={Checkbox}
        dropdownIndicator={DropdownIndicator}
        closeMenuOnSelect={CloseOnSelect}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        isLoading={Loading}
        className={`se-react-select-cn ${ClassName} ${removeBorderClass} ${addBorderClass} ${pendoClassName}`}
        classNamePrefix="se-react-select"
        components={{
          Control: ControlComponent,
          ValueContainer: Components.ValueContainer || ValueContainerComponent,
          Placeholder: Components.Placeholder || PlaceholderComponent,
          Option: Components.Option || OptionComponent,
          DropdownIndicator: Components.DropdownIndicator || DropdownIndicatorComponent,
          // ClearIndicator: Components.ClearIndicator || ClearIndicatorComponent,
          MenuList: MenuListComponent,
          NoOptionsMessage: NoOptionsMessageComponent,
        }}
        filterOption={filterOption}
        onInputChange={onInputChange}
        onMenuClose={onMenuClose}
        menuPlacement={menuPlacement}
        onChange={onReactSelectChange}
        config={config}
        userValue={userValue}
        setUserValue={setUserValue}
        setSelectedOption={setSelectedOption}
        menuPosition="fixed"
      />
    </StyleComponent>
  );
};

export default ReactSelect;

// Function will be called whenever value changed, create userValue for selected values
// param selectedOptions: value returned by React Select's onChange handler.
const getNewUserValue = (
  selectedOptions: Array<FilterModel.ISelectFilterOptions>, userValue: FilterModel.IUserFilterPayload,
  Options: Array<FilterModel.ISelectFilterOptions>, Count: number,
) => {
  const newUserValue = {} as FilterModel.IUserFilterPayload;
  const selectedValuesInSubset = selectedOptions.map((option) => option.value);
  if (Count === Options.length) {
    // Block handle the case when all options are loaded.
    // If all options selected, set the type to "ALL".If unselected values count is lesser
    // than selected values, set type to "NOTIN" and UserIds to unselected values otherwise
    // set type to "IN" and UserIds to selected values.
    if (selectedValuesInSubset.length === Options.length) {
      newUserValue.Type = UserFilterPayloadType.All;
      newUserValue.UserIds = [];
    } else if ((Options.length - selectedValuesInSubset.length) < selectedValuesInSubset.length) {
      newUserValue.Type = UserFilterPayloadType.NotIn;
      newUserValue.UserIds = Options
        .filter((option) => !selectedValuesInSubset.includes(option.value))
        .map((option) => option.value);
    } else {
      newUserValue.Type = UserFilterPayloadType.In;
      newUserValue.UserIds = selectedValuesInSubset;
    }
  } else if (userValue) {
    const unselectedValuesInSubset = Options
      .filter((option) => !selectedValuesInSubset.includes(option.value))
      .map((option) => option.value);
    switch (userValue.Type) {
      case UserFilterPayloadType.All:
        // For this case all unselected values are present in loaded options list but all selected
        // values may not be neccessarily present therefore not possible to extract all selected
        // values hence value type is set to "NOTIN".
        newUserValue.Type = UserFilterPayloadType.NotIn;
        newUserValue.UserIds = Options
          .filter((option) => !selectedValuesInSubset.includes(option.value))
          .map((option) => option.value);
        break;
      case UserFilterPayloadType.In: {
        // Not all unselected values present in options list hence value type is not changed only UserIds
        // list is updated, new selected values are added if not present in UserIds list and unselected
        // items are removed from UserIds list.
        let newSelectedValues = userValue.UserIds.filter((item) => (
          !unselectedValuesInSubset.includes(item)));
        newSelectedValues = newSelectedValues.concat(
          selectedValuesInSubset.filter((item: number) => !newSelectedValues.includes(item)),
        );
        newUserValue.Type = UserFilterPayloadType.In;
        newUserValue.UserIds = newSelectedValues;
        break;
      }
      case UserFilterPayloadType.NotIn: {
        let newUnselectedValues = userValue.UserIds.filter((item: number) => (
          !selectedValuesInSubset.includes(item)));
        newUnselectedValues = newUnselectedValues.concat(
          unselectedValuesInSubset.filter((item: number) => !newUnselectedValues.includes(item)),
        );
        if (newUnselectedValues.length) {
          newUserValue.Type = UserFilterPayloadType.NotIn;
          newUserValue.UserIds = newUnselectedValues;
        } else {
          newUserValue.Type = UserFilterPayloadType.All;
          newUserValue.UserIds = [];
        }
        break;
      }
      default:
        break;
    }
  } else {
    newUserValue.Type = UserFilterPayloadType.In;
    newUserValue.UserIds = selectedValuesInSubset;
  }
  return newUserValue;
};

// Function called whenever user select, unselect values in loaded options(subset)
// for filter type "UserMultiSelect".
// param config: config of the filter.
// param userValue: user filter value.
// returns selected value count in entire option list.
const getSelectedValueCountForUserFilter = (
  config: FilterModel.IConfig, userValue: FilterModel.IUserFilterPayload,
) => {
  let count = 0;
  if (config?.Meta?.OptionsMeta?.Count) {
    switch (userValue.Type) {
      case UserFilterPayloadType.All:
        count = config.Meta.OptionsMeta.Count;
        break;
      case UserFilterPayloadType.In: {
        count = userValue.UserIds.length;
        break;
      }
      case UserFilterPayloadType.NotIn: {
        count = config.Meta.OptionsMeta.Count - userValue.UserIds.length;
        break;
      }
      default:
        break;
    }
  }
  return count;
};
