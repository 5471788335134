import React from 'react';
import { ReportIcon } from 'core/constants/report';
import CallIcon from 'components/common/svg/Call/call.svg';
import StarsIcon from 'components/common/svg/Stars/stars.svg';
import BulbIcon from 'components/common/svg/Bulb/bulb.svg';
import SheetIcon from 'components/common/svg/Sheet/sheet.svg';
import RevenueIcon from 'components/common/svg/Revenue/revenue.svg';
import CustomIcon from 'components/common/svg/Custom/custom.svg';
import PersonIcon from 'components/common/svg/Person/person.svg';
import { IProps } from 'components/feature/ReportListing/CategoryIcons/category-icons.d';

const CategoryIcons = (props: IProps) => {
  const {
    name, width, height, color,
  } = props;
  return (
    <>
      {
        getCategoryIcons(name, width, height, color)
      }
    </>
  );
};

export default CategoryIcons;

const getCategoryIcons = (
  name: string, width: number, height: number, color: string,
) => {
  switch (name) {
    case ReportIcon.Phone:
      return (
        <CallIcon
          width={width}
          height={height}
          fill={color}
        />
      );

    case ReportIcon.Star:
      return (
        <StarsIcon
          width={width}
          height={height}
          fill={color}
        />
      );

    case ReportIcon.Bulb:
      return (
        <BulbIcon
          width={width}
          height={height}
          fill={color}
        />
      );

    case ReportIcon.Sheet:
      return (
        <SheetIcon
          width={width}
          height={height}
          fill={color}
        />

      );
    case ReportIcon.Person:
      return (
        <PersonIcon
          width={width}
          height={height}
          fill={color}
        />

      );
    case ReportIcon.Revenue:
      return (
        <RevenueIcon
          width={width}
          height={height}
          fill={color}
        />

      );
    case ReportIcon.Custom:
      return (
        <CustomIcon
          width={width}
          height={height}
          fill={color}
        />

      );

    default:
      return (
        <SheetIcon
          width={width}
          height={height}
          fill={color}
        />
      );
  }
};
