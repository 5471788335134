import styled from 'styled-components';

const StyledContainer = styled.div`

.se-fields-btn {
    border: none;
    font: 11px SemiBold;
    color: var(--expandListButtonColor);
    display: flex;

    .se-fields-icon{
        height: 100%;
        margin-right: 4px;
        align-items: center;
        text-align: center;
    } 
    .se-fields-label.expand {
        color: var(--expandListButtonHoverColor);
    }
    :hover {
        color: var(--expandListButtonHoverColor);
        svg {
            .icon-stroke {
            fill: var(--expandListButtonHoverColor);
            }
        }
    }
}`;
export default StyledContainer;
