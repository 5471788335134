import { Dispatch } from 'redux';
import { ReportService } from 'services/report.service';
import { NavSectionSubTypes } from 'core/constants/side-navbar';
import { ReportListActionTypes } from 'core/constants/redux-action-types';
import { ReportListReduxModel, ReportListResponseModel } from 'core/models';
import * as Actions from 'redux/report-list/actions';

export default (store: any) => (next: any) => (action: ReportListReduxModel.IAction) => {
  const { dispatch, getState } = store;

  switch (action.type) {
    case ReportListActionTypes.REPORT_CATEGORIES_LOAD_REQUEST: {
      next(action);
      handleReportCategoriesLoadRequest(dispatch);
      break;
    }

    case ReportListActionTypes.PINNED_REPORTS_LOAD_REQUEST: {
      next(action);
      handlePinnedReportsLoadRequest(dispatch);
      break;
    }

    case ReportListActionTypes.CATEGORY_REPORTS_LOAD_REQUEST: {
      next(action);
      const { reportList } = getState();
      handleCategoryReportsLoadRequest(
        dispatch,
        reportList.selectedReportType.id,
      );
      break;
    }

    case ReportListActionTypes.REPORT_PIN_UNPIN_REQUEST: {
      next(action);
      const { reportList } = getState();
      const {
        reportId, isPinned,
      } = action.payload as ReportListReduxModel.IReportPinUnpinRequestPayload;
      handleReportPinUnpinRequest(
        dispatch,
        reportList.selectedReportType,
        reportId,
        isPinned,
      );
      break;
    }

    case ReportListActionTypes.REORDER_REPORT_CATEGORIES_REQUEST: {
      next(action);
      const {
        categories,
      } = action.payload as ReportListReduxModel.IReorderReportCategoriesRequestPayload;
      handleReorderReportCategoriesRequest(
        dispatch,
        categories,
      );
      break;
    }

    default:
      next(action);
      break;
  }
};

const handleReportCategoriesLoadRequest = (dispatch: Dispatch) => {
  new ReportService().getReportCategories()
    .then((res) => {
      dispatch(Actions.reportCategoriesLoadSuccess(res));
    }).catch(() => {
      dispatch(Actions.reportCategoriesLoadFailure());
    });
};

const handlePinnedReportsLoadRequest = (dispatch: Dispatch) => {
  new ReportService().getPinnedReports({
    sort: 'A-Z',
  })
    .then((res) => {
      dispatch(Actions.pinnedReportsLoadSuccess(res));
    }).catch(() => {
      dispatch(Actions.pinnedReportsLoadFailure());
    });
};

const handleCategoryReportsLoadRequest = (dispatch: Dispatch, categoryId: string) => {
  new ReportService().getCategoryReports({
    CategoryId: categoryId,
    sort: 'A-Z',
  })
    .then((res) => {
      dispatch(Actions.categoryReportsLoadSuccess(
        categoryId,
        res,
      ));
    }).catch(() => {
      dispatch(Actions.categoryReportsLoadFailure());
    });
};

const handleReportPinUnpinRequest = (
  dispatch: Dispatch, selectedReportType: ReportListReduxModel.ISelectedReportType,
  reportId: string, isPinned: boolean,
) => {
  new ReportService().pinUnpinReport({
    ReportId: reportId,
    IsPinned: isPinned,
  })
    .then((res) => {
      if (res) {
        if (selectedReportType) {
          if (selectedReportType.isReportCategory) {
            dispatch(Actions.categoryReportsLoadRequest());
          } else {
            switch (selectedReportType.id) {
              case NavSectionSubTypes.Pinned: {
                dispatch(Actions.pinnedReportsLoadRequest());
                break;
              }
              default:
                break;
            }
          }
        }
        dispatch(Actions.reportPinUnpinSuccess());
      }
    }).catch(() => {
      dispatch(Actions.reportPinUnpinFailure());
    });
};

const handleReorderReportCategoriesRequest = (
  dispatch: Dispatch, categories: Array<ReportListResponseModel.ICategory>,
) => {
  new ReportService().reorderReportCategories({
    CategoryIds: categories.map((item) => item.CategoryId),
  })
    .then(() => {
      dispatch(Actions.reorderReportCategoriesSuccess(categories));
    }).catch(() => {
      dispatch(Actions.reorderReportCategoriesFailure());
    });
};
