import { AuthResponseModel } from 'core/models';
import * as ActionTypes from 'redux/user-auth/action-types';

export const setAuth = (auth: AuthResponseModel.Auth | null) => ({
  type: ActionTypes.SET_AUTH,
  payload: {
    auth,
  },
});

export const setReportingRights = (isReportingDisabled:boolean) => ({
  type: ActionTypes.SET_REPORTING_RIGHTS,
  payload: isReportingDisabled,
});

export const setMarvinAuth = (isMarvin: boolean) => ({
  type: ActionTypes.SET_MARVIN_AUTH,
  payload: isMarvin,
});

export const setV1ReportContext = (isV1Report: boolean) => ({
  type: ActionTypes.SET_V1_REPORT_CONTEXT,
  payload: isV1Report,
});

export const setStatusErrorCode = (statusCode: number) => ({
  type: ActionTypes.SET_STATUS_ERROR_CODE,
  payload: statusCode,
});
