import React from 'react';
import StyledComponent from 'components/feature/ReportListing/ReportHeader/report-header.style';
import ReportSortingLoader from 'components/feature/ReportListing/ReportHeader/ReportSorting/report-sorting.loader';

const ReportHeaderLoader = () => (
  <StyledComponent>
    <div
      className="se-report-header"
    >
      <div
        className="se-report-header-label se-shimmer-cn"
      >
        <div
          className="se-shimmer"
        >
            &nbsp;
        </div>
      </div>
      <div
        className="se-report-header-content"
      >
        <div>
          <ReportSortingLoader />
        </div>
        <div
          className="searchContainer se-shimmer-cn"
        >
          <div
            className="se-shimmer"
          />
        </div>
        <div
          className="se-new-report-button-container"
        >
          <div
            className="se-new-report-button se-shimmer-cn"
          >
            <div
              className="se-shimmer"
            />
          </div>
        </div>
      </div>
    </div>
  </StyledComponent>
);

export default ReportHeaderLoader;
