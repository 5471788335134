import axios, { AxiosInstance, AxiosResponse } from 'axios';
import store from 'redux/store';
import { setAuth } from 'redux/user-auth/actions';
import lsqMarvinExternalAppIntegrator from '@lsq-marvin-app-utility/external-app-integrator';
import { IContext } from '@lsq-marvin-app-utility/external-app-integrator/build/esm/external-app-integrator/interface';

export default class API {
  static redirectUrl = process.env.REACT_APP_AUTH_REDIRECT_URL || '';

  instance: AxiosInstance;

  constructor(
    baseURL: string,
    authorizedReq: boolean = true,
    interceptResponse: boolean = true,
  ) {
    this.instance = axios.create({
      baseURL,
    });
    this.onSignOutMarvin();
    const { token, isMarvin } = store.getState().auth;
    if (authorizedReq) {
      if (isMarvin) {
        this.marvinInterceptorRequest();
      } else if ((token && !this.isTokenExpired())) {
        this.interceptRequest();
      } else {
        store.dispatch(setAuth(null));
      }
    }
    if (interceptResponse) {
      this.interceptResponse();
    }
  }

  onSignOutMarvin() {
    lsqMarvinExternalAppIntegrator.eventSubscriber.onSignOut({
      callBackFn: () => {
        store.dispatch(setAuth(null));
      },
    });
  }

  getMarvinToken(reIssueToken: boolean) {
    return new Promise((resolve) => {
      let marvinToken = '';
      if (!reIssueToken) {
        lsqMarvinExternalAppIntegrator.context.get({
          callBackFn: (data: IContext) => {
            marvinToken = data.authenticationDetails.token;
            resolve(marvinToken);
          },
        });
      } else {
        lsqMarvinExternalAppIntegrator.actionDispatcher.reIssueTokens({
          callBackFn: (data) => {
            marvinToken = data.Tokens.Token;
            resolve(marvinToken);
          },
        });
      }
    });
  }

  marvinInterceptorRequest(reIssueToken = false) {
    const { token } = store.getState().auth;
    this.instance.interceptors.request.use(
      async (requestConfig: any) => {
        // Get marvin token - reIssueToken will be passed true if at all the marvin token gets expired
        const marvinToken = await this.getMarvinToken(reIssueToken);
        const config = { ...requestConfig };
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.MARVINTOKEN = marvinToken;
        config.headers.SOURCE = 'MARVIN';
        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  interceptRequest() {
    const { token } = store.getState().auth;
    this.instance.interceptors.request.use(
      (requestConfig) => {
        const config = { ...requestConfig };
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.SOURCE = 'LSQ';
        return config;
      },
    );
  }

  private interceptResponse() {
    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error && error.response && error.response.status === 401) {
          store.dispatch(setAuth(null));
        }
        return Promise.reject(error);
      },
    );
  }

  responsify(response: AxiosResponse) {
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  }

  responsifyGeneric<T>(response: AxiosResponse): T {
    if (response && response.status === 200 && response.data) {
      return response.data as T;
    }
    return null;
  }

  logoutApi = async () => {
    const { token } = store.getState().auth;
    if (token) {
      const inst = axios.create({
        baseURL: process.env.REACT_APP_AUTH_BASE_URL || '',
      });
      await inst.get('user/logout', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  };

  logout(redirectUrl: string | null = null, cb: Function | null = null) {
    let redirectTo = API.redirectUrl;
    if (redirectUrl && redirectUrl.length) {
      redirectTo = redirectUrl;
    }
    store.dispatch(setAuth(null));
    const { isMarvin } = store.getState().auth;
    this.logoutApi()
      .then(() => {
        if (isMarvin) {
          window.top.location.href = document.referrer;
        } else {
          window.open(`${redirectTo}${window.location.href}`, '_self');
        }
      })
      .catch(() => {})
      .finally(() => {
        if (cb && typeof cb === 'function') {
          try {
            cb(true);
          } catch (error) {
            //
          }
        }
      });
  }

  isTokenExpired() {
    const { expiresOn } = store.getState().auth;
    if (expiresOn > new Date().getTime() / 1000) return false;
    store.dispatch(setAuth(null));
    return true;
  }
}
