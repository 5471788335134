import React, { useEffect, useState } from 'react';
import 'react-dates/initialize';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import StyledContainer from 'components/common/DatePicker/date-picker.styled';
import moment from 'moment';
import ismobile from 'is-mobile';
import { IDatePickerProps } from 'components/common/DatePicker/date-picker.d';

const DatePicker = (props:IDatePickerProps) => {
  const {
    initialDateRange, autofocus, onChangeAbsoluteRange, toggle,
  } = props;
  const START_DATE: FocusedInputShape = 'startDate'; // to set the by default focus on start date
  const [dateStart, setDateStart] = useState(initialDateRange.startDate);
  const [dateEnd, setDateEnd] = useState(initialDateRange.endDate);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isRelative, setRelative] = useState(false);

  useEffect(() => {
    if (autofocus) {
      setFocusedInput(START_DATE);
    }
  }, []);

  // Below function will add from and to title to calender
  const renderCalenderTitle = () => (
    <div className="se-calender-to-from">
      <div className="se-date-label">From</div>
      <div className="se-date-label">To</div>
    </div>
  );

  // Below function will trigger once any of start or end date is  changed from calender
  const handleDatesChange = (range:any) => {
    setRelative(true);
    setDateStart(range.startDate.startOf('day'));
    // by having start date in or condition we make sure start and end will be same if the user click outside date range component
    setDateEnd(range.endDate || range.startDate.startOf('day'));
    onChangeAbsoluteRange({
      startDate: range.startDate,
      endDate: range.endDate || range.startDate.startOf('day'),
    });
  };

  // this function is used for closing the  calender with relative dates
  const onClose = (range:any) => {
    const endDateCheck = moment(range.endDate)
      .isSameOrAfter(range.startDate);
    if (isRelative && endDateCheck) {
      toggle();
    }
  };

  // this function gets 'day' as param through which we can validate our logic and return true to make day non-selectable, false to selectable
  // to enable all the dates to be selectable we are returning false
  const isOutsideRangeHandler = () => false;

  return (
    <StyledContainer>
      <div className="se-data-range-picker">
        <DateRangePicker
          numberOfMonths={2}
          orientation={ismobile() ? 'vertical' : 'horizontal'} // for mobile view
          verticalHeight={600}
          hideKeyboardShortcutsPanel
          small
          startDate={dateStart}
          onClose={({ startDate, endDate }) => onClose({ startDate, endDate })}
          startDateId="start-date"
          endDate={dateEnd}
          endDateId="end-date"
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          isOutsideRange={isOutsideRangeHandler}
          calendarInfoPosition="top"
          renderCalendarInfo={renderCalenderTitle}
          onFocusChange={(focusInput) => {
            if (focusInput) {
              setFocusedInput(focusInput);
            }
          }}
          minimumNights={0}
        />
      </div>
    </StyledContainer>
  );
};

export default DatePicker;
