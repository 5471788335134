import styled from 'styled-components';

const StyledContainer = styled.div`
 .se-load-information-container{
    width: 86px;
    display: flex;
    height: 100%;
    align-items: center;

    .se-display-none {
      display: none;
    }

    .se-job-info-cn {
    position: absolute;
    background-color: #212B36;
    bottom: 55px;
    color: #FFFFFF;
    padding: 16px;
    border-radius: 4px;
    font: 13px Regular;
    font-weight: 500;
    z-index: 2;

    .se-info-header {
        margin-bottom: 20px;
        .se-close-icon {
            float: right;
            .se-close-btn {
              border: none;
            }
        }
    }

    .se-job-load-details {
        display: flex;
    }
  }
  .se-divider {
  border: 1px solid #ADBAC7;
  border-left: none;
  height: 100%;
}
  }
  .se-load-seperator {
    width: 1px;
    height: 32px;
    background: #ADBAC7;
    margin-right: 16px;
  } 
  .se-load-information-btn-cn {
   display: inherit;
   padding: 6px 12px;
   border: none;
   border-radius: 4px;
   margin-right: 12px;

   :hover, :focus {
    background-color: #F5F5F5;
   }
  .se-load-information-button{
    height: 20px;
    width: 20px;
  }
  .se-load-btn {
    margin-left: 22px;
  }
}
`;

export default StyledContainer;
