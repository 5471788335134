import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const LineChartIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 24 24"
  >
    <g
      id="Line_Chart"
      data-name="Line Chart"
      transform="translate(-94 -540)"
    >
      <rect
        id="Rectangle_6374"
        data-name="Rectangle 6374"
        width="24"
        height="24"
        transform="translate(94 540)"
        fill="#fff"
      />
      <path
        id="_2_ANgel_Logo"
        data-name="2 ANgel Logo"
        d="M2,19.085l6.749-7.121,3.6,3.793L18.729,8.2,20,9.536,12.349,18.6l-3.6-3.793-5.4,5.7Zm1.35-4.267,5.4-5.7,3.6,3.793L20,3.847,18.729,2.51l-6.38,7.557-3.6-3.793L2,13.4Z"
        transform="translate(95.001 540.491)"
        fill={fill}
      />
    </g>
  </svg>
);

LineChartIcon.defaultProps = {
  fill: '#3bb345',
};

export default LineChartIcon;
