import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const ReportViewsPanelIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.3593 7.59689L1.27336 7.79547L1.3593 7.99405C2.20083 9.93862 3.48158 11.5188 5.19648 12.7252C6.92038 13.938 8.8541 14.5455 10.9832 14.5455C13.1123 14.5455 15.046 13.938 16.7699 12.7252C18.4848 11.5188 19.7655 9.93862 20.607 7.99405L20.693 7.79547L20.607 7.59689C19.7655 5.65232 18.4848 4.07217 16.7699 2.8657C15.046 1.65291 13.1123 1.04547 10.9832 1.04547C8.8541 1.04547 6.92038 1.65291 5.19648 2.8657C3.48158 4.07217 2.20083 5.65232 1.3593 7.59689ZM10.9832 11.8371C12.0996 11.8371 13.0641 11.4422 13.8467 10.6594C14.6293 9.87665 15.0242 8.91204 15.0242 7.79547C15.0242 6.6789 14.6293 5.7143 13.8467 4.93153C13.0641 4.14877 12.0996 3.7538 10.9832 3.7538C9.86673 3.7538 8.90226 4.14877 8.11963 4.93153C7.33701 5.7143 6.94215 6.6789 6.94215 7.79547C6.94215 8.91204 7.33701 9.87665 8.11963 10.6594C8.90226 11.4422 9.86673 11.8371 10.9832 11.8371ZM10.9832 9.6288C10.4598 9.6288 10.036 9.45346 9.68081 9.0982C9.32561 8.74293 9.15027 8.31903 9.15027 7.79547C9.15027 7.27192 9.32561 6.84801 9.68081 6.49274C10.036 6.13748 10.4598 5.96214 10.9832 5.96214C11.5066 5.96214 11.9303 6.13748 12.2855 6.49274C12.6407 6.84801 12.8161 7.27192 12.8161 7.79547C12.8161 8.31903 12.6407 8.74293 12.2855 9.0982C11.9303 9.45346 11.5066 9.6288 10.9832 9.6288ZM15.3466 11.0108C14.0181 11.8683 12.5674 12.2955 10.9832 12.2955C9.39895 12.2955 7.94826 11.8683 6.61975 11.0108C5.35612 10.1952 4.37491 9.12569 3.67128 7.79547C4.37491 6.46525 5.35612 5.39574 6.61975 4.58015C7.94826 3.72268 9.39895 3.29547 10.9832 3.29547C12.5674 3.29547 14.0181 3.72268 15.3466 4.58015C16.6105 5.39589 17.5852 6.46541 18.2763 7.79547C17.5852 9.12553 16.6105 10.195 15.3466 11.0108Z"
      fill={fill}
      stroke="white"
    />
  </svg>
);

ReportViewsPanelIcon.defaultProps = {
  fill: '#454F5B',
};

export default ReportViewsPanelIcon;
