import React from 'react';

type Props = {
    width: number;
    height: number;
    fill1?: string;
    fill2?: string;
    stroke?: string;
}

const CheckboxIcon = ({
  width, height, fill1, fill2, stroke,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
      fill: fill1,
    }}
  >
    <g
      id="Group_1353"
      data-name="Group 1353"
      transform="translate(-337 -216)"
    >
      <g
        id="Rectangle_772"
        data-name="Rectangle 772"
        transform="translate(337 216)"
        style={{
          fill: fill2,
          stroke,
        }}
      >
        <rect
          rx="2"
          style={{
            width,
            height,
            stroke: 'none',
          }}
        />
        <rect
          x=".5"
          y=".5"
          rx="1.5"
          style={{
            fill: 'none',
            width: width - 1,
            height: height - 1,
          }}
        />
      </g>
      <g
        id="Group_1351"
        data-name="Group 1351"
        transform="translate(-50.381 -2.705)"
      >
        <g
          id="Group_1352"
          data-name="Group 1352"
        >
          <path
            id="Path_822"
            data-name="Path 822"
            className="cls-2"
            d="M.03.139 9.592 0l-.03 2.079L0 2.217z"
            transform="rotate(135.97 155.256 193.236)"
          />
          <path
            id="Path_823"
            data-name="Path 823"
            className="cls-2"
            d="m0 0 4.573.066.027 2.079-4.57-.066z"
            transform="rotate(45 -77.517 586.616)"
          />
        </g>
      </g>
    </g>
  </svg>
);

CheckboxIcon.defaultProps = {
  fill1: '#006fd6',
  fill2: '#ffffff',
  stroke: '#c4ced5',
};

export default CheckboxIcon;
