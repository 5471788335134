import styled from 'styled-components';

interface IProps {
  minHeightPx?: number;
}

export default styled.div<IProps>`
  min-height: ${(props) => props.minHeightPx}px;
  
  .se-info-icon {
    .icon-stroke {
      fill: #F38037;
    }
  }

`;
