import React from 'react';
import Auth from 'components/container/Auth/auth.component';
import { Unauthorized } from 'components/common';

export default (props: any) => (
  <Auth
    Component={Unauthorized}
    closedNavbar
    {...props}
  />
);
