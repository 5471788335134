import styled from 'styled-components';
import StyledReportSidebarContainer from 'components/feature/Report/ReportSidebar/report-sidebar.style';

export default styled(StyledReportSidebarContainer)` // replicate the report sidebar style
position: absolute;
top: 0;
right: 0;
height: 100%;
overflow: hidden;

.se-report-sidebar {
  .se-report-sidebar-showcase {
    width: 233px;
    border-right: 0px;
    .se-sidebar-showcase-header {
      padding: 18px 15px 17px 11px;
    }
  }
}

.se-sidebar-title-label {
  margin-left: 6px;
  color: #212B36 !important;
  line-height: 17px;
}

.se-sidebar-title-icon.se-sidebar-close-button {
  cursor: pointer;
  margin-left: auto;
}

.se-drilldown-icon {
  display: flex;
  transform: rotate(180deg);
  text-align: center;
  align-items: center;
}

// overrides

.se-dimensions-settings {
  padding: 12px 0px 16px 0px;
  overflow: overlay !important;
  .se-item-name{
    color:var(--paginationColor);
  }
}

.se-draggable-item {
  margin-bottom: 0px;
  border-color: transparent;
  
  &:hover {
    border-color: transparent;
    background-color:var(--borderColor); 
  }
}

`;
