import styled from 'styled-components';

const StyleComponent = styled.div`
width: 100%;

.se-react-select-cn  {

    .se-react-select__control--is-disabled {
        background: #F9FAFB !important;
    }

    .se-react-select__control {
        width: 100%;
        height: 32px;
        min-height: 32px;
        border-radius: 2px;
        box-shadow: none;
        background-color:var(--noDataBackground);
        border-color: var(--paginationBorder);
        padding: 0 8px 0 12px;
        cursor: pointer;

        :hover {
            border-color: var(--onHoverBorder) !important;
        }

        .se-react-select__placeholder {
            color: var(--arrowIcon);
        }

        .se-react-select__clear-indicator {
            display: none;
        }
    }

    .se-react-select__control--is-focused {
        .se-react-select__placeholder {
            color: var(--headerColor);
        }

        .se-react-select__clear-indicator {
            display: flex;
            align-items: center;
        }
    }

    .se-react-select__value-container {
        font-family: Regular;
        font-size: 13px;
        color:var(--headerColor);
        flex-wrap: nowrap;
        padding: 2px 0;
    }

    .se-react-select__input {
        margin-left: -2px;
    }

    .se-react-select__single-value {
        color: inherit;
        margin: 0;
    }

    .se-react-select__option {
        display: flex;
        align-items: center;
        font-family: Regular;
        font-size: 13px;
        padding: 8px 8px 8px 12px;
        color: var(--headerColor);
        cursor: pointer;
    }

    .se-react-select__option--is-focused {
        background-color: var(--borderColor);
    }

    .se-react-select__option--is-selected {
        background-color:var(--reactOnOptionSelect);
    }

    .se-react-select-option-label, .se-react-select-multi-value-cn {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .se-react-select-multi-value-cn {
        position: absolute;
        max-width: 100%;
    }

    .se-react-select-option-label {
        flex: 1;
    }

    .se-react-select-tick-icon {
        margin-left: 6px;
        padding: 0 2px;
    }

    .se-react-select-option-checkbox {
        display: flex;
        align-items: center;
        margin-right: 8px;
    }

    .se-react-select__menu-list {
        max-height: 200px;
        scroll-behavior: smooth;
        ::-webkit-scrollbar {
            width: 6px;
        };
        ::-webkit-scrollbar-thumb {
            background-color: var(--reactSelectScrollBar);
            border-radius: 6px;
        };
    }

    .se-react-select__indicator-separator {
        display: none;
    }

    .se-react-select__dropdown-indicator, .se-react-select__clear-indicator {
        padding: 0 0 0 8px;
    }

    .se-react-select-dropdown-indicator-rotate {
        transform: rotate(180deg);
        display: flex;
        align-items: center;
    }

    .se-react-select__multi-value {
        display: none;
    }

    .se-lazy-loading-cn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
    }

    .se-load-more-options-btn {
        border: 0;
        color: #919EAB;
        font-size: 13px;
    }

    .se-spinner-cn {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            padding-left: 5px;
            font-size: 13px;
            color: #919EAB;
        }
    }

}
.se-remove-red-border {
    .se-react-select__control {
        border-color: #D1331B !important;
    }
}
.se-add-green-border {
    .se-react-select__control {
        border-color: #3BB345 !important;
    }
}

`;
export default StyleComponent;
