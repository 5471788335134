import React from 'react';

type Props = {
  width: number;
  height: number;
  fill: string;
}

const LargeExportIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.47 12.93L8.92 12.48L7.33 10.88V8.87H6.73V11.12L8.47 12.93ZM7 15C6.45333 15 5.93667 14.895 5.45 14.685C4.96333 14.475 4.53833 14.1883 4.175 13.825C3.81167 13.4617 3.525 13.0367 3.315 12.55C3.105 12.0633 3 11.5467 3 11C3 10.4533 3.105 9.93667 3.315 9.45C3.525 8.96333 3.81167 8.53833 4.175 8.175C4.53833 7.81167 4.96333 7.525 5.45 7.315C5.93667 7.105 6.45333 7 7 7C7.54667 7 8.06333 7.105 8.55 7.315C9.03667 7.525 9.46167 7.81167 9.825 8.175C10.1883 8.53833 10.475 8.96333 10.685 9.45C10.895 9.93667 11 10.4533 11 11C11 11.5467 10.895 12.0633 10.685 12.55C10.475 13.0367 10.1883 13.4617 9.825 13.825C9.46167 14.1883 9.03667 14.475 8.55 14.685C8.06333 14.895 7.54667 15 7 15Z"
      fill={fill}
    />
    <path
      d="M1.35 18C0.99 18 0.675 17.865 0.405 17.595C0.135 17.325 0 17.01 0 16.65V1.35C0 0.99 0.135 0.675 0.405 0.405C0.675 0.135 0.99 0 1.35 0H9.4725L14.4 4.9275V16.65C14.4 17.01 14.265 17.325 13.995 17.595C13.725 17.865 13.41 18 13.05 18H1.35ZM8.7975 5.535V1.35H1.35V16.65H13.05V5.535H8.7975ZM1.35 1.35V5.535V1.35V16.65V1.35Z"
      fill={fill}
    />
  </svg>

);

export default LargeExportIcon;
