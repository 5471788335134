const dark = {
  body: '#fff',
  text: '#FAFAFA',
  border: '#6B8096',
  background: '#999',
  headerColor: '#454F5B',
  filterLabelColor: '#637381',
  datepickerColor: '#919EAA',
  datePickerBorder: '#DFE3E8',
  reactSelectBorderAcitve: '#006fd6',
  reactSelectBackground: '#919eaa',
  reactSelectActiveOption: '#f0f8ff',
  backButtonBackground: '#e2ecf9',
  backButtonColor: '#454f5b',
  loaderColor: '#309AFC',
  fullLoaderBar1: '#015CCD',
  fullLoaderBar2: '#E2ECF9',
  fullLoaderBar3: '#3980D8',
  fullLoaderBar4: '#C6DAF3',
  noDataBackground: '#fff',
  noDataColor: '#898989',
  paginationColor: '#637381',
  paginationBorder: '#DFE3E8',
  paginationRecordsSeparator: '#C9d3DB',
  reportHeaderLabel: '#212b36',
  searchBorder: '#C4CDD5',
  searchHoverBorder: '#1172D3',
  searchBoxTextColor: ' #919EAB',
  searchButtonBackground: '#538AC5',
  borderColor: '#F4F6F8',
  navHeaderColor: '#333333',
  sideNavBorderColor: '#cad3db',
  collapsibleColor: '#006fd6',
  paginationPrev: '#000000',
  paginationNext: '#adbac6',
  arrowIcon: '#adbac7',
  onHoverBorder: '#006fd6',
  reactOnOptionSelect: '#E5F0FB',
  reactSelectScrollBar: '#D0CECE',
  rawDataHeader: '#97a2ab',
  rawDataButton: '#e8e9ea',
  boxShadow: '#00000029',
  backButtonBorder: '#E0E3E6',
  sideNavBarBorder: '#00000014',
  navSectionIcon: '#ed7b24',
  headerBackgroundColor: '#012240',
  avtarColor: '#F5B129',
  primaryColor: '#212B36',
  secondaryColor: '#454F5B',
  dangerColor: '#DE323B',
  expandListButtonColor: '#006FD6',
  expandListButtonHoverColor: '#084E96',
  grayCounterBackgroundColor: '#F4F6F8',
  paleSkyColor: '#637381',
  infoIconColor: '#919EAB',
};

export default dark;
