import styled from 'styled-components';

const StyledContainer = styled.div<
{animationTime: number}
>`

width: 100%;
position: relative;

.se-linear-bar-loader {
    width: 0;
    position: absolute;
    height: 4px;
    border-radius: 2px;
}

.se-linear-bar-loader.XS {
    height: 2px;
}

.se-linear-bar-loader.bottom-placed {
    top: -2.2px;
}

.se-linear-bar-loader.primary {
    background-color: #116FD6; 
}

.se-linear-bar-loader.danger {
    background-color: #D1331B; 
}

.se-linear-bar-loader.warning {
    background-color: #F5802A;
}

.se-linear-bar-loader.true {
    width: 100%;
    transition: width ${(props) => props.animationTime}s ease-in-out;
}

.se-linear-bar-loader.showPrimary {
    width: 100%;
    transition: width ${(props) => props.animationTime}s ease-in-out;
}

.se-linear-bar-loader.full {
    width: 100%;
}
`;
export default StyledContainer;
