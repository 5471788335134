import React from 'react';

type Props = {
    width: number;
    height: number;
}

const MainCsvIcon = ({
  width, height,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33342 0.333008H2.00008C1.26675 0.333008 0.673415 0.933008 0.673415 1.66634L0.666748 12.333C0.666748 13.0663 1.26008 13.6663 1.99341 13.6663H10.0001C10.7334 13.6663 11.3334 13.0663 11.3334 12.333V4.33301L7.33342 0.333008ZM10.0001 12.333H2.00008V1.66634H6.66675V4.99967H10.0001V12.333Z"
      fill="#454F5B"
    />
    <path
      d="M9.73081 6.61399L9.31818 7.0561L9.72942 7.49951L9.82262 7.6L5 7.6H4.35V8.25L4.35 9.75V10.4H5H9.81621L9.72381 10.499L9.30841 10.9441L9.72538 11.3877L10.7194 12.4452L11.1935 12.9496L11.6671 12.4447L14.4741 9.45219L14.8902 9.00861L14.4752 8.56399L11.6682 5.55649L11.193 5.04737L10.7178 5.55649L9.73081 6.61399Z"
      fill="#454F5B"
      stroke="white"
      strokeWidth="1.3"
    />
  </svg>

);

export default MainCsvIcon;
