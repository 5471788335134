import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  AuthReduxModel, ReduxModel,
} from 'core/models';
import * as Actions from 'redux/report/actions';

const mapStateToProps = (state: ReduxModel.IGlobalState) => ({
  reduxState: state,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reduxAction: {
    filterChange: (filterId: string, filterValue: any, variables: AuthReduxModel.IVariables) => {
      dispatch(Actions.filterChange(filterId, filterValue, variables));
    },
    applyFilters: () => {
      dispatch(Actions.applyFilters());
    },
    clearFilters: (variables: AuthReduxModel.IVariables) => {
      dispatch(Actions.clearFilters(variables));
    },
    filterLoad: (filterId: string, variables: AuthReduxModel.IVariables) => {
      dispatch(Actions.filterLoadRequest(filterId, variables));
    },
    setToolbarMenuItem: (index: number) => {
      dispatch(Actions.setToolbarMenuItem(index));
    },
    reportConfigLoad: (reportId: string, isRefresh?: boolean) => {
      dispatch(Actions.reportConfigLoadRequest(reportId, isRefresh));
    },
    searchDropdownOptions: (filterId: string, searchKey: string, filterValue: any) => {
      dispatch(Actions.searchDropdownOption(filterId, searchKey, filterValue));
    },
    loadMoreDropdownOptions: (filterId: string, filterValue: any) => {
      dispatch(Actions.loadMoreDropdownOption(filterId, filterValue));
    },
    deleteDynamicFilter: (filterId: string) => {
      dispatch(Actions.deleteDynamicFilter(filterId));
    },
    refresh: () => {
      dispatch(Actions.refresh());
    },
  },
});

export default () => connect(mapStateToProps, mapDispatchToProps);
