import styled from 'styled-components';

const StyledColumnHeaderComponent = styled.div`
width: 100%;
  .se-column-header-cn {
    display: flex;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
  }
  .se-column-header-menu-button {
    flex: 0;
    margin-right: 8px;
  }
  
  .se-column-header-label {
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .se-column-header-sort-cn {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }
  
  .se-column-header-sort-sequence {
    position: absolute;
    line-height: 0;
    top: 8px;
    right: -6px;
    font-size: 11px;
    font-family: Bold;
  }
  
  .se-column-header-sort-up-icon {
    display: flex;
  }
  
  .se-column-header-sort-down-icon {
    display: flex;
   
  }
  
  .se-column-header-cn .se-column-header-sort-cn .active {
    color: #454f5b;
}

.se-column-header-cn .se-column-header-sort-cn .inactive {
    color: #adbac6;
}
`;

export default StyledColumnHeaderComponent;
