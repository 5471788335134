import styled from 'styled-components';

interface IProps {
  isMovable: boolean;
  topOffset: number;
}

export default styled.div<IProps>`
  width: 100%;
  position: ${(props) => (props.isMovable ? 'absolute' : 'relative')};
  top: ${(props) => props.topOffset}px;

  .se-grouping-drag-drop-placeholder {
    border: 1px dashed #ADBAC7;
    padding: 8px 12px;
    font-size: 11px;
    color: #ADBAC7;
    border-radius: 2px;
    text-align: center;
    overflow: hidden;
    transition: border 0.2s cubic-bezier(0.2, 0, 0, 1) 0s;
  }

  .se-grouping-drag-drop-placeholder.active {
    border-color: #084E96;
    color: #084E96;
  }
`;
