import React, { useEffect, useRef } from 'react';
import Tooltip from 'rc-tooltip';
import { SideBarNavItemProps } from 'components/feature/Report/ReportSidebar/common/SideBarNavItems/sidebar-nav-item.d';
import StyledSidebarItem from 'components/feature/Report/ReportSidebar/common/SideBarNavItems/sidebar-item.style';

const SideBarNavItem = (props:SideBarNavItemProps) => {
  const {
    item, isActive, isAutodrilldown, onPress, index,
  } = props;
  const itemContainer = useRef(null);
  let heightOfInsideButton;
  useEffect(() => {
    if (itemContainer !== null) {
      heightOfInsideButton = itemContainer.current.clientHeight;
    }
  }, [itemContainer]);
  return (
    <StyledSidebarItem>
      <Tooltip
        key={item.id}
        placement="left"
        overlay={(
          <span>
            {item.displayName}
          </span>
          )}
        mouseEnterDelay={1.5}
      >
        <div className={`se-item-container ${isAutodrilldown && index !== 1 ? 'disabled' : ''}`} ref={itemContainer}>
          <button
            className={`se-sidebar-nav-item ${item.className} ${isAutodrilldown && index !== 1 ? 'disabled' : ''} ${isActive ? 'active' : ''}`}
            type="button"
            onClick={() => onPress()}
          >
            <div className="se-inside-button" style={{ height: `${heightOfInsideButton}px` }}>
              <div className={`se-nav-item ${item.displayName}`}>
                <div className="se-nav-icon">
                  <item.icon
                    width={20}
                    height={20}
                    fill="#454F5B"
                    fill1="#454F5B"
                    strokeFill="#454F5B"
                  />
                </div>
                <div className="se-nav-item-name">
                  {item.displayName}
                </div>
              </div>
              <div className={`se-active-indicator ${isActive ? 'active' : ''}`} />
            </div>
          </button>
        </div>
      </Tooltip>
    </StyledSidebarItem>
  );
};

export default SideBarNavItem;
