import styled from 'styled-components';

const StyledReportComponent = styled.div`
padding: 16px 20px 0 0;

.se-report-card {
    width: 320px;
    height: 80px;
    display: block;
    padding: 12px;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    box-sizing: border-box;
    text-align: left;
    text-decoration: none;

    :hover {
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
    }
}

.se-report-header {
    display: flex;
    align-items: center;
    justify-items: space-between;
}

.se-report-card-icon {
    display: flex;
    align-items: center;
}

.se-report-title {
    flex: 1;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: SemiBold;
    font-size: 13px;
    color: #454F5B;
}

.se-report-action {
    margin-left: 8px;

}

.se-report-description {
    height: 36px;
    display: -webkit-box;
    font-size: 12px;
    line-height: 1.5;
    color:var(--paginationColor);
    margin-left:3px;
    font-family: Regular;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

`;

export default StyledReportComponent;
