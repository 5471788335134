import React from 'react';
import Tooltip from 'rc-tooltip';
import InfoIcon from 'components/common/svg/Info/info.svg';
import { mouseEnterDelay } from '../common/constants';

export const getDimensionSelectedCount = (maxLimitReached: boolean, dimensionsSelected: number, maxDimensions: number) => (
  <span
    className={`se-grouping-counter ${maxLimitReached ? 'danger' : ''}`}
  >
    {`${dimensionsSelected}/${maxDimensions}`}
  </span>
);

export const getInfoIconWithTooltip = (tooltipDescription: string) => (
  <Tooltip
    placement="bottom"
    overlay={<span>{tooltipDescription}</span>}
    overlayClassName="se-grouping-tooltip"
    mouseEnterDelay={mouseEnterDelay}
  >
    <div className="se-info-icon">
      <InfoIcon
        width={12}
        height={12}
        fill="var(--infoIconColor)"
      />
    </div>
  </Tooltip>
);
