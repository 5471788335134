import { ReportListActionTypes } from 'core/constants/redux-action-types';
import { ReportListSortType } from 'core/constants/report';
import { ReportListReduxModel, ReportListResponseModel } from 'core/models';

export const reportCategoriesLoadRequest = ():
ReportListReduxModel.IReportCategoriesLoadRequest => ({
  type: ReportListActionTypes.REPORT_CATEGORIES_LOAD_REQUEST,
});

export const reportCategoriesLoadSuccess = (categories: Array<ReportListResponseModel.ICategory>):
ReportListReduxModel.IReportCategoriesLoadSuccess => ({
  type: ReportListActionTypes.REPORT_CATEGORIES_LOAD_SUCCESS,
  payload: {
    categories,
  },
});

export const reportCategoriesLoadFailure = ():
ReportListReduxModel.IReportCategoriesLoadFailure => ({
  type: ReportListActionTypes.REPORT_CATEGORIES_LOAD_FAILURE,
});

export const pinnedReportsLoadRequest = ():
ReportListReduxModel.IPinnedReportsLoadRequest => ({
  type: ReportListActionTypes.PINNED_REPORTS_LOAD_REQUEST,
});

export const pinnedReportsLoadSuccess = (pinnedReports: Array<ReportListResponseModel.IReportSection>):
ReportListReduxModel.IPinnedReportsLoadSuccess => ({
  type: ReportListActionTypes.PINNED_REPORTS_LOAD_SUCCESS,
  payload: {
    pinnedReports,
  },
});

export const pinnedReportsLoadFailure = ():
ReportListReduxModel.IPinnedReportsLoadFailure => ({
  type: ReportListActionTypes.PINNED_REPORTS_LOAD_FAILURE,
});

export const categoryReportsLoadRequest = ():
ReportListReduxModel.ICategoryReportsLoadRequest => ({
  type: ReportListActionTypes.CATEGORY_REPORTS_LOAD_REQUEST,
});

export const categoryReportsLoadSuccess = (
  categoryId: string, categoryReports: Array<ReportListResponseModel.IReportSection>,
):
ReportListReduxModel.ICategoryReportsLoadSuccess => ({
  type: ReportListActionTypes.CATEGORY_REPORTS_LOAD_SUCCESS,
  payload: {
    categoryId,
    categoryReports,
  },
});

export const categoryReportsLoadFailure = ():
ReportListReduxModel.ICategoryReportsLoadFailure => ({
  type: ReportListActionTypes.CATEGORY_REPORTS_LOAD_FAILURE,
});

export const reportPinUnpinRequest = (reportId: string, isPinned: boolean):
ReportListReduxModel.IReportPinUnpinRequest => ({
  type: ReportListActionTypes.REPORT_PIN_UNPIN_REQUEST,
  payload: {
    reportId,
    isPinned,
  },
});

export const reportPinUnpinSuccess = () => ({
  type: ReportListActionTypes.REPORT_PIN_UNPIN_SUCCESS,
});

export const reportPinUnpinFailure = (): ReportListReduxModel.IReportPinUnpinFailure => ({
  type: ReportListActionTypes.REPORT_PIN_UNPIN_FAILURE,
});

export const sortingListChange = (sortType: ReportListSortType)
: ReportListReduxModel.ISortingChange => ({
  type: ReportListActionTypes.SORTING_LIST_CHANGE,
  payload: {
    sortType,
  },
});

export const selectReportType = (id: string, name: string, isReportCategory: boolean)
: ReportListReduxModel.ISelectReportType => ({
  type: ReportListActionTypes.SELECT_REPORT_TYPE,
  payload: {
    id,
    name,
    isReportCategory,
  },
});

export const reorderReportCategoriesRequest = (categories: Array<ReportListResponseModel.ICategory>)
: ReportListReduxModel.IReorderReportCategoriesRequest => ({
  type: ReportListActionTypes.REORDER_REPORT_CATEGORIES_REQUEST,
  payload: {
    categories,
  },
});

export const reorderReportCategoriesSuccess = (categories: Array<ReportListResponseModel.ICategory>)
: ReportListReduxModel.IReorderReportCategoriesSuccess => ({
  type: ReportListActionTypes.REORDER_REPORT_CATEGORIES_SUCCESS,
  payload: {
    categories,
  },
});

export const reorderReportCategoriesFailure = ()
: ReportListReduxModel.IReorderReportCategoriesFailure => ({
  type: ReportListActionTypes.REORDER_REPORT_CATEGORIES_FAILURE,
});
