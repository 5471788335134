import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const ReportViewsIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width="19px"
    height="19px"
    viewBox="0 0 19 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_8848"
      data-name="Path 8848"
      d="M9.16667 10.1252C10.1528 10.1252 10.9896 9.78141 11.6771 9.09391C12.3646 8.40641 12.7083 7.56961 12.7083 6.5835C12.7083 5.59738 12.3646 4.76058 11.6771 4.07308C10.9896 3.38558 10.1528 3.04183 9.16667 3.04183C8.18056 3.04183 7.34375 3.38558 6.65625 4.07308C5.96875 4.76058 5.625 5.59738 5.625 6.5835C5.625 7.56961 5.96875 8.40641 6.65625 9.09391C7.34375 9.78141 8.18056 10.1252 9.16667 10.1252ZM9.16667 8.91683C8.51389 8.91683 7.96181 8.69114 7.51042 8.23975C7.05903 7.78836 6.83333 7.23627 6.83333 6.5835C6.83333 5.93072 7.05903 5.37863 7.51042 4.92725C7.96181 4.47586 8.51389 4.25016 9.16667 4.25016C9.81944 4.25016 10.3715 4.47586 10.8229 4.92725C11.2743 5.37863 11.5 5.93072 11.5 6.5835C11.5 7.23627 11.2743 7.78836 10.8229 8.23975C10.3715 8.69114 9.81944 8.91683 9.16667 8.91683ZM9.16667 12.8335C7.13889 12.8335 5.30556 12.2571 3.66667 11.1043C2.02778 9.95155 0.805556 8.44461 0 6.5835C0.805556 4.72238 2.02778 3.21544 3.66667 2.06266C5.30556 0.909885 7.13889 0.333496 9.16667 0.333496C11.1944 0.333496 13.0278 0.909885 14.6667 2.06266C16.3056 3.21544 17.5278 4.72238 18.3333 6.5835C17.5278 8.44461 16.3056 9.95155 14.6667 11.1043C13.0278 12.2571 11.1944 12.8335 9.16667 12.8335Z"
      fill="none"
      className="icon-active"
    />

    <path
      id="Path_8849"
      data-name="Path 8849"
      d="M9.16667 9.79167C10.1528 9.79167 10.9896 9.44792 11.6771 8.76042C12.3646 8.07292 12.7083 7.23611 12.7083 6.25C12.7083 5.26389 12.3646 4.42708 11.6771 3.73958C10.9896 3.05208 10.1528 2.70833 9.16667 2.70833C8.18056 2.70833 7.34375 3.05208 6.65625 3.73958C5.96875 4.42708 5.625 5.26389 5.625 6.25C5.625 7.23611 5.96875 8.07292 6.65625 8.76042C7.34375 9.44792 8.18056 9.79167 9.16667 9.79167ZM9.16667 8.58333C8.51389 8.58333 7.96181 8.35764 7.51042 7.90625C7.05903 7.45486 6.83333 6.90278 6.83333 6.25C6.83333 5.59722 7.05903 5.04514 7.51042 4.59375C7.96181 4.14236 8.51389 3.91667 9.16667 3.91667C9.81944 3.91667 10.3715 4.14236 10.8229 4.59375C11.2743 5.04514 11.5 5.59722 11.5 6.25C11.5 6.90278 11.2743 7.45486 10.8229 7.90625C10.3715 8.35764 9.81944 8.58333 9.16667 8.58333ZM9.16667 12.5C7.13889 12.5 5.30556 11.9236 3.66667 10.7708C2.02778 9.61805 0.805556 8.11111 0 6.25C0.805556 4.38889 2.02778 2.88194 3.66667 1.72917C5.30556 0.576389 7.13889 0 9.16667 0C11.1944 0 13.0278 0.576389 14.6667 1.72917C16.3056 2.88194 17.5278 4.38889 18.3333 6.25C17.5278 8.11111 16.3056 9.61805 14.6667 10.7708C13.0278 11.9236 11.1944 12.5 9.16667 12.5ZM9.16667 11.25C10.8472 11.25 12.3924 10.7951 13.8021 9.88542C15.2118 8.97569 16.2847 7.76389 17.0208 6.25C16.2847 4.73611 15.2118 3.52431 13.8021 2.61458C12.3924 1.70486 10.8472 1.25 9.16667 1.25C7.48611 1.25 5.94097 1.70486 4.53125 2.61458C3.12153 3.52431 2.04167 4.73611 1.29167 6.25C2.04167 7.76389 3.12153 8.97569 4.53125 9.88542C5.94097 10.7951 7.48611 11.25 9.16667 11.25Z"
      fill={fill}
      className="icon-stroke"
    />

  </svg>
);

ReportViewsIcon.defaultProps = {
  fill: '#454F5B',
};

export default ReportViewsIcon;
