/* This component will render the Reports with Basic tables. This component will be stateless
 component and states are maintained at parent wrapper component */

import React from 'react';
import { getRowData, hasPivotColumn } from 'core/utils/report-builder.util';
import { IProps } from 'components/feature/Report/ReportBuilder/Reports/Tables/BasicTable/basic-table.d';
import StoreConnector from 'components/feature/Report/ReportBuilder/Reports/Tables/BasicTable/basic-table.redux';
import Grid from 'components/common/visualization/Grid/grid.component';
import { isEmpty } from 'lodash';

function BasicTable(props: IProps) {
  // It will contain the configuraiton specific to  table report.
  const {
    reduxState, reduxAction, columns, onCellClicked, onColumnReorder,
  } = props;
  const {
    reportConfig, reportData, page, sort, isMultiSort, activeFilters, activeDimensions,
    activeDimensionsLength, isMultiSortInActiveDimension, columns: reportColumns,
    autodrilldownData,
  } = reduxState.report;
  const { sortingChange } = reduxAction;
  const basicTableConfig = {};

  const { report } = reduxState;

  let allowUserDrilldown = reportConfig.AllowUserDrillDown;
  let pageSize = page.PageSize;
  let rowData = reportData?.Raw?.Data
    ? getRowData(reportColumns, reportData.Raw.Data, activeDimensions)
    : [];
  let aggregatesData = reportData?.Raw?.Aggregates;
  let sorting = {
    isMultiSortInActiveDimension,
    sort,
    isMultiSort,
    onChange: sortingChange,
  };
  let currActiveFilters = activeFilters;
  let currActiveDimLength = activeDimensionsLength;
  let pivotMode = hasPivotColumn(reportColumns);
  const isAutodrilldown = !isEmpty(autodrilldownData) && !isEmpty(autodrilldownData.reportData);
  const isDrilldown = !!reportConfig && reportConfig.AllowUserDrillDown
  && !!report.prevAppliedFilters && !!report.prevAppliedFilters.length;
  let allowAutodrilldown = reportConfig.AllowDrilldownOnMaskedDimensions;

  if (isAutodrilldown) {
    allowUserDrilldown = autodrilldownData.reportData.Visualization?.AllowUserDrillDown;
    pageSize = autodrilldownData.page.PageSize;
    rowData = autodrilldownData.reportData?.Raw?.Data
      ? getRowData(autodrilldownData.columns, autodrilldownData.reportData.Raw.Data, [])
      : [];
    aggregatesData = autodrilldownData.reportData?.Raw?.Aggregates;
    sorting = {
      isMultiSortInActiveDimension: false,
      sort: autodrilldownData.sort,
      isMultiSort: autodrilldownData.isMultiSort,
      // disabled sorting for drilldown.
      onChange: () => {},
    };
    currActiveFilters = {};
    currActiveDimLength = 0;
    pivotMode = false;
    allowAutodrilldown = false;
  }

  // All methods specific to this component will be below
  return (
    <div className={`se-basic-table ${isAutodrilldown || isDrilldown ? 'se-basic-table-autodrilldown' : ''}`}>
      <div className="se-grid-container">
        <Grid
          paginationPageSize={pageSize}
          rowData={rowData}
          aggregatesData={aggregatesData}
          columns={columns}
          sorting={sorting}
          specificconfig={basicTableConfig}
          onCellClicked={onCellClicked}
          onColumnReorder={onColumnReorder}
          activeFilters={currActiveFilters}
          AllowUserDrillDown={allowUserDrilldown}
          activeDimensionsLength={currActiveDimLength}
          pivotMode={pivotMode}
          autodrilldown={isAutodrilldown}
          allowAutodrilldown={allowAutodrilldown}
        />
      </div>
    </div>
  );
}

export default StoreConnector()(BasicTable);
