/* eslint-disable no-shadow */
export const DateDisplayFormat = 'MMM DD, yyyy';

export const DatePayloadFormat = 'yyyy-MM-DDTHH:mm:ss';

export const InvalidDate = 'Invalid Date';

export enum IDateRangeTypes {
    Absolute = 'Absolute',
    Relative = 'Relative',
}

export enum IRelativeDateRanges {
    Today = 'Today',
    Yesterday = 'Yesterday',
    ThisWeek = 'This Week',
    LastWeek = 'Last Week',
    ThisMonth = 'This Month',
    LastMonth = 'Last Month',
    ThisQuarter = 'This Quarter',
    LastQuarter = 'Last Quarter',
    ThisYear = 'This Year',
    LastYear = 'Last Year',
}
