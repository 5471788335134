import React from 'react';
import { IRelativeDateRanges } from 'core/constants/date';
import { ICustomRangeProps } from 'components/common/DatePicker/DateRange/CustomRange/custom-range.d';
import StyledContainer from 'components/common/DatePicker/DateRange/CustomRange/custom-range.style';

const CustomRange = (props: ICustomRangeProps) => {
  const { selected, onChange } = props;
  return (
    <StyledContainer>
      {
        Object.values(IRelativeDateRanges).map((item) => (
          <button
            key={item}
            className="se-custom-range"
            type="button"
            onClick={() => {
              onChange(item, true);
            }}
            disabled={item === selected}
          >
            {item}
          </button>
        ))
      }
    </StyledContainer>
  );
};

export default CustomRange;
