import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const ListIcon = ({
  width, height, fill,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ width, height }} viewBox="0 0 20 11.11">
    <path
      id="list_FILL0_wght400_GRAD0_opsz48"
      d="M10.721,15.666V14H26v1.666Zm0,4.721V18.721H26v1.666Zm0,4.721V23.443H26v1.666ZM6.833,15.666a.807.807,0,1,1,.6-.236A.809.809,0,0,1,6.833,15.666Zm0,4.721a.807.807,0,1,1,.6-.236A.809.809,0,0,1,6.833,20.388Zm0,4.721a.807.807,0,1,1,.6-.236A.809.809,0,0,1,6.833,25.109Z"
      transform="translate(-6 -14)"
      fill={fill}
    />
  </svg>
);

ListIcon.defaultProps = {
  width: 20,
  height: 11,
  fill: '#637381',
};

export default ListIcon;
