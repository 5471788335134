import styled from 'styled-components';

const StyledEmptyData = styled.div`
text-align: center;
width:100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
color: var(--noDataColor);
.se-empty-data-header{
text-align: center;
font-family: SemiBold;
letter-spacing: 0px;
color: #454F5B;
opacity: 1;
font-size:16px;
}
> .se-empty-data-text{
    text-align: center;
    font-family:Regular
    letter-spacing: 0px;
    color: #637381;
    opacity: 1;
    font-size:13px;
}

`;

export default StyledEmptyData;
