import React from 'react';
import { IProps } from 'components/common/SideNavbar/SideNavbarSection/side-navbar-section.d';
import SideNavCategories from 'components/common/SideNavbar/SideNavbarSection/SideNavCategories/side-nav-categories.component';

const SideNavBarSection = (props: IProps) => {
  const {
    item, location, history, reorderCategories, ...rest
  } = props;

  return (
    <div
      key={item.SectionId}
      className="se-side-nav-section"
    >
      <div
        className="se-side-nav-section-header"
      >
        {item.Section}
      </div>
      <div
        className="se-side-nav-section-types-cn"
      >
        {
          item.SubTypes && (
            <div
              className="se-side-nav-section-sub-types"
            >
              {
                item.SubTypes.map((subType) => {
                  const isSelected = location.hash === `#${item.SectionId}/${subType.Id}`;
                  return (
                    <button
                      key={subType.Id}
                      type="button"
                      className={`se-side-nav-sub-type ${isSelected ? 'active' : ''}`}
                      onClick={() => {
                        history.push({
                          pathname: item.Path,
                          hash: `${item.SectionId}/${subType.Id}`,
                        });
                      }}
                    >
                      <div
                        className="se-side-nav-sub-type-icon"
                      >
                        <subType.Icon
                          width={20}
                          height={20}
                          fill={subType.IconColor}
                        />
                      </div>
                      <div
                        className="se-side-nav-sub-type-label"
                      >
                        {subType.Name}
                      </div>
                    </button>
                  );
                })
            }
            </div>
          )
        }
        {
          item.Categories && (
            <SideNavCategories
              {...rest}
              history={history}
              location={location}
              section={item}
              reorderCategories={reorderCategories}
            />
          )
        }
      </div>
    </div>
  );
};

export default SideNavBarSection;
