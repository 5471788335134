import API from 'services/api';
import { ObjModel, ReportListResponseModel, ReportResponseModel } from 'core/models';
import { IDataRefresh } from 'core/models/report-response';
import { IReportViewInfo, IReportView, IDeleteReportView } from 'types/report-view.type';
import { ReportViewTypes } from 'core/constants/report';

export class ReportService extends API {
  static baseURL = process.env.REACT_APP_DATA_BASE_URL || '';

  constructor() {
    super(ReportService.baseURL);
  }

  getReportConfig = async (params: ObjModel.Obj): Promise<ReportResponseModel.IReportConfig> => (
    this.responsify(await this.instance.get('reports/get-report-config', {
      params,
    }))
  )

  getReports = async () => (
    this.responsify(await this.instance.get('reports/get-report-configs'))
  );

  getReportDataWithChart = async (criteria: ReportFetchCriteria) => (
    this.responsify(await this.instance.post('reports/fetch-report-chart', criteria))
  );

  getReportData = async (criteria: ReportFetchCriteria, params:any) : Promise<ReportResponseModel.IReportData> => (
    this.responsify(await this.instance.post('reports/fetch-report', criteria, params))
  );

  getReportCategories = async (): Promise<Array<ReportListResponseModel.ICategory>> => (
    this.responsify(await this.instance.get('categories/get-categories'))
  );

  getPinnedReports = async (data: IPinnedReportsPayload): Promise<Array<ReportListResponseModel.IReportSection>> => (
    this.responsify(await this.instance.post('reports/get-pinned-reports', data))
  );

  getCategoryReports = async (data: ICategoryReportsPayload): Promise<Array<ReportListResponseModel.IReportSection>> => (
    this.responsify(await this.instance.post('reports/get-reports-category', data))
  );

  pinUnpinReport = async (data: IPinReportPayload): Promise<string> => (
    this.responsify(await this.instance.post('reports/pin-unpin-report', data))
  );

  reorderReportCategories = async (data: ICategoriesReorderPayload) : Promise<string> => (
    this.responsify(await this.instance.post('categories/sort-categories', data))
  )

  getReportViews = async (reportId: string) : Promise<IReportView[]> => (
    this.responsify(await this.instance.get('reports/get-report-views', {
      params: {
        reportId,
      },
    }))
  )

  createReportView = async (data: ObjModel.Obj) => (
    this.responsify(await this.instance.post('reports/create-report-view', data))
  )

  deleteReportView = async (params: IDeleteReportView) => (
    this.responsify(await this.instance.delete('reports/delete-report-view', { params }))
  )

  saveColumnsOrder = async (data: ISaveColumnsOrderPayload) => (
    this.responsify(await this.instance.post('reports/save-columns', data))
  )

  getDynamicDimensions = async (reportId: string, selectedMeasure?:string) : Promise<Array<ReportResponseModel.IColumn>> => (
    this.responsify(await this.instance.get('visualisation/get-dynamic-fields', {
      params: {
        reportId,
        selectedMeasure,
      },
    }))
  )

  getDrillDownReportConfig = async (reportId: string, selectedMeasure: string): Promise<ReportResponseModel.IReportConfig> => (
    this.responsify(await this.instance.post('reports/get-drilldown-config', null, {
      params: {
        reportId,
        selectedMeasure,
      },
    })))

    getFetchDrilldownReport = async (criteria: ReportFetchCriteria) : Promise<ReportResponseModel.IReportData> => (
      this.responsify(await this.instance.post('reports/fetch-drilldown-report', criteria))
    );

    getExportData = async (criteria: ReportFetchCriteria, fileType:string) : Promise<ReportResponseModel.IExportData> => (
      this.responsify(await this.instance.post('reports/trigger-export', criteria, {
        params: {
          fileType,
        },
      }))
    );

    getExportListData = async (): Promise<Array<ReportResponseModel.IExportData>> => (
      this.responsify(await this.instance.get('reports/get-export-list'))
    );

    getExportUsageStatus = async (): Promise<ReportResponseModel.IExportUsageData> => (
      this.responsifyGeneric<ReportResponseModel.IExportUsageData>(await this.instance.get('reports/get-export-usage'))
    );

    getExportListDataByIds = async (reportIds: Array<string>): Promise<Array<ReportResponseModel.IExportData>> => (
      this.responsify(await this.instance.get('reports/get-export-status', {
        params: {
          exportIds: reportIds.join(','),
        },
      }))
    );

    getRetryExportData = async (params: object) : Promise<string> => (
      this.responsify(await this.instance.post('reports/retry-export', params))
    );

    getDataRefreshDetails = async (params: object) : Promise<IDataRefresh> => (
      this.responsify(await this.instance.get('reports/data-refresh-status', {
        params,
      })))

    getReportViewInfoAPI = async (personalisationId:string, ReportViewType: ReportViewTypes) : Promise<IReportViewInfo> => (
      this.responsify(await this.instance.get('reports/get-report-view-info', {
        params: {
          personalisationId,
          ReportViewType,
        },
      }))
    );

    shareUnshareReportView = async (params: object) => (
      this.responsify(await this.instance.post('reports/share-unshare-report-view', params))
    )
}

export interface ReportFetchCriteria {
  ReportId: string;
  Filters: any;
  Page:any;
  Sort:any
  Columns: Array<ReportResponseModel.IColumn>;
  FiltersMetadata?: ReportResponseModel.IFiltersMetaData;
  SelectedMeasure: string;
  SelectedRow: Array<SelectedRow>;
  DrilldownColumns?: Array<ReportResponseModel.IColumn>;
  PersonalisedViewId: string;
}

export interface SelectedRow {
  Alias: string;
  Value: string;
  Entity: string;
  IsMasked: boolean;
}

export interface IPinnedReportsPayload {
  sort: string;
}

export interface ICategoryReportsPayload {
  CategoryId: string;
  sort: string;
}

export interface IPinReportPayload {
  ReportId: string;
  IsPinned: boolean;
}

export interface ICategoriesReorderPayload {
  CategoryIds: Array<string>;
}

export interface ISaveColumnsOrderPayload {
  ReportId: string;
  ColumnNames: Array<string>;
}
