import React from 'react';
import { IProps } from 'components/common/loader/Spinner/spinner.d';
import StyledContainer from 'components/common/loader/Spinner/spinner.style';

const Spinner = ({ className }: IProps) => (
  <StyledContainer>
    <div
      className={`se-spinner-loader ${className || ''}`}
    />
  </StyledContainer>

);

export default Spinner;
