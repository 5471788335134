import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'globalStyles';
import 'index.css';
import App from 'App';
import Store from 'redux/store';
import dark from 'themes/dark.theme';
import 'rc-tooltip/assets/bootstrap_white.css';

ReactDOM.render(
  <Provider store={Store}>
    <ThemeProvider theme={dark}>
      <GlobalStyle />
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
