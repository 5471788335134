import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  IAvailableDimensionsProps,
} from 'components/feature/Report/ReportSidebar/common/AvailableDimensions/available-dimensions.d';
import StyledContainer from 'components/feature/Report/ReportSidebar/common/AvailableDimensions/available-dimensions.style';
import StyledSettingsContainer from 'components/feature/Report/ReportSidebar/Settings/settings.style';
import StyledDimensionsContainer from 'components/feature/Report/ReportSidebar/Settings/Dimensions/dimensions.style';
import CloseIcon from 'components/common/svg/Close/close.svg';
import * as Actions from 'redux/report/actions';
import { ReduxModel } from 'core/models';
import getIcon from './available-dimension-utils';

const AvailableDimensions = (props: IAvailableDimensionsProps) => {
  const {
    reportBuilderPortal, title, expanded, collapse,
    showcase, search, context,
  } = props;

  const [dynamicDimensions] = useSelector((state: ReduxModel.IGlobalState) => [
    state.report.dynamicDimensions, state.report.reportConfig] as const);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dynamicDimensions) {
      dispatch(Actions.dynamicDimensionsLoadRequest());
    }
  }, []);

  // Available Fields is supposed to hover over the report, which means
  // it must be rendered in the report builder. So we open a React portal to it.

  if (!reportBuilderPortal) return null;
  return ReactDOM.createPortal((
    <StyledContainer>
      {/* We re-create the entire sidebar and all its child components
          to re-use its styling because now we are outside it.
      */}
      <div className="se-report-sidebar">
        <div className={`se-report-sidebar-showcase ${expanded ? 'expand' : ''}`}>
          <div className="se-sidebar-showcase-header">
            <div className="se-sidebar-header-title">
              <div className="se-sidebar-title-icon">
                {getIcon(context)}
              </div>
              <div className="se-sidebar-title-label font-sm" style={{ fontSize: 13 }}>
                {title}
              </div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                className="se-sidebar-title-icon se-sidebar-close-button"
                onClick={() => collapse()}
              >
                <CloseIcon
                  width={16}
                  height={16}
                />
              </div>
            </div>
          </div>
          <div className="se-sidebar-searchbar">
            {search}
          </div>
          <StyledSettingsContainer>
            <div className="se-report-settings">
              <StyledDimensionsContainer>
                {showcase}
              </StyledDimensionsContainer>
            </div>
          </StyledSettingsContainer>
        </div>
      </div>
    </StyledContainer>
  ), reportBuilderPortal);
};

export default AvailableDimensions;
