import React, { useState } from 'react';
import StyledBackButtonContainer from 'components/common/BackButton/back-button.styled';
import { ObjModel } from 'core/models';
import { IProps } from 'components/common/BackButton/back-button.d';
import NextIcon from 'components/common/svg/Next/next.svg';

// Backbutton component will be used to navigate from current component to parent/previous component
// Params will be of Props Interface
// Returns a component.
export default function BackButton(props: IProps) {
  const {
    backCallback, prevValues, getPreviousDrilldownValue, drilldownLevel,
  } = props;
  const [open, setOpen] = useState(false);
  const backButtonCallback = (params: IProps, value:number) => {
    if (params.backCallback) {
      params.backCallback(value);
      setOpen(!open);
    }
  };
  const getAllIndex = prevValues.map((value:ObjModel.Obj, index:number) => index);
  const getLastThreeIndex = getAllIndex.slice(-3);
  const buttonVisible = getAllIndex.length > 3;
  const dropdownValues = buttonVisible ? getAllIndex.slice(0, getAllIndex.length - 3) : null;
  const toggleDropDown = () => {
    setOpen(!open);
  };
  return (

    <StyledBackButtonContainer>
      {buttonVisible && (
        <button
          type="button"
          className={`se-dropdown-btn ${open ? 'active' : ''}`}
          onClick={toggleDropDown}
        >
          &#183;&#183;&#183;
        </button>
      )}
      {open && buttonVisible && (
        <ul className="se-dropdown">
          {dropdownValues.reverse().map((value:number) => (
            <li>
              <button
                type="button"
                className="se-back-item"
                onClick={() => backButtonCallback(props, value)}
                disabled={backCallback === null}
              >
                {getPreviousDrilldownValue(value)}
              </button>
            </li>
          )) }

        </ul>
      )}
      {getLastThreeIndex.map((value:number) => (
        <button
          type="button"
          className="se-back-item"
          onClick={() => backButtonCallback(props, value)}
          disabled={backCallback === null}
        >

          {getPreviousDrilldownValue(value)}

          <span
            className="se-back-icon"
          >
            <NextIcon
              width={20}
              height={20}
              fill="#637381"
            />
          </span>
        </button>
      ))}
      <div className="se-last-back-item-wrapper">
        <span className="se-last-back-item">{drilldownLevel}</span>
      </div>
    </StyledBackButtonContainer>

  );
}
