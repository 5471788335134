import Highcharts from 'highcharts';
import { VisualizationTypes, Chartcolors } from 'core/constants/visualizations';
import {
  ColumnActionTypes, ColumnModes, DimensionNoValue, DimensionStatus, DrilldownChangesTypes, DrilldownChangeTypes, EncryptedText, PivotColGrpHeaderSeparator,
} from 'core/constants/report';
import { getFormattedValues, getFormattedValuesForBinning } from 'core/utils/formatters.utility';
import {
  GridModel, ObjModel, ReportReduxModel, ReportResponseModel, FilterModel,
} from 'core/models';
import { IColumnActionDrilldownChangeMeta } from 'core/models/report-response';
import { extractValFromStrWithSeparator, getColumnsProperties } from 'core/utils/report-builder.util';
import { getFieldIdentifier } from 'core/utils/report.util';

export default class ColumnChartUtil {
  static Styles = {
    barLoader: {
      backgroundColor: 'rgb(234 234 234 / 70%)',
      zIndex: 1,
    },
  };

  data: ReportReduxModel.IState;

  builder: ReportResponseModel.IChartVisualizationBuilder;

  XAxis: Array<ReportResponseModel.IColumn>;

  YAxis: Array<ReportResponseModel.IColumn>;

  constructor(data: ReportReduxModel.IState) {
    this.data = data;
    this.builder = data.reportConfig.Visualization.Builder as ReportResponseModel.IChartVisualizationBuilder;
    this.XAxis = [];
    this.YAxis = [];

    if (this.data.columns) {
      // Below builds x and y axis for graph. Only those columns are selected which has entry
      // in respective axis's Columns inside Visualization's Builder. The columns and referer
      // columns are validated for dimensions, ignored if related dimension is not applied.
      const refererCols = [] as Array<string>;
      this.data.columns.forEach((column) => {
        if (column.Props) {
          if (column.Props.Dimension) {
            if (!(column.BuilderConfig && column.BuilderConfig.IsDynamicField)) { // ignore for dynamic fields
              if (column.Props.ReferTo) {
                refererCols.push(column.Props.ReferTo);
              }
            }
            if (column.Props.Dimension.Applied === DimensionStatus.NotApplied) {
              return;
            }
          }
          if (column.Props.Mode === ColumnModes.Hidden) {
            return;
          }
        }
        if (!(column.BuilderConfig && column.BuilderConfig.IsDynamicField)) { // ignore for dynamic fields
          if (refererCols.includes(column.Name)) {
            const index = this.data.columns.findIndex((item) => (item.Props
            && item.Props.Dimension && item.Props.ReferTo === column.Name
            && item.Props.Dimension.Applied === DimensionStatus.NotApplied
            ));
            if (index > -1) {
              return;
            }
          }
        }

        if (this.builder.XAxis.Columns.includes(getFieldIdentifier(column))
        || (column.BuilderConfig && column.BuilderConfig.IsDynamicField)) {
          this.XAxis.push(column);
        }
        if (this.builder.YAxis.Columns.includes(column.Name)) {
          this.YAxis.push(column);
        }
      });
    }
  }

  GetXAxisColumns = () : {categories: Array<string>, categoryData: ObjModel.ObjGeneric<ObjModel.Obj>} => {
    const data = this.data.reportData.Raw.Data;
    // const fomatters = {};
    let categories : Array<string> = [];
    const categoryData : ObjModel.ObjGeneric<ObjModel.Obj> = {};
    if (this.XAxis && this.XAxis) {
      for (let i = 0; i < this.XAxis.length; i += 1) {
        if (i > 0) {
          break;
        }
        const column = this.XAxis[i];
        const { Props } = column;

        // fomatters= column.formatters
        categories = categories.concat(data.map((d: ObjModel.Obj) => {
          if (Props && Props.IsMasked) {
            if (this.data.reportConfig.AllowDrilldownOnMaskedDimensions) {
              return `${d[getFieldIdentifier(column)]}${PivotColGrpHeaderSeparator}${EncryptedText}`;
            }
            return d[getFieldIdentifier(column)];
          }
          return Props.Dimension && Props.Dimension.DimensionProp
            ? getFormattedValuesForBinning(d[getFieldIdentifier(column)], Props.Dimension.DimensionProp.Props.BinningType)
            : getFormattedValues(d[getFieldIdentifier(column)], Props.Formatter);
        }));
        data.forEach((d: ObjModel.Obj) => {
          categoryData[
            column.Props && column.Props.IsMasked && this.data.reportConfig.AllowDrilldownOnMaskedDimensions
              ? `${d[getFieldIdentifier(column)]}${PivotColGrpHeaderSeparator}${EncryptedText}`
              : d[getFieldIdentifier(column)]
          ] = d;
        });
      }
    }
    return { categories, categoryData };
  };

  GetYAxisColumns = (activeFilters:ObjModel.ObjGeneric<FilterModel.IFilterResponse>, drilldown:Function, AllowUserDrillDown:boolean) : Array<{name: string, data: Array<ObjModel.Obj>}> => {
    const data = this.data.reportData.Raw.Data;
    const series = [];
    if (this.YAxis && this.YAxis.length) {
      for (let i = 0; i < this.YAxis.length; i += 1) {
        const column = this.YAxis[i];
        series.push({
          name: column.Name,
          data: this.GetYAxisData(data, column, activeFilters, drilldown, AllowUserDrillDown),
          color: this.GetChartColors(i),
          marker: {
            symbol: 'circle',
          },
        });
      }
    }
    return series;
  };

  GetYAxisData=(data:ObjModel.Obj[], column:ReportResponseModel.IColumn, activeFilters:ObjModel.ObjGeneric<FilterModel.IFilterResponse>, drilldown:Function, AllowUserDrillDown:boolean) => {
    const yAXisData = data.map((d: ObjModel.Obj) => {
      const xColumn = this.XAxis[0];
      if (xColumn && xColumn.Props && xColumn.Props.Action && xColumn.Props.Action.Type && xColumn.Props.Action.Type === ColumnActionTypes.Drilldown) {
        const metadata = xColumn.Props.Action.Meta as ReportResponseModel.IColumnActionDrilldownMeta;
        if (
          metadata
        && metadata.DrilldownChanges
        && metadata.DrilldownChanges.Changes
        ) {
          if (metadata.DrilldownChanges.Type === DrilldownChangesTypes.FilterOnly) {
            for (let i = 0; i < metadata.DrilldownChanges.Changes.length; i += 1) {
              const change = metadata.DrilldownChanges.Changes[i];
              if (change.Type === DrilldownChangeTypes.Filter) {
                const changeMeta = change.ChangeMeta as IColumnActionDrilldownChangeMeta;
                if (changeMeta) {
                  const filterToChange = changeMeta.FilterID;
                  if (changeMeta.TakeValueFrom && changeMeta.TakeValueFrom.length) {
                    if (activeFilters[filterToChange]?.FilterResponse === d[changeMeta.TakeValueFrom]) {
                      return {
                        y: getFormattedValues(d[column.Name], column.Props.Formatter),
                        className: 'disable-click',
                        events: {
                          click: '',
                        },
                      };
                    }
                  }
                }
              }
            }
          }
        }
      }
      return {
        y: getFormattedValues(d[column.Name], column.Props.Formatter),
        className: 'enable-click',
        events: {
          click: AllowUserDrillDown ? drilldown : '',
        },
      };
    });

    return yAXisData;
  }

  GetChartColors=(color:number) => {
    // eslint-disable-next-line default-case
    const chartColors = this.data.reportConfig.Visualization.StyleConfig ? this.data.reportConfig.Visualization.StyleConfig.Colors : null;
    switch (this.data.reportConfig.Visualization.Type) {
      case VisualizationTypes.ColumnChart: {
        // below values will be from config line {this.data.reportConfig.Visualization.chartcolors}
        const colors = chartColors || Chartcolors.ColumnChart;
        return {
          linearGradient: {
            x1: 1,
            x2: 1,
            y1: 1,
            y2: 0,
          },
          stops: [
            [0, colors[color][0]],
            [1, colors[color][1]],
          ],
          opacity: '35%',
        };
      }
      case VisualizationTypes.BarChart: {
        const colors = chartColors || Chartcolors.BarChart;
        return {
          linearGradient: {
            x1: 1,
            x2: 1,
            y1: 1,
            y2: 0,
          },
          stops: [
            [0, colors[color][0]],
            [1, colors[color][1]],
          ],
          opacity: '100%',
        };
      }
      case VisualizationTypes.AreaChart: {
        const colors = chartColors || Chartcolors.AreaChart;
        return {
          linearGradient: {
            x1: 1,
            x2: 1,
            y1: 1,
            y2: 0,
          },
          stops: [
            [0, colors[color][0]],
            [1, colors[color][1]],
          ],
          opacity: '35%',
        };
      }
      case VisualizationTypes.LineChart: {
        const colors = chartColors || Chartcolors.LineChart;
        return colors[color];
      }
      default: {
        const colors = chartColors || Chartcolors.Default;
        return colors[color];
      }
    }
  }

  GetPreviousDrilldownValue = (prev: number) => {
    const isParent = !this.data.prevAppliedFilters.length;
    if (!isParent && this.data.prevAppliedFilters[prev] && this.XAxis && this.XAxis.length) {
      const drilldownIndex = this.XAxis.findIndex(
        (c) => c.Props && c.Props.Action && c.Props.Action.Type === ColumnActionTypes.Drilldown,
      );
      if (drilldownIndex > -1) {
        const column = this.XAxis[drilldownIndex];
        const metadata = column.Props.Action.Meta as ReportResponseModel.IColumnActionDrilldownMeta;
        if (
          metadata
            && metadata.DrilldownChanges
            && metadata.DrilldownChanges.Changes
        ) {
          if (metadata.DrilldownChanges.Type === DrilldownChangesTypes.FilterOnly) {
            for (
              let i = 0;
              i < metadata.DrilldownChanges.Changes.length;
              i += 1
            ) {
              const change = metadata.DrilldownChanges.Changes[i];
              if (change.Type === DrilldownChangeTypes.Filter) {
                const changeMeta = change.ChangeMeta as ReportResponseModel.IColumnActionDrilldownChangeMeta;
                if (changeMeta) {
                  const filterToChange = changeMeta.FilterID;
                  const prevValue = this.data.prevAppliedFilters[prev][filterToChange];
                  if (
                    prevValue
                      && prevValue.Hidden
                      && typeof prevValue.Hidden === 'string'
                      && prevValue.Hidden.length
                  ) {
                    return prevValue.Hidden;
                  }
                }
              }
            }
          }
        }
      }
    }
    return 'Back';
  };

  GetRawColums = ():Array<GridModel.IColumnConfig> => getColumnsProperties(
    this.data.columns,
    this.data.reportConfig.AllowDrilldownOnMaskedDimensions,
  );

  GetRawDataConfig = (activeDimensions: Array<ObjModel.Obj>):
      {
        rowData: Array<ObjModel.Obj>,
        columns: Array<GridModel.IColumnConfig>,
      } => {
    const columns = this.GetRawColums();
    const rowData:Array<ObjModel.Obj> = [];
    if (this.data.reportData?.Raw?.Data && this.data.reportData?.Raw?.Data.length) {
      const reportData = this.data.reportData.Raw.Data;
      for (let i = 0; i < this.data.reportData?.Raw?.Data.length; i += 1) {
        const data = reportData[i];
        rowData.push({
          Fields: columns.map((c) => {
            const idx = activeDimensions.findIndex((dim) => dim.Applied !== DimensionStatus.NotApplied
            && ((dim.BuilderConfig && dim.BuilderConfig.IsDynamicField && c.colId === dim.BuilderConfig.Alias)
              || (c.colId === (dim.ReferTo || dim.Name))) && !data[c.colId]);
            return ({
              Name: c.colId,
              Value: idx === -1 ? data[c.colId] : DimensionNoValue,
            });
          }),
        });
      }
    }

    return { rowData, columns };
  };

  GetChartType = () => {
    switch (this.data.reportConfig.Visualization.Type) {
      case VisualizationTypes.ColumnChart:
      default:
        return 'column';
      case VisualizationTypes.LineChart:
        return 'line';
      case VisualizationTypes.AreaChart:
        return 'area';
      case VisualizationTypes.BarChart:
        return 'bar';
    }
  };

  GetChartOptions = (categories: any, series: any, AllowUserDrillDown:boolean) => ({
    chart: {
      type: this.GetChartType(),
    },
    xAxis: {
      categories,
      crosshair: true,
      type: 'category',
      labels: {
        useHTML: true,
        style: { // added label with width
          width: 100,
          textOverflow: 'ellipsis',
        },
        formatter() {
          const { value } = this as any;
          return extractValFromStrWithSeparator(value);
        },
      },
    },
    yAxis: {
      min: 0,
      gridLineDashStyle: 'longdash',
      title: {
        text: this.builder.YAxis.label,
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:8px;color:#454F5B;font-family:SemiBold" >{point.key}</span><table>',
      pointFormat: '<tr><td style="min-width:15px;border-radius:50%;background:{series.color};color: {series.color};"></td><td style="color:#637381;padding:0;font-size:8px;font-family:Regular;">{series.name}: </td>'
            + '<td style="padding:0;color:#637381;font-size:8px;font-family:Regular;">{point.y}</td></tr>',
      footerFormat: '</table>',
      borderColor: '#006FD6',
      backgroundColor: '#FFFFFF',
      shared: true,
      useHTML: true,
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemMarginTop: 10,
      itemMarginBottom: 10,
      width: '10%',
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0.1,
        cursor: AllowUserDrillDown ? 'pointer' : 'not-allowed',
      },

    },
    series,
  })

  static BaseTheme : Highcharts.Options = {
    // colors: ['#015CCD', '#5592DD', '#AAC8EE'],
    chart: {
      backgroundColor: '#0000',
    },
    title: {
      style: {
        color: '#4D5A6D',
        font: '12px "SemiBold"',
      },
      align: 'left',
    },
    subtitle: {
      style: {
        color: 'var(--base-font-color)',
        font: '12px "Regular"',
      },
    },
    legend: {
      itemStyle: {
        font: '10px Regular',
        color: '#4D5A6D',
        cursor: 'pointer',
      },
      itemHoverStyle: {
        color: 'gray',
      },
      verticalAlign: 'top',
      align: 'right',
    },
  };
}
