import React from 'react';
import StyledComponent from 'components/feature/ReportListing/ReportHeader/ReportSorting/report-sorting.style';

const ReportSortingLoader = () => (
  <StyledComponent>
    <div
      className="se-shimmer"
    />
  </StyledComponent>
);

export default ReportSortingLoader;
