/* eslint-disable import/prefer-default-export */
/* eslint-disable no-shadow */
export enum URLHash {
    Report = 'report',
}

export enum SIERAPage {
    LISTING = 'report-list',
    REPORT='report',
    DASHBOARD='dashboard',
    UNAUTHORIZED='unauthorized',
}
