import React from 'react';

type Props = {
    width: number;
    height: number;
    fill?: string;
}

const ColumnGroupingIcon = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.4501 2.65059V5.92259L13.5501 5.92259V2.65059L4.4501 2.65059ZM4.4501 6.97259V10.2276L13.5501 10.2276L13.5501 6.97259L4.4501 6.97259ZM4.4501 11.2776V14.5506L13.5501 14.5506V11.2776L4.4501 11.2776ZM3.4001 2.65059C3.40694 2.37426 3.51976 2.11115 3.71521 1.9157C3.91066 1.72025 4.17378 1.60743 4.4501 1.60059L13.5501 1.60059C13.8264 1.60743 14.0895 1.72025 14.285 1.9157C14.4804 2.11115 14.5933 2.37426 14.6001 2.65059L14.6001 14.5506C14.5933 14.8269 14.4804 15.09 14.285 15.2855C14.0895 15.4809 13.8264 15.5937 13.5501 15.6006L4.4501 15.6006C4.17378 15.5937 3.91066 15.4809 3.71521 15.2855C3.51976 15.09 3.40694 14.8269 3.4001 14.5506L3.4001 2.65059Z"
      fill={fill}
    />
    <rect
      x="13.6001"
      y="2.60059"
      width="3.33"
      height="9.2"
      transform="rotate(90 13.6001 2.60059)"
      fill="#116FD6"
      fillOpacity="0.5"
    />
  </svg>

);

ColumnGroupingIcon.defaultProps = {
  fill: '#454F5B',
};

export default ColumnGroupingIcon;
