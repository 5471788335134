/* eslint-disable no-shadow */
import React from 'react';

export interface IDraggableItemProps<T> {
  draggableId: string;
  text: string; // display text
  index: number; // index for Draggable component
  data: T; // custom data to return on clicking close button
  state?: DraggableItemState; // defines which icons and border colours to show
  isDragDisabled?: boolean; // disable dragging of item
  showCloseButton?: boolean; // whether to show the close button
  isCloseButtonDisabled?: boolean; // whether the close button is shown but disabled
  disabledCloseButtonOverlay?: TooltipOverlay; // overlay for disabled close button tooltip
  infoOverlay?: TooltipOverlay; // overlay for an info tooltip
  infoOverlayClassName?: string;
  className?: string; // class name passed when wrapped with a custom styled component;
  textTooltipContent?: string;
  borderClassName?: string;
  addBorderClass?: string;
  onClickClose?(data: T);
  entity: string;
  binningType?: string;
}

export enum DraggableItemState {
  Default, // item is at rest
  Dragging, // item is being dragged
  AboutToBeAdded, // item is hovering over a droppable section
  AboutToBeCombined, // item is hovering over another item
  DropDisallowed, // item is not allowed to be dropped
}

export const StateToClassMapping = {
  [DraggableItemState.Dragging]: 'se-blue-border',
  [DraggableItemState.AboutToBeAdded]: 'se-green-border',
  [DraggableItemState.AboutToBeCombined]: 'se-yellow-border',
  [DraggableItemState.DropDisallowed]: 'se-red-border',
};

type TooltipOverlay = (() => React.ReactNode) | React.ReactNode;
