import styled from 'styled-components';

const StyledSearchBar = styled.div`
width:100%;
height:100%;

.se-sidebar-showcase-search {
    padding: 12px;
    background:  var(--noDataBackground);
}

.se-sidebar-search {
    position: relative;
    box-sizing: border-box;
    width: 209px;
    height: 32px;
    border: 1px solid #DFE3E8;
    border-radius: 2px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
}

div.se-sidebar-search:hover {
    border: 1px solid #116FD6;
}


.se-sidebar-input {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size:13px;
    letter-spacing: 0px;
    color: #454F5B;
    opacity: 1;
    width: 100%;
    height: 100%;
    
    /* removing border of input tag */
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
}

input::placeholder {
    color: #ADBAC7;
}

input[type="text" i] {
    padding: 0px 0px 0px 12px;
}

.se-sidebar-clear {
    .se-icon-cn {
        position: absolute;
        right: 12px;
        top: 8px;
        z-index: 100;
        cursor: pointer;
    }

    .se-icon-cn.hide {
        display: none;
    }
}

.se-search-divider{ 
    border-top: 1px solid #CAD3DB;
    margin: 0px 12px;
    opacity: 0.25;
}
`;

export default StyledSearchBar;
