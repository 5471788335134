import React from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from 'redux/report/actions';
import Tooltip from 'rc-tooltip';
import QueryString from 'query-string';
import { IProps } from 'components/feature/Report/ReportHeader/report-header.d';
import StyledComponent from 'components/feature/Report/ReportHeader/report-header.style';
import StoreConnector from 'components/feature/Report/ReportHeader/report-header.redux';
import PrevIcon from 'components/common/svg/Prev/prev.svg';
import { ObjModel } from 'core/models';
import { DimensionStatus, MaskedMessage } from 'core/constants/report';
import LockIcon from 'components/common/svg/Lock/lock.svg';
import { IFilterConfig } from 'core/models/report-response';
import { IDimension } from 'core/models/report-redux';
import { mouseEnterDelay, PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';
import ExportButtons from 'components/feature/Report/ReportHeader/ExportButtonsComponent/export-buttons-component';
import store from 'redux/store';

const ReportHeader = (props: IProps) => {
  const {
    reduxState, reduxAction, setOpenPannel, ...rest
  } = props;
  const { report } = reduxState;
  const isV1Report = store.getState().auth.isV1Report;

  const {
    reportConfig, autodrilldownData, activeDimensions, filterConfig,
  } = report;
  const { resetState } = reduxAction;
  const { history, location } = rest;
  const queryParams: ObjModel.Obj = QueryString.parse(location.search);

  const dispatch = useDispatch();
  const isAutodrilldown = !!(autodrilldownData && autodrilldownData.reportData);

  const isUserDrilldown = !!reportConfig && reportConfig.AllowUserDrillDown
  && !!report.prevAppliedFilters && !!report.prevAppliedFilters.length;

  const maskedFilter = filterConfig.find((filter: IFilterConfig) => filter.IsMasked);
  const maskedField = activeDimensions.find(
    (field: IDimension) => field.Applied !== (DimensionStatus.NotApplied) && field.IsMasked,
  );
  const permissionTemplateApplied = (maskedFilter || maskedField);
  const onBackButtonClickHandler = () => {
    if (isAutodrilldown) {
      dispatch(Actions.autodrilldown(null, null));
    } else {
      resetState();
      history.push(`/${queryParams && queryParams.referral
        ? `#${queryParams.referral}` : ''}`);
    }
  };

  return (
    <StyledComponent
      className={`${isV1Report ? 'v1ReportStyle' : ''}`}
    >
      <div
        className="se-report-header"
      >
        <div
          className="se-header-container"
        >
          <div
            className="se-report-header-label"
          >
            {reportConfig.Details.Title}
            {permissionTemplateApplied
            && (
            <div className="se-permission-template-cn">
              <Tooltip
                placement="bottom"
                overlay={(
                  <span>
                    {MaskedMessage.HeaderTooltip}
                  </span>
                )}
                mouseEnterDelay={mouseEnterDelay}
                overlayClassName="se-restricted-field"
              >
                <div className="se-icon-cn">
                  <LockIcon
                    width={20}
                    height={20}
                  />
                </div>
              </Tooltip>
              <div className="se-permission-template-label">
                {MaskedMessage.Header}
              </div>
            </div>
            )}
          </div>
          <div
            className="se-report-sub-header-label"
          >
            {reportConfig.Details.Description}
          </div>
        </div>
        <div
          className="se-report-top-button-containers"
        >

          <>
            <div
              className="se-new-report-button-container"
            >
              <div className={`se-export-dropdown ${reportConfig.IsExportable ? '' : 'se-dropdown-hidden'}`}>
                <ExportButtons setOpenPannel={setOpenPannel} />
              </div>

              {(!isV1Report || isAutodrilldown) ? (
                <>
                  <div className={`se-header-divider ${reportConfig.IsExportable ? '' : 'se-divider-hidden'}`} />
                  <Tooltip
                    placement="left"
                    overlay={<span>Back</span>}
                    mouseEnterDelay={1.5}
                  >
                    <button
                      className={`se-new-header-button ${PendoClassNames.BackBtn}`}
                      type="button"
                      onClick={onBackButtonClickHandler}
                    >
                      <PrevIcon
                        width={16}
                        height={16}
                      />
                      <span
                        className="se-new-report-button-text"
                      >
                        Back
                      </span>
                    </button>
                  </Tooltip>
                </>
              ) : null}
            </div>

          </>
        </div>
      </div>
    </StyledComponent>
  );
};

export default StoreConnector()(ReportHeader);
