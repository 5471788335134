import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const NoFieldsIcon = ({
  width, height, fill,
}: Props) => (

  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 36.308 37.284">
    <defs>
      <filter id="Path_10184" x="5.978" y="0" width="28.472" height="37.284" filterUnits="userSpaceOnUse">
        <feOffset dy="3" in="SourceAlpha" />
        <feGaussianBlur result="blur" />
        <feFlood floodColor="#fff" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_22409" data-name="Group 22409" transform="translate(-922 -388.954)">
      <path id="list_FILL0_wght400_GRAD0_opsz48" d="M14.573,17.026V14H42.308v3.026Zm0,8.573V22.573H42.308V25.6Zm0,8.573V31.145H42.308v3.026ZM7.513,17.026a1.513,1.513,0,1,1,0-3.026,1.513,1.513,0,1,1,0,3.026Zm0,8.573a1.513,1.513,0,1,1,0-3.026,1.513,1.513,0,1,1,0,3.026Zm0,8.573a1.513,1.513,0,1,1,0-3.026,1.513,1.513,0,1,1,0,3.026Z" transform="translate(916 383.006)" fill="#919eab" />
      <g transform="matrix(1, 0, 0, 1, 922, 388.95)" filter="url(#Path_10184)">
        <path id="Path_10184-2" data-name="Path 10184" d="M8519.935-7684.452l18.518,22.768,8.405,10.252" transform="translate(-8513.18 7685.08)" fill={fill} stroke="#919eab" strokeWidth="2" />
      </g>
    </g>
  </svg>

);

NoFieldsIcon.defaultProps = {
  fill: '#919eab',
};

export default NoFieldsIcon;
