import React from 'react';
import StyledFullScreenLoader from 'components/common/loader/FullscreenLoader/fullscreen-loader.styled';
import { IProps } from 'components/common/loader/FullscreenLoader/fullscreen-loader.d';

const FullScreenLoader = (props: IProps) => {
  const { isRelative, style } = props;
  return (
    <StyledFullScreenLoader>
      <div className={`se-fullscreen-loader-wrapper ${isRelative ? 'relative' : ''}`} style={style}>
        <div className="se-fullscreen-loader-bars-wrapper">
          <span className="se-fullscreen-loader-bars-1" />
          <span className="se-fullscreen-loader-bars-2" />
          <span className="se-fullscreen-loader-bars-3" />
          <span className="se-fullscreen-loader-bars-4" />
        </div>
        Loading...
      </div>
    </StyledFullScreenLoader>
  );
};

export default FullScreenLoader;
