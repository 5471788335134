import React from 'react';
import EmptyListIcon from 'components/common/svg/EmptyList/empty-lsit.svg';
import StyledEmptyState from './empty-state.style';
import { IEmptyStateProps } from './empty-state.d';

const EmptyStateScreen = (props:IEmptyStateProps) => {
  const { message } = props;
  return (
    <StyledEmptyState>
      <div>
        <div className="se-empty-list-icon">
          <EmptyListIcon
            width={24}
            height={22}
          />
        </div>
        <div className="se-empty-state-text">
          {message}
        </div>
      </div>

    </StyledEmptyState>
  );
};

export default EmptyStateScreen;
