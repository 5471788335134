import React from 'react';
import { IProps } from 'components/common/loader/LinearBar/linear-bar.d';
import StyledContainer from 'components/common/loader/LinearBar/linear-bar.style';
import { LinearBarColorTypes } from 'core/constants/loader';

const LinearBar = (props: IProps) => {
  const {
    show, isBottomPlaced = false, barColor = LinearBarColorTypes.Primary, isCompleted,
    size, time,
  } = props;
  return (
    <StyledContainer
      animationTime={time}
    >
      <div
        className={`se-linear-bar-loader ${show && barColor === LinearBarColorTypes.Primary ? 'showPrimary' : show || ''} 
          ${isBottomPlaced ? 'bottom-placed' : ''} ${barColor} 
          ${isCompleted ? 'full' : ''} ${size || ''}`}
      />
    </StyledContainer>
  );
};

export default LinearBar;
