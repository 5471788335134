import React from 'react';
import {
  BrowserRouter, Switch, Route,
} from 'react-router-dom';
import { ProtectedRoute } from 'components/common';
import Main from 'routes/main/main.route';
import Report from 'routes/report/report.route';
import Dashboard from 'routes/dashboard/dashboard.route';
import Unauthorized from 'routes/unauthorized/unauthorized.route';

const Routes = () => (
  <>
    <BrowserRouter>
      <Switch>
        <ProtectedRoute
          path="/report/:reportId"
          component={Report}
        />
        <Route
          path="/dashboard"
          exact
          component={Dashboard}
        />
        <Route
          path="/unauthorized"
          component={Unauthorized}
        />
        <ProtectedRoute
          path="/"
          component={Main}
        />
      </Switch>
    </BrowserRouter>
  </>
);

export default Routes;
