import styled from 'styled-components';

const StyledContainer = styled.div`
width: auto;

.se-report-view-info {
    position: relative;
    cursor: normal;

    :hover {
        .se-info {
            display: block;
        }
    }

    .se-info-icon {
        padding: 0;
        border: 0;
        :hover, :focus {
            .icon-stroke {
                fill: #454F5B !important;
            }
        }
    }

    .se-info {
        width: 158px;
        height: 300px;
        box-shadow: 0px 2px 6px #212B3633;
        background: #fff;

        .se-info-details {
            width: 100%;
            height: 100%;
            padding: 10px 12px;
            box-sizing: border-box;
            overflow: auto;
        }

        .se-filter-header {
            font-family: 'Regular';
            font-size: 11px;
            color: #919EAB;
        }

        .se-filter {
            font-family: Regular;
            font-size: 11px;
            color: #454F5B;
            margin-bottom: 8px;

            :last-child {
                margin: 0;
            }

            .se-filter-label {
                color: #919EAB;
            }

            .se-filter-value {
                word-break: break-all;
                width: 100%;
                margin-top: 4px;
            }
        }

        .se-setting-item {
            font-family: Regular;
            font-size: 11px;
            color: #454F5B;
            margin-bottom: 8px;

            :last-child {
                margin: 0;
            }

            .se-setting-label {
                color: #919EAB;
            }

            .se-setting-value {
                margin-top: 4px;
                word-break: break-all;
            }
        }

        .se-info-divider {
            margin: 12px 0;
            border-top: 1px solid #919EAB27;
        }

        .se-sorting-info {
            font-family: Regular;
            font-size: 11px;
            color: #454F5B;

            .se-sorting-label {
                color: #919EAB;
            }

            ol {
                margin: 0;
                padding-left: 10px;
            }

            .se-sorting-value {
                margin-top: 4px;
            }
        }
    }
}

.tooltip-container {
    padding: 0;
    .se-info-details {
        pointer-events: all !important;
    }
}
`;

export default StyledContainer;
