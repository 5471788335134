import styled from 'styled-components';

const StyledContainer = styled.div`
width: 100%;
height: 32px;
min-height: 32px;

.se-filter-container {
    border: 1px solid #DFE3E8;
    padding: 5px 8px 5px 12px;
    background-color: #F9FAFB;
    border-radius: 2px;
    display: flex;

    :hover {
        border-color: #F5802A;
    }

    .se-item-name {
        flex: 1;
        font-family: Regular;
        color: #ADBAC7;
        font-size: 13px;
        overflow: hidden;
        white-space: nowrap;
    }
    
    .se-lock-icon { 
        padding: 0 0 0 8px;
    }
}

.se-remove-red-border {
    border-color: #D1331B !important;
  }

`;

export default StyledContainer;
