import styled from 'styled-components';

const StyledFullScreenLoader = styled.div`
.se-fullscreen-loader-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: var(--base-white);
  top: 0;
  left:0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: SemiBold;
  z-index: 4;
}

.se-fullscreen-loader-bars-wrapper {
  height: 120px;
  width: 150px;
  border-left: 2px solid #a9b3bf;
  border-bottom: 2px solid #a9b3bf;
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}

.se-fullscreen-loader-bars-wrapper > span {
  width: 30px;
  height: 300px;
  background-color: var(--base-font-color);
  margin: 0 5px;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}


.se-fullscreen-loader-wrapper.relative {
  position: absolute;
}

span.se-fullscreen-loader-bars-1 {
  margin-left: 10px;
  background-color: var(--fullLoaderBar1);
  animation-name: bar1;
}
span.se-fullscreen-loader-bars-2 {
  background-color: var(--fullLoaderBar2);
  animation-name: bar2;
}
span.se-fullscreen-loader-bars-3 {
  background-color: var(--fullLoaderBar3);
  animation-name: bar3;
}
span.se-fullscreen-loader-bars-4 {
  background-color: var(--fullLoaderBar4);
  animation-name: bar4;
}

@keyframes bar1 {
  from, to {
    height: 50px;
  }
  50% {
    height: 99px;
  }
}
@keyframes bar2 {
  from, to {
    height: 10px;
  }
  45% {
    height: 60px;
  }
}
@keyframes bar3 {
  from, to {
    height: 30px;
  }
  55% {
    height: 90px;
  }
}
@keyframes bar4 {
  from, to {
    height: 45px;
  }
  70% {
    height: 85px;
  }
}
`;
export default StyledFullScreenLoader;
