import React from 'react';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';
import { ExpandListButtonContext } from 'core/constants/report';
import MinusIcon from '../svg/Minus/minus-icon.svg';
import PlusIcon from '../svg/Plus/plus-icon.svg';
import StyledContainer from './expand-list-button.styled';
import { IProps } from './expand-list-button.types';

const ExpandListButton = (props: IProps) => {
  const {
    label, isExpanded, onClickToggler, clickSource,
  } = props;

  const getPendoClassNames = () => {
    switch (clickSource) {
      case ExpandListButtonContext.Filters: {
        return PendoClassNames.AvailableFiltersBtn;
      }
      case ExpandListButtonContext.RowGroup: {
        return PendoClassNames.RowGrouping;
      }
      case ExpandListButtonContext.ColGroup: {
        return PendoClassNames.ColGrouping;
      }
      case ExpandListButtonContext.Drilldown: {
        return PendoClassNames.ExpandDrilldown;
      }
      default: {
        return '';
      }
    }
  };
  return (
    <StyledContainer>
      <button
        type="button"
        onClick={() => {
          onClickToggler();
        }}
        className={`se-fields-btn ${getPendoClassNames()}`}
      >
        <div
          className="se-fields-icon"
        >
          {isExpanded
            ? (
              <MinusIcon
                width={8}
                height={8}
              />
            )
            : (
              <PlusIcon
                width={8}
                height={8}
              />
            )}
        </div>
        <div className={`se-fields-label ${isExpanded ? 'expand' : ''}`}>
          {label}
        </div>
      </button>
    </StyledContainer>
  );
};

export default ExpandListButton;
