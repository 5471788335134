import React from 'react';

type Props = {
  width: number;
  height: number;
}

const NoDataIcon = ({
  width, height,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 333 83">
    <defs>
      <filter id="Rectangle_6509" x="0" y="0" width={width} height={height} filterUnits="userSpaceOnUse">
        <feGaussianBlur stdDeviation="1.5" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <linearGradient id="linear-gradient" x1="1.442" y1="3.195" x2="0" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#dfe3e8" />
        <stop offset="1" stopColor="#f4f6f8" />
      </linearGradient>
    </defs>
    <g id="Group_19743" data-name="Group 19743" transform="translate(-391.5 -190.5)">
      <g transform="matrix(1, 0, 0, 1, 391.5, 190.5)" filter="url(#Rectangle_6509)">
        <rect id="Rectangle_6509-2" data-name="Rectangle 6509" width="324" height="74" transform="translate(4.5 4.5)" fill="#fff" />
      </g>
      <path id="Path_8357" data-name="Path 8357" d="M16-2461.714H339.613V-2480H16Z" transform="translate(379.193 2693)" fill="#fff" />
      <path id="Path_8359" data-name="Path 8359" d="M16-2461.714H339.807V-2480H16Z" transform="translate(379.193 2711.5)" fill="#fff" />
      <path id="Path_8364" data-name="Path 8364" d="M16-2480H339.807v18.286H16Z" transform="translate(379.193 2729.785)" fill="#fff" />
      <path id="Path_8355" data-name="Path 8355" d="M16-2503H339.807v18.286H16Z" transform="translate(379.193 2697.357)" fill="#fff" />
      <line id="Line_2382" data-name="Line 2382" y2="11.429" transform="translate(444.285 198.071)" fill="none" stroke="#637381" strokeWidth="1" opacity="0.1" />
      <line id="Line_2381" data-name="Line 2381" y2="11.429" transform="translate(504.857 198.071)" fill="none" stroke="#637381" strokeWidth="1" opacity="0.1" />
      <line id="Line_2380" data-name="Line 2380" y2="11.429" transform="translate(571.143 198.071)" fill="none" stroke="#637381" strokeWidth="1" opacity="0.1" />
      <line id="Line_2378" data-name="Line 2378" y2="11.429" transform="translate(620.857 197.571)" fill="none" stroke="#637381" strokeWidth="1" opacity="0.1" />
      <line id="Line_2383" data-name="Line 2383" y2="11.429" transform="translate(672.857 197.571)" fill="none" stroke="#637381" strokeWidth="1" opacity="0.1" />
      <rect id="Rectangle_6679" data-name="Rectangle 6679" width="28" height="12" transform="translate(403 198)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6680" data-name="Rectangle 6680" width="28" height="12" transform="translate(403 216)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6681" data-name="Rectangle 6681" width="28" height="12" transform="translate(403 235)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6682" data-name="Rectangle 6682" width="28" height="12" transform="translate(403 254)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6675" data-name="Rectangle 6675" width="28" height="12" transform="translate(461 197)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6676" data-name="Rectangle 6676" width="28" height="12" transform="translate(461 215)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6677" data-name="Rectangle 6677" width="28" height="12" transform="translate(461 234)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6678" data-name="Rectangle 6678" width="28" height="12" transform="translate(461 253)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6671" data-name="Rectangle 6671" width="28" height="12" transform="translate(524 198)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6672" data-name="Rectangle 6672" width="28" height="12" transform="translate(524 216)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6673" data-name="Rectangle 6673" width="28" height="12" transform="translate(524 235)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6674" data-name="Rectangle 6674" width="28" height="12" transform="translate(524 254)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6667" data-name="Rectangle 6667" width="28" height="12" transform="translate(583 198)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6665" data-name="Rectangle 6665" width="28" height="12" transform="translate(583 216)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6666" data-name="Rectangle 6666" width="28" height="12" transform="translate(583 235)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6668" data-name="Rectangle 6668" width="28" height="12" transform="translate(583 254)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6660" data-name="Rectangle 6660" width="28" height="12" transform="translate(633 197.5)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6683" data-name="Rectangle 6683" width="28" height="12" transform="translate(685 197.5)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6661" data-name="Rectangle 6661" width="28" height="12" transform="translate(633 215.5)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6684" data-name="Rectangle 6684" width="28" height="12" transform="translate(685 215.5)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6662" data-name="Rectangle 6662" width="28" height="12" transform="translate(633 234.5)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6685" data-name="Rectangle 6685" width="28" height="12" transform="translate(685 234.5)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6669" data-name="Rectangle 6669" width="28" height="12" transform="translate(633 253.5)" fill="url(#linear-gradient)" />
      <rect id="Rectangle_6686" data-name="Rectangle 6686" width="28" height="12" transform="translate(685 253.5)" fill="url(#linear-gradient)" />
    </g>
  </svg>

);

export default NoDataIcon;
