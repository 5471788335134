import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const CloseIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 16 16"
  >
    <g
      id="close-24px_1_"
      data-name="close-24px (1)"
      opacity="0.5"
    >
      <path
        id="Path_4852"
        data-name="Path 4852"
        d="M0,0H16V16H0Z"
        fill="none"
      />
      <path
        id="Path_4853"
        className="icon-stroke"
        data-name="Path 4853"
        d="M14.749,5.982,13.767,5,9.874,8.893,5.982,5,5,5.982,8.893,9.874,5,13.767l.982.982,3.893-3.893,3.893,3.893.982-.982L10.856,9.874Z"
        transform="translate(-1.874 -1.874)"
        fill={fill}
      />
    </g>
  </svg>
);

CloseIcon.defaultProps = {
  fill: '#262626',
};

export default CloseIcon;
