import React, { useEffect, useState } from 'react';
import {
  dimensionItemBottomMarginPx,
  dimensionItemHeightPx,
  DroppableIds, DroppableTypes,
} from 'components/feature/Report/ReportSidebar/common/constants';
import { ReduxModel } from 'core/models';
import { Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import DraggableItem from 'components/feature/Report/ReportSidebar/common/DraggableItem/draggable-item.component';
import { DimensionItemWarning, DimensionStatus } from 'core/constants/report';
import * as Actions from 'redux/report/actions';
import StyledContainer from 'components/feature/Report/ReportSidebar/Settings/Dimensions/RowGrouping/row-grouping.style';
import { DraggableItemState } from 'components/feature/Report/ReportSidebar/common/DraggableItem/draggable-item.d';
import { getFieldDisplayName, getFieldIdentifier, getTextTooltipContentForField } from 'core/utils/report.util';
import { checkDrilldownLimits } from 'components/feature/Report/ReportSidebar/common/helpers';
import { IDrilldownGroupingProps, IColumnWithIndex } from 'components/feature/Report/ReportSidebar/Settings/DrilldownDimensions/DrilldownGrouping/drilldown-grouping.d';

const DrilldownGrouping = (props: IDrilldownGroupingProps) => {
  const {
    selectedDimension, dimensionState,
    isMaxDrilldownGroupingReached, replaceIndex, newAdditionIndex,
  } = props;
  const [
    autodrilldownData,
  ] = useSelector((state: ReduxModel.IGlobalState) => [
    state.report.autodrilldownData, state.report.reportConfig,
  ] as const);
  const dispatch = useDispatch();
  const removeDrilldownFields = (index:number) => {
    dispatch(Actions.setGroupingDrilldown(index, DimensionStatus.NotApplied));
  };
  const [minHeight, setMinHeight] = useState(dimensionItemHeightPx + dimensionItemBottomMarginPx);

  const groupedDimensions = autodrilldownData.drilldownFields && autodrilldownData.drilldownFields
    .map((dim, index) => ({ ...dim, originalIndex: index } as IColumnWithIndex))
    .filter((dim) => dim.Props.Dimension && dim.Props.Dimension.Applied === DimensionStatus.Applied);

  const {
    isMinDrilldownGroupingReached,
    minDrilldownGroupingLimit,
  } = checkDrilldownLimits(groupedDimensions && groupedDimensions);

  useEffect(() => {
    // set minimum height based on number of items, their margins
    let newMinHeight = groupedDimensions && groupedDimensions.length * (dimensionItemHeightPx + dimensionItemBottomMarginPx);
    if (newMinHeight >= dimensionItemBottomMarginPx) newMinHeight -= dimensionItemBottomMarginPx;
    setMinHeight(newMinHeight);
  }, [groupedDimensions && groupedDimensions.length, isMaxDrilldownGroupingReached]);

  return (
    <Droppable
      droppableId={DroppableIds.DrilldownGrouping}
      type={DroppableTypes.Measures}
      isCombineEnabled
    >
      {(droppableProvided) => (
        <StyledContainer
          minHeightPx={minHeight}
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
        >
          {
              groupedDimensions && groupedDimensions
                .map((dim, index) => {
                  const showMinGroupingWarning = isMinDrilldownGroupingReached && dim.Props.Dimension.Applied === DimensionStatus.Applied;
                  const closeButtonDisabled = showMinGroupingWarning;
                  const name = getFieldDisplayName(dim);
                  const key = getFieldIdentifier(dim);
                  return (
                    <DraggableItem
                      key={key}
                      draggableId={key}
                      text={name}
                      data={dim.originalIndex}
                      state={key === selectedDimension ? dimensionState : DraggableItemState.Default}
                      index={index}
                      showCloseButton
                      isCloseButtonDisabled={closeButtonDisabled}
                      disabledCloseButtonOverlay={showMinGroupingWarning && (
                        <span>
                          {
                          DimensionItemWarning.MinGrouping.replace(
                            '{{count}}',
                            minDrilldownGroupingLimit.toString(),
                          )
                        }
                        </span>
                      )}
                      onClickClose={removeDrilldownFields}
                      textTooltipContent={getTextTooltipContentForField(dim)}
                      borderClassName={replaceIndex === dim.originalIndex ? 'se-replace-blue-border' : ''}
                      addBorderClass={newAdditionIndex === dim.originalIndex ? 'se-add-green-border' : ''}
                      entity={dim.BuilderConfig && dim.BuilderConfig.Entity}
                    />
                  );
                })
            }
          {droppableProvided.placeholder}
        </StyledContainer>
      )}
    </Droppable>
  );
};

export default DrilldownGrouping;
