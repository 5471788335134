import styled from 'styled-components';

const StyledContainer = styled.div`
  flex: 1;
  height: 100%;
  overflow: auto;
  position: relative; // for the portal

  ::-webkit-scrollbar {
    width: 6px !important;
  }

  .se-render-report-layout {
    width: 100%;
    height: 100%;
  }

  /* 
  
  :hover::-webkit-scrollbar{
    width: 6px;
    cursor:pointer;
  } 
  
  ::-webkit-scrollbar{
    background-color: transparent;
    width: 6px;
    cursor:pointer;
  } 
  
  :hover::-webkit-scrollbar-track {
      /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  //border-radius:8px;
  /* cursor:pointer;
}

:hover::-webkit-scrollbar-thumb {
background-color:#ADBAC7;
width: 15px;
cursor:pointer;
} 

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
background-color:transparent;
cursor:pointer;
}  */

  .se-grid-container {
    height: 100%;
  }

  .se-basic-table {
    height: 100%;
    background-color: var(--noDataBackground);
    opacity: 1;
  }

  .se-basic-table-autodrilldown{
    height: calc(100% - 40px);
  }

  .track-vertical {
    position: absolute;
    width: 6px !important;
    transition: opacity 200ms ease 0s;
    opacity: 1;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
    background-color: transparent;
  }

  .thumb-vertical {
    background-color: #ADBAC7;
    border-radius: 3px;
    cursor: pointer;
  }

  .track-horizontal {
    position: absolute;
    width: 4px !important;
    transition: opacity 200ms ease 0s;
    opacity: 0;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 3px;
    background-color: transparent;
  }

  .thumb-horizontal {
    background-color: #ADBAC7;
    border-radius: 3px;
    cursor: pointer;
  }

  .se-report-builder {
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    ::-webkit-scrollbar {
      width: 6px !important;
      background-color: transparent;
      border-radius: inherit;
    }

  }

  .se-error-cn {
    height: 400px;
  }

  #autodrilldown {
    .se-icon-cn {
      margin-left: 5px;
    }
    .se-selected-measure-cn {
      font-size: 12px;
      color: var(--paginationColor);
      margin-left: 8px;
      font-family: Regular;

      .se-selected-dimension, .se-selected-measure {
        font-family: SemiBold;
      }
    }
  }
`;

export default StyledContainer;
