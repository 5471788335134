import React from 'react';
import EmptyExportIcon from 'components/common/svg/EmptyExport/EmptyExport.svg';
import { INoExportStateProps } from 'components/feature/Report/ReportSidebar/common/NoExportState/no-export-state.d';
import StyledNoExportState from 'components/feature/Report/ReportSidebar/common/NoExportState/no-export-state.style';

const NoExportScreen = (props:INoExportStateProps) => {
  const { message } = props;
  return (
    <StyledNoExportState>
      <div>
        <div className="se-no-export-icon">
          <EmptyExportIcon
            width={26}
            height={27}
          />
        </div>
        <div className="se-no-export-state-text">
          {message}
        </div>
      </div>

    </StyledNoExportState>
  );
};

export default NoExportScreen;
