import { isEmpty, sortBy } from 'lodash';
import {
  ColumnModes,
  DimensionIdentifier,
  DimensionMode,
  DimensionStatus,
  ExportStatus,
  FieldEntitiesType,
  PreDefinedFieldEntityType,
  ReportViewTypes,
  ReportViewURLTypes,
  ToolbarDisplayMenuItems,
  ToolbarMenuItemTypes,
} from 'core/constants/report';
import { FilterType, UserFilterPayloadType } from 'core/constants/filter';
import {
  FilterModel, ObjModel, ReportReduxModel, ReportResponseModel, ReportToolbarModel,
} from 'core/models';
import FilterIcon from 'components/common/svg/Filter/filter.svg';
import ReportViewsIcon from 'components/common/svg/ReportViews/report-views.svg';
import SettingIcon from 'components/common/svg/Setting/setting.svg';
import ZeroValuesIcon from 'components/common/svg/ZeroValues/zero-values.svg';
import { displayName } from 'components/feature/Report/ReportSidebar/common/helpers';
import LeadIcon from 'components/common/svg/Lead/lead.svg';
import UserIcon from 'components/common/svg/User/User.svg';
import ActivityIcon from 'components/common/svg/Activity/activity.svg';
import OpportunityIcon from 'components/common/svg/Opportunity/opportunity.svg';
import TaskIcon from 'components/common/svg/Task/task.svg';
import ReportViewsPanelIcon from 'components/common/svg/ReportViewsPanel/report-views-panel.svg';
import React from 'react';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';
import FailedIcon from 'components/common/svg/Failed/failed.svg';
import SuccessIcon from 'components/common/svg/Success/success.svg';
import LoadingComponent from 'components/common/LoadingSpinner/loading-component';
import PreDefinedIcon from 'components/common/svg/PreDefined/pre-defined.svg';
import { IColumn } from '../models/report-response';

export const ReportToolbarMenuOptions = [
  {
    id: ToolbarMenuItemTypes.Filters,
    name: ToolbarMenuItemTypes.Filters,
    icon: FilterIcon,
    panelIcon: FilterIcon,
    desc: 'Filter your report data using the below fields.',
    className: PendoClassNames.Filters,
    displayName: ToolbarDisplayMenuItems.Filters,
  },
  {
    id: ToolbarMenuItemTypes.Setting,
    name: ToolbarMenuItemTypes.Setting,
    icon: SettingIcon,
    panelIcon: SettingIcon,
    desc: 'Modify rows and columns to be viewed in your report.',
    className: PendoClassNames.Setting,
    displayName: ToolbarDisplayMenuItems.Setting,
  },
  {
    id: ToolbarMenuItemTypes.ReportViews,
    name: ToolbarMenuItemTypes.ReportViews,
    icon: ReportViewsIcon,
    panelIcon: ReportViewsPanelIcon,
    desc: 'Save your tables, charts & filters settings for easy access.',
    className: PendoClassNames.ReportViews,
    displayName: ToolbarDisplayMenuItems.ReportViews,
  },
  {
    id: ToolbarMenuItemTypes.ZeroValues,
    name: ToolbarMenuItemTypes.ZeroValues,
    icon: ZeroValuesIcon,
    panelIcon: ZeroValuesIcon,
    className: PendoClassNames.ZeroValues,
    displayName: ToolbarDisplayMenuItems.ZeroValues,
  },
] as Array<ReportToolbarModel.IToolbarMenuItem>;

export const getPerosnalisationDataForReportView = (
  filterConfig: Array<ReportResponseModel.IFilterConfig>,
  activeFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>,
  activeDimensions: Array<ReportReduxModel.IDimension>,
  sort: Array<ReportResponseModel.ISortedField>,
) => {
  const data = {} as ReportResponseModel.IPersonalisationObject;
  const filters: ObjModel.Obj = {};
  if (filterConfig && !isEmpty(filterConfig) && activeFilters && !isEmpty(activeFilters)) {
    for (let i = 0; i < filterConfig.length; i += 1) {
      if (activeFilters[filterConfig[i].ID] && !activeFilters[filterConfig[i].ID].Disabled
        && activeFilters[filterConfig[i].ID].FilterResponse) {
        switch (filterConfig[i].Type) {
          case FilterType.GroupMultiSelect:
          case FilterType.LSQMetadataMultiSelect:
          case FilterType.CustomDefinedMultiSelect: {
            if (!isEmpty(activeFilters[filterConfig[i].ID].FilterResponse)) {
              filters[filterConfig[i].ID] = activeFilters[filterConfig[i].ID].FilterOptions.filter(
                (option) => activeFilters[filterConfig[i].ID].FilterResponse.includes(option.value),
              );
            }
            break;
          }

          case FilterType.CustomDefinedSingleSelect:
          case FilterType.LSQMetadataSingleSelect: {
            const selectedOption = activeFilters[filterConfig[i].ID].FilterOptions.find((option) => (
              option.value === activeFilters[filterConfig[i].ID].FilterResponse));
            filters[filterConfig[i].ID] = selectedOption;
            break;
          }

          default:
            if (filterConfig[i].Type === FilterType.UserMultiSelect) {
              if (hasUserFilterValue(activeFilters[filterConfig[i].ID].FilterResponse,
                activeFilters[filterConfig[i].ID].OptionsMeta.Count)) {
                filters[filterConfig[i].ID] = activeFilters[filterConfig[i].ID].FilterResponse;
                // convert value type to ALL.
                switch (activeFilters[filterConfig[i].ID].FilterResponse.Type) {
                  case UserFilterPayloadType.In:
                    if (activeFilters[filterConfig[i].ID].FilterResponse.UserIds.length
                      === activeFilters[filterConfig[i].ID].OptionsMeta.Count) {
                      filters[filterConfig[i].ID] = {
                        Type: UserFilterPayloadType.All,
                        UserIds: [],
                      };
                    }
                    break;
                  case UserFilterPayloadType.NotIn:
                    if (!activeFilters[filterConfig[i].ID].FilterResponse.UserIds.length) {
                      filters[filterConfig[i].ID] = {
                        Type: UserFilterPayloadType.All,
                        UserIds: [],
                      };
                    }
                    break;
                  default:
                    break;
                }
              }
            } else {
              filters[filterConfig[i].ID] = activeFilters[filterConfig[i].ID].FilterResponse;
            }
            break;
        }
      }
    }
  }
  if (!isEmpty(filters)) {
    data.filters = filters;
  }
  if (activeDimensions) {
    const rowGrouping = [] as Array<string>;
    const colGrouping = [] as Array<string>;
    const binning : ObjModel.Obj = {};
    activeDimensions.forEach((item) => {
      if (item.Applied !== DimensionStatus.NotApplied) {
        const dimensionStr = getReportViewDimensionStr(item);
        if (item.DimensionMode !== DimensionMode.ColumnGroup) {
          rowGrouping.push(dimensionStr);
        } else {
          colGrouping.push(dimensionStr);
        }
        if (item.DimensionProp) {
          binning[dimensionStr] = item.DimensionProp.Props.BinningType;
        }
      }
    });
    if (!isEmpty(rowGrouping)) {
      data.rowGrouping = rowGrouping;
    }
    if (!isEmpty(colGrouping)) {
      data.colGrouping = colGrouping;
    }
    data.binning = binning;
  }

  if (sort && !isEmpty(sort)) {
    data.sort = sort;
  }
  return data;
};

export const hasUserFilterValue = (
  filterResponse: FilterModel.IUserFilterPayload, count: number,
) => {
  if (count) {
    switch (filterResponse.Type) {
      case UserFilterPayloadType.All:
        return true;
      case UserFilterPayloadType.In:
        if (filterResponse.UserIds.length) {
          return true;
        }
        break;
      case UserFilterPayloadType.NotIn:
        if (filterResponse.UserIds.length) {
          if (filterResponse.UserIds.length !== count) {
            return true;
          }
        } else {
          return true;
        }
        break;
      default:
        break;
    }
  }
  return false;
};

export const getRowIndexOfRowGroupColumn = (columns:any, field: string) => {
  const rowIndex = columns.find((column:any) => {
    const { headerName, builderConfig } = column.colDef;
    if (builderConfig && builderConfig.IsDynamicField) {
      return builderConfig.Alias === field;
    }
    return headerName === field;
  });
  return rowIndex && rowIndex.colDef && rowIndex.colDef.rowGroupIndex;
};

export const getReportViewDimensionStr = (dimension: ReportReduxModel.IDimension) => {
  if (dimension) {
    const text = displayName(dimension, true);
    return dimension.BuilderConfig && dimension.BuilderConfig.IsDynamicField
      ? `${text}${DimensionIdentifier}${dimension.BuilderConfig.Alias}`
      : text;
  }
  return '';
};

export const getTextTooltipContentForDimension = (dimension: ReportReduxModel.IDimension | IColumn) => {
  if (dimension) {
    const text = displayName(dimension);
    if (dimension.BuilderConfig && dimension.BuilderConfig.IsDynamicField) {
      return `${text} (${dimension.BuilderConfig.SchemaName})`;
    }
    return text;
  }
  return '';
};

export const getFieldIdentifier = (
  field: ReportResponseModel.IColumn | ReportReduxModel.IDimension,
) => {
  if (field) {
    if (field.BuilderConfig && field.BuilderConfig.IsDynamicField) {
      return field.BuilderConfig.Alias;
    }
    return field.Name;
  }
  return null;
};

export const getFieldDisplayName = (field: ReportResponseModel.IColumn) => {
  if (field) {
    if (field.BuilderConfig && field.BuilderConfig.IsDynamicField) {
      return `${field.BuilderConfig.DisplayName}`;
    }
    return field.Props.ReferTo || field.Name;
  }
  return '';
};

export const getFieldEntityName = (field: ReportResponseModel.IColumn) => {
  if (field) {
    if (field.BuilderConfig && field.BuilderConfig.IsDynamicField) {
      return `${field.BuilderConfig.Entity}`;
    }
  }
  return 'Other';
};

export const getTextTooltipContentForField = (field: ReportResponseModel.IColumn) => {
  if (field) {
    const text = getFieldDisplayName(field);
    if (field.BuilderConfig && field.BuilderConfig.IsDynamicField) {
      return `${text} (${field.BuilderConfig.SchemaName})`;
    }
    return text;
  }
  return '';
};

export const isMeasure = (column: IColumn) => !!column?.Props?.Measure;
export const isMeasureAndApplied = (column: IColumn) => isMeasure(column) && column.Props.Mode !== ColumnModes.Hidden;
export const isMeasureDynamic = (column: IColumn) => !!column?.Props?.Measure?.Expression;

// generic search compares two string
// params query:- query string
// params searchField:- string to be compared
// returns true if searchField has contains matching query string
// return false if  query does not match and query is empty.
export const genericSearch = (query: string = '', searchField: string = '') => (
  query
    ? searchField.toString().toLowerCase().includes(query.toLowerCase())
    : true);

export const sortAdditionalFields = <T extends ReportReduxModel.IDimension | ReportResponseModel.IColumn>
  (dimensions: T[]) => sortBy(dimensions, (item) => {
    let name = item.Name;

    // if builder config is present, we chose displayname to sort. We trim and do lowercase to make it readable for user
    if (item.BuilderConfig && item.BuilderConfig.IsDynamicField) {
      name = item.BuilderConfig.DisplayName || '';
    }

    // for default columns, we just show it by alias configured in config
    return [name.toLowerCase().replace(/\s/g, '')];
  });

export const FieldEntities = {
  [PreDefinedFieldEntityType]: {
    icon: PreDefinedIcon,
    color: '#a456ba',
  },
  [FieldEntitiesType.Lead]: {
    icon: LeadIcon,
    color: '#F5B129',
  },
  [FieldEntitiesType.Activity]: {
    icon: ActivityIcon,
    color: '#084E96',
  },
  [FieldEntitiesType.Opportunity]: {
    icon: OpportunityIcon,
    color: '#3bb345',
  },
  [FieldEntitiesType.Task]: {
    icon: TaskIcon,
    color: '#46BFC9',
  },
  [FieldEntitiesType.User]: {
    icon: UserIcon,
    color: '#e32c8d',
  },
} as ObjModel.ObjGeneric<IFieldEntity>;

export interface IFieldEntity {
  icon: React.ElementType;
  color: string;
}

export const ExportStatusIcons = {
  [ExportStatus.InProgress]: {
    icon: LoadingComponent,
    expiryText: 'Export in progress...',
  },
  [ExportStatus.InQueue]: {
    icon: LoadingComponent,
    expiryText: 'Export in progress... ',
  },
  [ExportStatus.Success]: {
    icon: SuccessIcon,
    expiryText: 'Expires in: {{expiry time}} ',
  },
  [ExportStatus.Failed]: {
    icon: FailedIcon,
    expiryText: 'Process failed ',
  },
} as ObjModel.ObjGeneric<IExportStatusIcon>;

export interface IExportStatusIcon {
  icon: React.ElementType
  expiryText: string
}

export const deleteGivenQueryStringsFromUrl = (deleteParams:string[]) => {
  const queryParams = new URLSearchParams(window.location.search);

  deleteParams.forEach((item) => {
    if (queryParams.has(item)) {
      queryParams.delete(item);
    }
  });
  window.history.pushState(null, null, `?${queryParams.toString()}`);
};

export const setReportViewDataInURL = (PersonalisationId: string, ViewType: ReportViewTypes, userId:string) => {
  const queryParams = new URLSearchParams(window.location.search);

  queryParams.set(ReportViewURLTypes.rvId, PersonalisationId);
  queryParams.set(ReportViewURLTypes.rvType, ViewType);
  queryParams.set(ReportViewURLTypes.rvu, userId);

  return `?${queryParams.toString()}`;
};
