import React from 'react';
import LinearBar from 'components/common/loader/LinearBar/linear-bar.component';
import { LinearBarColorTypes, SizeTypes } from 'core/constants/loader';
import { duplicateReportViewMessage, ReportViewTypes } from 'core/constants/report';
import StyledContainer from './style/create-report-view.style';
import ReportViewInfo from './report-view-info.component';

export interface CreateReportViewProps {
  handleCreate: (name: string) => void,
  isDisabled: boolean,
  stateLabel: string,
  handleSetStateLabel: (label: string) => void,
  isValidLabel: boolean,
  isActionCompleted: boolean,
  isCreating: boolean,
}

const CreateReportView = (props: CreateReportViewProps) => {
  const {
    handleCreate, isDisabled, stateLabel, handleSetStateLabel, isValidLabel, isActionCompleted, isCreating,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== ' ') {
      handleSetStateLabel(event.target.value);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isValidLabel && event.key === 'Enter') {
      handleCreate(stateLabel);
    }
  };

  return (
    <StyledContainer>
      <div className={`se-create-state-cn ${isDisabled ? 'disabled' : ''}`}>
        <div className="se-create-state">
          <ReportViewInfo
            stateIndex={-1}
            ViewType={ReportViewTypes.Custom}
          />
          <div className="se-label-input-cn">
            <input
              autoFocus
              placeholder="Enter Name"
              value={stateLabel}
              onChange={handleChange}
              disabled={isDisabled}
              onKeyPress={handleKeyPress}
            />
          </div>
        </div>
        <LinearBar
          show={isCreating}
          size={SizeTypes.XSmall}
          isBottomPlaced
          barColor={LinearBarColorTypes.Primary}
          isCompleted={isActionCompleted}
          time={1}
        />
        {
          stateLabel && !isValidLabel && (
          <div className="se-invalid-label-info">
            {duplicateReportViewMessage}
          </div>
          )
        }
      </div>
    </StyledContainer>
  );
};

export default CreateReportView;
