import { authSelector } from 'components/feature/Report/ReportSidebar/ReportView/report-view.selector';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SieraLogo from '../svg/SieraLogo/siera-logo.svg';
import StyledUnauthorizedContainer from './unauthorized.styled';

const Unauthorized = () => {
  const { isMarvin } = useSelector(authSelector);

  const navigateToMarvin = () => {
    window.top.location.href = document.referrer;
  };

  const navigationOnSelection = () => {
    if (isMarvin) {
      return <div onClick={() => navigateToMarvin()} className="se-go-to-dashboard">Go back to Dashboard</div>;
    }
    return <Link to="dashboard" className="se-go-to-dashboard">Go back to Dashboard</Link>;
  };

  return (
    <StyledUnauthorizedContainer>
      <div className="unauth-section">
        <SieraLogo
          width={226}
          height={114}
        />
        <span className="se-info-data-header">Sorry, you do not have permission to access SIERA</span>
        <span className="se-contact-data-text">Contact your admin for more information </span>
        {navigationOnSelection()}
      </div>
    </StyledUnauthorizedContainer>
  );
};

export default Unauthorized;
