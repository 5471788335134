import styled from 'styled-components';

const StyledExportedReportInfo = styled.div`
.se-exported-report-info-cont{
    display: flex;
    flex-direction: row;
    border: 1px solid #DFE3E8;
    border-radius: 2px;
    opacity: 1;
    width: 316px;
    padding: 16px;
    margin-left: 16px;
    margin-right: 16px;
    box-sizing: border-box;
    margin-top: 12px;
}
.se-exported-report-info-cont:hover{
    background: #F9FAFB;
}

.se-format-type-icon{
    margin-top: 10px;
}

.se-info-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 12px;
    padding-right: 12px;
    z-index: 1;
    box-sizing: border-box;
    width: 242px;
}

.se-report-name{
    font: 13px 'Regular';
    letter-spacing: 0px;
    color: #454F5B;
    opacity: 1;
    margin-bottom: 4px;
    width: 218px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.se-extend-width {
    width: 250px;
    padding-right: 0px;
}

.se-report-timestamp{
    font: 12px 'Regular';
    letter-spacing: 0px;
    color: #637381;
    opacity: 1;
    display: block;
    word-wrap: break-word;
}

.se-report-failed{
    cursor: pointer;
}
.se-report-status, .se-report-failed{
    margin: auto;
}
.hideIcon{
    display: none;
}

.se-highlight {
    color: #006FD6;
}
.se-failed{
    color:#DE323B;
}

.se-hideTime {
    display:none;
}
.se-showTime-bar{
    color: #637381;
    opacity: 50%;
    margin:0px 8px;
}
`;

export default StyledExportedReportInfo;
