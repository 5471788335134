import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ReduxModel } from 'core/models';
import * as Actions from 'redux/report-list/actions';

const mapStateToProps = (state: ReduxModel.IGlobalState) => ({
  reduxState: state,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reduxAction: {
    pinnedReportsLoadRequest: () => {
      dispatch(Actions.pinnedReportsLoadRequest());
    },
    categoryReportsLoadRequest: () => {
      dispatch(Actions.categoryReportsLoadRequest());
    },
    selectReportType: (id: string, name: string, isReportCategory: boolean) => {
      dispatch(Actions.selectReportType(
        id,
        name,
        isReportCategory,
      ));
    },
  },
});

export default () => connect(mapStateToProps, mapDispatchToProps);
