import React from 'react';

type Props = {
  width: number;
  height: number;
}

const UnderlyingIcon = ({
  width, height,
}: Props) => (
  <svg
    id="table_chart_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
      stroke: 'none',
    }}
    viewBox="0 0 20 20"
  >
    <path
      id="Path_7888"
      data-name="Path 7888"
      d="M0,0H20V20H0Z"
      style={{
        fill: 'none',
      }}
    />
    <path
      id="Path_7889"
      data-name="Path 7889"
      d="M17.167,3H4.667A1.672,1.672,0,0,0,3,4.667V16.333A1.672,1.672,0,0,0,4.667,18h12.5a1.672,1.672,0,0,0,1.667-1.667V4.667A1.672,1.672,0,0,0,17.167,3Zm0,1.667v2.5H4.667v-2.5ZM13,16.333H8.833v-7.5H13Zm-8.333-7.5h2.5v7.5h-2.5Zm10,7.5v-7.5h2.5v7.5Z"
      transform="translate(-0.5 -0.5)"
      style={{
        fill: '#454f5b',
      }}
    />
  </svg>

);

export default UnderlyingIcon;
