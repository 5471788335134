import styled from 'styled-components';
import { StyledSettingsSectionContainer } from '../settings.style';

export default styled(StyledSettingsSectionContainer)`
flex: 1;
width: 100%;
overflow: hidden;

  .se-grouping-limit-info-tooltip {
    position: fixed;
    display: none;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1000000;
    padding: 0 10px ;
    color: #454F5B ;
    text-align: left;
    text-decoration: none;
    background-color: #F4F6F8;
    border-radius: 3px;
    border: 1px solid #F4F6F8;
    box-shadow: 0px 3px 4px #00000029;
 }

.se-no-dimension-data {
    height: 32px;
    font-size: 13px;
    color: #b9c4ce;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.se-grouping-header-cn{
    display: flex;
    margin-bottom: 8px;
}
`;
