import React from 'react';

type Props = {
  width: number;
  height: number;
  fill1?: string;
  fill2?: string;
}

const SettingIcon = ({
  width, height, fill1, fill2,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 20 20"
  >

    <g
      id="Group_22630"
      data-name="Group 22630"
      transform="translate(-12012 -9719)"
    >
      <g
        id="Group_22624"
        data-name="Group 22624"
      >
        <g
          id="description_black_24dp_6_"
          data-name="description_black_24dp (6)"
          transform="translate(12012 9719)"
        >
          <path
            id="Path_10836"
            data-name="Path 10836"
            d="M0,0H20V20H0Z"
            fill="none"
          />
          <path
            id="Path_10189"
            data-name="Path 10189"
            d="M7.322,13.65h6.643v1.664H7.322Zm0-3.329h6.643v1.664H7.322ZM12.3,2H5.661A1.667,1.667,0,0,0,4,3.664V16.979a1.66,1.66,0,0,0,1.653,1.664h9.973a1.667,1.667,0,0,0,1.661-1.664V6.993Zm3.322,14.979H5.661V3.664h5.813V7.825h4.152Z"
            transform="translate(-0.643 -0.322)"
            fill={fill1}
          />
          <path
            id="Path_10837"
            data-name="Path 10837"
            d="M12.33,2H5.666A1.664,1.664,0,0,0,4.008,3.666L4,17a1.664,1.664,0,0,0,1.658,1.666h10A1.671,1.671,0,0,0,17.328,17V7ZM14,15.331H7.332V13.665H14ZM14,12H7.332V10.332H14ZM11.5,7.832V3.25l4.581,4.583Z"
            transform="translate(-0.664 -0.332)"
            fill="none"
            className="icon-stroke"
          />
        </g>
        <g
          id="Group_22623"
          data-name="Group 22623"
          transform="translate(-5999 17166)"
        >
          <g
            id="description_black_24dp_1_"
            data-name="description_black_24dp (1)"
            transform="translate(18011 -7447)"
          >
            <path
              id="Path_10188"
              data-name="Path 10188"
              d="M0,0H20V20H0Z"
              fill="none"
            />
          </g>
          <g
            id="Group_21885"
            data-name="Group 21885"
            transform="translate(-2.33 -19.763)"
          >
            <circle
              id="Ellipse_687"
              data-name="Ellipse 687"
              cx="5"
              cy="5"
              r="5"
              transform="translate(18023.33 -7418.237)"
              fill={fill2}
            />
            <path
              id="Path_7614"
              data-name="Path 7614"
              d="M17.951,14.369a2.923,2.923,0,0,0-.04-.424l.768-.681L18.005,12.1l-.977.33A2.457,2.457,0,0,0,16.3,12l-.2-1H14.751l-.2,1a2.457,2.457,0,0,0-.728.424l-.977-.33-.674,1.166.768.681a2.249,2.249,0,0,0,0,.849l-.768.681.674,1.166.977-.33a2.457,2.457,0,0,0,.728.424l.2,1H16.1l.2-1a2.457,2.457,0,0,0,.728-.424l.977.33.674-1.166-.768-.681A2.924,2.924,0,0,0,17.951,14.369Zm-2.527,1.348a1.348,1.348,0,1,1,1.348-1.348A1.352,1.352,0,0,1,15.424,15.717Z"
              transform="translate(18012.906 -7427.605)"
              fill={fill1}
              className="icon-stroke"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

SettingIcon.defaultProps = {
  fill1: '#212B36',
  fill2: '#f9fafb',
};

export default SettingIcon;
