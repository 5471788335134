import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const LogoutIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="logout_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 16 16"
  >
    <g
      id="Group_19551"
      data-name="Group 19551"
    >
      <path
        id="Path_8935"
        data-name="Path 8935"
        d="M0,0H16V16H0Z"
        fill="none"
      />
    </g>
    <g
      id="Group_19552"
      data-name="Group 19552"
      transform="translate(2.007 2.007)"
    >
      <path
        id="Path_8936"
        data-name="Path 8936"
        d="M12.323,6.33l-.939.939,1.052,1.059H7V9.659h5.441l-1.052,1.052.939.946,2.664-2.664Zm-7.991-2H8.993V3H4.332A1.336,1.336,0,0,0,3,4.332v9.323a1.336,1.336,0,0,0,1.332,1.332H8.993V13.655H4.332Z"
        transform="translate(-3 -3)"
        fill={fill}
      />
    </g>
  </svg>

);

LogoutIcon.defaultProps = {
  fill: '#3bb345',
};

export default LogoutIcon;
