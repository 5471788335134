import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { APIRequestStatus } from 'core/constants/redux';
import { ErrorMessage } from 'core/constants/errors';
import { ReportActionTypes } from 'core/constants/redux-action-types';
import { LicenseManager } from 'ag-grid-enterprise';
import { ObjModel, ReportResponseModel } from 'core/models';
import { useDispatch, useSelector } from 'react-redux';
import { applyReportView } from 'redux/report/actions';
import { getReportViewInfo, getReportViewList, resetReportview } from 'redux/report-view/report-view.actions';
import { ReportViewTypes, ReportViewURLTypes } from 'core/constants/report';
import QueryString from 'query-string';
import { setReportViewDataInURL } from 'core/utils/report.util';
import ReportSidebar from './ReportSidebar/report-sidebar.component';
import ExportComponent from './ReportSidebar/ExportPanel/export-panel-component';
import ErrorComponent from '../../common/Error/error.component';
import ReportHeader from './ReportHeader/report-header.component';
import ReportFooter from './ReportFooter/report-footer.component';
import ReportBuilder from './ReportBuilder/report-builder.component';
import StoreConnector from './report.redux';
import StyledContainer from './report.styled';
import { IProps } from './report.d';
import { authSelector, reportSelector, reportViewSelector } from './ReportSidebar/ReportView/report-view.selector';
import FullScreenLoader from '../../common/loader/FullscreenLoader/fullscreen-loader.component';

const Report = (props: IProps) => {
  const { reduxState, reduxAction, ...rest } = props;
  const dispatch = useDispatch();
  const reportViewState = useSelector(reportViewSelector);
  const reportState = useSelector(reportSelector);
  const authState = useSelector(authSelector);

  const { selectedReportViewItem, reportViewError, reportViewList: { reportViewItems } } = reportViewState;

  const { variables, userAttributes } = authState;
  const { report } = reduxState;
  const {
    reportId, reportConfig, filterConfig, appliedFilters, sort, page, requestProcessing,
    activeFilters, useChangedFilters, activeDimensions, defaultView, isFirstLoad,
  } = report;
  const {
    reportConfigLoad, reportDataLoad, filterLoad, reset, applyFilters, refresh, dataRefreshDetailsLoad,
  } = reduxAction;
  const { match } = rest;
  const {
    [ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST]: configLoadStatus,
    [ReportActionTypes.FILTER_LOAD_REQUEST]: filtersLoadStatus,
    [ReportActionTypes.REPORT_DATA_LOAD_REQUEST]: dataLoadStatus,
  } = requestProcessing;
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);
  const isLoadingConfig = configLoadStatus === APIRequestStatus.Processing;
  const isConfigError = configLoadStatus === APIRequestStatus.Failure;
  const isLoadingData = dataLoadStatus === APIRequestStatus.Processing;
  if (userAttributes && userAttributes.UserTimeZone) {
    // setting at report level as moment has dependency in filter-fetch utility. it will set timezone at root level
    // before loading report
    moment.tz.setDefault(userAttributes.UserTimeZone);
  }

  const { location } = rest;

  const queryParams: ObjModel.Obj = QueryString.parse(location.search);

  const isValidReportViewURL = () => {
    const checkURLParams = new URLSearchParams(window.location.search);
    if (checkURLParams?.get(ReportViewURLTypes.rvId) && checkURLParams?.get(ReportViewURLTypes.rvType) && checkURLParams?.get(ReportViewURLTypes.rvu)) {
      return true;
    }
    return false;
  };

  // If a user share a URL of shared ReportView then for other user we change the type from custom to shared as the type for getReportViewInfo is required by backend to identify
  const getUpdatedViewType = (viewType:ReportViewTypes, userId: string) => {
    let updatedViewType = viewType;
    if (viewType === ReportViewTypes.Custom && userId !== userAttributes.Id) {
      updatedViewType = ReportViewTypes.Shared;
    }
    return updatedViewType;
  };

  const [openPannel, setOpenPannel] = useState(false);
  const [reportBuilderPortal, setReportBuilderPortal] = useState<HTMLDivElement>(null);
  // This state is created to call the apply report view method once when loading just like apply filter and then will set to false.
  const [loadReportView, setLoadReportView] = useState(true);

  useEffect(() => {
    const reportID = match?.params?.reportId || '';
    if (reportID !== reportId) {
      if (isValidReportViewURL()) {
      // We are not using the react-route as to implement that we have copy major chunk of code so we used the qury params implementation
        window.history.pushState(null, null, setReportViewDataInURL(queryParams?.rvId, getUpdatedViewType(queryParams?.rvType, queryParams?.rvu), userAttributes?.Id));
      }
      dispatch(getReportViewList(reportID));
      reportConfigLoad(match.params.reportId);
      dataRefreshDetailsLoad(match.params.reportId);
    }
    dispatch(resetReportview());
    return reset;
  }, [match.params.reportId]);

  useEffect(() => {
    if (queryParams?.rvId) {
      if (isValidReportViewURL()) {
        dispatch(getReportViewInfo({ personalisationId: queryParams?.rvId, context: 'reportSelect', ReportViewType: getUpdatedViewType(queryParams?.rvType, queryParams?.rvu) }));
      }
    }
  }, [queryParams.rvId]);

  useEffect(() => {
    // This ensures that when user is selecting report view from normal flow it works
    if (!isValidReportViewURL()) {
      setLoadReportView(false);
    }
    if (selectedReportViewItem && match.params.reportId === reportId && !loadReportView) {
      dispatch(applyReportView(reportState, variables, reportViewState));
    }
  }, [selectedReportViewItem]);

  useEffect(() => {
    if (filterConfig && !isEmpty(filterConfig)) {
      if (appliedFilters && !isEmpty(appliedFilters)) {
        let isLoaded = true;
        filterConfig.forEach((item) => {
          if (isLoaded && appliedFilters[item.ID] && (appliedFilters[item.ID].shouldLoad
            || filtersLoadStatus[item.ID] === APIRequestStatus.Processing)) {
            isLoaded = false;
          }
          if (appliedFilters[item.ID] && appliedFilters[item.ID].shouldLoad
            && filtersLoadStatus[item.ID] !== APIRequestStatus.Processing) {
            if (item.LinkedTo) {
              if (appliedFilters[item.LinkedTo]) {
                if (appliedFilters[item.LinkedTo].shouldLoad
                  || filtersLoadStatus[item.LinkedTo] === APIRequestStatus.Processing
                  || filtersLoadStatus[item.LinkedTo] === APIRequestStatus.Failure) {
                  return;
                }
              } else {
                return;
              }
            }
            filterLoad(item.ID, variables);
          }
        });
        // This dispatch the report when the dynamic fields are loaded and only once.
        if (isLoaded && useChangedFilters) {
          if (queryParams?.rvId && loadReportView && !reportViewError && isValidReportViewURL()) {
            dispatch(applyReportView(reportState, variables, reportViewState, getUpdatedViewType(queryParams?.rvType, queryParams?.rvu)));
            setLoadReportView(false);
          } else {
            applyFilters();
          }
        }
      }
    }
  }, [filterConfig, appliedFilters]);

  useEffect(() => {
    let loadData = true;
    if (!reportConfig) {
      return;
    }
    if (filterConfig) {
      if (appliedFilters && !isEmpty(appliedFilters)) {
        filterConfig.forEach((item) => {
          if (appliedFilters[item.ID] && (appliedFilters[item.ID].shouldLoad
            || filtersLoadStatus[item.ID] === APIRequestStatus.Processing)) {
            loadData = false;
          }
        });
      }
    }
    if (loadData && !isLoadingData && (queryParams.rvId ? !(reportViewError === 'Deleted') : true)) {
      reportDataLoad();
    }
  }, [reportConfig, activeFilters, sort, page, activeDimensions]);

  useEffect(() => {
    if (reportConfig && reportConfig.Details) {
      fireReportVisitEvent(reportConfig, 0);
    }
  }, [reportConfig]);

  // If we get default or personalised view in get-report-config reponse we load view on first load and set it as selected
  useEffect(() => {
    if (defaultView && !isValidReportViewURL() && !isEmpty(reportViewItems) && isFirstLoad) {
      if (defaultView.PersonalisedViewID) {
        const personalisedViewType = reportViewItems?.find((item) => item?.PersonalisationId === defaultView?.PersonalisedViewID)?.ReportViewType;
        if (personalisedViewType) {
          dispatch(getReportViewInfo({ personalisationId: defaultView.PersonalisedViewID, context: 'reportSelect', ReportViewType: personalisedViewType }));
          window.history.pushState(null, null, setReportViewDataInURL(defaultView.PersonalisedViewID, personalisedViewType, userAttributes?.Id));
        }
      } else if (defaultView.DefaultViewID && defaultView.ApplyDefaultView) {
        dispatch(getReportViewInfo({ personalisationId: defaultView.DefaultViewID, context: 'reportSelect', ReportViewType: ReportViewTypes.Default }));
        window.history.pushState(null, null, setReportViewDataInURL(defaultView.DefaultViewID, ReportViewTypes.Default, userAttributes?.Id));
      }
    }
  }, [defaultView, reportViewItems]);

  if (isLoadingConfig) {
    return (
      <FullScreenLoader />
    );
  }

  if (isConfigError) {
    return (
      <ErrorComponent
        message={ErrorMessage.Report}
        tenant={userAttributes && userAttributes.OrgShortCode}
        issue={`Report: ${reportId}`}
        retry={() => {
          refresh();
        }}
      />
    );
  }

  if (reportConfig) {
    return (
      <>
        <StyledContainer>
          <ExportComponent openPannel={openPannel} setOpenPannel={setOpenPannel} />
          <div className={`${openPannel ? 'se-cover-open' : 'se-cover-close'}`} />
          <div
            className="se-report"
          >
            <ReportHeader
              setOpenPannel={setOpenPannel}
              {...rest}
            />
            <div
              className="se-report-showcase"
            >
              <div
                className="se-report-body"
              >
                <ReportBuilder
                  setReportBuilderPortal={setReportBuilderPortal}
                />
                <div />
                <ReportFooter />
              </div>
              <ReportSidebar
                reportBuilderPortal={reportBuilderPortal}
              />
            </div>
          </div>
        </StyledContainer>
      </>
    );
  }
  return null;
};

// fireReportVisitEvent checks if pendo is added and pushes an event on every report load.
const fireReportVisitEvent = (reportConfig: ReportResponseModel.IReportConfig, retryCount: number) => {
  const pendo = (window as any).pendo;
  if (pendo && pendo.isReady && pendo.isReady() && pendo.track) {
    pendo.track('REPORT_VIEWED', {
      reportName: reportConfig.Details.Title,
    });
  } else if (retryCount < 10) {
    setTimeout(() => fireReportVisitEvent(reportConfig, retryCount + 1), 500);
  }
};

export default StoreConnector()(Report);
