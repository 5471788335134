import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const RevenueIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="request_quote_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 20 20"
  >
    <rect id="Rectangle_971" data-name="Rectangle 971" width="20" height="20" fill="none" />
    <path id="Path_858" data-name="Path 858" d="M12.5,2H5.7A1.705,1.705,0,0,0,4,3.7V17.3A1.705,1.705,0,0,0,5.7,19H15.9a1.705,1.705,0,0,0,1.7-1.7V7.1ZM5.7,17.3V3.7h5.951V7.1H15.9V17.3Zm4.251-.85h1.7V15.6h.85a.853.853,0,0,0,.85-.85V12.2a.853.853,0,0,0-.85-.85H9.951V10.5h3.4V8.8h-1.7v-.85h-1.7V8.8H9.1a.853.853,0,0,0-.85.85V12.2a.853.853,0,0,0,.85.85h2.55v.85h-3.4v1.7h1.7Z" transform="translate(-0.599 -0.3)" fill={fill} />
  </svg>
);

RevenueIcon.defaultProps = {
  fill: '#f5b129',
};

export default RevenueIcon;
