import styled from 'styled-components';

const StyledContainer = styled.div<
{
IndicatorHeight: number,
IndicatorColor:string
}
>`
width: 100%;

.se-report-view {
    width: 100%;
    margin-bottom: 8px;
    position: relative;
    font-family: Regular;

    button.se-report-view-btn {
        box-sizing: border-box;
        width: 268px;
        display: flex;
        align-items: center;
        padding: 11px 11px 10px 11px;
        border: 1px solid #CAD3DB;
        text-align: left;
        border-radius: 2px;
        transition: border 500ms ease 0s, background-color 500ms ease 0s;

        :hover {
            border: 1px solid #116FD6;
        }

        .se-report-view-label-cn {
            flex: 1;
            margin-left: 6px;
            margin-right: 8px;
            font-family: Regular;
            overflow: hidden;
        }
    
        .se-report-view-name {
            width: 100%;
            font-size: 13px;
            color: #454F5B;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
            line-height: 17px;
        }

        button.se-undo-action-btn {
            border: 0;
            padding: 0;
            font-size: 13px;
            color: #006FD6;
        }

        :disabled {
            pointer-events: none;
        }
    }

    button.se-new-create {
        border: 1px solid #189F3F ;
        background-color: rgba(24, 159, 63, 0.1);
        pointer-events: none;
    }

    button.se-action-success {
        .se-report-view-label-cn {
            margin-left: 12px;
            margin-right: 0px;
        }
    }

    button.se-delete-success {
        border: 1px solid #D1331B ;
        background-color: rgba(209, 51, 27, 0.1);
        pointer-events: none;
    }

    button.se-share-success {
        border: 1px solid #006FD6;
        background-color: rgba(0, 111, 214, 0.1);
        pointer-events: none;
    }

    button.se-stop-share-success {
        border: 1px solid #F5802A;
        background-color: rgba(245, 128, 42, 0.1);
        pointer-events: none;
    }


    button.se-report-view-btn.selected {
        border: 1px solid #006FD6;
    }

    button.se-report-view-btn.delete {
        background-color: #D1331B1A;
        border: 1px solid #D1331B;
        }
        
    button.se-report-view-btn.se-delete-share{
        .se-report-view-name {
            overflow-wrap: break-word;
            white-space: normal;
        }
    }

    button.se-report-view-btn.Share {
        background-color: rgba(0, 111, 214, 0.1);
        border: 1px solid #006FD6;
        
        .se-report-view-name {
            overflow-wrap: break-word;
            white-space: normal;
        }
    }

    button.se-report-view-btn.StopShare {
        background-color: rgba(245, 128, 42, 0.1);
        border: 1px solid #F5802A;
        
        .se-report-view-name {
            overflow-wrap: break-word;
            white-space: normal;
        }
    }

    .se-kebab-divider {
                border: 1px solid rgba(173, 186, 199, 0.25);
                border-left: none;
                height: 14px;
                margin-left: 11px;
                margin-right: 8px;
            }

    .se-report-view-btn.masked {
        background-color: #F9FAFB;
        cursor: default;

        .se-lock-icon {
            cursor: pointer !important;
        }
        button.se-delete-icon {
                display: none;
            }
    }

}

.se-report-view.disabled {
    pointer-events: none;
}

  .se-viewType-indicator {
    width: 2px;
    height: 38px ;
    background: ${(props) => props.IndicatorColor};
    border-radius: 2px ;
    position: absolute;
    top: 0.8px;
    left: 1px;
  }
`;

export default StyledContainer;
