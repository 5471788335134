import styled from 'styled-components';

const StyledGridComponent = styled.div`
position: relative;
width: 100%;
height: 100%;
.ag-theme-alpine {
    width: 100%;
    height: 100%;
    .ag-root-wrapper-body.ag-layout-normal{
        min-height: 100% !important;
    }
    .ag-row-first{
        .row-group{
            border-top-color: transparent;    
        }
    } 
    .row-group{
        border-top-color: #00000029;    
    }
    .cel-border-right{
        border-right-color: #00000029;     ;
    }

    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value){
        display: none;
    }

    .cell-disabled {
        color: var(--headerColor);
        opacity: 0.6;
        cursor: default !important;
    }

    // disable clicks on cell for ex. click on owner name for hierarchical drilldown.
    .cell-disabled:active{
        pointer-events:none;
    }

    .cell-default {
        cursor: default !important;
     }

    .cell-default:active {
       pointer-events: none;
    }
    
    .ag-row {
        cursor: default;
    }

//  disable border for row group cell for first row.
    .ag-row-group:first-child {
        .ag-cell {
            border-top: 0;
        }
    }

// change color of top border of header row below column group header row.
    .ag-header-row:not(:first-child) {
        .ag-header-cell {
            border-top-color: #dde2eb;
        }
    } 


    .ag-cell{
        cursor:pointer
    }
    
    .ag-header {
        background-color: #fff;
    }

    .ag-icon {
        display: inline-block;
    }
    .ag-overlay-loading-center {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
    }
    .ag-header-cell-resize::after {
        height: 40%;
    }
    .ag-paging-row-summary-panel {
        display: none;
    }

    .ag-paging-description {
        display: none;
    }

    .ag-icon-previous,
    .ag-icon-next {
        border: 1px solid #e7eaed;
        border-radius: 2px;
        color: #333;
    }

    .ag-icon-first {
        display: none;
    }
    .ag-icon-last {
        display: none;
    }

    .ag-header-row {
        color: #181d1f;
        color: var(
            --ag-header-foreground-color,
            var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))
        );
        line-height: 36px;
    }
    .ag-grid-cn {
        width: 100%;
        height: 100%;
    }
    .ag-horizontal-right-spacer.ag-scroller-corner {
        width: 6px !important;
        max-width: 6px !important;
        min-width: 6px !important;
    }
    .ag-root-wrapper {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: none;
        background: var(--white) 0% 0% no-repeat padding-box;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0 0 2px var(--boxShadow);
        opacity: 1;
    }
    .ag-header {
        border-bottom:1px solid #DFE3E8;
    }

    .ag-paging-panel {
        border-top: none;
    }
   
  
    .ag-center-cols-viewport {
        width: 100%;
    }
    .firstElement {
        flex: auto;
    }

    .ag-header-row {
        font-weight: 500;
    }
    .ag-header:hover .ag-header-cell-resize{
        opacity: 1;
    }
    .ag-header-cell-resize{
        opacity: 0;
    }

    .ag-row {
        color: #454f5b;
        font-size: 13px;
        border-color: rgba(173, 186, 199,.1)
    }
    .ag-row-odd{
        background-color: white;
    }

    .ag-cell,
    .ag-full-width-row .ag-cell-wrapper.ag-row-group {
        line-height: 40px;
    }
 

 
     .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb {
      background-color: #ADBAC7;
      cursor:pointer;
      border-radius:4px;
      } 
     .ag-body-horizontal-scroll-viewport{
      height: 6px !important;
      min-height: 6px !important;
      max-height: 6px !important;
      ::-webkit-scrollbar {
      height: 6px !important;
      } 
      
    }
    .ag-body-horizontal-scroll{
      height: 6px !important;
      min-height: 6px !important;
      max-height: 6px !important;
      ::-webkit-scrollbar {
      height: 6px !important;
     } 
    } 
   .ag-body-horizontal-scroll-container{
      height: 6px !important;
      min-height: 6px !important;
      max-height: 6px !important;
      ::-webkit-scrollbar {
      height: 6px !important;
    } 
    } 

    .ag-pinned-left-cols-container, .ag-center-cols-container{
        border-bottom: 1px solid #ddd;
        overflow: hidden;
    }

    .ag-body-horizontal-scroll{
        position: absolute;
        bottom: 0;
    }
  
    .ag-pinned-left-header {
        border-right: solid 1px;
        border-right-color: #dfe3e8;
        border-right-color: var(--ag-border-color, #dfe3e8);
    }

    .ag-root-wrapper-body {
        height: 100%;
        width: 100%;
    }

    .dropdown {
        border: 1px solid #dfe3e8;
        border-radius: 3px;
        padding: 5px 3px;
        margin: 5px 5px;
    }

    .link-primary {
        cursor: pointer;
        color: #006fd6;
    }

    .align-right{
        text-align: right;
    }

    .align-left{
        text-align: left;
    }

    .header-right .se-column-header-cn {
        justify-content: flex-end;
        
        .se-column-header-sort-sequence{
            margin-right: 6px ;
        }
    }

    .header-left .se-column-header-cn {
        justify-content: flex-start;
    }

    .default-cursor{
        cursor: default;
    }

    .ag-header-group-text {
        margin-left: auto;
    }
    
    .ag-header-cell, .ag-cell, .ag-header-group-cell{
        padding-left: 8px;
        padding-right: 8px;
    }

    .ag-filter-toolpanel-header,
    .ag-filter-toolpanel-search,
    .ag-status-bar,
    .ag-header-row,
    .ag-panel-title-bar-title,
    .ag-multi-filter-group-title-bar {
        font-weight: 600;
        color:#637381; 
        font-size:13px ;
    }
}

.ag-floating-bottom{
    border-color: #006fd6;
}

.ag-floating-bottom .ag-cell{
    color:#454f5b;
    cursor:default !important;
    font-family: 'SemiBold';
}

.ag-header-cell .ag-react-container{
    width: 100%;
}

.ag-body-viewport::-webkit-scrollbar {
 background-color: white;
 width: 6px;
}

.ag-body-viewport::-webkit-scrollbar-track {
   background-color: transparent;
    border-radius:8px;
    cursor:pointer;
}
 
.ag-body-viewport::-webkit-scrollbar-thumb {
    background-color:#ADBAC7;
  cursor:pointer;
  border-radius:3px;
} 

.ag-body-viewport:hover::-webkit-scrollbar-thumb {
    background-color: #ADBAC7;
  cursor:pointer;
  border-radius:3px;
} 
// to prevent spacing in pivot mode
.ag-pivot-leaf-group {
  margin-left: 0px !important;
}


@media (min-width: 320px) {
    .ag-theme-alpine {
        .ag-center-cols-viewport {
            width: 100%; 
        }
    }
}
@media (min-width: 768px) {
    .ag-theme-alpine {
        .ag-center-cols-viewport {
            width: 100%; 
        }
    }
}
@media (min-width: 1024px) {
    .ag-theme-alpine {
        .ag-center-cols-viewport {
            width: 100%; 
        }
    }
}
`;

export default StyledGridComponent;
