import React from 'react';

type Props = {
    width: number;
    height: number;
    fill?: string;
}

const MinusIcon = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="8" height="8" fill="white" />
    <path d="M0 4.5V3.5H8V4.5H0Z" fill={fill} />
  </svg>

);

MinusIcon.defaultProps = {
  fill: '#084E96',
};

export default MinusIcon;
