/* eslint-disable no-console */
import store from 'redux/store';
import { setAuth, setReportingRights, setStatusErrorCode } from 'redux/user-auth/actions';
import API from 'services/api';
import { AuthResponseModel } from 'core/models';
import { getWeek } from 'date-fns';
import { IsAuthResponse } from 'core/models/auth-response';
import lsqMarvinExternalAppIntegrator from '@lsq-marvin-app-utility/external-app-integrator';

export default class AuthService extends API {
  static baseURL: string = process.env.REACT_APP_AUTH_BASE_URL || '';

  activeInterval: any = null;

  constructor() {
    super(AuthService.baseURL, false, false);
  }

  async isAuthorized(): Promise<boolean> {
    const { token } = store.getState().auth;
    if ((
      token && !this.isTokenExpired() && await this.checkAuth())
      || await this.createAuth()) {
      try {
        // fires on every page load.
        const { userAttributes } = store.getState().auth;
        this.initalizePendo(userAttributes, 0);
      } catch (e) {
        console.error(e);
      }
      return true;
    }
    return false;
  }

  async createAuth(reIssueToken = false): Promise<boolean> {
    const $this = this;
    const { isMarvin } = store.getState().auth;
    if (isMarvin) {
      this.marvinInterceptorRequest(reIssueToken);
    } else {
      this.interceptRequest();
    }
    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error && error.response && error.response.status === 401) {
          if (isMarvin) {
            if (!reIssueToken) {
              store.dispatch(setStatusErrorCode(error.response.status));
            } else {
              lsqMarvinExternalAppIntegrator.actionDispatcher.signOut();
            }
          } else {
            $this.logout();
          }
        }
        return Promise.reject(error);
      },
    );
    const res = this.responsifyGeneric<AuthResponseModel.Auth>(await this.instance.get('authorizer/authorize', {
      withCredentials: true,
    }));
    if (res) {
      // redirect to url if requested from authorize response
      // this is done to handle cross region URL access
      if (res.RedirectAttributes && res.RedirectAttributes.Redirect && res.RedirectAttributes.Url) {
        window.open(res.RedirectAttributes.Url, '_self');
      }
      store.dispatch(setAuth(res));
      this.loopCheckForExpiry();
      return true;
    }
    this.logout();
    return false;
  }

  // check auth status from server
  private async checkAuth(): Promise<boolean> {
    try {
      const { IsAuthorized, ReportingDisabled: isReportingDisabled } = this.responsifyGeneric<IsAuthResponse>(await new API(AuthService.baseURL).instance.get('authenticator/isAuthorized', {

        withCredentials: true,
      }));
      store.dispatch(setReportingRights(isReportingDisabled));
      if (IsAuthorized) {
        this.loopCheckForExpiry();
        return true;
      }
    } catch { //
    }
    return false;
  }

  // check every 1ms for auth token expiry(client side), if expired call isAuthorized
  private loopCheckForExpiry() {
    if (this.activeInterval) {
      return;
    }
    this.activeInterval = setInterval(() => {
      if (this.isTokenExpired()) {
        clearInterval(this.activeInterval);
        this.activeInterval = null;
        this.isAuthorized();
      }
    }, 1);
  }

  // initalizePendo initialises the pendo script if pendo is added
  private initalizePendo = (userDetails: AuthResponseModel.UserAttributes, retryCount: number) => {
    const pendo = (window as any).pendo;
    if (pendo && pendo.initialize) {
      pendo.initialize({
        visitor: {
          id: userDetails.Id, // Required if user is logged in
          org_code: userDetails.OrgShortCode,
          region_id: userDetails.RegionId,
          role: userDetails.Role,
          email_id: userDetails.passPhrase,
          week_number: getWeek(new Date()),
        },
        account: {
          id: userDetails.OrgShortCode,
        },
      });
    } else if (retryCount < 10) {
      setTimeout(() => this.initalizePendo(userDetails, retryCount + 1), 500);
    }
  };
}
