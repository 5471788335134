import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import StyledHighChartComponent from 'components/common/visualization/highcharts/HighChart/high-chart.styled';
import { IProps } from 'components/common/visualization/highcharts/HighChart/high-chart';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxModel } from 'core/models';
import { formatType } from 'core/constants/report';
import { updateTriggerExport } from 'redux/report/actions';
import highchartsExporting from 'highcharts/modules/exporting';
import { formattedCurrentDate } from 'core/utils/formatters.utility';

// adding export modules for HighCharts
highchartsExporting(Highcharts);

export const HighChart = (props: IProps) => {
  const {
    options,
  } = props;

  const [reportTitle, triggerExport] = useSelector((state:ReduxModel.IGlobalState) => [state.report.reportConfig.Details.Title, state.report.triggerExport] as const);
  const filenameForCharts = `${reportTitle}_${formattedCurrentDate()}`;

  Highcharts.setOptions({
    exporting: {
      filename: filenameForCharts,
      scale: 1,
      sourceWidth: 2000,
    },
    title: {
      text: '',
    },
  });
  const ref = React.useRef(null);
  if (ref && ref.current && ref.current.chart) {
    ref.current.chart.reflow();
  }
  const dispatch = useDispatch();

  useEffect(() => {
    if (ref?.current?.chart) {
      if (triggerExport === formatType.PNG) {
        ref.current.chart.exportChart({
          type: 'image/png',
        });
      }
      if (triggerExport === formatType.JPG) {
        ref.current.chart.exportChart({
          type: 'image/jpeg',
        });
      }
      if (triggerExport === formatType.PDF) {
        ref.current.chart.exportChart({
          type: 'application/pdf',
        });
      }
    }
    dispatch(updateTriggerExport(''));
  }, [triggerExport]);
  return (
    <StyledHighChartComponent>
      <HighchartsReact
        ref={ref}
        highcharts={Highcharts}
        options={options}
      />
    </StyledHighChartComponent>
  );
};

export default HighChart;
