/* eslint-disable import/prefer-default-export */
import { ReportListResponseModel, SideNavbarModel } from 'core/models';
import PinnedIcon from 'components/common/svg/Pinned/pinned.svg';
import { NavSectionSubTypes } from 'core/constants/side-navbar';

export const NavReportSectionSubTypes = [
  {
    Id: NavSectionSubTypes.Pinned,
    Name: 'Pinned Reports',
    Icon: PinnedIcon,
    IconColor: 'var(--navSectionIcon)',

  },
];

export const getNavSections = (
  reportCategories: Array<ReportListResponseModel.ICategory> = [],
): Array<SideNavbarModel.INavSection> => [
  {
    SectionId: 'report',
    Section: 'Reports',
    SubTypes: NavReportSectionSubTypes,
    Categories: reportCategories,
    Path: '/',
  },
];
