import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const ColumnChartIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 24 24"
  >
    <g
      id="Column_Chart"
      data-name="Column Chart"
      transform="translate(-92 -248)"
    >
      <rect
        id="Rectangle_6368"
        data-name="Rectangle 6368"
        width="24"
        height="24"
        transform="translate(92 248)"
        fill="none"
      />
      <path
        id="Path_7574"
        data-name="Path 7574"
        d="M5,9.2H8V19H5ZM10.6,5h2.8V19H10.6Zm5.6,8H19v6H16.2Z"
        transform="translate(92 248)"
        fill={fill}
      />
    </g>
  </svg>
);

ColumnChartIcon.defaultProps = {
  fill: '#3bb345',
};

export default ColumnChartIcon;
