import styled from 'styled-components';

const StyledSidebarItem = styled.div`

.se-item-container.disabled {
  pointer-events: none;
}

button.se-sidebar-nav-item {
  display: flex;
  border: none;
  background: transparent;
  cursor: pointer;
  width: 68px;
  color: #454F5B;
  box-sizing: border-box;
  padding: 0px;
    
  :hover {
    color: #006FD6;
    background-color: #E7F1FB;
    transition: background-color 300ms ease-out;
    .icon-stroke {
      fill: #116FD6 !important;
    }
    .icon-active {
        fill: #116FD6 !important;
        transition: fill 300ms ease-out;
    }
    svg #Path_10189 {
      fill: #fff !important;
    }
    svg #Path_8849 {
      fill: none !important;
    }
  }
}

button.se-sidebar-nav-item.active {
  color: #006FD6;
  opacity: 1;
  .icon-stroke {
    fill: #116FD6 !important;
  }
  .icon-active {
    fill: #116FD6 !important;
  }
  svg #Path_10189 {
    fill: #fff !important;
  }
  svg #Path_8849 {
    fill: none !important;
  }
  :hover {
    background: transparent;
  }
}

button.se-sidebar-nav-item.disabled {
  pointer-events: none;
  color: #919EAB;

  svg #Path_10189 {
    fill:#919EAB !important;
  }

  svg #Path_10837 {
    fill: none !important;
  }

  .icon-stroke {
    fill: #919EAB !important;
  }
}

.se-inside-button{
  display: flex;
  flex-direction: row;
  width: 68px;
  padding: 0;
  margin: 0;
}

.se-nav-item {
  display: flex;
  flex-direction: column;
  width: 64px;
  padding: 10px 0px;
  line-height: 15px;
}
.Report {
  padding: 6.5px 0px !important;
}

.se-nav-icon{
  padding-left: 23px;  
  padding-right: 21px;
}

.se-nav-item-name {
  font-family: 'Regular';
  font-size: 11px;
  padding-left: 4px;
  padding-right: 2px;
}

.se-active-indicator.active {
  margin: 2px 0px;
  background-color: #006FD6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 2px;
}
`;

export default StyledSidebarItem;
