import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  AuthReduxModel, ReduxModel,
} from 'core/models';
import * as Actions from 'redux/report/actions';

const mapStateToProps = (state: ReduxModel.IGlobalState) => ({
  reduxState: state,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reduxAction: {
    reportConfigLoad: (reportId: string, isRefresh?: boolean) => {
      dispatch(Actions.reportConfigLoadRequest(reportId, isRefresh));
    },
    reportDataLoad: () => {
      dispatch(Actions.reportDataLoadRequest());
    },
    filterLoad: (filterId: string, variables: AuthReduxModel.IVariables) => {
      dispatch(Actions.filterLoadRequest(filterId, variables));
    },
    filterChange: (filterId: string, filterValue: any, variables: AuthReduxModel.IVariables) => {
      dispatch(Actions.filterChange(filterId, filterValue, variables));
    },
    reset: () => {
      dispatch(Actions.reset());
    },
    applyFilters: () => {
      dispatch(Actions.applyFilters());
    },
    clearFilters: (variables: AuthReduxModel.IVariables) => {
      dispatch(Actions.clearFilters(variables));
    },
    refresh: () => {
      dispatch(Actions.refresh());
    },
    autodrilldownReportDataLoad: () => {
      dispatch(Actions.autodrilldownReportDataLoadRequest());
    },
    dataRefreshDetailsLoad: (reportId: string) => {
      dispatch(Actions.dataRefreshDetailsLoadRequest(reportId));
    },
  },
});

export default () => connect(mapStateToProps, mapDispatchToProps);
