import React, { useEffect, useState } from 'react';
import { IActionButton, IButtonsDropdownProps } from './buttons-dropdown.d';
import StyledButtonsDropdownContainer from './buttons-dropdown.styled';
import SimpleDownArrowIcon from '../svg/SimpleDownArrow/simple-down-arrow.svg';
import SimpleUpArrowIcon from '../svg/SimpleUpArrow/simple-up-arrow.svg';

const ButtonsDropdown = (props:IButtonsDropdownProps) => {
  const {
    mainSvgComponent,
    actionButtons,
    mainBtnClick,
    mainPendoClass,
    isDisabled,
  } = props;
  const [expand, setExpand] = useState(false);
  const togglerRef = React.useRef(null);
  const dropdownContentRef = React.useRef(null);

  // on clicking outside the container, close the dropdown
  useEffect(() => {
    const checkIfClickedOutside = (event: any) => {
      if (expand && togglerRef.current && !togglerRef.current.contains(event.target)
        && dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) {
        setExpand(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [expand]);

  const buttonClickHandler = (event:any, index:number) => {
    setExpand(false);
    actionButtons[index].onClickHandler();
  };

  return (
    <StyledButtonsDropdownContainer>
      <div className="se-buttons-dropdown">
        <div className={`se-dropdown-buttons ${isDisabled ? 'disabled ' : ''}`}>
          <button type="button" className={`svg-main-button ${mainPendoClass || ''}`} onClick={mainBtnClick} disabled={isDisabled}>
            <span className="se-main-icon">
              {mainSvgComponent}
            </span>
            <span className="se-main-text">
              Export
            </span>
          </button>
          <div className="se-dropdown-bar" />
          <button ref={togglerRef} onClick={() => setExpand(!expand)} type="button" className={`se-arrow-indicator ${isDisabled && 'se-disable'}`}>
            { expand ? <SimpleUpArrowIcon width={7.4} height={4.5} /> : <SimpleDownArrowIcon width={7.4} height={4.5} /> }
          </button>
        </div>
        {
        expand && (
        <div ref={dropdownContentRef} className="se-dropdown-content">
          {actionButtons.length > 0 && actionButtons.map((item:IActionButton, index:number) => {
            const {
              svgComponent, name, topborderRequire, pendoClass, isDisabled: isButtonDisabled, isVisible,
            } = item;

            return (
              <React.Fragment key={name}>
                {topborderRequire && <span className="se-dropdown-line-seperator se-dropdown-margin" />}
                <button type="button" className={`se-dropdown-item ${pendoClass || ''} ${index !== 0 ? 'se-dropdown-margin' : ''} ${isButtonDisabled ? 'disable' : ''} ${isVisible ? '' : 'se-hide-button'}`} onClick={(e) => buttonClickHandler(e, index)}>
                  <span className="se-dropdown-item-svg">
                    {svgComponent}
                  </span>
                  <span className="se-dropdown-item-name">{name}</span>
                </button>
              </React.Fragment>
            );
          })}
        </div>
        )
      }
      </div>
    </StyledButtonsDropdownContainer>
  );
};

export default ButtonsDropdown;
