import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const ActivityIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 12 10.803"
  >
    <path
      id="Activity"
      d="M7,13.8a.6.6,0,0,1-.522-.306L4.334,9.664H2v-1.2H4.682a.6.6,0,0,1,.528.306L6.8,11.632l2.286-8.2a.6.6,0,0,1,1.128-.1L11.72,6.52H14v1.2H11.336a.6.6,0,0,1-.546-.348L9.8,5.308,7.58,13.36a.6.6,0,0,1-.51.432Z"
      transform="translate(-2 -2.995)"
      fill={fill}
    />
  </svg>
);

ActivityIcon.defaultProps = {
  fill: '#919eab',
};

export default ActivityIcon;
