import React, { useState } from 'react';
import Tooltip from 'rc-tooltip';
import { FilterType } from 'core/constants/filter';
import { APIRequestStatus } from 'core/constants/redux';
import {
  FilterModel, ReportResponseModel, AuthReduxModel,
} from 'core/models';
import { isClearable, isFilterChanged } from 'core/utils/filter-builder.util';
import {
  IProps, IOnChange, ISearchDropdownOptions, ILoadMoreDropdownOptions,
} from 'components/common/FilterBuilder/filter-builder.d';
import StyledContainer from 'components/common/FilterBuilder/filter-builder.styled';
import DateRangeWrapper from 'components/common/FilterBuilder/DateRangeWrapper/date-range-wrapper.component';
import ReactSelect from 'components/common/select/ReactSelect/react-select.component';
import ArrowDownIcon from 'components/common/svg/ArrowDown/arrow-down.svg';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { resetReportview } from 'redux/report-view/report-view.actions';
import { authSelector } from 'components/feature/Report/ReportSidebar/ReportView/report-view.selector';
import { deleteGivenQueryStringsFromUrl } from 'core/utils/report.util';
import { FilterButtonTypes, ReportViewURLTypes } from 'core/constants/report';
import FilterCloseIcon from '../svg/Close/filterclose.svg';
import MaskedFilter from './MaskedFilter/masked-filter.component';

const filterComponent = (
  filterConfig: ReportResponseModel.IFilterConfig, onChange: IOnChange, filter: FilterModel.IFilterResponse,
  loading: APIRequestStatus, searchDropdownOptions?: ISearchDropdownOptions, loadMoreDropdownOptions?: ILoadMoreDropdownOptions,
  auth?:AuthReduxModel.IState, removeId?:string, newAddFilterID?: string,
) => {
  const value = filter.FilterResponse;
  const configData: FilterModel.IConfig = {
    Key: filterConfig.ID,
    Loading: loading === APIRequestStatus.Processing,
    Disabled: filter.Disabled,
    ...filterConfig,
  };

  const removedBorderClass = removeId === configData.ID ? 'se-remove-red-border' : '';
  const addBorderClass = newAddFilterID === configData.ID ? 'se-add-green-border' : '';
  if (filterConfig.IsMasked) {
    return (
      <MaskedFilter
        label={configData.Placeholder || 'Select'}
        removeBorderClass={removedBorderClass}
      />
    );
  }
  // For React select dropdown list UI, don't change the option object. It will affect the search functionality. Update OptionComponent.
  switch (filterConfig.Type) {
    case FilterType.DateRange:
      return (
        <DateRangeWrapper
          config={configData}
          value={value}
          onChange={onChange}
          removeBorderClass={removedBorderClass}
          addBorderClass={addBorderClass}
        />
      );
    case FilterType.UserMultiSelect:
      return (
        <ReactSelect
          config={{
            ...configData,
            IsMulti: true,
            LazyLoading: loading === APIRequestStatus.LazyLoading,
            Meta: {
              Options: filter.FilterOptions,
              DropdownIndicator: true,
              OptionsMeta: {
                ...filter.OptionsMeta,
                searchDropdownOptions,
                loadMoreDropdownOptions,
              },
            },
          }}
          value={value}
          onChange={onChange}
          removeBorderClass={removedBorderClass}
          addBorderClass={addBorderClass}
          pendoClassName={PendoClassNames.DropDownFilters}
        />
      );
    case FilterType.GroupMultiSelect:
    case FilterType.CustomDefinedMultiSelect:
    case FilterType.LSQMetadataMultiSelect:
      return (
        <ReactSelect
          config={{
            ...configData,
            IsMulti: true,
            Meta: {
              Options: filter.FilterOptions,
              DropdownIndicator: true,
              OptionsMeta: filter.OptionsMeta,
            },
          }}
          value={value}
          onChange={onChange}
          removeBorderClass={removedBorderClass}
          addBorderClass={addBorderClass}
          pendoClassName={PendoClassNames.DropDownFilters}
        />
      );
    case FilterType.LSQMetadataSingleSelect:
      return (
        <ReactSelect
          config={{
            ...configData,
            IsMulti: false,
            Meta: {
              Options: filter.FilterOptions,
              DropdownIndicator: true,
            },
          }}
          value={value}
          onChange={onChange}
          removeBorderClass={removedBorderClass}
          addBorderClass={addBorderClass}
          pendoClassName={PendoClassNames.DropDownFilters}
        />
      );
    case FilterType.CustomDefinedSingleSelect:
      return (
        <ReactSelect
          config={{
            ...configData,
            IsMulti: false,
            Meta: {
              Options: filter.FilterOptions,
              DropdownIndicator: true,
            },
          }}
          value={value}
          onChange={onChange}
          removeBorderClass={removedBorderClass}
          addBorderClass={addBorderClass}
          pendoClassName={PendoClassNames.DropDownFilters}
        />
      );
    default:
      return null;
  }
};

function FilterBuilder(props: IProps) {
  const {
    config, filters, onChange, loaders, applyFilters, clearFilters, activeFilters,
    searchDropdownOptions, loadMoreDropdownOptions, auth, availableDynamicFilters,
    removeDynamicFilter, closeAvailableFilters, newAddFilterID,
  } = props;
  const dispatch = useDispatch();

  const [showDisabledFilters, setShowDisabledFilters] = useState<Boolean>(false);
  const [removeId, setRemoveId] = useState('');
  const disableApplyFilters = !isFilterChanged(config, filters, activeFilters);
  const disableClearFilters = !isClearable(config, activeFilters);
  const { isV1Report, isMarvin } = useSelector(authSelector);

  const getFilterLabel = (item: ReportResponseModel.IFilterConfig) => {
    const label = (name: string) => (
      <div
        className="se-filter-label"
      >
        {name}
      </div>
    );
    if (item.IsDynamic) {
      return (
        <>
          <Tooltip
            placement="top"
            overlay={(
              <span>
                {`${item.Entity} | ${item.Label} (${item.Alias})`}
              </span>
            )}
            mouseEnterDelay={1.5}
          >
            {label(`${item.Entity} | ${item.Label}`)}
          </Tooltip>
          <div>
            <button
              className={`se-icon-cn se-remove-dynamic-filter ${PendoClassNames.RemoveFilter}`}
              type="button"
              onClick={() => {
                setRemoveId(item.ID);
                return (
                  setTimeout(() => {
                    setRemoveId('');
                    removeDynamicFilter(item.ID);
                  }, 600));
              }}
            >
              <FilterCloseIcon
                width={12}
                height={12}
                fill="var(--arrowIcon)"
              />
            </button>
          </div>
        </>
      );
    }
    return label(item.Label);
  };

  const onClickHandler = (callFrom: FilterButtonTypes) => {
    if (closeAvailableFilters) {
      closeAvailableFilters();
    }
    dispatch(resetReportview());
    if (callFrom === FilterButtonTypes.apply) { applyFilters(); } else { clearFilters(); }
    deleteGivenQueryStringsFromUrl([ReportViewURLTypes.rvId, ReportViewURLTypes.rvType, ReportViewURLTypes.rvu]);
  };

  const getFilterShowcase = (item: ReportResponseModel.IFilterConfig) => (
    <div
      key={item.ID}
      className="se-filter"
    >
      <div
        className="se-filter-label-cn"
      >
        {getFilterLabel(item)}
      </div>
      {
        filterComponent(
          item,
          onChange,
          filters[item.ID],
          loaders[item.ID],
          searchDropdownOptions,
          loadMoreDropdownOptions,
          auth,
          removeId,
          newAddFilterID,
        )
      }
    </div>
  );

  const mandatoryFilters = config.filter((item) => item.Type !== FilterType.PRE_APPLIED && item.AlwaysEnabled);
  const nonMandatoryFilters = config.filter((item) => (item.Type !== FilterType.PRE_APPLIED
    && !item.IsRemoved && !item.AlwaysEnabled && filters[item.ID] && !filters[item.ID].Disabled));
  const disabledFilters = config.filter((item) => (
    item.Type !== FilterType.PRE_APPLIED && filters[item.ID] && filters[item.ID].Disabled));

  return (
    <StyledContainer>
      <div
        className="se-filter-builder-cn"
      >
        <div
          className="se-filters se-custom-scrollbar"
        >
          {availableDynamicFilters}
          {
            mandatoryFilters.map((item: ReportResponseModel.IFilterConfig) => getFilterShowcase(item))
          }
          {
            nonMandatoryFilters.map((item: ReportResponseModel.IFilterConfig) => getFilterShowcase(item))
          }
        </div>
        {
          !!disabledFilters.length
          && (
          <div
            className={`se-disabled-filters-cn ${showDisabledFilters ? 'expand se-custom-scrollbar' : ''}`}
          >
            <div
              className="se-disabled-filters-section-header"
            >
              <button
                className="se-disabled-filters-toggler"
                type="button"
                onClick={() => setShowDisabledFilters(!showDisabledFilters)}
              >
                <div>
                  Disabled Filters
                </div>
                <div
                  className="se-icon-cn"
                >
                  <ArrowDownIcon
                    width={24}
                    height={24}
                    fill="#ADBAC7"
                  />
                </div>
              </button>
              <div
                className="se-disabled-filters-desc"
              >
                These filters will be active when associated fields in grouping are checked.
              </div>
            </div>
            <div
              className="se-disabled-filters"
            >
              {
                disabledFilters.map((item: ReportResponseModel.IFilterConfig) => getFilterShowcase(item))
              }
            </div>
          </div>
          )
        }
        <div
          className={`se-filter-builder-footer ${isV1Report || isMarvin ? 'externalAppIntegrationStlye' : ''}`}
        >
          <Tooltip
            placement="left"
            overlay={<span>Reset Filters</span>}
            mouseEnterDelay={1.5}
          >
            <button
              type="button"
              onClick={() => onClickHandler(FilterButtonTypes.reset)}
              className={`se-clear-filters ${disableClearFilters ? 'disabled' : ''} ${PendoClassNames.ResetFilters} `}
              disabled={disableClearFilters}
            >
              Reset Filters
            </button>
          </Tooltip>
          <Tooltip
            placement="top"
            overlay={<span>Apply Filters</span>}
            mouseEnterDelay={1.5}
          >
            <button
              type="button"
              onClick={() => onClickHandler(FilterButtonTypes.apply)}
              className={`se-apply-filters ${disableApplyFilters ? 'disabled' : ''} ${PendoClassNames.ApplyFilters}`}
              disabled={disableApplyFilters}

            >
              Apply Filters
            </button>
          </Tooltip>
        </div>
      </div>
    </StyledContainer>
  );
}

export default FilterBuilder;
