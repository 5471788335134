import styled from 'styled-components';

interface IProps {
  cursorPointer?: boolean;
}

export default styled.div<IProps>`
  width: 100%;
  margin: 0 0 8px 0;
  cursor: ${(props) => (props.cursorPointer ? 'pointer' : 'auto')};

  :last-child {
    margin: 0 0 0 0;
  }
  &.se-dim-dragging {
    margin: 0 0 8px 0;
    opacity: 1 !important;
  }

  .set-binning-border .se-react-select__control{
    border-color: var(--onHoverBorder);
  }

  .se-draggable-item {
    display: flex;
    border: 1px solid #DFE3E8;
    padding: 5px 8px 5px 12px;
    background-color:var(--noDataBackground);
    border-radius: 2px;
    transition: border 100ms ease 0s, background-color 100ms ease 0s; // copied from react-select CSS

    overflow: hidden;

    &:hover {
      border-color: var(--onHoverBorder);
      .se-icon-cn.se-drag-icon .icon-stroke {
        fill:var(--paginationColor);
      }
    }
    &.se-green-border {
      border-color: #189F3F66;
    }
    &.se-blue-border {
      border-color: #006FD666;
    }
    &.se-yellow-border {
      border-color: #FFE05F80;
    }
    &.se-red-border {
      border-color: #D1331B66;
    }
    &.se-replace-blue-border {
      border-color: #116FD6;
    }
    &.se-remove-red-border {
      border-color: #D1331B;
    }
    &.se-add-green-border {
      border-color: #3BB345;
    }
    &.se-disable-pointer {
      pointer-events: none;
    }

    .se-item-name {
      flex: 1;
      margin-left: 12px;
      font-size: 13px;
      color: #454F5B;
      font-family: Regular;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    
    .se-icon-cn {
      height: 20px; // set the non-padded height of .se-draggable-item to a consistent value
    }
    
    .se-item-close-btn {
      cursor: pointer;
      
      &.se-item-close-btn-disabled {
        cursor: not-allowed;
      }
      
      .icon-stroke {
        fill: #B9C4CE;
      }
      &:hover .icon-stroke {
        fill: var(--headerColor);
      }
    }

    .se-react-select__control {
      height: 20px !important;
      min-height: 20px;
      background: var(--base-white) 0% 0% no-repeat padding-box;
      border-radius: 2px;
      padding: 2px 0 0 8px;
      width: 80px;
      margin-right: 8px;
    }
  
    .se-react-select__value-container {
      font-size: 11px;
      bottom: 4px;
    }
  
    .se-react-select__single-value {
      width: auto;
      max-width: none;
      position: relative;
      transform: none;
      align-items:center;
      font: 11px Regular;
      display: none;
    }
    .se-react-select-single-select-cn{
        position: absolute;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
  
    .se-react-select__option {
      font-size: 11px;
    }

    se-react-select__indicator {
      bottom: 3px;
    }

    .se-react-select__dropdown-indicator{
      padding-bottom: 4px; 
      padding-right: 6px;
    }

  .se-react-select__menu-list{
    max-height: 205px;
  }

  .se-react-select__menu-portal {
    width: 180px;
    max-width: 180px;
    margin-left: -60px;
    z-index: 2;
  }

  .se-react-select__option {
    height: 40px;
    max-height: 40px;
    overflow: hidden;

    .se-react-select-option-cn{
      flex: 1;
    }

    .rs-option-label-cn {
      font: 13px Regular;
    }

    .rs-option-example {
      font: 11px Regular;
      color: #919EAB;
      max-width: 130px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  }

  .se-entity-indicator-cn {
    position: relative;
  }

  .se-entity-indicator {
    padding: 0 1px;
    height: 32px;
    position: absolute;
    top: -5px;
    left: -12px;
  }
`;
