import styled from 'styled-components';

const StyledReportComponent = styled.div`
height: 120px;
padding: 20px 0;
background: #fff;
position: sticky;
top: 0;
z-index: 2;
box-sizing: border-box;

.se-report-header {
   height: 100%;
  .se-report-header-content{
    display:flex;
}
    .se-report-header-label {
      font-size: 20px;
      font-family: SemiBold;
      color: #212b36;
      line-height: 1.4;
      margin-bottom: 20px;
    }

    .se-report-header-label.se-shimmer-cn {
      width: 100px;
    }
}
.searchContainer {
    display: flex;
    position: relative;
    align-items:center;
    overflow: hidden;  
    width:300px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid var(--searchBorder);
    border-radius: 2px;
    opacity: 1;
    margin-left:12px;
    padding: 0 8px;
  }
  
.se-new-report-button-container{
    display:flex;
    place-content: flex-end;
    flex:1;
    
} 

.se-new-report-button-text{
  padding-left:8px;
}
div.searchContainer:hover{
   border-color: var(--searchHoverBorder);
 } 

.se-new-report-button{
    background: var(--onHoverBorder) 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1; 
    font-family: Regular;
    letter-spacing: 0px;
    color: #fff;
    opacity: 1;
    padding: 0px 15px 0px 12px;
    display: flex;
    align-items: center;
    cursor:pointer;
    border:none
} 

.se-new-report-button.se-shimmer-cn {
  width: 100px;
}
 
  .searchIcon {
    padding: 0.5rem;
  }
  
  .searchBox {
    border: 0;
    flex: 1;
    color:var(--searchBoxTextColor);
    font-family: Regular;
    outline:none;
    
  }
  .searchBox:focus,.searchBox:hover{
    border:0px
  }
  .searchButton {
    background: var(--searchButtonBackground);
    border: 0;
    color: white;
    border-radius: 0;
  }
  @media (max-width: 320px) {
    .se-report-header-label {
      margin-left: 20px;
}}

@media (max-width: 991px) {
    .se-report-header-label {
      margin-left: 20px;
}
}
`;

export default StyledReportComponent;
