import styled from 'styled-components';

const StyledComponent = styled.div`
.se-report-action-select-cn {
    width: auto !important;

    .se-select-menu {
        width: 120px !important;
        padding: 0px !important;
    }

    .se-select-option {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .se-option-icon {
        margin-right: 8px;
    }

    .se-option-label {
        flex: 1;
        text-align: left;
        font-family: Regular;
        font-size: 13px;
        color: #637381;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .se-cs-select-toggler {
        opacity: 0.5;

        :hover {
            opacity: 1;
        }
    }
}

.se-report-action-select-cn.active {
    .se-cs-select-toggler {
        opacity: 1;
    }
}




`;

export default StyledComponent;
