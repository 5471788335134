import React from 'react';
import {
  DimensionMode,
  DimensionStatus,
  ReportDimensionsZeroValuesDesc,
  ReportDimensionsZeroValuesTitle,
  ZeroAnchorStatus, ZeroValuesHelpText,
} from 'core/constants/report';
import { ReduxModel } from 'core/models';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'redux/report/actions';
import StyledContainer from 'components/feature/Report/ReportSidebar/ZeroValues/zero-values.style';
import SidebarHeader from 'components/feature/Report/ReportSidebar/common/SidebarHeader/sidebar-header.component';
import SingleSelectList
  from 'components/feature/Report/ReportSidebar/common/SingleSelectList/single-select-list.component';
import {
  ISingleSelectItem,
} from 'components/feature/Report/ReportSidebar/common/SingleSelectList/SingleSelectItem/single-select-item.d';
import ApplySettingsButton
  from 'components/feature/Report/ReportSidebar/common/ApplySettingsButton/apply-settings.component';
import { IDimension } from 'core/models/report-redux';
import { applyZeroAnchorsSettings } from 'core/utils/report-builder.util';
import { displayName } from 'components/feature/Report/ReportSidebar/common/helpers';
import { getFieldIdentifier } from 'core/utils/report.util';
import { DateBinningFormatters } from 'core/constants/formatters';

const ZeroValues = () => {
  const [
    appliedDimensions,
    activeDimensions,
    zeroValuesEnabled,
  ] = useSelector((state: ReduxModel.IGlobalState) => [
    state.report.appliedDimensions,
    state.report.activeDimensions,
    state.report.zeroValuesEnabled,
  ] as const);
  const dispatch = useDispatch();

  const toggleZeroAnchors = (status: boolean) => dispatch(Actions.toggleZeroAnchors(status));
  const setZeroAnchor = (index: number) => dispatch(Actions.setZeroAnchor(index));
  const applyDimensions = () => dispatch(Actions.applyDimensions());

  const isDisabled = (dimension: IDimension): boolean => {
    if (!!dimension && dimension.ZeroAnchor === ZeroAnchorStatus.Disabled) {
      return true;
    }
    // disabled zero values for all binning types except Hour of the Day(backend is not compatible)
    if (!!dimension && dimension.ZeroAnchor !== ZeroAnchorStatus.Disabled
      && dimension.DimensionProp && dimension.DimensionProp?.Props?.BinningType !== DateBinningFormatters.HOUROFTHEDAY) {
      return true;
    }
    return false;
  };

  const getDisabledHelpText = (index: number): string => {
    const dimension = appliedDimensions[index];
    if (dimension === undefined || !isDisabled(dimension)) {
      return '';
    }
    return ZeroValuesHelpText.Disabled;
  };

  return (
    <StyledContainer>
      <div className="se-report-settings">
        <div className="se-zero-values">
          <div className="se-zero-values-settings se-custom-scrollbar">
            <div className="se-zero-values-settings-item">
              <SidebarHeader
                title={ReportDimensionsZeroValuesTitle}
                desc={ReportDimensionsZeroValuesDesc}
                toggleValue={zeroValuesEnabled}
                onToggle={toggleZeroAnchors}
              />
              {
                zeroValuesEnabled && (
                  <SingleSelectList
                    items={
                      appliedDimensions
                        .map((d, index): ISingleSelectItem<number> => {
                          if (d.Applied === DimensionStatus.NotApplied || d.DimensionMode !== DimensionMode.RowGroup) {
                            return null;
                          }
                          const id = getFieldIdentifier(d);
                          const text = displayName(d, true);
                          return {
                            id,
                            text,
                            data: index,
                            isSelected: d.ZeroAnchor === ZeroAnchorStatus.Applied,
                            isDisabled: isDisabled(d),
                          };
                        })
                        .filter((item) => item !== null)
                    }
                    onSelectItem={setZeroAnchor}
                    getDisabledHelpText={getDisabledHelpText}
                  />
                )
              }
            </div>
          </div>
        </div>
        <ApplySettingsButton
          onApply={applyDimensions}
          disabled={!applyZeroAnchorsSettings(appliedDimensions, activeDimensions)}
        />
      </div>
    </StyledContainer>
  );
};

export default ZeroValues;
